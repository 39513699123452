<script setup>
import { computed, nextTick, reactive, ref } from 'vue';
import { CustomButton, Loader } from '@eron/ui-kit';
import { createRule, updateRule, uploadAttachments, getAttachments, deleteAttachment, getRule } from '@/api/tasks/generate';
import { Notifications } from '@shared/notifications';

import SidePanel from '@/components/common/SidePanel.vue';
import ExtraParamsExpansionPanel from '@/components/tasks/ExtraParamsExpansionPanel.vue';
import TasksGenerateRules from './TasksGenerateRules.vue';
import TasksGenerateTaskData from './TasksGenerateTaskData.vue';
import TasksGenerateExecutors from './TasksGenerateExecutors.vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    mode: {
        type: String,
        default: 'create',
        validator: value => ['create', 'edit'].includes(value),
    },
    ruleId: {
        type: Number,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value', 'save']);

const innerRule = ref();
const attachments = ref([]);
const expandedBlock = ref();
const rulesBlockRef = ref();
const taskDataBlockRef = ref();
const executorsBlockRef = ref();
const extraParamsBlockRef = ref();
const loading = ref(false);
const validationState = reactive({
    rules: false,
    executors: false,
    taskData: false,
    extraParams: false,
});
const visible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:model-value', value);
    },
});
const title = computed(() => (props.mode === 'create' ? 'Создание периодической задачи' : 'Редактирование периодической задачи'));
const actionLabel = computed(() => (props.mode === 'create' ? 'Создать' : 'Сохранить'));
const actionDisabled = computed(() => {
    return loading.value || !validationState.rules || !validationState.taskData || !validationState.extraParams || !validationState.executors;
});
let attachmentsToDelete = [];

const validate = async block => {
    const blocks = {
        rules: rulesBlockRef,
        taskData: taskDataBlockRef,
        extraParams: extraParamsBlockRef,
        executors: executorsBlockRef,
    };

    if (block) {
        validationState[block] = await blocks[block].value?.validate();
        return;
    }

    Object.keys(blocks).forEach(async block => {
        validationState[block] = await blocks[block].value?.validate();
    });
};

const onAttachmentDelete = attachment => {
    attachmentsToDelete.push(attachment);
};

const onOpen = async () => {
    attachmentsToDelete = [];
    attachments.value = [];
    innerRule.value = {};

    if (props.ruleId) {
        loading.value = true;
        try {
            const result = await getRule(props.ruleId);
            innerRule.value = result.data;
        } catch {
            Notifications.danger('Произошла ошибка. Пожалуйста, повторите попытку позже');
            visible.value = false;
            loading.value = false;
            return;
        }

        try {
            const result = await getAttachments(props.ruleId);
            attachments.value = result.data;
        } catch {
            Notifications.danger('Произошла ошибка при загрузке вложений');
        }
        loading.value = false;
    }

    await nextTick();
    expandedBlock.value = 'rules';
    validate();
};

const onSave = async () => {
    const { executors, deadlineByLocalTime } = innerRule.value;
    const apiRule = {
        ...innerRule.value,
        deadlineByLocalTime: ['DIVISION', 'REGION'].includes(executors.executorRole) ? false : deadlineByLocalTime
    };
    const attachmentsToUpload = attachments.value.filter(item => !!item.file).map(item => item.file);

    let ruleId;
    let resultRule;

    loading.value = true;

    try {
        if (props.mode === 'create') {
            const result = await createRule(apiRule);
            resultRule = result.data;
            ruleId = result.data.id;
        } else {
            ruleId = props.ruleId;
            const result = await updateRule(ruleId, apiRule);
            resultRule = result.data;
        }
    } catch {
        Notifications.danger('Произошла ошибка');
        loading.value = false;
        return;
    }

    if (attachmentsToUpload.length) {
        try {
            await uploadAttachments(ruleId, attachmentsToUpload);
        } catch {
            Notifications.danger('Произошла ошибка при загрузке вложений');
        }
    }

    if (attachmentsToDelete.length) {
        const results = await Promise.allSettled(attachmentsToDelete.map(item => deleteAttachment(ruleId, item.uid)));

        if (results.some(res => res.status === 'rejected')) {
            Notifications.danger('Произошла ошибка при удалении вложений');
        }
    }

    Notifications.success(props.mode === 'create' ? 'Правило успешно создано' : 'Правило успешно отредактировано');
    loading.value = false;
    visible.value = false;
    emit('save', resultRule);
};
</script>

<template>
    <side-panel v-model="visible" :title="title" @opened="onOpen">
        <tasks-generate-rules
            ref="rulesBlockRef"
            v-model="innerRule"
            :expanded="expandedBlock === 'rules'"
            class="-mb-6"
            @update:model-value="validate('rules')"
            @update:expanded="expandedBlock = 'rules'"
        />
        <tasks-generate-task-data
            ref="taskDataBlockRef"
            v-model="innerRule"
            :attachments="attachments"
            :expanded="expandedBlock === 'taskData'"
            class="-mb-6"
            @update:model-value="validate('taskData')"
            @update:attachments="attachments = $event"
            @delete:attachment="onAttachmentDelete"
            @update:expanded="expandedBlock = 'taskData'"
        />
        <tasks-generate-executors
            ref="executorsBlockRef"
            v-model="innerRule"
            :expanded="expandedBlock === 'executors'"
            class="-mb-6"
            @update:model-value="validate('executors')"
            @update:expanded="expandedBlock = 'executors'"
        />
        <extra-params-expansion-panel
            ref="extraParamsBlockRef"
            v-model="innerRule"
            :expanded="expandedBlock === 'extraParams'"
            @update:model-value="validate('extraParams')"
            @update:expanded="expandedBlock = 'extraParams'"
        />

        <loader v-if="loading" inside-element inside-element-position="absolute" class="-text-primary" />

        <template #footer="{ close }">
            <div class="-flex">
                <custom-button :text="actionLabel" :disabled="actionDisabled" theme="green" class="-relative -mr-4" @click="onSave" />
                <custom-button text="Отменить" @click="close()" />
            </div>
        </template>
    </side-panel>
</template>

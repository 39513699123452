(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('TasksService', TasksService);

    TasksService.$inject = [];

    function TasksService() {
        var typesFilter = [{
            title: 'Все',
            state: 'tasks.all'
        },
        {
            title: 'Общие',
            state: 'tasks.common'
        },
        {
            title: 'Фотоотчеты',
            state: 'tasks.photo'
        },
        {
            title: 'Чек-листы',
            state: 'tasks.checklists'
        },
        {
            title: 'Проверка наличия',
            state: 'tasks.form'
        }];

        var service = {
            typesFilter: typesFilter
        };

        return service;
    }
})();

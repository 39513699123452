angular.module('smartApp').controller('HoutController', function($timeout, ProcessTreeUtils, TWQuestionService, config, ngDialog, Notifications, $scope, PWCategoryService, OrgstructService, $q, $filter) {

    var _this = $scope;

    $scope.Utils = {
        keys : Object.keys
    };

    $scope.dataForTheTree = [];

    $scope.config = config;


    $scope.expandedNodes = [];
    $scope.selectedNode = undefined;
    $scope.localLang =  {
        selectAll       : "Выбрать",
        selectNone      : "Отменить",
        reset           : "Сбросить",
        search          : "Поиск...",
        nothingSelected : "Ничего не выбрано"
    }

    $scope.dataForRouting =  {users: [], businessLines: []}


    init();

    function init(){
        $scope.viewUsersBusinessLine = [];

        $scope.questionUser = [];
        $scope.content = {};
        $scope.changedRoutes = [];
        $scope.usersBusinessLine = [];
        $scope.reloading = false;
    }


    $scope.showRouting = function(question){
        if(!$scope.dataForRouting.businessLines.length){
            OrgstructService.getBusinessDirs({sort: 'name,desc'}).then(function (result) {
               $scope.dataForRouting.businessLines = result.data;
               $scope.$emit("businessLinesLoaded")
               console.log('$emit("businessLinesLoaded")')
            });
        }

        if(!$scope.dataForRouting.users.length){
            OrgstructService.getUsers({shopDir: false}).then(function (result) {
                 $scope.dataForRouting.users = result.data.map(el => {
                    el.name = el.firstName+ " " + el.lastName+ " <mail>" +el.login+ "</mail>";
                    return el
                });
                $scope.$emit("usersLoaded")
            });
        }
        _scope = $scope;

        ngDialog.open({
            template: 'routingPopup',
            controller: ['$scope', function($scope) {
                var transpail = {'bn': 'routeToBusinessDirId', 'user': 'routeToUserId'}
                $scope.route = {type:undefined}
                $scope.localLang = _scope.localLang
                $scope.routing = {user:[], bn: []};
                $scope.showList = _scope.showList;
                $scope.usersForBusinessLine = []
                $scope.dataForRouting = _scope.dataForRouting
                $scope.routeExists = false;

                var setLoadedBN = function(){
                    var setBN = function(){
                        $scope.dataForRouting.businessLines = $scope.dataForRouting.businessLines.map(el => {
                            el.ticked = (question.routeToBusinessDirId == el.id)
                            return el
                        })
                        $scope.route.type = 'bn'
                    }
                    var setUser = function(){
                        $scope.dataForRouting.users = $scope.dataForRouting.users.map(el => {
                            el.ticked = (question.routeToUserId == el.id)
                            return el
                        })
                        $scope.route.type = 'user'
                    }

                    if(question.routeToBusinessDirId || question.routeToUserId){
                        if(question.routeToBusinessDirId){
                            if(!$scope.dataForRouting.businessLines.length){
                                _scope.$on("businessLinesLoaded",setBN)
                            }else setBN()                           
                        }else{
                            if(!$scope.dataForRouting.users.length){
                                _scope.$on("usersLoaded", setUser)
                            }else setUser()
                        }
                        $scope.routeExists = true;
                    }
                }

                $scope.loadUsersForBusinessLine = function(){

                    var requestParamsUsers = {fields: "businessDir", businessDirId:$scope.routing.bn[0].id, levels:"REGION"};
                    $scope.bnLoading = true;
                    OrgstructService.getUsers(requestParamsUsers).then(function(result) {
                        $scope.bnLoading = false;
                        if(result.status != 200) {
                            console.log(result)
                            Notifications.danger('Ошибка! Не удается загрузить список пользователей');
                            return
                        }

                        $scope.usersForBusinessLine = result.data;

                      

                    })
                }

                $scope.remove = function(){
                    $scope.save(true)
                }

                $scope.save = function(remove){
                    
                    for(var type in transpail){
                        question[transpail[type]] = (type == $scope.route.type && !remove)?$scope.routing[type][0].id:null
                    }
                    question.loading = true
                    TWQuestionService.saveQuestion(question, function(err, result){
                        question.loading = false
                        ngDialog.close()
                        if(err){
                            console.log(err, result);
                            Notifications.danger("Ошибка сохранения");
                            return;
                        }
                        
                        angular.forEach($scope.questionsBackup, function(questionBackup, index){
                            if(questionBackup.id === question.id) $scope.questionsBackup[index] = result
                        });
                        Notifications.success("Изменения сохранены")
                    })

                }


                //action
                setLoadedBN()
            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })
    }

    $scope.showList = function($event, data, header){
        $event.preventDefault();
        $event.stopPropagation();
        ngDialog.open({
            template: 'listPopup',
            controller: ['$scope', function($scope) {
                $scope.header = header;
                $scope.list = data;
            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })
    }



    $scope.changedRouteRequired = function(wQuestion){
       //wQuestion.routeRequired = !wQuestion.routeRequired;
        wQuestion.routeToUserId = null;
        wQuestion.routeToBusinessDirId = null;

        $scope.questionBusinessLine[wQuestion.id] = null;
        $scope.questionUser[wQuestion.id] = null;

        //$scope.setChanged(wQuestion);
        if (!wQuestion.routeRequired){
            $scope.saveRoute(wQuestion)
        }
    };


    $scope.nodeSelect = function(node){
        $scope.selectedNode = node;
        $scope.selectedNode.nameEdit = $scope.selectedNode.name;
        $scope.selectedNode.weightEdit = $scope.selectedNode.weight;
        $scope.selectedNode.routeRequiredEdit = $scope.selectedNode.routeRequired;
        $scope.questions = undefined;
        if(node.hasQuestions){
            $scope.selectedNode.loading=true;
            PWCategoryService.getQuestions(node.id, $scope.showQuestions)
        }
    };

    $scope.saveName = function(){
        PWCategoryService.renameProcess($scope.selectedNode.id, $scope.selectedNode.nameEdit, function(err, result) {
            if(err){
                Notifications.danger("Ошибка сохранения");
                return;
            }
            $scope.selectedNode.name = $scope.selectedNode.nameEdit;
        })
    };


    $scope.saveWeight = function(){
        PWCategoryService.updateWeightProcess($scope.selectedNode.id, $scope.selectedNode.weightEdit, function(err, result){
            if(err){
                Notifications.danger("Ошибка сохранения");
                return;
            }
            $scope.selectedNode.weight = $scope.selectedNode.weightEdit;
        })
    }

    $scope.showQuestions = function(err, data){
        delete $scope.selectedNode.loading;
        if(err){
            console.log(err, data);
            Notifications.danger("Ошибка загрузки");
            return;
        }

        $scope.questions = data;
        $scope.questionsBackup = angular.copy(data);
    };

    $scope.confirmNodeDeletion = function(){
        var node = $scope.selectedNode;
        ngDialog.open({
            template: 'deleteTreeNode',
            controller: ['$scope', 'ProcessTreeUtils', function($scope, ProcessTreeUtils) {
                $scope.node = node;
                $scope.confirm = function(){
                    PWCategoryService.deleteProcess(_this.selectedNode.id, function(err, result) {
                        if (err) {
                            Notifications.danger("HTTP " + err.code, err.text, 4000);
                            return;
                        }
                        console.log($scope.node);
                        if($scope.node.parentId != null) {
                            var parentNode = getSubMenuItem(_this.dataForTheTree, $scope.node.parentId);
                            parentNode.children = _.without(parentNode.children, node);
                        } else {
                            _this.dataForTheTree = _.without(_this.dataForTheTree, node);
                        }
                        _this.selectedNode = undefined;
                        if(parentNode.children.length == 0){//если нода пустая - меняем её
                            parentNode.type='empty';
                            ProcessTreeUtils.setupIconForNewNode(parentNode)
                        }
                    });
                };
                var getSubMenuItem = function (subMenuItems, id) {
                    if (subMenuItems) {
                        for (var i = 0; i < subMenuItems.length; i++) {
                            if (subMenuItems[i].id == id) {
                                return subMenuItems[i];
                            }
                            var found = getSubMenuItem(subMenuItems[i].children, id);
                            if (found) return found;
                        }
                    }
                };


            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })

    }

    $scope.addNewProcess = function(toRoot){
        var _scope = $scope;

        ngDialog.open({
            template: 'addNewProcess',
            controller: ['$scope', '$timeout', 'ProcessTreeUtils', function($scope, $timeout, ProcessTreeUtils) {
                // $timeout(function() {
                //     angular.element("input.newProcess").focus()
                // }, 0)
                $scope.parent = _scope.selectedNode;
                if(toRoot)$scope.parent = undefined;
                $scope.confirm = function(){
                    var newProcessData = {
                        name:$scope.name,
                        parentId: ($scope.parent && $scope.parent.id)?$scope.parent.id:undefined,
                        active: true
                    }

                    PWCategoryService.addProcess(newProcessData, function(err, data) {
                        if(err){
                            console.log(err)
                            Notifications.danger("Ошибка создания процесса");
                            return
                        }

                        newProcessData.id = data.id;

                        //добавление блока в корень
                        if(!$scope.parent){
                            _this.dataForTheTree.push(ProcessTreeUtils.setupIconForNewNode(data));
                            return;
                        }

                        //если это был пустой блок - необходимо заменть пустой объект детей на массив
                        if(!Array.isArray(_scope.selectedNode.children))_scope.selectedNode.children = [];

                        //добавляем дочерний объект
                        _scope.selectedNode.children.push(ProcessTreeUtils.setupIconForNewNode(data));


                        switch(_scope.selectedNode.icon){
                            case "empty":
                                //добавление в неопределившийся процесс

                                //_scope.selectedNode.type='group'
                                _scope.selectedNodeProcess = ProcessTreeUtils.setupIconForNewNode(_scope.selectedNode)

                            case "folder":
                                //добавление в свёрнутый процесс
                                //вот тут надо развернуть текущую ноду
                                $timeout(function() {
                                   angular.element("span#"+_scope.selectedNode.id).parent().prev().prev().click()
                                }, 0);

                                break;
                            case "folderOpen":
                                //добавление в развёрнутый процесс
                                //не надо ничего
                                break;
                            default:
                                Notifications.success("Случилось что-то весьма странное")
                                break;
                        }

                        Notifications.success("Добавлено")

                        //if()
                        // data.type = 'categorie';
                        // data.icon = 'file-o';
                        // data.children = [];
                        // if(parent.children.length) {
                        //     parent.children.push(data);
                        // }
                        //Uncomment to handle implicit click on el

                        //$timeout(function() {
                        //    var nodeElement = document.getElementById(parent.id);
                        //    angular.element(nodeElement).triggerHandler("click");
                        //}, 0);
                    })
                }

            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })
    }

    $scope.actClones = function(question, action){
        TWQuestionService.getClones(action, question.id, function(err, questions){
            if(err){
                console.log(err, questions)
                Notifications.danger("Ошибка получения данных")
                return;
            }

            _scope = $scope;
            ngDialog.open({
                template: 'actClones',
                controller: ['$scope', 'ProcessTreeUtils', function($scope, ProcessTreeUtils) {
                    $scope.getCheckedNum = function(){
                        if(!questions) return 0;
                        return questions.filter(function(el){return el.checked}).length
                    }
                    $scope.confirm = function(){
                        TWQuestionService.actClones(action, question.id, questions.filter(function(el){return el.checked}).map(function(el){return el.id}), function(err, answer){
                            if(err){
                                console.log(err, answer)
                                Notifications.danger("Ошибка изменения данных")
                                return;
                            }

                            //убираем кнопки
                            if($scope.getCheckedNum() == questions.length){
                                if(action == "relink"){
                                    question.needToRelink = false;
                                }else{
                                    question.needToRename = false;
                                }
                            }

                            Notifications.success("Пере" + ((action == "process")?"привязка прошла":"именование прошло") + " успешно")
                        })
                    }
                    $scope.action = action;
                    $scope.questions = questions

                }],
                className: 'ngdialog-theme-plain dialog-wide',
                showClose: false
            })
        })

    }

    function findById(node, id) {

                        if(node.id && node.id === id ){
                          return node;
                        }
                        if(node.children && node.children.length){
                            var result;
                            for(var i in node.children){
                                result =  findById(node.children[i], id)
                                if(result)return result
                            }
                        }
                        return result;
                    }

    $scope.getSelectedQuestions = function(){
        return $scope.questions && $scope.questions.filter(function(el){return el.selected}).map(function(el){return {id:el.id, name:el.name}})
    }

    $scope.moveQuestions = function(questions){
        _scope = $scope;

        ngDialog.open({
            template: 'moveQuestion',
            controller: ['$scope', 'ProcessTreeUtils', function($scope, ProcessTreeUtils) {
                $scope.possibleToMove = false
                $scope.questionsNames = questions.map(function(el){return el.name});
                var questionsIds = questions.map(function(el){return el.id})
                $scope.nodeSelect = function(node){
                    $scope.selectedNode = node;
                    $scope.possibleToMove = node && (node.type=='categorie' || node.type=='empty')
                }
                $scope.confirm = function(){

                    

                    
                    if(!$scope.selectedNode.hasQuestions){
                                   
                        var selectedNodeInParentTree = findById({children:_this.dataForTheTree}  , $scope.selectedNode.id)

                        if(selectedNodeInParentTree){
                            selectedNodeInParentTree.type='categorie'
                            selectedNodeInParentTree.hasQuestions = true;
                            selectedNodeInParentTree= ProcessTreeUtils.setupIconForNewNode(selectedNodeInParentTree);
                        }
                    }

                    TWQuestionService.move(questionsIds, $scope.selectedNode.id, function(err, data) {
                        ngDialog.closeAll();
                        if(err){

                            Notifications.danger("Ошибка перемещения вопрос"+((questions.length > 1)?"ов":"а"))
                            console.log(err, data)
                            return
                        }




                        _scope.questions = _scope.questions.filter(function(el){return (!~questionsIds.indexOf(el.id)?true:false)})
                        if(_scope.questions.length == 0){ //Удалили все вопросы
                            _scope.selectedNode.type = 'empty';
                            _scope.selectedNode.icon = 'empty';
                            _scope.selectedNode.hasQuestions = false;
                        }
                        Notifications.success("Перемещение успешно")
                    })
                }
            }],
            className: 'ngdialog-theme-plain',
            showClose: false
        })
    }

    $scope.moveProcess = function(){
        _scope = $scope;
        ngDialog.open({
            template: 'moveProcess',
            controller: ['$state', '$scope', 'ProcessTreeUtils', function($state, $scope, ProcessTreeUtils) {
                $scope.process = _scope.selectedNode;

                $scope.nodeSelect = function(node){
                    $scope.selectedNode = node;

                    $scope.possibleToMove = node && (node.type=='group' || node.type=='empty')
                }

                var getSubMenuItem = function (subMenuItems, id) {
                    if (subMenuItems) {
                        for (var i = 0; i < subMenuItems.length; i++) {
                            if (subMenuItems[i].id == id) {
                                return subMenuItems[i];
                            }
                            var found = getSubMenuItem(subMenuItems[i].children, id);
                            if (found) return found;
                        }
                    }
                };

                $scope.confirm = function(){
                    PWCategoryService.updateProcessParent($scope.process.id, $scope.selectedNode.id, function(err, data) {
                        ngDialog.closeAll();
                        if(err){

                            Notifications.danger("Ошибка перемещения вопроса")
                            console.log(err, data)
                            return
                        }
                        //$state.reload();
                        //орисовка нового
                        var selectedNodeInParentTree = findById({children:_scope.dataForTheTree}  , $scope.selectedNode.id)
                        
                        if(selectedNodeInParentTree){

                                   //если это был пустой блок - необходимо заменть пустой объект детей на массив
                            if(!Array.isArray(selectedNodeInParentTree.children))selectedNodeInParentTree.children = [];

                            //добавляем дочерний объект
                            selectedNodeInParentTree.children.push(ProcessTreeUtils.setupIconForNewNode(data));


                            if(selectedNodeInParentTree.icon == 'empty'){
                             //добавление в неопределившийся процесс
                             selectedNodeInParentTree = ProcessTreeUtils.setupIconForNewNode(selectedNodeInParentTree)
                            }
                            if(selectedNodeInParentTree.icon == 'folder'){
                             // $timeout(function() {
                             //    angular.element("span#"+_scope.selectedNode.id).parent().prev().prev().click()
                             // }, 0);
                            }



                        }


               

                        //убивание старого
                    
                        var parentForRemove = findById({children:_scope.dataForTheTree}  , _scope.selectedNode.parentId)
                        var processForRemove = findById({children:_scope.dataForTheTree}  , _scope.selectedNode.id)
                        console.log(parentForRemove)

                        parentForRemove.children = parentForRemove.children.filter(el => el.id != processForRemove.id)
                        console.log(parentForRemove.children)
                        if(!parentForRemove.children.length){
                            //parentForRemove.children = {}
                            parentForRemove.icon = "folder"
                        }
                        
                        _this.selectedNode = undefined;
                        delete nodeForeRemove

                        Notifications.success("Перемещение успешно")
                    })
                }
            }],
            className: 'ngdialog-theme-plain',
            showClose: false
        })
    }

    var scrollTo = function(elId){
        //$("#"+elId).addClass("unfilled")        
        $('div.scrollable').scrollTop(0)
        $('div.scrollable').animate({
            scrollTop: $("#"+elId).offset().top-160
        }, 1000);
    }

    $scope.addNewQuestion = function(){

        var processId = $scope.selectedNode.id;
        var processName = $scope.selectedNode.name;
        ngDialog.open({
            template: 'addNewQuestion',
            controller: ['$scope', 'ProcessTreeUtils', function($scope, ProcessTreeUtils) {
                $scope.processName = processName;
                $scope.confirm = function(clear){
                    $scope.loading = true;
                    PWCategoryService.addQuestion(processId, {name:$scope.name}, function(err, data){


                        if(err){
                            $scope.loading = false;
                            Notifications.danger("Ошибка создания вопроса");
                            return;
                        }

                        data.hint = $scope.hint;
                        TWQuestionService.saveQuestion(data, function(err, data){
                            $scope.loading = false;
                            if(err){
                                Notifications.warinig("Ошибка добавления подсказки в вопрос");
                                console.log(err, data)
                            }





                            if(clear){
                                $scope.hint = "";
                                $scope.name = "";
                            }

                            if(!_this.questions)_this.questions = [];
                            _this.questions.push(data);
                            _this.questionsBackup.push(data);
                            setTimeout(function(){
                                scrollTo(data.id)
                            }, 100)
                            

                            if(_this.selectedNode.children.length == 0) {
                                _this.selectedNode.type = 'categorie';
                                _this.selectedNode.hasQuestions = true;
                                _this.selectedNode = ProcessTreeUtils.setupIconForNewNode(_this.selectedNode);
                            }

                           console.log(_this.selectedNode)

                        })


                    })
                }

            }],
            className: 'ngdialog-theme-plain dialog-wide',
            showClose: false
        })
    };


    $scope.removeQuestions = function(questions){
        var _scope = $scope;
        ngDialog.open({
            template: 'deleteQuestion',
            controller: ['$scope', 'ProcessTreeUtils', function($scope,  ProcessTreeUtils) {
                $scope.questionsNames = questions.map(function(el){return el.name});
                var questionsIds = questions.map(function(el){return el.id})
                $scope.confirm = function(){
                    TWQuestionService.remove(questionsIds, function(err, data){
                        if(err){
                            Notifications.danger("Ошибка удаления вопрос"+((questions.length > 1)?"ов":"а"))
                            return
                        }
                        _scope.questions = _scope.questions.filter(function(el){return (!~questionsIds.indexOf(el.id)?true:false)})
                        if(_scope.questions.length == 0){ //Удалили все вопросы
                            _scope.selectedNode.type = 'empty';
                            _scope.selectedNode.icon = 'empty';
                            _scope.selectedNode.hasQuestions = false;
                        }
                    })

                }

            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })
    }

    $scope.saveQuestionName = function(question){
        question.loading = true;
        var tQuestion = angular.copy(question)
        tQuestion.name = tQuestion.nameEdit
        TWQuestionService.saveQuestion(tQuestion, function(err, result){
            delete question.loading
            if(err){
                console.log(err, result)
                Notifications.danger("Ошибка сохранения")
                return;
            }
            question.name = question.nameEdit;
            question.needToRename = true;
        })
    }

    $scope.saveQuestionRoute = function(question){
        question.loading = true;
        var tQuestion = angular.copy(question)
        tQuestion.route = tQuestion.routeEdit
        TWQuestionService.saveQuestion(tQuestion, function(err, result){
            delete question.loading
            if(err){
                console.log(err, result)
                Notifications.danger("Ошибка сохранения")
                return;
            }
            question.route = question.routeEdit;
            question.needToRename = true;
        })
    }

    $scope.saveQuestionHint = function(question){
        question.loading = true;
        var tQuestion = angular.copy(question);
        tQuestion.hint = tQuestion.hintEdit;
        TWQuestionService.saveQuestion(tQuestion, function(err, result){
            delete question.loading
            if(err){
                console.log(err, result)
                Notifications.danger("Ошибка сохранения")
                return;
            }
            question.hint = question.hintEdit;
            question.needToRename = true;
        })
    }

    $scope.saveAllChanges = function(question) {
        var savingQuestion = {};
        question.loading = true;
        savingQuestion = angular.copy(question)
        savingQuestion.name = savingQuestion.nameEdit;
        savingQuestion.hint = savingQuestion.hintEdit;
        savingQuestion.route = savingQuestion.routeEdit;
        TWQuestionService.saveQuestion(savingQuestion, function(err) {
            delete question.loading;
            if (err) {
                Notifications.danger('Ошибка сохранения');
                return;
            }
            question.name = question.nameEdit;
            question.hint = question.hintEdit;
            question.needToRename = true;
        });
    };

    $scope.checkQuestionChanges = function(question) {
        var copiedQuestions = angular.copy($scope.questions);
        var initialQuestion = _.find(copiedQuestions, function(questionItem) {
            return questionItem.id === question.id;
        });
        initialQuestion.nameEdit = initialQuestion.name;
        initialQuestion.hintEdit = initialQuestion.hint;
        initialQuestion.routeEdit = initialQuestion.route;

        return angular.equals(question, initialQuestion);
    };
});
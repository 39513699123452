var PanelBusinessLineService;

PanelBusinessLineService = function($http, config) {
    return {
        getAllBusinessDirs: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/business-dirs', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getBusinessDir: function (id, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/business-dirs/' + id, {
                noThrobber: hasNoThrobber
            });
        },
        createBusinessDir: function (params) {
            return $http.post(config.backendOrgstruct + '/business-dirs', params);
        },
        updateBusinessDir: function (id, params) {
            return $http.put(config.backendOrgstruct + '/business-dirs/' + id, params);
        },
        deleteBusinessDir: function (id) {
            return $http.delete(config.backendOrgstruct + '/business-dirs/' + id);
        },
        getUsersBusinessDir: function (idBL, params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/business-dirs/' + idBL + '/users/mixed', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },

        addUserInBusinessDir: function (idBL, userIds) {
            return $http.post(config.backendOrgstruct + '/business-dirs/' + idBL + '/users/' + userIds);
        },

        deleteUserFromBusinessDir: function (idBL, userIds) {
            return $http.delete(config.backendOrgstruct + '/business-dirs/' + idBL + '/users/' + userIds);
        }
    }
};

angular.module('smartApp').factory('PanelBusinessLineService', PanelBusinessLineService);

PanelBusinessLineService.$inject = ['$http', 'config'];
function setVisible(element) {element.removeClass('hidden');}
function setHidden(element) {element.addClass('hidden');}

angular.module('smartApp')
    .directive('hasAnyRole', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.isInAnyRole(roles);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    roles = attrs.hasAnyRole.replace(/\s+/g, '').split(',');

                if (roles.length > 0) {
                    defineVisibility(true);
                }
            }
        };
    }])
    .directive('hasRole', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.isInRole(role);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    role = attrs.hasRole.replace(/\s+/g, '');

                if (role.length > 0) {
                    defineVisibility(true);
                }
            }
        };
    }])
    .directive('notInRole', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                function defineVisibility(reset) {
                    if (reset) {setVisible(element);}
                    var result = !Principal.isInRole(role);
                    if (result) {setVisible(element);}
                    else {setHidden(element);}
                }
                var role = attrs.notInRole.replace(/\s+/g, '');
                if (role.length > 0) {
                    defineVisibility(true);
                }
            }
        };
    }])
	.directive('notInRoles', ['Principal', function (Principal) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				function defineVisibility(reset) {
					if (reset) {setVisible(element);}
					var result = !Principal.isInAnyRole(roles);
					if (result) {setVisible(element);}
					else {setHidden(element);}
				}
				var roles = attrs.notInRoles.replace(/\s+/g, '').split(',');
				if (roles.length > 0) {
					defineVisibility(true);
				}
			}
		};
	}]);

'use strict';

angular.module('smartApp').factory('TemplateService', function TemplateService(Notifications, $http, config, HttpUtils) {

    var activeTemplate;
    var templates;

    return {
        getTemplateByName: function(templateName) {
            var query = HttpUtils.getQuery('/template/getByName', {templateName: templateName});
            return $http.get(query)
                .success(function(data) {
                    activeTemplate = data;
                    return data;
                });
        },
        getAllAssignedTo: function() {
            return $http.get(HttpUtils.getQuery('/objects/templates'));
        },
        getAllAssignedGroupShops: function(templateId) {
            return $http.get(config.backend + '/template/getAllAssignedGroupShops?assignedTo=' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        getTemplateById: function (templateId) {
            return $http.get(config.backend + '/template/getById?templateId=' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        getTemplateByIdNew: function (templateId) {
            return $http.get(config.backend + '/template/' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        deleteTemplate: function(template) {
            return $http.post(config.backend + '/template/deleteTemplate', template);
        },
        getAll: function() {
            return $http.get(config.backend + '/template/getAll')
                .success(function(data) {
                    templates = data;
                    return data;
                });
        },
        getAllAccreditated: function(cb) {
            $http.get(config.backend + "/objects/templates/accreditated").success(function (data) {
                return cb(data);
            });
        },
        getAll2: function(params) {
            return $http.get(config.backend + '/admin/templates', {params: params}).success(function(data) {
                    templates = data;
                    return data;
                });
        },
        getCategoriesOfTemplate: function(templateIds) {
          templateIds = templateIds.join(',');
          return $http.get(config.backend + '/categories/forChecklists/' + templateIds);
        },
        getQuestionsOfCategory: function(categories) {
            var categoryIds = categories.map(function(elem){
                return elem.id;
            }).join(",");
            return $http.get(config.backend + '/admin/categories/' + categoryIds + '/questions');
        },
        getOnlyDM: function() {
            return $http.get(config.backend + '/admin/templates/onlyDm')
                .success(function(data) {
                    templates = data;
                    return data;
                });
        },
        getTemplates: function() {
            return templates;
        },
        getActiveTemplate: function() {
            return activeTemplate;
        },
        saveTemplate: function(template) {
            return $http.post(config.backend + '/template/saveTemplate', template)
                .success(function(data, status ) {
                    if(status != 200){
                        Notifications.danger("Ошибка сохранения");
                        return false
                    }
                    return data;
                });
        }
    }

});

<script setup>
import { Accordion } from '@eron/ui-kit';
import { computed } from 'vue';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value']);

const expanded = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:model-value', value);
    },
});
</script>

<template>
    <accordion
        :id="id"
        v-model:show-content="expanded"
        :title="title"
        :class="{ 'fpr-expansion-panel--expanded': expanded }"
        class="fpr-expansion-panel"
    >
        <slot name="default"></slot>
    </accordion>
</template>

<style lang="scss">
.fpr-expansion-panel {
    .component-accordion__header {
        padding-bottom: 16px;
        color: var(--u-green);
        border-bottom: 1px solid #e9ede1;
    }

    .component-accordion__content {
        margin-top: 16px;
    }
}
</style>

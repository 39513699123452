'use strict';

angular.module('smartApp').controller('ActFullViewController', ActFullViewController);

ActFullViewController.$inject = ['$scope', '$state', '$stateParams', 'ngDialog', 'ImageModal', 'InspectionActService', 'Notifications'];

function ActFullViewController($scope, $state, $stateParams, ngDialog, ImageModal, InspectionActService, Notifications) {
    var uid = $stateParams.uid;

    if (!uid) {
        $state.go('inspectionActs');
        return;
    }

    $scope.goToInspectionActs = function() {
        $state.go('inspectionActs');
    };

    $scope.openPhotosDialog = function(task) {
        ngDialog.open({
            showClose: true,
            closeByNavigation: true,
            template: 'openPhotosDialog',
            controller: ['$scope', function($scope) {
                $scope.task = task;

                $scope.openPhoto = function(url) {
                    ImageModal.open(url);
                }
            }]
        })
    };

    function getInspectionAct(uid) {
        InspectionActService.getInspectionAct(uid).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки акта проверки');
                return;
            }

            $scope.inspectionAct = result.data;
            $scope.actTasks = [];
            var listsOfTasks = result.data.sections.map(section => section.tasks.map(task => task));
            listsOfTasks.forEach(task => {
                $scope.actTasks = $scope.actTasks.concat(task);
            });
        });
    }

    getInspectionAct(uid);
}

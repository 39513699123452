<template>
    <div>
        <banner-modal v-model="isAttachmentModalOpened" title="Attachments">
            <banner-attachment-list
                class="attachments"
                :attachment-object="attachmentObject"
                :busy="fetchAttachmentsStatus"
                @attachment-deleted="handleAttachment(attachmentObject.id)"
                @attachment-added="handleAttachment(attachmentObject.id)"
            ></banner-attachment-list>
        </banner-modal>
        <banner-modal v-model="isTaskModalOpen" title="Поиск задачи по ID">
            <div class="search-task-wrapper mb-3">
                <custom-input
                    id="search-task-id"
                    v-model="taskSearchString"
                    class="search-task-input"
                    placeholder="Введите ID задачи"
                ></custom-input>
                <custom-button
                    :disabled="taskSearchString.length === 0"
                    text="Поиск"
                    variant="grey"
                    @click="handleSearchTask"
                >
                </custom-button>
            </div>

            <div class="task-search-result">
                <custom-table
                    id="search-tasks"
                    class="search-task-table"
                    :busy="taskSearchFetchStatus"
                    :columns="taskSearchTableCols"
                    :items="taskSearchTableItems"
                    :no-data-message
                >
                    <template #select="{ item, row }">
                        <custom-button
                            text="Выбрать"
                            variant="grey"
                            @click="changeItemTaskId(row)"
                        >
                        </custom-button>
                    </template>
                    <template #createDate="{ item, row }">
                        <p>{{ formattedTaskCreateDate(item.value) }}</p>
                    </template>
                </custom-table>
            </div>
        </banner-modal>

        <div class="banner-content-item">
            <div class="row">
                <div class="col">
                    <label>Платформа</label>
                    <custom-input id="banner-platform" v-model="item.platform" disabled></custom-input>
                </div>

                <div class="col">
                    <label>Баннер блок</label>
                    <custom-input id="banner-block" v-model="item.description" disabled></custom-input>
                </div>

                <div class="col align-self-end">
                    <custom-button
                        variant="grey"
                        text="Добавить контент"
                        @click="handleAddRow"
                    >
                    </custom-button>
                </div>
            </div>

            <br>
            <br>

            <div class="row">
                <div class="col-12">
                    <custom-table
                        id="banner-content-items"
                        :columns="tableFields"
                        :items="localItems"
                    >
                      <template #startDate="{ row, item: { value } }">
                        <custom-datepicker
                          :model-value="value"
                          teleport-center
                          display-year
                          auto-position
                          @update:model-value="onInputChange(row, $event, 'startDate')"
                        ></custom-datepicker>
                      </template>

                      <template #finishDate="{ row, item: { value } }">
                        <custom-datepicker
                          :model-value="value"
                          teleport-center
                          display-year
                          auto-position
                          @update:model-value="onInputChange(row, $event, 'finishDate')"
                        ></custom-datepicker>
                      </template>

                      <template #link="{ row }">
                        <div class="content-item-select-wrapper">
                          <single-select
                            :id="`link-select-${row.id}`"
                            :model-value="row.linkType"
                            class="content-item-select"
                            :options="linkOptions"
                            :clearable="false"
                            @update:model-value="onInputChange(row, $event, 'linkType')"
                          ></single-select>

                          <custom-input
                            v-if="row.linkType === 'externalLink'"
                            id="external-link"
                            :model-value="row.link"
                            class="content-item-link"
                            @update:model-value="onInputChange(row, $event, 'link')"
                          ></custom-input>

                          <custom-input
                            v-else
                            id="task-link"
                            :model-value="row.taskId"
                            class="content-item-link"
                            readonly
                            @update:model-value="onInputChange(row, $event, 'taskId')"
                          >
                            <template #postfix>
                                <icon-plus
                                    v-if="row.taskId"
                                    @click="handleDeleteTaskId(row)"
                                >
                                </icon-plus>
                            </template>
                          </custom-input>

                          <custom-button
                            v-if="row.linkType === 'taskLink'"
                            text="Выбрать задачу"
                            variant="grey"
                            @click="handleOpenTaskModal(row)"
                          >
                          </custom-button>
                        </div>
                      </template>

                      <template #switchTime="{ row, item: { value } }">
                        <custom-input
                            id="switch-time"
                            :model-value="value"
                            @update:model-value="onInputChange(row, $event, 'switchTime')"
                        ></custom-input>
                      </template>

                      <template #value="{ row, item: { value } }">
                        <custom-input
                            id="value"
                            :model-value="value"
                            @update:model-value="onInputChange(row, $event, 'value')"
                        ></custom-input>
                      </template>

                      <template #mediaLink="{ row }">
                        <template v-if="row.mediaLink && row.mediaLink.length !== 0">
                          <div class="image-wrapper">
                            <span class="delete-image-icon" @click="handleDeleteImg(row)"></span>
                            <img
                              class="image-preview"
                              :src="row.mediaLink ? row.mediaLink : row.value"
                              alt="Image"
                              @click="openImgModal(row)">
                          </div>
                        </template>
                        <template v-else>
                          <file-uploader
                            id="banner-content-image"
                            @upload="handleFileUpload($event, row)"
                          ></file-uploader>
                        </template>
                      </template>

                      <template #attachments="{ row }">
                        <custom-button
                            :text="`Вложения (${row.attachmentQty})`"
                            variant="grey"
                            v-if="row.id"
                            @click="openAttachments(row)"
                        >
                        </custom-button>
                      </template>

                      <template #delete="{ row }">
                        <custom-button
                            text="X"
                            variant="white"
                            class="delete-row-button"
                            @click="handleDeleteRow(row)">
                        </custom-button>
                      </template>

                      <template #save="{ row }">
                        <custom-button
                            text="Сохранить"
                            v-if="row.isModified"
                            @click="handleSaveRow(row)">
                        </custom-button>
                      </template>
                    </custom-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import isEqual from '@/helpers/isEqual';
import formatDate from '@/helpers/formatDate';
import { fileToBase64 } from '@/helpers/fileToBase64';

// Components
import BannerAttachmentList from './BannerAttachmentList.vue';
import BannerModal from "./BannerModal.vue";
import {
    CustomButton,
    CustomDatepicker,
    CustomInput,
    CustomTable,
    FileUploader,
    Modal,
    SingleSelect,
    IconPlus, Loader,
} from '@eron/ui-kit';
import TasksGenerateRules from "../tasks-generate/editor/TasksGenerateRules.vue";
import TasksGenerateTaskData from "../tasks-generate/editor/TasksGenerateTaskData.vue";
import TasksGenerateExecutors from "../tasks-generate/editor/TasksGenerateExecutors.vue";

export default {
    name: 'BannerContentItem',
    components: {
        TasksGenerateExecutors, TasksGenerateTaskData, TasksGenerateRules, Loader,
        BannerModal,
        CustomInput,
        CustomButton,
        SingleSelect,
        CustomDatepicker,
        FileUploader,
        Modal,
        CustomTable,
        BannerAttachmentList,
        IconPlus,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        bannerId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            localItems: [],
            imgModalLink: null,
            attachmentObject: [],
            linkType: null,
            isTaskModalOpen: false,
            taskSearchString: '',
            taskSearchTableItems: [],
            isAttachmentModalOpened: false,
            editingItem: '',
            taskSearchTableCols: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'description',
                    label: 'Описание',
                },
                {
                    key: 'authorName',
                    label: 'Автор',
                },
                {
                    key: 'createDate',
                    label: 'Дата создания',
                },
                {
                    key: 'select',
                    label: '',
                },
            ],
            linkOptions: [
                {
                    label: 'Ссылка на задачу',
                    value: 'taskLink',
                },
                {
                    label: 'Сторонняя ссылка',
                    value: 'externalLink',
                },
            ],
        };
    },
    methods: {
        handleDeleteTaskId(item) {
            const detectedItem = this.localItems.find(_item => _item.id === item.id);
            detectedItem.taskId = null;
            this.onInputChange(detectedItem);
        },
        handleOpenTaskModal(item) {
            this.testID = item.id;
            this.isTaskModalOpen = true;
        },
        changeItemTaskId(item) {
            const detectedItem = this.localItems.find(item => item.id === this.testID);
            detectedItem.taskId = item.id;
            this.onInputChange(detectedItem);
            this.taskSearchTableItems = [];
            this.isTaskModalOpen = false;
            this.taskSearchString = '';
        },
        formattedTaskCreateDate(value) {
            return formatDate(new Date(value));
        },
        handleSearchTask() {
            this.taskSearchTableItems = [];
            this.$store.dispatch('banners/fetchBannerLinkTask', this.taskSearchString).then((result) => {
                this.taskSearchTableItems.push(result);
            });
        },
        openImgModal(row) {
            window.open(row.mediaLink, '_blank');
        },
        onInputChange(item, e, prop) {
            this.editingItem = this.localItems.find(localItem => localItem.id === item.id);
            this.editingItem[prop] = e;

            const fieldsToCompare = ['id', 'mediaLink', 'startDate', 'finishDate', 'link', 'switchTime', 'value', 'taskId'];
            const currentItem = {};
            const originalItem = {};

            fieldsToCompare.forEach(field => {
                currentItem[field] = this.editingItem[field];
                originalItem[field] = this.editingItem._original[field];
            });

            this.editingItem.isModified = !isEqual(currentItem, originalItem);
        },
        handleDeleteImg(row) {
            const detectedItem = this.localItems.find(_item => _item.id === row.id);
            detectedItem.mediaLink = [];
            this.onInputChange(detectedItem);
        },
        async handleFileUpload(files, row) {
            const detectedItem = this.localItems.find(_item => _item.id === row.id);
            const base64 = await fileToBase64(files[0]);
            detectedItem.base64 = base64
            detectedItem.mediaLink = 'data:image/png;base64, ' + base64;
            this.onInputChange(detectedItem);
        },
        async handleSaveRow(tableRow) {
            const itemForSave = this.localItems.find(item => item.id === tableRow.id);
            const linksObj = {
                link: null,
                taskId: null,
            };
            if (tableRow.linkType === 'taskLink') {
                linksObj.link = null;
                linksObj.taskId = itemForSave.taskId;
            } else {
                linksObj.link = itemForSave.link;
                linksObj.taskId = null;
            }
            const payloadObject = {
                contentId: itemForSave.id,
                bannerId: this.bannerId,
                blockId: this.item.id,
                blockName: this.item.name,
                contentType: this.item.name === 'pinnedtext' ? 'TEXT' : 'MEDIA',
                content: this.item.name === 'pinnedtext' ? itemForSave.value : itemForSave.base64,
                link: linksObj.link,
                taskId: linksObj.taskId,
                switchTime: Number(itemForSave.switchTime),
                startDate: itemForSave.startDate,
                finishDate: itemForSave.finishDate,
            };

            if (this.item.name !== 'pinnedtext' && itemForSave.mediaLink === itemForSave._original.mediaLink) {
                payloadObject.contentType = 'MEDIA';
                delete payloadObject.content;
            }

            this.$store.dispatch('banners/fetchBannerBlockUpdate', payloadObject).then((savedData) => {
                Object.assign(itemForSave, savedData);
                itemForSave._original = { ...itemForSave };
                itemForSave.isModified = false;
                this.$emit('update-item', {
                  content: this.localItems.map(localItem => ({ ...localItem })),
                });
            });
        },
        handleAddRow() {
            const newItem = {
                mediaLink: [],
                startDate: '',
                finishDate: '',
                link: '',
                switchTime: 5,
                isModified: false,
                _original: {},
            };
            this.localItems.push(newItem);
        },
        handleDeleteRow(row) {
            if (row.id) {
                let isConfirmed = confirm('Вы уверены, что хотите удалить запись?');
                if (isConfirmed) {
                    this.$store.dispatch('banners/fetchBannerBlockDelete', row.id).then(() => {
                        this.localItems.splice(row.index, 1);
                        this.item.content = this.localItems.map(localItem => ({ ...localItem }));
                    });
                }
            } else {
                this.localItems.splice(row.index, 1);
                this.item.content = this.localItems.map(localItem => ({ ...localItem }));
            }
        },
        openAttachments(row) {
            this.isAttachmentModalOpened = true;
            this.attachmentObject = {
                id: row.id,
                attachments: [],
            };
            if (row.attachmentQty > 0) {
                this.handleAttachment(row.id);
            }
        },
        handleAttachment(id) {
            this.$store.dispatch('banners/fetchContentAttachments', id).then((result) => {
                this.attachmentObject.attachments = result;
                const detectedItem = this.localItems.find(_item => _item.id === id);
                detectedItem.attachmentQty = result.length;
            });
        },
    },
    computed: {
        ...mapGetters('banners', {
            taskSearchFetchStatus: 'getTaskSearchFetchStatus',
            taskSearchFetchError: 'getTaskSearchFetchError',
            fetchAttachmentsStatus: 'getFetchAttachmentsStatus',
        }),
        tableFields() {
            let fields = [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'mediaLink',
                    label: 'Медиа-файл',
                },
                {
                    key: 'startDate',
                    label: 'Дата начала',
                },
                {
                    key: 'finishDate',
                    label: 'Дата окончания',
                },
                {
                    key: 'link',
                    label: 'Ссылка',
                },
                {
                    key: 'switchTime',
                    label: 'Время отображения (секунд)',
                },
                {
                    key: 'attachments',
                    label: 'Вложения',
                },
                {
                    key: 'save',
                    label: '',
                },
                {
                    key: 'delete',
                    label: '',
                },
            ];
            if (this.item.name === 'pinnedtext') {
                fields[1].key = 'value';
                fields[1].label = 'Текст';
            } else {
                fields[1].key = 'mediaLink';
                fields[1].label = 'Медиа-файл';
            }

            return fields;
        },
    },
    watch: {
        'item.content': {
            handler(newContent) {
                this.localItems = newContent.map(newItem => ({
                    ...newItem,
                    linkType: newItem.taskId ? this.linkOptions[0].value : this.linkOptions[1].value,
                    isModified: false,
                    _original: { ...newItem },
                }));
            },
            immediate: true,
        },
        editingItem: {
            handler(newValue) {
                const today = new Date();
                if (!this.editingItem.startDate && newValue.finishDate < today) {
                    this.editingItem.finishDate = today;
                }
                this.$nextTick(() => {
                    if (newValue.startDate > new Date(this.editingItem.finishDate)) {
                        this.editingItem.finishDate = newValue.startDate;
                    }
                    if (this.editingItem.startDate && new Date(this.editingItem.startDate) > newValue.finishDate) {
                        this.editingItem.finishDate = this.editingItem.startDate;
                    }
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.modal {
    display: flex !important;
}

.side-panel {
    width: 550px !important;

    .modal__body {
        flex-grow: 1;
    }

    .modal__content-inner {
        border-radius: 0;
    }

    .modal__header,
    .modal__body,
    .modal__footer {
        padding-left: 28px !important;
        padding-right: 28px !important;
    }

    .modal__header svg {
        right: 28px;
    }
}
</style>

<style scoped lang="scss">
.delete-task-id-icon {
    transform: rotate(45deg);
}

.search-task-table, .attachments {
    min-width: 70vw;
}

.search-task-wrapper {
    display: flex;
    flex-flow: row;

    .search-task-input {
        margin-right: 15px;
        min-width: 40%;
        width: fit-content;
    }
}

.content-item-select-wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 8px;

    .content-item-link {
        flex-grow: 0;
        flex-shrink: 0;
        width: fit-content;
    }

    .content-item-select {
        min-width: 100px;
        flex-grow: 1;
        flex-shrink: 0;
    }
}

:deep(.component-input_readonly .component-input__input) {
    padding: 8px;
    font-size: 1rem;
}

:deep(.table.b-table) {
    tbody {
        tr:last-of-type {
            border-bottom-color: transparent;
        }
    }
}

.image-wrapper {
    position: relative;
}

.delete-image-icon {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;

    &:before, &:after {
        content: "";
        position: absolute;
        width: 18px;
        height: 3px;
        background: #363636;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.banner-content-item {
    margin: 60px 0;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    .delete-row-button {
        color: darkred;
        background: transparent;
        border: none;
    }
}

.image-preview {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
</style>

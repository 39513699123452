angular.module('smartApp')
    .controller('RecountTaskController', ['$scope', '$stateParams', '$state', 'TaskListServiceHttp', 'Notifications', function(
        $scope,
        $stateParams,
        $state,
        TaskListServiceHttp,
        Notifications
    ) {
    if (!$stateParams.id) {
        $state.go('tasks');
        return;
    }

    const responseId = $stateParams.id;

    $scope.response = {};
    $scope.task = {};
    $scope.goods = [];

    $scope.getRowClassName = function(row) {
        const hasValue = val => val || val === 0;

        if (hasValue(row.inStock) && hasValue(row.inShop)) {
            return row.total !== row.leftover ? 'red-row' : 'green-row';
        } else if (hasValue(row.inStock) || hasValue(row.inShop)) {
            return 'yellow-row';
        }

        return '';
    };

    TaskListServiceHttp
        .getResponse(responseId)
        .then(function(resp) {
            if (resp.status !== 200) {
                Notifications.danger('Произошла ошибка при загрузке товаров');
                $state.go('tasks');
                return;
            }

            $scope.response= resp.data;
            $scope.goods = resp.data.goods;

            getTaskInfo(resp.data.taskId);
        });

    function getTaskInfo(id) {
        TaskListServiceHttp
            .getTask(id)
            .then(function(resp) {
                if (resp.status !== 200) {
                    Notifications.danger('Произошла ошибка при загрузке задачи');
                    $state.go('tasks');
                    return;
                }

                $scope.task = resp.data;
            });
    }
}]);

import axios from 'axios'
import xlsxMaker from "../../../helpers/excel";
export default {
    namespaced: true,
    state: {
        errorsItems: [],
        errorsPagination: {},
        errorsFetchStatus: false,
        excelFetchStatus: false,
    },
    mutations: {
        setErrorsItems(state, payload) {
            state.errorsItems = payload;
        },
        setErrorsPagination(state, payload) {
            state.errorsPagination = payload;
        },
        setErrorsFetchStatus(state, payload) {
            state.errorsFetchStatus = payload;
        },
        resetPagination(state) {
            state.errorsFetchStatus = {};
        },
        setExcelFetchStatus(state, payload) {
            state.excelFetchStatus = payload;
        },
    },
    actions: {
        fetchItems({ commit }, payload) {
            commit('setErrorsFetchStatus', true);
            return new Promise((resolve) => {
                axios.post(`/admin/error/search`, {
                        ...payload,
                    },
                    {
                        headers: {

                            "access-token": JSON.parse(localStorage.getItem('ls.token')).token,

                        }
                    }).then(response => {
                    resolve(response);
                    commit('setErrorsItems', response.data.data);
                    commit('setErrorsPagination', response.data.paginator)
                }).catch(error => {
                    if (error.request.status === 400) {
                        commit('setErrorsItems', []);
                    }
                    console.error('Error', error);
                }).finally(() => {
                    commit('setErrorsFetchStatus', false)
                })
            })
        },
        fetchExcelExport({ commit }, payload) {
            commit('setExcelFetchStatus', true);
            return new Promise((resolve) => {
                axios.post(`/api/reports/stock/errors/export-to-excel`, {
                    ...payload,
                }, {
                    responseType: 'blob',
                    headers: {
                        "x-auth-token": JSON.parse(localStorage.getItem('ls.token')).token,
                        "Content-Type": 'application/json',
                    }
                }).then(response => {
                    resolve(response);
                    xlsxMaker(response,'errors-result');
                }).catch(error => {
                    console.error('Error', error);
                }).finally(() => {
                    commit('setExcelFetchStatus', false);
                })
            })
        },
    },
    getters: {
        getItems(state) {
            return state.errorsItems;
        },
        getPagination(state) {
            return state.errorsPagination;
        },
        getItemsFetchStatus(state) {
            return state.errorsFetchStatus;
        },
        getExcelFetchStatus(state) {
            return state.excelFetchStatus;
        },
    },
};
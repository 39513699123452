angular.module('smartApp').factory('Xfilter9', checkXreportFactory9);
checkXreportFactory9.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory9(ChecklistService, DownloadService) {
	return {
		id: 14,
		title: 'Отчёты',
		resultTitle: 'Результаты отчета Блоки-подблоки-процессы',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter9/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter9/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		load: function(filters) {
			var t = ChecklistService.getXreport9(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport9(filters);
		}
	};

}
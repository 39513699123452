'use strict';

angular.module('smartApp').controller('Dashboard3Controller', function(
	$filter,
	$scope,
	ngDialog,
	TaskListServiceHttp,
	$state,
	Principal,
	moment,
	OrgstructService,
	ObjectServiceForTemplates,
	Dashboard3Service,
	Notifications,
	localStorageService,
	ShopService,
	TWTemplateService,
	Pagination,
	SystemParamsService,
	TaskNewService,
	TasksTemplateService,
	projectConfig,
	ChecklistService,
	MyResponsibilityFilter) {
	let filterByMyResponsibility;

	$scope.daysForCalendar = [];
	$scope.checklistTemplates = [];
	$scope.ordinateTypes = Dashboard3Service.getOrdinateTypes()
	$scope.activeOrdinateType = $scope.ordinateTypes[0]
	$scope.search = {string: ""}
	$scope.systemProps = [];
	$scope.lastTenList = {
		taskTypes: [],
		taskTemplates: [],
		checklists: []
	}
    $scope.account = Principal.getIdentity();
	$scope.dataLoading = false;
	$scope.open = function($event) {
		$event.preventDefault();
		$event.stopPropagation();

		$scope.opened = true;
	};

	$scope.dateOptions = {
		formatYear: 'yy',
		startingDay: 1,
	};

	$scope.isInspector = Principal.isInRole('ROLE_INSPECTOR');
	$scope.isRegManager = Principal.isInRole('ROLE_REG_MANAGER');

	$scope.MAX_TASKS_PER_DAY = 3

	$scope.pagination = Pagination.get(function(data) {
      this.viewPort = data;
    });
	$scope.localLang =  {
            selectAll       : "Выбрать",
            selectNone      : "Отменить",
            reset           : "Сбросить",
            search          : "Поиск...",
            nothingSelected : "Ничего не выбрано"
        }

	$scope.filters = {

		viible: false,
		data: {
			divisions:undefined,
			clusters:undefined
		},
		empty:{
			divisions:[],
			clusters:[],
		},
		selected:{
			divisions:[],
			clusters:[],
			onlyMyResponsibility: false,
		},
		onchange: {
			onlyMyResponsibility: function() {
				const value = $scope.filters.selected.onlyMyResponsibility;
				const result = filterByMyResponsibility(value)
				$scope.filters.data.divisions = result.divisions;
				$scope.filters.data.clusters = result.clusters;
			}
		},
		use: function(){
			localStorageService.set('dashboard:filter:onlyMyResponsibility', this.selected.onlyMyResponsibility)
			$scope.getData()
			this.visible = false;
		},
		clear: function(){
			for(var i in this.data){
				this.data[i] = this.data[i].map(el => {
					delete el.ticked
					return el
				})
			}
			this.selected.onlyMyResponsibility = false;
			this.onchange.onlyMyResponsibility();
			this.use()
		},
		loadData: function(callback) {
			var _self = this
			async.parallel([
				Dashboard3Service.getClustersList,
				Dashboard3Service.getDivisionsList
			],
			function(err, results) {
				if (err) {
					Notifications.danger('Ошибка загрузки фильтров');
					callback && callback(err)
					return;
				}

				_self.data.clusters = results[0];
				_self.data.divisions = results[1];
				filterByMyResponsibility = MyResponsibilityFilter(_self.data.divisions, _self.data.clusters);
				callback && callback(null, results)
			})
		},
		switchVisibility: function(){
			this.visible = !this.visible
		},
		outsideClick: function(){
			if(angular.element(".checkboxLayer.show:visible").length)return;
			this.visible = false
		},
		hasFiltersActive: function(){
			for(var i in this.selected){
				if (typeof this.selected[i] === 'boolean') return this.selected[i];
				if(this.selected[i].length)return true;
			}
			return false
		}

	}

	var genGroup = function(type){
		if(type=="PHOTO_REPORT"){
			return "COMMON"
		}

		return type;
	};

	var insertTaskToDashboard = function(newTasksArr){
		var activeOrdinateTypeIndex = $scope.ordinateTypes.findIndex(x => x.val==$scope.activeOrdinateType.val);
		var ordType = $scope.ordinateTypes[activeOrdinateTypeIndex].val;
		var ordinateData = $scope.ordinateTypes[activeOrdinateTypeIndex].data;
		for(var i = 0; i < newTasksArr.length; i++){
			var newTask = newTasksArr[i]
			for(var k = 0; k < ordinateData.length; k++){
				var curOrd = ordinateData[k];
				if(newTask[ordType.substring(0, ordType.length -1) + "Id"] == curOrd.id){
					var newTaskDate = moment(newTask.createDate).format("YYYY-MM-DD");
					newTask.group = genGroup(newTask.type);

					if(!$scope.tasks[curOrd.id][newTaskDate])$scope.tasks[curOrd.id][newTaskDate] = []
					$scope.tasks[curOrd.id][newTaskDate].push(newTask)

					if(!$scope.tasks[curOrd.id][newTaskDate+'perGroup'])$scope.tasks[curOrd.id][newTaskDate+'perGroup'] = {}

					if(!$scope.tasks[curOrd.id][newTaskDate+'perGroup'][newTask.group]) {
						$scope.tasks[curOrd.id][newTaskDate+'perGroup'][newTask.group] = [];
						$scope.tasks[curOrd.id][newTaskDate+'perGroup'][newTask.group].groupName = newTask.group;
					}

					$scope.tasks[curOrd.id][newTaskDate+'perGroup'][newTask.group].push(newTask)
				}
			}

		}
	}

	var createChecklist = function(rawChecklist){
		var cb = function(){
			ngDialog.closeAll();
		}

		if ($scope.systemProps['checklist.enablePlanningDateControl'] === "true") {

			var isNextMonth = moment(rawChecklist.date).isSame(moment().add(1, 'month'), 'month');
			var isToday = moment(rawChecklist.date).isSame(moment(), 'day');

			if (!(isNextMonth || isToday)) {
				var limitText = ".";

					limitText =  " или на следующий месяц <i>(" + moment().add(1, 'month').format("MMMM YYYY") + ")</i>"

				Notifications.danger("Ошибка", "Проверку можно назначить на сегодняшний день" + limitText, 7000);

				cb();
				return;
			}

		}


		var shops = [];
		var executors = [];

		if(rawChecklist.ordType == "shops"){
			shops = [rawChecklist.ordItem]
			executors = rawChecklist.ordAnother
		}else if(rawChecklist.ordType == "executors"){
			executors = [rawChecklist.ordItem]
			shops = rawChecklist.ordAnother
		}

        // 'временный' костыль, так как бэк не подерживает создание проверки для уровня COMPANY
        const role = executors[0].level === 'COMPANY' ? 'DIVISION' : executors[0].level;

		var objectToSend = {
            planDate: moment(rawChecklist.date).format('YYYY-MM-DD'),
            deadlineDate: moment(rawChecklist.date).format('YYYY-MM-DD'),
            deadlineTime: '23:59:59',
            templateId: rawChecklist.template.id,
            role,
            shopIds: shops.map(el => el.id),
            userIds: executors.map(user => user.id),
			name: rawChecklist.template.name,
			description: rawChecklist.template.name,
			notifyType: "DEADLINE",
			taskType: "CHECKLIST",
			commentRequired: false,
			minAttachmentsCount: 0,
			isWeb: true
		}

		TaskListServiceHttp.createTask(objectToSend).then(function(response, a,b ) {
            if (response.status === 403) {
                Notifications.danger('У вас нет полномочий планировать проверки подчинённых сотрудников', '', 2000);
                cb();
                return;
            }

			if (response.status === 400){
				var errMsg = response.headers('x-error-message');
				console.error(response.headers('x-error'), errMsg);

				if (errMsg.indexOf('min plan interval is') === -1) {
					Notifications.danger("Ошибка! " + errMsg);
					cb();
					return;
				}

				var minPlanDays = errMsg.match(/\d+/g)[0];
				Notifications.danger("Ошибка! Минимальный интервал планирования: " + minPlanDays + ' (дней)');
				cb();
				return;
			}

			if (response.status !== 200){
				console.error(response.headers('x-error'), response.headers('x-error-message'));
				Notifications.danger("Ошибка! "+ response.headers('x-error-message'));
				cb();
				return;
			}

			insertTaskToDashboard(response.data);




			//TODO: Запихать созданную проверку в календарь

			Notifications.success("Проверка успешно создана");
			cb()
		})

	}

	$scope.dateChange = function(dt){
		var date = angular.copy(dt);
		var t = "c "+moment(date).startOf('week').format("DD.MM.YYYY")+ " по "+ moment(date).endOf('week').format("DD.MM.YYYY");
		setTimeout(function(){angular.element("input[uib-datepicker-popup]").val(t)}, 0);


		$scope.daysForCalendar = [];
		var daysLength = moment(date).endOf('week').diff(moment(date).startOf('week'), 'days') + 1;
		for(var i = 0; i < daysLength; i++){
			$scope.daysForCalendar.push(moment(date).startOf('week').add(i, 'day'));
		}

		$scope.getData()

	}

	$scope.getOrdinateItemTitle = function(ordItem, ordType) {
		return (ordType.val == 'shops' ? ordItem.sap + ' ' : '') + ordItem.name;
	};

	/* Немного наркоманская логика, бережём моск

	ordType - тип выбранной шняги по ординате ('shops' | 'executors')
	ordItem - собственно шняга - магазин или исполнитель
	ordAnother - исполнитель для ordType == 'shops' или магазин для ordType == 'executors'

	initEvent - состояние на момент вызова
	newEvent - собственно то что создаём
	*/

	$scope.openNewEvent = function(newEventParams){
		var _scope = $scope;
        const isDateEarlyThan7Days = moment(newEventParams.date).diff(moment().subtract(7, 'day'), 'days') < 0;

        if(isDateEarlyThan7Days) {
			Notifications.warning("Нельзя планировать в прошлом")
			return
		}

        if(newEventParams.ordType === "executors" && newEventParams.ordItem && $scope.account.level === "COMPANY" && $scope.account.id === newEventParams.ordItem.id){
			newEventParams.type = 'checklist'
		}

		ngDialog.open({
			template: 'creationPopup',
	        controller: ['$scope', function($scope) {
	        	$scope.userIsShopLevel = _scope.account.level === 'SHOP';
                $scope.isThisPastDate = moment(newEventParams.date).diff(moment(), 'days') < 0;
                $scope.isInspector = _scope.isInspector;
	        	$scope.initEvent = angular.copy(newEventParams)
	        	$scope.newEvent = $scope.isThisPastDate && !newEventParams.type || $scope.isInspector ? Object.assign({}, newEventParams, { type: 'checklist' }) : newEventParams;
	        	$scope.activeOrdinateType = _scope.activeOrdinateType.val
				$scope.checklistTemplates = $scope.isThisPastDate ? _scope.checklistTemplates.filter(template => template.allowedBackdatingPlanning) : _scope.checklistTemplates;
				$scope.taskTemplates = _scope.taskTemplates;
				$scope.executorsForShop = undefined;
				$scope.shopsForExecutor = undefined;
				$scope.calendarOpen = false;
				$scope.shops = [];
				$scope.dateOptions = { formatYear: 'yy', startingDay: 1, minDate: new Date() };

                // Если кликнута кнопка "проверки" или выбран шаблон из кнопки проверки, то минимальная дата за 7 дней становится
                if (newEventParams.type === "checklist") {
                    if (!newEventParams.template || newEventParams.template && newEventParams.template.allowedBackdatingPlanning) {
                        $scope.dateOptions.minDate = moment().subtract(7, 'day').toDate();
                    }
                }

                $scope.showCalendar = function(){
					$scope.calendarOpen = !$scope.calendarOpen
				}

                $scope.onDateChange = function(date) {
                    if (newEventParams.type === "checklist") {
                        $scope.isThisPastDate = moment(date).diff(moment(), 'days') < 0;
                        $scope.checklistTemplates = $scope.isThisPastDate ? _scope.checklistTemplates.filter(template => template.allowedBackdatingPlanning) : _scope.checklistTemplates;
                    }
                }

				$scope.refreshShops = function (search) {
					if(search.length >= 2){
						Dashboard3Service.getShops({search: search, page: 1, perPage: 10}, function(err, result){
							if(err){
								Notifications.danger("Ошибка поиска магазинов: "+err)
								return;
							}
							$scope.shops = result;
						})
					}
				};

				$scope.refreshExecutors = function (search) {
					if(search.length >= 2){

						Dashboard3Service.getExecutors({search: search, page: 1, perPage: 10}, function(err, result){
							if(err){
								Notifications.danger("Ошибка поиска исполнителей: "+err)
								return;
							}
							var user = Principal.getIdentity()
							$scope.executors = ($scope.newEvent.type=='checklist')?result:result.filter(el => {
								var t = !(user.level == 'COMPANY' && el.id == user.id)
								return t
							});
						})
					}
				}
				$scope.loadShopsForExecutor = function(executorId){
					$scope.shopsForExecutorLoading = true
					OrgstructService.getShopsForExecutors({ executorsIds: [ executorId ] }, function(err, data){
						$scope.shopsForExecutorLoading = false
						if(err){
							Notifications.danger("Ошибка загрузки магазинов для исполнителя: "+err+data)
							ngDialog.closeAll();
							console.error(err, data)
							return
						}
						if(data.length == 0)Notifications.warning("У исполнителя нет магазинов")
						$scope.shopsForExecutor = data;
					})
				}
				$scope.loadExecutorsForShop = function(shopId){
					$scope.executorsForShopLoading = true
					OrgstructService.getExecutorsForShop(shopId, function(err, data){
						$scope.executorsForShopLoading = false
						if (err) {
							Notifications.danger("Ошибка загрузки исполнителей для магазина: "+err+data)
							ngDialog.closeAll();
							console.error(err, data)
							return
						}
						if (data.length === 0) Notifications.warning("У выбранного магазина нет исполнителей");

						$scope.executorsForShop = data;
					})
				}

				$scope.loadOrShopsForItem = function(itemId){
					if($scope.activeOrdinateType == "shops"){
						$scope.loadExecutorsForShop(itemId);
					}else{
						$scope.loadShopsForExecutor(itemId);
					}
				}

				$scope.$watch('newEvent.type', function(prev, cur) {
			        if(cur && prev && cur != prev){
			        	$scope.newEvent.template = undefined
			        	$scope.newEvent.ordAnother = undefined
			        }
			    }, true);

				$scope.create = function(){
					//clean up object

					var item = angular.copy($scope.newEvent);
					item.date = moment(item.date).format("YYYY-MM-DD");

					if (item.ordItem && item.ordItem.id) {
						item.ordItemId = item.ordItem.id;
					}

					if (item.checklistTemplate) {
						item.checklistTemplateId = item.checklistTemplate.id;
						delete item.checklistTemplate;
					}

					if (item.type == 'taskByTemplate') item.type = "task";

					switch(item.type){
						case "task":
							openNewTask(item)
							return;
						case "checklist":
							createChecklist(item);
							break;
						default:
							Notifications.danger("Некорректный типа события");
							break;
					}
				}


				// if(newEventParams.ordType == "shops" && newEventParams.type == "checklist"){
				// 	$scope.loadExecutorsForShop(newEventParams.ordItem.id)
				// }
			}],
	        className: 'ngdialog-theme-plain dialog dialog-wide',
	        showClose: false
		 })
	}

	$scope.showTaskList =  function(taskGroup){
		var _scope = $scope;
		ngDialog.open({
			template: 'taskGroupPopup',
	        controller: ['$scope', function($scope) {
	        	$scope.taskGroupName = taskGroup[0].type
	        	$scope.taskGroup = taskGroup;
	        	$scope.openItemOnDashboard = _scope.openItemOnDashboard
	        }],
	        className: 'ngdialog-theme-plain dialog',
	        showClose: true
	    })
	}

	var openNewTask =  function(task){

		ngDialog.open({
			template: 'newTaskPopup',
	        controller: ['$scope', function($scope) {
	        	$scope.newItem = task
	        	$scope.callback = function(newTasks){
	        		ngDialog.closeAll()
	        		if(newTasks && newTasks.length)insertTaskToDashboard(newTasks)
	        	}

	        }],
	        className: 'ngdialog-theme-plain dialog dialog-wide taskCreate',
	        showClose: false
	    })
	}

	$scope.onDayClick = function(day, ordItem){
		$scope.openNewEvent({
    		date: day,
    		ordItem: ordItem,
    		ordType: $scope.activeOrdinateType.val
    	})
	}


	$scope.selectPeriod = function(action){
		var newDate = undefined;
		switch(action){
			case "prev":
				newDate = $scope.daysForCalendar[0].subtract(1, "week");
				break;
			case "next":
				newDate = $scope.daysForCalendar[0].add(1, "week");
				break;
			case "today":
				newDate = moment();
				break;
			default:
				Notifications.danger("Непредвиденная ошибка при смене даты календаря");
				console.log("wrong period action for calendar");
				return;
				break;
		}

		$scope.dateChange(newDate);
	}

	$scope.setActiveOrdType = function(ordType){
		$scope.activeOrdinateType = ordType;
		$scope.search.string = "";
		$scope.pagination.page = 1;
		$scope.getData()
	}

	$scope.prevent = function(e){
		e.stopPropagation();
		e.preventDefault();
	}




	$scope.getData = function(){
		var activeOrdinateTypeIndex = $scope.ordinateTypes.findIndex(x => x.val==$scope.activeOrdinateType.val);
		var query;
		var ordType = $scope.ordinateTypes[activeOrdinateTypeIndex].val;
		var queries = {
			shops : Dashboard3Service.getShops,
			executors: Dashboard3Service.getExecutors
		}
		if(!queries[ordType]){
			Notifications.danger("Всё сломалось :( Некорректный тип ординаты");
			return;
		}

		var queryParams = {
			planDateFrom: $scope.daysForCalendar[0].format("YYYY-MM-DD"),
			planDateTo: $scope.daysForCalendar[$scope.daysForCalendar.length -1].format("YYYY-MM-DD"),
			ordType: ordType,
			ordIds: [],
			isWeb: true
		}
		$scope.dataLoading = true;
		async.auto({
			filtersData: function(callback) {
				if ($scope.filters.data.divisions) {
					callback(null, [$scope.filters.data.clusters, $scope.filters.data.divisions])
				} else {
					$scope.filters.loadData(function (err) {
						if (err) {
							callback(err);
							return;
						}

						if ($scope.isRegManager && localStorageService.get('dashboard:filter:onlyMyResponsibility') === 'true') {
							$scope.filters.selected.onlyMyResponsibility = true;
							$scope.filters.onchange.onlyMyResponsibility(true);
						}

						callback(null, [$scope.filters.data.clusters, $scope.filters.data.divisions]);
					});
				}
			},
			getOrdinateData: ['filtersData', function (result, callback) {
				let divisionIds = [];

				if ($scope.filters.selected.onlyMyResponsibility) {
					divisionIds = ($scope.filters.selected.divisions.length ? $scope.filters.selected.divisions : $scope.filters.data.divisions).map(el => {
						return el.id;
					})
				}

				var params = {
					page: $scope.pagination.page,
					perPage: $scope.pagination.perPage,
					search: $scope.search.string,
					divisionIds,
					clusterIds: $scope.filters.selected.clusters.map(el => el.id)
				}

				queries[ordType](params, function(err, data, headers){
					if(err){
						console.log(err);
						callback(err, data);
						return;
					}
					callback(null, data, headers)
				})
			}],
			getTasks: ['getOrdinateData', function(result, callback){
				queryParams.ordIds = result.getOrdinateData[0].map(el => el.id);
				Dashboard3Service.getTasks(queryParams, function(err, data){
					if(err){
						callback(err);
						return;
					}
					callback(null, data)
				});

			}]
		}, function (err, results) {
			if(err){
				Notifications.danger("Ошибка получения данных", err, results);
				console.error( err, results)
				return false
			}

			$scope.ordinateTypes[activeOrdinateTypeIndex].data = results.getOrdinateData[0];

			$scope.tasks = (function(tasks, ordinateData, ordType){
				var result = {};

				for(var i in ordinateData){
					var curOrdId = ordinateData[i].id;

					if (!result[curOrdId]) result[curOrdId] = {};

					for (var j in tasks) {

						var curTask = tasks[j];

						curTask.group = genGroup(curTask.type);
						var curTaskDate = moment(curTask.createDate).format("YYYY-MM-DD");

						if (curTask[ordType.substring(0, ordType.length -1) + "Id"] === curOrdId) {
							if(!result[curOrdId][curTaskDate]) result[curOrdId][curTaskDate] = [];

							result[curOrdId][curTaskDate].push(curTask);

							if(!result[curOrdId][curTaskDate+'perGroup'])result[curOrdId][curTaskDate+'perGroup'] = {};

							if(!result[curOrdId][curTaskDate+'perGroup'][curTask.group]) {
								result[curOrdId][curTaskDate+'perGroup'][curTask.group] = [];
								result[curOrdId][curTaskDate+'perGroup'][curTask.group].groupName = curTask.group;
							}

							result[curOrdId][curTaskDate+'perGroup'][curTask.group].push(curTask)
						}

					}
				}
				return result
			})(results.getTasks, results.getOrdinateData[0], ordType);

			$scope.pagination.total = results.getOrdinateData[1]('x-total-count');
			 if(localStorageService.get("perPage") !== $scope.pagination.perPage)localStorageService.set("perPage", $scope.pagination.perPage)
            //$scope.pagination.pageChange($scope.tasks);
			$scope.dataLoading = false;

		})

	}

	$scope.openItemOnDashboard = function(item, e){
		e.stopPropagation();
		e.preventDefault();

		var _scope = $scope;

		ngDialog.open({
			template: 'openItem',
	        controller: ['$scope', '$state', function($scope, $state) {
				const isChecklist = item.type === 'CHECKLIST';
				const userIsAdminOrCheckAuthor = _scope.account.roles.includes('ROLE_ADMIN');
				const isSsbDirAndCheckSSB = _scope.account.roles.includes('ROLE_INSPECTOR') && (['DIVISION', 'COMPANY'].includes(_scope.account.level));
				$scope.item = item;

				$scope.showDeleteCheckBtn = isChecklist && (userIsAdminOrCheckAuthor || isSsbDirAndCheckSSB);


				$scope.showGoBtn = isChecklist ? item.userRole === 'EXECUTOR' : true;

	        	$scope.submit = function() {
	        		ngDialog.closeAll();

	        		if (isChecklist) {
						 		$state.go('editChecklist', {checklist: item.checklistId});
							} else {
								$state.go('tasks.taskFullView', { id: item.id });
							}
	        	};

						$scope.deleteChecklist = function(check) {
	        		ngDialog.closeAll();

							ChecklistService.deleteChecklist(check.checklistId).then(result => {
								if (result.status !== 200) {
									Notifications.danger('Не удалось удалить проверку');
									return;
								}

								var activeOrdinateVal = _scope.activeOrdinateType.val;
								var createDate = moment(item.createDate).format('YYYY-MM-DD');

								switch(activeOrdinateVal) {
									case 'executors':
										_scope.tasks[item.executorId][createDate] = _scope.tasks[item.executorId][createDate].filter(obj => obj.id !== item.id);
										break;
									case 'shops':
										_scope.tasks[item.shopId][createDate] = _scope.tasks[item.shopId][createDate].filter(obj => obj.id !== item.id);
										break;
								}

								Notifications.success('Проверка удалена');
							});
						};

	        }],
	        className: 'ngdialog-theme-plain dialog dialog-wide taskCreate',
	        showClose: false
	    })


	}

	/* init */

	$scope.dt = new Date();
	$scope.dateChange($scope.dt);

	//getData()

	async.parallel([
        function(callback){
            callback(null, TaskNewService.getTaskTypes());
        },
        function(callback){
            Dashboard3Service.getLastTaskTemplates(callback);
        },
    ],

    function(err, results){
        if(err){
        	console.error(err, results);
        	Notifications.danger("Ошибка подгрузки данных: "+ err + results);
        	return;
        }

        $scope.lastTenList = {
			taskTypes: results[0],
			taskTemplates: results[1],
		}
    })

    // TWTemplateService.getAllWithParams({active:true}, function(err, data) {
    //     if (err) {
    //         Notifications.danger('Ошибка загрузки шаблонов: '+err+data);
    //         return;
    //     }

    //  	$scope.checklistTemplates = data;
    // })

	ObjectServiceForTemplates.query({}, function(result,a,v){
		if(result.length == 0){
			Notifications.warning("Шаблоны не найдены!")

		}
		$scope.checklistTemplates = result;
        $scope.lastTenList.checklists = result;
	})

    TasksTemplateService.getAll({onlyActive: true}, function(err, data, headers) {
        if (err) {
            Notifications.danger('Ошибка загрузки шаблонов задач');
            console.log(err, data)
            return;
        }

      	$scope.taskTemplates = data;
    });


	SystemParamsService.getSystemProps(['checklist.daysToBegin', 'checklist.beginPlaningPeriodDay', 'checklist.check.interval', 'checklist.enablePlanningDateControl'], function(err, params){
		if(err){
    		Notifications.danger('Ошибка получения данных');
    		console.log("Err: ", err);
    		return
    	}

    	$scope.systemProps = params
	})


});

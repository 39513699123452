'use strict';

angular.module('smartApp').factory('FilterService', function FilterService($http, config) {

    return {
        getPositions: function () {
            return $http.get(config.backend + '/objects/positions');
        },
        getDataCleaningBasic: function () {
            return $http.get(config.backendV2 + '/reports/contractors-to-blocks/basic/filters/values');
        },
        getDataCleaningDetailed: function () {
            return $http.get(config.backendV2 + '/reports/contractors-to-blocks/detailed/filters/values');
        }
    }
});
angular.module('smartApp')
	.directive('pokazometr', function() {		
		return {
			restrict: 'E',
			template: 
				'<p>Выполнено {{closed}} из {{total}}</p>'+
        		'<line>'+
        		'	<div style="width: {{width}}%">'+
        		'		<fill ng-class=\'{"filled":loaded}\' />'+
        		'	</div>'+
        		'</line>'
        		,				
			
			scope: {
                closed: '<',
                total: '<'
            },
			link: function(scope, elm, attrs, controller) {
				scope.loaded = false
				scope.width = scope.closed/scope.total*100;
				setTimeout(function(){  //грязный хак что бы не тянуть сюда angularTimeout
					scope.$apply(function(){
						scope.loaded = true
					})
				}, 10)
			}
		};
	});
'use strict';

angular.module('smartApp').factory('ProcessGroupService', function ProcessGroupService($http, config) {

    return {
        getAll: function() {
            return $http.get(config.backend + '/processGroup/getAll')
                .success(function(data) {
                    return data;
                }
            );
        },
        saveNewProcessGroup: function(processGroup) {
            return $http.post(config.backend + '/processGroup/saveProcessGroup', processGroup)
                .success(function(data) {
                    return data;
                }
            );
        },
        deleteProcessGroup: function(processGroup) {
            return $http.post(config.backend + '/processGroup/deleteProcessGroup', processGroup)
                .success(function(data) {
                    return data;
                }
            );
        },
        saveAll: function(processGroups) {
            return $http.post(config.backend + '/processGroup/saveAll', processGroups)
                .success(function(data) {
                    return data;
                }
            );
        }
    }

});
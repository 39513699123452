'use strict';

angular.module('smartApp').controller('CommonEditChecklistController', function(DownloadService, UserService, ChecklistService, ObjectServiceForChecklists, TWTemplateService, gradeFormatterFilter, ngDialog, $state, $stateParams, $rootScope, $scope, $http, moment, Principal, ChecklistEditor, TemplateService, localStorageService, Notifications, config, Upload, $timeout, SystemParamsService, $window) {

	$scope.start = start;
	$scope.criteres = [];
	$scope.openCategories = [];
	$scope.showGalleryButton = false;
	$scope.categoriesCounter = [];
    $scope.galeryMethods = {};
	$scope.reviewAllowed = false;
	$scope.acceptAllowed = false;
	$scope.isShowDeleteButton = (Principal.getIdentity().roles.indexOf('ROLE_INSPECTOR') === -1) && (Principal.getIdentity().level !== 'REGION')

	$scope.isError = false;
    $scope.warningOutTime = false;

	$scope.config = config;

    $scope.TWTemplate = null;

	$scope.daysArr = new Array(5)

	$scope.supervisorAttachments = {};
	$scope.directorAttachments = {};

	const user = Principal.getIdentity();
	var systemProps = {}
	var refreshFilledCounter = function(){

		var counter = 0;
		angular.element("question:visible").each(function(i, el){
			if(angular.element(el).find("answerCheckbox input[type=checkbox]:checked").length && (angular.element(el).find("director textarea").val().length || !$scope.template.directorCommentNecessity)){
				counter++;
			}
		})

		var filledClass = "";
		switch(counter){
			case 0:
				filledClass = "none";
				break
			case $scope.negativeAnswers:
				filledClass = "all";
		}
		angular.element("summary").attr("class", "").addClass(filledClass).find("filled").html(counter)
	}

	var questions = [];

	var _this = $scope;
	var checklistId = $stateParams.checklist;

	function isAllowedDiffDate (start, end, allowedDiff) {
		var today = parseInt(start);
		var check_date = parseInt(end);
		var diff_dates = today - check_date;
		return diff_dates > allowedDiff;
	}



	$scope.isRemovable = function(){
		if (!$scope.template) return false;

		const userIsAdmin = user.roles.includes('ROLE_ADMIN');
		const isSsbDirAndCheckSSB = user.roles.includes('ROLE_INSPECTOR') && (['DIVISION', 'COMPANY'].includes(user.level));

		return isSsbDirAndCheckSSB || userIsAdmin;
	};

	$scope.deleteChecklist = function () {
		var template = $scope.template;
		ngDialog.open({
			template: 'deleteConfirm',
			controller: ['$scope', 'moment', function($scope, moment) {

				$scope.today = moment(template.resolveDate).format("DD MMMM YYYY (dddd)");
				$scope.shopName = template.shopName;
				$scope.delete = function(){
					ngDialog.closeAll();

					ChecklistService.deleteChecklist(template.id)
						.success(function (result, status) {
							if (status == 200) {
								Notifications.success("Проверка удалена");
								$state.go("home");
							}else{
								Notifications.danger("Произошла ошибка");
								console.log("Critical error: can't remove checklist");
							}

						});
				}

			}],
			className: 'ngdialog-theme-plain dialog',
			showClose: false
		 })




	};

	$scope.$on('$stateChangeStart', onStateChange);

	$scope.printTemplate = function(format, viewMode) {
		var possible = {
			formats: ["PDF", "EXCEL"],
			viewModes: ["ALL_QUESTIONS", "ONLY_NEED_FIX_QUESTIONS"]
		}
		if(!format || !~possible.formats.indexOf(format))format = possible.formats[0]
		if(!viewMode || !~possible.viewModes.indexOf(viewMode))viewMode = possible.viewModes[0]
		DownloadService.downloadResolverChecklist($scope.template.id, format, viewMode);
	};


	$scope.getRequiredMessage = function(question) {
		var msg = '';

		if (question.required && question.attachmentRequired) {
			msg = '(обязательно для заполнения, вложения обязательны)';
		} else if (question.required) {
			msg = '(обязательно для заполнения)';
		} else if (question.attachmentRequired) {
			msg = '(вложения обязательны)';
		}

		return msg;
	};

	$scope.allowedForEditing = function() {
		return !$scope.isPreview() && $scope.template.resolverId === user.id && (Principal.isInAnyRole(['ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_SECRET']) && !$scope.allowedForRole('ROLE_INSPECTOR'));
	};


	$scope.checkAnswers = function(question, subQuestion, value, oldAnswer) {
        if(question.answer == null){ //если не выбран ответ, то возвращаем старое значение поля
            question.answer =  JSON.parse(oldAnswer);
            return;
		}
		//ChecklistEditor.setChanged(true);
		if(!question.daysForAction && question.daysForAction !== 0) {
			question.daysForAction = systemProps["checklist.daysForAction"];
		}

		var flag = false;
		if (subQuestion) {
			angular.forEach(question.subQuestions, function(subQuestion, key) {
				if (subQuestion.answer != undefined && subQuestion.answer == 0) {
					flag = true;
				}
			});

			if(flag) {
				question.answer = false;
				question.change = true;

			}
			question.disabled = flag;
		}

		(value === 0.5) ? (question.change = true) : (question.change = !value);
		$scope.saveQuestion(question);

	};

	$scope.calcBlocked = function () {
		$scope.template.blocked = false;
		angular.forEach($scope.template.categories, function(category, cKey) {
			category.blocked = false;
			angular.forEach(category.questions, function(question, qKey) {
				if(question.answer == 0 && question.blockCategory) {
					category.blocked = true;
				}
				if(question.answer == 0 && question.blockChecklist) {
					$scope.template.blocked = true;
				}
			});
		});
	};

	$scope.isMobile = function () {
		return ismobile;
	};


	$scope.collapseAll = function() {
		angular.forEach($scope.openCategories, function(oc, ocKey) {
			$scope.openCategories[ocKey] = false;
		});
	};

	$scope.uncollapseAll = function() {
		angular.forEach($scope.openCategories, function(oc, ocKey) {
			$scope.openCategories[ocKey] = true;
		});
	};


	$scope.initTemplate = function() {
		ObjectServiceForChecklists.get({id: checklistId}, function(data) {
			if(data.status && data.status === 'error') {
				$scope.isError = true;
				return;
			}
			$scope.template = data;
            $scope.templateInit = angular.copy(data);
			angular.forEach($scope.template.categories, function(category, categoryKey) {
				$scope.openCategories[categoryKey] = true;
				$scope.categoriesCounter[category.id] = {positive: 0, negative: 0, fixed: 0};

			});

			$scope.initQuestions();
			if($scope.template && $scope.template.templateId) {
                $scope.initTWTemplate();
            }else{
                Notifications.danger("Не найдены данные шаблона чек-листа", "", 2000);
			}
		});
	};

	$scope.initTWTemplate = function() {
        TWTemplateService.getTemplate($scope.template.templateId, function (err, result) {
            if (err) {
                Notifications.danger("Не найдены данные шаблона чек-листа", "", 2000);
                console.log(err);
                return;
            }
            $scope.TWTemplate = result;

            if (!$scope.TWTemplate){
                Notifications.danger("Ошибка", "Ошибка загрузки информации по шаблону чек-листа", 7000);
            }
        });
    };

	// Потомкам
	// Этот дикий адЪ нужен потому как изначально логика ответов была основана на чилах 0, 5, 100
	// Когда я передывал фронт проверок я превёл логику на нормальную булеву, но сервер пока остаётся на числах
	// Поэтому приходится перебивать по два раза ответы

	// К true, false и undefined мы внезапно добавляем 0.5
	// Я сопротивлялся, но меня заставили
	// 0.5 приводится к 2.5

	var answerTurner = function(answer, revert){
		if(!revert){
			switch(answer){
				case 0:
				case '0':
					return false;
					break;
				case 5:
				case '5':
					return true;
					break;
				case '100':
				case 100:
					return undefined;
					break
				case '2.5':
				case 2.5:
					return 0.5;
					break
			}
		}else{
			switch(answer){
				case '0':
				case false:
				case 0:
					return '0';
					break;
				case '1':
				case true:
				case 1:
					return '5';
					break;
				case '.5':
				case 0.5:
					return '2.5';
					break;
				case undefined:
					return '100';
					break
				default:
					return '100';
					break;
			}
		}
	}

	$scope.loadImages = function(question) {
		question.loadingImages = true;
		question.loadingImagesDirector = true;

		ChecklistService.getQuestionPhotos(question.id, $scope.template.id, function(err, attachments) {
			if (err) {
				Notifications.warning("Ошибка загрузки фото")
				return;
			}

			question.images = attachments.map((el, index) => {
				el.id = index;
				el.deletable = true;

				return el;
			});

			splitAttachments(question, question.images, 'supervisor');
			question.loadingImages = undefined;
		});

		ChecklistService.getQuestionDirectorPhotos(question.id, $scope.template.id, function(err, attachments) {
			if (err) {
				Notifications.warning("Ошибка загрузки фото")
				return;
			}

			question.imagesDirector = attachments.map((el, index) => {el.id = index; el.deletable = true; return el});
			splitAttachments(question, question.imagesDirector, 'director');
		  	question.loadingImagesDirector = undefined;
		});
	};

	$scope.viewDocAttachment = function(attachment){
    $window.open(attachment.url, '_blank');
  };

	$scope.getDocTypeForPreview = function(type) {
		switch (type) {
			case 'text/html':
				return '-code-';
			case 'application/zip':
				return '-zip-';
			case 'application/pdf':
				return '-pdf-';
			case 'application/vnd.ms-excel':
				return '-excel-';
			case 'application/msword':
				return '-word-';
			default:
				return '-';
		}
	};

	$scope.initQuestions = function () {
		$scope.negativeAnswers = 0;
		var questionSumm = {weightedAnswers:0, weights:0, catWeightedAnswers:0, catWeights:0}

		angular.forEach($scope.template.categories, function(category, cKey) {
			angular.forEach(category.questions, function(question, qKey) {
				//turn answers to 0 => false, 5=> true, 100 => undefined
				question.answer = answerTurner(question.answer);
				question.acceptedByDirector  = answerTurner(question.acceptedByDirector)
				question.weight = question.weight*5;
				question.images = []
				if(question.hasPhotos){
					$scope.loadImages(question);
					$scope.showGalleryButton = true;
				}

				if(!question.daysForAction && question.daysForAction !== 0) {
					question.daysForAction = systemProps.daysForAction;
				}

				questionSumm.catWeightedAnswers = 0;
				questionSumm.catWeights = 0;
				questions.push(question);
				//question.visibleId = (cKey + 1) + '.' + (qKey + 1);
				if(question.answer != undefined){
					question.mark = question.answer * question.weight
					questionSumm.catWeightedAnswers += question.answer*question.weight;
					questionSumm.catWeights += question.weight;
					questionSumm.weightedAnswers += question.answer*question.weight;
					questionSumm.weights += question.weight;
				}
				// if(question.daysForAction != 0 || question.answer == 0) {
				//  question.change = true;
				// }

				angular.forEach(question.subQuestions, function(subQuestion, key) {
					subQuestion.answer = answerTurner(subQuestion.answer);
					if (subQuestion.answer == false) {
						//question.disabled = true;
					}
				});

				if(question.answer == undefined) return;

				if(!question.answer || question.answer == 0.5) {
					$scope.negativeAnswers++;
					$scope.categoriesCounter[category.id].negative++;

					if(question.acceptedByDirector === 0) {
						$scope.categoriesCounter[category.id].fixed++;
					}
				} else if(question.answer == true) {
					if(question.change)$scope.negativeAnswers++;
					$scope.categoriesCounter[category.id].positive++;
				}
			});

			if(questionSumm.catWeightedAnswers == 0 || Principal.isInRole("ROLE_SUPERVISOR")){category.empty = true;}
			category.mark = (questionSumm.catWeightedAnswers/questionSumm.catWeights).toFixed(1);
		});

		$scope.template.mark = (questionSumm.weightedAnswers/ questionSumm.weights).toFixed(1);

		// if(Principal.isInRole("ROLE_DIRECTOR")) {
		//  //$scope.collapseAll();
		// } else if(Principal.isInRole("ROLE_SUPERVISOR")) {
		//  $scope.uncollapseAll();
		// }
		setTimeout(refreshFilledCounter, 0)
	   // $timeout(refreshFilledCounter);
	};


	$scope.prepareReviewReport = function() {
		ngDialog.open(
			{
				template: 'prepareReviewReport',
				controller: ['$scope', function($scope) {
                    $scope.warningOutTime = _this.warningOutTime;
                    $scope.intervalStart = _this.template.intervalStart;
                    $scope.intervalEnd = _this.template.intervalEnd;

					$scope.prepareTableData = function() {
						$scope.tableData = [];
						angular.forEach(_this.template.categories, function(cat, catKey) {
							angular.forEach(cat.questions, function (q, qKey) {
								//if(q && q.change && q.answer != 100) {
								if(q && (q.answer == 0 || q.answer == .5 || (q.answer && q.change))) {

									var date = new Date(_this.template.resolveDate);
									date.setDate(date.getDate() + q.daysForAction);
									$scope.tableData.push({
										visibleId: q.visibleId,
										name: q.name,
										answer: q.answer,
										date: date.getDate() + "-" + (Number(date.getMonth())+1) + "-" + date.getFullYear(),
										comments: q.commentsSupervisor
									});
								}
							});
						});
					};

					$scope.confirm = function() {
						_this.setChecklistChanged(false);
						_this.reviewAllowed = true;
						_this.review();
					};

					$scope.prepareTableData();
				}],
				className: 'ngdialog-theme-mine',
				showClose: false
			}
		);
	};
	$scope.save = function(flag, callback) {
		$scope.calcBlocked();
		$scope.template.timeShift = -(new Date().getTimezoneOffset()/60);
        turneredTemplate();
		ObjectServiceForChecklists.put($scope.template, function (result) {
			if(flag) {
				$rootScope.$broadcast("saveSuccess");
				refresh();
			}
			if(callback)callback(result);
		});
	};



	function splitAttachments(question, attachments, userRole) {
		var docs = [];
		var images = [];

		attachments.forEach(attachment => {
			if (attachment.type.indexOf('image') !== -1) {
				images.push(attachment);
			} else {
				docs.push(attachment);
			}
		});

		$scope[userRole + 'Attachments'][question.id] = { images: images, docs: docs };
	}

    function turneredTemplate (){
    	$scope.allQuestions = [];
        angular.forEach($scope.template.categories, function(category, cKey) {
            angular.forEach(category.questions, function(question, qKey) {
                question.answer = answerTurner(question.answer, true)
                question.acceptedByDirector = answerTurner(question.acceptedByDirector, true)
                $scope.allQuestions.push(question);
            })
        });
    }

	var scrollTo = function(elId){
		$("#"+elId).addClass("unfilled")

		$('content>wrapper').scrollTop(0)
		$('content>wrapper').animate({
			scrollTop: $("#"+elId).offset().top-160
		}, 1000);
	}

	// $scope.saveList = function() {
	// 	if(!ChecklistEditor.isChanged()) {
	// 		$rootScope.$broadcast("nothingToSave");
	// 	} else {
	// 		if(Number(angular.element("summary")) != $scope.negativeAnswers){
	// 			var fixedNum = 0;
	// 			var commentedNum = 0;
	// 			angular.element("question:visible").each(function(i, el){
	// 				if(angular.element(el).find("answerCheckbox input[type=checkbox]:checked").length){
	// 					fixedNum++;
	//
	// 				}
	// 				if(angular.element(el).find("director textarea").val().length){
	// 					commentedNum++;
	// 				}
	// 			})
	// 			if($scope.template.directorCommentNecessity && fixedNum != commentedNum && fixedNum == $scope.negativeAnswers){
	//
	// 				ngDialog.open(
	// 					{
	// 						template: 'confirmUnResolved',
	// 						controller: ['$scope', function($scope) {
	// 							$scope.confirm = function() {
     //                                saveQ()
	// 							};
	// 						}],
	// 						className: 'ngdialog-theme-plain',
	// 						showClose: false
	// 					}
	// 				);
	//
	// 			} else saveQ();
	// 		} else saveQ();
	// 	}
	// };



    $scope.saveQuestion = function(question) {
        var questionRequest = {
            id: question.id
        };

		(question.answer != null) && (questionRequest.answer = answerTurner(question.answer, true));
        (question.acceptedByDirector != null) && (questionRequest.acceptedByDirector = answerTurner(question.acceptedByDirector, true));
        (question.orderInCategory != null) && (questionRequest.orderInCategory = question.orderInCategory);
        (question.change != null) && (questionRequest.change = question.change);
        (question.commentsDirector != null) && (questionRequest.commentsDirector = question.commentsDirector);
        (question.commentsSupervisor != null) && (questionRequest.commentsSupervisor = question.commentsSupervisor);
        (question.daysForAction != null) && (questionRequest.daysForAction = question.daysForAction);
        (question.numberOfIncidents != null) && (questionRequest.numberOfIncidents = question.numberOfIncidents);

		var reqParam = {clientTimeShift: -(new Date().getTimezoneOffset()/60)};

		question.loading = true;
		ChecklistEditor.saveQuestion($scope.template.id, reqParam, questionRequest).then(function(data){
			delete question.loading;
			if (data.status === 200){
				$rootScope.$broadcast("saveSuccess");
			}else{
				Notifications.danger("Ошибка сохранения данных", '', 5000);
			}
		}, function(err){
			Notifications.danger('Ошибка сохранения данных', '', 5000);
		});
    };

    $scope.saveCommentChecklist = function(comment) {
        ChecklistEditor.saveCommentChecklist($scope.template.id, {comment: comment}).then(function(data){
            if (data.status === 200){
                $rootScope.$broadcast("saveSuccess");
            }else{
                Notifications.danger("Ошибка! Не удается сохранить комментарий", '', 5000);
            }
        }, function(err){
            Notifications.danger('Ошибка! Не удается сохранить комментарий');
        });
    };

	$scope.noTrueAnswersComments = function(){
		Notifications.success('Не заполнены необходимые комментарии');
		return true;
	};

    $scope.noSaveAnswersComments = function(){
        Notifications.success('Не сохранены необходимые комментарии');
        return true;
    };

	$scope.review = function() {
		var qNoAnswer = false; //turns to true if question is unanswered
		var qNonFilledFields = false; //turns to true if we have non-filled textfields on true answers
		var qNoImages = false;

		var qNoAnswerId = undefined;
		var qNonFilledFieldsId = undefined;
		var qNoImagesId = undefined;

        //get current time with added timezone offset
        var d = new Date();
        var currentTime = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
        //because start time stores in DB as UTC+0 and comes with current system offset
        var startTime = new Date($scope.template.startTime);
        //calculate diff in seconds
        var diffTime = (currentTime.getTime() - startTime.getTime()) / 1000;
        // if (diffTime > 86400) {
        //     Notifications.danger("Ошибка", "Чек-лист не может быть завершён позднее, чем через сутки после старта", 7000);
        //     return;
        // }
        if (diffTime < 0) {
            Notifications.danger("Ошибка", "Чек-лист не может быть завершён ранее, чем он был стартован", 7000);
            return;
        }

        if($scope.TWTemplate.allDay === undefined){
            Notifications.danger("Не найдены параметры заполнения чек-листа", "", 7000);
            return;
        }else  if (!$scope.TWTemplate.allDay){
            conditions = getIntervalConditions();

            if (!conditions.checkIntervalTime && conditions.systemPropsInterval){
                Notifications.danger("Ошибка", "Данный чек-лист может быть заполнен в период времени с " + $scope.TWTemplate.intervalStart + " по " + $scope.TWTemplate.intervalEnd, 7000);
                return;
            }else if(!conditions.checkIntervalTime && !conditions.systemPropsInterval){
                $scope.warningOutTime = true;
            }
        }else{
            $scope.warningOutTime = false;
        }
		angular.forEach($scope.template.categories, function(category, categoryKey) {
			angular.forEach(category.questions, function(question, questionKey) {
        $("#"+question.id).removeClass("unfilled");
        if ((question.required && question.answer == undefined)) {
            qNoAnswer = true;

						if (!qNoAnswerId) {
							qNoAnswerId = question.id;
						}
        }

				if (question.attachmentRequired && question.images.length === 0) {
					qNoImages = true;

					if (!qNoImagesId) {
						qNoImagesId = question.id;
					}
				}

				if(((question.answer == true && question.change) || question.answer == false || (question.answer == .5)) && !question.commentsSupervisor && $scope.template.supervisorCommentNecessity){
				    qNonFilledFields = true;
				    if(!qNonFilledFields) {
							qNonFilledFieldsId = question.id;
						}
				}
			});
		});

		var endReviewObj = {
			noAnswer: qNoAnswer,
			noSavedComments: null,
			nonFilledFields: qNonFilledFields,
			noImages: qNoImages,
			noAnswerId: qNoAnswerId,
			noSavedCommentsId: null,
			nonFilledFieldsId: qNonFilledFieldsId,
			noImagesId: qNoImagesId
		};

		if($scope.template.supervisorCommentNecessity && !qNonFilledFields) { //проверка на то, что комментарии были сохранены
            ObjectServiceForChecklists.get({id: $scope.template.id}, function (data) {
                if (data.status && data.status === 'error') {
                    $scope.isError = true;
                    return;
                }
                angular.forEach(data.categories, function (category, categoryKey) {
                    angular.forEach(category.questions, function (question, questionKey) {
                        question.answer = answerTurner(question.answer);
                        if (question.answer != null && (question.answer !== true || (question.answer === true && question.change)) && !question.commentsSupervisor) {
                            endReviewObj.noSavedComments = true;

														if (!endReviewObj.noSavedCommentsId) {
															endReviewObj.noSavedCommentsId = question.id;
														}
                        }
                    });
                });

                endReview(endReviewObj);
            });
      } else {
        endReview(endReviewObj);
			}
	};

    function endReview (data) {
        $scope.calcBlocked();

        if(data.noAnswer) {
          scrollTo(data.noAnswerId);
          $rootScope.$broadcast("checklistCanNotBeReviewed");
        } else if ($scope.reviewAllowed || Principal.isInRole('ROLE_SECRET')) {
            if(Principal.isInRole('ROLE_SECRET')) {
                //ChecklistEditor.setChanged(false);
            }

            $scope.template.statusName = "REVIEW";
            var questionRequest = {
                timeShift: -(new Date().getTimezoneOffset()/60),
                finishDate: +new Date()
            };

            ChecklistService.finishChecklist($scope.template.id, questionRequest).then(function(data){
                if (data.status === 200){
                    localStorageService.remove("activeChecklist");
                    $rootScope.$broadcast("checklistSentToReview");
                    $state.go("home");
                }else{
                    Notifications.danger("Невозможно завершить чек-лист", '', 5000);
                }
            }, function(err){
                Notifications.danger('Ошибка сохранения данных');
            });

        } else {
            if (Principal.isInRole("ROLE_DIRECTOR") || Principal.isInRole("ROLE_SUPERVISOR")){
                if (data.nonFilledFields) {
                    if ($scope.noTrueAnswersComments()) {
                        scrollTo(data.nonFilledFieldsId);
                        return;
                    }
                }
                if (data.noSavedComments) {
                    if ($scope.noSaveAnswersComments()) {
                        scrollTo(data.noSavedCommentsId);
                        return;
                    }
                }
								if (data.noImages) {
									Notifications.success('Вложения обязательны!');
                  scrollTo(data.noImagesId);
                  return;
                }
            }
						$scope.prepareReviewReport();
        }
    }

	$scope.resetFlag = false;


	$scope.refillDaysForAction = function(q){
		q.daysForAction = systemProps["checklist.daysForAction"];
	}
	$scope.setChecklistChanged = function(flag) {
		refreshFilledCounter()

		//ChecklistEditor.setChanged(flag);
	};

	$scope.setQuestionChanged = function(){
      //  refreshFilledCounter();

	};

	$scope.setChange = function(question){
        // if(!checkRequiredComment(question)){
         //    question.change = !question.change;
         //    return;
		// }
        $scope.refillDaysForAction(question);
        refreshFilledCounter();
        $scope.saveQuestion(question);
	};

    $scope.setAcceptedByDirector = function(question, category){
		if($scope.template.directorCommentNecessity && question.acceptedByDirector && !question.commentsDirector){
			Notifications.danger('Не сохранено. Не заполнен обязательный комментарий');
            question.acceptedByDirector = false;
            //$scope.template.categories[category.id].questions[question.id] = angular.copy($scope.templateInit.categories[category.id].questions[question.id]);
			return;
        }
        $scope.categoriesCounter[category.id].fixed = (question.acceptedByDirector == true) ? ($scope.categoriesCounter[category.id].fixed - 1) : ($scope.categoriesCounter[category.id].fixed + 1);
        refreshFilledCounter();
        $scope.saveQuestion(question);
    };

	$scope.changeAnswer = function(day, question){
		question.daysForAction = day;
		question.daysForActionEdit = question.daysForAction;

		refreshFilledCounter();
		$scope.saveQuestion(question);
	};

	$scope.canSaveDaysForAction = function(question) {
		return question.daysForAction !== question.daysForActionEdit && question.daysForAction <= 99 && question.daysForAction >= 0;
	};

	$scope.allowedForRole = function(role) {
		return Principal.isInRole(role);
	};

	$scope.prepareAcceptStatement = function() {
		ngDialog.open(
			{
				template: 'prepareAcceptStatement',
				controller: ['$scope', function($scope) {
					$scope.confirm = function() {
						_this.setChecklistChanged(false);
						_this.acceptAllowed = true;
						_this.accept();
					}
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};

	$scope.accept = function() {
		console.log("Something went wrong");
		// var flag = false;
		// var flagReview = false;
		// angular.forEach($scope.template.categories, function(category, categoryKey) {
		// 	angular.forEach(category.questions, function(question, questionKey) {
		// 		if((question.change && question.acceptedByDirector == 0)) {
		// 			flag = true;
		// 		}

		// 		if((question.required == 1 && question.answer == 100 && Principal.isInRole('ROLE_CURATOR'))) {
		// 			flagReview = true;
		// 		}
		// 	});
		// });
		// $scope.calcBlocked();
		// if(flagReview && Principal.isInRole('ROLE_CURATOR')) {
		// 	$rootScope.$broadcast("checklistCanNotBeReviewed");
		// 	return;
		// }
		// if(flag && !Principal.isInRole('ROLE_CURATOR')) {
		// 	$rootScope.$broadcast("checklistCanNotBeAccepted");
		// } else {
		// 	if($scope.acceptAllowed) {
		// 		if(Principal.isInRole('ROLE_CURATOR')) {
		// 			$scope.template.statusName = "ACCEPTEDBYSYSTEM";
		// 		} else {
		// 			$scope.template.statusName = "ACCEPTED";
		// 		}
		// 		$scope.save(false, function () {
		// 			$rootScope.$broadcast("clAccepted");
		// 			$state.go("home");
		// 		});
		// 	} else {
		// 		$scope.prepareAcceptStatement();
		// 	}
		// }
	};

	$scope.showHint = function(hint) {
		$.SmartMessageBox({
			title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Подсказка <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span>",
			content : hint,
			buttons : '[OK]'
		});
	};

	$scope.showHintImage = function (question, field) {
		field = field ? 'questionRightImage' : 'questionWrongImage';
		$.SmartMessageBox({
			title : '<img/><div class="image" style="background:url(' + config.pathToPhoto + question[field].name + ');"></div>',
			buttons : '[OK]'
		});
	};

	$scope.isPreview = function(){
		return !!(!$scope.template || $scope.template.statusName === "PLANNING");
	};

	$scope.notInFuture = function(){
		return !!($scope.template && !moment($scope.template.resolveDate).isAfter(moment().set('hours', 23).set('minutes', 59)));
	};

	$scope.allowBegin = function () {
		if (!$scope.template) return false;

		if ($scope.template.resolverId !== user.id || $scope.allowedForRole('ROLE_INSPECTOR')) return false;

		if(systemProps["checklist.daysToBegin"] || systemProps["checklist.daysBeforeBegin"]){

			var startDate = (systemProps["checklist.daysToBegin"])?moment(moment().subtract(systemProps["checklist.daysToBegin"], 'days').format("DDMMYYYY"), "DDMMYYYY"):moment(moment().format("DDMMYYY"), "DDMMYYYY");

			var endDate = (systemProps["checklist.daysBeforeBegin"])?moment(moment().add(systemProps["checklist.daysBeforeBegin"], 'days').format("DDMMYYYY 23:59:59"), "DDMMYYYY hh:mm:ss"):moment(moment().format("DDMMYYY 23:59:59"), "DDMMYYYY hh:mm:ss");

			return $scope.isPreview() && moment($scope.template.resolveDate).isBetween(startDate, endDate);
		}


		return $scope.isPreview() && $scope.notInFuture() && !(isAllowedDiffDate(moment().format('DDD'), moment($scope.template.resolveDate).format('DDD'), 0));
	};

	$scope.allowChecklistComment = function () {
		return ($scope.template && !!~['EDITING', 'REVIEW'].indexOf($scope.template.statusName))
			&& $scope.template.resolverId === user.id
			&& (Principal.isInRole('ROLE_CURATOR') || Principal.isInRole('ROLE_SUPERVISOR'));
	};

	$scope.allowCommentForDirector = function () {
		return $scope.template && $scope.template.resolverId === user.id
			&& ((Principal.isInRole('ROLE_DIRECTOR') && !!$scope.template.comment)
				|| ((Principal.isInRole('ROLE_CURATOR')
				|| Principal.isInRole('ROLE_SUPERVISOR'))
				&& !!$scope.template.comment
				&& !!~['ACCEPTEDBYSYSTEM', 'ACCEPTED'].indexOf($scope.template.statusName)));
	};

	$scope.checklistComment = function () {
		var $selfScope = $scope;
		ngDialog.open(
			{
				template: 'checklistComment',
				controller: ['$rootScope', '$scope', function($rootScope, $scope) {
					$scope.template = $selfScope.template;

					var comment = $scope.template.comment;

					$scope.save = function () {
						_this.saveCommentChecklist($scope.template.comment);
					};

					$scope.close = function() {
						$scope.template.comment = comment;
					};
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};

	$scope.checklistCommentShow = function () {
		$.SmartMessageBox({
			title : 'Комментарий к анкете:',
			content : $scope.template.comment,
			buttons : '[OK]'
		});
	};

	function checkRequiredComment(checkQuestion){
        if($scope.template.supervisorCommentNecessity && !checkQuestion.commentsSupervisor){
            Notifications.danger('Необходимо сначала заполнить комментарий', '', 3000);
            return false;
        }
        return true;
	}

	function onError(){
		Notifications.danger('Чек-лист не найден');
		$state.go('home');
	}

	function refresh(){
		if(Principal.isInRole('ROLE_DIRECTOR')) {
		   // initChecklists();
			$scope.showPositiveQuestions = true;
		} else {
		   // initChecklist(checklistId);
		}
		$scope.initTemplate();

	}

	var paramsArr = ['daysToBegin',
		'daysForAction',
		'daysBeforeBegin',
		'beginPlaningPeriodDay',
		'deletion.nextMonthUnplan',
		'deletion.nextMonthPlan',
		'deletion.today',
		'deletion.currentMonthUnplan',
		'deletion.currentMonthPlan',
		'check.interval'];

	SystemParamsService.getSystemProps(paramsArr.map(function(el){return "checklist."+el}), function(err, params){
		if(err){
    		Notifications.danger('Ошибка получения данных');
    		cb(result.status)
    		console.log("Err: ", err);
    		return
    	}

    	systemProps = params

    	refresh();
	})




	/**
	 * Начать проверку
	 */

	function getIntervalConditions(){

        var currentTime = moment().format("HH:mm");
        var currentDate = Date.parse('01/01/1970 ' + currentTime);
        var startDate = Date.parse('01/01/1970 ' + $scope.template.intervalStart);
        var endDate = Date.parse('01/01/1970 ' + $scope.template.intervalEnd);

        var checkIntervalTime = (
			( ( startDate < endDate ) && ( currentDate > startDate ) && ( currentDate < endDate ) )
			||
            ( ( startDate > endDate ) && ( ( currentDate > startDate ) || ( currentDate < endDate ) ) )
		);
        var systemPropsInterval = (systemProps["checklist.check.interval"] == 'true');
        return {checkIntervalTime: checkIntervalTime, systemPropsInterval: systemPropsInterval}
	}

	var conditions;

	function start(){
        if($scope.TWTemplate.allDay === undefined){
            Notifications.danger("Не найдены параметры заполнения чек-листа", "", 5000);
            return;
        }else if (!$scope.TWTemplate.allDay){
        	conditions = getIntervalConditions();

            if (!conditions.checkIntervalTime && conditions.systemPropsInterval){
                Notifications.danger("Ошибка", "Данный чек-лист может быть заполнен в период времени с " + $scope.TWTemplate.intervalStart + " по " + $scope.TWTemplate.intervalEnd, 7000);
                return;
            }else if(!conditions.checkIntervalTime && !conditions.systemPropsInterval){
                $scope.warningOutTime = true;
            }
        }else{
            $scope.warningOutTime = false;
		}

		ngDialog.openConfirm({
			template: 'scripts/app/common/confirm-dialog.template.html',
			scope: $scope,
			data: {title: 'Вы действительно хотите начать проверку?'}
		}).then(function(e){
			//$scope.save(true);
            var d = new Date();
            var questionRequest = {
				startTime: moment(d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
				timeShift: -d.getTimezoneOffset()/60
            };
            ChecklistService.startChecklist($scope.template.id, questionRequest).then(function(data){
                if (data.status === 200){
                    $scope.template.statusName = "EDITING";
                    //$scope.template.intervalStart = moment(d).format("HH:mm");
                    $scope.template.startTime = data.startTime;
                    $scope.template.timeShift = data.timeShift;
                    Notifications.success("Проверка началась!")
                }else if(data.status === 400){
                    Notifications.danger("Не дан ответ на обязательный вопрос", '', 5000);
                }
            }, function(err){
                Notifications.danger('Ошибка сохранения данных');
            });
		});
	}

	function onStateChange(event){
		if(ChecklistEditor.isChanged()){
			event.preventDefault();
			$.SmartMessageBox({
				title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Вы уверены, что хотите выйти, не сохранив изменения? <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span>",
				content : "Если Вы выйдете, не сохранив изменения текущей сессии, они потом буду утеряны",
				buttons : '[Отмена][Да]'
			}, function(ButtonPressed) {
				if (ButtonPressed == "Да") {
					ChecklistEditor.setChanged(false);
					$state.go('home');
				}
			});
		}
	}





	$scope.showGallery = function(){

		ChecklistService.getChecklistPhotos($scope.template.id, function(err, images){
			if(images && images.length!=0){

				$scope.galeryImages = images.map((el, index) => {el.id = index;return el})
				setTimeout(function(){$scope.galeryMethods.open()}, 100)

			}
			if(err){
				console.log(err, images)
				Notifications.warning("Ошибка загрузки")
			}
		})

	}




	$scope.deletePhoto = function(checklistId, questionId, item, cb){
		ChecklistService.delPhoto(checklistId, questionId, item.uid).then(function(data){
			if (data.status != 200){
				Notifications.danger("Не удалось удалить изображение");
				return;
			}

			cb();

		}, function(err){
        Notifications.danger('Не удалось удалить изображение');
    })
	}



	$scope.uploadImage = function(file, errFiles, e, question, template, director){
		var img = angular.element("#"+question.id).find("images>item:last-of-type")
		//$("images>item:last-of-type").prev().after($("images>item:last-of-type").first().prev().clone())

		$scope.errFile = errFiles && errFiles[0];
		if (file) {
			file.upload = Upload.upload({
				url: config.backend + '/photoService/'+((director)?"dirPhoto":"photo"),
				data: {file: file, questionId:question.id, checklistId:template.id, noTrobber:true}
			});

			file.upload.then(function (response) {
				if(response.status != 200){
					question["uploadImage"+((director)?'Director':'')] = null;
					Notifications.danger('Плохое изображение');
					return;
				}

				$timeout(function () {
					file.result = response.data;

					$scope.showGalleryButton = true;

					ChecklistService["getQuestion"+((director)?'Director':'')+"Photos"](question.id, $scope.template.id, function(err, result) {
						var attachmentsKey = "images" + ((director) ? 'Director' : '');

						question[attachmentsKey] = result.map((el, index) => {
							el.id = index + el.uid;
							el.deletable = true;

							return el;
						});
						question["uploadImage"+((director)?'Director':'')] = undefined;

						var userRole = director ? 'director' : 'supervisor';
						splitAttachments(question, question[attachmentsKey], userRole);
					});
				});
			}, function (response) {
				if (response.status > 0)
					$scope.errorMsg = response.status + ': ' + response.data;
			});
		}
	}


});

angular.module('smartApp')
    .controller('EquipmentTaskController', ['$scope', '$stateParams', '$state', 'TaskListServiceHttp', 'Notifications', function(
        $scope,
        $stateParams,
        $state,
        TaskListServiceHttp,
        Notifications
    ) {
        if (!$stateParams.id) {
            $state.go('tasks');
            return;
        }

        const responseId = $stateParams.id;

        $scope.response = {};
        $scope.task = {};
        $scope.equipments = [];

        function init() {
            TaskListServiceHttp
                .getResponse(responseId)
                .then(resp => {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке товаров');
                        $state.go('tasks');
                        return;
                    }

                    $scope.response= resp.data;
                    $scope.equipments = resp.data.equipments;

                    getTaskInfo(resp.data.taskId);
                });
        }

        function getTaskInfo(id) {
            TaskListServiceHttp
                .getTask(id)
                .then(resp => {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке задачи');
                        $state.go('tasks');
                        return;
                    }

                    $scope.task = resp.data;
                });
        }

        init();
    }]);

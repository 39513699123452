/**
 * Created by tyurin on 20.04.15.
 */
angular.module('smartApp')
	.factory('ChecklistReports', checklistReportFactory)
	.service('ChecklistReportService', checklistReportServiceFactory);

checklistReportServiceFactory.$inject = ['$q', 'ChecklistReports', '$rootScope'];
checklistReportFactory.$inject = [
	'Filter1',
	'Filter2',
	'Filter3',
	'Filter4',
	'Filter5',
	'AccreditationChecklistReport',
	'AccreditationCategoryReport',
	'FilterHR',
	'Xfilter1',
	'Xfilter2',
	'Xfilter3',
	'Xfilter4',
	'Xfilter5',
	'Xfilter6',
	'Xfilter7',
	'Xfilter8',
	'Xfilter9',
	'Xfilter10',
	'Xfilter11',
	'Xfilter12',
	'filterForms',
	'filterFormsResults',
	'filterLogs',
	'transactionsReport',
    'ReportInspectors',
    'ReportManagerCoordinates',
];

function checklistReportFactory(Filter1, Filter2, Filter3, Filter4, Filter5, AccreditationChecklistReport, AccreditationCategoryReport, FilterHR, Xfilter1, Xfilter2, Xfilter3, Xfilter4, Xfilter5, Xfilter6, Xfilter7, Xfilter8, Xfilter9, Xfilter10, Xfilter11,  Xfilter12, filterForms, filterFormsResult, filterLogs, transactionsReport, ReportInspectors, ReportManagerCoordinates){
	return [Filter1, Filter2, Filter3, Filter4, Filter5, AccreditationChecklistReport, AccreditationCategoryReport, FilterHR, Xfilter1, Xfilter2, Xfilter3, Xfilter4, Xfilter5, Xfilter6, Xfilter7, Xfilter8, Xfilter9, Xfilter10, Xfilter11,  Xfilter12, filterForms, filterFormsResult, filterLogs, transactionsReport, ReportInspectors, ReportManagerCoordinates];
}

function checklistReportServiceFactory($q, reports, $rootScope){

	var report = reports[0];
	var filters = {};
	var filtersValidator = null;

	return {
		setReport: function(newReport){
			filters = {};
			report = newReport
		},

		getReport: function(){
			return report || reports.length;
		},

		getAvailableReports: function(){
			return reports;
		},

		getFilters: function() {
			return filters;
		},

		getFilter: function(name){
			return filters[name];
		},

		setFilter: function(name, value) {
			filters[name] = value;
		},

		removeFilter: function(name){
			delete filters[name];
		},

		isValid: function() {
			if (angular.isFunction(filtersValidator)) return filtersValidator(filters);
			return report && angular.isFunction(report.isValid) ? report.isValid(filters) : true;
		},

		setValidator: function(validator) {
			filtersValidator = validator;
		},

		loadReport: function(){
			var defer = $q.defer();

			if(report && angular.isFunction(report.load))
				report.load(filters)
					.then(function(result){
						if (result.data.length === 0 || (result.data.list && result.data.list.length === 0) || (result.data.body && result.data.body.length === 0)) {$rootScope.$broadcast("noResultsFound");}
						defer.resolve(result)
					}, function(error){
						defer.reject(error)
					});
			else
				defer.resolve([]);
			return defer.promise;
		},

		downloadReport: function(){
			return report && angular.isFunction(report.download) && report.download(filters) || null;
		},

        downloadReportKPO: function(){
            return report && angular.isFunction(report.downloadKPO) && report.downloadKPO(filters) || null;
        },

		canLoad: function() {
			return report && angular.isFunction(report.load);
		}

	};
}

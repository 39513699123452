import axios from 'axios';
import { events, dispatch } from '@/events';

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: {
        indexes: null,
    },
});

api.interceptors.request.use(config => {
    const tokenData = localStorage.getItem('ls.token');

    if (!tokenData) {
        dispatch(events.unauthorized);
        return config;
    }

    const parsedTokenData = JSON.parse(tokenData);

    if (parsedTokenData.expires && parsedTokenData.expires <= new Date().getTime()) {
        dispatch(events.unauthorized);
        return config;
    }

    config.headers['x-auth-token'] = parsedTokenData.token;
    return config;
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            dispatch(events.unauthorized);
        }
        return Promise.reject(error);
    }
);

export default api;

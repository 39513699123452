angular.module('smartApp').service('GoodsService', ['$http', 'config', GoodsService]);

function GoodsService($http, config) {
    return {
        getWorkOrdersList(body) {
            const requestBody = body || {};
            return $http.post(config.backend + '/goods/order/web/all', requestBody);
        },

        getWorkOrder(orderNumber) {
            return $http.get(config.backend + '/goods/order/web/info/getByNumber', { params: { number: orderNumber } });
        },

        getOrderGoods(params) {
            return $http.get(config.backend + '/goods/order/web/goods/getByNumber', { params });
        },

        getOrderGoodsBox(params) {
            return $http.get(config.backend + '/goods/order/web/box/getByNumber', { params });
        }
    }
}

angular.module('smartApp').factory('Xfilter7', checkXreportFactory7);
checkXreportFactory7.$inject = ['ChecklistService'];

function checkXreportFactory7(ChecklistService) {
	return {
		id: 12,
		title: 'Отчёты - Сводный',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter7/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter7/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		load: function(filters) {

			var t = ChecklistService.getXreport7(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport7(filters);
		}
	};

}
'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('entercounters', {
                parent: 'counters',
                url: '/entercounters',
                data: {
                    roles: ['ROLE_DIRECTOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/counters/entercounters/entercounters.html',
                        controller: 'EnterCountersController'
                    }
                }
            });
    });
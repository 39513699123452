'use strict';
var PanelShopsController;

PanelShopsController = function (
    OrgstructService,
	$anchorScroll,
	ObjectServiceForClusters,
	$rootScope,
	$scope,
	$location,
	PanelShopsResource,
	Notifications,
	ParseLink,
	UserService,
	$timeout,
	localStorageService,
	ngDialog,
	DownloadService,
    $q
	) {
	var ROLE_DIRECTOR     = 'ROLE_DIRECTOR';
	var ROLE_SUPERVISOR   = 'ROLE_SUPERVISOR';

  var getDefaultCoords = function() {
    return {latitude: 55.75597611604962, longitude: 37.6174396276474};
  };

    $scope.showFilters = true;
	$scope.showActive     = true;
	$scope.userService    = UserService;
	$scope.isSelected     = false;
	$scope.isLoading      = true;
	$scope.selected_users = [];
	$scope.selected_row   = null;
	$scope.shop_to_search = null;
	$scope.links          = null;
	$scope.clearFilters   = {"divisions":[],"clusters":[],"statuses":[]};

	$scope.supervisorPagination = {
		totalCount: null,
		offset: 5,
		index: 1,
		firstPage: 1,
		lastPage: 1
	};
	$scope.shop        = null;
	$scope.data        = [];
	$scope.directors   = [];
	$scope.supervisors = [];

	$scope.possibleClusters = $scope.allClusters = $scope.allDivisions = $scope.allShopStatuses = [];

	//$scope.getPage = goToPage;
	$scope.edit    = edit;

	/**google map*/

    $scope.map = {
        center: {
            latitude: 51.219053,
            longitude: 4.404418
        },
        zoom: 19,
        options: {
            streetViewControl: false,
            mapTypeControl: false,
            scaleControl: false,
            rotateControl: false,
            zoomControl: true
        },
	      events: {
        	click: function (map, eventName, args) {
        		var shop = {};

            if(!$scope.shop && $scope.newShop){
            	$scope.newShop.latitude = args[0].latLng.lat();
           		$scope.newShop.longitude = args[0].latLng.lng();
            } else{
            	$scope.shop.latitude = args[0].latLng.lat();
           		$scope.shop.longitude = args[0].latLng.lng();
            }

            $scope.marker.coords =  {
              latitude: args[0].latLng.lat(),
              longitude: args[0].latLng.lng()
            };

            markerCoords = Object.assign({}, $scope.marker.coords);

		        map.setCenter(args[0].latLng);
		        $scope.changeCoords(args[0].latLng)
          }
        }
    };

    var markerCoords = null;
    $scope.marker = {id: 0};
    $scope.markerEvents = {
      position_changed: function() {
        var currentCoords = $scope.marker.coords;

        if (markerCoords.longitude !== currentCoords.longitude || markerCoords.latitude !== currentCoords.latitude) {
          $scope.marker.coords = markerCoords;
        }
      }
    };

    $scope.getHasNoDirectorLabel = function(value) {
      switch (value) {
        case false:
          return 'Есть';
        case true:
          return 'Нет';
        default:
          return 'Не важно';
      }
    }

    $scope.toggleFilters = function() {
      $scope.showFilters = !$scope.showFilters;

      if (!$scope.$$phase) {
        $scope.$digest();
      }
    };

    $scope.changeCoords = function(latLng){
         new google.maps.Geocoder().geocode({'location': latLng}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var addrArr = results[0].address_components
                var address, city;


                address = addrArr[1].long_name+ ', ' +addrArr[0].long_name


                if(addrArr[0].long_name == "Unnamed Road" || addrArr.length <=3 ){
                    address = 'n/a'
                }

                city = (addrArr.length > 4 && addrArr[addrArr.length - 5].long_name != "Unnamed Road" )?addrArr[addrArr.length - 5].long_name:'n/a'


                if(!$scope.shop && $scope.newShop){

                    $scope.newShop.address = address
                    $scope.newShop.city = city;

                }else{
                    $scope.shop.address = address
                    $scope.shop.city = city;
                }

                // console.log($scope.newShop)
            }
        });
    }

    var debouncedUpdateTimer;
    $scope.debounceAddress = function (shop){
        $timeout.cancel(debouncedUpdateTimer);
            debouncedUpdateTimer = $timeout(function () {
                $scope.changeAddress(shop)
            }, 500);
    }


    $scope.changeAddress = function (shop) {
    	$timeout.cancel(debouncedUpdateTimer);

    	var address = shop.address;

    	if (shop.city) {
        address = shop.city + " " + address
      }

      var geocodeAddress = function (address, callback) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': address, 'language':'ru'}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                callback(results[0].geometry.location);
            }
        });
      };

      var _scope = $scope
      var _shop = shop

      geocodeAddress(address, function (latLng) {
        console.log(latLng);
      	console.log(latLng.lat(), latLng.lng())
      	console.log(shop)
        var lat = latLng.lat();
        var lng = latLng.lng();

		    _scope.map.center.latitude = lat;
        _scope.map.center.longitude = lng;

        _shop.latitude = lat;
        _shop.longitude = lng;

        _scope.marker.coords =  {
          latitude: lat,
          longitude: lng
        };

        markerCoords = Object.assign({}, _scope.marker.coords);

      	_scope.$apply();
      });
    };
  // end google map

	$scope.go_to_shop_creation = function () {
		$scope.shop = null;
		$scope.newShop = {};

		if(!$scope.clusters || !$scope.shopStatuses){

				$scope.clusters = [];
				ObjectServiceForClusters.query({}, function(result) {
					$scope.clusters = result;
					$scope.shopStatuses = [];
					PanelShopsResource.getShopStatuses().success(function(result, status, headers){
						$scope.shopStatuses = result;
						$location.hash('newShopForm');
						$anchorScroll();
					});
				});



        }else{
            setTimeout(function(){
                $location.hash('newShopForm');
                $anchorScroll();
            }, 0)
        }

        $scope.setShopOnMap(getDefaultCoords())


	};


	  $scope.setShopOnMap = function(shop) {
    		var checkLat = function(val){
    			return (val == +val && val <= 90 && val >= 0)
    		};
        var checkLng = function(val){
    			return (val == +val && val <= 180 && val >= 0)
    		};

    		if(!checkLat(shop.latitude) || !checkLng(shop.longitude)) {
    			$scope.showMap = false;
    			Notifications.warning("Неправильные координаты магазина");
    			console.log("Wrong coords of shop", shop)
    			return;
    		}

        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({ location: {
          lat: shop.latitude,
          lng: shop.longitude
        }}, function(results, status) {
          $scope.showMap = true;

          switch (status) {
            case 'OK':
              $scope.map.center.latitude = shop.latitude;
              $scope.map.center.longitude = shop.longitude;
              $scope.marker.coords = $scope.map.center;
              break;
            case 'ZERO_RESULTS':
              $scope.map.center = getDefaultCoords();
              $scope.marker.coords = getDefaultCoords();
              break;
          }

          markerCoords = Object.assign({}, $scope.marker.coords);

          $scope.$apply();
        });
    };

    $scope.changePosition = function(shop) {
        var updateCenter = function() {
          $scope.map.center.latitude = shop.latitude || 0;
          $scope.map.center.longitude = shop.longitude || 0;
        };
        $scope.$watch('lat', updateCenter);
        $scope.$watch('long', updateCenter);

    };

  // end google map

	$scope.showPassChange = function(director){
		var mainScope = $scope;
		ngDialog.open(
			{
				template: 'passChange',
				controller: ['$scope', 'PanelUsersService', function($scope, PanelUsersService) {
					$scope.confPwd = undefined;
					$scope.newPwd = undefined;
					$scope.userName = director.firstName+' '+director.lastName;
					$scope.userLogin = director.login;
					$scope.pwdMinLen = 4;

					$scope.confirm = function() {
						director.password = $scope.newPwd
						PanelUsersService.updateUser(director).success(function (data, status) {
							if (status === 200 || status === 201) {
								Notifications.success('Пользователь успешно обновлен');
							}else{
								Notifications.danger('Ошибка');
							}
							$scope.closeThisDialog();
						});
					};
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	}

    var binaryParams = {
        production: {
            name: 'продукции',
            params: {
                meat: { val: undefined, name: 'мясной цех' },
                fish: { val: undefined, name: 'рыбный цех' },
                salad: { val: undefined, name: 'салатный цех' },
                bakery: { val: undefined, name: 'пекарня' }
            }
        },
        equipment: {
            name: 'оборудованию',
            params: {
                combiSteamer: { val: undefined, name: 'пароконвектомат' },
                grill: { val: undefined, name: 'гриль' },
                islandCounter: { val: undefined, name: 'островной прилавок' },
                saladBar: { val: undefined, name: 'салат-бар' },
                scalesSelf: { val: undefined, name: 'весы самообслуживания' }
            }
        }
    };


    var defaultLimit = 10;
    $scope.tableSettings = {
        page: 1,
        limit: localStorageService.get('perPage')
            ? localStorageService.get('perPage')
            : defaultLimit
    };
    $scope.filters = { divisions: [], clusters: [], statuses: [] };

    var showBinary = function(type) {
        var mainScope = $scope;

        if (!binaryParams[type]) {
            return;
        }

        if (mainScope.filters[type]) {
            for (var param in mainScope.filters[type]) {
                binaryParams[type].params[param].val =
                    mainScope.filters[type][param];
            }
        }

        ngDialog.open({
            template: 'binaryParams',
            controller: [
                '$scope',
                function($scope) {
                    $scope.typeName = binaryParams[type].name;
                    $scope.params = binaryParams[type].params;
                    $scope.confirm = function() {
                        var reqData = {};
                        for (var param in $scope.params) {
                            if ($scope.params[param].val != undefined)
                                reqData[param] = $scope.params[param].val;
                        }
                        mainScope.filters[type] = reqData;
                    };
                }
            ],
            className: 'ngdialog-theme-plain',
            showClose: false
        });
    };

    $scope.$on('EDITING_SHOP', function(ev, shop) {
        if (shop.director) {
            $scope.selectedDirector.data = {
                email: shop.director.email,
                id: shop.director.id,
                level: 'SHOP',
                login: shop.director.login,
                firstName: shop.director.firstName,
                lastName: shop.director.lastName
            };
        }

        $scope.setShopOnMap(shop);
    });

    $scope.supervisorsDistribution = {
      pagination: {
        page: 1,
        perPage: 20,
        totalItems: null
      },
      resetControl: false,
      searhTimeout: null,
      searchValue: '',
      loading: false,
      data: [],
      onBoxOnePageChange: function(page) {
        this.pagination.page = page;
        this.loadSupervisors();
      },
      onBoxOneSearch: function(searchValue) {
        $timeout.cancel(this.searhTimeout);

        this.searhTimeout = $timeout(() => {
          this.pagination.page = 1;
          this.searchValue = searchValue;
          this.loadSupervisors();
        }, 3000);
      },
      reset: function() {
        this.pagination.page = 1;
        this.resetControl = true;

        $timeout(() => {
          this.resetControl = false;
        });
      },
      loadSupervisors: function() {
        var requestParams = {};

        requestParams.page = this.pagination.page - 1;
        requestParams.perPage = this.pagination.perPage;
        requestParams.search = this.searchValue;

        getSupervisors(requestParams).then((data) => {
            this.data = data.content;
            this.pagination.totalItems = data.totalElements;
        });
      }

    };

    $scope.loadSupervisorsTabData = function() {
        $scope.supervisorsDistribution.loadSupervisors();
    };

    $scope.loadDirectorTabData = function() {
        getDirectors().then(directors => {
            $scope.unsignedDirList = directors;
        });
    };

    function getDirectors() {
        var deferred = $q.defer();

        OrgstructService.getDirectors()
            .then(function(resp) {
                if (resp.status === 200 && resp.data && resp.data.length) {
                    deferred.resolve(resp.data);
                } else {
                    deferred.resolve([]);
                }
            });

        return deferred.promise;
    }


    function getSupervisors(params, hasNoThrobber) {
        var deferred = $q.defer();

        PanelShopsResource
            .getUsersByRole('ROLE_SUPERVISOR', params, hasNoThrobber)
            .then(function(resp){
                if (resp.status !== 200) {
                    Notifications.danger('Ошибка! Не удалось загрузить проверяющих.');
                    deferred.resolve([]);
                    return;
                }

                deferred.resolve(resp.data);
            });

        return deferred.promise;
    }


    $scope.showProduction = function() {
        showBinary('production');
    };

    $scope.showEquipment = function() {
        showBinary('equipment');
    };

    $scope.sort = 'sap';
    $scope.reverse = true;

    $scope.clear_input = function() {
        $scope.shop_to_search = '';
        $scope.getPage(1);
    };

    $scope.updateDirector = function(director) {
        if(!director){
           OrgstructService.removeDirectorFromShop($scope.shop.id).then(function(resp) {
                if (resp.status === 200 || resp.status === 201) {
                    Notifications.success('Директор успешно отвязан');
                }else{
                    console.log(resp)
                    Notifications.danger('Ошибка: ', resp.headers('x-error'), resp.headers('x-error-text'));
                    return;
                }
            });
        }
        OrgstructService.setDirectorToShop(director.id, $scope.shop.id)
            .then(function(resp) {
                if (resp.status === 200 || resp.status === 201) {
                    Notifications.success('Директор успешно обновлен');
                    $scope.shop.director = $scope.selectedDirector.data;
                }else if(resp.headers('x-error-text') && resp.headers('x-error-text').match(/^Shop\s{1,2}\d+\salready has director/)){
                    Notifications.danger('Ошибка: выбранный директор уже имеет привязанный магазин');
                }else{
                    console.log(resp)
                    Notifications.danger('Ошибка: ', resp.headers('x-error'), resp.headers('x-error-text'));
                    return;
                }
            });

    };

    $scope.onShopSupervisorsChange = function(selectedSupervisors, allSupervisors) {
      if ($scope.shop && $scope.shop.director) {
        // без таймаута duallistbox не обновляется
        $timeout(function() {
          var directorIsNotSupervisor = selectedSupervisors.filter(supervisor => supervisor.id === $scope.shop.director.id).length === 0;

          if (directorIsNotSupervisor) {
            var director = allSupervisors.filter(supervisor => supervisor.id === $scope.shop.director.id);
            $scope.shop.supervisors = $scope.shop.supervisors.concat(director);
            Notifications.danger('Проверяющий ' + $scope.userService.getFullUserName(director[0]) + ' является заведующим магазина');
          }
        });
      }
    };

    $scope.onDivisionsChange = function() {
        if (
            !$scope.filters.divisionObjs ||
            !$scope.filters.divisionObjs.length
        ) {
            $scope.possibleClusters = $scope.allClusters;
            return;
        }
        var divisionsIds = [];
        for (var i in $scope.filters.divisionObjs) {
            divisionsIds.push($scope.filters.divisionObjs[i].id);
        }
        $scope.possibleClusters = [];
        for (var i in $scope.allClusters) {
            if (!!~divisionsIds.indexOf($scope.allClusters[i].divisionId))
                $scope.possibleClusters.push($scope.allClusters[i]);
        }
    };

    $scope.doSearch = function(clear) {
        if (clear) {
            $scope.filters = angular.copy($scope.clearFilters);
        }

        //cookie set
        if (
            $scope.tableSettings.limit != defaultLimit &&
            $scope.tableSettings.limit != localStorageService.get('perPage')
        ) {
            localStorageService.set('perPage', $scope.tableSettings.limit);
        }

        for (var i in $scope.filters) {
            if ($scope.filters[i] === '') delete $scope.filters[i];
        }
        //return
        $scope.getData();
    };

    $scope.setSort = function(sort) {
        $scope.reverse = $scope.sort === sort ? !$scope.reverse : false;
        $scope.sort = sort;
        $scope.getData();
    };

    $scope.isCurSort = function(field, dir) {
        dir = dir ? 'asc' : 'desc';

        for (var i = 0; i < $scope.sort.length; i++) {
            if ($scope.sort[i].field === field && $scope.sort[i].orderBy === dir)
                return true;
        }
        return false;
    };

    $scope.selectedDirector = {
        data: null
    };

    function edit(shop) {
        $scope.selected_row = shop.id;
        async.parallel(
            [
                function(callback) {
                    PanelShopsResource.getById(shop.id).success(function(
                        shop
                    ) {
                        callback(null, shop);
                    });
                }
            ],
            function(err, results) {
                $scope.shop = results[0];
            }
        );
    }

    function init() {
        $scope.getData();
    }

    $timeout(function() {
        init();
    }, 10);
};



angular
    .module('smartApp')
    .controller('CommonPanelShopsController', PanelShopsController);


PanelShopsController.$inject = ['OrgstructService', '$anchorScroll', 'ObjectServiceForClusters', '$rootScope', '$scope', '$location', 'PanelShopsResource', 'Notifications', 'ParseLink', 'PanelUsersService', '$timeout', 'localStorageService', 'ngDialog', 'DownloadService', '$q'];

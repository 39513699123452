(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('sortDirection', sortDirection);

    sortDirection.$inject = [];

    function sortDirection() {
        var directive = {
            template: '<i class="fa fa-long-arrow-down" data-ng-class="toggle ? \'fa-long-arrow-up\' : \'fa-long-arrow-down\'"></i>',
            bindToController: true,
            controller: SortDirectionController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                toggle: '='
            }
        };
        return directive;
    }

    function SortDirectionController () {}
})();
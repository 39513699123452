'use strict';

//Check https://gist.github.com/niallo/3109252 for details
angular.module('smartApp')
    .service('ParseLink', function () {
        this.parse = function (header) {
            var parts = header.split(',');
            var links = {};
            angular.forEach(parts, function (p) {
                var section = p.split(';');
                var url = section[0].replace(/<(.*)>/, '$1').trim();
                var queryString = {};
                url.replace(
                    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                    function($0, $1, $2, $3) { queryString[$1] = $3; }
                );
                var page = queryString['page'];
                if(angular.isString(page) ) {
                    page = parseInt(page);
                }
                var name = section[1].replace(/rel="(.*)"/, '$1').trim();
                links[name] = page;
            });

            return links;
        };

        this.getIdFromLocation = function(location){
            if(location){
                var res = location.split('/');
                if(res.length){
                    var id = parseInt(res.pop());
                    return Number(id);
                }
            }
        }
    });

angular.module('smartApp').factory('GoodsApiService', GoodsApiService);

function GoodsApiService($http) {
    return {
        getGoods: function(params) {
            return $http.get('/api/goods/search', { params: params });
        },
        getGoodsByLocalCodes: function(localcodes) {
            return $http.post('api/goods/assortment/localcode', localcodes);
        }
    };
}

GoodsApiService.$inject = ['$http'];
'use strict';

angular.module('smartApp').controller('PanelContractorsController', function ($rootScope, $scope, Notifications, PanelContractorsService, $location, $anchorScroll, FormFactory, ngDialog, OrgstructService, $timeout, $filter, Upload, config, $q) {

    var vm = this;

    vm.tableSettings = {
        page: 0,
        size: 10
    };

    vm.contractors = [];
    vm.allDivisions = [];
    vm.allRegions = [];
    vm.possibleRegions = []
    vm.addingShop = {};
    vm.addingContact = {};
    vm.calendarsOpen = {
        'create': [],
        'viewFrom': [],
        'viewTo': [],
        'filter': []
    };
    vm.calendarFormat = 'dd.MM.yyyy';
    // vm.isLoading = false;
    vm.filters = {};
    vm.possibleClusters = vm.allClusters = vm.allDivisions  = [];


    if (!vm.links){
        vm.links = angular.copy(vm.tableSettings);
    }

    vm.getData = getData;
    vm.getPage = getPage;
    vm.viewEditContractor = viewEditContractor;
    vm.createContractor = createContractor;
    vm.createForm = createForm;
    vm.updateContractor = updateContractor;
    vm.showNewContractor = showNewContractor;
    vm.showNewContractorShop = showNewContractorShop;
    vm.showNewContact = showNewContact;
    vm.addContractorShop = addContractorShop;
    vm.debounceSearch = debounceSearch;
    vm.searchShop = searchShop;
    vm.openCalendar = openCalendar;
    vm.confirmDelete = confirmDelete;
    vm.changeShopData = changeShopData;
    vm.changeContactData = changeContactData;
    vm.doSearchContractors = doSearchContractors;
    vm.onDivisionsChange = onDivisionsChange;
    vm.downloadExcel = downloadExcel;
    vm.importContractors = importContractors;
    vm.addContact = addContact;
    vm.deleteContact = deleteContact;
    vm.deleteContractorShops = deleteContractorShops;
    vm.validEditContact = validEditContact;
    vm.onCalendarChange = onCalendarChange;
    vm.closeThisModal = closeThisModal;
    vm.checkAll = checkAll;
    vm.calcCheckedAll = calcCheckedAll;
    vm.validDelete = validDelete;
    vm.validateAddingShop = validateAddingShop;

    init();

    function init(){
        getPage(0);
        getDataFilters();
    }

    function getDataFilters(){
        OrgstructService.getDivisions().then(function(result) {
            if(result.status === 200) {
                vm.allDivisions = result.data;
            }
        });

        OrgstructService.getRegions().then(function(result) {
            if(result.status === 200) {
                vm.allRegions = result.data;
                vm.possibleRegions = vm.allRegions;
            }
        });
    }

    function getData (requestObj, excel) {
        if(!requestObj) requestObj = {};
        requestObj.page = vm.links.page;

        if(!excel){
            vm.isLoading = true;
            PanelContractorsService.getContractors(requestObj).then(function(result) {
                if(result.status === 200){
                    vm.contractors = result.data.body;
                    vm.links = {
                        page: parseInt(result.headers()["x-page-number"]),
                        size: parseInt(result.headers()["x-page-size"]),
                        totalElements: parseInt(result.headers()["x-total-elements"]),
                        totalPages: parseInt(result.headers()["x-total-pages"])
                    };
                }else if(result.status === 400){
                    Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
                }else{
                    Notifications.danger('Ошибка! Не удается загрузить список контрагентов', '', 5000);
                }
            }, function(error) {
                Notifications.danger('Ошибка! Не удается загрузить список контрагентов', '', 5000);
                $log.log(error);
            }).finally(function(){
                vm.isLoading = false;
            });
        }else{
            PanelContractorsService.getContractorsExcel(requestObj).then(function(result) {
                if (result.status === 200 ||  result.status === 201){
                    var blob = new Blob([result.data], {
                        type: "application/vnd.ms-excel"
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    link.download = "contractors_"+moment().format('DD-MM-YYYY')+".xls"
                    link.click();

                    Notifications.success('Файл успешно загружен');
                }else if(result.status === 400){
                    Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
                }else{
                    Notifications.danger('Ошибка! Невозможно сформировать файл', '', 5000);
                }
            }, function(error) {
                Notifications.danger('Ошибка! Невозможно сформировать файл', '', 5000);
                $log.log(error);
            }).finally(function(){
                vm.isLoading = false;
            });
        }
    }

    function importContractors(file){
        if (file != null) {
            file.upload = Upload.upload({
                url: config.backendV2 + '/contractors',
                data: {file: file}
            });
            file.upload.then(function (result) {
                if (result.status == 200) {
                    Notifications.success('Контрагенты успешно импортированы', '', 5000);
                    getPage(0);
                }else if(result.status === 400) {
                    Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
                }else{
                    Notifications.danger('Ошибка! Не удается импортировать контрагентов', '', 5000);
                }
            });
        }
    }

    function downloadExcel(){
        doSearchContractors(true)
    }

    function getPage (page, clear) {
        vm.links.page = page;
        if (clear){
            vm.filters = {};
            vm.links = angular.copy(vm.tableSettings);
        }
        doSearchContractors();
    }

    function doSearchContractors(excel) {
        vm.editContractor = null;
        var requestObjSearch = angular.copy(vm.filters);

        (vm.filters.bindingFrom) && (requestObjSearch.bindingFrom = $filter('formatDefault')(vm.filters.bindingFrom, null, "YYYY-MM-DD"));
        (vm.filters.bindingTo) && (requestObjSearch.bindingTo = $filter('formatDefault')(vm.filters.bindingTo, null, "YYYY-MM-DD"));
        (vm.filters.size) ? (requestObjSearch.size = vm.filters.size) : (requestObjSearch.size = vm.links.size);

        if(vm.filters.regions){
            requestObjSearch.regionIds = vm.filters.regions.map(function(region){return region.id}).toString();
            delete requestObjSearch.regions;
        }
        if(vm.filters.divisions){
            requestObjSearch.divisionIds = vm.filters.divisions.map(function(division){return division.id}).toString();
            delete requestObjSearch.divisions;
        }

        getData(requestObjSearch, excel);
    }

    function viewEditContractor (row){
        vm.selectedRowId = row.id;
        vm.newContractor = null;
        vm.checkedAllBindings = false;
        vm.checkedAllContacts = false;
        vm.editContractor = angular.copy(row);
        vm.editContractorCopy = angular.copy(row);
        getContractorShops(vm.editContractor.bindings);
    }

    function getContractorShops(bindings){
        if(bindings && bindings.length !== 0){
            var idsShops = [];
            angular.forEach(bindings, function(binding){
                idsShops.push(binding.shop);
            });
            var reqShops = {
                ids: idsShops.toString()
            };
            if(idsShops.length > 0) {
                OrgstructService.getShops(reqShops).then(function (result) {
                    if (result.status === 200) {
                        angular.forEach(bindings, function(binding){
                            angular.forEach(result.data, function(shop){
                                if(binding.shop === shop.id){
                                    binding.ext = {};
                                    binding.ext.address = shop.address;
                                    binding.ext.locality = shop.locality;
                                    binding.ext.sap = shop.sap;
                                }
                            })
                        })
                    }
                })
            }
        }
    }

    function updateContractor(contractor){
        delete contractor.bindings;
        delete contractor.contacts;
        if(contractor.id) {
            PanelContractorsService.updateContractor(contractor.id, contractor).then(function (result) {
                if (result.status === 200) {
                    contractor = result.data.body;
                    angular.forEach(vm.contractors, function(contractorItem, index){
                        if(contractorItem.id === result.data.body.id){
                            vm.contractors[index] = result.data.body;
                            getContractorShops(vm.editContractor.bindings);
                        }
                    });
                    Notifications.success('Контрагент успешно сохранен', '', 5000);
                }else if(result.status === 400) {
                    Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
                }else{
                    Notifications.danger('Ошибка! Не удается сохранить контрагента', '', 5000);
                }
            }, function (error) {
                Notifications.danger('Ошибка! Не удается сохранить контрагента', '', 5000);
                $log.log(error);
            });
        }else{
            Notifications.danger('Ошибка! Не удается сохранить контрагента', '', 5000);
        }
    }

    function createContractor () {
        vm.editContractor = null;
        if (vm.newContractor && !vm.newContractor.id){
            PanelContractorsService.addContractor(vm.newContractor).then(function(result) {
                if(result.status === 200){
                    vm.newContractor = null;
                    Notifications.success('Контрагент успешно добавлен', '', 5000);
                    ngDialog.closeAll();
                    vm.getPage(0, true);
                }else if(result.status === 400) {
                    Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
                }else{
                    Notifications.danger('Ошибка! Не удается создать контрагента', '', 5000);
                }
            }, function(error) {
                Notifications.danger('Ошибка! Не удается создать контрагента', '', 5000);
                $log.log(error);
            });
        }else{
            Notifications.danger('Ошибка! Не удается создать контрагента', '', 5000);
        }
    }

    function showNewContractor(){
        vm.editContractor = undefined;
        vm.selectedRowId = null;
        vm.newContractor = {
            uniqueId: null,
            name: null,
            active: true
        };
        ngDialog.open({
            template: 'newContractor',
            scope: $scope,
            className: 'ngdialog-theme-plain dialog-adding-item dialog-contractor',
            showClose: false
        })
    }

    function createForm(form){ // TODO: to directive
        return new FormFactory(form);
    }

    function showNewContractorShop(){
        vm.foundSap = true;
        ngDialog.open({
            template: 'newContractorShop',
            scope: $scope,
            className: 'ngdialog-theme-plain dialog-adding-item dialog-contractor',
            showClose: false
        })
    }
    function showNewContact(){
        ngDialog.open({
            template: 'newContact',
            scope: $scope,
            className: 'ngdialog-theme-plain dialog dialog-contractor',
            showClose: false
        })
    }

    function validateAddingShop(){
        return !vm.foundSap || !vm.addingShop.from || !vm.addingShop.to || !vm.addingShop.contract || !vm.addingShop.email || !vm.addingShop.person || !vm.addingShop.phone
    }

    function addContractorShop(idContractor, addingShop) {
        var requestAddingShop = {
            shop: addingShop.id,
            from: $filter('getDateLocal')(addingShop.from, null, "YYYY-MM-DD"),
            to: $filter('getDateLocal')(addingShop.to, null, "YYYY-MM-DD"),
            contract: addingShop.contract,
            email: addingShop.email,
            person: addingShop.person,
            phone: addingShop.phone
        };

        closeThisModal();
        PanelContractorsService.addContractorShop(idContractor, requestAddingShop).then(function (result) {
            if (result.status === 200) {
                Notifications.success("Магазин добавлен", "", 5000);
                getContractorBindings(idContractor);
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Невозможно добавить магазин', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Невозможно добавить магазин', "", 5000);
        })
    }

    function addContact(idContractor, addingContact) {
        var requestAddingContact = {
            name: addingContact.name,
            email: addingContact.email,
            phone: addingContact.phone
        };
        closeThisModal();
        PanelContractorsService.addContact(idContractor, requestAddingContact).then(function (result) {
            if (result.status === 200) {
                getContractorContacts(idContractor);
                Notifications.success("Контакт добавлен", "", 5000);
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Невозможно добавить контакт', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Невозможно добавить контакт', "", 5000);
        })
    }

    function getContractorContacts(idContractor) {
        PanelContractorsService.getContacts(idContractor).then(function (result) {
            if (result.status === 200) {
                vm.editContractor.contacts = angular.copy(result.data.body);
                vm.editContractorCopy.contacts = angular.copy(result.data.body);
                vm.contractors.map(function(elem){
                    (elem.id === vm.editContractor.id) && (elem.contacts = result.data.body)
                })
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Невозможно загрузить контакты', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Невозможно загрузить контакты', "", 5000);
        })
    }

    function getContractorBindings(idContractor) {
        PanelContractorsService.getContractorShop(idContractor).then(function (result) {
            if (result.status === 200) {
                vm.editContractor.bindings = result.data.body;
                getContractorShops(vm.editContractor.bindings);
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Невозможно привязанные магазины', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Невозможно загрузить привязанные магазины', "", 5000);
        })
    }

    function confirmDelete(title, deleteMethod){ //deleteMethod - deleteContractorShops, deleteContact
        ngDialog.openConfirm({
            template: 'scripts/app/common/confirm-dialog.template.html',
            scope: $scope,
            data: {
                title: 'Вы действительно хотите удалить выбранные ' + title +'?'
            }
        }).then(function(e){
            deleteMethod();
        }, function () {});
    }

    function deleteContractorShops() {
        var idsShops = [];

         angular.forEach(vm.editContractor.bindings, function(item){
             if(item.remove){
                 idsShops.push(item.id);
             }
         });

        PanelContractorsService.deleteContractorShops(vm.editContractor.id, idsShops).then(function (result) {
            if (result.status === 200) {
                Notifications.success("Привязки магазинов успешно удалены", "", 5000);
                getContractorBindings(vm.editContractor.id);
                vm.checkedAllBindings = false;
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Не удается удалить привязки магазинов', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Не удается удалить привязки магазинов', "", 5000);
        })
    }

    function deleteContact() {
        var idsContacts = [];

        angular.forEach(vm.editContractor.contacts, function(item){
            if(item.remove){
                idsContacts.push(item.id);
            }
        });

        PanelContractorsService.deleteContact(vm.editContractor.id, idsContacts).then(function (result) {
            if (result.status === 200) {
                Notifications.success("Контакты успешно удалены", "", 5000);
                getContractorContacts(vm.editContractor.id);
                vm.checkedAllContacts = false;
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Не удается удалить контакт', '', 5000);
            }
        }, function () {
            Notifications.danger('Ошибка! Не удается удалить контакт', "", 5000);
        })
    }

    var debouncedUpdateTimer;
    function debounceSearch(sap){
        $timeout.cancel(debouncedUpdateTimer);
        debouncedUpdateTimer = $timeout(function () {
            searchShop(sap);
        }, 2000);
    }

    function searchShop(sap) {
        vm.isLoading = true;
        $timeout.cancel(debouncedUpdateTimer);
        vm.addingShop.locality = '';
        vm.addingShop.address = '';
        if(sap) {
            OrgstructService.getShops({sap: sap}).then(function (result) {
                if (result.status === 200) {
                    if(result.data[0]) {
                        vm.addingShop.id = result.data[0].id;
                        vm.addingShop.locality = result.data[0].locality;
                        vm.addingShop.address = result.data[0].address;
                        vm.foundSap = true;
                    }else{
                        vm.foundSap = false;
                    }
                }
            }).finally(function(){
                vm.isLoading = false;
            })
        }
    }

    function openCalendar($event, type, id) {
        $event.preventDefault();
        $event.stopPropagation();

        for (var typeItem in vm.calendarsOpen){
            if(vm.calendarsOpen[typeItem].length> 0){
                for (var item in vm.calendarsOpen[typeItem]){
                    vm.calendarsOpen[typeItem][item] = false;
                }
            }
        }
        vm.calendarsOpen[type][id] = true;
    }

    function changeShopData(bindingElem){
        var requestUpdate = {
            from: $filter('getDateLocal')(bindingElem.from, null, "YYYY-MM-DD"),
            to: $filter('getDateLocal')(bindingElem.to, null, "YYYY-MM-DD"),
            shop: bindingElem.shop
        };
        PanelContractorsService.updateContractorShop(vm.editContractor.id, bindingElem.id, requestUpdate).then(function (result) {
            if (result.status === 200) {
                Notifications.success('Данные обновлены', '', 5000);

            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Не удается загрузить список контрагентов', '', 5000);
            }
        },function () {
            Notifications.danger('Ошибка! Не удается сделать изменения в данных', '', 5000);
        })
    }

    function validEditContact(changedValue, copyValue){
        return (changedValue) && (changedValue !== copyValue)
    }

    function changeContactData(contactElem, index){
        if (!contactElem.id) {
            Notifications.danger('Ошибка! Не удается сделать изменения в данных', '', 5000);
            return;
        }

        var requestUpdate = {
            id: contactElem.id,
            name: contactElem.name,
            email: contactElem.email,
            phone: contactElem.phone
        };
        PanelContractorsService.updateContact(vm.editContractor.id, requestUpdate).then(function (result) {
            if (result.status === 200) {
                getContractorContacts(vm.editContractor.id);
                // vm.editContractorCopy.contacts[index] = result.data.body;
                // vm.editContractor.contacts[index] = result.data.body;
                Notifications.success('Данные обновлены', '', 5000);
            }else if(result.status === 400){
                Notifications.danger('Ошибка! ' + result.data.error.message, '', 5000);
            }else{
                Notifications.danger('Ошибка! Не удается загрузить список контрагентов', '', 5000);
            }
        },function () {
            Notifications.danger('Ошибка! Не удается сделать изменения в данных', '', 5000);
        })
    }

    function onDivisionsChange(){
        if(!vm.filters.divisions || vm.filters.divisions.length === 0){
            vm.possibleRegions = vm.allRegions;
            return;
        }

        vm.possibleRegions = [];
        var divisionsIds = vm.filters.divisions.map(function(divisionItem){
            return divisionItem.id;
        });

        angular.forEach(vm.allRegions, function(regionItem){
            if(!!~divisionsIds.indexOf(regionItem.divisionId))vm.possibleRegions.push(regionItem)
        });

        var regionFound;
        angular.forEach(vm.filters.regions, function(regionItem, index){
            regionFound = vm.possibleRegions.some(function (possibleRegion) {
                return possibleRegion.id === regionItem.id
            });
            (!regionFound) && (vm.filters.regions.splice(index, 1))
        });
    }

    function onCalendarChange(){
        if(+new Date(vm.addingShop.to) < +new Date(vm.addingShop.from)){
            vm.addingShop.to = null;
        }else{
            (vm.addingShop.from) && (vm.dateFromBindTo = new Date(vm.addingShop.from))
        }
    }

    function closeThisModal(){
        ngDialog.closeAll();
        vm.addingShop = {};
        vm.addingContact = {};
        vm.dateFromBindTo = null
    }

    function checkAll(checkedAll, items){
        angular.forEach(items, function(binding){
            binding.remove = checkedAll;
        });
    }

    function calcCheckedAll(items){
        return items.every(function (binding) {
            return binding.remove === true;
        });
    }

    function validDelete(items){
        if(vm.editContractor) {
            return items.some(function (item) {
                return item.remove
            });
        }
    }
});

import { db } from './db';

export * from './db';

export function refreshData() {
    const entities = ['users', 'shops', 'divisions', 'regions'];
    const tokenData = localStorage.getItem('ls.token');

    if (!tokenData) {
        throw new Error('Unauthorized');
    }

    const { token } = JSON.parse(tokenData);
    const promises = entities.map(async entity => {
        const lastModified = localStorage.getItem(`${entity}:last-modified`);

        try {
            const response = await fetch(`/api/orgstruct/${entity}/modified${lastModified ? `?modifiedAfter=${lastModified}` : ''}`, {
                headers: {
                    'x-auth-token': token,
                }
            });
    
            if (response.status === 200) {
                const lastModifiedHeader = response.headers.get('x-last-modified');
                let data;

                try {
                    data = await response.json();
                } catch {
                    //
                }

                if (data && lastModifiedHeader) {
                    await db.table(entity).bulkPut(data);
                    localStorage.setItem(`${entity}:last-modified`, lastModifiedHeader);
                }
            }
        } catch (error) {
            console.error(`data: failed to fetch ${entity} data:`, error);
        }
    });

    return Promise.all(promises);
}
var PanelContractorsService;

PanelContractorsService = function($http, config) {
    return {
        getContractors: function (params) {
            return $http.get(config.backendV2 + "/contractors", {params: params});
        },

        getContractorsExcel: function (params){
            return $http.get(config.backendV2 + "/contractors", {responseType: "arraybuffer", params: params, headers: {'Accept': 'application/vnd.ms-excel'}});
        },

        addContractor: function(contractor){
            return $http.post(config.backendV2 + "/contractors", contractor);
        },

        updateContractor: function(contractorId, params){
            return $http.put(config.backendV2 + "/contractors/" + contractorId, params);
        },

        addContractorShop: function(contractorId, params){
            return $http.post(config.backendV2 + "/contractors/" + contractorId + "/bindings", params);
        },

        getContractorShop: function(contractorId){
            return $http.get(config.backendV2 + "/contractors/" + contractorId + "/bindings");
        },

        updateContractorShop: function(contractorId, shopId, params){
            return $http.put(config.backendV2 + "/contractors/" + contractorId + "/bindings/" + shopId, params);
        },

        deleteContractorShops: function(contractorId, shopIds){
            return $http.delete(config.backendV2 + "/contractors/" + contractorId + "/bindings/" + shopIds);
        },

        addContact: function(contractorId, params){
            return $http.post(config.backendV2 + "/contractors/" + contractorId + "/contacts", params);
        },

        getContacts: function(contractorId){
            return $http.get(config.backendV2 + "/contractors/" + contractorId + "/contacts");
        },

        updateContact: function(contractorId, params){
            return $http.post(config.backendV2 + "/contractors/" + contractorId + "/contacts" , params);
        },

        deleteContact: function(contractorId, contactIds){
            return $http.delete(config.backendV2 + "/contractors/" + contractorId + "/contacts/" + contactIds);
        }
    }
};

angular.module('smartApp').factory('PanelContractorsService', PanelContractorsService);

PanelContractorsService.$inject = ['$http', 'config'];
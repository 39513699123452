'use strict';

angular.module('smartApp').factory('TaskListServiceHttp', function TaskServiceHttp($http, config, Upload, localStorageService, $q) {

    return {
        getAll: function () {
            return $http.get(config.backend + '/tasks-manual/');
        },
        // getFiltered: function (filters) {
        //     filters.size = 10;
        //     return $http.get(config.backend + '/tasks-manual/', {
        //         params: filters
        //     });
        // },
        getFiltered: function (filtersOrigin) {
            var filters = angular.copy(filtersOrigin);

            if (filters.role) {
                filters.role = filters.role.toUpperCase();
            }

            if (filters.taskIdParam) {
              filters.taskId = filters.taskIdParam;
              delete filters.taskIdParam;
            }

            return $http.get(config.backend + '/tasks-manual/all', {
                params: filters
            });
        },

        getTask: function (taskId) {
            return $http.get(config.backend + '/tasks-manual/' + taskId);
        },

        getResponse: function (responseId) {
            return $http.get(config.backend + '/tasks-manual/responses/' + responseId);
        },

        fetchMeterIndications: function (responseId) {
            return $http.get(config.backend + '/tasks-manual/meter/response/' + responseId);
        },

        getTaskAllStatuses: function (taskId, filters) {
            return $http.get(config.backend + '/tasks-manual/' + taskId + '/responses', {
                params: filters
            });
        },

        getExemplarsOfPeriodicTask: function (taskId, filters){
            filters.size = 10;
            return $http.get(config.backend + '/tasks-manual/periodic/' + taskId + '/exemplars', {
                params: filters
            });
        },

        getExemplarExcel: function (exemplarId, filters){
            return $http.get(config.backend + '/tasks-manual/' + exemplarId + '/responses', {responseType: "arraybuffer", params: filters, headers: {'Accept': 'application/vnd.ms-excel'}});
        },

        getTaskAllStatusesShort: function(taskId){
            return $http.get(config.backend + '/tasks-manual/' + taskId + '/responses/short');
        },

        getExcel: function (taskId, filters){
            return $http.get(config.backend + '/tasks-manual/' + taskId + '/responses', {responseType: "arraybuffer", params: filters, headers: {'Accept': 'application/vnd.ms-excel'}});
        },

        getFile: function (fileUrl){
            return $http.get(fileUrl, {responseType: "arraybuffer"});
        },

        changeStatusTask: function (taskResponseId, action, taskParams){
            return $http.post(config.backend + '/tasks-manual/responses/' + taskResponseId + '/' + action, taskParams)
        },

        changeComment: function (taskResponseId,  requestObj) {
            return $http({method: 'PATCH', url: config.backend + '/tasks-manual/responses/' + taskResponseId + '/comment', data: requestObj, noThrobber: true});
        },

        getOrgstuctDivisions: function (params) {
          return $http.get(config.backend + '/orgstruct/divisions', {
            params: params
          });
        },

        getHierarchyShops: function () {
            return $http.get(config.backend + '/tasks-manual/directory/shops/hierarchy', { timeout: 60 * 1000 * 2 });
           //return $http.get(config.backend + '/orgstruct/shops/hierarchy');
        },

        getHierarchyRegions: function (itemParams) {
            return $http.get(config.backend + '/orgstruct/regions/hierarchy', {
                params: itemParams
            });
        },

        getListExecutors: function (itemParams, type){
            return $http.get(config.backend + '/orgstruct/' + type, {
                params: itemParams
            });
        },

        getShopData:function (sapCodes) {
            return $http.get(config.backend + '/tasks-manual/directory/shops?sap=' + sapCodes.join(','));
        },

        createTask: function (taskParams) {
            return $http.post(config.backend + '/taskmanager/tasks', taskParams)
        },

        deleteTask: function (taskResponseId) {
            return $http.delete(config.backend + '/tasks-manual/' + taskResponseId)
        },

        deleteAttachment: function (attachmentUrl) {
            return $http.delete(attachmentUrl);
        },

        getExecutorInfo: function (itemParams) {
            return $http.get(config.backend + '/tasks-manual/executor', {
                params: itemParams
            });
        },

        getExecutorRoles: function (itemParams) {
            return $http.get(config.backend + '/tasks-manual/executor/role', {
                params: itemParams
            });
        },

        getUserPermissions: function (){
            var defer = $q.defer();
            var getFromLocalStorage = localStorageService.get("userPermissions")
            if(getFromLocalStorage){
                if(new Date().getTime() - getFromLocalStorage.time < config.userPermissionsCacheTime){
                    return $q.resolve(getFromLocalStorage.data);
                }
            }

            $http.get(config.backend + '/tasks-manual/user/permissions').then(data => {
                console.log("!!!!!!!!!", data)
                if(data.status !== 200) {
                    Notifications.danger('На сервере произошла ошибка. Попробуйте повторить позже');
                    defer.reject(data);

                }

                localStorageService.set("userPermissions", {data: data.data, time: new Date().getTime()})
                defer.resolve(data.data);


            });





            return defer.promise
        },

        getListMainAttachments: function (itemParams){
            return $http.get(config.backend + '/attachmentService/forTask', {
                params: itemParams,
                noThrobber: true
            });
        },

        getListResponseAttachments: function (itemParams){
            return $http.get(config.backend + '/attachmentService/forResponse', {
                params: itemParams
            });
        },

        uploadAttachments: function (type, id, attachments){
            //type=forResponse, forTask
            return Upload.upload({
                url: config.backend + '/attachmentService/' + type + '/' + id,
                data: {"attachments": attachments}
            })
        },

        getOpenTaskCounter: function (){
            return $http.get(config.backend + '/tasks-manual/counters');
        },

        notificationAll: function (){
            return $http.post(config.backend + '/tasks-manual/notification/all');
        },

        returnTask: function (responseId, taskParams){
            return $http.post(config.backend + '/tasks-manual/responses/' + responseId + '/return', taskParams);
        },

        stopPeriodicTask: function (periodicId, taskParams) {
            return $http.post(config.backend + '/tasks-manual/periodic/' + periodicId + "/stop", taskParams);
        },

        stopCleaningTask: function (taskResponseId, taskParams){
            return $http.post(config.backendV2 + '/tasks-manual/typed/cleaning/responses/' + taskResponseId + '/stop', taskParams)
        },

        changeDeadline: function (taskId, params) {
            return $http.put(config.backend + '/tasks-manual/' + taskId + "/deadline", '',  {params: params});
        },

        getFormInstance: function(formId) {
          return $http.get(config.backend + '/forms/forms/' + formId);
        },

        getFormTemplate: function(templateId) {
          return $http.get(config.backend + '/forms/templates/' + templateId);
        },

        getFormTemplateByInstanceId: function(instanceId) {
          return $http.get(config.backend + '/forms/forms/' + instanceId + '/template');
        },

        getFormAnswers: function(formId) {
          return $http.get(config.backend + '/forms/forms/' + formId + '/answers');
        },

        multiAnswerToFormQuestion: function(answers) {
          return $http.put(config.backend + '/forms/forms/answers', answers);
        },

        linkAttachmentToTask: function(taskId, uids) {
          return $http.post(config.backend + '/attachmentService/forTask/' + taskId + "/link", { uids: uids });
        },

        loadFormsTemplates: function() {
          return $http.get(config.backend + '/forms/templates/simple');
        },

        // body = [{ instanceId, pointId, resolution},...]
        updatePointsResolutions: function(body) {
          return $http.post(config.backend + '/forms/forms/resolutions', body);
        },
        getPointsResolutions: function(formId) {
            return $http.get(config.backend + '/forms/forms/' + formId + '/resolutions');
        },

        getResponseInventoryReport: function(responseId) {
            return $http.get(config.backend + '/tasks-manual/inventory/report', {
                params: { responseId },
                responseType: 'arraybuffer',
            });
        }
    }
});

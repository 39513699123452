'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('dashboard2', {
                parent: 'site',
                url: '/dashboard2?:id',
                data: {
                    roles: [],
                    breadcrumbs: [['calendar','Календарь проверок']]
                },
                params: {
                    id: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/dashboard2/dashboard2.html',
                        controller: 'Dashboard2Controller'
                    }
                }
            });
    });
(function() {
    'use strict';

    angular.module('smartApp').directive('chipsInput', chipsInput);

    function chipsInput() {
        var directive = {
            templateUrl: 'scripts/components/controls/chips-input/chips-input.view.html',
            bindToController: true,
            controller: СhipsInputController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                list: '=',
                hint: '=',
                changeVal: '&'
            },
            link: link
        };
        return directive;

        function link(scope, element) {

            scope.setFocus = setFocus;
            scope.selectChips = selectChips;

            function selectChips(event) {
                event.stopPropagation();
                element.find('input').blur();
            }

            function setFocus() {
                element.find('input').focus();
            }
        }
    }

    function СhipsInputController() {
        var vm = this;

        vm.inputVal = '';
        vm.add = add;
        vm.removeByKey = removeByKey;
        vm.remove = remove;

        init();

        function init() {
            vm.list = vm.list;
        }

        function add($event) {

            if (($event === 'button' || $event.keyCode === 13) && vm.inputVal) {
                vm.list.push(vm.inputVal);
                vm.inputVal = '';
                vm.changeVal();
            }
        }

        function removeByKey($event) {
            var last;
            if (vm.list.length && $event.keyCode === 8 && !vm.inputVal) {
                last = vm.list.length - 1;
                vm.list.splice(last, 1);
            }
        }

        function remove(i) {
            if (vm.list.length) {
                vm.list.splice(i, 1);
                vm.changeVal();
            }
        }
    }
})();

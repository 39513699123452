angular.module('smartApp').controller('XfilterController7', XfilterController7);
XfilterController7.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService'];

function XfilterController7($scope) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);


	
}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
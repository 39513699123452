(function() {
    'use strict';

    angular.module('smartApp').filter('startFrom', function() {
        return function(input, start) {
            if (!input) return;
            start = +start; //parse to int
            return input.slice(start);
        };
    });

    angular
        .module('smartApp')
        .directive('templateTableWithControls', templateTableWithControls);

    function templateTableWithControls() {
        var directive = {
            restrict: 'E',
            templateUrl: '/scripts/templates/templateWizard/template-table-with-controls.html',
            controller: TemplateTableWithControlsController,
            scope: {}
        };

        return directive;
    }

    TemplateTableWithControlsController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$filter',
        'DownloadService',
        'AssignTemplateServiceForTemplates',
        'AssignTemplateServiceForGroups',
        'AssignTemplateService',
        'TWTemplateService',
        'Notifications',
        'OrgstructService',
        'PanelRolesService',
        'PanelUsersService',
        'ngDialog',
        'moment',
        'localStorageService',
        'Pagination'
    ];

    function TemplateTableWithControlsController(
        $rootScope,
        $scope,
        $state,
        $filter,
        DownloadService,
        AssignTemplateServiceForTemplates,
        AssignTemplateServiceForGroups,
        AssignTemplateService,
        TWTemplateService,
        Notifications,
        OrgstructService,
        PanelRolesService,
        PanelUsersService,
        ngDialog,
        moment,
        localStorageService,
        Pagination
    ) {
        $scope.showActive = true;

        $scope.pageSizeAct = 5;
        $scope.selected = {
            groups: [],
            users: []
        };
        $scope.time = { from: null, to: null };
        $scope.allDayInterval = false;
        $scope.allUsers = [];
        $scope.changed = { value: false, type: [] };

        $scope.activeTemplateTab = 0;

        $scope.notificationSettings = [{
          type: 'SHOP_SUPERVISOR',
          title: 'Отправлять письмо о проведении проверки проверяющему'
        }, {
          type: 'SHOP_DIRECTOR',
          title: 'Отправлять письмо о проведении проверки заведующему магазином'
        }, {
          type: 'REGION_DIRECTOR',
          title: 'Отправлять письмо о проведении проверки региональному директору'
        }, {
          type: 'DIVISION_DIRECTOR',
          title: 'Отправлять письмо о проведении проверки директору ' + $filter('wordReplace')('division') + 'а'
        }, {
          type: 'CUSTOM_USER',
          title: 'Отправлять пользователям'
        }];

        $scope.equals = function(prop, val) {
            return function(item) {
                return item[prop] === val;
            };
        };
        $scope.greaterThan = function(prop, val) {
            return function(item) {
                return item[prop] > val;
            };
        };
        $scope.numberOfPagesAct = function() {
            if (!$scope.processBlocks) return;
            return Math.ceil(
                $scope.processBlocks.length / $scope.pageSizeAct
            );
        };

        $rootScope.$on("templateCreated", function(event, newTemplate){
            console.log(newTemplate)
            $scope.templates.push(newTemplate)
            $scope.pagination.page = 1;
            $scope.pagination.pageChange($scope.templates);
            $scope.pagination.total = $scope.templates.length;
        })

        $scope.pagination = Pagination.get();

        var handleTemplatesLoad = function(data) {
          if (!data) {
            data = [];
          }

          $scope.data = data.map(function(el) {
              el.settings = {
                  directorCommentNecessity: [
                      'Обязателен комментарий директора',
                      el.directorCommentNecessity
                  ],
                  supervisorCommentNecessity: [
                      'Обязателен комментарий проверяющего',
                      el.supervisorCommentNecessity
                  ]
              };
              return el;
          });

          $scope.pagination.total = $scope.data.length
          $scope.templates = $scope.allTemplates = $scope.data;
          $scope.pagination.pageChange($scope.templates);
        };

        $scope.createTemplate = function() {
            ngDialog.open(
                {
                    template: 'scripts/templates/templateWizard/ng-dialog-create-template.html',
                    className: 'ngdialog-theme-plain',
                    controller: ['$scope', 'TWTemplateService', function($scope, TWTemplateService) {
                        $scope.createTemplate = function() {
                            var template = {
                                name: $scope.selectedName,
                                active: true,
                                directorCommentNecessity: true,
                                supervisorCommentNecessity: true,
                                afterEnd: {
                                    shopDirector: true,
                                    supervisor: true
                                },
                                gradeProcessor: "simple",
                                accreditationLevel: "category",
                                viewMode: 'PROCESS'
                            }

                            TWTemplateService.createTemplate(template,
                                function (err, result) {
                                    if (err) {
                                        Notifications.danger("Ошибка создания шаблона! "+err+result)
                                        console.log(err);
                                        return;
                                    }
                                    Notifications.success("Шаблон успешно создан!")
                                    $rootScope.$broadcast("templateCreated", result);
                                }
                            );
                        }
                    }]
                }
            )
        };

        /**
        * @desc get data for viewing
        * @param {boolean} isInit - if first data loading in page
        */
        $scope.getTemplates = function(isInit) {
            $scope.isLoading = true;

            var filters = {
              active: $scope.showActive
            };

            TWTemplateService.getAllWithParams(filters, function(
                err,
                data
            ) {
                $scope.isLoading = false;

                if (err) {
                    Notifications.danger('Ошибка загрузки');
                    return;
                }

                handleTemplatesLoad(data);

                if (isInit && data && data.length) {
                    $scope.selectTemplate($scope.templates[0], true);
                }
            });
        };

        $scope.getBusinessLines = function(templateId, hasNoThrobber) {

            OrgstructService.getBusinessDirs({}, hasNoThrobber).then(function(dataAll) {
                if (dataAll.status === 200) {
                    TWTemplateService.getTemplatesBusinessDirs(
                        templateId,
                        hasNoThrobber
                    ).then(function(dataId) {
                        if (dataId.status === 200) {
                            $scope.businessLines = dataAll.data;
                            $scope.selected.businessLines = [];
                            angular.forEach(dataId.data, function(
                                businessLine
                            ) {
                                $scope.selected.businessLines.push(
                                    _.find(dataAll.data, {
                                        name: businessLine.name
                                    })
                                );
                            });
                        } else {
                            Notifications.danger(
                                'Не удается загрузить бизнес-направления'
                            );
                        }
                    });
                } else {
                    Notifications.danger(
                        'Не удается загрузить бизнес-направления'
                    );
                }
            });
        };

        $scope.downloadTemplate = function(template, e) {
            e.preventDefault();
            e.stopPropagation();

            if (!template.hasQuestions) {
              return;
            }

            DownloadService.downloadAdminTemplate(template.id);
        };

        $scope.downloadTemplateExcel = function(template, e) {
          e.preventDefault();
          e.stopPropagation();

          if (!template.hasQuestions) {
            return;
          }

          DownloadService.downloadAdminTemplateExcel(template.id);
        };

        $scope.switchTemplate = function(template, e) {
            e.preventDefault();
            e.stopPropagation();
            TWTemplateService.switchTemplate(template, function(err) {
                if (err) {
                    return;
                }
                template.active = !template.active;
            });
        };

        $scope.edit = function(template, e) {
            e.preventDefault();
            e.stopPropagation();
            $state.go('templateWizard.editor', { id: template.id });
        };

        $scope.filterByActive = function() {
          var filterByActive = $scope.showActive || undefined;

          $scope.isLoading = true;

          TWTemplateService.getAllWithParams({ active: filterByActive }, function(err, data) {
            $scope.isLoading = false;

            if (err) {
                Notifications.danger('Ошибка загрузки');
                return;
            }

            handleTemplatesLoad(data);

            if (!!$scope.searchValue) {
              $scope.search();
            } else {
              $scope.selectTemplate($scope.templates[0], true);
            }
          });
        };

        $scope.search = function() {
            var filterByKeyword = !!$scope.searchValue;

            if (!filterByKeyword) {
              $scope.templates = $scope.allTemplates;
            } else {
              $scope.templates = $scope.allTemplates.filter((el)=>{
                var hasKeyword = false;

                if (el.name) {
                  hasKeyword = el.name.toLowerCase().indexOf($scope.searchValue.toLowerCase()) !== -1;
                }

                return hasKeyword;
              });
            }

            $scope.pagination.page = 1;
            $scope.pagination.total = $scope.templates.length;
            $scope.pagination.pageChange($scope.templates);

            if ($scope.templates.length > 0) {
              $scope.selectTemplate($scope.templates[0], true);
            } else {
              $scope.activeTemplate = null;
            }
        }

        $scope.copyTemplate = function(template) {
            var _scope = $scope;
            ngDialog.open({
                template:
                    'scripts/templates/templateWizard/ng-dialog-create-template.html',
                className: 'ngdialog-theme-plain',
                controller: [
                    '$scope',
                    function($scope) {
                        $scope.title = 'Скопировать шаблон';
                        $scope.titleButton = 'Скопировать';
                        $scope.createTemplate = function() {
                            TWTemplateService.copyTemplate({
                                copiedTemplateId: template.id,
                                newTemplateName: $scope.selectedName
                            }).then(
                                function(result) {
                                    if (
                                        result.status === 200 ||
                                        status === 201
                                    ) {
                                        Notifications.success(
                                            'Шаблон скопирован'
                                        );
                                        _scope.getTemplates();
                                    } else {
                                        Notifications.danger(
                                            'Ошибка! Не удается скопировать шаблон'
                                        );
                                    }
                                },
                                function() {
                                    Notifications.danger(
                                        'Ошибка! Не удаетсяскопировать шаблон'
                                    );
                                }
                            );
                        };
                    }
                ],
                showClose: false
            });
        };

        $scope.saveTime = function(allDayInterval) {
            if (!allDayInterval) {
                if ($scope.time.from && $scope.time.to) {
                    $scope.activeTemplate.intervalStart = moment(
                        $scope.time.from
                    ).format('HH:mm');
                    $scope.activeTemplate.intervalEnd = moment(
                        $scope.time.to
                    ).format('HH:mm');
                } else if (!$scope.time.from) {
                    Notifications.danger('Не заполнено поле "Заполнять с"');
                    return;
                } else if (!$scope.time.to) {
                    Notifications.danger(
                        'Не заполнено поле "Заполнять до"'
                    );
                    return;
                }
            } else {
                $scope.activeTemplate.allDay = true;
                $scope.activeTemplate.intervalStart = $scope.time.from = null;
                $scope.activeTemplate.intervalEnd = $scope.time.to = null;
            }

            TWTemplateService.saveTemplate($scope.activeTemplate, function(
                err
            ) {
                if (err) {
                    Notifications.danger('Ошибка сохранения');
                    return;
                }
                Notifications.success('Успешно изменено');
            });
        };

        $scope.saveActiveTemplate = function() {
            TWTemplateService.saveTemplate($scope.activeTemplate, function(
                err
            ) {
                if (err) {
                    Notifications.danger('Ошибка сохранения');
                    return;
                }
                Notifications.success('Успешно изменено');
            });
        };

        $scope.visibilityTime = function() {
            $scope.allDayInterval = !$scope.allDayInterval;
            if (!$scope.allDayInterval) {
                $scope.saveTime(true);
            }
        };

        $scope.selectTemplate = function(template, hasNoThrobber) {
            $scope.activeTemplate = template;
            $scope.allDayInterval = !template.allDay;

            var allTypes = ['SHOP_DIRECTOR', 'SHOP_SUPERVISOR', 'REGION_DIRECTOR', 'DIVISION_DIRECTOR', 'CUSTOM_USER'];
            var defaultNotificationSettings = {
              notificationType: null,
              pdfForFixed: false,
              pdfForReview: false,
              excelForFixed: false,
              excelForReview: false,
              viewMode: 'ONLY_NEED_FIX_QUESTIONS'
            };
            var notificationSettings = $scope.activeTemplate.notificationSettings;

            allTypes.forEach(type => {
              notificationSettings[type] = Object.assign({},
                defaultNotificationSettings,
                { notificationType: type },
                notificationSettings[type]
              );
            });

            if (template.intervalStart && template.intervalEnd) {
                $scope.time.from = moment(
                    template.intervalStart,
                    'HH:mm'
                ).toDate();
                $scope.time.to = moment(template.intervalEnd, 'HH:mm').toDate();
            }

            $scope.actParams = {};

            $scope.getRecipients(hasNoThrobber);
            $scope.toRemoveRecipients = [];

            AssignTemplateService.get(template.id, hasNoThrobber)
                .then(function(result) {
                    if (result) {
                        console.log(result)
                        $scope.selected.groups = result.groups.map(el => {
                            return _.find($scope.groups, { name: el.name })
                        })
                    }
                });
            TWTemplateService.getProcessBlocks(template, hasNoThrobber, function(
                err,
                result
            ) {
                $scope.processBlocks = result;
            });
            $scope.getBusinessLines(template.id, hasNoThrobber);
        };

        $scope.init = function() {
            $scope.isLoading = false;
            $scope.data = [];
            $scope.dataForView = [];
            $scope.getTemplates(true);
        };

        $scope.onChange = function(type) {
            $scope.changed.value = true;
            $scope.changed.type.push(type);
        };

        $scope.saveChanges = function() {
            var businessDirsIds = [];
            if ($scope.changed.value) {
                if (~$scope.changed.type.indexOf('groups')) {
                    $scope.activeTemplate.groups = $scope.selected.groups;
                    AssignTemplateServiceForTemplates.update(
                        $scope.activeTemplate,
                        function() {
                            Notifications.success('Изменения сохранены');
                        }
                    );
                }
                if (~$scope.changed.type.indexOf('businessLines')) {
                    businessDirsIds = $scope.selected.businessLines.map(
                        function(businessLine) {
                            return businessLine.id;
                        }
                    );
                    if (businessDirsIds && businessDirsIds.length) {
                        TWTemplateService.updateTemplatesBusinessDirs(
                            $scope.activeTemplate.id,
                            businessDirsIds
                        ).then(function(data) {
                            if (data.status === 200) {
                                Notifications.success(
                                    'Изменения сохранены'
                                );
                            } else {
                                Notifications.danger(
                                    'Не удается обновить бизнес-направления'
                                );
                            }
                        });
                    } else {
                        TWTemplateService.deleteTemplatesBusinessDirs(
                            $scope.activeTemplate.id
                        ).then(function(data) {
                            if (data.status === 200) {
                                Notifications.success(
                                    'Изменения сохранены'
                                );
                            } else {
                                Notifications.danger(
                                    'Не удается обновить бизнес-направления'
                                );
                            }
                        });
                    }
                }
                $scope.changed.type = [];
                $scope.changed.value = false;
            } else {
                Notifications.warning('Нечего сохранять');
            }
        };

        $scope.saveActParams = function() {
            var isValid = _.every($scope.processBlocks, function(item) {
                return item.limit <= 100 && item.limit >= 0;
            });
            if (isValid) {
                TWTemplateService.saveActParams(
                    $scope.processBlocks,
                    $scope.activeTemplate,
                    function(err) {
                        if (err) {
                            Notifications.danger('Ошибка сохранения');
                            return;
                        }
                        Notifications.success('Успешно изменено');
                    }
                );
            } else {
                Notifications.warning(
                    'Значение должно быть в диапазоне от 0 до 100'
                );
            }
        };

        PanelRolesService.getAllUsers().success(function(result) {
            return ($scope.allUsers = result);
        });

        AssignTemplateServiceForGroups.query({}, function(result) {
            $scope.groups = result;
        });

        $scope.foundUsers = [];
        $scope.recipients = [];

        $scope.userSearch = function(reqSearch) {
            $scope.foundUsers = [];
            var selectedUsers = [];
            if (reqSearch.length >= 2) {
                PanelUsersService.userSearch(reqSearch).then(function(
                    dataUsers
                ) {
                    if (dataUsers && dataUsers.length !== 0) {
                        if (
                            $scope.selected.users &&
                            $scope.selected.users.length > 0
                        ) {
                            selectedUsers = angular.copy(
                                $scope.recipients.users.concat(
                                    $scope.selected.users
                                )
                            );
                        } else {
                            selectedUsers = angular.copy(
                                $scope.recipients.users
                            );
                        }
                        if (selectedUsers.length !== 0) {
                            $scope.foundUsers = dataUsers.filter(function(
                                elem
                            ) {
                                return !(
                                    selectedUsers
                                        .map(function(el) {
                                            return el.id;
                                        })
                                        .indexOf(elem.id) !== -1
                                );
                            });
                            if ($scope.foundUsers.length === 0) {
                                Notifications.warning(
                                    'Соответствий больше не найдено'
                                );
                            }
                        } else {
                            $scope.foundUsers = dataUsers;
                        }
                    }
                });
            }
        };

        $scope.addRecipients = function(recipients) {
            $scope.isLoading = true;
            var recipientsIds = recipients.map(function(elem) {
                return elem.id;
            });
            TWTemplateService.addRecipients(
                'SEND_NOTIFICATION_ON_CHECKLIST',
                $scope.activeTemplate.id,
                recipientsIds,
                function(err) {
                    $scope.isLoading = false;
                    if (err) {
                        Notifications.danger(
                            'Ошибка добавления получателей'
                        );
                        return;
                    }
                    $scope.selected = {
                        users: []
                    };
                    $scope.foundUsers = [];
                    $scope.getRecipients();
                }
            );
        };

        $scope.getRecipients = function(hasNoThrobber) {

            TWTemplateService.getRecipients(
                'SEND_NOTIFICATION_ON_CHECKLIST',
                $scope.activeTemplate.id,
                hasNoThrobber,
                function(err, result) {
                    if (err) {
                        Notifications.danger(
                            'Ошибка получения данных получателей'
                        );
                        return;
                    }
                    $scope.recipients.users = result;
                }
            );
        };

        $scope.removeRecipients = function(users) {
            $scope.isLoading = true;
            var idsRemoveUsers = users
                .filter(function(el) {
                    return el.toDelete;
                })
                .map(function(el) {
                    return el.id;
                });
            TWTemplateService.removeRecipients(
                'SEND_NOTIFICATION_ON_CHECKLIST',
                $scope.activeTemplate.id,
                idsRemoveUsers,
                function(err) {
                    $scope.isLoading = false;
                    if (err) {
                        Notifications.danger('Ошибка удаления получателей');
                        return;
                    }
                    $scope.getRecipients();
                }
            );
        };

        $scope.countRecipientsToDelete = function(users) {
            if (!users) return 0;
            return users.filter(function(el) {
                return el.toDelete;
            }).length;
        };




        $scope.init();
    }
})();

'use strict';

angular.module('smartApp').factory('UserService', function UserService($http, config) {

    return {
        getAll: function () {
            return $http.get(config.backend + '/account/getAll')
                .success(function(data) {
                    return data;
                });
        },
        getAllWithoutDirectors: function () {
            return $http.get(config.backend + '/account/getSupervisors')
                .success(function(data) {
                    return data;
                });
        },
        getAllNotAssignedSupervisors: function(templateId) {
            return $http.get(config.backend + '/account/getAllNotAssignedSupervisors?templateId=' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        getAllAssigned: function(templateId) {
            return $http.get(config.backend + '/account/getAllAssigned?templateId=' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        saveAssigned: function(users, templateId) {
            return $http.post(config.backend + '/account/saveAssigned?templateId=' + templateId, users)
                .success(function(data) {
                    return data;
                });
        },
        getUserById: function(id) {
            return $http.get(config.backend + '/account/getUserById?id=' + id)
                .success(function(data) {
                    return data;
                });
        }
    }

});
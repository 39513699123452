/**
 * Created by tyurin on 27.05.15.
 */


'use strict';

angular.module('smartApp')

	.service('HttpUtils', httpUtilsService);

httpUtilsService.$inject = ['config'];

function httpUtilsService(config){
	return {
		getParamString: function(object, disableEmptyValues){
			if(object){
				var params = [];
				for(var key in object){
					if(disableEmptyValues && !object[key])
						continue;
					var value = object[key];
					if(angular.isArray(value)){
						if(value.length)
							params.push(key + '=' + value.join(','));
					}else{
						params.push(key + '=' + value);
					}
				}
				return params.join('&');
			}else{
				return '';
			}
		},

		getQuery: function(url, params){
			if(angular.isString(url)){
				var paramString = this.getParamString(params, true);
				return encodeURI(config.backend + url + (paramString ? '?' + paramString : ''));

			}else throw 'Url is not string, but ' + url;
		}
	}
}



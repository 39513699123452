var PanelUsersService;

PanelUsersService = function($http, config, utils, Notifications) {
    return {
        getUsers: function(page, limit, login, noGroups, roles, showAll) {
            var params = {
                page: page,
                per_page: limit,
                login: login,
                no_groups: noGroups ? true : false,
                roles: roles,
                showAll: showAll
            };
            var query =
                config.backend +
                '/admin/users?' +
                utils.getParamString(params, true);
            return $http.get(query);
        },
        getUsersFiltered: function(page, limit, filters, sort) {
            // if(filters.divisions && filters.divisions.length == 0){
            //     //delete filters.divisions;
            // }else if(filters.divisions && filters.divisions.length > 0){

            //     filters.divisionsIds = filters.divisions.map(function(cur){return cur.id});
            // }
            //console.log(filters)
            if (filters.roleObj == undefined) {
                delete filters.role == undefined;
            } else {
                filters.role = filters.roleObj.name;
            }

            var params = {
                page: page,
                perPage: limit,
                sorts: sort,
                filters: filters
            };
            return $http.post(config.backend + '/admin/users_new', params);
        },
        getUsersWithRoles: function(roles) {
            return $http.get(
                config.backend + '/account/getUsersByRoles?userRoles=' + roles
            );
        },
        getUsersByRole: function(role, hasNoThrobber) {

            return $http.get(
                config.backend + '/account/getUsersByRole?userRole=' + role, {
                    noThrobber: hasNoThrobber
                }
            );
        },
        getUserStats: function(ids) {
          return $http.get(config.backend + '/xauth/statistics?userIds=' + ids.join(','));
        },
        getEnergetics: function(notBind) {
            return $http.get(
                config.backend +
                    '/account/getEnergetics' +
                    (notBind ? '?notBind=true' : '')
            );
        },

        getEngineers: function(notBind) {
            return $http.get(
                config.backend +
                    '/account/getEngineers' +
                    (notBind ? '?notBind=true' : '')
            );
        },

        createUser: function(user_info) {
            return $http.post(config.backend + '/admin/users/', user_info);
        },
        updateUser: function(user_to_update) {
            return $http.put(config.backend + '/admin/users', user_to_update);
        },
        deleteUser: function(ids) {
            return $http['delete'](config.backend + '/admin/users/' + ids);
        },
        getAllRoles: function() {
            return $http.get(config.backend + '/objects/authorities');
        },
        getUserInfo: function(id) {
            return $http.get(config.backend + '/admin/users/' + id);
        },
        getFullUserName: function(user) {
            return (
                user &&
                user.login +
                    ' ' +
                    (user.firstName || '') +
                    ' ' +
                    (user.lastName || '')
            );
        },
        getRoleName: function(role) {
            return role.description || role.name;
        },
        parse: function(headers) {
            var links, parts;
            parts = headers.split(',');
            links = {};
            angular.forEach(parts, function(p) {
                var name, page, queryString, section, url;
                section = p.split(';');
                url = section[0].replace(/<(.*)>/, '$1').trim();
                queryString = {};
                url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
                    $0,
                    $1,
                    $2,
                    $3
                ) {
                    return (queryString[$1] = $3);
                });
                page = queryString['page'];
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                name = section[1].replace(/rel="(.*)"/, '$1').trim();
                return (links[name] = page);
            });
            return links;
        },
        userSearch: function(search) {
            return this.getUsersFiltered(1, 1000, { login: search }).then(
                function(response) {
                    if (response.status === 200) {
                        return response.data;
                    } else if (response.status === 204) {
                        Notifications.warning('Пользователь не найден');
                    } else {
                        Notifications.danger('Ошибка поиска пользователя');
                    }
                }
            );
        }
    };
};

angular.module('smartApp').factory('PanelUsersService', PanelUsersService);

PanelUsersService.$inject = ['$http', 'config', 'HttpUtils', 'Notifications'];

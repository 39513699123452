/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 17.06.15.
 */

(function () {
	'use strict';
	angular.module('smartApp')

		.controller('CountersEntriesEditDialogController', countersEntriesEditDialogController)
		.controller('CountersEntriesEditController', countersEntriesEditController);


	countersEntriesEditController.$inject = ['$scope', '$filter', 'GroupShopService', 'ObjectServiceForDivisions', 'ObjectServiceForClusters', 'ObjectServiceForShops', 'CounterService', 'Principal', 'FormFactory', 'ngTableParams', 'ngDialog', 'Notifications'];
	countersEntriesEditDialogController.$inject = ['$scope', 'CounterService', 'FormFactory', 'Notifications'];

	function countersEntriesEditController($scope, $filter, GroupShopService, DivisionService, ClusterService, ShopService, CounterService, Principal, FormFactory, TableParams, Dialog, Notifications){

		$scope.canFilterDivisions = Principal.isInRole(Principal.ROLES.ROLE_MAIN_ENGINEER);
		$scope.openEditDialog = openEditDialog;
		$scope.initSelection = setSelected;
		$scope.loadReport = loadReport;
		$scope.toggleHistory = toggleHistory;
		$scope.mapArray = mapArray;

		$scope.multiselect = {};
		$scope.enableReport = false;
		$scope.filters = {
			dateFrom: null,
			dateTo: null,
			division: null,
			clusters: [],
			shops: [],
			search: null
		};
		$scope.divisions = [];
		$scope.clusters = [];
		$scope.shops = [];
		$scope.tableParams = new TableParams({
			page: 1,
			count: 25
		}, {
			total: 0,
			getData: function ($defer, params) {
				var _this = this;
				$scope.searchProgress = true;
				var page = params.page();
				var limit = params.count();
				var division = $scope.filters.division.id;
				var clusters = $scope.filters.clusters.map(function(e){return e.id});
				var shops = $scope.filters.shops.map(function(e){return e.id});
				var search = $scope.filters.search;
				CounterService.getCounters(division, clusters, shops, search, page, limit).success(function(result, status, headers){
					if(status === 200){
						$defer.resolve(result);
						_this.total = headers('x-total-count');
					}else{
						$defer.resolve([]);
						_this.total = 0;
					}
					$scope.searchProgress = false;
				});
			}
		});

		$scope.$watchCollection('filters.division', watchDivisions);
		$scope.$watchCollection('filters.clusters', watchClusters);

		init();

		function init(){
			if($scope.canFilterDivisions){
				loadDivisions();
			}else{
				loadAssignedDivision();
			}
			loadClusters();
			loadShops();
			//loadReport();
		}

		function loadDivisions(){
			DivisionService.query({}, function(result) {
				$scope.divisions = result;
			});
		}

		function loadAssignedDivision(){
			GroupShopService.getAssigned()
				.then(function(result){
					if(result.status === 200){
						$scope.divisions = result.data;
						$scope.filters.division = result.data[0];
					}else if(result.status === 204){
						Notifications.danger('Вы не привязаны к '+$filter('wordReplace')('division')+'у', 'Обратитесь к администратору системы')
					}
				})
		}

		function loadClusters(){
			ClusterService.query({}, function(result) {
				$scope.clusters = result;
			});
		}

		function loadShops(){
			ShopService.query({}, function(result) {
				$scope.shops = result;
			});
		}

		function watchDivisions(value){
			var select = $scope.multiselect.clusters;
			if(select && select.selected){
				var id = value && value.id;
				var i;
				for(i = 0; i < select.selected.length; i++){
					if(select.selected[i].divisionId !== id){
						select.removeChoice(i);
						i--;
					}
				}
				for(i = 0; i < $scope.filters.clusters.length; i++){
					if($scope.filters.clusters[i].divisionId !== id){
						$scope.filters.clusters.splice(i, 1);
						i--;
					}
				}
				select.updateSelectedFlag();
			}
		}

		function watchClusters(value, old){
			if(value && (value.length || old.length)){
				var select = $scope.multiselect.shops;
				var ids = value.map(function(e){return e.id});
				var i;
				for(i = 0; i < select.selected.length; i++){
					if(!_.includes(ids, select.selected[i].clusterId)) {
						select.removeChoice(i);
						i--;
					}
				}
				for(i = 0; i < $scope.filters.shops.length; i++){
					if(!_.includes(ids, $scope.filters.shops[i].clusterId)){
						$scope.filters.shops.splice(i, 1);
						i--;
					}
				}
				select.updateSelectedFlag();
			}
		}

		function setSelected(name, e){
			$scope.multiselect[name] = e;
			e.onRemoveCallback = function(scope){
				this.updateSelectedFlag(scope.$select);
			};
			e.onSelectCallback = function(scope){
				this.updateSelectedFlag(scope.$select);
			};
			e.isSelectAll = false;
			e.selectAll = function(){
				var _this = this;
				if(this.isSelectAll){
					angular.forEach(this.items, function(item){
						_this.select(item);
					})
				}else{
					var size = this.selected.length;
					for(var i = 0; i < size; i++){
						this.removeChoice(0);
					}
				}
				this.updateSelectedFlag()
			};
			e.updateSelectedFlag = function(){
				if(this.selected.length === 0){
					this.isSelectAll = false;
				}else if(this.items.length === 0){
					this.isSelectAll = true;
				}else{
					this.isSelectAll = null;
				}
			}
		}

		function loadReport(){
			$scope.enableReport = true;
			$scope.tableParams.reload();
		}

		function openEditDialog(counter, entry, next, prev){
			Dialog.open(
				{
					template: 'scripts/app/counters/editcounters/edit-entry.dialog.html',
					controller: 'CountersEntriesEditDialogController',
					className: 'ngdialog-theme-plain',
					showClose: true,
					closeByDocument: false,
					scope: $scope.$new(),
					data: {
						entry: entry,
						nextEntry: next,
						prevEntry: prev
					}
				}
			).closePromise.then(function(result){
					counter.$table.reload();
					if(!next && result.value){
						counter.currentEntry = result.value.entry;
					}
				});
		}

		function toggleHistory(counter){
			counter.$extend = !counter.$extend;
			if(!counter.$table){
				counter.$table = createTable(counter);
			}
		}

		function mapArray(array, key){
			key = key || 'id';
			return array.map(function(e){return e[key]});
		}

		function createTable(counter){
			return new TableParams({
				page: 1,
				count: 10
			}, {
				total: 0,
				getData: function ($defer, params) {
					var _this = this;
					var page = params.page();
					var limit = params.count();
					CounterService.getEntriesHistory(counter.id, page, limit).success(function(result, status, headers){
						if(status === 200){
							$defer.resolve(result);
						}else{
							$defer.resolve([]);
							_this.total = 0;
						}
						_this.total = headers('x-total-count');
					});
				}
			});
		}


	}

	function countersEntriesEditDialogController($scope, CounterService, FormFactory, Notifications){
		$scope.createForm = createForm;
		$scope.save = save;
		$scope.error = notifyError;
		$scope.progress = false;
		$scope.value = $scope.ngDialogData.entry.entry;
		$scope.next = $scope.ngDialogData.nextEntry && $scope.ngDialogData.nextEntry.entry;
		$scope.prev = $scope.ngDialogData.prevEntry && $scope.ngDialogData.prevEntry.entry;

		function createForm(form){ // TODO: to directive
			return new FormFactory(form);
		}

		function save(){
			$scope.progress = true;
			$scope.ngDialogData.entry.entry = $scope.value;
			CounterService.updateEntry($scope.ngDialogData.entry).success(function(result, status){
				if(status === 200){
					Notifications.success('Изменения успешно сохранены');
					$scope.closeThisDialog($scope.ngDialogData.entry);
				}
				$scope.progress = false;
			});
			return true;
		}

		function notifyError(){
			Notifications.danger('Ошибка', 'Не все поля заполнены верно');
		}
	}
})();

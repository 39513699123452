angular.module('smartApp').directive('vueComponent', function() {
  return {
    restrict: 'E',
    scope: {
      componentName: '@',
      sidePanel: '<',
      onSidePanelClose: '&',
    },
    link: function(scope, element) {
      import('@/main.js').then(({ mount }) => {
        app = mount(element[0], {
          componentName: scope.componentName,
          sidePanel: scope.sidePanel,
          onSidePanelClose: scope.onSidePanelClose
        });
      });
    }
  };
});
<script setup>
import { ref } from 'vue';
import { CustomTextarea, CustomButton, Modal } from '@eron/ui-kit';
import { db } from '@shared/data';
import { Notifications } from '@shared/notifications';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    getAvailableShops: {
        type: Function,
        required: true,
    },
});
const emit = defineEmits(['search']);
const searchString = ref();
const notFoundModalVisible = ref(false);
const notFoundModalContent = ref();

const search = async () => {
    const pattern = /([^ ,\n\s][a-zA-Zа-яА-Я0-9_-]*[^, \n\s]*)/gm;
    let patternResult = searchString.value.match(pattern);

    if (!patternResult) {
        Notifications.danger('Неверный формат данных');
        return;
    }

    patternResult = patternResult.map(item => item.trim().toLowerCase());

    const availableShops = props.getAvailableShops();
    const availableShopIds = availableShops.map(s => s.id);
    const foundShops = await db.shops
        .where('sap')
        .anyOf(patternResult)
        .and(shop => availableShopIds.includes(shop.id))
        .toArray();
    const foundShopIds = foundShops.map(shop => shop.id);
    const missingItems = _.difference(
        patternResult,
        foundShops.map(shop => shop.sap.trim().toLowerCase())
    );

    if (missingItems.length) {
        notFoundModalVisible.value = true;
        notFoundModalContent.value = missingItems.join(', ');
    }

    searchString.value = '';
    emit(
        'search',
        availableShops.filter(shop => foundShopIds.includes(shop.id))
    );
};

const onCopyClick = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(notFoundModalContent.value);
    }
};
</script>

<template>
    <div class="-relative">
        <custom-textarea :id="id" v-model.trim="searchString" placeholder="Введите список номеров, разделённый запятыми или пробелами" />

        <custom-button :disabled="!searchString" theme="green" text="Обработать" class="-absolute -bottom-3 -right-6" @click="search" />
    </div>

    <modal v-model="notFoundModalVisible" :focus-trap="false" :teleport-to="false" show-header sub-title="Не были найдены магазины с SAP">
        <template #default>
            {{ notFoundModalContent }}
        </template>

        <template #footer>
            <custom-button theme="honey" text="Скопировать" @click="onCopyClick" />
        </template>
    </modal>
</template>

(function() {
    'use strict';

  angular
        .module('smartApp')
        .directive('taskFullView', taskFulView);

    taskFulView.$inject = [];

    function taskFulView() {
        var directive = {
            templateUrl: 'scripts/app/tasks/task-full-view/task-full-view.view.html',
            bindToController: true,
            controller: TaskFullViewController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                userPermissions: '<',
                systemParams: '<'
            }
        };
        return directive;
    }

    TaskFullViewController.$inject = ['DownloadService', 'Notifications','TasksDefaultFilterService','$stateParams', '$state', 'TaskListServiceHttp', 'TasksService', 'Pagination'];

    function TaskFullViewController(DownloadService, Notifications, TasksDefaultFilterService, $stateParams, $state, TaskListServiceHttp, TasksService, Pagination) {
        var vm = this;
        var searchedResponses = null;
        var filteredResponses = null;

        vm.step = 10;
        vm.fullView = true;
        vm.displayedPages = 5;

        vm.isLinks = false;

        vm.tasksFilter = {};

        vm.filterByType =  filterByType;

        vm.getTaskInfo = getTaskInfo;
        vm.getTaskResponses = getTaskResponses;

        vm.taskInfo;
        vm.taskResponses = [];

        vm.settings = {
            params: null,
            permissions: null
        };

        vm.searchValue = '';

        vm.pagination = Pagination.get(onPaginationChange);
        vm.pagination.variants.push(50, 100);

        vm.downloadAttachments = function(){
            DownloadService.downloadTaskAttachments(vm.taskInfo.id);
        }

        vm.getTaskDescription = function(taskInfo) {
            return taskInfo.description.replace(/[\n\r]/g, '<br/>');
        };

        vm.createExcel = function(){
            TaskListServiceHttp.getExemplarExcel(vm.taskInfo.id).then(function(result) {
                if(result.status === 200 || result.status === 201){
                    var blob = new Blob([result.data], {
                        type: "application/vnd.ms-excel"
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    link.download = "Задача «"+ vm.taskInfo.name +"» (id " + vm.taskInfo.id + ").xls";
                    link.click();

                    Notifications.success('Файл успешно загружен');
                }else{
                    Notifications.danger('Ошибка! Невозможно сформировать файл');
                }
            }, function(error) {
                Notifications.danger('Ошибка! Невозможно сформировать файл');
                console.log(error);
            });
        }

         vm.statusesFilter = TasksDefaultFilterService.getStatusesFilter().map(el => {
            el.type = 'status';
            return el
         });
         vm.statusesFilter.push({title: "Просроченные", val:true, type: 'overdue'});

        vm.onStatusFiltersChange = function(statuses) {
            const selected = statuses.filter(el => el.isSelected).reduce((filters, status) => {
                if (status.type === 'overdue') {
                    filters.overdue = status.val;
                    return filters;
                }
                filters.status = filters.status ? filters.status + ',' + status.val : status.val;
                return filters;
            }, {});

            vm.tasksFilter.status = selected.status;
            vm.tasksFilter.overdue = selected.overdue;
            updatePagination();
        };

        vm.onSearchChange = function(id) {
            vm.tasksFilter.responseIds = id;
            updatePagination();
        };

        init();

        function init() {
            if(!$stateParams.id) {
                Notifications.danger('Ошибка! Вы не должны были сюда попасть');
                return;
            }

            async.parallel([
                function(callback){
                    getTaskResponses($stateParams.id, callback);
                },
                function(callback){
                    getTaskInfo($stateParams.id, callback);
                }
            ],
            function(err, results){
                if(err){
                    console.log(err, results);
                    Notifications.danger("Ошибка: "+err +results)
                    return;
                }

                vm.taskInfo = results[1];
                handleResponseLoad(results[0]);
            });

            vm.settings.params = vm.systemParams;
            vm.settings.permissions = vm.userPermissions;
        }

        function updatePagination() {
          vm.pagination.page = 1;
          vm.pagination.pageChange();
        }

        function getTaskInfo(taskId, cb) {
            TaskListServiceHttp
                .getTask(taskId)
                .then(function(resp) {
                    if(resp.status !== 200){
                        cb(resp.status, resp.headers('x-error-text'))
                    }

                    cb(null, resp.data)
                });
        }

        function getTaskResponses(taskId, cb) {
            const pagination = { page: vm.pagination.page - 1, size: vm.pagination.perPage };

            TaskListServiceHttp
                .getTaskAllStatuses(taskId, Object.assign({}, vm.tasksFilter, pagination))
                .then(function(resp) {
                    if(resp.status != 200){
                        cb(resp.status, resp.headers('x-error-text'))
                    }

                    cb(null, resp.data);
                });
        }

        function filterByType(state) {
            vm.tasksFilter.taskTypes = state.state;
            getTaskResponses();
        }

        function onPaginationChange() {
            getTaskResponses($stateParams.id, (err, result) => {
                if (err) {
                    Notifications.danger('Ошибка: ' + result);
                    return;
                }
                handleResponseLoad(result);
            });
        }

        function handleResponseLoad(result) {
            vm.taskResponses = result.content.map((el) => {
                el.taskType = vm.taskInfo.taskType;
                el.responseInTask = true;
                el.responseData = angular.copy(el); // для универсализации выдачи респонсов. Данные отображаемого респонса в объекте responseData
                el.userRole = vm.taskInfo.userRole;
                return el;
            });
            vm.pagination.total = result.totalElements;
        }
    }
})();


'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider.state('filter6', {
            parent: 'checklist',
            url: '/report6',
            data: {
                roles: ['REPORT_ACCREDITATION_CHECKLIST']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/checklist/clreports/clreports.html',
                    controller: 'ChecklistReportsController'
                }
            }
        });
    });
angular.module('smartApp').controller('XfilterController9', XfilterController9);
XfilterController9.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService', 'PWCategoryService'];

function XfilterController9($scope, UserService, ChecklistReportService, PanelGroupsService, PWCategoryService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);
	//$scope.users = [];
	$scope.fio = {selected:undefined}

	//$scope.selectAllTemplates()

	
	
}


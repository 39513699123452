angular.module('smartApp').factory('ChartsConfig', function ChartsConfig() {
  var barDefaultStyles = { backgroundColor: '#557a23', hoverBackgroundColor: '#8CC63F' };
  var barExceptSelectedStyles = { backgroundColor: 'rgba(78, 220, 110, 0.4)' };
  var barSelectedStyles = { borderColor: 'rgb(78, 220, 110)', borderWidth: 5 };

  var config = [
    {
      panelName: 'ProcessAnalytics',
      charts: [
        {
          id: 'processes-radar',
          type: 'radar',
          switchTypes: ['bar'],
          dataFetchMethod: 'getDBReport1',
          labelKey: 'categoryName',
          dataKey: 'grade',
          filter: {
            by: 'categoryId',
            key: 'categoryId'
          },
          getStyles: function(type) {
            var style = {
              radar: { backgroundColor: '#557a23', pointRadius: 7, pointHoverRadius: 7, pointBackgroundColor: '#dedede', pointHoverBackgroundColor: 'rgba(222, 222, 222, 0.7)' },
              bar: Object.assign({}, barDefaultStyles)
            };
            return style[type];
          },
          getExceptSelectedStyles: function(type) {
            var style = {
              radar: { pointBackgroundColor: 'rgba(222, 222, 222, 0.6)', pointRadius: 5, pointHoverRadius: 5 },
              bar: Object.assign({}, barExceptSelectedStyles)
            };
            return style[type];
          },
          getSelectedStyles: function(type) {
            var style = {
              radar: { pointRadius: 10, pointHoverRadius: 10, pointBorderColor: 'rgb(78, 220, 110)', pointBorderWidth: 3 },
              bar: Object.assign({}, barSelectedStyles)
            };
            return style[type];
          },
          placement: 'mainCharts',
          placementIndex: 0
        },
        {
          id: 'templates-bar',
          title: 'Оценка по шаблонам',
          type: 'horizontalBar',
          dataFetchMethod: 'getDBReport2',
          labelKey: 'templateName',
          dataKey: 'clGrade',
          width: '30%',
          filter: {
            by: 'templateId',
            key: 'templateId'
          },
          getStyles: function() {
            return Object.assign({}, barDefaultStyles);
          },
          getExceptSelectedStyles: function() {
            return Object.assign({}, barExceptSelectedStyles);
          },
          getSelectedStyles: function() {
            return Object.assign({}, barSelectedStyles);
          },
          placement: 'bottomCharts',
          placementIndex: 0
        },
        {
          id: 'shops-bar',
          title: 'Оценка магазина',
          type: 'bar',
          dataFetchMethod: 'getDBReport3',
          labelKey: 'shopName',
          dataKey: 'grade',
          width: '70%',
          filter: {
            by: 'shopId',
            key: 'shopId'
          },
          getStyles: function() {
            return Object.assign({}, barDefaultStyles);
          },
          getExceptSelectedStyles: function() {
            return Object.assign({}, barExceptSelectedStyles);
          },
          getSelectedStyles: function() {
            return Object.assign({}, barSelectedStyles);
          },
          placement: 'bottomCharts',
          placementIndex: 1
        }
      ]
      // rightFilters: [
      //   {
      //     title: 'Шаблоны',
      //     labelKey: 'name',
      //     dataFetchMethod: function() {
      //
      //     },
      //     filter: {
      //       by: 'templateId',
      //       key: 'id'
      //     }
      //   }
      // ]
    }
  ];

  return {
    getConfig: function() {
      return config;
    },
    getChartByIdInPanel: function(panel, chartId) {
      var chartsInPanel = panel.charts;
      var chart = null;

      for (var i = 0; i < chartsInPanel.length; i++) {
        chart = chartsInPanel[i];

        if (chart.id === chartId) {
          break;
        }
      }

      return chart;
    }
  };
});

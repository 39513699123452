import api from "@/api";
import axios from "axios";
import textTransform from "../../helpers/textTransform";
export default {
    namespaced: true,
    state: {
        bannersList: {
            bannersDto: [],
            pages_count: 0,
        },
        bannersFetchStatus: false,
        bannerSaveFetchStatus: false,
        taskSearchFetchStatus: false,
        taskSearchFetchError: false,
        businessUnits: [],
    },
    mutations: {
        setFetchStatus(state, payload) {
            state.bannersFetchStatus = payload;
        },
        setBannersList(state, payload) {
            state.bannersList = payload;
        },
        setBusinessUnits(state, payload) {
            state.businessUnits = payload;
        },
        setFetchSaveStatus(state, payload) {
            state.bannerSaveFetchStatus= payload;
        },
        setBannerData(state, payload) {
            const banner = state.bannersList.bannersDto.find((item)=> item.id === payload.bannerId )
            banner.blocks = payload.response;
        },
        setTaskSearchFetchStatus(state, payload) {
            state.taskSearchFetchStatus = payload;
        },
        setTaskSearchFetchError(state, payload) {
            state.taskSearchFetchError = payload;
        },
        setAttachmentsFetchStatus(state, payload) {
            state.fetchAttachmentsStatus = payload;
        },
    },
    actions: {
        fetchBannersList({ commit }, payload) {
            commit('setFetchStatus', true);
            const filtersQuery = {};
            for (let key in payload.filters) {
                if (payload.filters[key] !== "") filtersQuery[key] = payload.filters[key]
            }
            const params = {
                sort: textTransform.toSnakeCase(payload.sorting.sortBy) + `${payload.sorting.sortDesc ? ',desc': ',asc'}`,
                page: payload.page,
                size: payload.size,
                ...filtersQuery
            };
            return new Promise((resolve, reject) => {
                api.get(`api/fpabanners/banners/`, {params})
                    .then(response => {
                        resolve(response);
                        commit('setBannersList', response.data);
                    }).catch(error => {
                    commit('setBannersList', {
                        bannersDto: [],
                        pages_count: 0,
                    });
                    console.error('Error', error);
                }).finally(() => {
                    commit('setFetchStatus', false);
                })
            })
        },
        fetchBusinessUnits({ commit }) {
            return new Promise((resolve, reject) => {
                api.get('api/orgstruct/businessunit/list')
                    .then(response => {
                        resolve(response);
                        commit('setBusinessUnits', response.data);
                    }).catch(error => {
                    commit('setBusinessUnits', []);
                    console.error('Error', error);
                })
            })
        },
        saveBanner({ commit }, payload) {
            commit('setFetchSaveStatus', true);
            return new Promise((resolve, reject) => {
                api.post('api/fpabanners/banners/save', payload).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error('Error', error);
                    alert('Ошибка при сохранении, проверьте заполненность полей')
                }).finally(() => {
                    commit('setFetchSaveStatus', false);
                })
            })
        },
        fetchBannerData({ commit }, bannerId) {
            commit('setFetchStatus', true);
            return new Promise((resolve, reject) => {
                api.get(`api/fpabanners/banners/${bannerId}`).then(response => {
                        commit('setBannerData', {
                            response: response.data.bannerBlocks,
                            bannerId
                        })
                        resolve(response);
                    })
                }).catch(error => {
                    console.error('Error', error);
                }).finally(() => {
                    commit('setFetchStatus', false);
                })
        },
        fetchBannerBlockDelete({ commit }, contentId) {
            commit('setFetchStatus', true);
            return new Promise((resolve, reject) => {
                api.delete(`api/fpabanners/content/${contentId}`).then(response => {
                        resolve(response);
                    }).catch(error => {
                        alert(`Ошибка при удалении - ${error.message}`)
                        console.error('Error', error);
                    }).finally(() => {
                        commit('setFetchStatus', false);
                    })
            })
        },
        fetchBannerBlockUpdate({ commit }, payload) {
            commit('setFetchStatus', true);
            return new Promise((resolve, reject) => {
                api.post('api/fpabanners/content', payload, {
                    headers: {
                        "Content-Type": 'application/json',
                    }}).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    alert(`Ошибка при сохранении - ${error.message}`)
                    console.error('Error', error);
                }).finally(() => {
                    commit('setFetchStatus', false);
                })
            })
        },
        fetchContentAttachments({ commit }, contentId) {
            commit('setAttachmentsFetchStatus', true);
            return new Promise((resolve, reject) => {
                api.get(`api/fpabanners/attachment?contentId=${contentId}`).then(response => {
                        resolve(response.data);
                }).catch(error => {
                    console.error('Error', error);
                }).finally(() => {
                    commit('setAttachmentsFetchStatus', false);
                })
            })
        },
        fetchContentAttachmentDelete({ commit }, attachmentId) {
            return new Promise((resolve, reject) => {
                api.delete(`api/fpabanners/attachment/${attachmentId}`).then(response => {
                        resolve(response);
                    }).catch(error => {
                        alert(`Ошибка при удалении - ${error.message}`)
                        console.error('Error', error);
                    })
            })
        },
        fetchAddAttachment({ commit }, payload) {
            const formData = new FormData();
            formData.append('file', payload.file);
            formData.append('content', new Blob([JSON.stringify(payload.content)], { type: 'application/json' }));

            return new Promise((resolve, reject) => {
                axios.post('api/fpabanners/attachment', formData, {
                    headers: {
                        "x-auth-token": JSON.parse(localStorage.getItem('ls.token')).token,
                    }}).then(response => {
                    resolve(response);
                }).catch(error => {
                    alert('Ошибка при добавлении вложения')
                    console.error('Error', error);
                })
            })
        },
        fetchBannerLinkTask({ commit }, taskId) {
            commit('setTaskSearchFetchStatus', true);
            commit('setTaskSearchFetchError', false)
            return new Promise((resolve, reject) => {
                api.get(`api/tasks-manual/${taskId}`).then(response => {
                        if (response.status === 200) {
                            resolve(response.data);
                        } else commit('setTaskSearchFetchError', true)
                    }).catch(error => {
                        commit('setTaskSearchFetchError', true)
                        console.error('Error', error);
                    }).finally(() => {
                        commit('setTaskSearchFetchStatus', false);
                    })
            })
        },
    },
    getters: {
        getBannersFetchStatus(state) {
            return state.bannersFetchStatus;
        },
        getBannersList(state) {
            return state.bannersList;
        },
        getBusinessUnits(state) {
            return state.businessUnits;
        },
        getBannerSaveFetchStatus(state) {
            return state.bannerSaveFetchStatus;
        },
        getTaskSearchFetchStatus(state) {
            return state.taskSearchFetchStatus;
        },
        getTaskSearchFetchError(state) {
            return state.taskSearchFetchError;
        },
        getFetchAttachmentsStatus(state) {
            return state.fetchAttachmentsStatus
        }
    },
};

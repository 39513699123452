'use strict';

angular.module('smartApp').controller('TasksTemplatesController', function(
    $filter,
    $scope,
    ngDialog,
    $state,
    Principal,
    Notifications,
    TasksTemplateService,
    Pagination,
    localStorageService,
    AssignTemplateServiceForGroups,
    OrgstructService,
    projectConfig) {


    $scope.search = {string:undefined}
	$scope.pagination = Pagination.get(function(data) {
		this.viewPort = data;
	});

	$scope.createTemplate = function() {

        ngDialog.open(
            {
                template: 'scripts/templates/templateWizard/ng-dialog-create-template.html',
                className: 'ngdialog-theme-plain',
                controller: ['$scope', 'TWTemplateService', function($scope, TWTemplateService) {
                    $scope.createTemplate = function() {
                        var template = {
                            name: $scope.selectedName,
                            active: true,
                            directorCommentNecessity: true,
                            supervisorCommentNecessity: true,
                            afterEnd: {
                                shopDirector: true,
                                supervisor: true
                            },
                            gradeProcessor: "simple",
                            accreditationLevel: "category",
                            viewMode: 'PROCESS'
                        }

                        TasksTemplateService.createTemplate(template,
                            function (err, result) {
                                if (err) {
                                    Notifications.danger("Ошибка создания шаблона! "+err+result)
                                    console.log(err);
                                    return;
                                }
                                Notifications.success("Шаблон успешно создан!")
                                $state.go('tasksTemplates.editor', { id: result.id });
                            }
                        );
                    }
                }]
            }
        )
    };

    /**
    * @desc get data for viewing
    * @param {boolean} isInit - if first data loading in page
    */
    $scope.getTemplates = function(isInit) {
        $scope.isLoading = true;

        var params = {
            active: $scope.showActive,
            page: $scope.pagination.page,
            perPage: $scope.pagination.perPage,
            search: $scope.search.string,
            onlyActive: $scope.onlyActive
        };

        TasksTemplateService.getAll(params, function(err, data, headers) {
            if(localStorageService.get("perPage") !== $scope.pagination.perPage)localStorageService.set("perPage", $scope.pagination.perPage)

            $scope.isLoading = false;
            if (err) {
                Notifications.danger('Ошибка загрузки');
                return;
            }
            console.log(headers('x-total-count'))
            $scope.pagination.total = headers('x-total-count');
            $scope.pagination.pageChange(data);

            if (isInit && data && data.length) {
                $scope.selectTemplate($scope.templates[0], true);
            }
        });
    };

    $scope.copyTemplate = function(template) {
        var newTemplate = angular.copy(template)
        delete newTemplate.id;
        var _scope = $scope;
        ngDialog.open({
            template:
                'scripts/templates/templateWizard/ng-dialog-create-template.html',
            className: 'ngdialog-theme-plain',
            controller: [
                '$scope',
                function($scope) {
                    $scope.title = 'Скопировать шаблон';
                    $scope.titleButton = 'Скопировать';
                    $scope.createTemplate = function() {
                        newTemplate.name = $scope.selectedName

                        TasksTemplateService.createTemplate(newTemplate,
                            function (err, result) {
                                if (err) {
                                    Notifications.danger("Ошибка создания шаблона! "+err+result)
                                    console.log(err);
                                    return;
                                }
                                Notifications.success("Шаблон успешно скопирован!")
                                $state.go('tasksTemplates.editor', { id: result.id });
                            }
                        );
                    };
                }
            ],
            showClose: false
        });
    };


    $scope.selectTemplate = function(template){
        $scope.activeTemplate = template;

        $scope.activeTemplate.groups = $scope.activeTemplate.groupIds.map(el => {
            return _.find($scope.groups, { id: el })
        })
        $scope.activeTemplate.businessDirs= $scope.activeTemplate.businessDirIds.map(el => {
            return _.find($scope.businessLines, { id: el })
        })
        console.log( $scope.activeTemplate.groups)

    }

    $scope.saveTemplate = function(){
        $scope.activeTemplate.groupIds = $scope.activeTemplate.groups.map(el => el.id);
        $scope.activeTemplate.businessDirIds = $scope.activeTemplate.businessDirs.map(el => el.id)
        TasksTemplateService.updateTemplate($scope.activeTemplate, function(err, data){
            if(err){
                console.log(err, data)
                Notifications.danger("Ошибка сохранения шаблона задачи: ", err+data)
                return
            }
            Notifications.success("Шаблон задачи успешно изменён")
        })
    }

    $scope.switchTemplate = function(template, e) {
        e.preventDefault();
        e.stopPropagation();

        TasksTemplateService.switchTemplate(template, function(err, data) {
            if (err) {
                Notifications.danger('Ошибка переключения');
                return;
            }
            template.active = !template.active;
        });
    };

    $scope.edit = function(template, e) {
        e.preventDefault();
        e.stopPropagation();
        $state.go('tasksTemplates.editor', { id: template.id });
    };

    //init
    OrgstructService.getBusinessDirs({}).then(function(dataAll) {
        $scope.businessLines = dataAll.data;
    })
    AssignTemplateServiceForGroups.query({}, function(result) {
        $scope.groups = result;
    });
    $scope.getTemplates()

});

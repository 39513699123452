/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 09.06.15.
 */

'use strict';

angular.module('smartApp')

	.controller('CounterModelsListController', modelsListController);


modelsListController.$inject = ['$scope', '$state', 'CounterService', 'ngTableParams', '$filter', 'Notifications', 'ngDialog'];

function modelsListController($scope, $state, CounterService, TableParams, $filter, Notifications, Dialog){

	$scope.createNewModel = createNewModel;
	$scope.edit = edit;
	$scope.delete = deleteModel;
	$scope.search = search;
	$scope.itemsByPage = 20;
	$scope.models = [];
	$scope.searchValue = null;
	$scope.searchProgress = false;

	$scope.tableParams = new TableParams({
		page: 1,
		count: 10
	}, {
		total: 0,
		getData: function ($defer, params) {
			$scope.searchProgress = true;
			var data = params.filter() ? $filter('filter')($scope.models, params.filter()) : $scope.models;
			this.total = data.length;
			$defer.resolve(data.slice((params.page() - 1) * params.count(), params.page() * params.count()));
			$scope.searchProgress = false;
		}
	});

	init();

	function init(){
		CounterService.getAllModels().success(function(data, status){
			$scope.models = data;
		})
	}

	function edit(model){
		$state.go('counters.edit', {id: model.id});
	}

	function deleteModel(model){
		Dialog.openConfirm({
			template: 'scripts/app/common/confirm-dialog.template.html',
			scope: $scope,
			data: {title: 'Вы действительно хотите удалить модель?'}
		}).then(function(){
			CounterService.deleteModel(model.id).success(function(data, status){
				if(status === 200){
					removeFromData(model);
					Notifications.success('Модель успешно удалена');
				}else if(status === 400){
					Notifications.danger('Ошибка');
				}

			})
		});
	}

	function createNewModel() {
		$state.go('counters.new');
	}

	function search(value){
		var object = value && {model: value} || {};
		if(!angular.equals($scope.tableParams.filter(), object)){
			$scope.searchProgress = true;
			$scope.tableParams.filter(object);
		}
	}

	function removeFromData(model){
		var index = -1;
		_.forEach($scope.models, function(e, i){e.id === model.id && (index = i)});
		if(index >= 0){
			$scope.models.splice(index, 1);
			$scope.tableParams.reload();
		}
	}
}
angular.module('smartApp').factory('Filter5', checkReportFactory5);
checkReportFactory5.$inject = ['ChecklistService', 'DownloadService'];

function checkReportFactory5(ChecklistService, DownloadService){
	return {
		id: 5,
		title: 'Фильтр 5',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter5/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter5/view.html',
		isValid: function(filters) {
			return filters.rangeFrom &&	filters.rangeTo && filters.division && filters.division.length && filters.template;
		},
		load: function(filters) {
			return ChecklistService.getLightGreyReport(filters);
		},
		download: function(filters) {
			DownloadService.downloadLightGreyReport(filters);
		}
	};
}
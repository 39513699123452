<script setup>
import { ref, watch } from 'vue';
import { CustomInput } from '@eron/ui-kit';

import WeekdaysSelect from '../components/WeekdaysSelect.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const weeks = ref();
const days = ref([]);
let skipUpdate = false;

const emitChange = () => {
    emit('update:model-value', weeks.value && days.value.length ? { weeks: [weeks.value], days: days.value } : undefined);
    skipUpdate = true;
};

const validate = () => {
    return Boolean(weeks.value) && Boolean(days.value.length);
};

const onWeeksBlur = () => {
    weeks.value = weeks.value ? Math.max(weeks.value, 1) : weeks.value;
    emitChange();
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        weeks.value = Array.isArray(newVal?.weeks) && newVal.weeks.length ? newVal.weeks[0] : undefined;
        days.value = Array.isArray(newVal?.days) ? newVal.days : [];
    },
    { immediate: true }
);

defineExpose({
    validate,
});
</script>

<template>
    <div class="-flex -items-center">
        <span class="-mr-1.5 -text-sm -text-gray-moss">Каждую</span>
        <span class="-mr-1.5 -w-20">
            <custom-input id="weekly-settings-week" v-model="weeks" type="number" @blur="onWeeksBlur" />
        </span>
        <span class="-mr-1.5 -text-sm -text-gray-moss">неделю в указанные дни:</span>
        <weekdays-select id="weekly-settings-weekdays" v-model="days" class="-flex-grow" @update:model-value="emitChange" />
    </div>
</template>

var PanelClustersController;

PanelClustersController = function ($rootScope, $scope, $filter, PanelDivisionsService, PanelClustersService, PanelUsersService, Notifications, TableParams, PanelBusinessLineService, OrgstructService, Pagination, ngDialog) {
	var ROLE_DIRECTOR_CLUSTER = 'ROLE_DIRECTOR_CLUSTER';
	$scope.showActive = true;
	$scope.userService = PanelUsersService;
	$scope.isSelected = false;
	$scope.isLoading = true;
	$scope.selected_clusters = [];
	$scope.selected_row = null;
	$scope.cluster_to_search = null;
	$scope.tableSettings = {
		page: 1,
		limit: 10
	};
	$scope.shopPagination = {};
	$scope.data = [];
	$scope.directors = [];

	$scope.pagination = Pagination.get(function(data) {
		this.viewPort = data;
	});

	$scope.tableParams = new TableParams({
		page: 1,
		count: 5
	}, {
		counts: [5, 10, 25],
		total: 0,
		getData: function ($defer, params) {
			if($scope.cluster && $scope.cluster.shops){
				var page = params.page();
				var limit = params.count();
				var from = (page - 1) * limit;
				var to = from + limit;
				this.total = $scope.cluster.shops.length;
				$defer.resolve($scope.cluster.shops.slice(from, to))
			}else{
				$defer.resolve([]);
			}
		}
	});

    $scope.getClusterDirectors = getClusterDirectors;

	$scope.getData = function(isInit) {
		var page = $scope.pagination.page;
		var limit = $scope.pagination.perPage;

		PanelClustersService.getClusters(page, limit, $scope.cluster_to_search, !$scope.showActive).success(function (data, status, headers) {
			if (data) {
				$scope.links = PanelClustersService.parse(headers('link'));
				$scope.data = data;

				if (isInit && $scope.data && $scope.data.length) {
					$scope.editCluster(data[0], true);
				}

				$scope.pagination.total = headers('x-total-count');
				$scope.pagination.pageChange($scope.data);

				return $scope.isLoading = false;
			} else {
				return $rootScope.$broadcast('noResultsFound');
			}
		});
	}

	init();

	$scope.inCollection = function(collection, id){
		for(var i in collection){
			if (collection[i].id == id){return true;}
		}
		return false;
	}

	$scope.showAllDirectors = function(directors, e) {
		e.stopPropagation();

		ngDialog.open({
			template: 'directorsModal',
			data: directors,
			showClose: true
		});
	};

	$scope.clearInput = function() {
		$scope.cluster_to_search = '';
		$scope.getPage(1);
	};
	$scope.getPage = function (page) {
		$scope.tableSettings.page = page;
		$scope.getData();
	};

	$scope.OrgstructService = OrgstructService;

	$scope.editCluster = function (row, hasNoThrobber) {


		// We need to abuse this variable to keep the chain between the scopes of controller and ui-select
		$scope.cluster = undefined;

		$scope.selected_row = row.id;
		async.parallel([
		    function(callback){
			  PanelUsersService.getUsersByRole(ROLE_DIRECTOR_CLUSTER, hasNoThrobber).success(function(result){
				  callback(null, result);
			  });
		    },
		    function(callback){
		       PanelClustersService.getClusterInfo(row.id, hasNoThrobber).success(function (result, headers, status) {
		       		callback(null, result, headers, status);
		       });
		    },
		    function(callback){
		       PanelDivisionsService.getAllDivisions(hasNoThrobber).success(function (data, status, headers) {
		       		callback(null, data, headers, status);
		       });
		    },
			function(callback){
                PanelBusinessLineService.getAllBusinessDirs({}, hasNoThrobber).success(function (data, status, headers) {
					callback(null, data, headers, status);
				});
			}
		],
		// optional callback
		function(err, results) {
            $scope.directors = results[0];
            $scope.cluster = results[1][0];
            $scope.divisions = results[2][0];
            $scope.businessLines = results[3][0];
            $scope.shopPagination = {
                totalCount: null,
                offset: 5,
                index: 1,
                firstPage: 1,
                lastPage: 1
            };
            $scope.tableParams.reload();
		});
	};

    function getClusterDirectors(businessLine, flagFromBNUpdate){ //flagFromBNUpdate - этот флаг костылик, который нужен для того, чтобы обновить прежде всего allSelectedDirectors, а потом уже загружать информацию для БН

        var reqObjAvailableUsers = {
            active: true,
            levels: 'REGION',
            fields: 'businessDir,roles',
            //skipRegionId: $scope.cluster.id
        };

        var reqObjSelectedUsers = {
            active: true,
            levels: 'REGION',
            fields: 'businessDir,roles'
		};

    	if (businessLine && !flagFromBNUpdate){
            reqObjAvailableUsers.businessDirId = businessLine.id;
            reqObjSelectedUsers.businessDirId = businessLine.id;
            reqObjSelectedUsers.regionId = $scope.cluster.id;
		} else {
            reqObjSelectedUsers.regionId = $scope.cluster.id;
		}

        var initSelectedBLDirectors = [];
        var availableClusterDirectors = [];

        OrgstructService.getUsers(reqObjAvailableUsers).then(function(result) {
            if(result.status === 200){
                availableClusterDirectors = result.data;
                initSelectedBLDirectors = [];
                OrgstructService.getUsers(reqObjSelectedUsers).then(function(result) {
                    if(result.status === 200){
                        angular.forEach(result.data, function(element) {
                            initSelectedBLDirectors.push(_.find(availableClusterDirectors, {id: element.id}));
                        });
                        if(!businessLine || businessLine && flagFromBNUpdate){
                            $scope.allSelectedDirectors = angular.copy(initSelectedBLDirectors);
						}
                        if(!flagFromBNUpdate){
                            $scope.initSelectedBLDirectors = initSelectedBLDirectors;
                            $scope.availableClusterDirectors = availableClusterDirectors;
                            $scope.cluster.directors = $scope.initSelectedBLDirectors;
                        }

                        if (flagFromBNUpdate){
                            getClusterDirectors(businessLine, false);
                        }
                    }else{
                        Notifications.danger(' Ошибка! Не удается загрузить выбранных пользователей', '', 2000);
                    }
                }, function() {
                    Notifications.danger('Ошибка! Не удается загрузить выбранных пользователей', '', 2000);
                });
            }else{
                Notifications.danger('Ошибка! Не удается загрузить доступных пользователей', '', 2000);
            }
        }, function() {
            Notifications.danger('Ошибка! Не удается загрузить доступных пользователей', '', 2000);
        });

    };

	$scope.saveChangesInformation = function (form) {
		if(form.$invalid){
			Notifications.warning('Не все поля заполнены верно!');
			return false;
		}

		var sendCluster = {
			active: $scope.cluster.active,
			name: $scope.cluster.name,
			divisionId: $scope.cluster.division.id,
			sapLoginCluster: $scope.cluster.sapLoginCluster
		};

        PanelClustersService.updateRegion($scope.cluster.id, sendCluster).then(function (result) {
            if (result.status === 200) {
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.getData();
            } else if (result.headers('x-error') === 'DuplicateSapLoginCluster') {
            	Notifications.danger('Ошибка! Такой SAP логин ответственного уже существует.');
            } else {
                Notifications.danger('Ошибка! Не удается сохранить изменения', '', 3000);
            }
        }, function () {
            Notifications.danger('Ошибка! Не удается сохранить изменения', '', 3000);
        });
	};

    $scope.saveChangesDirectors = function () {
        var clusterDirectorsIds = [];

    	if($scope.cluster.businessLine){ //так как БН является фильтром и не взаимодействует с бекендом, то будем вручную фильтровать то, что нужно
    		var diff;
            clusterDirectorsIds = $scope.allSelectedDirectors.map(function(elem){ //$scope.allSelectedDirectors - все выбранные директора без учета БН
                return elem.id
            });

            //$scope.cluster.directors - выбранные директора в БН на текущий момент
    		angular.forEach($scope.cluster.directors, function(clusterDirector){ //эта логика для добавления выбранных директоров в текущий БН
               diff = null;
               diff = $scope.allSelectedDirectors.filter(function(initBLDirector){return clusterDirector.id === initBLDirector.id})[0];
                if(!diff){
                    clusterDirectorsIds.push(clusterDirector.id)
                }
			});
            //initSelectedBLDirectors -  выбранные директора в БН изначально с сервера
            angular.forEach($scope.initSelectedBLDirectors, function(initBLDirector){ //эта логика для удаления выбранных директоров из текущего БН
                diff = null;
                diff = $scope.cluster.directors.filter(function(clusterDirector){return initBLDirector.id === clusterDirector.id})[0]; //
                if(!diff){
                    var index = clusterDirectorsIds.indexOf(initBLDirector.id);
                    if (index > -1){
                        clusterDirectorsIds.splice(index, 1);
                    }
                }
            });
		} else {
            clusterDirectorsIds = $scope.cluster.directors.map(function(elem){
                return elem.id;
            });
		}

		if (clusterDirectorsIds.length > 0) {
            updateRegionDirectors('updateRegionDirectors', clusterDirectorsIds);
        }else{ //если пользователь не выбрал ни одного директора, удаляем всех соотвественно
            updateRegionDirectors('deleteRegionDirectors');
		}
    };

    function updateRegionDirectors(method, directorsIds){ //method - либо updateRegionDirectors, либо deleteRegionDirectors
        PanelClustersService[method]($scope.cluster.id, directorsIds).then(function (result) {
            if (result.status === 200) {
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.getData();
                getClusterDirectors($scope.cluster.businessLine, true);
                //$scope.getClusterDirectors();
            } else {
                Notifications.danger('Ошибка! Не удается обновить директоров ' + $filter('wordReplace')('clusters'), '', 2000);
            }
        }, function () {
            Notifications.danger('Ошибка! Не удается обновить директоров ' + $filter('wordReplace')('clusters'), '', 2000);
        });
	}

	function init(){
		$scope.getData(true);
	}
};

angular.module('smartApp').controller('CommonPanelClustersController', PanelClustersController);

PanelClustersController.$inject = ['$rootScope', '$scope', '$filter', 'PanelDivisionsService', 'PanelClustersService', 'PanelUsersService', 'Notifications', 'ngTableParams', 'PanelBusinessLineService', 'OrgstructService', 'Pagination', 'ngDialog'];

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('zoneWizard', {
                parent: 'wizards',
                url: '/zoneWizard',
                data: {
                    roles: ['ROLE_ADMIN'],
                    css: ['css/states/zone-wizard.css']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/constructor/zoneWizard/zoneWizard.html',
                        controller: 'ZoneWizardController'
                    }
                }
            });
    });
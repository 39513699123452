angular.module('smartApp').factory('Filter4', checkReportFactory4);
checkReportFactory4.$inject = ['ChecklistService', 'DownloadService'];

function checkReportFactory4(ChecklistService, DownloadService){
	return {
		id: 4,
		title: 'Фильтр 4',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter4/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter4/view.html',
		isValid: function(filters) {
			return filters.division && filters.division.length > 0;
		},
		load: function(filters) {
			return ChecklistService.getLightBlueReport(filters);
		},
		download: function(filters) {
			return DownloadService.downloadLightBlueReport(filters);
		}
	};

}
'use strict';

var InstructionController;

InstructionController = function($scope, Principal, $controller) {
	$scope.adminDocName;
	$scope.directorDocName;
	$scope.supervisorDocName;

	$scope.Principal = Principal;
}

angular.module('smartApp').controller('CommonInstructionController', InstructionController);

InstructionController.$inject = [
	'$scope',
	'Principal',
	'$controller'
]

angular.module('smartApp')
	.directive('processTree', ['PWCategoryService','Notifications', 'ProcessTreeUtils', 'TWQuestionService', 'ngDialog', '$timeout',
        function (PWCategoryService, Notifications, ProcessTreeUtils, TWQuestionService, ngDialog, $timeout) {
		return {
			restrict: 'E',

			scope:{onselect:'=', dataobject:'=', prefix:'@'},
			templateUrl: 'scripts/templates/processWizard/process-tree.html',

			link: function (scope, element, attrs) {

				/**
				 * Tree operations with nodes
				 */

                // filter

                if(!scope.prefix)scope.prefix="";
                scope.timerProcess = '';
                scope.timerArrow = '';
                scope.searchingValByProcess = '';
                scope.searchingValByQuestion = '';
                scope.arrOfFilteredProc = [];
                scope.arrOfFilteredQuest = [];
               
                scope.searchingByQuestion = function () {
                    if (scope.searchingValByQuestion.length < 3) return;
                        
                    TWQuestionService.getQuestionByValue(scope.searchingValByQuestion).then(function(questData) {
                        scope.arrOfFilteredQuest = questData;
                        showLinksInDialog('processLinkQuest');
                    });
                };

                scope.searchingByPprocesses = function () {
                    if (scope.searchingValByProcess.length < 3) return;
                    
                    PWCategoryService.getProcessByValue(scope.searchingValByProcess).then(function(procData) {
                        scope.arrOfFilteredProc = procData;
                        showLinksInDialog('processLinkProc');
                    });
                };
                
                function showLinksInDialog (templateId) {
                    scope.filterDialog = ngDialog.open({
                        template: templateId,
                        controller: ['$scope', function($scope) {
              
                        }],             
                        className: 'ngdialog-theme-plain dialog',
                        scope: scope,
                        showClose: false
                    });
                }

                scope.openProcess = function(processId) {
                    // закрываем акно с результатоми по поиску процессов
                    scope.filterDialog ? scope.filterDialog.close() : null;
                
                    // получаем процессы
                    PWCategoryService.getProcessPathById(processId).then(function(pathArr) {
                        // закрываем открытые процессы если есть
                        scope.dataobject.forEach(function(item) {
                            item.children = {};
                            if (item.icon == "folderOpen") {
                                autoClikByArrow(item.id);
                            }
                        });
                        console.log(pathArr.length)
                        if (!pathArr.length) {
                            // scope.nodeSelect(process);                            
                            autoClikByProcess(processId);
                        } else {
                            openProcess(pathArr, processId);
                        } 
                    });
                };

                function autoClikByArrow(id) {
                    scope.timerArrow = $timeout(function() { 
                    	if(!scope.prefix)scope.prefix = "";
                    	
                        var elem = angular.element("span#" + scope.prefix + id);
                        if(elem && elem.parent() && elem.parent().prev() && elem.parent().prev().prev()) {   
                        	console.log(elem.parent().prev().prev())
                            elem.parent().prev().prev().click();
                            $timeout.cancel(scope.timerArrow);
                        } else {
                            autoClikByArrow(id);
                        }
                    }, 300);
                }

                function autoClikByProcess(id) {
                    scope.timerProcess = $timeout(function() { 
                    	if(!scope.prefix)scope.prefix = ""
                        var elem = angular.element("span#" + scope.prefix + id);
                        if(elem && elem.parent()) { 
                       // console.log(elem.parent())  
                            elem.parent().click();
                            $timeout.cancel(scope.timerProcess);
                        } else {
                            autoClikByProcess(id);
                        }
                    }, 300);
                }

                function getElem(nodeId, pathArr, processId) {
                	if(!scope.prefix)scope.prefix = ""
                    $timeout(function() {
                        var elem = angular.element("span#" + scope.prefix + nodeId);
                        if(elem && elem.parent() && elem.parent().prev() && elem.parent().prev().prev()) {
                        //	console.log(elem.parent().prev().prev())
                            elem.parent().prev().prev().click();
                            openProcess(pathArr, processId);
                        } else {
                        $timeout(function() { 
                            getElem(nodeId, pathArr, processId);
                        }, 300);
                        }
                    }, 300);
                }

                function openProcess(pathArr, processId) {

                    var nodeId = pathArr.splice(0, 1);
                    if (nodeId.length) {
                        getElem(nodeId, pathArr, processId);
                    } else {
                        // scope.nodeSelect(process);
                        autoClikByProcess(processId);
                    }
                }
                // end filter


				scope.getNodes = function () {
					PWCategoryService.getAll(function(result) {
						for (var i = 0; i < result.length; i++) {
							scope.dataobject.push(ProcessTreeUtils.setupIconForNewNode(result[i]));
						}
					});
				};

				scope.getNode = function (node, cb) {
					PWCategoryService.getOne(node.id, function(result) {
					   cb(result);
					});
				};

				scope.init = function () {
					scope.getNodes();
				};

				scope.init();

				scope.toggleNode = function (node, expanded) {
					if (expanded) {
						if (Object.keys(node.children).length != 0 && node.children.length != 0) {
							node.icon = "folderOpen";
							return;
						}
						node.icon = "loading"
						scope.getNode(node, function (data) {
							node.icon = "folderEmpty";
							node.children = [];
							//если всё же приехали пустые массивы
							if (data.length == 0) {
								node.icon = "folderEmptyOpen";
								return;
							}

							node.icon = "folderOpen";
							node.children = [];
							for (var i = 0; i < data.length; i++) {
								node.children.push(ProcessTreeUtils.setupIconForNewNode(data[i]));
							}
						})
					} else {
						var ans = undefined;
						switch (node.icon) {
							case "folderEmptyOpen":
								ans = "folderEmpty";
								break;
							case "folderOpen":
								ans = "folder";
								break;
							default:
								ans = node.icon;
								break;
						}

						node.icon = ans;
					}
				};

				scope.showSelected = function (node) {
					scope.selectedNode = node;
					if(scope.onselect)scope.onselect(node)
					if (node.children == [] || node.children == {})$scope.toggleNode(node, true)
				};

				scope.treeOptions = {
					label: "Root",
					nodeChildren: "children",
					dirSelectable: true,
					injectClasses: {
						ul: "a1",
						li: "a2",
						liSelected: "a7",
						iExpanded: "a3",
						iCollapsed: "a4",
						iLeaf: "a5",
						label: "a6",
						labelSelected: "a8"
					}
				};

				/**
				 * The part below is taken from original angular-tree-control plugin.
				 * The original tree setting are initiated in the scope of the main controller,
				 * to which the directive instance belongs, so we dont have access to them from
				 * this scope. This is an implicit option spec that can be replaced by any other
				 * workaround that solves the issue with the defaultTreeOptions init in directive
				 * scope.
				 */

				scope.treeOptions.equality = function (a, b) {
					if (!a || !b)
						return false;
					a = shallowCopy(a);
					a[scope.treeOptions.nodeChildren] = [];
					b = shallowCopy(b);
					b[scope.treeOptions.nodeChildren] = [];
					return angular.equals(a, b);
				};

				scope.treeOptions.isSelectable = function (node) {
					return true;
				};

				function shallowCopy(src, dst) {
					if (angular.isArray(src)) {
						dst = dst || [];

						for (var i = 0; i < src.length; i++) {
							dst[i] = src[i];
						}
					} else if (angular.isObject(src)) {
						dst = dst || {};

						for (var key in src) {
							if (hasOwnProperty.call(src, key) && !(key.charAt(0) === '$' && key.charAt(1) === '$')) {
								dst[key] = src[key];
							}
						}
					}

					return dst || src;
				};

				scope.treeOptions.isLeaf = function (node) {
					return !node[scope.treeOptions.nodeChildren] || node[scope.treeOptions.nodeChildren].length === 0;
				};

			}
		};
}])
<script setup>
import { nextTick, onMounted, ref, useModel, watch } from 'vue';
import { SingleSelect, CustomInput, CustomCheckbox, CustomDatepicker, ULabel } from '@eron/ui-kit';

import { getTimezones } from '@/api/common/datetime';

import TimeSelect from '@/components/common/TimeSelect.vue';
import ExpansionPanel from '@/components/common/ExpansionPanel.vue';
import PeriodicitySelect from '../components/PeriodicitySelect.vue';
import DaysToCompleteInput from '../components/DaysToCompleteInput.vue';
import TasksGenerateWeeklySettings from './TasksGenerateWeeklySettings.vue';
import TasksGenerateMonthlySettings from './TasksGenerateMonthlySettings.vue';
import TasksGenerateQuarterlySettings from './TasksGenerateQuarterlySettings.vue';
import TasksGenerateHalfYearlySettings from './TasksGenerateHalfYearlySettings.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
    expanded: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value']);

const innerExpanded = useModel(props, 'expanded');
const name = ref();
const active = ref(true);
const period = ref();
const infinite = ref(false);
const periodicity = ref();
const periodicitySettings = ref();
const time = ref();
const deadlineTime = ref();
const deadlineByLocalTime = ref(false);
const timezoneId = ref();
const timezones = ref([]);
const periodicitySettingsRef = ref();
const daysToComplete = ref();
const resetDatepicker = ref(false)
let defaultTzId;
let skipUpdate = false;

const emitChange = () => {
    let activeFrom, activeTo, deadlineTimeEnabled, minutesToComplete;

    if (period.value) {
        [activeFrom, activeTo] = Array.isArray(period.value) ? period.value : [period.value];
    }

    if (deadlineTime.value) {
        const [hours, minutes] = deadlineTime.value.split(':');
        minutesToComplete = parseInt(hours) * 60 + parseInt(minutes);
        deadlineTimeEnabled = true;
    } else {
        deadlineTimeEnabled = false;
    }

    skipUpdate = true;
    emit('update:model-value', {
        ...props.modelValue,
        name: name.value,
        active: active.value,
        scheduledTime: time.value,
        deadlineByLocalTime: deadlineByLocalTime.value,
        timezoneId: timezoneId.value,
        generationRule: periodicity.value
            ? {
                  type: periodicity.value,
                  ...periodicitySettings.value,
              }
            : undefined,
        activeFrom,
        activeTo,
        minutesToComplete,
        deadlineTimeEnabled,
        daysToComplete: daysToComplete.value,
    });
};

const validate = async () => {
    if (!['daily', 'lastDayOfMonth'].includes(periodicity.value)) {
        // ожидание маунта компонента настроек периодичности
        await nextTick();
    }

    const requiredRefs = [name, time, timezoneId, periodicity];
    const validators = {
        period: () => {
            if (!period.value) return false;
            return infinite.value ? !!period.value : period.value.length === 2 && period.value.every(item => !!item);
        },
        periodicitySettings: () => (periodicitySettingsRef.value ? periodicitySettingsRef.value.validate() : true),
    };

    return requiredRefs.every(item => !!item.value) && Object.values(validators).every(item => item());
};

const onInfiniteChange = () => {
    if (infinite.value) {
        period.value = Array.isArray(period.value) ? period.value[0] : period.value;
    } else {
        period.value = Array.isArray(period.value) ? period.value : period.value ? [period.value] : undefined;
    }
    emitChange();
};

watch(
    () => props.modelValue,
    async newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        if (!newVal) {
            return;
        }

        name.value = newVal.name;
        active.value = newVal.active === false ? false : true;
        time.value = newVal.scheduledTime;
        deadlineByLocalTime.value = newVal.deadlineByLocalTime;
        timezoneId.value = newVal.timezoneId || defaultTzId;
        daysToComplete.value = newVal.daysToComplete;

        if (newVal.minutesToComplete) {
            const hours = Math.trunc(newVal.minutesToComplete / 60);
            const minutes = newVal.minutesToComplete % 60;
            deadlineTime.value = [String(hours).padStart(2, '0'), String(minutes).padStart(2, '0')].join(':');
        } else {
            deadlineTime.value = undefined
        }

        if (newVal.generationRule) {
            periodicity.value = newVal.generationRule.type;
            periodicitySettings.value = newVal.generationRule;
        } else {
            periodicity.value = undefined
            periodicitySettings.value = undefined
        }

        if (newVal.activeFrom && newVal.activeTo) {
            period.value = [new Date(newVal.activeFrom), new Date(newVal.activeTo)];
        } else if (newVal.activeFrom) {
            period.value = new Date(newVal.activeFrom);
            infinite.value = true;
        } else {
            period.value = undefined;
            infinite.value = false;
            resetDatepicker.value = true;
            await nextTick();
            resetDatepicker.value = false;
        }
    },
    { immediate: true }
);

onMounted(async () => {
    const result = await getTimezones();
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const resultTZ = result.data.find(tz => tz.name === localTZ);

    timezones.value = result.data;

    if (resultTZ) {
        defaultTzId = resultTZ.name
        timezoneId.value = defaultTzId;
    }
});

defineExpose({
    validate,
});
</script>

<template>
    <expansion-panel id="tasks-generate-rules" v-model="innerExpanded" title="Правила генерации задачи">
        <div class="-mb-6 -flex -items-end">
            <div class="-mr-4 -w-80 -flex-shrink-0">
                <u-label for-value="rule-name"> Название правила </u-label>
                <custom-input id="rule-name" v-model.trim="name" @update:model-value="emitChange" />
            </div>

            <custom-checkbox id="rule-active" v-model:checked="active" label="Активность" @update:checked="emitChange" />
        </div>

        <div class="-mb-6 -flex -items-end">
            <div class="-mr-4 -w-80 -flex-shrink-0">
                <u-label for-value="rule-period"> Период работы правила </u-label>
                <custom-datepicker v-if="!resetDatepicker" id="rule-period" v-model="period" :range="!infinite" class="-mt-2" @update:model-value="emitChange" />
            </div>

            <custom-checkbox id="rule-infinite" v-model:checked="infinite" label="Бессрочно" @update:checked="onInfiniteChange" />
        </div>

        <div class="-mb-6 -flex -items-end">
            <div class="-mr-4 -w-80 -flex-shrink-0">
                <u-label for-value="rule-periodicity"> Периодичность срабатывания </u-label>
                <periodicity-select
                    id="rule-periodicity"
                    v-model="periodicity"
                    @update:model-value="
                        periodicitySettings = undefined;
                        emitChange();
                    "
                />
            </div>

            <div>
                <u-label for-value="rule-time"> Время срабатывания </u-label>
                <time-select id="rule-time" v-model="time" @update:model-value="emitChange" />
            </div>
        </div>

        <tasks-generate-weekly-settings
            v-if="periodicity === 'weekly'"
            ref="periodicitySettingsRef"
            v-model="periodicitySettings"
            class="-mb-6"
            @update:model-value="emitChange"
        />
        <tasks-generate-monthly-settings
            v-else-if="periodicity === 'monthly'"
            ref="periodicitySettingsRef"
            v-model="periodicitySettings"
            class="-mb-6"
            @update:model-value="emitChange"
        />
        <tasks-generate-quarterly-settings
            v-else-if="periodicity === 'quarterly'"
            ref="periodicitySettingsRef"
            v-model="periodicitySettings"
            class="-mb-6"
            @update:model-value="emitChange"
        />
        <tasks-generate-half-yearly-settings
            v-else-if="periodicity === 'halfYearly'"
            ref="periodicitySettingsRef"
            v-model="periodicitySettings"
            class="-mb-6"
            @update:model-value="emitChange"
        />

        <div class="-mb-6 -w-80">
            <u-label for-value="rule-timezone"> Часовой пояс срабатывания </u-label>
            <single-select
                id="rule-timezone"
                v-model="timezoneId"
                :options="timezones"
                :clearable="false"
                label-prop="displayName"
                value-prop="name"
                @update:model-value="emitChange"
            />
        </div>

        <div class="-mb-6 -w-80">
            <u-label for-value="rule-days-to-complete"> Кол-во дней на выполнение задачи </u-label>
            <days-to-complete-input
                id="rule-days-to-complete"
                v-model="daysToComplete"
                @update:model-value="emitChange"
            />
        </div>

        <div class="-flex -items-end">
            <div class="-mr-4 -flex-shrink-0">
                <u-label for-value="rule-deadline"> Выполнить до (необязательно) </u-label>
                <time-select id="rule-deadline" v-model="deadlineTime" @update:model-value="emitChange" />
            </div>

            <custom-checkbox id="rule-local-time" v-model:checked="deadlineByLocalTime" label="По местному времени" @update:checked="emitChange" />
        </div>
    </expansion-panel>
</template>

angular.module('smartApp').factory('Xfilter3', checkXreportFactory3);
checkXreportFactory3.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory3(ChecklistService, DownloadService) {
	return {
		id: 8,
		title: 'Отчёт Проверки',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter3/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter3/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo && filters.divisionIds && filters.divisionIds.length > 0  && (!filters.checklistIds || filters.checklistIds.match(/^(?:\d\,?\s*)+\d?$/));
		},
		load: function(filters) {
 			return ChecklistService.getXreport3(filters); 
		},
		download: function(filters) {
			return DownloadService.downloadXreport3(filters);
		},
        downloadKPO: function (filters) {
            return DownloadService.downloadXreport3KPO(filters);
        }
	};

}
angular.module('smartApp').directive('dualListBox', function($timeout, $compile) {
  return {
    templateUrl: 'scripts/templates/dualListBox.html',
    scope: {
      model: '=',
      options: '<',
      enableBoxOnePagination: '<',
      boxOnePerPage: '<',
      boxOneTotalItems: '<',
      onBoxOnePageChange: '&',
      onBoxOneSearch: '&',
      defaultSearchBoxOne: '<',
      boxOneDataIsLoading: '<',
      onChange: '&',
      selectedListLabel: '@',
      nonSelectedListLabel: '@',
      filterClearLabel: '@',
      infoFiltered: '@',
      infoAll: '@',
      infoEmpty: '@',
      filterPlaceholder: '@',
      moveAllLabel: '@',
      removeAllLabel: '@',
      reset: '@'
    },
    compile: function(tElement, tAttrs) {
      var select = tElement.find('select');
      var value = tAttrs.valueProp ? 'obj.' + tAttrs.valueProp : 'obj';
      var label = null;

      if (tAttrs.labelExpression) {
        label = tAttrs.labelExpression;
      } else {
        label = tAttrs.labelProp ? 'obj.' + tAttrs.labelProp : 'obj';
      }

      var ngOptions = value + ' as ' + label + ' for obj in items track by obj.id';

      select.attr('ng-options', ngOptions);

      return {
        post: function(scope, element, attrs)  {
          scope.defaultSearchBoxOne = scope.defaultSearchBoxOne !== false ? true : false;

          scope.items = [];

          if (scope.defaultSearchBoxOne || !scope.enableBoxOnePagination) {
            return;
          }

          // wait until child directives render
          $timeout(function() {
            var boxOne = element.find('.box1');
            var container = element.find('.customDualListBox');
            var modelCache = scope.model;

            container.click(function(e) {
              var targetClassName = e.target.className;
              var targetParentClass = e.target.parentNode.className;
              var className = targetClassName + ' ' + targetParentClass;

              if (className.indexOf('move') !== -1 || className.indexOf('moveall') !== -1) {
                modelCache = scope.model;
              }
            });

            scope.$watch('boxOneDataIsLoading', function(isLoading) {
              if (isLoading) {
                boxOne.append($('<div class="loadingOverlay"></div>'));
              } else {
                boxOne.find('.loadingOverlay').remove();
              }
            });

            scope.$watch('reset', function(reset) {
              if (reset) {
                modelCache = scope.model;
              }
            });

            scope.$watch('model', function() {
              scope.onChange({ selected: scope.model, all: scope.items });
              modelCache = scope.model;
            }, true);

            scope.$watch('options', function(newVal, oldVal) {
              if (!scope.options) {
                return;
              }

              modelCache = (modelCache && modelCache.length) ? modelCache :
                           (scope.model && scope.model.length) ? scope.model : [];

              scope.items = angular.copy(newVal);

              modelCache.forEach((item, i) => {
                var ids = scope.items.map(opt => opt.id);
                var indexInOptions = ids.indexOf(item.id);

                if (indexInOptions === -1) {
                  scope.items.push(item);
                } else {
                  modelCache[i] = scope.items[indexInOptions];
                }
              });

              scope.model = modelCache;
            });

            if (!scope.defaultSearchBoxOne) {
              scope.boxOneSearchValue = '';
              scope.handleBoxOneSearch = function(searchValue) {
                scope.onBoxOneSearch({ searchValue: searchValue });
              };

              var bindedElement = compileSearchInput(scope, 'handleBoxOneSearch(boxOneSearchValue)', 'boxOneSearchValue', scope.filterPlaceholder);

              boxOne.find('.info-container').after(bindedElement);
            }

            if (scope.enableBoxOnePagination) {
              scope.boxOnePage = 1;

              scope.handleBoxOnePageChange = function(page) {
                if (scope.onBoxOnePageChange) scope.onBoxOnePageChange({ page: page });
              };
            }
          });

          function compileSearchInput(scope, handlerString, modelString, placeholder) {
            var searchInputTemplate = '<input class="filter form-control customDualListBoxSearch" type="text" ng-change="' + handlerString + '" ng-model="' + modelString + '" placeholder="' + (placeholder || 'Поиск') + '" ng-enter="$event.stopPropagation()"/>';
            var linkFn = $compile(searchInputTemplate);
            return linkFn(scope);
          }
        }
      }
    }
  };
});

angular.module('smartApp').controller('PhotoReportCheckController', ['$rootScope',
                                                                     '$scope',
                                                                     '$stateParams',
                                                                     'Notifications',
                                                                     'ngDialog',
                                                                     'TaskListServiceHttp',
                                                                     'Pagination',
                                                                     'ImageModal', function($rootScope, $scope, $stateParams, Notifications, ngDialog, TaskListServiceHttp, Pagination, ImageModal) {
  var stateChangeListener = $rootScope.$on('$stateChangeStart', () => {
    ngDialog.closeAll();
  });

  var taskId = null;

  $scope.$on('$destroy', function () {
    stateChangeListener();
  });

  var selectedAttachments = [];
  var allExecutors = [];

  $scope.activeExecutor = null;
  $scope.activeExecutorAttachments = null;
  $scope.executors = [];
  $scope.searchVal = '';

  $scope.pagination = Pagination.get();
  $scope.pagination.perPage = 15;

  $scope.search = function() {
    if (!$scope.searchVal) {
      $scope.executors = allExecutors;
    } else {
      $scope.executors = allExecutors.filter(ex => ex.name.toLowerCase().indexOf($scope.searchVal.toLowerCase()) !== -1);
    }

    var firstEx = $scope.executors[0];

    if (firstEx) {
      selectExecutor(firstEx);
      loadAttachments(firstEx.responseId);
    } else {
      $scope.activeExecutor = null;
    }
  };

  $scope.handleExecutorSelect = function(executor) {
    if (executor.id === $scope.activeExecutor.id) {
      return;
    }

    selectExecutor(executor);
    selectedAttachments = [];
    loadAttachments(executor.responseId);
  };

  $scope.toggleAttachment = function(attachment) {
    if (attachment.selected) {
      selectedAttachments.splice(selectedAttachments.indexOf(attachment), 1);
    } else {
      selectedAttachments.push(attachment);
    }

    attachment.selected = !attachment.selected;
  };

  $scope.createPretension = function() {
    if (!selectedAttachments.length) {
      Notifications.danger('Выберите одно или несколько фото для создания претензии!');
      return;
    } else if ($scope.activeExecutor.taskStatus !== 'CLOSED') {
      Notifications.danger('Задача не завершена!');
      return;
    }

    // async.parallel([
    //   function(cb) {
    //     getExecutorInfo($scope.activeExecutor.role, $scope.activeExecutor.id, cb);
    //   },
    //   function(cb) {
    //     TaskListServiceHttp.getTask($stateParams.id).then(function(result) {
    //       if (result.status >= 300 || result.status < 200) {
    //         cb(true);
    //         return;
    //       }
    //
    //       cb(null, result.data.name);
    //     });
    //   }
    // ], function(err, result) {
    //   if (err) {
    //     Notifications.danger('Ошибка создания претензии!');
    //     return;
    //   }
    //
    //   ngDialog.open({
    //     template: 'createPretension',
    //     className: 'ngdialog-theme-plain pretension-dialog',
    //     data: {
    //       attachments: selectedAttachments,
    //       executors: [{
    //         name: $scope.activeExecutor.name,
    //         role: $scope.activeExecutor.role,
    //         regionId: result[0].regionId,
    //         divisionId: result[0].divisionId,
    //         shopId: result[0].shopId,
    //       }],
    //       taskName: 'Претензия по задаче "' + result[1] + '"'
    //     },
    //     disableAnimation: true
    //   });
    // });

    TaskListServiceHttp.getTask($stateParams.id).then(function(result) {
      if (result.status !== 200) {
        Notifications.danger('Ошибка создания претензии!');
        return;
      }

      ngDialog.open({
        template: 'createPretension',
        className: 'ngdialog-theme-plain pretension-dialog',
        data: {
          attachments: selectedAttachments,
          executors: [angular.copy($scope.activeExecutor)],
          taskName: 'Претензия по задаче "' + result.data.name + '"'
        },
        disableAnimation: true
      });
    });
  };

  $scope.getPage = function(page) {
    getResponses({ size: $scope.pagination.perPage, page: page - 1, status: 'CLOSED' }, true);
  };

  $scope.openImageModal = function(attachment) {
    ImageModal.open(attachment.url);
  };

  function getExecutorInfo(executorRole, executorId, cb) {
    switch (executorRole) {
      case 'REGION':
        getInfoForRegionExecutor(executorId, cb);
        break;
      case 'SHOP':
        getInfoForShopExecutor(executorId, cb);
        break;
      case 'DIVISION':
        getInfoForDivisionExecutor(executorId, cb);
        break;
    }
  }

  function getInfoForRegionExecutor(executorId, cb) {
    TaskListServiceHttp.getHierarchyRegions({ withDirs: true }).then(result => {
      if (result.status >= 300 || result.status < 200) {
        cb(true);
        return;
      }

      var divisions = result.data;
      var divisionId = null;
      var regionId = null;

      divisions.every(division => {
        return division.regions.every(region => {
          return region.dirs.every(dir => {
            if (dir.id === executorId) {
              divisionId = division.id;
              regionId = region.id;
              return false;
            }

            return true;
          });
        });
      });

      cb(null, { regionId: regionId, divisionId: divisionId });
    });
  }

  function getInfoForShopExecutor(executorId, cb) {
    TaskListServiceHttp.getHierarchyShops().then(result => {
      if (result.status >= 300 || result.status < 200) {
        cb(true);
        return;
      }

      var divisions = result.data;
      var shopId = null;
      var regionId = null;
      var divisionId = null;

      divisions.every(division => {
        return division.children.every(region => {
          return region.children.every(shop => {
            if (shop.directorId === executorId) {
              divisionId = division.id;
              regionId = region.id;
              shopId = shop.id;
              return false;
            }

            return true;
          });
        });
      });

      cb(null, { regionId: regionId, divisionId: divisionId, shopId: shopId });
    });
  }

  function getInfoForDivisionExecutor(executorId, callback) {
    TaskListServiceHttp.getOrgstuctDivisions().then(result => {
      if (result.status >= 300 || result.status < 200) {
        cb(true);
        return;
      }

      var divisions = result.data;
      var divisionId = null;

      divisions.every(division => {
        return division.dirs.every(dir => {
          if (dir.id === executorId) {
            divisionId = division.id;
            return false;
          }

          return true;
        });
      });

      cb(null, { divisionId: divisionId });
    });
  }

  function init() {
    taskId = $stateParams.id;

    if (!taskId) {
      Notifications.danger('Ошибка! Не указан id задачи.');
      return;
    }


    getResponses({ size: $scope.pagination.perPage, page: $scope.pagination.page - 1, status: 'CLOSED' });
  }

  function getResponses(params, search) {
    TaskListServiceHttp.getTaskAllStatuses(taskId, params).then(function(result) {
      if (result.status !== 200) {
        Notifications.danger('Ошибка! Не удалось загрузить ответственных.');
        return;
      }

      $scope.pagination.total = result.data.totalElements;

      allExecutors = result.data.content.map(item => {
        return {
          id: item.executorId,
          name: item.executorName,
          role: item.executorRole,
          responseId: item.id,
          taskStatus: item.status,
          active: false
        };
      });

      $scope.executors = allExecutors;

      var ex = $scope.executors[0];

      if (ex) {
        if (search) {
          $scope.search();
        } else {
          selectExecutor(ex);
          loadAttachments(ex.responseId);
        }
      }
    });
  }

  function loadAttachments(responseId) {
    $scope.activeExecutorAttachments = null;

    TaskListServiceHttp.getListResponseAttachments({ responseId: responseId }).then(function(result) {
      if (result.status >= 300 || result.status < 200) {
        Notifications.danger('Ошибка! Не удалось загрузить вложения.');
        return;
      }

      $scope.activeExecutorAttachments = result.data.attachments;
    });
  }

  function selectExecutor(executor) {
    if ($scope.activeExecutor) {
      $scope.activeExecutor.active = false;
    }

    executor.active = true;
    $scope.activeExecutor = executor;
  }

  init();
}]);

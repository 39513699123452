angular.module('smartApp').factory('ChartsService', ['$http', 'HttpUtils', function ChartsService($http, HttpUtils) {
  var sortChartDataAndLabels = function(data, labels) {
    var swapped = false;
    var index = 0;

    for (let i = 0; i < data.length; i++) {
      var currentData = data[i];
      var nextData = data[i + 1];
      var diff = currentData - nextData;

      if (diff > 0) {
        var currentLabel = labels[i];
        var nextLabel = labels[i + 1];

        swapped = true;

        data[i] = nextData;
        data[i + 1] = currentData;
        labels[i] = nextLabel;
        labels[i + 1] = currentLabel;
      }
    }

    if (swapped) {
      return sortChartDataAndLabels(data, labels)
    }

    return {
      data: data,
      labels: labels
    };
  };

  var generateChartData = function(originalData, labelKey, dataKey) {
    var grouped = originalData.groupBy(labelKey);
    var labels = Object.keys(grouped);
    var chartData = [];

    labels.forEach(label => {
      var sum = 0;
      var average = 0;

      grouped[label].forEach(el => {
        sum += el[dataKey];
      });

      average = sum / grouped[label].length;
      average = parseFloat(average.toFixed(2));

      chartData.push(average);
    });

    return { data: chartData, labels: labels, grouped: grouped };
  };

  var truncateLabel = function(label) {
    if (typeof label === 'number' && label.toString().length > 3) {
      return label.toFixed(1);
    }

    if (typeof label === 'string' && label.length > 10) {
      return label.slice(0, 7) + '...';
    }

    return label;
  };

  var Chart = function(opts) {
    this.originalData = opts.originalData;
    this.labelKey = opts.labelKey;
    this.dataKey = opts.dataKey;
    this.groupedData = null;
    this.chartData = [];
    this.chartLabels = [];
    this.labels = [];
    this.isLoading = false;
    this.MAX_ITEMS = 50;

    Object.assign(this, opts.extraProps);

    var barScales = {
      scales: {
        xAxes: [{
          ticks: {
            fontSize: 10,
            callback: truncateLabel
          }
        }],
        yAxes: [{
          ticks: {
            fontSize: 10,
            callback: truncateLabel
          }
        }]
      }
    };

    var radarScale = {
      scale: {
        pointLabels: {
          fontSize: 10,
          callback: truncateLabel
        }
      }
    };

    var defaultOptions = {
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          title: function(tooltipItems, data) {
            var i = tooltipItems[0].index;
            return data.labels[i];
          },
          label: function(tooltipItems, data) {
            var xLabel = parseFloat(tooltipItems.xLabel);
            return !isNaN(xLabel) ? xLabel : tooltipItems.yLabel;
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false
    };

    this.options = {};

    if (this.type === 'radar') {
      this.options = Object.assign({}, defaultOptions, radarScale);
    } else {
      this.options = Object.assign({}, defaultOptions, barScales);
    }

    this.setType = function(type) {
      this.type = type;

      if (type === 'radar') {
        this.options = Object.assign({}, defaultOptions, radarScale);
      } else {
        this.options = Object.assign({}, defaultOptions, barScales);
      }
    };

    this.setData = function(data) {
      var result = generateChartData(data, this.labelKey, this.dataKey);

      this.originalData = data;
      this.groupedData = result.grouped;
      this.labels = result.labels;
      this.chartData = result.data;

      if (this.chartData.length > 0) {
        this.hasData = true;
        sortChartDataAndLabels(this.chartData, this.labels);

        if (this.chartData.length > this.MAX_ITEMS) {
          const k = Math.floor(this.chartData.length / this.MAX_ITEMS);

          this.labels = this.labels.filter((label, i) => ((i + 1) % k) === 0);
          this.chartData = this.chartData.filter((item, i) => ((i + 1) % k) === 0);
        }

        this.chartLabels = this.labels;
      } else {
        this.hasData = false;
      }
    };

    this.setStylesAll = function(styles) {
      this.styles = {};

      Object.keys(styles).forEach(styleKey => {
        this.styles[styleKey] = styles[styleKey];
      });
    };

    this.setStylesByIndex = function(styles, index) {
      Object.keys(styles).forEach(styleKey => {
        var currentStyle = this.styles[styleKey];
        var hasCurrentStyle = currentStyle !== undefined;
        var styleToSet = styles[styleKey];

        if (index) {
          this.styles[styleKey] = [];

          if (hasCurrentStyle) {
            if (Array.isArray(currentStyle)) {
              currentStyle.forEach((st, i) => {
                this.styles[styleKey][i] = st;
              });
            } else {
              for (var i = 0; i < this.chartData.length; i++) {
                this.styles[styleKey].push(currentStyle);
              }
            }
          }

          if (Array.isArray(index)) {
            index.forEach(i => {
              this.styles[styleKey][i] = styleToSet;
            });
          } else {
            this.styles[styleKey][index] = styleToSet;
          }
        }
      });
    };

    this.setStylesExceptIndex = function(styles, index) {
      Object.keys(styles).forEach(styleKey => {
        var currentStyle = this.styles[styleKey];
        var styleToSet = styles[styleKey];

        if (index) {
          this.styles[styleKey] = [];

          for (var i = 0; i < this.chartData.length; i++) {
            this.styles[styleKey].push(styleToSet);
          }

          currentStyle = Array.isArray(currentStyle) ? currentStyle[index] : currentStyle;

          if (Array.isArray(index)) {
            index.forEach(i => {
              this.styles[styleKey][i] = currentStyle;
            });
          } else {
            this.styles[styleKey][index] = currentStyle;
          }
        }
      });
    };

    this.setData(this.originalData);
  };

  return {
    getDBReport1: function(params) {
      var query = HttpUtils.getQuery('/reports/report_db_1', params);

      return $http.get(query, { noThrobber: true });
    },
    getDBReport2: function(params) {
      var query = HttpUtils.getQuery('/reports/report_db_2', params);

      return $http.get(query, { noThrobber: true });
    },
    getDBReport3: function(params) {
      var query = HttpUtils.getQuery('/reports/report_db_3', params);

      return $http.get(query, { noThrobber: true });
    },
    getChart: function(opts) {
      var chart = new Chart({
        originalData: opts.generateFrom,
        labelKey: opts.labelKey,
        dataKey: opts.dataKey,
        extraProps: opts
      });

      return chart;
    }
  };
}]);

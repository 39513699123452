import api from '../';

const base = `/api/orgstruct`;

export const getBusinessDirections = () => {
    return api.get(`${base}/business-dirs`);
};

export const getDivisions = () => {
    return api.get(`${base}/divisions`);
};

<template>
    <div>
        <h1>Баннеры</h1>
        <br>
        <br>
        <div class="row align-items-center">
            <div class="col-auto">
                <custom-button
                    text="Добавить баннер"
                    variant="grey"
                    @click="addBannerStart"
                ></custom-button>
            </div>
        </div>

        <custom-table
            id="banners"
            v-model:meta="table.meta"
            :columns="tableFields"
            :items="tableItems"
            :sort-by="tableSorting.sortBy"
            :sort-desc="tableSorting.sortDesc"
            :filter="tableFilter"
            :limit-options="table.perPageOptions"
            :busy="bannersFetchStatus"
            show-pagination
            rounded
            class="banners-table mt-4"
            @row-click="handleRowClick"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            @update:filter="updateFilter"
        >
            <template #isActive="data">
                {{ handleIsActive(data.item.value) }}
            </template>

            <template #balanceUnit="{item, row}">
                <p class="table-balance-units">{{ item.value }}</p>
            </template>

            <template #filter-platformTypes="{item}">
                <multiple-select id="filter-platform-types" v-model="item.value"
                                 :options="item.options"></multiple-select>
            </template>

            <template #filter-bannerBlocks="{item}">
                <multiple-select id="filter-banner-blocks" v-model="item.value"
                                 :options="item.options"></multiple-select>
            </template>

            <template #filter-balanceUnits="{item}">
                <multiple-select id="filter-balance-units" v-model="item.value"
                                 :options="computedBusinessUnits"></multiple-select>
            </template>

            <template #filter-startDate="{item}">
                <custom-datepicker
                    display-year
                    auto-position
                    teleport
                    @update:model-value="handleDateSearch($event, item)"></custom-datepicker>
            </template>

            <template #filter-finishDate="{item}">
                <custom-datepicker
                    display-year
                    auto-position
                    teleport
                    @update:model-value="handleDateSearch($event, item)"></custom-datepicker>
            </template>

            <template #filter-createDate="{item}">
                <custom-datepicker
                    display-year
                    auto-position
                    teleport
                    @update:model-value="handleDateSearch($event, item)"></custom-datepicker>
            </template>

            <template #filter-updateDate="{item}">
                <custom-datepicker
                    display-year
                    auto-position
                    teleport
                    @update:model-value="handleDateSearch($event, item)"></custom-datepicker>
            </template>
        </custom-table>

        <div>
            <banner-edit
                v-if="showEdit"
                :aria-hidden="bannerSaveStatus"
                :item="selectedBanner"
                :show-content-tab="showContentTab"
                @save="saveBanner"
            ></banner-edit>

            <loader v-if="bannerSaveStatus" inside-element inside-element-position="absolute" class="-text-primary" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Components
import { Loader, CustomButton, CustomDatepicker, CustomTable, MultipleSelect } from '@eron/ui-kit';
import BannerEdit from './BannerEdit.vue';

export default {
    components: {
        Loader,
        BannerEdit,
        CustomButton,
        CustomTable,
        MultipleSelect,
        CustomDatepicker,
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Название',
                    sortable: true,
                    filterable: true,
                    filterClass: 'filterable-table-class',
                },
                {
                    key: 'platformType',
                    label: 'Платформа',
                    filterable: true,
                    filterType: 'select',
                    filterOptions: [
                        { value: 'ANDROID', label: 'Android' },
                        { value: 'WEB', label: 'WEB' },
                    ],
                    filterKey: 'platformTypes',
                    filterPlaceholder: 'Платформа',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.join(', '),
                },
                {
                    key: 'balanceUnit',
                    label: 'Балансовая единица',
                    filterable: true,
                    filterType: 'select',
                    filterOptions: [
                        { value: 'BPKZ', label: 'BPKZ' },
                        { value: 'BSTP', label: 'BSTP' },
                        { value: 'FPLV', label: 'FPLV' },
                        { value: 'FPUZ', label: 'FPUZ' },
                    ],
                    filterKey: 'balanceUnits',
                    filterPlaceholder: 'Бал. ед',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.join(', '),
                },
                {
                    key: 'bannerBlock',
                    label: 'Баннер-Блок',
                    filterable: true,
                    filterType: 'select',
                    filterOptions: [
                        { value: 'Главный баннер', label: 'Главный баннер' },
                        { value: 'Баннер с закрепленным текстом', label: 'Баннер с закрепленным текстом' },
                        { value: 'Быстрые ссылки', label: 'Быстрые ссылки' },
                    ],
                    filterKey: 'bannerBlocks',
                    filterPlaceholder: 'Баннер-блок',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.join(', '),
                },
                {
                    key: 'contentQty',
                    label: 'Количество контента',
                },
                {
                    key: 'startDate',
                    sortable: true,
                    label: 'Начало действия',
                    filterable: true,
                    filterPlaceholder: 'Начало действия',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.slice(0, 10),
                },
                {
                    key: 'finishDate',
                    sortable: true,
                    label: 'Окончание действия',
                    filterable: true,
                    filterPlaceholder: 'Окончание действия',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.slice(0, 10),
                },
                {
                    key: 'createDate',
                    sortable: true,
                    label: 'Дата создания',
                    filterable: true,
                    filterPlaceholder: 'Дата создания',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.slice(0, 10),
                },
                {
                    key: 'updateDate',
                    sortable: true,
                    label: 'Дата последнего изменения',
                    filterable: true,
                    filterPlaceholder: 'Дата последнего изменения',
                    filterClass: 'filterable-table-class',
                    formatter: (value, row) => value.slice(0, 10),
                },
                {
                    sortable: true,
                    key: 'isActive',
                    label: 'Активен',
                },
            ],
            table: {
                meta: { page: 1, limit: 5, total: 0 },
                perPageOptions: [5, 10, 15],
            },
            tableSorting: {
                sortBy: 'id',
                sortDesc: true,
            },
            tableFilter: {},
            showEdit: false,
            selectedBanner: {
                id: 0,
                name: 'Banner name',
                dateStart: null,
                dateFinish: null,
                isActive: false,
                businessUnit: [],
                blocks: [],
            },
            showContentTab: false,
        };
    },
    computed: {
        ...mapGetters('banners', {
            bannersList: 'getBannersList',
            bannersFetchStatus: 'getBannersFetchStatus',
            bannerSaveStatus: 'getBannerSaveFetchStatus',
            businessUnits: 'getBusinessUnits',
        }),
        computedBusinessUnits() {
            return this.businessUnits.map((item) => {
                return {
                    value: item.countryCode,
                    label: item.countryCode,
                };
            });
        },
        tableItems() {
            return this.bannersList.bannersDto ? this.bannersList.bannersDto : [];
        },
    },
    watch: {
        bannersList: {
            handler(listArg) {
                this.table.meta.total = listArg.pages_count * this.table.meta.limit;
            },
        },
        'table.meta.limit'() {
            this.showEdit = false;
            this.showContentTab = false;
            this.fetchBannersList();
        },
        'table.meta.page'() {
            this.showEdit = false;
            this.showContentTab = false;
            this.fetchBannersList();
        },
    },
    created() {
        this.fetchBannersList();
        if (this.businessUnits.length <= 0) this.$store.dispatch('banners/fetchBusinessUnits');
    },
    methods: {
        handleDateSearch(e, item) {
            if (e) {
                item.value = e.toISOString().slice(0, e.toISOString().length - 5);
            } else {
                item.value = null;
            }
        },
        updateFilter(e) {
            this.tableFilter = e;
            this.fetchBannersList();
        },
        updateSortDesc(e) {
            this.tableSorting.sortDesc = e;
            this.fetchBannersList();
        },
        updateSortBy(e) {
            this.tableSorting.sortBy = e;
            this.fetchBannersList();
        },
        handleIsActive(e) {
            return e === true ? 'Да' : 'Нет';
        },
        handleRowClick(rowArg) {
            if (rowArg.name) {
                this.showEdit = false;
                this.showContentTab = false;
            }

            this.$store.dispatch('banners/fetchBannerData', rowArg.id).then(() => {
                const banner = this.bannersList.bannersDto.find((bannerArg) => bannerArg.id === rowArg.id);
                this.selectedBanner = {
                    ...banner,
                    dateStart: banner.startDate,
                    dateFinish: banner.finishDate,
                    businessUnit: banner.balanceUnit[0].split(','),
                };
                this.showEdit = true;
                this.showContentTab = true;
            });
        },
        saveBanner(updatedItem) {
            this.selectedBanner = updatedItem;
            this.$store.dispatch('banners/saveBanner', this.selectedBanner).then(() => {
                this.showEdit = false;
                this.showContentTab = false;
                this.fetchBannersList();
            });
        },
        addBannerStart() {
            this.showEdit = false;
            this.$nextTick(() => {
                this.selectedBanner = {
                    id: 0,
                    name: 'Новый баннер',
                    dateStart: null,
                    dateFinish: null,
                    isActive: false,
                    businessUnit: [],
                    blocks: [],
                };

                this.showEdit = true;
                this.showContentTab = false;
            });
        },
        fetchBannersList() {
            this.$store.dispatch('banners/fetchBannersList', {
                sorting: this.tableSorting,
                filters: this.tableFilter,
                page: this.table.meta.page,
                size: this.table.meta.limit,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(.pagination) {
    display: flex;
    flex-flow: row;
}

:deep(.pagination-bar) {
    border-top: none !important;
}

:deep(.pagination-action__to-page) {
    display: none !important;
}

:deep(.filterable-table-class) {
    padding-left: 0;
}

.table-balance-units {
    word-break: break-word;
}
</style>

/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 21.06.15.
 */

(function () {
	'use strict';

	angular.module('smartApp')

		.service('AuthoritiesObjectsService', authoritiesObjectsService);

	authoritiesObjectsService.$inject = ['$http', 'config'];

	function authoritiesObjectsService($http, config){
		return {
			getAll: function(){
				return $http.get(config.backend + '/objects/authorities')
			}
		};
	}

})();

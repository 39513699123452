angular.module('smartApp').factory('RfcIntegrationApi', RfcIntegrationApi);

function RfcIntegrationApi($http) {
    return {
        fetchTable: function(data) {
            return $http.post('/api/integration/rfc', data);
        },
    };
}

RfcIntegrationApi.$inject = ['$http'];

(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('shopDirTasks', shopDirTasks);


    function shopDirTasks() {
        var directive = {
            templateUrl: 'scripts/app/checklist/shop-dir-tasks/shop-dir-tasks.view.html',
            bindToController: true,
            controller: ShopDirTasksController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {}
        };
        return directive;
    }

    ShopDirTasksController.$inject = ['$stateParams', 'TaskListServiceHttp', 'ShopDirTasksService'];

    function ShopDirTasksController ($stateParams, TaskListServiceHttp, ShopDirTasksService) {
        var vm = this;

        vm.page = 1;

        vm.toggle = false;

        vm.fields = ShopDirTasksService.fields;

        vm.filter = {
            taskTypes: 'routed'
        };

        vm.filterProp = {
            size: null,
            totalElements: null
        };

        vm.tasks = [];

        vm.getTasks = getTasks;

        init();

        function init() {
            vm.filter.templateId = $stateParams.checkListId;
            getTasks();
        }

        function getTasks(filter) {
            vm.filter.offset = vm.page - 1;
            vm.tasks.splice(0);
            if (filter) {
                _.keys(filter).forEach(function(key) {
                    if (_.isDate(filter[key])) {
                        filter[key] = ShopDirTasksService.convertDateToNumber(filter[key]);
                    }
                    if (filter[key]) {
                        vm.filter[key] = filter[key];
                    } else {
                        delete vm.filter[key];
                    }
                });
            }
            TaskListServiceHttp
                .getFiltered(vm.filter)
                .then(function (resp) {
                    if (resp.status === 200 || resp.status === 201) {
                        angular.forEach(resp.data, function(task) {
                            vm.tasks.push(task);
                        });
                    }
                });
        }
    }
})();


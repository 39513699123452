angular.module('smartApp').directive('modelToInteger', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(modelValue) {
        return Number(modelValue);
      });
      ngModel.$formatters.push(function(viewValue) {
        return Number(viewValue);
      });
    }
  };
});

angular.module('smartApp')
.config(function ($stateProvider, $urlRouterProvider) {
    // $urlRouterProvider.when('/settings/params', '/settings/params');

    $stateProvider
        .state('settings.params', {
            url: '/params',
            templateUrl: 'scripts/app/settings/params/params.view.html',
            controller: 'SettingsParamsController',
            data: {
                css: ['css/states/params.css']
            }
        })
        // .state('settings.params', {
        //     url: '/params',
        //     template: '<params data="$resolve.params" templates-resp="$resolve.templates"></params>',
        //     data: {
        //         css: ['css/states/settings.params.css']
        //
        //     },
        //     resolve: {
        //         params: ['ParamsService', function(ParamsService) {
        //             return ParamsService.getParams().then(function(response) {
        //                 return response;
        //             });
        //         }],
        //         templates: ['TemplateService', function(TemplateService) {
        //             return TemplateService.getAll2().then(function(resp) {
        //                 if (resp && resp.status === 200) {
        //                     return resp;
        //                 }
        //             });
        //         }]
        //     }
        // })
        // .state('settings.params.checklist', {
        //     url: '/checklist',
        //     views: {
        //         paramsTab: {
        //             template: '<params-tab class="params params__checklist" data-ng-if="vm.checklists" all-properties="vm.params" properties="vm.checklists" settings="vm.checklistView" templates="vm.templates"></params-tab>'
        //         }
        //     },
        //      data:{
        //         breadcrumbs: [['gears', 'Параметры'], ['envelope', 'Чеклисты']]
        //     }
        // })
        // .state('settings.params.general', {
        //     url: '/general',
        //     views: {
        //         paramsTab: {
        //             template: '<params-tab class="params params__general" data-ng-if="vm.generals" all-properties="vm.params" properties="vm.generals" settings="vm.generalView" templates="vm.templates"></params-tab>'
        //         }
        //     },
        //      data:{
        //         breadcrumbs: [['gears', 'Параметры'], ['gears', 'Общие']]
        //     }
        // })
        // .state('settings.params.notifications', {
        //     url: '/notifications',
        //     views: {
        //         paramsTab: {
        //             template: '<params-tab class="params params__notifications" data-ng-if="vm.notifications" all-properties="vm.params" properties="vm.notifications" settings="vm.notificationView" templates="vm.templates"></params-tab>'
        //         }
        //     },
        //     data:{
        //         breadcrumbs: [['gears', 'Параметры'], ['envelope', 'Уведомления']]
        //     }
        // })
        // .state('settings.params.quality', {
        //     url: '/quality',
        //     views: {
        //         paramsTab: {
        //             template: '<params-tab class="params params__quality" data-ng-if="vm.qualities" all-properties="vm.params" properties="vm.qualities" settings="vm.qualityView" templates="vm.templates"></params-tab>'
        //         }
        //     }
        // });
});

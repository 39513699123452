angular.module('smartApp').factory('UploadService', ['$http', 'config', 'Upload', function UploadService($http, config, Upload) {
  return {
    uploadFile: function(files) {
      return Upload.upload({
        url: config.backend + '/attachmentService/custom',
        data: {
          'attachments[0]': files
        },
        objectKey: '[value]'
      });
      // $http.post(config.backend + 'attachmentService/custom', formData);
    }
  };
}]);

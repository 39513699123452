'use strict';

angular.module('smartApp').controller('Dashboard2Controller', function(
	$filter,
	SystemParamsService,
	ObjectServiceForTemplates,
    ObjectServiceResolverTemplates,
	$rootScope,
	$compile,
	$scope,
	ngDialog,
	$state,
	Principal,
	ShopService,
	GroupShopService,
	TemplateService,
	ChecklistService,
	ChecklistEditor,
	Notifications,
	localStorageService,
	moment,
	$window,
	$timeout,
	ObjectServiceForChecklists,
    ObjectServiceResolverChecklists,
	ObjectServiceForShops,
    ObjectServiceResolverShops,
    TWTemplateService,
	projectConfig) {


 	var systemProps = {};
    (projectConfig.nameProject === 'md' && Principal.isInRole('ROLE_USER_CHECKLISTS_ORGANIZER')) && ($scope.orgProjectMd = true);

    $scope.warningOutTime = false;
    $scope.curUserId = $scope.resolverId = Principal.getIdentity().id;

    var getDaysArray = function(month, year) {
		var names = [ 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб' ];

		var date = new Date(year, month-1, 1);
		var result = [];
		while (date.getMonth() == month-1) {
			result.push({num:date.getDate(), ofWeek:names[date.getDay()], weekend:(date.getDay() == 0 || date.getDay() ==6)?true:false, today:(date.getMonth()+1 == moment().format("M") && date.getDate() == moment().format("D"))?true:false});




			date.setDate(date.getDate()+1);
		}
		return result;
	}
	var defaultLimit = 20;

	$scope.pagination = {variants:[5,10,20,50], page:1, limit: (localStorageService.get("perPage"))?localStorageService.get("perPage"):defaultLimit};

	var initMethods = function(resolver){
		if (resolver){
            $scope.methods = {
                checklists: ObjectServiceResolverChecklists,
                shops: ObjectServiceResolverShops
            };
		}else {
            $scope.methods = {
                checklists: ObjectServiceForChecklists,
                shops: ObjectServiceForShops
            };
        }
	}

	$scope.changeCurMonth = function(diff){
		$scope.curDate.add(diff, 'M')
		$scope.pagination.page = 1;

		refresh($scope.methods)
	}

	$scope.templateChange = function(){
		localStorageService.set('template', $scope.selectedTemplate);
	};

	$scope.initChecklists = function(methods, reloadEventSource, filter, month, callback) {
		$scope.checks = [];

        var method = methods.checklists; //method - ObjectServiceForChecklists, ObjectServiceResolverChecklists
        method.query({userId: $scope.resolverId, month: moment($scope.curDate.year() +"-"+($scope.curDate.month()+1), "YYYY-MM").format("YYYY-MM-DD")}, function (result) {
			for(var i in result){
				if(!$scope.checks[result[i].templateId])$scope.checks[result[i].templateId] = [];
				if(!$scope.checks[result[i].templateId][result[i].shopId])$scope.checks[result[i].templateId][result[i].shopId] = [];
				$scope.checks[result[i].templateId][result[i].shopId][Number(moment(result[i].resolveDate).format("D"))] = {status:result[i].statusName, id:result[i].id}
			}

			$scope.storedChecklists = result;
			filter && typeof filter === 'function' && filter();
			if((!filter || typeof filter !== 'function') && $scope.isMobile) $scope.filterByTemplate();

			if(callback)callback()
		});
	};

	$scope.initTemplates = function(method, resolverId) {
		$scope.templatesLoading = true;
   		method.query({userId:resolverId}, function(result) { // method - ObjectServiceForTemplates, ObjectServiceResolverTemplates
			$scope.templatesLoading = false;
			if(result.length == 0){
				Notifications.warning("Шаблоны не найдены!")
			}
			result = _.sortBy(result, ['name']);
			$scope.templates = result;

			if($scope.selectedTemplate){
	      var selectedTemplate =  $scope.templates.filter(function (elem) {
					return (elem.id === $scope.selectedTemplate.id)
	      });

	      if($scope.orgProjectMd && selectedTemplate.length > 0){
					$scope.selectedTemplate = selectedTemplate[0];
					return;
				}
			}

      $scope.selectedTemplate = $scope.templates.length === 1 ? $scope.templates[0] : null;
		});
	};

    $scope.initResolvers = function() {
        ChecklistEditor.getResolvers().then(function(data){
            if (data.status === 200){
				$scope.resolvers = data.data;
            }else{
                Notifications.danger("Ошибка", "Ошибка загрузки списка пользователей", 3000);
			}
        },
		function(){
            Notifications.danger("Ошибка", "Ошибка загрузки списка пользователей", 3000);
		})
    };

    $scope.setResolver = function(resolverItem) {
        if (resolverItem){
            $scope.resolverId = resolverItem.id;
            $scope.initTemplates(ObjectServiceResolverTemplates, resolverItem.id);
            initMethods(resolverItem);
    	}else{
			//($scope.orgProjectMd) ? ($scope.resolverId = null) : ($scope.resolverId = $scope.curUserId);
            $scope.resolverId = null;
            $scope.initTemplates(ObjectServiceForTemplates);
            initMethods();
		}
        refresh($scope.methods);
    };

		$scope.doSearch = function(methods) {
			$scope.pagination.page = 1;
			$scope.initShops(methods);
		};

	$scope.initShops = function(methods) {
		$scope.usedSearch = undefined;
		if(localStorageService.get("perPage") !== $scope.pagination.limit)localStorageService.set("perPage", $scope.pagination.limit)
		// if(Principal.isInRole('ROLE_CURATOR')) {
		// 	var shops = localStorageService.get('allCheckedShops');
		// 	shops = shops || [];
		// 	shops = shops.map($scope.shopToObject);
		// 	$scope.mobileShops = $scope.curShops = shops;
		// } else {
        var method = methods.shops; //method - ObjectServiceForShops, ObjectServiceResolverShops

        method.query({userId: $scope.resolverId, 'search': $scope.search, 'perPage':$scope.pagination.limit, 'page': $scope.pagination.page, view: 'dashboard', year: $scope.curDate.year(), month: ($scope.curDate.month()+1)}, function (data, headers) {
				var shops = data;



				$scope.pagination.total = headers('x-total-count')
				shops = shops.map(function (shop) {

					shop.name = shop.locality + ' ' + shop.sap;

					shop.limitsN = [];
					for(var l in shop.limits){
						if(!shop.limitsN[shop.limits[l].templateId])shop.limitsN[shop.limits[l].templateId] = []
						shop.limitsN[shop.limits[l].templateId][shop.limits[l].month] = {count:shop.limits[l].count, limit:shop.limits[l].limit}
					}

					return shop;
				});

				$scope.mobileShops = $scope.shops = shops;
				$scope.usedSearch = $scope.search;
			});
		//}
	};

	var afterChecklistCreate = function(result, shop){
		for(var i in result){
			if(!$scope.checks[result[i].templateId])$scope.checks[result[i].templateId] = [];
			if(!$scope.checks[result[i].templateId][result[i].shopId])$scope.checks[result[i].templateId][result[i].shopId] = [];
			$scope.checks[result[i].templateId][result[i].shopId][Number(moment(result[i].resolveDate).format("D"))] = {status:result[i].statusName, id:result[i].id}
		}
		if(shop.limitsN.length) {
            shop.limitsN[result[0].templateId][Number(moment(result[0].resolveDate).format("M"))].count++;
            ChecklistEditor.getLimits(result[0].templateId, result[i].shopId, moment(result[0].resolveDate).format("MM-YYYY"), function (err, data) {
                shop.limitsN[result[0].templateId][Number(moment(result[0].resolveDate).format("M"))].count = data.count;
                shop.limitsN[result[0].templateId][Number(moment(result[0].resolveDate).format("M"))].limit = data.limit;
            })
        }
	};

	$scope.editCheck = function(checklistId, shop, day){
        if(!$scope.selectedTemplate || !$scope.selectedTemplate.id) {
            Notifications.danger("Ошибка", "Выберите шаблон проверки", 7000);
            return;
        }
        if(!$scope.resolverId) {
            Notifications.danger("Ошибка", "Выберите пользователя", 7000);
            return;
        }
		if($scope.checks[$scope.selectedTemplate.id] &&
			$scope.checks[$scope.selectedTemplate.id][shop.id] &&
			$scope.checks[$scope.selectedTemplate.id][shop.id][day] &&
			($scope.checks[$scope.selectedTemplate.id][shop.id][day].id === checklistId) &&
            ($scope.resolverId !== $scope.curUserId)){

            if($scope.checks[$scope.selectedTemplate.id][shop.id][day].status === 'MISSED' || $scope.checks[$scope.selectedTemplate.id][shop.id][day].status === 'PLANNING'){
                $scope.deleteChecklist(shop, day);
            }
            return;
		}
		TWTemplateService.getTemplate($scope.selectedTemplate.id, function (err, result) {
			if (err) {
				console.log(err);
				return;
			}
			$scope.template = result;

			if (!$scope.template){
				Notifications.danger("Ошибка", "Ошибка загрузки информации по шаблону", 7000);
				return;
			}
			checkChecklist(checklistId, shop, day);
		});
	};

    function checkChecklist (checklistId, shop, day){
        var shopId = shop.id;
        var shopName = shop.name;
        if(checklistId){//edit
            $state.go('editChecklist', {checklist: checklistId});
        }else{ //create
            if(!(Principal.isInRole("ROLE_SUPERVISOR") || Principal.isInRole("ROLE_CURATOR") || Principal.isInRole("ROLE_USER_CHECKLISTS_ORGANIZER")))return;
            //var dateDiff = moment(day+"."+(self.curDate.month()+1)+"."+self.curDate.year(), "DD.MM.YYYY").diff(moment(), 'days')
            var self = $scope;
            var realDate = moment();
            var choosedDate = moment(day+"."+(self.curDate.month()+1)+"."+self.curDate.year(), "DD.MM.YYYY");





			//same code should be in addCheck.controller.js. Don't forget to update if if nessesary
			var planingControlEnabled = systemProps['checklist.enablePlanningDateControl'];

			if(choosedDate.isBefore(moment(), 'day')) {
				Notifications.danger("Ошибка", "Планирование проверок на прошедшую дату запрещено", 7000);
				return;
			}
			
			if (planingControlEnabled === "true") {
				var isNextMonth = choosedDate.isSame(moment().add(1, 'month'), 'month');
				var isToday = choosedDate.isSame(moment(), 'day');

				if (!(isNextMonth || isToday)) {
					var limitText = ".";

						limitText =  " или на следующий месяц <i>(" + moment().add(1, 'month').format("MMMM YYYY") + ")</i>"

					Notifications.danger("Ошибка", "Проверку можно назначить на сегодняшний день" + limitText, 7000);

					return;
				}

			}



            var _this = $scope;
            ngDialog.open({
                template: 'createConfirm',
                controller: ['$scope', 'moment', function($scope, moment) {

                    $scope.today = moment(day+"."+(self.curDate.month()+1)+"."+self.curDate.year(), "DD.MM.YYYY").format("DD MMMM YYYY (dddd)");
                    $scope.shopName = shopName;
                    $scope.create = function(){
                        ngDialog.closeAll();
                        var checklist = {
                            resolverId: _this.resolverId,
                            shopName: shopName,
                            shopId: shopId,
                            templateName: self.selectedTemplate.name,
                            templateId: self.selectedTemplate.id,
                            resolveDate: new Date(self.curDate.year(), self.curDate.month(), day)
                        };

                        ChecklistEditor.createChecklists([checklist])
                            .then(function(result,a,headers){
                                if(result.status == 200) {
                                    $rootScope.$broadcast("saveSuccess");
                                    //refresh()
                                    afterChecklistCreate(result.data, shop)
                                }else if(result.data && result.data.message){
                                    Notifications.danger(result.data.message, '', 3000);
								}else if(result.status == 400){
									Notifications.danger('Планирование проверок на прошедшую дату запрещено');
								}else{
                                    Notifications.danger('Изменения утеряны');
                                }

                            });
                    }

                }],
                className: 'ngdialog-theme-plain dialog',
                showClose: false
            });
        }
    }
    $scope.deleteChecklist = function (shop, day) {
        var template = $scope.selectedTemplate;
        var self = $scope;
        ngDialog.open({
            template: 'deleteConfirm',
            controller: ['$scope', 'moment', function($scope, moment) {
                $scope.today = moment(day+"."+(self.curDate.month()+1)+"."+self.curDate.year(), "DD.MM.YYYY").format("DD MMMM YYYY (dddd)");
                $scope.shopName = shop.name;
                $scope.delete = function(){
                    ngDialog.closeAll();

                    ChecklistService.deleteChecklist(template.id)
                        .success(function (result, status) {
                            if (status == 200) {
                                Notifications.success("Проверка удалена");
                                $state.go("home");
                            }else{
                                Notifications.danger("Произошла ошибка");
                                console.log("Critical error: can't remove checklist");
                            }

                        });
                }

            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        })
    };


	var refresh = function(funcMethods){
		async.parallel([
		    function(callback){
		    	$scope.initChecklists(funcMethods, false, false, Number($scope.curDate.month())+1, callback)
		    },
		    function(callback){
		    	//if (Principal.isInRole('ROLE_SUPERVISOR') || Principal.isInRole('ROLE_CURATOR')){
		    		$scope.initShops(funcMethods);
		    	//}

				callback()
		    }
		],
		// optional callback
		function(err, results){
			$scope.daysInMonth = getDaysArray($scope.curDate.month()+1, $scope.curDate.year());
			if(Principal.isInRole('ROLE_DIRECTOR')){
				$scope.shops = []
	    		$scope.shops.push({id:$scope.storedChecklists[0].shopId, locality:$scope.storedChecklists[0].shopName})

	    	}
		})

	}

	var getSystemProps = function(cb){
		SystemParamsService.getSystemProps(['checklist.daysToBegin', 'web.checklists.list.showLimits', 'checklist.beginPlaningPeriodDay', 'checklist.check.interval', 'checklist.enablePlanningDateControl'], function(err, params){
			if(err){
	    		Notifications.danger('Ошибка получения данных');
	    		cb(result.status);
	    		console.log("Err: ", err);
	    		return
	    	}

	    	cb(null, params)
		})
	}

	if(!Principal.isAuthenticated()) {
		$state.go('login');
		return
	}

	if(Principal.isInRole("ROLE_DIRECTOR")){
		 $state.go('plannedChecklist');
		 return
	}

	if (Principal.isInRole("ROLE_ADMIN") || Principal.isInRole("ROLE_DIRECTOR_CLUSTER") && !Principal.isInRole("ROLE_SUPERVISOR")) {
        $state.go('clreports');
        return
    }

    if (Principal.isInRole("ROLE_SECRET")) {
		$state.go('secret');
		return
	}
	if(Principal.isInRole('ROLE_ENERGETIC')){
        $state.go("counters.bind");
    }

    if (Principal.isInAnyRole(['ROLE_ENGINEER', 'ROLE_MAIN_ENGINEER'])) {
        $state.go("counters.report");
    }

	$scope.curDate = moment();
	//if( localStorageService.get('template'))$scope.selectedTemplate = localStorageService.get('template')
	if (Principal.isInAnyRole(['ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_USER_CHECKLISTS_ORGANIZER'])){
		$scope.initTemplates(ObjectServiceForTemplates);
    }

	if ($scope.orgProjectMd) {
		$scope.resolverId = null;
		$scope.initResolvers();
	}

	getSystemProps(function(err, data){
		if(err)return;
		systemProps = data;
		console.log(systemProps);
		$scope.showLimits = systemProps["web.checklists.list.showLimits"]

	});

    initMethods();
	refresh($scope.methods)

});

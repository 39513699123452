<script setup>
import { ref, nextTick, watch } from 'vue';
import { ButtonToggle, CustomInput } from '@eron/ui-kit';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Number,
    default: undefined,
  },
});
const emit = defineEmits(['update:model-value']);

const options = [...Array(6).keys()].map(i => {
  return { label: String(i), value: i };
});
const toggleValue = ref();
const inputValue = ref();

const setValue = value => {
  let intValue = parseInt(value);

  if (intValue !== intValue) {
    toggleValue.value = undefined;
    inputValue.value = undefined;
    return;
  }

  intValue = Math.max(0, intValue);

  if (intValue <= 5) {
    toggleValue.value = intValue;
    inputValue.value = undefined;
  } else {
    toggleValue.value = undefined;
    inputValue.value = intValue;
  }

  return intValue;
};  

const onUpdate = async (value) => {
  await nextTick();
  value = setValue(value);
  emit('update:model-value', value);
};

watch(() => props.modelValue, newVal => {
  setValue(newVal);
}, { immediate: true });
</script>

<template>
  <div class="generation-days-to-complete" :class="{ 'generation-days-to-complete--input-value': !!inputValue }">
    <button-toggle
      v-model="toggleValue"
      :options="options"
      outline
      class="generation-days-to-complete_btn-toggle"
      @update:model-value="onUpdate"
    />
    <custom-input
      :id="id"
      v-model="inputValue"
      class="generation-days-to-complete_input"
      @blur="onUpdate(inputValue)"
    />
  </div>
</template>

<style lang="scss">
.generation-days-to-complete {
  display: inline-flex;

  .button-toggle-item:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &_input {
    width: 70px !important;

    .custom-input__input {
      border-color: var(--u-green);
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &--input-value &_btn-toggle {
    opacity: 0.3;
  }
}
</style>
'use strict';

angular.module('smartApp').factory('SettingsPushService', SettingsPushService);

SettingsPushService.$inject = ['$http', 'config'];

function SettingsPushService($http, config) {
  return {
    getPushList: function() {
      return $http.get(config.backend + '/memento/notifications/push');
    },
    updatePush: function(push) {
      return $http.put(config.backend + '/memento/notifications/push', push);
    }
  }
}

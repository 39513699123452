angular.module('smartApp').factory('Filter1', checkReportFactory1);
checkReportFactory1.$inject = ['ChecklistService', 'DownloadService', 'Principal'];

function checkReportFactory1(ChecklistService, DownloadService, Principal) {
	return {
		id: 1,
		title: 'Фильтр 1',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter1/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter1/view.html',
		isValid: function(filters) {
			if(!Principal.isInRole("ROLE_SUPERVISOR")){
				return filters.rangeFrom && filters.rangeTo &&  filters.template && filters.division && filters.division.length != 0
			}else{
				return filters.rangeFrom && filters.rangeTo &&  filters.template
			}
			
		},
		load: function(filters) {
			console.log(Principal.getIdentity())
			return ChecklistService.getGreenReport(filters);
		},
		download: function(filters) {
			return DownloadService.downloadGreenReport(filters);
		}
	};

}
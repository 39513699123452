'use strict';

angular.module('smartApp').controller('ChecklistReportsController', function(
    $scope,
    $rootScope,
    $state,
    $filter,
    $http,
    $timeout,
    moment,
    PanelGroupsService,
    ObjectServiceForDivisions,
    ObjectServiceForTemplates,
    ObjectServiceForClusters,
    ObjectServiceForShops,
    DownloadService,
    Principal,
    ShopService,
    ngDialog,
    ChecklistService,
    ChecklistEditor,
    TemplateService,
    ChecklistReports,
    ChecklistReportService,
    AuthoritiesObjectsService,
    PanelShopsResource,
    Account,
    Notifications,
    PWCategoryService,
    OrgstructService,
    PanelContractorsService,
    FilterService,
    projectConfig,
    FormsHttpService,
	config
) {

	$scope.reportInspectorsView = 'map';
	$scope.dateType = {
		plan: 'PLAN',
		fact: 'FACT'
	};

  $scope.formsDateType = {
    create: 'CREATE',
    close: 'CLOSE'
  };

	$scope.downloadAccreditationAct = function(checklistId) {
		DownloadService.downloadAccreditationAct(checklistId);
	}

	$scope.createMapUrl = function(lat, lon){
		if(lat && lon){
			return "<a target=\"_blank\" href='https://maps.yandex.ru/?ll="+lon+","+lat+"&pt="+lon+","+lat+"&spn=0.03,0.03&l=sat,skl'>карта</a>";
		}else{
			var t = [];
			if(lat)t.push(lat+"N")
			if(lon)t.push(lon+"E")
			return t.join("<br>");
		}
	}

	$scope.pagination = {page:1, limit: 20};

	function _getQuestions(arr, callback) {
		var _arr = [],
			_out = [];
		for (var i = 0; i < arr.length; i++) {
			var questions = arr[i].questions;
			for (var a = 0; a < questions.length; a++) {
				_out.push(questions[a]);
			}
		}
		callback(_out);
	}
	function _deleteUnmatchedQuestions() {
		var P = $scope.uiSelect.process.selected,
			Q = $scope.uiSelect.question.selected,
			available_questions = [];

		for (var i = 0; i < P.length; i++) {
			var _q = P[i].questions;
			for (var a = 0; a < _q.length; a++) {
				available_questions.push(_q[a]);
			};
		};

		var new_Q = [];
		for (var i = 0; i < Q.length; i++) {
			var index = _.indexOf(available_questions, Q[i]);
			if (index !== -1) {
				new_Q.push(Q[i]);
			}
		};
		$scope.uiSelect.question.selected = new_Q;
    }

	// Init select
	$scope.uiSelect = {
		template: {
			selected: null
		},
		templates: {
			selected: []
		},
		process: {
			selected: []
		},
		question: {
			selected: []
		},
		division: {
			selected: []
		},
		cluster: {
			selected: []
		},
		group:{
			selected: []
		},
		fio:{
			selected: []
		},
		userIds:{
			selected: []
		},
		roles:{
			selected: []
		},
		categories:{
			selected: []
		},
		subcategories:{
			selected: []
		},
		processes:{
			selected: []
		},
    businessLine:{
        selected: []
    },
    contractors:{
        selected: []
    },
    positions:{
        selected: []
    },
    shops:{
        selected: []
    },
    formsTemplate: {
      selected: []
    },
    formsGroup: {
      selected: []
    },
    formsPoint: {
      selected: []
    }
    };

    $scope.group = {};
    $scope.checklist = {};

	$scope.shops = [];
	$scope.processes = [];
	$scope.questions = [];
	$scope.divisions = [];
	$scope.clusters = [];
	$scope.groups = [];
	$scope.businessLines = [];
	$scope.contractors = [];
  $scope.formsTemplates = [];
  $scope.formsGroups = [];
  $scope.formsPoints = [];
    $scope.eventsMap = {};
  var fullTemplates = {};

	$scope.nameProject = projectConfig.nameProject;
    $scope.positions = [];
    $scope.users = [];

    $scope.loadedGroups = false;
    $scope.loadedTemplates = false;

	$scope.initShopSelect = function($select) {
		$select && ($scope.uiSelect.shops = $select);
		if($scope.shops && $scope.uiSelect.shops && $scope.shops.length) {
			$scope.uiSelect.shops.selected = $scope.shops;
		}
	};

    $scope.deviationInMeters = 50;
	$scope.rangeTo = new Date();
	$scope.rangeFrom = moment().subtract(1, 'months').toDate();

	$scope.dateFromOptions = { showWeeks:false, maxDate: moment($scope.rangeTo).toDate() };
  $scope.dateToOptions = { showWeeks:false, minDate: moment($scope.rangeFrom).toDate() };
    $scope.selectedManagerCoordinatesShop = {};

	if(!Array.prototype.hasOwnProperty('unique')){
		Object.defineProperty(Array.prototype, 'unique', {
		    enumerable: false,
		    configurable: false,
		    writable: false,
		    value: function() {
		        var a = this.concat();
		        for(var i=0; i<a.length; ++i) {
		            for(var j=i+1; j<a.length; ++j) {
		                if(a[i] === a[j])
		                    a.splice(j--, 1);
		            }
		        }

		        return a;
		    }
		});
	}

  $scope.handleDateChange = function(name, value) {
    switch(name) {
      case 'rangeFrom':
        $scope.dateToOptions.minDate = moment(value).toDate();
        break;
      case 'rangeTo':
        $scope.dateFromOptions.maxDate = moment(value).toDate();
        break;
    }

    $scope.reports.setFilter(name, value);
  };

	$scope.editCheck = function(check){
		if(check.status != 'MISSED' && check.status != 'PLANNING')return;
		ngDialog.open({
			template: 'editCheck',
			controller: ['$scope', function($scope) {
					$scope.isRemoveable = (check.status == 'MISSED' || check.status == 'PLANNING');
					$scope.isRenewable = (check.status == 'MISSED');
					$scope.tplName = "dsa";
					$scope.date = check.date;
					$scope.delete = function(){
						ngDialog.closeAll();
						ChecklistService.deleteChecklistAdmin(check.id).success(function (result, status) {
							if (status == 200) {
								Notifications.success("Проверка удалена");
								angular.element("td[id='"+check.date + check.id + "']").html("").prop("class","")
							}else{
								console.log("Critical error: can't remove checklist");
							}
						});
					}
					$scope.renew = function(){
						ngDialog.closeAll();
						ChecklistService.changeStatus(check.id, 'EDITING').success(function (result, status) {
							if (status == 200) {
								Notifications.success("Проверка восстановлена");
								check.status = result.statusName
								check.displayStatusName = result.displayStatusName;
							}else if(status == 404){
								Notifications.warning("Проверка не найдена");
							}else{
								Notifications.danger("Произошла ошибка");
								console.log("Critical error: can't renew checklist");
							}
						});
					}
				}],
			className: 'ngdialog-theme-plain dialog ',
			showClose: false
		 })
	}

	$scope.checkActive = function () {
		$timeout(function () {
			$('.ui-select-match-item .red, .ui-select-choices-row .red').closest('li').addClass('red');
		}, 10)
	};
		$scope.initProcessSelect = function($select) {
			$scope.uiSelect.process.selected = $scope.processes;
			$select.onRemoveCallback = _deleteUnmatchedQuestions;
		};
		$scope.initQuestionSelect = function($select) {
			$scope.uiSelect.question.selected = $scope.processes;
		};

		$scope.initDivisionSelect = function($select) {};
		function init2() {
			$scope.uiSelect.process.selected = [];
			$scope.uiSelect.question.selected = [];
		}

		$scope.$watch(function () {
			return $scope.uiSelect.division.selected;
		}, function(newVal) {
			$scope.reports.setFilter('division', newVal);
			$scope.shrinkClusters(newVal);
		}, true);
	// Match
		$scope.onTemplateChanged = function(template) {
			if(template){
				$scope.processes = template.categories;
				//$scope.uiSelect.template.selected = template;
			}else if($scope.uiSelect.templates.selected){
				var cats = [];
				for(var i in $scope.uiSelect.templates.selected){
					var weAlreadyHave = cats.map(function(el){return el.id})
					cats = cats.concat($scope.uiSelect.templates.selected[i].categories.filter(function(el){return !~weAlreadyHave.indexOf(el["id"])}))
				}
				$scope.processes = cats;
			}

			init2();
		};

		$scope.selBusinessLinesIds = [];
		$scope.businessLineChanged = function(selectedBusinessLine, groups){ //for xreport3 and xreport5
			$scope.reports.setFilter('businessDirIds', selectedBusinessLine);
			$scope.selBusinessLinesIds = selectedBusinessLine.map(function(elem){
				return elem.id;
			}).join(",");
			if (selectedBusinessLine.length !== 0){
				if(groups){
					$scope.loadData.groups({businessDirIds: $scope.selBusinessLinesIds});
				}
			}else{
                $scope.checklist.selected = null;
				$scope.processes = [];
				$scope.questions = [];
				$scope.uiSelect.process.selected = [];
				$scope.uiSelect.question.selected = [];
			}
            $scope.loadData.templates(false, {businessDirIds: $scope.selBusinessLinesIds});
		};

        $scope.onTemplateChangedData = function(templates) { //for xreport5
            var selectProcesses = [];

            if(templates.length > 0){
              var ids = templates.map(template => template.id);

                TemplateService.getCategoriesOfTemplate(ids).then(function(result) {
                    if (result.status === 200 && result.data.length !== 0){
                        $scope.processes = Object.keys(result.data).map(checklistId => result.data[checklistId]).reduce((result, curr) => {
                          curr = curr.map(categoryInfo => {
                            categoryInfo = categoryInfo.split(': ');
                            var catId = categoryInfo[0];
                            var catName = categoryInfo[1];

                            return { id: catId, name: catName };
                          });

                          return result.concat(curr);
                        });

                        if ($scope.uiSelect.process.selected.length !== 0){
                            selectProcesses = result.data.filter(function(elem){
                            	for (var k=0; k < $scope.uiSelect.process.selected.length; k++){
                                    if (elem.id == $scope.uiSelect.process.selected[k].id){
                                    	return $scope.uiSelect.process.selected[k];
									}
								}
                            });

                            if (selectProcesses.length !== 0){
                                $scope.uiSelect.process.selected = selectProcesses;
                                $scope.onProcessChangedData($scope.uiSelect.process.selected);
							}else{
                                $scope.uiSelect.process.selected = [];
                                $scope.uiSelect.question.selected = [];
                                $scope.questions = [];
							}
						}
                    }else{
                        $scope.uiSelect.process.selected = [];
                        $scope.uiSelect.question.selected = [];
                        $scope.questions = [];
                        $scope.processes = [];
                    }
                    if(result.status !== 200){
                        Notifications.danger("Ошибка! Невозможно загрузить категории выбранного шаблона", '', 2000);
					}
                });
            }else{
                $scope.uiSelect.process.selected = [];
                $scope.uiSelect.question.selected = [];
                $scope.questions = [];
                $scope.processes = [];
			}
        };

		$scope.onProcessChangedData = function(processes) { //for xreport5
            var selectQuestions = [];
            if(processes.length !== 0){
                TemplateService.getQuestionsOfCategory(processes).then(function(result) {
                    if (result.status === 200 && result.data.length !== 0){
                        $scope.questions = result.data;
                        if ($scope.uiSelect.question.selected.length !== 0) {
                            selectQuestions = result.data.filter(function (elem) {
                                for (var l = 0; l < $scope.uiSelect.question.selected.length; l++) {
                                    if (elem.id == $scope.uiSelect.question.selected[l].id) {
                                        return $scope.uiSelect.question.selected[l];
                                    }
                                }
                            });
							$scope.uiSelect.question.selected = selectQuestions;
                        }
                    }else{
                        $scope.uiSelect.question.selected = [];
                        $scope.questions = [];
                        Notifications.danger("Ошибка! Невозможно загрузить вопросы выбранной категории", '', 2000)
                    }
                });
            }else{
                $scope.uiSelect.question.selected = [];
                $scope.questions = [];
			}
			//$scope.checkActive();
		};

		$scope.onProcessChanged = function() {
            if ($scope.subcategories.length === 0 && $scope.categories.length === 0) {
                $scope.processes = $scope.processesAll;
                return;
            }
			_getQuestions($scope.uiSelect.process.selected, function(questions) {
				$scope.questions = questions;
			});

			$scope.checkActive();
		};

		$scope.onProcessChange = function() {
			_getQuestions($scope.uiSelect.process.selected, function(questions) {
				$scope.questions = questions;
			});

			$scope.checkActive();
		};

		$scope.onCategoriesChanged = function(subcategories){
            if (subcategories.length === 0 && $scope.uiSelect.categories.selected.length !== 0) {
				$scope.uiSelect.process.selected = [];
                $scope.processes = $scope.oldProcesses;
				return;
            } else if (subcategories.length === 0 && $scope.uiSelect.categories.selected.length === 0) {
                $scope.processes = $scope.processesAll;
                return;
            }

            $scope.processes = $scope.processesAll.filter(function(el){
                return !!~subcategories.map(function(el){return el.id}).indexOf(el.parentId)
            });
		};
		$scope.onContractorsChangeData = function(contractors) {
			if(contractors.length === 0){
				$scope.shops = angular.copy($scope.fullData.shops);
				$scope.onShopsChangeData($scope.shops);
				return;
			}

			var shops = [];
			$scope.shops = [];
			angular.forEach(contractors, function(item){
				if(item.shopIds){
					if (item.shopIds.length === 0){
						$scope.shops = [];
					}else {
						shops = $scope.fullData.shops.filter(function(shop){
							return !~$scope.shops.map(function(el){return el.id}).indexOf(shop.id) && !!~(item.shopIds.indexOf(shop.id))
						});

						$scope.shops = $scope.shops.concat(shops);
					}
				}
			});

			$scope.onShopsChangeData($scope.shops);
		};

		$scope.onShopsChangeData = function(shops) {
			if(shops.length === 0){
				if($scope.shops.length >0) {
					$scope.clusters = angular.copy($scope.fullData.regions);
				}else{
					$scope.clusters = [];
				}
				$scope.onRegionsChangeData($scope.clusters);
				return;
			}

			var regions = [];
			$scope.clusters = [];
			angular.forEach(shops, function(item){
				if(item.regionId){
					regions = $scope.fullData.regions.filter(function(region){
						return !~$scope.clusters.map(function(el){return el.id}).indexOf(region.id) && (item.regionId === region.id)
					});

					$scope.clusters = $scope.clusters.concat(regions);
				}
			});
			$scope.onRegionsChangeData($scope.clusters);
		};

		$scope.onRegionsChangeData = function(regions) {
			if(regions.length === 0){
				if($scope.clusters.length >0) {
					$scope.divisions = angular.copy($scope.fullData.divisions);
				}else{
					$scope.divisions = [];
				}
				return;
			}

			var divisions = [];
			$scope.divisions = [];
			angular.forEach(regions, function(item){
				if(item.divisionId){
					divisions = $scope.fullData.divisions.filter(function(division){
						return !~$scope.divisions.map(function(el){return el.id}).indexOf(division.id) && (item.divisionId === division.id)
					});

					$scope.divisions = $scope.divisions.concat(divisions);
				}
			});
		};

		$scope.onDivisionsChangeData = function(divisions) {
			if(divisions.length === 0){
				$scope.clusters = angular.copy($scope.fullData.regions);
				return;
			}

			var clusters = [];
			$scope.clusters = [];
			angular.forEach(divisions, function(item){
				clusters = $scope.fullData.regions.filter(function(region){
					return !~$scope.clusters.map(function(el){return el.id}).indexOf(region.id) && (region.divisionId === item.id)
				});
				$scope.clusters = $scope.clusters.concat(clusters);
			});
		};

		$scope.onProcessChangeData = function(processes) {
			if(processes.length === 0){
				$scope.questions = angular.copy($scope.fullData.questions);
				return;
			}

			var questions = [];
			$scope.questions = [];

			angular.forEach(processes, function(item){
				questions = $scope.fullData.questions.filter(function(question) {
					return !~$scope.questions.map(function (el) {return el.id}).indexOf(question.id) && (item.id === question.processId)
				});
				$scope.questions = $scope.questions.concat(questions);
			});
		};

		$scope.onQuestionChangeData = function(questions) {
			if(questions.length === 0){
				$scope.processes = angular.copy($scope.fullData.processes);
				return;
			}

			var processes = [];
			$scope.processes = [];
			angular.forEach(questions, function(item){
				if(item.processId){
					processes = $scope.fullData.processes.filter(function(process){
						return !~$scope.processes.map(function(el){return el.id}).indexOf(process.id) && (item.processId === process.id)
					});

					$scope.processes = $scope.processes.concat(processes);
				}
			});
		};

	// Select all
		$scope.selectAllProcesses = function() {
			$scope.uiSelect.process.selected = $scope.processes;
			$scope.reports.setFilter('process', $scope.uiSelect.process.selected)
		};
    $scope.selectAllUsers = function() {
			$scope.uiSelect.fio.selected = $scope.users;
      $scope.reports.setFilter('userIds', $scope.uiSelect.fio.selected);
		};
		$scope.selectAllFio = function() {
			$scope.uiSelect.fio.selected = $scope.users;
      $scope.reports.setFilter('fio', $scope.uiSelect.fio.selected);
		};
		$scope.selectAllQuestions = function() {
			$scope.uiSelect.question.selected = $scope.questions;
		};
		$scope.selectAllDivisions = function() {
			$scope.uiSelect.division.selected.splice(0);
			_.forEach($scope.divisions, function(item) {
				$scope.uiSelect.division.selected.push(item);
			});
			$scope.reports.setFilter('divisionIds', $scope.uiSelect.division.selected)
		};
		$scope.selectAllClusters = function() {
			$scope.uiSelect.cluster.selected = $scope.clusters;
			$scope.reports.setFilter('clusterIds', $scope.uiSelect.cluster.selected)
		};
		$scope.selectAllGroups = function() {
			$scope.uiSelect.group.selected = $scope.groups;
      $scope.reports.setFilter('groups', $scope.uiSelect.group.selected);
		};
		$scope.selectAllTemplates = function() {
			$scope.uiSelect.templates.selected = $scope.templates;
      $scope.reports.setFilter('template', $scope.uiSelect.templates.selected);
		};
		$scope.selectAllRoles = function() {
			$scope.uiSelect.roles.selected = $scope.authorities;
		};
		$scope.selectAllCategories = function() {
			$scope.uiSelect.categories.selected = $scope.categories;
		};
		$scope.selectAllSubcategories = function() {
			$scope.uiSelect.subcategories.selected = $scope.subcategories;
		};
		$scope.selectAllBusinessLines = function() {
			$scope.uiSelect.businessLine.selected = $scope.businessLines;
		};
		$scope.selectAllContractors = function() {
			$scope.uiSelect.contractors.selected = $scope.contractors;
		};
		$scope.selectAllPositions = function() {
			$scope.uiSelect.positions.selected = $scope.positions;
		};
		$scope.selectAllShops = function() {
			$scope.uiSelect.shops.selected = $scope.shops;
		};
    $scope.selectAllFormsTemplates = function() {
      $scope.uiSelect.formsTemplate.selected = $scope.formsTemplates;
      $scope.reports.setFilter('formsTemplates', $scope.uiSelect.formsTemplate.selected);
      $scope.onFormsTemplateSelectChange($scope.formsTemplates);
    };
    $scope.deselectAllFormsTemplates = function() {
      $scope.uiSelect.formsTemplate.selected = [];
      $scope.reports.setFilter('formsTemplates', $scope.uiSelect.formsTemplate.selected);
      $scope.onFormsTemplateSelectChange([]);
      $scope.deselectAllFormsGroups();
    };
    $scope.selectAllFormsGroups = function() {
      $scope.uiSelect.formsGroup.selected = $scope.formsGroups;
      $scope.reports.setFilter('formsGroups', $scope.uiSelect.formsGroup.selected);
      $scope.onFormsGroupsSelectChange($scope.formsGroups);
    };
    $scope.deselectAllFormsGroups = function() {
      $scope.uiSelect.formsGroup.selected = [];
      $scope.reports.setFilter('formsGroups', $scope.uiSelect.formsGroup.selected);
      $scope.onFormsGroupsSelectChange([]);
      $scope.deselectAllFormsPoints();
    };
    $scope.selectAllFormsPoints = function() {
      $scope.uiSelect.formsPoint.selected = $scope.formsPoints;
      $scope.reports.setFilter('formsPoints', $scope.uiSelect.formsPoint.selected);
    };
    $scope.deselectAllFormsPoints = function() {
      $scope.uiSelect.formsPoint.selected = [];
      $scope.reports.setFilter('formsPoints', $scope.uiSelect.formsPoint.selected);
    };



	// Deselect all
	// 	$scope.deselectTemplate = function() {
	// 		$scope.uiSelect.template.selected = null;
	// 		$scope.deselectAllProcesses();
	// // 	};
		$scope.deselectAllProcesses = function() {
			$scope.uiSelect.process.selected = [];
			$scope.deselectAllQuestions();
		};
		$scope.deselectAllProcess = function() {
			$scope.uiSelect.process.selected = [];
			$scope.deselectAllQuestions();
		};
		$scope.deselectAllQuestions = function() {
			$scope.uiSelect.question.selected = [];
		};
		$scope.deselectAllDivisions = function() {
			$scope.uiSelect.division.selected.splice(0);
		};
		$scope.deselectAllClusters = function() {
			$scope.uiSelect.cluster.selected = [];
      $scope.reports.setFilter('clusterIds', $scope.uiSelect.cluster.selected);
		};
    $scope.deselectAllUsers = function() {
			$scope.uiSelect.fio.selected = [];
      $scope.reports.setFilter('userIds', $scope.uiSelect.fio.selected);
		};
		$scope.deselectAllFio = function() {
			$scope.uiSelect.fio.selected = [];
      $scope.reports.setFilter('fio', $scope.uiSelect.fio.selected);
		};
		$scope.deselectAllGroups = function() {
			$scope.uiSelect.group.selected = [];
      $scope.reports.setFilter('groups', $scope.uiSelect.group.selected);
		};
		$scope.deselectAllTemplates = function() {
			$scope.uiSelect.templates.selected = [];
      $scope.reports.setFilter('template', $scope.uiSelect.templates.selected);
		};
		$scope.deselectAllRoles = function() {
			$scope.uiSelect.roles.selected = [];
		};
		$scope.deselectAllCategories = function() {
			$scope.uiSelect.categories.selected = [];
		};
		$scope.deselectAllSubcategories = function() {
			$scope.uiSelect.subcategories.selected = [];
		};
		$scope.deselectAllBusinessLines = function() {
			$scope.uiSelect.businessLine.selected = [];
		};
    	$scope.deselectAllPositions = function() {
        	$scope.uiSelect.positions.selected = [];
    	};

    $scope.exportData = function(){
		var headText = "Отчёт \"" + $scope.filterName +"\"";
		if(angular.element("input.date_from").val() && angular.element("input.date_to").val())headText += " за "+ angular.element("input.date_from").val() + " - "+ angular.element("input.date_to").val()

		angular.element("div.data").after("<div class='tempXLS' />");
		angular.element("div.tempXLS").html(angular.element("div.data").html());
		//angular.element("div.tempXLS").find("table").prop("border", 1).append("<caption>"+headText+"</caption>")
		var blob = new Blob([angular.element("div.tempXLS").html()], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
		});
		saveAs(blob, headText+".xls");
		angular.element("div.tempXLS").remove()
	}

	$scope.isViewAllProcess = true;
	$scope.isViewAllQuestions = true;


	$scope.refreshShops = function (search) {
        var selectedShops = [];
		if(search.length >= 2){
			ShopService.search(search).success(function(result, status) {
				if(status === 200){
					selectedShops = $scope.reports.getFilter('shop');
					if (selectedShops && selectedShops.length !== 0) {
						$scope.shops = result.filter(function (elem) {
							return (!(selectedShops.map(function(el){return el.id}).indexOf(elem.id) !== -1))
						});
						if($scope.shops.length === 0){
							Notifications.warning("Соответствий больше не найдено")
						}
					} else {
						$scope.shops = result;
					}
				}else if(status === 204){
					$scope.shops = [];
				}
			})
		}else{
			$scope.shops.splice(0, $scope.shops.length);
		}
	};

	$scope.authorities = [];
	$scope.date_format_for_mask = "99.99.9999";
	$scope.reports = ChecklistReportService;

	$scope.reports.getAvailableReports();

	$scope.reportResult = null;
	$scope.progress = false;
	$scope.calendarsOpen = [];
	$scope.Principal = Principal;

	AuthoritiesObjectsService.getAll().success(function(result, status){
		if(status === 200){
			var supervisor = _.find(result, {name: 'ROLE_SUPERVISOR'});

			if(Principal.isInRole(Principal.ROLES.ROLE_SUPERVISOR)) {
				$scope.reports.setFilter('role', [supervisor]);
			}

			$scope.authorities = [
				supervisor,
				_.find(result, {name: 'ROLE_CURATOR'}),
				_.find(result, {name: 'ROLE_SECRET'})
			];
		}
	});

$scope.showStat = function(data){
		console.log("start")
		 ngDialog.open({
			template: 'showStat',
			controller: ['$scope', function($scope) {
				console.log("start2")
					$scope.data = data

				}],
			className: 'ngdialog-theme-plain dialog',
			showClose: false
		 })

	}

	$scope.open = function($event, id) {
		$event.preventDefault();
		$event.stopPropagation();

		$scope.calendarsOpen[1] = false;
		$scope.calendarsOpen[2] = false;
		$scope.calendarsOpen[id] = true;
	};

	$scope.reportChanged = function(report){
		$scope.reportResult = null;
		$scope.reports.setReport(report);
	};

	$scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'dd-MM-yyyy'];
	$scope.format = $scope.formats[2];

	$scope.filterQuery = function(pagination) {
		$scope.progress = true;

		if($scope.pagination){
			$scope.reports.setFilter("page", $scope.pagination.page || undefined);
			$scope.reports.setFilter("perPage", $scope.pagination.limit || undefined)
		}
		if(!pagination){
			$scope.reports.setFilter("page", 1);
			$scope.pagination.page = 1
		}
		$scope.reports.loadReport()
			.then(result => {
                $scope.reportResult = result.data;

                if (!$scope.reportResult.length) return;

                if ($state.current.name === 'report-inspectors' && $scope.reportInspectorsView === 'map') {
					initInspectorsMap($scope.reportResult);
				}

                if ($state.current.name === 'report-manager-coordinates') {
                    $scope.removeSelectedManagerCoordinatesShop();
                    $scope.eventsMap = getEventsMap($scope.reportResult);
                    initManagerCoordinatesMap($scope.reportResult);
				}

				if ($state.current.name === 'report-inspectors' && $scope.reportInspectorsView === 'table') {
					$scope.reportResult.reverse();
				}

				if (result.data.totalCount) {
                    $scope.pagination["total"] = result.data.totalCount;
                    return;
				}

        		var totalElements = result.headers('x-total-elements') || result.headers('x-total-count');
        		$scope.pagination.total =  parseInt(totalElements);
			}).finally(function(){
				$scope.progress = false;
			})
	};

    function getPolylines(polylineStroke) {
        return [
            {
                path: [],
                stroke: polylineStroke ? polylineStroke : { color: '#f3262ede', weight: 1 },
                editable: false,
                opacity: 0.9,
                clickable: true,
                geodesic: true,
                visible: true,
            },
        ];
    }

    function getMapSettings(latitude, longitude) {
        return {
            center: { latitude, longitude },
            zoom: 17,
            options: { streetViewControl: false, mapTypeControl: false, scaleControl: false, rotateControl: false, zoomControl: true },
        };
    }

    function initManagerCoordinatesMap(data) {
        if (!data) return;

        $scope.polylines = [];

        for (let key in $scope.eventsMap) {
            $scope.polylines.push(
                {
                    id: key,
                    options: {
                        keepSpiderfied: true,
                        markersWontMove: true,
                        markersWontHide: true,
                        nearbyDistance: 10,
                    },
                    markersEvents: {
                        click: function(marker, eventName, model) {
                            $scope.onManagerCoordinatesShopSelect(model);
                        },
                        spiderfy: function() {
                            // Обрабатываем этот эвент, чтоб не срабатывал эвент клика
                            $scope.map.popup.show = false;
                        },
                    },
                    paths: $scope.eventsMap[key].map((item, index) => {
                        let icon = { url: '../../../../img/report-images/manager-coordinates-point.png', anchor: { x: 5, y: 4 }, scaledSize: { width: 9, height: 9 } };

                        if (item.shopSap) {
                            icon = { url: '../../../../img/report-images/manager-coordinates-shop.png', anchor: { x: 25, y: 19 }, scaledSize: { width: 45, height: 45 } };
                        } else if (index === 0) {
                            icon = { url: '../../../../img/report-images/manager-coordinates-start.png', anchor: { x: 9, y: 6 }, scaledSize: { width: 19, height: 25 } };
                        } else if (index === $scope.eventsMap[key].length - 1) {
                            icon = { url: '../../../../img/report-images/manager-coordinates-end.png', anchor: { x: 9, y: 10 }, scaledSize: { width: 19, height: 25 } };
                        }

                        return Object.assign(item, {
                            id: key + '' + index,
                            icon,
                            shopSap: item.shopSap,
                            // Вьюшка коряво работает, потому приходится добавить флаг такой
                            isShopSapHidden: item.shopSap === undefined,
                        });
                    }),
                    stroke: { color: '#003882', weight: 2 },
                }
            );
        }

        $scope.map = getMapSettings(data[0].latitude, data[0].longitude);
        $scope.map.popup = { marker: {}, show: false, };
    }

	function initInspectorsMap(data) {
        if (!data) return;

		$scope.polylines = getPolylines();

		$scope.polylines[0].path = data.map((item, index) => {
			let icon = { url: '../../../../img/red-dot.png', anchor: { x: 3, y: 4 }, scaledSize: { width: 7, height: 7 } };

			if (index === 0) {
				icon = { url: '../../../../img/Start.png', anchor: { x: 8, y: 6 }, scaledSize: { width: 17, height: 17 } };
			} else if (index === data.length - 1) {
				icon = { url: '../../../../img/Finish.png', anchor: { x: 10, y: 6 }, scaledSize: { width: 17, height: 17 } };
			}

			return {
				id: index + 1,
				latitude: item.latitude,
				longitude: item.longitude,
				date: moment(item.pointDateTime).format('DD-MM-YYYY HH:mm'),
				icon,
			}
		});

        $scope.map = getMapSettings(data[0].latitude, data[0].longitude);
	}

    function getEventsMap(data) {
        const eventsMap = {};

        data.forEach((item, index) => {
            const date = moment(item.pointDateTime).format('DD-MM-YYYY');

            if (!eventsMap[date]) {
                eventsMap[date] = [Object.assign(item, { id: date + '_' + index })];
            } else {
                eventsMap[date].push(Object.assign(item, { id: date + '_' + index }));
            }
        });
        return eventsMap;
    }

    $scope.onManagerCoordinatesShopSelect = point => {
        if ($scope.selectedManagerCoordinatesShop.id === point.id) {
            $scope.selectedManagerCoordinatesShop = {};
            $scope.map.popup.show = false;
            return;
        }

        const elementId = point.shopSap ? point.id : null;
        $scope.selectedManagerCoordinatesShop = { id: point.id, pointId: elementId ? elementId : undefined, shopSap: point.shopSap };
        $scope.map.popup.marker = point;
        $scope.map.popup.show = true;

        $rootScope.scrollTo('reports.scrollable', point.id);
    };

    $scope.removeSelectedManagerCoordinatesShop = () => {
        $scope.selectedManagerCoordinatesShop = {};

        if ($scope.map && $scope.map.popup.show) {
            $scope.map.popup.show = false;
        }
    }

    $scope.goToEventTask = event => {
        if (!event) return;

        if (event.eventType === 'task') {
            window.open($state.href('tasks.taskFullView', { id: event.eventObjectId }),'_blank');
        } else if (event.eventType === 'act') {
            window.open($state.href('inspectionAct', { uid: event.eventObjectId }),'_blank');
        } else if (event.eventType === 'checklist') {
            window.open($state.href('editChecklist', { checklist: event.eventObjectId }),'_blank');
        }
    };

	$scope.changeTypeViewReportInspectors = function(type) {
		$scope.reportInspectorsView = type;

		if ($scope.reportResult) {
			$scope.reportResult.reverse();
		}

		if (type === 'map' && $scope.reportResult) {
			initInspectorsMap($scope.reportResult);
		}
	};

	$scope.loadData = {
		shops: function() {
			//if($scope.shops)return;
			$scope.shops = [];
			ObjectServiceForShops.query({}, function(result) {
				angular.forEach(result, function (s) {
					$scope.shops.push(s);
				});
				$scope.initShopSelect();
			})
		},
		divisions: function() {
			//if($scope.division)return;
			$scope.divisions = [];
			ObjectServiceForDivisions.query({}, function(result) {
				//$scope.divisions.push($scope.selectAllDivisions);
				if(Principal.isInRole(Principal.ROLES.ROLE_SUPERVISOR) || Principal.isInRole(Principal.ROLES.ROLE_DIRECTOR_CLUSTER)) {
					$scope.reports.setFilter('division', result);
				}
				angular.forEach(result, function(d, dKey) {
					$scope.divisions.push(d);
				});
            })
        },
    dependentDivisions: function() {
      $scope.divisions = [];
      OrgstructService.getDivisions().then(function(result) {
        if (result.status === 200) {
          $scope.divisions = result.data;
        }
      });
    },
		groups: function(params){
            var selectGroup;
            var reqGroupParams ={};
			//if($scope.groups)return;
			$scope.loadedGroups = false;
			$scope.groups = [];
			if (params){
				(params.businessDirIds) && (reqGroupParams.businessDirIds = params.businessDirIds);
			}
            OrgstructService.getGroups(reqGroupParams).success(function (result) {
                if(Principal.isInRole(Principal.ROLES.ROLE_SUPERVISOR) || Principal.isInRole(Principal.ROLES.ROLE_DIRECTOR_CLUSTER)) {
                    $scope.reports.setFilter('group', result);
                }
                angular.forEach(result, function(d, dKey) {
                    $scope.groups.push(d);
                });

                if($scope.group.selected && reqGroupParams.businessDirIds){
                    if($scope.groups.length !== 0){
                        selectGroup = $scope.groups.some(function(elem){
                            return elem.id == $scope.group.selected.id
                        });
                        (!selectGroup) && ($scope.group.selected = null);
                    }

                    if($scope.groups.length === 0){
                        $scope.group.selected = null;
                    }
                }
                $scope.loadedGroups = true;
            })
		},
		clusters: function(){
			$scope.clustersAll = [];
			$scope.clusters = [];
			ObjectServiceForClusters.query({}, function(result) {

				if(Principal.isInRole(Principal.ROLES.ROLE_SUPERVISOR) || Principal.isInRole(Principal.ROLES.ROLE_DIRECTOR_CLUSTER)) {
					$scope.reports.setFilter('cluster', result);
				}

				angular.forEach(result, function(c, cKey) {
					$scope.clusters.push(c);
				});
			});
			$scope.clustersAll = $scope.clusters;
		},
		templates: function(old, params){
            var selectChecklist;
            var reqTemplateParams = {};
            $scope.loadedTemplates = false;
			$scope.templates = [];
            if (params){
                (params.businessDirIds) && (reqTemplateParams.businessDirIds = params.businessDirIds)
            }
			if(old){
				ObjectServiceForTemplates.query({asTree: true}, function(result) {
					angular.forEach(result, function(t, tKey) {
						$scope.templates.push(t);
					});
				});
			}else{
                TemplateService.getAll2(reqTemplateParams).then(function(result) {
					$scope.templates = result.data;
					if($scope.checklist.selected && reqTemplateParams.businessDirIds){
						if(result.data.length !== 0){

							selectChecklist = result.data.some(function(elem){
								return elem.id == $scope.checklist.selected.id
							});

							if(!selectChecklist){
                                $scope.checklist.selected = null;
                                $scope.processes = [];
                                $scope.questions = [];
                                $scope.uiSelect.process.selected = [];
                                $scope.uiSelect.question.selected = [];
							}
						}else{
                            $scope.checklist.selected = null;
                            $scope.processes = [];
                            $scope.questions = [];
                            $scope.uiSelect.process.selected = [];
                            $scope.uiSelect.question.selected = [];
						}
					}
					$scope.loadedTemplates = true;
				});
            }
		},
		users: function(filters){
			//if($scope.users)return;
			$scope.users = [];
			OrgstructService.getUsers(filters).success(function (data) {
				if (data.status !== 404) {
					$scope.users = data;
				} else {
					$scope.users = [];
				}
	        });

		},
    dependentUsers: function() {
      $scope.users = [];
			OrgstructService.getDependentUsers().then(function (result) {
				if (result.status === 200) {
					$scope.users = result.data;
				} else {
					$scope.users = [];
				}
      });
    },
		categories: function(){
			PWCategoryService.getCategories().then(function(data) {
		        $scope.categories = data;
		        $scope.categoriesAll = angular.copy(data)
		    });
		},
		subcategories: function(){
			PWCategoryService.getSubcategories().then(function(data) {
		        $scope.subcategories = data;
		        $scope.subcategoriesAll = angular.copy(data)
		    });

		},
		processes: function(){
			PWCategoryService.getProcesses().then(function(data) {
		        $scope.processes = data;
		        $scope.processesAll = angular.copy(data)
	    	});
		},
        businessLines: function(){
            $scope.businessLines = [];
            OrgstructService.getBusinessDirs().then(function(data) {
            	if(data.status == 200){
                    $scope.businessLines = data.data;
				}else{
                    Notifications.danger("Не удается загрузить бизнес-направления");
				}

            });
        },
        positions: function(){
            $scope.positions = [];
            FilterService.getPositions().then(function(result) {
              if (result.status === 200) {
                $scope.positions = result.data.map(item => {
                  if (!Boolean(item)) {
                    return 'Не указана';
                  }
                  return item;
                });
              }
            });
        },
		initDataCleaningBasic: function(){
            FilterService.getDataCleaningBasic().then(function(data) {
                if(data.status === 200){
                    $scope.fullData = data.data.body;
                    $scope.contractors = data.data.body.contractors;
                    $scope.divisions = data.data.body.divisions;
                    $scope.clusters = data.data.body.regions;
                    $scope.shops = data.data.body.shops;
                }
            });
		},
        initDataCleaningDetailed: function(){
            FilterService.getDataCleaningDetailed().then(function(data) {
                if(data.status === 200){
                	$scope.fullData = data.data.body;
                    $scope.contractors = data.data.body.contractors;
                    $scope.divisions = data.data.body.divisions;
                    $scope.clusters = data.data.body.regions;
                    $scope.shops = data.data.body.shops;
                    $scope.questions = data.data.body.questions;
                    $scope.processes = data.data.body.processes;
                }
            });
        },
        formsTemplates: function(full) {
          FormsHttpService.queryTemplates({}, !full).then(result => {
            if (result.status === 200) {
              // $scope.formsTemplates = result.data;
              var template  = result.data[0];
              template.groups.forEach(group => $scope.formsGroups.push(group));
              $scope.reports.setFilter('formsTemplates', [template]);
            }
          });
        }
	};

	$scope.goToChecklist = function(checklistId){
		window.open($state.href('editChecklist', {checklist: checklistId}),'_blank');
	};

	$scope.shrinkClusters = function(divisions){
		if(!divisions || divisions.length == 0){
			$scope.clusters = $scope.clustersAll;
			return;
		}
		var divisionsIds = [];
		for(var i in divisions){
			divisionsIds.push(divisions[i].id)
		}

		//fill possible clusters
		$scope.clusters = [];
		for(var i in $scope.clustersAll){
			if(!!~divisionsIds.indexOf($scope.clustersAll[i].divisionId))$scope.clusters.push($scope.clustersAll[i])
		}

		//filter selected clusters
		$scope.uiSelect.cluster.selected = $scope.uiSelect.cluster.selected.filter(function(item){return !!~divisionsIds.indexOf(item.divisionId)});

	}

	$scope.shrinkSubcategories = function(categories){
		if(categories.length === 0 || categories.length === $scope.categoriesAll){
			$scope.subcategories = $scope.subcategoriesAll;
			$scope.processes = $scope.processesAll;
			return
		}

		$scope.subcategories = $scope.subcategoriesAll.filter(function(el){
			return !!~categories.map(function(el){return el.id}).indexOf(el.parentId)
		});

		if($scope.subcategories.length === 1){
			$scope.uiSelect.subcategories.selected = $scope.subcategories;
		}else{
			$scope.uiSelect.subcategories.selected = $scope.uiSelect.subcategories.selected.filter(function(subcategory){return !!~categories.map(function(cat){return cat.id}).indexOf(subcategory.parentId)})
		}

		$scope.shrinkProcesses($scope.subcategories, categories)

	};

	$scope.shrinkProcesses = function(subcategories, categories){
        var subCatsAndCats = subcategories.concat(categories);

		if(subcategories.length === 0 && $scope.categories.length === 0){
			$scope.processes = $scope.processesAll;
			return
		}

		$scope.processes = $scope.processesAll.filter(function(el){
			return !!~subCatsAndCats.map(function(el){return el.id}).indexOf(el.parentId)
		});

		$scope.oldProcesses = $scope.processes;

		if($scope.processes.length === 1){
			$scope.uiSelect.process.selected = $scope.processes;
		}else{
			$scope.uiSelect.process.selected = $scope.uiSelect.process.selected.filter(function(process){return !!~subCatsAndCats.map(function(cat){return cat.id}).indexOf(process.parentId)})
		}
	};

	$scope.init = function() {
		// Хз что это ваще и зачем. Закомментил из-за FPR-266. Есть вероятность что всё же оно для чего-то нужно
		// 03.07.18 by koryan

		// if (Principal.isInRole("ROLE_SUPERVISOR") && !Principal.isInRole("ROLE_OPERATION_DIRECTOR")) {
		// 	$scope.loadData.shops();

		// 	Account.get()
		// 		.then(function (e) {
		// 			console.log("!!!!!!!", 'fio', [e.data])
		// 			$scope.reports.setFilter('fio', [e.data]);
		// 		});
		// }


		//временный костыль. В ближайшем будущем отчёты объединим
		//
		// появился где-то в районе конца 15 года afaik
		// 03.07.15 by koryan

		if($state.current.name.substr(0,1) != "x" && !['filter-forms', 'report-inspectors', 'report-manager-coordinates'].includes($state.current.name)){
			if($state.current.name != 'filter4') {
				$scope.loadData.templates(true);
			}

			$scope.loadData.divisions();
			$scope.loadData.clusters();
		}


		var reports = {};

		switch ($state.current.name) {
			case 'filter1':
                $scope.loadData.users();
				reports = ChecklistReports[0];
				$scope.filterName = $filter('getFilterName')('filter1');
				break;
			case 'filter2':
                $scope.loadData.users();
				reports = ChecklistReports[1];
				$scope.filterName = $filter('getFilterName')('filter2');
                $scope.loadData.positions();
				break;
			case 'filter3':
                $scope.loadData.users();
				reports = ChecklistReports[2];
				$scope.filterName = $filter('getFilterName')('filter3');
				break;
			case 'filter4':
                $scope.loadData.users();
				reports = ChecklistReports[3];
				$scope.filterName = $filter('getFilterName')('filter4');
				break;
			case 'filter5':
                $scope.loadData.users();
				reports = ChecklistReports[4];
				$scope.filterName = $filter('getFilterName')('filter5');
				break;
			case 'filter6':
                $scope.loadData.users();
				reports = ChecklistReports[5];
				$scope.filterName = $filter('getFilterName')('filter6');
				$scope.getAccrTemplates();
				break;
			case 'filter7':
                $scope.loadData.users();
				reports = ChecklistReports[6];
				$scope.filterName = $filter('getFilterName')('filter7');
				$scope.getAccrTemplates();
				break;
			case 'filterHR':
				reports = ChecklistReports[7];
				$scope.filterName = $filter('getFilterName')('filterHR');
				break;
			case 'xfilter1':
				// $scope.loadDivisions();
				// $scope.loadClusters();
				// $scope.loadGroups();
				$scope.loadData.divisions();
				$scope.loadData.clusters();
				$scope.loadData.groups();

				reports = ChecklistReports[8];
				$scope.filterName = $filter('getFilterName')('xfilter1');
				break;
			case 'xfilter2':
				$scope.loadData.users();
				$scope.loadData.templates();

				reports = ChecklistReports[9];
				$scope.filterName = $filter('getFilterName')('xfilter2');
				break;
			case 'xfilter3':
				$scope.loadData.divisions();
				$scope.loadData.clusters();
				$scope.loadData.groups();
				$scope.loadData.users();
				$scope.loadData.templates();
                $scope.loadData.businessLines();

				reports = ChecklistReports[10];
				$scope.filterName = $filter('getFilterName')('xfilter3');
				break;
			case 'xfilter4':
				$scope.loadData.groups();
				$scope.loadData.templates(true);
				$scope.loadData.divisions();
                $scope.loadData.users();

				reports = ChecklistReports[11];
				$scope.filterName = $filter('getFilterName')('xfilter4');
				break;
			case 'xfilter5':
				$scope.loadData.divisions();
				$scope.loadData.templates(null, null, true);
                $scope.loadData.businessLines();

				reports = ChecklistReports[12];
				$scope.filterName =$filter('getFilterName')('xfilter5');
				break;
			case 'xfilter6':
				$scope.loadData.groups();
				$scope.loadData.templates(true);

				reports = ChecklistReports[13];
				$scope.filterName = $filter('getFilterName')('xfilter6');
				break;
			case 'xfilter7':
				reports = ChecklistReports[14];
				$scope.filterName = $filter('getFilterName')('xfilter7');
				break;
			case 'xfilter8':
                $scope.loadData.users();
				$scope.loadData.groups();
				$scope.loadData.templates(true);
				$scope.loadData.divisions();

				reports = ChecklistReports[15];
				$scope.filterName = $filter('getFilterName')('xfilter8');
				break;
			case 'xfilter9':
				$scope.loadData.divisions();
				$scope.loadData.clusters();
				$scope.loadData.templates();
				$scope.loadData.subcategories();
				$scope.loadData.categories();
				$scope.loadData.processes();
				reports = ChecklistReports[16];
				$scope.filterName = $filter('getFilterName')('xfilter9');
				break;
			case 'xfilter10':
				$scope.loadData.divisions();
				$scope.loadData.clusters();
				$scope.loadData.templates();
				reports = ChecklistReports[17];
				$scope.filterName = $filter('getFilterName')('xfilter10');
				break;
			case 'xfilter11':
			  $scope.loadData.initDataCleaningBasic();
			  reports = ChecklistReports[18];
			  $scope.filterName = $filter('getFilterName')('xfilter11');
			  break;
			case 'xfilter12':
			  $scope.loadData.initDataCleaningDetailed();
			  reports = ChecklistReports[19];
			  $scope.filterName = $filter('getFilterName')('xfilter12');
			  break;
			case 'filter-forms':
				reports = ChecklistReports[20];
				$scope.filterName = $filter('getFilterName')('filterForms');
				loadFormsFiltersData();
       		 break;
    		case 'filter-forms-results':
				reports = ChecklistReports[21];
				$scope.filterName = $filter('getFilterName')('filterFormsResults');
				loadFormsFiltersData();
    		break;
      		case 'filter-logs':
        		reports = ChecklistReports[22];
        		$scope.filterName = $filter('getFilterName')('filterLogs');
			break;
			case 'filter-transactions':
				reports = ChecklistReports[23];
				$scope.filterName = $filter('getFilterName')('transactions');
				break;
            case 'report-inspectors':
                reports = ChecklistReports[24];
                $scope.localLang = config.multiselectTranslations;
                $scope.loadData.users({ roles: ['ROLE_INSPECTOR'] });
                $scope.filterName = $filter('getFilterName')('reportInspectors');
                break;
            case 'report-manager-coordinates':
                reports = ChecklistReports[25];
                $scope.localLang = config.multiselectTranslations;
                $scope.loadData.users({ levels: 'REGION', active: true });
                $scope.filterName = $filter('getFilterName')('reportManagerCoordinates');
                $scope.reportClasses = 'rmc';
                $timeout(() => {
                    $scope.reports.setFilter('deviationInMeters', $scope.deviationInMeters);
                }, 0)

                break;
		}
        $scope.reportChanged(reports);
	};


	function loadFormsFiltersData() {
		$scope.loadData.dependentDivisions();
		$scope.loadData.clusters();
  		$scope.loadData.groups();
		$scope.loadData.dependentUsers();
	}

    $scope.currentName = $state.current.name;

    $scope.getViewRelExcel = function(currentName) {
        var xFilters = ['filter2', 'filter3', 'filter5', 'xfilter3', 'xfilter5',  'xfilter9', 'xfilter10', 'xfilter11', 'xfilter12', 'filter-forms', 'filter-forms-results', 'filter-logs', 'filter-transactions'];
        return !!~xFilters.indexOf(currentName) || (!!~['xfilter8', 'xfilter3'].indexOf(currentName) &&  !!~['intersection', 'md'].indexOf(projectConfig.nameProject));
    };

    $scope.getViewRelExcelKPO = function(currentName) {
        var xFilters = ['xfilter8', 'xfilter3'];
        return !!~xFilters.indexOf(currentName) && !!~['carousel'].indexOf(projectConfig.nameProject);
    };

	$scope.getAccrTemplates = function() {
		TemplateService.getAllAccreditated(function(data) {
			$scope.accrTemplates = data;
		});
	};

    $scope.getBusinessLines = function() {
        OrgstructService.getBusinessDirs(function(data) {
            $scope.businessLines = data;
        });
    };

  var selectedFormsTemplates = [];

  $scope.onFormsTemplateSelectChange = function(selected) {
    $scope.formsGroups = [];

    if (selected.length === 0) {
      return;
    }

    var prevSelectedIds = selectedFormsTemplates.map(template => template.id);
    var addedTemplates = selected.filter(template => prevSelectedIds.indexOf(template.id) === -1);

    if (addedTemplates.length === $scope.formsTemplates.length) {
      setAllTemplatesGroups();
      return;
    }

    var addedTemplate = addedTemplates[0];

    selected.forEach(template => {
      if (addedTemplate && template.id === addedTemplate.id) {
        return;
      }

      $scope.formsGroups = $scope.formsGroups.concat(fullTemplates[template.id].groups);
    });

    if (addedTemplate) {
      addOneTemplateGroups(addedTemplate);
    }

    selectedFormsTemplates = selected;
  };

  $scope.onFormsGroupsSelectChange = function(selected) {
    $scope.formsPoints = [];

    if (selected.length === 0) {
      $scope.uiSelect.formsPoint.selected = [];
      $scope.reports.setFilter('formsPoints', $scope.uiSelect.formsPoint.select);
      return;
    }

    selected.forEach(group => {
      $scope.formsPoints = $scope.formsPoints.concat(group.points);
    });
  };

  $scope.getFormsQuestions = function(questionsAnswers) {
    return questionsAnswers.map(obj => obj.questionName);
  };

  $scope.getFormsAnswers = function(questionsAnswers) {
    return questionsAnswers.map(obj => obj.answer);
  };

  $scope.openInNewTab = function(state, params) {
    var url = $state.href(state, params);
    window.open(url, '_blank');
  };

  function addOneTemplateGroups(template) {
    if (fullTemplates[template.id]) {
      $scope.formsGroups = $scope.formsGroups.concat(fullTemplates[template.id].groups);
      return;
    }

    FormsHttpService.getTemplateById(template.id).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка загрузки групп выбранного шаблона форм сбора!');
        return;
      }

      var template = result.data;
      fullTemplates[template.id] = template;
      $scope.formsGroups = $scope.formsGroups.concat(template.groups);
    })
  }

  function setAllTemplatesGroups() {
    $scope.formsGroups = [];

    var allFullTemplatesLoaded = Object.keys(fullTemplates).length === $scope.formsTemplates.length;

    if (allFullTemplatesLoaded) {
      Object.keys(fullTemplates).forEach(id => {
        $scope.formsGroups = $scope.formsGroups.concat(fullTemplates[id].groups);
      });
    } else {
      FormsHttpService.queryTemplates({}).then(result => {
        if (result.status !== 200) {
          Notifications.danger('Ошибка загрузки групп выбранных шаблонов форм сбора!');
          return;
        }

        var templates = result.data;
        templates.forEach(template => {
          fullTemplates[template.id] = template;
          $scope.formsGroups = $scope.formsGroups.concat(template.groups);
        });
      });
    }
  }

	$scope.init();

	$scope.reports.setFilter('rangeFrom', $scope.rangeFrom);
	$scope.reports.setFilter('rangeTo', $scope.rangeTo)

});

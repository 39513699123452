angular.module('smartApp')
	.controller('dashboardCalendarCtl', ['$scope', function ($scope) {

	}])
	.directive('dashboardCalendar', function($timeout) {
		return {
			restrict: 'A',
			require: ['^uiCalendar'],
			controller: 'dashboardCalendarCtl',
			link: function(scope, elm, attrs, controller) {
				var $jarvis = $('.jarviswidget');

				$jarvis.off('click', '#calendar-buttons #btn-prev');
				$jarvis.off('click', '#calendar-buttons #btn-next');
				$jarvis.off('click', '#calendar-buttons #btn-today');

				$jarvis.on('click', '#calendar-buttons #btn-prev', function () {
					elm.find('.fc-button-prev').click();
					scope.uiConfig.calendar.monthChange(-1);
					refresh();
					return false;
				});

				$jarvis.on('click', '#calendar-buttons #btn-next', function () {
					elm.find('.fc-button-next').click();
					scope.uiConfig.calendar.monthChange(1);
					refresh();
					return false;
				});

				function refresh() {
					$timeout(function () {
						scope.initEvents();
						scope.$apply();
					}, 0);
				}

				$jarvis.on('click', '#calendar-buttons #btn-today', function () {
					elm.find('.fc-button-today').click();
					return false;
				});

				//scope.$on('monthChanged', function() {
				//	scope.uiConfig.calendar.refetchEvents();
				//});

				scope.$on("monthChanged", function(event, arg) { refresh() })

				scope.uiConfig.calendar.eventRender = function (event, element, icon) {

					if (event.description) {
						element.find('.fc-event-title').append("<br/><span class='ultra-light'>" + event.description +"</span>");
					}
					if (event.icon) {
						element.find('.fc-event-title').append("<i class='air air-top-right fa " + event.icon +" '></i>");
					}

					if (event.editable) {$(element).draggable()}
				};

				scope.uiConfig.calendar.eventClick = function(calEvent, jsEvent, view) {
					var remove_clicked = $(jsEvent.target).hasClass('fa-times');
					if (remove_clicked) {
						scope.uiConfig.calendar.eventCloseClick(calEvent);
					} else {
						scope.uiConfig.calendar.click(calEvent);
					}
				};
			}
		};
	});

angular.module('smartApp').factory('Xfilter4', checkXreportFactory4);
checkXreportFactory4.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory4(ChecklistService, DownloadService) {
	return {
		id: 9,
		title: 'Отчёты - Проверки & процессы',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter4/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter4/view.html',
		isValid: function(filters) {
			//var valid = filters.rangeFrom && filters.rangeTo && filters.division && filters.division.length != 0  && (! || )
			angular.element("filter name.error").removeClass("error")
			var errs = [];
			if(!filters.rangeFrom)errs.push("rangeFrom")
			if(!filters.rangeTo)errs.push("rangeTo")
			if(!(filters.division && filters.division.length != 0))errs.push("uiSelect.division.selected")
			if(filters.checklistIds && !filters.checklistIds.match(/^(?:\d\,?\s*)+\d?$/))errs.push("checklistIds")

			errs.map(function(elem){
				angular.element("[ng-model='"+ elem +"']").parent().prev().addClass("error")
			})		
		console.log(filters)
			console.log(errs)	
			return errs.length == 0;

		},
		load: function(filters) {
			var t = ChecklistService.getXreport4(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport4(filters);
		}
	};

}
<script setup>
import { ref, watch } from 'vue';
import { CustomInput, ULabel } from '@eron/ui-kit';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const month = ref();
const day = ref();
let skipUpdate = false;

const emitChange = () => {
    emit('update:model-value', month.value && day.value ? { months: [month.value], days: [day.value] } : undefined);
    skipUpdate = true;
};

const validate = () => {
    return Boolean(month.value) && Boolean(day.value);
};

const onMonthBlur = () => {
    month.value = month.value ? Math.min(Math.max(month.value, 1), 6) : month.value;
    emitChange();
};

const onDayBlur = () => {
    day.value = day.value ? Math.min(Math.max(day.value, 1), 31) : day.value;
    emitChange();
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        month.value = Array.isArray(newVal?.months) && newVal.months.length ? newVal.months[0] : undefined;
        day.value = Array.isArray(newVal?.days) && newVal.days.length ? newVal.days[0] : undefined;
    },
    { immediate: true }
);

defineExpose({
    validate,
});
</script>

<template>
    <div>
        <u-label for="half-yearly-settings-month"> Введите номер месяца от 1 до 6 </u-label>
        <custom-input id="half-yearly-settings-month" v-model="month" type="number" class="-mb-3" @blur="onMonthBlur" />

        <u-label for="half-yearly-settings-day"> Введите день месяца от 1 до 31 </u-label>
        <custom-input id="half-yearly-settings-day" v-model="day" type="number" @blur="onDayBlur" />
    </div>
</template>

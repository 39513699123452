'use strict';

angular.module('smartApp').factory('QualityControlService', function QualityControlService($http, config) {

    return {
        getExcel: function (saps, filters) {
            //return  $http.get(config.backend + '/compliance/answers/by-sap/2047?from=2017-01-01T00:00:00%2E000%2B03&to=2017-02-25T00:00:00%2E000%2B03', {responseType: "arraybuffer", headers: {'Accept': 'application/vnd.ms-excel'}})//здесь точно есть данные
            if (saps.length === 0) {
                return $http.get(config.backend + '/compliance/answers/', {
                    responseType: "arraybuffer",
                    params: filters,
                    headers: {'Accept': 'application/vnd.ms-excel'}
                });
            } else if (saps.length === 1) {
                return $http.get(config.backend + '/compliance/answers/by-sap/' + saps[0], {
                    responseType: "arraybuffer",
                    params: filters,
                    headers: {'Accept': 'application/vnd.ms-excel'}
                });
            } else {
                //not implemented yet
                //stub - get result for first shop
                return $http.get(config.backend + '/compliance/answers/by-sap/' + saps[0], {
                    responseType: "arraybuffer",
                    params: filters,
                    headers: {'Accept': 'application/vnd.ms-excel'}
                });
            }
        }
    }
});
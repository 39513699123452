'use strict';

angular.module('smartApp')

    .constant('CounterTypes', [
        {name: 'commercial', title: 'Коммерческий'},
        {name: 'technical', title: 'Технический'}
    ])

    .constant('CounterConnectionTypes', [
        {name: 'direct', title: 'Прямой'},
        {name: 'transform', title: 'Трансформаторный'}
    ])

    .filter('ToCounterType', function(CounterTypes){
        return function(input, def){
            var result = _.find(CounterTypes, function(e){return e.name === input});
            return result && result.title || def || '';
        }
    })

    .factory('CounterService', function CategoryService($http, config, HttpUtils) {

    return {
        getAllByShopSap: function (shopSap) {
            return $http.get(config.backend + '/counters/getAllByShopSap?shopSap=' + shopSap)
                .success(function (data) {
                    return data;
                }
            );
        },

        getCounters: function(divisionId, clusterIds, shopIds, search, page, limit){
            var params = {
                divisionIds: [divisionId],
                clusterIds: clusterIds,
                shopIds: shopIds,
                model: search,
                page: page,
                per_page: limit
            };
            var paramsString = HttpUtils.getParamString(params, true);
            return $http.get(config.backend + '/objects/counters' + (paramsString ? '?' + paramsString: ''))
        },

        getCounterById: function(counterId){
            return $http.get(config.backend + '/counters/getCounterById?counterId=' + counterId)
        },

        createCounterEntry: function(counter) {
            return $http.post(config.backend + '/counters/createCounterEntry', counter)
                .success(function(data) {
                    return data;
                }
            );
        },
        updateCounter: function(counter) {
            return $http.post(config.backend + '/counters/updateCounter', counter)
        },

        deleteCounter: function(id){
            return $http.delete(config.backend + '/counters/counter/' + id);
        },

        getReport: function(divisions, page, limit){
            var params = {
                divisionIds: divisions,
                page: page,
                per_page: limit,
	            rnd: (+new Date()) + (Math.round(Math.random() * 100))
            };
            var paramsString = HttpUtils.getParamString(params, true);
            return $http.get(config.backend + '/reports/counter' + (paramsString ? '?' + paramsString: ''));
        },

        getEntriesHistory: function(counterId, page, limit){
            var params = {
                counterId: counterId,
                page: page,
                per_page: limit
            };
            var paramsString = HttpUtils.getParamString(params, true);
            return $http.get(config.backend + '/reports/getEntriesHistory' + (paramsString ? '?' + paramsString: ''));
        },

        updateEntry: function(entry){
            return $http.put(config.backend + '/counters/counterEntries', entry);
        },

        getExtendReport: function(counterId, from, to){
            var params = {
                counterId: counterId,
                fromDate: moment(from).format('DD-MM-YYYY'),
                toDate: moment(to).format('DD-MM-YYYY')
            };
            var paramsString = HttpUtils.getParamString(params, true);
            return $http.get(config.backend + '/reports/counterPerMonth' + (paramsString ? '?' + paramsString: ''));
        },

        getAllModels: function() {
            return $http.get(config.backend + '/counters/getAllModels')
                .success(function(data) {
                    return data;
                }
            );
        },

        createNewCounter: function(shopSap, serialNumber, model, ktt, counterYear, counterCalInterval, transYear, transCalInterval) {
	        var params = {
		        shopSap: shopSap,
		        serialNumber: serialNumber,
		        active: true,
		        model: model,
		        ktt: ktt,
		        counterYear: counterYear,
		        counterCalInterval: counterCalInterval,
		        transYear: transYear,
		        transCalInterval: transCalInterval
	        };

            return $http.post(config.backend + '/counters/createNewCounter', params)
                .success(function(data) {
                    return data;
                }
            );
        },
        createNewModel: function(model) {
            return $http.post(config.backend + '/counters/createNewModel', model);
        },

        getModelById: function(id) {
            return $http.get(config.backend + '/counters/getModelById?id=' + id);
        },

        updateModel: function(model){
            return $http.post(config.backend + '/counters/updateModel', model);
        },

        deleteModel: function(id){
            return $http.post(config.backend + '/counters/deleteModel?id=' + id);
        }
    }
});
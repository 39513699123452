'use strict';

import { listen, events } from '@/events';
import { refreshData } from '@shared/data';

angular.module('smartApp', [
	'templates',
	'ngResource',
	'ui.router',
	'LocalStorageModule',
	'ui.bootstrap',
	'plunker',
	'app.controllers',
	'app.activity',
	'app.smartui',
	'app.navigation',
	'app.main',
	'app.demoControllers',
	'app.localize',
	//'ui.tree',
	'frapontillo.bootstrap-duallistbox',
	'ngDialog',
	'ui.bootstrap.accordion',
	'ui.calendar',
	'ui.select',
	'ngSanitize',
	'angularMoment',
	"isteven-multi-select",
	'chart.js',
	'ngTable',
	'ngFileUpload',
	'uiRouterStyles',
	'thatisuday.ng-image-gallery',
	'treeControl',
	//'yaMap',
	'dndLists',
	'uiGmapgoogle-maps',
	'angular-click-outside',
	"isteven-multi-select"
])
	.run(function($rootScope, $location, $http, $state, Auth, Principal, ChecklistEditor, PrincipalRoles, ChecklistService, $filter) {
		listen(events.unauthorized, () => {
			$rootScope.$emit('logout');
			$location.path('/login');
		});

		$rootScope.history = [];

		// $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
		// });

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			$rootScope.history.push({state: fromState, params: fromParams});
			//Auth.authorize();
		});

		$rootScope.back = function() {
			if ($rootScope.history.length) {
				$rootScope.history.pop();
				window.history.back();
			} else {
				$state.go('home');
			}
		};

		// Если elem нет, можно указать скроллить вверх, передав 'top',
		// либо 'bottom', тогда проскроллит вниз
		$rootScope.scrollTo = function (scrollable, elem, cb, offsetTop) {
			const offsetTopValue = offsetTop ? offsetTop : 0;
			const $scrollable = $(scrollable);
			let scrollTopPosition;
			let duration = 200;

			if (elem === 'top' || elem === 'bottom') {
				scrollTopPosition = elem === 'top' ? 0 : $scrollable[0].scrollHeight;
			} else {
				const elemPosition = $(typeof elem === 'string' ? '#' + elem : elem)[0].offsetTop;
				scrollTopPosition = elemPosition - $scrollable.offset().top - offsetTopValue;
				duration = Math.max(Math.abs(elemPosition - $scrollable.scrollTop()), 300);
			}

			$scrollable.animate(
				{
					scrollTop: scrollTopPosition,
				},
				duration
			);

			if (cb) cb();
		};
	})

	.factory('timeoutHttpIntercept', function ($rootScope, $q) {
		return {
			'request': function(config) {
				config.timeout = config.timeout || 5 * 60000;
				return config;
			}
		};
	})

	.factory('originInterceptor', function() {
		return {
			request: function(config) {
				if (location.hostname !== 'localhost') {
					config.headers['Origin'] = location.origin;
				}

				return config;
			}
		}
	})


	.factory('authInterceptor', function ($rootScope, $q, $location, localStorageService, Notifications, ApiErrors, ThrobberService) {
		function _showErrorMessage (response) {
			if(!ApiErrors[response.headers('x-error')])return;
			if(ApiErrors[response.headers('x-error')].warning){
				Notifications.warning("Предупреждение", ApiErrors[response.headers('x-error')] && ApiErrors[response.headers('x-error')].title);
			}else Notifications.danger("Ошибка", ApiErrors[response.headers('x-error')] && ApiErrors[response.headers('x-error')].title);
		}

		var _fnDebounceShowError = _.debounce(_showErrorMessage, 1000, true);

		function _wrongNetworkError (response) {
			if(response.headers('x-error') === 'EXA3') {
				_fnDebounceShowError(response);
				$rootScope.$emit('logout');
				$location.path('/login');
				return true;
			}
			return false;
		}

		return {
			// Add authorization token to headers
			request: function (config) {

				if (!config.noThrobber)ThrobberService.showThrobber();
				config.headers = config.headers || {};
				var token = localStorageService.get('token');

				if(!token || (token  && token.expires && token.expires <= new Date().getTime())){
					$rootScope.$emit('logout');

					$location.path('/login');
					return config;
				}

				if (token && token.expires && token.expires > new Date().getTime()) {
					config.headers['x-auth-token'] = token.token;
				}

				// Disable cache in IE
				// config.headers['Cache-Control'] = 'no-cache';
				// config.headers['If-Modified-Since'] = '0';

				return config;
			},

			responseError: function(rejection) {
				console.log('Response error', rejection);

				ThrobberService.hideThrobber();
				if(rejection) {
					var xErrorHeaders = typeof rejection.headers === 'function' ? rejection.headers('x-error') : false;

					if (xErrorHeaders && rejection.status != 401 && rejection.status != 404) {
						_showErrorMessage(rejection);
					}

					if (rejection.status == 401 && !(window.location + '').includes('login')) {
						$rootScope.$emit('logout');
						$location.path('/login');
					}
				}
				if(((""+rejection.status).substr(0, 1) == 5 || rejection.status == -1) && rejection.config.url.substr(5) == "xauth/account"){
					$rootScope.$broadcast("criticalServerError");
					// return rejection;
				}
				return rejection;
			},

			response: function(response) {
				ThrobberService.hideThrobber();

				if(response && response.headers('x-error')) {
					if(!_wrongNetworkError(response)) {
						_showErrorMessage(response);
					} else {
						response.wrongNetwork = true;
						response.status = 403;
					}
				}
				return response;
			}
		};
	})
	.config(['uiGmapGoogleMapApiProvider', function (GoogleMapApi) {
		GoogleMapApi.configure({
			key: 'AIzaSyAWhFbsoX9-Fbd6bXLEqeLIIqXXB9WXhk8',
			//v: '3.20', //(defaults to 3.X)

			language:'ru-RU' //set whatever langs u want
		});
	}])


	.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider', function($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {

		$stateProvider.state('processWizard', {
			parent: 'wizards',
			url: '/processWizard',
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/hout.css'],
				breadcrumbs: [['database', 'Конструктор чек-листов'], ['chain', 'Библиотека процессов']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/constructor/hout/hout.html',
					controller: 'HoutController'
				}
			}
		});

		$stateProvider.state('filter1', {
			parent: 'checklist',
			url: '/report1',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', 'Проверки']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter2', {
			parent: 'checklist',
			url: '/report2',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', 'Оценка за период']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter3', {
			parent: 'checklist',
			url: '/report3',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', 'Отчет по календарю']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter4', {
			parent: 'checklist',
			url: '/report4',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', 'Привязка магазина к проверяющему']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter5', {
			parent: 'checklist',
			url: '/report5',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', 'Количество посещений']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filterHR', {
			parent: 'checklist',
			url: '/reporthr',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Отчет HR"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter1', {
			parent: 'checklist',
			url: '/xreport1',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "План/Норма"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter2', {
			parent: 'checklist',
			url: '/xreport2',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "План/Факт"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter3', {
			parent: 'checklist',
			url: '/xreport3',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Проверки"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter4', {
			parent: 'checklist',
			url: '/xreport4',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Проверки & процессы"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter5', {
			parent: 'checklist',
			url: '/xreport5',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Задания"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter6', {
			parent: 'checklist',
			url: '/xreport6',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Отклонения"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('xfilter7', {
			parent: 'checklist',
			url: '/xreport7',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Сводный"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter8', {
			parent: 'checklist',
			url: '/xreport8',
			data: {
				roles: ['ROLE_ADMIN', 'REPORT_CHECKLISTS_CATEGORIES_QUESTIONS'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Проверки-процессы-вопросы"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter9', {
			parent: 'checklist',
			url: '/xreport9',
			data: {
				roles: ['ROLE_ADMIN', 'REPORT_BPP'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Блоки-подблоки-процессы"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter10', {
			parent: 'checklist',
			url: '/xreport10',
			data: {
				roles: ['ROLE_ADMIN', 'REPORT_NON_WORKING_DM'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "ДМ, не заполняющие чеклисты"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter11', {
			parent: 'checklist',
			url: '/xreport11',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Отчет по клинингу"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});

		$stateProvider.state('xfilter12', {
			parent: 'checklist',
			url: '/xreport12',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Отчет по клинингу (детально)"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('report-inspectors', {
			parent: 'checklist',
			url: '/report-inspectors',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Перемещения ревизоров"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('report-manager-coordinates', {
			parent: 'checklist',
			url: '/report-manager-coordinates',
			data: {
				css: ['css/states/report-manager-coordinates.css'],
				breadcrumbs: [['table', 'Отчеты по проверкам'], ['wrench', "Перемещение менеджеров по продажам"]]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter-forms', {
			parent: 'checklist',
			url: '/report-forms',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по проверке наличия']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter-forms-results', {
			parent: 'checklist',
			url: '/report-forms-results',
			data: {
				roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER'],
				breadcrumbs: [['table', 'Отчеты по итогам проверки наличия']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter-logs', {
			parent: 'checklist',
			url: '/report-logs',
			data: {},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		$stateProvider.state('filter-transactions', {
			parent: 'checklist',
			url: '/report-transactions',
			data: {},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/clreports/clreports.html',
					controller: 'ChecklistReportsController'
				}
			}
		});
		// Admin panel
		$stateProvider.state('panel_users', {
			parent: 'admin_panel',
			url: '/panel_users',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes', 'Администрирование'], ['user', 'Пользователи']]

			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_users/panel_users.html'
				}
			}
		});
		$stateProvider.state('panel_groups', {
			parent: 'admin_panel',
			url: '/panel_groups',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes','Администрирование'], ['group', 'Группы']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_groups/panel_groups.html',
					controller: 'PanelGroupsController'
				}
			}
		});
		$stateProvider.state('panel_business_line', {
			parent: 'admin_panel',
			url: '/panel_business_line',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes', 'Администрирование'], ['flag', 'Бизнес-направления']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_business_line/panel_business_line.html'
				}
			}
		});
		$stateProvider.state('panel_roles', {
			parent: 'admin_panel',
			url: '/panel_roles',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes','Администрирование'], ['flag', 'Отчетные роли']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_roles/panel_roles.html'
				}
			}
		});
		$stateProvider.state('panel_shops', {
			parent: 'admin_panel',
			url: '/panel_shops',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes','Администрирование'], ['shopping-cart', 'Магазины']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_shops/panel_shops.html'
				}
			}
		});
		$stateProvider.state('instructions', {
			parent: 'admin_panel',
			url: '/instructions',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cloud-download', 'Инструкции']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/instructions/index.html'
				}
			}
		});
		$stateProvider.state('quality_control_service', {
			parent: 'admin_panel',
			url: '/quality_control_service',
			data: {
				roles: ['ROLE_COMPLIANCE'],
				breadcrumbs: [['table', 'Модуль оценки качества обслуживания']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/quality_control_service/quality_control_service.html',
					controller: 'QualityControlController'
				}
			}
		});
		$stateProvider.state('panel_divisions', {
			parent: 'admin_panel',
			url: '/panel_divisions',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes', 'Администрирование'], ['map-marker', 'Дивизионы']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_divisions/panel_divisions.html'
				}
			}
		});
		$stateProvider.state('panel_clusters', {
			parent: 'admin_panel',
			url: '/panel_clusters',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes', 'Администрирование'], ['map-marker', 'Регионы']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_clusters/panel_clusters.html'
				}
			}
		});
		$stateProvider.state('panel_contractors', {
			parent: 'admin_panel',
			url: '/panel_contractors',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['cubes', 'Администрирование'], ['map-marker', 'Контрагенты']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/admin_panel/panel_contractors/panel_contractors.html',
					controller: 'PanelContractorsController',
					controllerAs: 'vm'
				}
			}
		});
		$stateProvider.state('editChecklist', {
			parent: 'checklist',
			url: '/editChecklist?checklist&resolverId',
			data: {
				roles: ['ROLE_SECRET', 'ROLE_CURATOR', 'ROLE_DIRECTOR', 'ROLE_SUPERVISOR'],
				breadcrumbs: [['calendar', 'Календарь'], ['list', 'Подтвердить проверку']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/checklist/editChecklist/editchecklist.html',
					controller: 'EditChecklistController'
				}
			}
		});
		$stateProvider.state('checkNum', {
			parent: 'wizards',
			url: '/checkNum',
			data: {
				roles: ['ROLE_ADMIN'],
				breadcrumbs: [['database', 'Конструктор чек-листов'], ['wrench', 'Количество проверок']]
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/constructor/checkNum/checkNum.html',
					controller: 'checkNumChecklistsController'
				}
			}
		});

		$stateProvider.state('tasksTemplates', {
			parent: 'site',
			url: '/tasksTemplates',
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/tasksTemplates.css'],
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/tasks/tasksTemplates/tasksTemplates.html',
					controller: 'TasksTemplatesController'
				}
			}
		}).state('tasksTemplates.editor', {
			url: '/:id',
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/tasksTemplates.css'],
			},
			resolve:{
				taskTemplate: function($stateParams, $q, TasksTemplateService){
					var q = $q.defer();

					TasksTemplateService.getTemplate($stateParams.id, function (err, data) {
						if(err){
							q.reject(err);
							console.log(err);
						}
						q.resolve(data);
					})

					return q.promise;
				},
			},

			views: {
				'content@': {
					templateUrl: 'scripts/app/tasks/tasksTemplates/taskTemplateEdit.html',
					controller: 'TaskTemplateEditController'
				}
			}
		});

		$stateProvider.state('dashboard3', {
			parent: 'site',
			url: '/dashboard3',
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/dashboard3.css'],
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/dashboard3/dashboard3.html',
					controller: 'Dashboard3Controller'
				}
			}
		});

		$stateProvider
			.state('templateWizard', {
				parent: 'wizards',
				url: '/templateWizard',
				data: {
					roles: ['ROLE_ADMIN'],
					css: ['css/states/template-wizard.css'],
					breadcrumbs: [['database', 'Конструктор чек-листов'], ['chain', 'Шаблоны']]
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/constructor/templateWizard/index.html',
						controller: 'TemplateWizardController'
					}
				}
			})
			.state('templateWizard.editor', {
				url: '/:id',
				data: {
					roles: ['ROLE_ADMIN'],
					css: ['css/states/template-wizard-editor.css'],
					breadcrumbs: [['database', 'Конструктор чек-листов'], ['chain', 'Шаблоны'], ['edit', 'Редактирование']]
				},
				resolve: {
					allBusinessDirs: function(OrgstructService, $q){
						var q = $q.defer();
						OrgstructService.getBusinessDirs({sort: 'name,desc'}).then(function(res, status) {
							q.resolve(res);
						})
							.catch(function(err) {
								Notifications.danger('Произошла ошибка в загрузке данных бизнес-направлений!');
								q.reject(err);
								console.log(err);
							});
						return q.promise;
					},
					allUsers: function(OrgstructService, $q){
						var q = $q.defer();
						OrgstructService.getUsers({shopDir: false}).then(function(res, status) {
							q.resolve(res);
						})
							.catch(function(err) {
								Notifications.danger('Произошла ошибка в загрузке данных пользователей!');
								q.reject(err);
								console.log(err);
							});
						return q.promise;
					},
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/constructor/templateWizard/editor.html',
						controller: 'TemplateWizardEditorController'
					}
				}
			})
			.state('templateWizard.editor.zones', {
				url: '/zones',
				data: {
					roles: ['ROLE_ADMIN'],
					css: ['css/states/template-wizard-editor.css']
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/constructor/templateWizard/editor.html',
						controller: 'TemplateWizardEditorController'
					}
				}
			});

		$stateProvider.state('settings', {
			parent: 'site',
			url: '/settings',
			views: {
				'content@': {
					templateUrl: 'scripts/app/settings/settings.html',
					controller: 'SettingsController'
				}
			},
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/settings.css'],
				breadcrumbs: [['wrench', 'Настройки'], ['chain', 'Параметры']]
			}
		})
			.state('settings.notifications', {
				url: '/notifications',
				templateUrl: 'scripts/app/settings/notifications/notifications.html',
				controller: 'SettingsNotificationsController',
				data: {
					css: ['css/states/settings.notifications.css'],
					breadcrumbs: [['wrench', 'Настройки'], ['envelope', 'Уведомления']]
				}
			})
			.state('settings.cleaning', {
				url: '/cleaning',
				templateUrl: 'scripts/app/settings/cleaning/cleaning.html',
				controller: 'SettingsCleaningController',
				controllerAs: 'vm',
				data: {
					css: ['css/states/settings.cleaning.css'],
					breadcrumbs: [['wrench', 'Настройки'], ['envelope', 'Справочник параметров задач']]
				}
			})
			.state('settings.integration', {
				url: '/integration',
				templateUrl: 'scripts/app/settings/integration/view.html',
				controller: 'SettingsIntegrationController',
				data: {
					css: ['css/states/settings.integration.css']
				}
			})
			.state('settings.push', {
				url: '/push',
				templateUrl: 'scripts/app/settings/push/view.html',
				controller: 'SettingsPushController',
				data: {
					css: ['css/states/settings.push.css']
				}
			});

		$stateProvider.state('downloadApp', {
			parent: 'site',
			url: '/downloadApp',
			data: {
				roles: ['ROLE_ADMIN'],
				css: ['css/states/downloadApp.css'],
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/downloadApp/downloadApp.view.html',
					controller: 'DownloadAppController'
				}
			}
		});

		$stateProvider
			.state('inspectionActs', {
				parent: 'site',
				url: '/inspection-act',
				data: {
					css: ['css/states/inspection-acts.css'],
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/inspection-acts/inspection-acts.view.html',
						controller: 'InspectionActsController',
					}
				}
			})
			.state('inspectionAct', {
				parent: 'inspectionActs',
				url: '/:uid',
				data: {
					css: ['css/states/inspection-acts.css'],
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/inspection-acts/act-full-view/act-full-view.view.html',
						controller: 'ActFullViewController',
					}
				}
			});

		$stateProvider
			.state('goods', {
				parent: 'site',
				url: '/goods',
				data: {
					css: ['css/states/goods.css'],
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/goods/work-orders.view.html',
						controller: 'WorkOrdersController',
					}
				}
			})
			.state('goods.order', {
				parent: 'goods',
				url: '/:number&status=:status',
				data: {
					css: ['css/states/goods.css'],
				},
				views: {
					'content@': {
						templateUrl: 'scripts/app/goods/goods-order/goods-order.view.html',
						controller: 'GoodsOrderController',
					}
				}
			}).state('goods.box', {
			parent: 'goods.order',
			url: '/:localcode&name=:name&status=:status',
			data: {
				css: ['css/states/goods.css'],
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/goods/goods-box/goods-box.view.html',
					controller: 'GoodsBoxController',
				}
			}
		}).state('vueApp', {
			parent: 'site',
			url: '/vue/{path:.*}',
			data: {
				roles: []
			},
			views: {
				'content@': {
					template: '<vue-wrapper component="App"></vue-wrapper>',
					controller: function() {
						console.log('Vue App State Activated'); // Debug log
					}
				}
			}
		});

		$urlRouterProvider.otherwise(function($injector, $location){
			const path = $location.path();
			const Principal = $injector.get('Principal');
			const $state = $injector.get('$state')
			const query = new URLSearchParams(window.location.search);
			const code = query.get('code');
			const error = query.get('error');
			const errorDescription = query.get('error_description');
	
			if (!Principal.getIdentity() && (error || errorDescription || code)) {
				$injector.get('localStorageService').set('oidc:result', { code, error, errorDescription });
			} else if(path == "/" && $location.search().login && $location.search().password && $location.search().cbu){
				var cbu = $location.search().cbu;

				$injector.get('Auth').login({
					username: $location.search().login,
					password: $location.search().password
				}).then(function (arr) {
					$injector.get('$state').go('home');
				}, function(e){
					window.location.href = cbu;
				})

			} else if (path === '/' || path === '') {
				const account = Principal.getIdentity();

				if (account) {
					if (Principal.isInRole("ROLE_ADMIN")) {
						$state.go('start')
					} else {
						$state.go('home')
					}
				} else {
					$state.go('login');
				}
			} else if (path === '/vue') {
				$location.path('/vue/');
			} else if (path.startsWith('/vue/')) {
				$injector.get('$state').go('vueApp');
			} else {
				$injector.get('$state').go('notFound');
			}
		});

		console.log('Routes configured'); // Debug log

		$stateProvider.state('site', {
			'abstract': true,
			views: {
				'navbar@': {
					templateUrl: 'scripts/components/navbar/navbar.html',
					controller: 'NavbarController'
				},
				'leftpanel@': {
					templateUrl: 'scripts/components/leftpanel/leftpanel.html',
					controller: 'LeftbarController'
				}
			},
			resolve: {
				authorize: ['Auth',
					function (Auth) {
						return Auth.authorize();
					}
				],
				data: ['authorize', 'Principal', 'ThrobberService', function(_, Principal, ThrobberService) {
					if (Principal.isIdentityResolved()) {
						ThrobberService.showThrobber();
						return refreshData().then(() => ThrobberService.hideThrobber());
					}
				}],
			}
		});

		$stateProvider.state('notFound', {
			parent: 'site',
			views: {
				'content@': {
					templateUrl: 'scripts/app/notFound/notFound.html'
				}
			},
			data: {
				breadcrumbs: []
			}
		});

		$stateProvider.state('charts', {
			parent: 'site',
			url: '/charts',
			data: {
				roles: []
			},
			views: {
				'content@': {
					templateUrl: 'scripts/app/charts/charts.html',
					controller: 'ChartsController'
				}
			}
		});

		$httpProvider.interceptors.push('authInterceptor');
		$httpProvider.interceptors.push('timeoutHttpIntercept');
		$httpProvider.interceptors.push('originInterceptor');
	}])
	.run(['$rootScope', function($rootScope) {
		$rootScope.$on('$stateChangeStart', function(event, toState) {
			console.log('State change start:', toState.name); // Debug log
		});

		$rootScope.$on('$stateChangeSuccess', function(event, toState) {
			console.log('State change success:', toState.name); // Debug log
		});

		$rootScope.$on('$stateNotFound', function(event, unfoundState) {
			console.log('State not found:', unfoundState.to); // Debug log
		});

		$rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
			console.log('State change error:', error); // Debug log
		});
	}]);


var isMobile = getComputedStyle(document.getElementById('mobile-detect')).display === 'none';

angular.module('smartApp').constant('config', {
	'backend': "/api",
	'backendOrgstruct': "/api/orgstruct",
	'backendV2': "/api/v2",
	'notificationTimeout': 2000,
	'photoCreate': '/upload',
	'pathToPhoto': '/files/',
	'maxPhotoSize': 10000000,
	'isMobile': isMobile,
	'userPermissionsCacheTime': 1000*60*60*24,
	'multiselectTranslations': {
		selectAll: "Выбрать",
		selectNone: "Отменить",
		reset: "Сбросить",
		search: "Поиск...",
		nothingSelected: "Ничего не выбрано"
	}
});


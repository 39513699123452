import api from '../index';

const base = '/api/tasks-manual/templates';

export const getRules = params => {
    return api.get(base, { params });
};

export const getRule = id => {
    return api.get(`${base}/${id}`);
};

export const createRule = body => {
    return api.post(base, body);
};

export const updateRule = (id, body) => {
    return api.put(`${base}/${id}`, body);
};

export const getRuleAuthors = () => {
    return api.get(`${base}/filters/author`);
};

export const uploadAttachments = (ruleId, attachments) => {
    const formData = new FormData();

    attachments.forEach(item => {
        formData.append('attachments[0][value]', item, item.name);
    });

    return api.post(`/api/attachmentService/forTaskTemplate/${ruleId}`, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
    });
};

export const getAttachments = ruleId => {
    return api.get(`/api/attachmentService/forTaskTemplate/${ruleId}`);
};

export const deleteAttachment = (ruleId, uid) => {
    return api.delete(`/api/attachmentService/forTaskTemplate/${ruleId}`, {
        params: { uid },
    });
};

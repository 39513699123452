angular.module('smartApp').factory('FormsHttpService', ['$http', 'config', function FormsHttpService($http, config) {
  function getReportParams(filters) {
    return {
      dateType: filters.dateType,
      fromDate: moment(filters.rangeFrom).format('DD-MM-YYYY'),
      toDate: moment(filters.rangeTo).format('DD-MM-YYYY'),
      divisions: filters.divisionIds && filters.divisionIds.map(division => division.id),
      regions: filters.clusterIds && filters.clusterIds.map(region => region.id),
      userGroupIds: filters.groups && filters.groups.map(group => group.id),
      executorIds: filters.fio && filters.fio.map(user => user.id),
      shopIds: filters.shops && filters.shops.map(shop => shop.id),
      templateFormIds: filters.formsTemplates && filters.formsTemplates.map(template => template.id),
      groupFormIds: filters.formsGroups && filters.formsGroups.map(group => group.id),
      pointFormIds: filters.formsPoints && filters.formsPoints.map(point => point.id),
      taskIds: !filters.taskIds ? undefined : filters.taskIds.split(",").map(item => parseInt(item.trim())),
      page: filters.page,
      perPage: filters.perPage
    };
  }

  return {
    getFormsReport: function(filters) {
      return $http.post(config.backend + '/reports/forms', getReportParams(filters));
    },
    getFormsResultsReport: function(filters) {
      return $http.post(config.backend + '/reports/inspectionPercentage', getReportParams(filters));
    },
    getTemplateById: function(templateId) {
      return $http.get(config.backend + '/forms/templates/' + templateId);
    },
    queryTemplates: function(params, simple) {
      var url = config.backend + '/forms/templates';

      if (simple) {
        url += '/simple'
      }

      return $http.get(url, { params: params });
    }
  };
}]);

/**
 * Created by vsko on 26.07.16.
 */

'use strict';

angular.module('smartApp').factory('PWCategoryService', function ($http, config) {
	return {

		getAll: function(cb) {
			$http.get(config.backend + '/admin/constructor/processes').success(function (result) {
				cb(result); //fuck! ошибка передаётся ПЕРВОЙ! переделать
			})
		},

		getOne: function(processId, cb) {
			$http.get(config.backend + '/admin/constructor/processes/' + processId).success(function (result) {
				cb(result);  //fuck! ошибка передаётся ПЕРВОЙ! переделать
			})
		},

		getQuestions:  function(processId, cb) {
			$http.get(config.backend + '/admin/constructor/processes/' + processId + '/questions', {noThrobber:true}).success(function (result, status) {
				cb((status == 200 || status == 204)?null:status, result);
			})
		},

		addQuestion: function(processId, question, cb){
			$http.post(config.backend + '/admin/constructor/processes/' + processId + '/questions', question).success(function (result, status) {
				cb((status == 200 || status == 204)?null:status, result);
			})
		},

		addProcess: function(processObj, cb){
			$http.post(config.backend + '/admin/constructor/processes/', processObj).success(function (result, status) {
				cb((status == 200 || status == 204)?null:status, result);
			})
		},

		deleteProcess: function(processId, cb) {
			$http.delete(config.backend + '/admin/constructor/processes/' + processId, {noThrobber:true}).success(
				function (result, status) {
					if (status && status != 200) {
						cb(null, status);
					}
					cb(null, result);
				}
			);
		},

		renameProcess: function(processId, processName, cb) {
			$http.put(config.backend + '/admin/constructor/processes/' + processId + '/name', processName, {noThrobber: true}).success(
				function (result, status) {
					cb((status == 200 || status == 204) ? null : status, result);
				}
			);
		},

		updateWeightProcess: function(processId, processWeight, cb) {
			$http.put(config.backend + '/admin/constructor/processes/' + processId + '/weight', processWeight, {noThrobber: true}).success(
				function (result, status) {
					cb((status == 200 || status == 204) ? null : status, result);
				}
			);
		},

        updateProcessParent: function(processId, parentId, cb) {
            $http.put(config.backend + "/admin/constructor/processes/" + processId + "/parent", parentId, {noThrobber: true}).success(
                function (result, status) {
                    if (status && status != 200) {
                        cb(null, status);
                    }
                    cb(null, result);
                }
            );
        },

        getProcessByValue: function(searchVal) {
            return $http.post(config.backend + '/admin/constructor/processes/search', JSON.stringify(searchVal))
                .then(function (response) {
                    return response.status == 200 ? response.data : [];
                }, function (response) {
                    console.log(response);
                });
        },

        getProcessPathById: function (processId) {
            return $http.get(config.backend + '/admin/constructor/processes/' + processId + '/path' )
                .then(function (response) {
                    return response.status == 200 ? response.data : [];
                }, function (response) {
                    console.log(response);
                });
        },



        getCategories: function () {
            return $http.get(config.backend + '/admin/constructor/processes/blocks' )
                .then(function (response) {
                    return response.status == 200 ? response.data : [];
                }, function (response) {
                    console.log(response);
                });
        },
        getSubcategories: function (parentIds) {

            return $http.get(config.backend + '/admin/constructor/processes/subBlocks' + ((parentIds && parentIds.length > 0)?'?parentIds=' + parentIds:''))
                .then(function (response) {
                    return response.status == 200 ? response.data : [];
                }, function (response) {
                    console.log(response);
                });
        },
        getProcesses: function (parentIds) {
            return $http.get(config.backend + '/admin/constructor/processes/leafs' + ((parentIds && parentIds.length > 0)?'?parentIds=' + parentIds:''))
                .then(function (response) {
                    return response.status == 200 ? response.data : [];
                }, function (response) {
                    console.log(response);
                });
        },




    };


		// search: function(str, cb){
		// 	$http.get(config.backend + "/admin/constructor/processes/search?q="+str, {noThrobber: true}).success(
		// 		function (result, status) {
		// 			if (status && status != 200 && status != 204) {
		// 				cb(status, result);
		// 			}
		// 			cb(null, result);
		// 		}
		// 	);
		// }

});

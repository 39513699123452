'use strict';

angular
    .module('smartApp')
    .factory('AssignTemplateServiceForTemplates', function($resource, config) {
        return $resource(
            config.backend + '/assignTemplate/templates/:id',
            {},
            {
                query: { method: 'GET', isArray: true },
                get: { method: 'GET' },
                update: { method: 'PUT' }
            }
        );
    });

(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('tasksDefaultFilter', tasksDefaultFilter);

    function tasksDefaultFilter() {
        var directive = {
            templateUrl: 'scripts/app/tasks/tasks-default-filter/tasks-default-filter.view.html',
            bindToController: true,
            controller: TasksDefaultFilterController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                generalFilter: '=',
                filter: '&',
                showExtended: '=',
                taskType: '<?'
            }
        };
        return directive;
    }

    TasksDefaultFilterController.$inject = [
        'TasksDefaultFilterService',
        'ObjectServiceForChecklists',
        'moment',
        'ShopService',
        'Principal',
        'OrgstructService',
        'TWTemplateService',
        'config'
    ];

    function TasksDefaultFilterController (
        TasksDefaultFilterService,
        ObjectServiceForChecklists,
        moment,
        ShopService,
        Principal,
        OrgstructService,
        TWTemplateService,
        config
    ) {
        var vm = this;

        vm.rolesFilter = TasksDefaultFilterService.getRolesFilter();

        vm.statusesFilter = TasksDefaultFilterService.getStatusesFilter();

        vm.overdue = false;
        vm.multiselectTranslations = {}
        vm.multiselectTranslations.shop = angular.copy(config.multiselectTranslations)
        vm.multiselectTranslations.template = angular.copy(config.multiselectTranslations)
        vm.multiselectTranslations.template.nothingSelected = "Выберите шаблон"
        vm.multiselectTranslations.shop.nothingSelected = "Выберите магазин"

        vm.filterByMultyVal = filterByMultyVal;
        vm.filterByOverdue = filterByOverdue;
        vm.changeDate = changeDate;
        vm.changeVal = changeVal;
        vm.clearVal = clearVal;

        vm.templates = [];
        vm.shops = [];

        vm.datepickers = {};

        init();

        var preDoFilter = function(){
            if(vm.generalFilter.template) {
                vm.generalFilter.templateId = (vm.generalFilter.template[0])?vm.generalFilter.template[0].id:undefined;
            }
            if(vm.generalFilter.shop) {
                vm.generalFilter.shopId = (vm.generalFilter.shop[0])?vm.generalFilter.shop[0].id:undefined;
            }
            vm.filter();
        }

        function init() {
            if (vm.taskType === 'routed') {
                initRoutedFilters();
            }

            if (vm.generalFilter.role) {
                const selectedRoles = vm.generalFilter.role.split(',');
                vm.rolesFilter.forEach(item => {
                    item.isSelected = selectedRoles.includes(item.val);
                });
            }

            if (vm.generalFilter.taskStatus) {
                const selectedStatuses = vm.generalFilter.taskStatus.split(',');
                vm.statusesFilter.forEach(item => {
                    item.isSelected = selectedStatuses.includes(item.val);
                });
            }
        }

        function filterByMultyVal(list, type) {
            TasksDefaultFilterService.selectMultyValues(list, vm.generalFilter, type);
            preDoFilter();
        }

        function filterByOverdue() {
            if (vm.overdue) {
                vm.generalFilter.overdue = vm.overdue;
            } else {
                delete vm.generalFilter.overdue;
            }
            preDoFilter();
        }

        function initRoutedFilters() {
            vm.templatesLoading = true;
            TWTemplateService.getAllWithParams({active:true, hasChecklists: true}, function(err, data){
                vm.templatesLoading = false;
                if (err) {
                    Notifications.danger('Ошибка загрузки шаблонов');
                    return;
                }
                console.log(data)
                vm.templates = data.map((el)=>{
                    el.id = ""+el.id
                    return el
                })
            });
            
            ShopService
                .getAllObjectsByActorId(Principal.getIdentity().id)
                .then(function(resp) {
                    if (resp.status === 200 && resp.data && resp.data.length) {
                        _.forEach(resp.data, function(shop) {
                            shop.id = shop.id.toString();
                            shop.name = OrgstructService.getShopName(shop);
                            vm.shops.push(shop);
                        });
                    }
                });
            vm.datepickers = {
                dateFrom: {
                    val: null,
                    isOpen: false
                },
                dateTo: {
                    val: null,
                    isOpen: false
                }
            };
            if (vm.generalFilter.dateTo || vm.generalFilter.dateFrom) {
                vm.datepickers.dateTo.val = new Date(vm.generalFilter.dateTo) || null;
                vm.datepickers.dateFrom.val = new Date(vm.generalFilter.dateFrom) || null;
            }
            console.log(vm.generalFilter)
        }

        function changeDate(date, key) {
            if (vm.taskType === 'routed') {
                vm.generalFilter.taskTypes = 'routed';
            }
            vm.generalFilter[key] = date ? moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS') : null;
            preDoFilter();
        }

        function changeVal() {
            if (vm.taskType === 'routed') {
                vm.generalFilter.taskTypes = 'routed';
            }
            preDoFilter();
        }

        function clearVal(key) {
            if (vm.taskType === 'routed') {
                vm.generalFilter.taskTypes = 'routed';
            }
            vm.generalFilter[key] = undefined;
            preDoFilter();
        }


        vm.clearChecklist = function(){
            vm.generalFilter.checklistId = undefined;
            vm.generalFilter.shop = undefined;
            vm.generalFilter.template = undefined;
            vm.changeVal()
        }
    }
})();

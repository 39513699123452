/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 11.06.15.
 */

(function () {
	'use strict';

	angular.module('smartApp')
		.directive('indeterminate', function() {
			return {
				restrict: 'A',
				require: 'ngModel',
				scope: {
					model: '=ngModel'
				},
				link: function(scope, element, attrs, ctrl){
					scope.$watch('model', function(){
						element.prop("indeterminate", scope.model === null);
					});
				}
			};
		});
})();

angular.module('smartApp').controller('XfilterController10', XfilterController10);
XfilterController10.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService', 'PWCategoryService', 'TemplateService'];

function XfilterController10($scope, UserService, ChecklistReportService, PanelGroupsService, PWCategoryService, TemplateService) {
	//$scope.users = [];
	$scope.fio = {selected:undefined}

	//$scope.selectAllTemplates()

	// TemplateService.getOnlyDM().then(function(result) {
	// 	$scope.uiSelect.templates.selected = result.data
	// });
}


'use strict';

angular.module('smartApp').controller('WorkOrdersController', function(localStorageService, $scope, Dashboard3Service, GoodsService, config, Notifications, $state, PanelShopsResource) {
    const defaultLimit = 20;
    let shopsFilters = { page: 1, perPage: 100, allLoaded: false, search: '' };
    $scope.perPageVariants = [ 5, 10, 15, 20 ];
    $scope.localLang = config.multiselectTranslations;
    $scope.shops = [];
    $scope.workOrders = [];
    $scope.totalWorkOrders = 0;
    $scope.statuses = [{
        title: 'Новые',
        val: 'NEW',
        isSelected: false
    }, {
        title: 'В работе',
        val: 'IN_PROGRESS',
        isSelected: false
    }, {
        title: 'Закрытые',
        val: 'CLOSED',
        isSelected: false
    }];
    $scope.dates = { statusModifiedAtFrom: moment().subtract(1, 'month'), statusModifiedAtTo: moment() };
    $scope.dateOptions = { showWeeks: false };
    $scope.calendarsOpen = [];
    $scope.filters = { page: 1, size: localStorageService.get("perPage") ? localStorageService.get("perPage") : defaultLimit, searchLike: '', shopSaps: [] };

    $scope.open = function(id) {
        $scope.calendarsOpen[1] = false;
        $scope.calendarsOpen[2] = false;
        $scope.calendarsOpen[id] = true;
    };

    $scope.clearFilters = function() {
        $scope.dates = { statusModifiedAtFrom: moment().subtract(1, 'month'), statusModifiedAtTo: moment() };
        $scope.filters = { page: 1, size: localStorageService.get("perPage") ? localStorageService.get("perPage") : defaultLimit, searchLike: '', shopSaps: [] };
        $scope.shops.forEach(s => s.ticked = false);
        $scope.statuses.forEach(status => status.isSelected = false);
        fetchWorkOrders();
    };

    $scope.getFilteredWorkOrders = function() {
        if (localStorageService.get("perPage") !== $scope.filters.size) localStorageService.set("perPage", $scope.filters.size);

        let statuses = $scope.statuses.filter(s => s.isSelected).map(s => s.val);

        // На бэке есть статус SENT, который никак не юзается, но в него попадают CLOSED акты, потому нужно мапить, чтоб вместе с закрытыми еще и SENT отображались
        if (statuses.includes('CLOSED')) {
            statuses.push('SENT');
        }

        const params = Object.assign({}, $scope.filters, {
            page: $scope.filters.page - 1,
            shopSaps: $scope.filters.shopSaps.length ? $scope.filters.shopSaps.map(s => s.sap) : undefined,
            statuses: statuses.length ? statuses : undefined,
            statusModifiedAtFrom: moment($scope.dates.statusModifiedAtFrom).format('DD-MM-YYYY'),
            statusModifiedAtTo: moment($scope.dates.statusModifiedAtTo).format('DD-MM-YYYY'),
        });

        fetchWorkOrders(params);
    };

    $scope.goToOrder = function(order) {
        $state.go('goods.order', { number: order.number, status: order.status });
    };

    $scope.onShopSearch = function(search) {
        if (search === shopsFilters.search || shopsFilters.allLoaded) return;

        shopsFilters.allLoaded = false;
        shopsFilters.search = search;
        fetchShops();
    }

    $scope.loadMoreShops = function() {
        ++shopsFilters.page
        fetchShops(true);
    };

    function fetchWorkOrders(params) {
        GoodsService.getWorkOrdersList(params).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки заказ-нарядов');
                return;
            }

            $scope.workOrders = result.data;
            $scope.totalOrders = parseInt(result.headers('x-total-count'));
        })
    }

    function fetchShops(isNextPage) {
        if (shopsFilters.allLoaded) return;
        if (!isNextPage) shopsFilters.page = 1;

        Dashboard3Service.getShops({ page: shopsFilters.page, perPage: shopsFilters.perPage, name: shopsFilters.search }, (err, result) => {
            if (err) {
                console.log('Error on getting shops');
                return;
            }

            $scope.shops = isNextPage ? $scope.shops.concat(result) : result;

            if (!result.length) shopsFilters.allLoaded = true;
        })
    }

    function init() {
        fetchShops();
        $scope.getFilteredWorkOrders();
    }

    init();
});

'use strict';

angular.module('smartApp')
    .factory('Auth', function Auth($rootScope, $q, Principal, AuthServerProvider, localStorageService) {
			let ssoConfig;
		var Auth = {
			login: function(credentials) {
				var _this = this;
				var deferred = $q.defer();

				AuthServerProvider.login(credentials)
					.then(function (data) {
						console.log("Principal.identity 1")
						Principal.identity(true).then(function (data2) {

							deferred.resolve([data, data2]);
						});
					}, function (e) {
						_this.logout();
						deferred.reject(e);
					});

				return deferred.promise;
			},

			logout: function() {
				console.log("logout in auth")
				AuthServerProvider.logout();
				Principal.authenticate(null);
			},

			authorize: function() {
				return Principal.identity()
					.then(function() {
						$rootScope.$broadcast("principalUpdated");
					});
			},

			passwordChange: function(newPassword, repeatNewPassword, email) {
				var _this = this;
				var deferred = $q.defer();

				AuthServerProvider.makeNewPassword(newPassword, repeatNewPassword, email)
					.then(function (data){
						if(data.data){
							console.log(data.data.token)
							localStorageService.set('token', data.data);
						}
						
						deferred.resolve(data)
					}, function (e) {						
						deferred.reject(e);
					});

				return deferred.promise;
			},
			passwordRestore: function(login) {
				var _this = this;
				var deferred = $q.defer();

				AuthServerProvider.passwordRestore(login)
					.then(function (data){
						deferred.resolve(data)
					}, function (e) {						
						deferred.reject(e);
					});

				return deferred.promise;
			},
			oidcURL(oidcConfig) {
				const url = new URL(oidcConfig.authorizationEndpoint);
		
				url.searchParams.set('client_id', oidcConfig.clientId);
				url.searchParams.set('scope', oidcConfig.scope);
				url.searchParams.set('redirect_uri', oidcConfig.redirectURI);
				url.searchParams.set('response_type', 'code');
				url.searchParams.set('response_mode', 'query');
				url.searchParams.set('prompt', 'login');

				return url.toString();
			},
			getLastOIDCRedirectTime() {
				return localStorageService.get('oidc:redirect_ts');
			},
			oidcRedirect(oidcConfig) {
				const url = this.oidcURL(oidcConfig);
				localStorageService.set('oidc:redirect_ts', Date.now());
				window.location.href = url.toString();
			},
			loginOIDC(code) {
				const deferred = $q.defer();
				AuthServerProvider.loginOIDC(code).then(authResult => {
					Principal.identity(true).then(accountResult => {
						if (!accountResult) {
							deferred.reject();
						} else {
							deferred.resolve([authResult, accountResult]);
						}
					});
				}).catch(err => {
					deferred.reject(err);
				});
				return deferred.promise;
			},
			loadSSOConfig() {
				if (ssoConfig) return $q.resolve(ssoConfig);
				return AuthServerProvider.getSSOConfiguration().then(result => {
					if (result.status === 200) {
						ssoConfig = result.data;
						return ssoConfig;
					}
				})
			},
		};

		//$rootScope.$on('logout', Auth.logout);

        return Auth;
    });

angular.module('smartApp').directive("intersectionObserver", function() {
    return {
		restrict: 'A',
		scope: {
			onIntersect: '&intersectionObserver',
			offset: '<',
		},
		link: function(scope, elm) {
            const raw = elm[0];
            const offset = scope.offset || 1;
            let intersected = false;
    
            elm.bind('scroll', function() {
                const isTargetPosition = raw.scrollTop + offset >= raw.scrollHeight - raw.clientHeight;

                if (!intersected && isTargetPosition) {
                    scope.onIntersect();
                    intersected = true;
                } else if (!isTargetPosition) {
                    intersected = false;
                }
            });
        },
	};
});

angular.module('smartApp').factory('transactionsReport', transactionsReportFactory);

transactionsReportFactory.$inject = ['ChecklistService', 'DownloadService'];

function transactionsReportFactory(ChecklistService, DownloadService) {
    function convertToApiParams(filters) {
        var apiParamNames = {
            cardOwnerId: 'buyerId',
            cardOwnerFullName: 'buyerName',
            storeNum: 'shopId',
            page: 'start',
        };
    
        return Object.keys(filters).reduce(function(params, key) {
            if (['dateFrom', 'dateTo'].includes(key)) {
                params[apiParamNames[key] || key] = moment(filters[key]).format('DD-MM-YYYY');
            } else if (key === 'page') {
                params[apiParamNames[key] || key] = filters[key] - 1;
            } else {
                params[apiParamNames[key] || key] = filters[key];
            }

            return params;
        }, {});
    }

	return {
		filtersTemplate: 'scripts/app/checklist/clreports/transactions/filters.html',
        viewTemplate: 'scripts/app/checklist/clreports/transactions/view.html',
		load: function(filters) {
			return ChecklistService.getTransactionsReport(convertToApiParams(filters));
		},
		download: function(filters) {
			return ChecklistService.getTransactionsReport(Object.assign({ xls: true }, convertToApiParams(filters))).then(result => {
                if (result.status !== 200) {
                    Notifications.danger('Не удалось загрузить файл');
                    return;
                }
                DownloadService.downloadFile(result.data, 'Card_transactions(' + result.config.data.dateFrom + '_' + result.config.data.dateTo + ')', '.csv');
                return result;
            });
		}
	};

}
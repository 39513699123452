angular.module('smartApp').controller('FilterControllerHR', FilterControllerHR);
FilterControllerHR.$inject = ['$scope', 'UserService', 'ChecklistReportService'];

function FilterControllerHR($scope, UserService, ChecklistReportService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);

}


/*
 onUpdateReportResult - функция, отвечающая за биндинг
 $scope.init - запрос к API и инициализация фильтров
 */
'use strict';

angular.module('smartApp').factory('FormApiService', function TaskServiceHttp($http, config, Upload) {

    return {
        getFormById: function (formId) {
            return $http.get(config.backendV2 + '/form/forms/' + formId);
        },
        updateForm: function (formId, params) {
            return $http.put(config.backendV2 + '/form/forms/' + formId, params);
        },
        getFormTemplates: function (params){
            return $http.get(config.backendV2 + '/form/templates/', params);
        }
    }
});
angular.module('smartApp').factory('AccreditationChecklistReport', accreditationCHecklistReportFactory);
accreditationCHecklistReportFactory.$inject = ['ChecklistService', 'DownloadService'];

function accreditationCHecklistReportFactory(ChecklistService, DownloadService) {
    return {
        id: 2,
        title: 'Фильтр 6',
        resultTitle: 'Результаты отчета',
        filtersTemplate: 'scripts/app/checklist/clreports/filter6/filters.html',
        viewTemplate: 'scripts/app/checklist/clreports/filter6/view.html',
        isValid: function(filters) {
            return filters.dateFrom && filters.dateTo
        },
        load: function(filters){
            return ChecklistService.getAccreditationChecklistReport(filters);
        },
        download: function(filters){
            return DownloadService.downloadAccreditationChecklistReport(filters);
        }
    };
}
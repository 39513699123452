angular.module('smartApp').factory('MyResponsibilityFilter', function MyResponsibilityFilter() {
    return function(divisions, clusters) {
        function filter(onlyMyResponsibility) {
            if (onlyMyResponsibility) {
                const resultDivisionIds = [];
                
                const resultDivisions = divisions.filter(division => {
                    if (division.myResponsibility) {
                        resultDivisionIds.push(division.id);
                        return true;
                    }
                    return false;						
                });
                const resultClusters = clusters ? clusters.filter(cluster => {
                    return resultDivisionIds.includes(cluster.divisionId);
                }) : [];

                return { divisions: resultDivisions, clusters: resultClusters };
            }
            
            return { divisions, clusters };
        }

        return filter;
    }
});
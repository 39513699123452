'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('account', {
                parent: 'site',
                url: '/account',
                data: {
                    roles: ['ROLE_DIRECTOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/account.html',
                        controller: 'AccountController'
                    }
                }
            });
    });
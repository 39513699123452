angular.module('smartApp').controller('FilterController3', FilterController3);
FilterController3.$inject = ['$scope', 'UserService', 'moment', '$rootScope'];

function FilterController3($scope, UserService, moment, $rootScope) {
	var FORMAT = 'DD.MM.YYYY';
	//$scope.users = [];
	


	$scope.sort = {
		asc: function (a, b) {
			return a.resolverFio.localeCompare(b.resolverFio);
		},

		desc: function (b, a) {
			return a.resolverFio.localeCompare(b.resolverFio);
		},

		state: 'asc'
	};
	
	function onUpdateReportResult(result) {
		if (result) {
			$scope.result = result.map(function(e){
				e.dates = e.checklists.reduce(function(prev, cl){
					var key = cl.date;
					prev[key] = {
						date: cl.date,
						status: cl.status,
						displayStatusName: cl.displayStatusName,
						id: cl.id,
						checklistTypeDisplay: cl.checklistTypeDisplay,
						checklistType: cl.checklistType
					};
					return prev;
				}, {});
				return e;
			});

			$scope.dates = getDates(result);

			$scope.result.sort(function (a, b) {
				return a.resolverFio.localeCompare(b.resolverFio);
			});

			//if (result.length === 0) {$rootScope.$broadcast('noDates');}
		}
	}

	$scope.sortByFio = function () {
		$scope.sort.state = $scope.sort.state === 'asc' ? 'desc' : 'asc';
		$scope.result.sort($scope.sort[$scope.sort.state]);
	};

	$scope.$watch('reportResult', onUpdateReportResult);

	function getDates(result){
		var oneDay = 24 * 60 * 60 * 1000; //24 hours in milliseconds
		var arr = [];
		var filters = $scope.reports.getFilters();
		var start = filters.rangeFrom;
		var end = filters.rangeTo;

		while(+start < +end+oneDay) {
			arr.push(moment(start).format(FORMAT));
			start = new Date(+start + oneDay);
		}

		arr.sort(function(e1, e2){
			var ta = moment(e1, FORMAT);
			var tb = moment(e2, FORMAT);
			if(ta.isAfter(tb))
				return 1;
			else if(ta.isBefore(tb))
				return -1;
			else return 0;
		});
		return arr;
	}
}

(function() {
    'use strict';

    angular.module('smartApp').directive('numberInput', numberInput);

    function numberInput() {
        var directive = {
            templateUrl: 'scripts/components/controls/number-input/number-input.view.html',
            link: link,
            restrict: 'EA',
            require:  '?ngModel',
            scope: {
                max: '=',
                min: '=',
                typeStyle: '@'
            }
        };
        return directive;

        function link(scope, element, attrs, ngModel) {

            scope.isRequired;

            scope.$watch(
                function() {
                    return scope.val;
                },
                function() {
                    if (scope.isRequired) {
                        if (!_.isNil(scope.val)) {
                            ngModel.$setValidity('required', true);
                        } else {
                            ngModel.$setValidity('required', false);
                        }
                    }
                    ngModel.$setViewValue(scope.val);
                }
            );

            init();

            function init() {
                ngModel.$render = function() {
                    scope.val = ngModel.$viewValue;
                    if (scope.min > scope.val) {
                        scope.val = scope.min;
                    }
                    if (scope.max < scope.val) {
                        scope.val = scope.max;
                    }
                };
                scope.isRequired = !!_.find(attrs, [
                    'name',
                    'required'
                ]);
            }

            scope.increase = function() {
                if (_.isNil(scope.max) || +scope.max > scope.val) {
                    scope.val++;
                }
            };

            scope.decrease = function() {
                if (_.isNil(scope.min) || scope.min < scope.val) {
                    scope.val--;
                }
            };
        }
    }
})();

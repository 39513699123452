angular.module('smartApp')
	.controller('dashboardDragCtl', ['$scope', function ($scope) {

	}])
	.directive('dashboardDrag', ['$compile', function($compile) {
		return {
			restrict: 'A',
			controller: 'dashboardDragCtl',
			scope: {shops:'=ngShops', curdate:'=ngCurdate',  selectedTemplate:'=ngSelectedTemplate', curShops:'=ngCurShops', getDragData:"=ngDragData", deleteAssigning:"=deleteItem"},
			link: function(scope, elm, attrs, controller) {

				function initDrag(e) {
					e = $(e);

					var $span = e.children('span');
					var eventObject = {
						title: $.trim(e.children().find("name").text()),
						id: $.trim($span.data('id')),
						description: $.trim($span.data('description')),
						icon: $.trim($span.data('icon')),
						className: $.trim($span.attr('class')),
						durationEditable: false
					};

					e.data('eventObject', eventObject);

					e.draggable({
						zIndex: 999,
						revert: true,
						revertDuration: 0
					});
				};

				function init (arg) {
					var dragData = scope[attrs['ngDragData']]();
					var selectedTemplate = scope[attrs["ngSelectedTemplate"]];
					
					if(dragData) {
						elm.html();
						var cb = '';
						angular.forEach(dragData, function(data) {
							var limitsHtml = '';
							var bgClass='blue';

							// ТОЛЬКО ПЕРЕКРЕСТОК: Если не находим лимит - отображаем дефолт (сегодняшний месяц)
							if(selectedTemplate && data.limits && data.limits.length != 0){
								var limits = (function(data, selectedTemplateId){
									var returnValue;
									if(isInt(arg)) {
										returnValue = findLimitByMonth(data, selectedTemplateId, (arg + 1));
									} else {
										returnValue = findLimitByMonth(data, selectedTemplateId, new Date().getMonth() + 1);
									}
									return returnValue;
								})(data, selectedTemplate.id);
								bgClass='yellow';
								if(limits) {
									if(limits.count == 0)bgClass='red';
									if(limits.count >= limits.limit)bgClass='green';
									if(limits.limit == 0)bgClass='blue';
									limitsHtml = '<limits><done>'+limits.count+'</done>/<from>'+limits.limit+'</from></limits>';
								}
							}

							
							cb += '<li><span id="drop-remove" class="store bg-color-'+bgClass+' txt-color-white"' +
									' data-icon="fa-pie" data-id="' + data.id + '">'+((limitsHtml)?limitsHtml:'')+'<name>' + data.name +
									'</name><i data-ng-click="onDelete(' + data.id + ')" class="pull-right fa fa-times" style="cursor: pointer"></i></span></li>';
							
						});

						elm.html($compile(cb)(scope));
					}

					elm.find('li').each(function() {
						$(this).draggable();
						initDrag(this);
					});
				}

				function findLimitByMonth(data, templateId, month) {
					for(var i in data.limits){
						if(data.limits[i].templateId == templateId &&
							data.limits[i].month == month) {
							return data.limits[i];
						}
					}
				}

				function isInt(value) {
				  var x;
				  if (isNaN(value)) {
				    return false;
				  }
				  x = parseFloat(value);
				  return (x | 0) === x;
				}

				scope.onDelete = function (data) {
					scope[attrs.deleteItem](data);
				};

				scope.$on("deleteSucceed", function(event, arg) { init(arg); })
				scope.$on("monthChanged", function(event, arg) { init(arg); })
				scope.$on("saveSuccess", function(event, arg) { init(arg); })

				scope.$watch(attrs.ngRefresh, init)
				scope.$watch(attrs.ngSelectedTemplate, init);
				scope.$watch(attrs.ngShops, init);
				scope.$watch(attrs.ngCurShops, init);
				scope.$watch(attrs.ngCurdate, function(newValue, oldValue) {
                	init()
           		}, true);
			}
		};
	}]);

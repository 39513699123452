'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('start', {
                url: '/start',
                data: {
                    roles: ['ROLE_ADMIN'],
                    css: ['css/states/guide.css'],
                    breadcrumbs: [['plane', 'Старт']]
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/empty.html',
                        controller: function($http, $scope, ngDialog, DownloadService, Notifications, Principal){
                            var items = []
                           console.log(Principal.getIdentity())

                            $http.get('scripts/guide.json').
                              success(function(data, status) {
                                items = data
                                $scope.guideItems = items.map(el => {
                                    return {
                                        name: el[0],
                                        img: el[1],
                                        state: el[2],
                                        buttonTxt: el[3],
                                        description: el[4]
                                    }
                                })
                              }).error(function(data) {
                                Notifications.danger('Ошибка чтения данных из файла guide.json')
                            });

                            


                            //console.log("$scope.guideItems", $scope.guideItems)

                            $scope.showPopup = function(item){
                                if(!item.name || item.name == '')return;
                              
                                var _scope = $scope;
                                ngDialog.open({
                                    template:
                                        'popup',
                                    className: 'ngdialog-theme-plain',
                                    controller: [
                                        '$scope', '$state',
                                        function($scope, $state) {
                                            $scope.header = item.name;
                                            $scope.content = item.description;
                                            $scope.button = {
                                                state: item.state,
                                                text: item.buttonTxt
                                            }

                                            $scope.go = function(state){
                                                $state.go(state)
                                                ngDialog.close()
                                            }
                                            
                                        }
                                    ],
                                    showClose: false
                                });
                            }
                        }
                    }
                },
                parent: 'site'
            });
    });
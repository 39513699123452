(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('TasksViewService', TasksViewService);

    TasksViewService.$inject = ['Principal'];

    const showOnlyResultTaskTypes = ['UNSOLD_GOODS', 'METER', 'EQUIPMENT', 'COLLECTION_DELIVERY', 'COLLECTION_PICKUP', 'APPROVAL_DELIVERY', 'APPROVAL_PICKUP', 'DISBANDMENT', 'EXPIRATION_DATE_ADD', 'EXPIRATION_DATE_CHECK'];
    const showOnlyResultTaskTypesExt = showOnlyResultTaskTypes.concat(['PRINT_PRICE_TAGS']);
    const showOnlyResultTaskTypesExt2 = showOnlyResultTaskTypesExt.concat(['MANUAL_RECOUNT', 'AUTO_RECOUNT', 'INVENTORY_PREPARATION']);

    function TasksViewService(Principal) {
        return {
            getViewProperty: getViewProperty,
            getAttachmentTypeForPreview: getAttachmentTypeForPreview
        };

        /**
         * @param {object} task - data for task
         * @param {object} userPermissions - permissions for logged-in user
         * @returns {object} - property for task template
         */
        function getViewProperty(task, settings) {
            return {
                userRole: task.userRole,
                taskName: task.name,
                startBtn: {
                    isShow: setDisplaingStartBtn(task),
                    isDisabled: setDisabledStartBtn(task)
                },
                completeBtn: {
                    isShow: setDisplaingCompleteBtn(task),
                    isDisabled: setDisabledCompleteBtn(task)
                },
                checkPhotoReportBtn: {
                  isShow: showPhotoReportAttachments(task)
                },
                responseAttachments: {
                    isShow: (function(task){
                        return !task.task && (task.userRole === 'EXECUTOR' || task.userRole === 'AUTHOR');
                    })(task)
                },
                deleteResponseAttachment: {
                  isShow: (function(task) {
                    return isUnclosedResponseExecutor(task) && task.taskType !== 'UNSOLD_GOODS';
                  })(task)
                },
                addExecutorAttachmentsBtn: {
                    isShow: (function(task){
                      return isUnclosedResponseExecutor(task) && !showOnlyResultTaskTypesExt.includes(task.taskType);
                    })(task),
                    isDisabled:  (function(task){
                        return !task.task && task.responseData.status !== 'IN_PROGRESS' && task.responseData.status !== 'OPEN';
                    })(task)
                },
                downloadBtn: {
                    isShow: setDisplaingDownloadBtn(task, settings.permissions),
                },
                openBtn: {
                    isShow: setDisplaingOpenBtn(task, settings),
                    isDisabled: (function(task) {
                        return !task.task && task.responseData.status === 'OPEN';
                    })(task),
                },
                deleteBtn: {
                    isShow: setDisplaingDeleteBtn(task, settings.permissions),
                },
                writeComment:{
                    isShow: (function(task){
                        return isUnclosedResponseExecutor(task) && !showOnlyResultTaskTypesExt.includes(task.taskType);
                    })(task),
                    isDisabled: (function(task){
                        return false;
                    })(task)
                },
                readComment:{
                    isShow: (function(task){
                        return task.task && task.totalCount == 1 && task.userRole === 'AUTHOR' ||
                            !task.task && (task.userRole === 'AUTHOR' || task.userRole === 'EXECUTOR' && task.responseData.status == 'CLOSED');
                    })(task)
                },
                openAttachmentsBtn:{
                    isShow: (function(task){
                        return task.responseInTask && (task.userRole === 'EXECUTOR' || task.userRole === 'OBSERVER');
                    })(task)
                },
                returnBtn:{
                    isShow: (function(task){
                        return !showOnlyResultTaskTypes.includes(task.taskType) && task.responseInTask && task.userRole === 'AUTHOR';
                    })(task),
                    isDisabled: (function(task){
                        return task.responseInTask && task.userRole === 'AUTHOR' && task.responseData.status !== 'CLOSED';
                    })(task)
                },
                recountBtn: {
                    isShow: ['MANUAL_RECOUNT', 'AUTO_RECOUNT'].includes(task.taskType),
                },
                overdueTaskResponses: {
                    isShow: (function(task){
                        return task.userRole !== 'EXECUTOR' && !task.response && task.overdue && task.deadlineByLocalTime && task.totalCount > 1;
                    })(task)
                },
                equipmentBtn: {
                    isShow: task.taskType === 'EQUIPMENT' && task.status === 'CLOSED',
                },
                crmBtn: {
                    isShow: !task.task && task.status === 'CLOSED' && ['COLLECTION_DELIVERY', 'COLLECTION_PICKUP', 'APPROVAL_DELIVERY', 'APPROVAL_PICKUP'].includes(task.taskType),
                },
            };
        }

        function getAttachmentTypeForPreview(type){
            if(type.substr(0,5) == "image")return "image";
            if(type == "text/html")return "code";
            if(type == "application/zip" || type == "application/x-rar")return "zip";
            if(type == "application/pdf")return "pdf";
            if(type.substr(0,5) == "video")return "video";
            if(type.substr(0,5) == "audio")return "audio";
            if(type == "application/vnd.ms-excel")return "excel";
            if(type == "application/msword")return "word";
            return "file"
        }

        function showPhotoReportAttachments(task) {
            // для админа в разделе фотоотчеты всегда показываем кнопку
          return (Principal.isInRole('ROLE_ADMIN') && task.taskType === 'PHOTO_REPORT') || task.task && task.userRole === 'AUTHOR' && task.taskType === 'PHOTO_REPORT';
        }

        function setDisplaingOpenBtn(task){
            return !['FORMS'].includes(task.taskType) && task.totalCount > 0 && task.task;
        }

        function setDisplaingDownloadBtn(task){
            return task.task && (task.userRole === 'AUTHOR' || task.userRole === 'OBSERVER') && !task.periodic;
        }

        function setDisplaingDeleteBtn(task)  {
            return task.task && task.userRole === 'AUTHOR' && !task.periodic && task.taskType !== 'ROUTED' && !task.parentTaskId && task.taskType !== 'PRINT_PRICE_TAGS';
        }

        //verified

        function setDisplaingStartBtn(task) {
            return !showOnlyResultTaskTypesExt2.includes(task.taskType) && isUnclosedResponseExecutor(task);
        }

        function setDisabledStartBtn(task) {
            return !task.task && (task.responseData.status === 'IN_PROGRESS' || task.responseData.status === 'CLOSED')// TODO: || !!task.childTaskId;
        }

        function setDisplaingCompleteBtn(task) {
            return (task.task && task.userRole === 'AUTHOR' && task.periodic
             || isUnclosedResponseExecutor(task)) && !showOnlyResultTaskTypesExt2.includes(task.taskType);
        }

        function setDisabledCompleteBtn(task) {
            return !task.task && task.responseData.status === 'CLOSED' ||
                task.task && task.status === 'CLOSED' ||  !task.task && task.responseData.executorsCommentRequired && (!task.responseData.comment || task.responseData.comment.length == 0);
            // || (taskResponse.EXECUTORsCommentRequired && !taskResponse.comment) || (task.attachmentsCount < taskResponse.minAttachmentsCount);
        }

        function isUnclosedResponseExecutor(task) {
            return !task.task && task.userRole === 'EXECUTOR' && task.responseData.status !== 'CLOSED';
        }
    }
})();

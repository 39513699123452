angular.module('smartApp').directive('dropdownFilter', ['$window', function($window) {
  return {
    link: function(scope, element) {
      var visible = false;
      var el = element[0];
      var name = el.getElementsByTagName('name')[0];

      var handleWinClick = function(e) {
        var parent = e.target;
        var canHide = true;

        do {
          if (parent === el) {
            canHide = false;
            break;
          }
        } while(parent = parent.parentElement);

        if (canHide) {
          visible = false;
          el.classList.remove('visible');
        }
      }

      $window.addEventListener('click', handleWinClick);

      name.addEventListener('click', function() {
        visible = !visible;
        el.classList.toggle('visible', visible);
      });

      scope.$on('$destroy', function() {
        $window.removeEventListener('click', handleWinClick);
      });
    }
  };
}]);

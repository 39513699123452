angular.module('smartApp').factory('SystemParamsService', function UserService($http, config) {
	var getParams = function(){
		return $http.get(config.backend + "/admin/systemProperties");
	}

	var systemParams = {};
	var lastTime = undefined;


	return {
		getSystemProps: function(params, cb){
			var systemProps = {};

			for(var i in params){
				systemProps[params[i]] = undefined;
			}

			if(systemParams.length && lastTime && Date().getTime() - lastTime < 5000){
				cb(null, systemParams);
				return;
			}

			getParams().success(function (result) {
					if(!result){
						cb(500)
						return
					}
		    	if(result.status && result.status != 200){
		    		cb(result.status)
		    		return
		    	}

		    	if(!params){
		    		cb(null, result)
		    		return;
		    	}

		    	for(var i in result){
		    		for(var j in systemProps){
		    			if(j == result[i].propertyKey) systemProps[j] = result[i].value;
		    		}
		    	}


		    	//отладочный костыль. Выпилить
		    	if("checklist.daysForAction" in systemProps && !systemProps["checklist.daysForAction"])systemProps["checklist.daysForAction"] = 7

		    	cb(null, systemProps);
		    	lastTime = new Date().getTime();
		    });
        },

		set: function(data, params){
			return $http.post(config.backend + '/admin/systemProperties', data, Object.assign({ timeout: 600000 }, params));
		},
		use: function(){
			$http.get(config.backend + "/admin/systemProperties/reload");
		}
	}

})

'use strict';

angular.module('smartApp').factory('ChecklistEditor', function ChecklistEditor($http, TemplateService, config, localStorageService) {

	var _checklist;
	var _qaAnswers;
	var _newChanges = false;

	return {
		//TODO: use Moment JS
		updateChecklist: function(checklist) {
			checklist.resolveDate.setMinutes(checklist.resolveDate.getMinutes() - checklist.resolveDate.getTimezoneOffset());
			return $http.post(config.backend + '/checklist/updateChecklist', checklist);
		},

		createChecklists: function(checklists) {
			angular.forEach(checklists, function(cl, clKey) {
				cl.resolveDate.setMinutes(cl.resolveDate.getMinutes() - cl.resolveDate.getTimezoneOffset());
			});
			return $http.post(config.backend + '/checklist/create', checklists);
		},

		loadChecklist: function(checklist) {
			_checklist = checklist;
			localStorageService.set("activeChecklist", checklist);
			return $http.post(config.backend + '/checklist/updateChecklist', _checklist);
		},

		getChecklist: function() {
			return _checklist;
		},

		getLimits: function(templateId, shopId, monthYear, cb){
			$http.get(config.backend + '/countCheck/template/'+templateId+'/shop/'+ shopId +'/month/'+ monthYear).success(function(data, status) {
				cb((status == 200)?null:status, data)
			});
		},

        saveAllQuestions: function(checklistId, params, questions){
            return $http.put(config.backend + '/checklists/' + checklistId + '/answers/all', questions, {params: params});
		},

		saveQuestion: function(checklistId, params, question){
            return $http.put(config.backend + '/checklists/' + checklistId + '/answers/one', question, {params: params, noThrobber: true});
		},

        saveCommentChecklist: function(checklistId, comment){
            return $http.put(config.backend + '/checklist/' + checklistId + '/comment', comment, {noThrobber: true});
        },

		loadQuestionAnswers: function(checklistId) {
			return $http.get(config.backend + '/checklist/getAnswersByChecklistId?checklistId=' + checklistId)
				.success(function(data) {
					_newChanges = false;
					return data;
				});
		},

		saveAnswers: function(answers) {
			return $http.post(config.backend + '/checklist/updateChecklistWithAnswers', JSON.stringify(answers));
		},

		getQuestionAnswers: function() {
			return _qaAnswers;
		},

		isChanged: function() {
			return _newChanges;
		},

		setChanged: function(flag) {
			_newChanges = flag;
		},

		getResolvers: function(searchResolver){
            return $http.get(config.backend + '/checklist/resolvers', searchResolver);
		},

		deleteChecklist: function(checklistId){
            return $http.delete(config.backend +'/checklist/'+checklistId);
		}
	}

});
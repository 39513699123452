angular.module('smartApp').factory('Xfilter1', checkXreportFactory1);
checkXreportFactory1.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory1(ChecklistService, DownloadService) {
	return {
		id: 7,
		title: 'X Фильтр 1',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter1/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter1/view.html',
		isValid: function(filters) {
			return filters.month && filters.divisionIds && filters.divisionIds.length > 0;
		},
		load: function(filters) {
			var t = ChecklistService.getXreport1(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport1(filters);
		}
	};

}
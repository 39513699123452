var PanelUsersController;

PanelUsersController = function (
    $scope,
    $rootScope,
    $location,
    $anchorScroll,
    $q,
    $filter,
    PanelUsersService,
    PanelGroupsService,
    FormFactory,
    Notifications,
    ParseLink,
    Errors,
    ngDialog,
    localStorageService,
    PanelRolesService,
    DownloadService,
    PanelDivisionsService,
    Roles,
    OrgstructService,
    UsersPageService
	) {
	$scope.userService = PanelUsersService;
    $scope.orgstructService = OrgstructService;
	$scope.emailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	$scope.whithoutWhitespaces = /^[\S]+$/;
	$scope.isValidPanel = true;
	$scope.isSelected = false;
	$scope.isLoading = false;
	$scope.selected_users = [];
	$scope.selectedRow = null;
	$scope.login_to_search = null;
	$scope.changePasswordView = true;
    $scope.LDAPswitchPasswordReqiured = false;
    $scope.usersSort = {sort: 'login', direction: 'desc'};
    $scope.showFilters = true;

    $scope.authTypes = [
        {name: "Локальная", value: "LOCAL"},
        {name: "Доменная", value: "LDAP"}
    ];

    $scope.isShopDir = { value: false };
    $scope.isInspector = { value: false };
    $scope.isRegManager = { value: false };
    $scope.isGenerationRuleEditor = { value: false };

	// $scope.navbar_names = ['Информация', 'Группы', 'Роли', 'Заместитель'];
	$scope.data = [];

	$scope.allDivisions = [];
    $scope.allGroups = [];
    $scope.allReports = [];
    $scope.allRegions = [];
    $scope.shopsWithoutDir = [];
    $scope.shopsWithoutDirPlusCurrent = [];
    $scope.shops = [];
    $scope.selectedUserShops = null;
    $scope.savedUserShopId = null;
    $scope.levels = [];
    $scope.businessLines = [];

    $scope.userDeviceInfo = {
      loadStatus: '',
      platform: null,
      appVersion: null,
      deviceUid: null,
      deviceModel: null
    };

	$scope.roleList = [];
	$scope.filters = {};
    $scope.requiredError = {};
	$scope.reverse = false;

    $scope.requestReportRoles =[];
    $scope.addNamesReports = [];
    $scope.deleteNamesReports = [];
    $scope.checkDirSelecting = checkDirSelecting;

	$scope.tableSettings = {
		page: 1,
        limit: 20
    };

    $scope.canMakeChecking = false;
    $scope.changeChecking = changeChecking;
    $scope.changeUserRole = changeUserRole;
    $scope.changeBusDir = changeBusDir;

    if (!$scope.links){
        $scope.links = angular.copy($scope.tableSettings);
    }

    var _scope = $scope;
    var errorMessage;



	$scope.download = function() {
        $scope.doSearch(true)
	};

	$scope.onChange = function (value) {
        $scope.requiredError = {};

		if (!$scope.user) return;

	    $scope.setInspectorSettings(value, $scope.user);

        $scope.user.changed = true;
	};

    $scope.onAuthTypeChange = function (value) {
        $scope.isLDAP = value === 'LDAP';
        $scope.changePasswordView = false;
        $scope.LDAPswitchPasswordReqiured = true;

        $scope.user.changed = true;
    };

	$scope.onChangeIntegrity = function () {
        $scope.requiredError = {};
    };

    $scope.onChangeGroups = function () {
        if ($scope.user) {
            $scope.user.changedGroups = true;
        }
    };

    $scope.setInspectorSettings = function(value, user) {
        if (value !== 'bn' || !user.businessLine || user.businessLine.id !== 1006) return;

        $scope.isInspector.value = true;
        changeBusDir(user, true)
    };

	$scope.createForm = function (form) {
		return new FormFactory(form);
	};

	$scope.errorValidation = function () {
        Notifications.danger(errorMessage || 'Не все поля заполнены верно', '', 3000);
        errorMessage = '';
	};


    $scope.setUsersSort = function (sort) {
        $scope.reverse = ($scope.usersSort.sort === sort) ? !$scope.reverse : false;
        $scope.usersSort.direction = ($scope.usersSort.sort === sort && $scope.usersSort.direction === 'desc') ? 'asc' : 'desc';
        $scope.usersSort.sort = sort;
        $scope.doSearch();
    };

	$scope.getPage = function (page, clear) {
    $scope.links.page = page;
    if (clear) {
      $scope.filters = {};
      $scope.links = angular.copy($scope.tableSettings);
    }
    $scope.addNamesReports = [];
    $scope.deleteNamesReports = [];
		$scope.doSearch(null, true);
	};

	$scope.goToUserCreation = function () {
        $scope.requiredError = {};
		$scope.user = undefined;
        $scope.selectedRow = null;
		$scope.newUser = newUser();
		$scope.isInspector.value = false;
        $scope.isRegManager.value = false;
        $scope.isGenerationRuleEditor.value = false;

		setTimeout(function(){
			$location.hash('newUserForm');
			$anchorScroll();
		}, 0)

	};

    $scope.doSearch = function(excel, openUser){
        var requestObjSearch = {};
        if ($scope.filters.active !== undefined) requestObjSearch.active = $scope.filters.active;
        if ($scope.filters.login) requestObjSearch.loginLike = $scope.filters.login;
        if ($scope.filters.email) requestObjSearch.emailLike = $scope.filters.email;
        if ($scope.filters.firstName) requestObjSearch.firstNameLike = $scope.filters.firstName;
        if ($scope.filters.lastName) requestObjSearch.lastNameLike = $scope.filters.lastName;
        if ($scope.filters.position) requestObjSearch.positionLike = $scope.filters.position;
        if ($scope.filters.businessLine) requestObjSearch.businessDirId = $scope.filters.businessLine.id;
        if ($scope.filters.noGroups !== undefined) requestObjSearch.hasGroup = !$scope.filters.noGroups;
        if ($scope.filters.level) requestObjSearch.levels = $scope.filters.level;

        requestObjSearch.page = $scope.links.page - 1;
        if ($scope.links.limit) requestObjSearch.size = $scope.links.limit;
        $scope.getData(requestObjSearch, excel, openUser);

    }

	$scope.changePassword = function () {
		return $scope.changePasswordView = !$scope.changePasswordView;
    };

    $scope.getBusDir = function(name) {
        return _.find($scope.businessLines, function(businessLine) {
            return businessLine.name === name;
        });
    };

  $scope.getUserDeviceInfo = function() {
    if ($scope.userDeviceInfo.loadStatus === 'loaded' || $scope.userDeviceInfo.loadStatus === 'loading') {
      return;
    }

    $scope.userDeviceInfo.loadStatus = 'loading';

    PanelUsersService.getUserStats([$scope.user.id]).then(function(result) {
      if (result.status < 200 && result.status > 299) {
        Notifications.danger('Ошибка загрузки информации о девайсе пользователя!');
        $scope.userDeviceInfo.loadStatus = '';
        return;
      }

      var deviceInfo = result.data[0];

      $scope.userDeviceInfo.platform = deviceInfo.devicePlatform;
      $scope.userDeviceInfo.appVersion = deviceInfo.appVersion;
      $scope.userDeviceInfo.deviceUid = deviceInfo.deviceUid;
      $scope.userDeviceInfo.deviceModel = deviceInfo.deviceModel;
      $scope.userDeviceInfo.loadStatus = 'loaded';
    });
  };

	function getIds(arrayElements){
        return arrayElements.map(function(elem) {
            return elem.id;
        });
	}

    function getNames(arrayElements){
        return arrayElements.map(function(elem) {
            return elem.name;
        });
    }

	$scope.createUser = function () {
        $scope.requiredError = {};
		var requestObj;
		var requestGroup = [];
		if (userIsValid($scope.newUser)) {
		    if(!$scope.isValidPanel) return;
			requestObj = {
                login: $scope.newUser.login,
                authType: $scope.newUser.authType,
                level: $scope.newUser.level,
                active: $scope.newUser.active,
                supervisor: $scope.newUser.supervisor,
                taskManager: $scope.newUser.taskManager,
                userChecklistsOrganizer: $scope.newUser.userChecklistsOrganizer,
                shopDirector: $scope.newUser.shopDirector,
                shopIds: ($scope.newUser.shops)?[$scope.newUser.shops]:[],
                inspector: $scope.isInspector.value,
                regManager: $scope.isRegManager.value,
                generationRuleEditor: $scope.isGenerationRuleEditor.value,
			};

			if ($scope.newUser.email) requestObj.email = $scope.newUser.email;
            if ($scope.newUser.firstName) requestObj.firstName = $scope.newUser.firstName;
            if ($scope.newUser.lastName) requestObj.lastName = $scope.newUser.lastName;
            if ($scope.newUser.position) requestObj.position = $scope.newUser.position;
            if ($scope.newUser.password) requestObj.password = $scope.newUser.password;
            if ($scope.newUser.businessLine) requestObj.businessDirId = $scope.newUser.businessLine.id;
            if ($scope.newUser.divisions && $scope.newUser.divisions.length) {
                requestObj.divisionIds = $scope.newUser.divisions;
			}
            if ($scope.newUser.regions && $scope.newUser.regions.length) {
                requestObj.regionIds = $scope.newUser.regions;
            }

            if ($scope.newUser.shops) {
                requestObj.shopIds = ($scope.newUser.shops.id)?[$scope.newUser.shops.id]:$scope.newUser.shops
            }

			OrgstructService.createUser(requestObj).then(function (response) {
                if(response.status === 200 || response.status === 201) {
                    Notifications.success('Пользователь успешно добавлен');
                    if ($scope.newUser.groups && $scope.newUser.groups.length) {
                        requestGroup = $scope.newUser.groups;
                        OrgstructService.addUserGroups(response.data.id, requestGroup).then(function (response) {
                            if (!(response.status === 200 || response.status === 201)) {
                                Notifications.danger('Ошибка сервера! Невозможно назначить пользователю группы');
                            }
                        }, function () {
                            Notifications.danger('Ошибка сервера! Невозможно назначить пользователю группы');
                        });
                    }

                    if ($scope.newUser.reports && $scope.newUser.reports.length) {
                        $scope.requestReportRoles = $scope.requestReportRoles.concat(getNames($scope.newUser.reports));
                        OrgstructService.addUserReportRoles(response.data.id, $scope.requestReportRoles).then(function (response) {
                            if (!(response.status === 200 || response.status === 201)) {
                                Notifications.danger('Ошибка! Невозможно назначить пользователю отчеты');
                            }
                        }, function () {
                            Notifications.danger('Ошибка! Невозможно назначить пользователю отчеты');
                        });
                    }

                    $scope.filters.login = $scope.newUser.login;
                    $scope.doSearch(false, true);
                    delete $scope.newUser;
                }else if (response.status === 500 && response.headers('x-error') === 'UserAlreadyExistsException'){
                    Notifications.danger('Ошибка! Такой пользователь уже существует');
                }else if(response.status === 403){
                    Notifications.danger('Ошибка! Нет прав на создание пользователя');
                }else{
                    Notifications.danger('Ошибка! Невозможно добавить пользователя');
                }
			}, function() {
                Notifications.danger('Ошибка! Невозможно добавить пользователя');
            });
		} else {
			// $rootScope.$broadcast('formNotFilled');
			return false;
		}
	};

    function addUserInReport(addNamesReports){
        OrgstructService.addUserInReport($scope.selectedRow, addNamesReports).then(function(result) {
            if(result.status === 200){
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.addNamesReports = [];
                $scope.getData();
                //$scope.editUser($scope.selectedRow);
            }else if(result.status === 404){
                Notifications.danger('Ошибка!', 'Не найден отчет', 2000);
            }else{
                Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            $log.log(error);
        });
    }

    function deleteUserFromReport(deleteNamesReports){
        OrgstructService.deleteUserFromReport($scope.selectedRow, deleteNamesReports).then(function(result) {
            if(result.status === 200){
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.deleteNamesReports = [];
                $scope.getData();
                //$scope.editUser($scope.selectedRow);
            }else if(result.status === 404){
                Notifications.danger('Ошибка!', 'Не найден отчет', 2000);
            }else{
                Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            $log.log(error);
        });
    }

	$scope.saveChangedReports = function(){
        var checked = $scope.allUserReports.filter((el) => el.active);

        var toAdd = checked.filter((el) => {
             return !!!$scope.user.originReports.filter((filter) => {
                return filter.name == el.name
             }).length
        }).map((el) => el.name);

        var toRemove = $scope.user.originReports.filter((el) => {
             return !!!checked.filter((filter) => {
                return filter.name == el.name
             }).length
        }).map((el) => el.name);


        async.parallel([
            function(callback){
                if(!toRemove.length){
                    callback(false);
                    return;
                }
                OrgstructService.deleteUserFromReport($scope.selectedRow, toRemove).then(function(result) {
                    if(result.status === 200){
                        callback(false)
                    }else{
                        callback(result.status, result.headers)
                    }
                })
            },
            function(callback){
                if(!toAdd.length){
                    callback(false);
                    return;
                }
                OrgstructService.addUserInReport($scope.selectedRow, toAdd).then(function(result) {
                    if(result.status === 200){
                        callback(false)
                    }else{
                        callback(result.status, result.headers)
                    }
                })
            }
        ],
        function(err, results){
            if(err){
                console.log(err, results);
                Notifications.danger("Ошибка: "+err +results);
                return;
            }

            getUserReports($scope.user.id);
            Notifications.success("Права доступа к отчётам успешно обновлены");
        });
	};


    $scope.editUser = function (id, hasNoThrobber) {
        var userParams = { fields: 'businessDir,roles,authType' };

        var userRoles = {
            supervisor: 'ROLE_SUPERVISOR',
            taskManager: 'ROLE_TASK_MANAGER',
            userChecklistsOrganizer: 'ROLE_USER_CHECKLISTS_ORGANIZER'
        };

        $scope.userDeviceInfo.loadStatus = '';
        $scope.allUserReports = [];
        $scope.selectedRow = id;
        $scope.newUser = undefined;
        $scope.user = undefined;
        $scope.isShopDir.value = false;
        $scope.changePasswordView = true;
        $scope.LDAPswitchPasswordReqiured = false;

        $scope.selectedUserShops = {
            name: '',
            id: null
        };

        OrgstructService
            .getUser(id, userParams, hasNoThrobber)
            .then(function(resultUser) {
                if(resultUser.status === 200){
                    if (resultUser.data.id) {
                        $scope.user = angular.copy(resultUser.data);
                        if (!resultUser.data.authType) {
                            $scope.user.authType = 'LOCAL';
                            $scope.onChange();
                        } else {
                            $scope.user.authType = resultUser.data.authType;
                        }

                        if (resultUser.data.businessDir) {
                            $scope.user.businessLine = resultUser.data.businessDir;
                        }

                        $scope.isShopDir.value = _.includes($scope.user.roles, 'ROLE_SHOPDIR');
                        $scope.isRegManager.value = _.includes($scope.user.roles, 'ROLE_REG_MANAGER');
                        $scope.user.shopDirector = $scope.isShopDir.value;
                        $scope.isInspector.value = $scope.user.businessDir.id === 1006; //Ревизор
                        $scope.isGenerationRuleEditor.value = _.includes($scope.user.roles, 'TASK_GENERATION_RULE_EDITOR');

                        getUserGroups(resultUser.data.id, hasNoThrobber);
                        getUserReports(resultUser.data.id, hasNoThrobber);
                        $scope.isLDAP = $scope.user.authType === 'LDAP';

                        if (resultUser.data.roles.length) {
                            _.forIn(userRoles, function(role, field) {
                                $scope.user[field] = _.includes(resultUser.data.roles, role);
                            });
                        }

                        if (!resultUser.data.level) return;

                        $scope.user.level = resultUser.data.level;

                        if (resultUser.data.level === 'SHOP') {
                            getAllShops(resultUser.data)
                        }

                        if (resultUser.data.level === 'REGION') {
                            getUserRegions(resultUser.data)
                        }

                        if (resultUser.data.level === 'DIVISION' || $scope.isRegManager.value) {
                            getUserDivisions(resultUser.data)
                        }
                    }
                } else {
                    Notifications.danger('Ошибка! Не удается загрузить данные', '', 2000);
                }
            }, function() {
                Notifications.danger('Ошибка! Не удается загрузить данные', '', 2000);
            });
    };

    function getAllShops(user){
      var requests = [];

      if (!$scope.shopsWithoutDir || !$scope.shopsWithoutDir.length) {
        requests.push(function(callback) {
          getShops(true, callback);
        });
      }

      if (!$scope.user.linkedShops || !$scope.user.linkedShops.length) {
        requests.push(function(callback) {
          getUserShops(user, true, callback);
        });
      }

      if (!requests.length) {
        return;
      }

      async.series(requests, function(err){
          if(err){
              console.log(err, results);
              Notifications.danger("Ошибка: "+err +results)
              return;
          }


          $scope.shopsWithoutDirPlusCurrent = angular.copy($scope.shopsWithoutDir)
          $scope.shopsWithoutDirPlusCurrent = $scope.shopsWithoutDirPlusCurrent.concat($scope.user.linkedShops)
      });
    }

    function getUserShops(userData, hasNoThrobber, cb){
        if(!userData.id){cb();return;}
        var typeControl;

        typeControl = userData.level.toLowerCase() + 's';
        return OrgstructService
            .getUserControls(userData.id, typeControl, hasNoThrobber)
            .then(function (resultControls) {
                if (resultControls.status === 200) {
                    $scope.user[typeControl] = resultControls.data.map((el) => el.id); //typeControl equals divisions, regions, shops
                    if (typeControl === 'shops') {
                        //$scope.user.directorOfShop = undefined;
                        $scope.user.linkedShops = []
                        $scope.user.linkedShops = resultControls.data.map((el) => {
                            el.name = el.locality
                            return el
                        })

                        // if($scope.user.shopDirector){
                        //     $scope.user.directorOfShop = resultControls.data[0]
                        //     $scope.user.directorOfShop.name = $scope.user.directorOfShop.locality+" "+ $scope.user.directorOfShop.sap
                        //     if(cb)cb()
                        // }else{
                        //}
                        console.log("cb",cb)
                         if(cb)cb()
                    }else{
                        console.log("STRANGE", typeControl, $scope.user[typeControl])
                    }
                } else {
                    Notifications.danger(
                        'Ошибка! Не удается загрузить распределение ' +
                        $filter('userLevels')(userData.level) +
                        'ов',  '', 2000);
                }
            });
    }

    var getUserRegions = function(user){
        OrgstructService.getUserRegions(user.id, true).then(function(data) {
            if(data.status != 200){
                Notifications.danger("Ошибка загрузки " + $filter('wordReplace')('cluster') + "ов пользователя: "+response.headers('x-error') + "("+response.headers('x-error-text')+")");
                console.error("Error on loading users regions", response.headers('x-error'), response.headers('x-error-text'))
                return;
            }
            $scope.user.regions = data.data.map((el) => el.id);
            //console.log(" user.regions", user.regions, data.data)
        })
    }

     var getUserDivisions = function(user){
        OrgstructService.getUserDivisions(user.id, true).then(function(data) {
            if(data.status != 200){
                Notifications.danger("Ошибка загрузки " + $filter('wordReplace')('division') + "ов пользователя: "+response.headers('x-error') + "("+response.headers('x-error-text')+")");
                console.error("Error on loading users divisions", response.headers('x-error'), response.headers('x-error-text'))
                return;
            }
            $scope.user.divisions = data.data.map((el) => el.id);
        })
    }

    function getUserReports(idUser, hasNoThrobber){
        var requestUserGroups = {report: true};

        OrgstructService.getUserReportsSources(idUser, requestUserGroups, hasNoThrobber).then(function(result) {

            _scope.user.originReports = angular.copy(result.data);

            if(result.status === 200 ||result.status === 201){
              var allReports = angular.copy(_scope.allReports);

                if(result.data.length > 0){
                    _scope.allUserReports = allReports.map(item => {
                      if(result.data.filter((report) => report.name == item.name).length)item.active = true;
                      return item;
                    });
                }else{
                    _scope.allUserReports = allReports;
                }
            }else{
                Notifications.danger('Ошибка! Не удается загрузить группы пользователя', '', 2000);
            }
        }, function() {
            Notifications.danger('Ошибка! Не удается загрузить группы пользователя', '', 2000);
        });
    }

    function getUserGroups(idUser, hasNoThrobber){

        OrgstructService.getUserGroups(idUser, {}, hasNoThrobber).then(function(result) {
            if(result.status === 200){
                if(!_scope.user)console.log("User lost (((")
                if(!result)console.log("QQQQQQQQQQQQQQQQQQQ")
                _scope.user.groups = getIds(result.data);
            }else if(result.status === 404){
                Notifications.danger('Ошибка! Не удается загрузить группы пользователя', '', 2000);
            }else{
                Notifications.danger('Ошибка! Не удается загрузить группы пользователя', '', 2000);
            }
        }, function() {
            Notifications.danger('Ошибка! Не удается загрузить группы пользователя', '', 2000);
        });
    }

	$scope.saveUserChanges = function () {
        var requestUserUpdate = {};

        var userFields = UsersPageService.getUserFields();

		if ($scope.user && $scope.user.changed) {
			if (userIsValid($scope.user)) {
				requestUserUpdate = {
                    login: $scope.user.login,
                    active: $scope.user.active,
                    regManager: $scope.isRegManager.value,
                };
				if ($scope.user.level) {
                    requestUserUpdate.level = $scope.user.level;
                }
                if ($scope.user.supervisor) {
                    requestUserUpdate.supervisor = $scope.user.supervisor;
                } else {
                    requestUserUpdate.supervisor = false;
                }
                if ($scope.user.taskManager) {
                    requestUserUpdate.taskManager = $scope.user.taskManager;
                } else {
                    requestUserUpdate.taskManager = false;
                }
                if ($scope.user.userChecklistsOrganizer) {
                    requestUserUpdate.userChecklistsOrganizer = $scope.user.userChecklistsOrganizer;
                } else {
                    requestUserUpdate.userChecklistsOrganizer = false;
                }
                if ($scope.user.businessLine) requestUserUpdate.businessDirId = $scope.user.businessLine.id;

                _.forEach(userFields, function(field) {
                    if ($scope.user[field.userField] &&  _.includes(field.accessibleLevel, $scope.user.level)) {
                        requestUserUpdate[field.requestField] = $scope.user[field.userField];
                    }
                });

                requestUserUpdate.shopDirector = $scope.user.shopDirector;
                requestUserUpdate.authType = $scope.user.authType;
                requestUserUpdate.inspector = $scope.isInspector.value;
                requestUserUpdate.generationRuleEditor = $scope.isGenerationRuleEditor.value;

                if ($scope.user.level == "SHOP") {
	                requestUserUpdate.shopIds = $scope.user.linkedShops.filter(shop => !!shop).map(shop => shop.id);
                }

                 if ($scope.user.level == "DIVISION") {
                    if(!requestUserUpdate.divisionIds)requestUserUpdate.divisionIds = []
                 }

                OrgstructService
                    .updateUser($scope.user.id, requestUserUpdate)
                    .then(function (response) {
                        if (!(response.status === 200 || response.status === 201)) {
                            Notifications.danger('Ошибка! Невозможно обновить данные пользователя');
                        } else {
                            $scope.user.changed = false;
                            $scope.changePasswordView = true;
                            $scope.LDAPswitchPasswordReqiured = false;
                            Notifications.success('Пользователь успешно обновлен');
                        }
                    }, function() {
                        Notifications.danger('Ошибка! Невозможно обновить данные пользователя');
                    });
            } else {
                $scope.errorValidation();
            }
        } else {
            Notifications.danger('Ошибка', 'Нечего сохранять', 2000);
        }
    };

    $scope.userSaveGroups = function () {
        if ($scope.user && $scope.user.changedGroups) {
            OrgstructService.updateUserGroups($scope.user.id, $scope.user.groups).then(function (response) {
                if (!(response.status === 200 || response.status === 201)) {
                    Notifications.danger('Ошибка! Невозможно обновить группы пользователя');
                } else {
                    Notifications.success('Пользователь успешно обновлен');
                }
            }, function () {
                Notifications.danger('Ошибка! Невозможно обновить группы пользователя');
            });
        } else {
            Notifications.danger('Ошибка', 'Нечего сохранять', 2000);
        }
    };

    function init() {
        $scope.getData(null, null, true);
    }

    $scope.getData = function(requestParamsUsers, excel, isInit) {
        var requestObject;

        $scope.user = null;

        if(!requestParamsUsers){
            requestParamsUsers = {};
            requestParamsUsers.size =  $scope.links.limit;
            requestParamsUsers.page = $scope.links.page - 1;
        }

        requestParamsUsers.fields = 'businessDir,roles,authType';
        requestParamsUsers.sort = $scope.usersSort.sort + ',' + $scope.usersSort.direction;

        if (!excel){
            $scope.isLoading = true;
            requestObject = {users: OrgstructService.getUsers(requestParamsUsers)};
        } else {
            requestObject = {usersExcel: OrgstructService.getUsersExcel(requestParamsUsers)};
        }

        if (!$scope.businessLines.length){
            requestObject.businessDirs = OrgstructService.getBusinessDirs({sort: 'name,desc'});
        }
        if (!$scope.levels.length){
            requestObject.levels = OrgstructService.getLevels({});
        }
        if (!$scope.allReports.length){
            requestObject.reports = OrgstructService.getReports({});
        }
        if (!$scope.allGroups.length){
            requestObject.groups = OrgstructService.getGroups({});
        }
        if (!$scope.allDivisions.length){
            console.log("getting divisions")
            requestObject.divisions = OrgstructService.getDivisions({});
        }
        if (!$scope.allRegions.length){
            console.log("getting regions")
            requestObject.regions = OrgstructService.getRegions({});
        }
        $q.all(requestObject).then(function (result) {
        	if (result.users){
                $scope.isLoading = false;
        	    if (result.users.status === 200 || result.users.status === 201) {
                    $scope.users = result.users.data;
                    $scope.links = {
                        page: parseInt(result.users.headers()["x-page-number"]) + 1,
                        limit: parseInt(result.users.headers()["x-page-size"]),
                        totalElements: parseInt(result.users.headers()["x-total-count"]),
                        totalPages: parseInt(result.users.headers()["x-total-pages"])
                    };
                    if (result.businessDirs && result.businessDirs.status === 200) {
                        $scope.businessLines = result.businessDirs.data;
                    }
                    if (result.levels && result.levels.status === 200) {
                        $scope.levels = result.levels.data;
                    }
                    if (result.reports && result.reports.status === 200) {
                        $scope.allReports = result.reports.data;
                    }
                    if (result.groups && result.groups.status === 200) {
                        $scope.allGroups = result.groups.data;
                    }
                    if (result.divisions && result.divisions.status === 200) {
                        $scope.allDivisions = result.divisions.data;
                    }
                    if (result.regions && result.regions.status === 200) {
                        $scope.allRegions = result.regions.data;
                    }
                    if (isInit && $scope.users && $scope.users.length) {
                        $scope.editUser($scope.users[0].id, true);
                    }
                } else {
                    Notifications.danger('Ошибка! Не удается загрузить пользователей');
                }
            } else if (result.usersExcel) {
        	    if (result.usersExcel.status === 200 ||  result.usersExcel.status === 201){
                    var blob = new Blob([result.usersExcel.data], {
                        type: 'application/vnd.ms-excel'
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    link.download = 'users_' + moment().format('YYMMDD') + '.xls';
                    link.click();

                    Notifications.success('Файл успешно загружен');
                } else {
                    Notifications.danger('Ошибка!  Невозможно сформировать файл');
                }
            }
        }, function () {
            Notifications.danger('Ошибка!');
        });
    };

    $scope.changeLevel = function(item, changeEntity, newUser) {
        $scope.requiredError = {};
        if(changeEntity) $scope.onChange();

        item.supervisor = true;

        if (item.level === 'SHOP'){
            item.taskManager = false;
            item.userChecklistsOrganizer = false;

            if (newUser) {
              getShops(true);
            } else {
              getAllShops(item);
            }
        }

        if (item.id && item.level === 'REGION'){
            getUserRegions(item)
        }

         if (item.id && item.level === 'DIVISION'){
            getUserDivisions(item)
        }
    };

	function isEmail(email) {
		var re;
		re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
		return re.test(email);
	}

	function userIsValid(user) {
        var isValid, key, ref, val;
        isValid = true;
        $scope.isValidPanel = true;

        if (user.level) {
            if ((user.level === 'DIVISION' || $scope.isRegManager.value) && (!user.divisions || user.divisions.length === 0)) {
                $scope.isValidPanel = false;
                $scope.requiredError.divisions = true;
                errorMessage = 'Ошибка! Необходимо заполнить ' + $filter('wordReplace')('divisions');
                return;
            } else if (user.level === 'REGION' && (!user.regions || user.regions.length === 0)) {
                $scope.isValidPanel = false;
                $scope.requiredError.regions = true;
                errorMessage = 'Ошибка! Необходимо заполнить ' + $filter('wordReplace')('clusters');
                return;
            }
            // } else if (user.level === 'SHOP' && (user.shopDirector && !user.directorOfShop || !user.shopDirector && user.linkedShopsIds.length == 0)) {
            //     $scope.isValidPanel = false;
            //     $scope.requiredError.shops = true;
            //     Notifications.danger('Ошибка!  Необходимо заполнить магазины', '', 5000);
            //     return;
            // }
        }

		if (!isEmail(user.login)) {
            isValid = false;
		}
		if (val === null) {
			ref = user;
			for (key in ref) {
				val = ref[key];
                isValid = false;
			}
		}

		if (!user.id) {
			if (user.authType === 'LOCAL') {

				if (!user.password) {
					Notifications.danger('Пароль пустой');
                    isValid = false;
				} else if (user.password !== user.confirmPassword) {
					$rootScope.$broadcast('passwordsAreDifferent');
                    isValid = false;
				}
			}
		}
		return isValid;
	}

    /* Возвращает объект нового пользователя */
    function newUser() {
        return {
            login: undefined,
            firstName: undefined,
            lastName: undefined,
            position: undefined,
            active: true,
            supervisor: false,
            password: undefined,
            confirmPassword: undefined,
            authType: $scope.authTypes[0].value,
            businessLine: undefined,
            email: undefined,
            taskManager: false,
            userChecklistsOrganizer: false,
            shopDirector: false
        };
    }

    /**
     * @desc changes user data when checking/unchecking shopDirector property
     * @param {object} user - editing user data
     * @param {string} role - shopDirector | regManager
     * @param {boolean} value
     */
    function changeUserRole(user, role, value) {
        if (role === 'shopDirector') {
            if (value) {
                user.supervisor = true;
                user.businessLine = $scope.getBusDir('Директора магазинов');
                user.level = 'SHOP';
                user.inspector = false;
                user.userChecklistsOrganizer = false;
                user.taskManager = false;
                if (!$scope.shopsWithoutDir.length) {
                    getShops()
                }
            } else {
                user.businessLine = null;
                user.level = null;
            }
            if (user.id) {
                user.shopDirector = value;
            }
        } else if (role === 'regManager') {
            if (value) {
                $scope.isInspector.value = false;
                $scope.isShopDir.value = false;
                user.shopDirector = false;
                user.level = 'COMPANY';

                if (user.businessLine && [1006, 1004].includes(user.businessLine.id)) {
                    user.businessLine = null;
                }
            } else {
                user.level = null;
            }
        }
    }

    function changeBusDir(user, isInspector) {
        if (!isInspector) {
            user.businessLine = null;
            user.level = null;
            user.inspector = false;
            return;
        }

        user.businessLine = $scope.getBusDir('Ревизоры');
        user.inspector = true;
        user.supervisor = true;
        user.taskManager = false;
        user.shopDirector = false;
        $scope.isShopDir.value = false;
    }

    function changeChecking() {
        $scope.newUser.supervisor = $scope.canMakeChecking;
    }

    function checkDirSelecting(user) {
        var selectedBusDir = $scope.getBusDir('Директора магазинов');
        return user
            && user.level === 'SHOP'
            && _.get(user, ['businessLine', 'id']) === _.get(selectedBusDir, ['id']);
    }

    function getShops(hasNoThrobber, cb) {
        return OrgstructService
            .getUnsignedShops(hasNoThrobber)
            .then(function (response) {
                $scope.shopsWithoutDir = [];
                if (response.status === 200 && response.data.length) {
                    $scope.shopsWithoutDir = response.data
                }
                console.log("getShops end")
                if(cb)cb()
            }, function () {
                Notifications.danger('Ошибка! Невозможно получить список магазинов');
            });
    }

    init();
};

angular.module('smartApp').controller('PanelUsersController', PanelUsersController);

PanelUsersController.$inject = [
    '$scope',
    '$rootScope',
    '$location',
    '$anchorScroll',
    '$q',
    '$filter',
    'PanelUsersService',
    'PanelGroupsService',
    'FormFactory',
    'Notifications',
    'ParseLink',
    'ApiErrors',
    'ngDialog',
    'localStorageService',
    'PanelRolesService',
    'DownloadService',
    'PanelDivisionsService',
    'PrincipalRoles',
    'OrgstructService',
    'UsersPageService'
];

(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('shopsHistoryChecking', shopsHistoryChecking);

    function shopsHistoryChecking() {

        var directive = {
            controller: ShopsHistoryCheckingController,
            templateUrl: 'scripts/app/checklist/shops-history-checking/shops-history-checking.html',
            restrict: 'EA',
            scope: {},
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;
    }

    ShopsHistoryCheckingController.$inject = [
        '$state',
        'moment',
        'ChecklistService',
        'Notifications',
        'ObjectServiceForDivisions',
        'ObjectServiceForClusters',
        'config',
        '$q',
        'MyResponsibilityFilter',
        'localStorageService',
        'Principal',
        'ShopService'
    ];

    function ShopsHistoryCheckingController(
        $state,
        moment,
        ChecklistService,
        Notifications,
        ObjectServiceForDivisions,
        ObjectServiceForClusters,
        config,
        $q,
        MyResponsibilityFilter,
        localStorageService,
        Principal,
        ShopService
    ) {
        var vm = this;
        let filterByMyResponsibility;

        vm.multiselectTranslations = config.multiselectTranslations;
        vm.isRegManager = Principal.isInRole('ROLE_REG_MANAGER');
        vm.account = Principal.getIdentity();

        vm.history = [];

        vm.loading = false;
        vm.divisions = [];
        vm.clusters = [];
        vm.onlyMyResponsibility = false;
        vm.selectedDivisions = [];
        vm.selectedClusters = [];
        vm.shops = [];

        vm.calendarsOpen = [];

        vm.isFullScreen = false;

        vm.rangeTo = new Date();
        vm.rangeFrom = moment().subtract(1, 'months').toDate();
        vm.selectedShops = [];

        vm.getFirstPage = getFirstPage;
        vm.getNextPage = getNextPage;
        vm.goToCheckList = goToCheckList;
        vm.open = open;
        vm.refreshShops = refreshShops;
        vm.onFiltersPopupApply = onFiltersPopupApply;
        vm.onOnlyMyResponsibilityChange = onOnlyMyResponsibilityChange;

        /** @desc pagination settings */
        vm.pagination = {
            page: 1,
            limit: 20
        };

        /** @desc datepicker settings */
        vm.dateOptions = {
            showWeeks: false
        };

        let fetchedAllHistory = false;

        init();

        function init() {
            $q.all({
                clusters: ObjectServiceForClusters.query({}).$promise,
                divisions: ObjectServiceForDivisions.query({}).$promise
            }).then(res => {
                vm.divisions = res.divisions;
                vm.clusters = res.clusters;
                filterByMyResponsibility = MyResponsibilityFilter(res.divisions, res.clusters);

                if (vm.isRegManager && localStorageService.get('plannedChecklist:filter:onlyMyResponsibility') === 'true') {
                    vm.onlyMyResponsibility = true;
                    onOnlyMyResponsibilityChange();
                }

                getFirstPage();
            }, () => {
                Notifications.danger('Ошибка загрузки фильтров');
            });
        }

        function getHistory(page) {
            var filter = {
                rangeFrom: vm.rangeFrom,
                rangeTo: vm.rangeTo,
                divisionIds: (vm.selectedDivisions.length ? vm.selectedDivisions : vm.divisions),
                regionIds: vm.selectedClusters,
                shop: vm.selectedShops,
                dateType: 'FACT',
                page: page,
                perPage: 20
            };
            return ChecklistService.getXreport3(filter)
                .then(function(response) {
                    if (response.status === 200) {
                        return response.data.list;
                    }
                });
        }

        function getFirstPage() {
            fetchedAllHistory = false;
            vm.pagination.page = 1;
            vm.loading = true;
            vm.history = [];
            getHistory(vm.pagination.page).then(result => {
                if (result) {
                    vm.history = result;
                }
                vm.loading = false;
            });
        }

        function getNextPage() {
            if (fetchedAllHistory || vm.loading) return;
            getHistory(vm.pagination.page + 1).then(result => {
                if (result) {
                    fetchedAllHistory = !result.length;

                    if (!fetchedAllHistory) {
                        vm.history = vm.history.concat(result);
                        vm.pagination.page += 1;
                    }
                }
            });
        }

        function goToCheckList(id) {
            var url = $state.href('editChecklist', {
                checklist: id
            });
            window.open(url, '_blank');
        }

        function open($event, id) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.calendarsOpen[1] = false;
            vm.calendarsOpen[2] = false;
            vm.calendarsOpen[id] = true;
        }

        function refreshShops(search) {
            const selectedShopIds = vm.selectedShops.map(s => s.id);

            if (search.length >= 2) {
                ShopService.search(search).success(function(result, status) {
                    if (status === 200) {
                        vm.shops = selectedShopIds.length ? result.filter(s => !selectedShopIds.includes(s.id)) : result;
                    } else if (status === 204) {
                        vm.shops = [];
                    }
                });
            } else {
                vm.shops = [];
            }
        }

        function onFiltersPopupApply() {
            localStorageService.set('plannedChecklist:filter:onlyMyResponsibility', vm.onlyMyResponsibility)
            getHistory();
        }

        function onOnlyMyResponsibilityChange() {
            const result = filterByMyResponsibility(vm.onlyMyResponsibility);
            vm.divisions = result.divisions;
            vm.clusters = result.clusters;
        }
    }
})();

angular.module('smartApp').service('Pagination', ['localStorageService', 'config', function(localStorageService, config){
    var defaultLimit = 5;

    var pagination = {
        variants: [5,10,15,20],
        page:1,
        perPage: (localStorageService.get("perPage")) ? localStorageService.get("perPage") : defaultLimit,
        viewPort: [],
        pageChange: function(allList){
            if(localStorageService.get("perPage") !== this.perPage)localStorageService.set("perPage", this.perPage)
            this.viewPort = allList.slice((this.page-1)*this.perPage, this.page*this.perPage)
        },
        total: undefined
    };

    return {
    	get: function(pageChangeFunc){
    		if(pageChangeFunc && typeof pageChangeFunc == "function")pagination.pageChange = pageChangeFunc;
    		return angular.copy(pagination);
    	}

    }
}]);

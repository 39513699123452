angular.module('smartApp').factory('Xfilter11', checkXreportFactory11);
checkXreportFactory11.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory11(ChecklistService, DownloadService) {
	return {
		id: 15,
		title: 'Отчёты',
		resultTitle: 'Результаты отчета по клинингу',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter11/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter11/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		load: function(filters) {
			var t = ChecklistService.getXreport11(filters);

			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport11(filters);
		}
	};

}
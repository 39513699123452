export const Notifications = {
    /**
     *
     * @param params
     *  {
                title : "Congratulations! Your form was submitted",
                content : "<i class='fa fa-clock-o'></i><i>1 seconds ago...</i>",
                color : "#5F895F",
                iconSmall : "fa fa-check bounce animated",
                timeout : 4000
            }
     */

    alert: function(title, content, color, timeout){
        $.smallBox({
            title: title,
            content: content,
            color: color,
            timeout: timeout || (timeout === null ? null : 2000),
            sound: false
        });
    },

    success: function(title, content, timeout){
        this.alert(title, content, '#739E73', timeout)
    },

    danger: function(title, content, timeout){
        this.alert(title, content, '#C46A69', timeout)
    },
    warning: function(title, content, timeout){
        this.alert(title, content, '#f0ad4e', timeout)
    }
};
import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';
import VueAxios from 'vue-axios';

import '@/styles/main.scss';

const componentsMap = {
    App,
    TasksEditor: defineAsyncComponent(() => import('@/views/tasks-editor/TasksEditor.vue')),
};

/*
    Следующая логика нужна для правильной работы библиотеки @vueform/multiselect, которую используют селекты, внутри shadow dom.
    @vueform/multiselect использует document.activeElement для активации селекта, но в shadow dom
    document.activeElement всегда возвращает контейнер, в котором смонтирован dom, а не активный элемент.
    Поэтому патчим свойство, чтобы обработать этот кейс, и возвращаем document.activeElement.shadowRoot.activeElement,
    если к свойству обращаются из shadow dom.
*/
Object.defineProperty(document, 'activeElement', {
    get() {
        let active = document.__realActiveElement || null;

        if (active?.shadowRoot?.activeElement) {
            return active.shadowRoot.activeElement;
        }

        return active;
    },
    configurable: true
});

document.addEventListener('focusin', (event) => {
    document.__realActiveElement = event.target;
});

export function mountVueComponent(componentName, el, props, options) {
    const component = componentsMap[componentName];
    if (!component) {
        return;
    }
    try {
        const container = document.createElement('div');
        const shadow = el.attachShadow({ mode: 'open' });

        shadow.appendChild(container);
        window.vueStyles.inject(shadow);

        const app = createApp(component, props);

        if (!options || options.withRouter !== false) {
            app.use(router);
        }

        app.use(store);
        app.use(VueAxios, axios);
        app.mount(container);
    } catch (error) {
        console.error('Error mounting Vue component:', error);
    }
}

window.mountVueComponent = mountVueComponent;

'use strict';

angular.module('smartApp').factory('TasksTemplateService', function TasksTemplateService(Notifications, $http, config, HttpUtils) {

    return {
        getAll: function(params, cb) {
            $http.get(HttpUtils.getQuery('/taskmanager/templates/', {noThrobber: true, onlyActive:params.onlyActive, name: params.search, page: params.page, perPage: params.perPage})).success(function (result, status, headers) {                
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result, headers);
            });
        }, 
        createTemplate:  function(newTemplate, cb) {
            $http.post(config.backend + '/taskmanager/templates/', newTemplate).success(function (result, status) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result);
            });
        }, 
        updateTemplate: function(newTemplate, cb) {
            $http.post(config.backend + '/taskmanager/templates/', newTemplate).success(function (result, status) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result);
            });
        }, 
        getTemplate: function(id, cb) {
          
            $http.get(config.backend + '/taskmanager/templates/'+id).success(function (result, status) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result);
            });
        }, 
        switchTemplate: function(template, cb) {
           
            $http.post(config.backend + '/taskmanager/templates/'+ template.id +'/toggle', {noThrobber: true}).success(
                function (result, status) {
                    if (status && status != 200) {
                        cb(status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },
    }

});

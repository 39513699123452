angular.module('smartApp').directive('fileInput', function() {
  return {
    require: 'ngModel',
    link: function postLink(scope, elem, attrs, ngModel) {
      elem.on('change', function(e) {
        var file = elem[0].files[0];

        if (!file) {
          ngModel.$setViewValue(null);
          return;
        }

        ngModel.$setViewValue(file);
      })
    }
  }
});

<script setup>
import { computed } from 'vue';
import { SingleSelect, MultipleSelect } from '@eron/ui-kit';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, Array],
        default: undefined,
    },
    multi: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value']);

const options = [
    {
        label: 'Общая',
        value: 'COMMON',
    },
    {
        label: 'Фотоотчёт',
        value: 'PHOTO_REPORT',
    },
];
const selected = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:model-value', value);
    },
});
</script>

<template>
    <component :is="multi ? MultipleSelect : SingleSelect" :id="id" v-model="selected" :options="options" />
</template>

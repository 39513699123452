import { db } from '@shared/data';
import { getAccessibleOrgstruct } from '../common/orgstruct';

const dataCache = {
    objectMaps: undefined,
    accessibleObjects: undefined,
    shopsMappers: undefined,
    clear() {
        this.objects = undefined;
        this.shopsMappers = undefined;
        this.accessibleObjects = undefined;
    },
};

const getObjectMaps = async () => {
    if (dataCache.objectMaps) return dataCache.objectMaps;
    const objectKeys = ['shops', 'regions', 'divisions'];
    const promises = objectKeys.map(async key => {
        const objects = await db[key].toArray();
        return objects.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
        }, {});
    });
    dataCache.objectMaps = (await Promise.all(promises)).reduce((hash, dataMap, index) => {
        const objectKey = objectKeys[index];
        hash[objectKey] = dataMap;
        return hash;
    }, {});
    return dataCache.objectMaps;
};

const getShopsMappers = async () => {
    if (dataCache.shopsMappers) return dataCache.shopsMappers;

    const objectMaps = await getObjectMaps();
    const shopsMappers = { byRegion: new Map(), byDivision: new Map() };

    await db.shops.each(shop => {
        const region = objectMaps.regions[shop.clusterId];

        if (region) {
            const division = objectMaps.divisions[region.divisionId];
            const regionShops = shopsMappers.byRegion.get(region.id) || [];
            shopsMappers.byRegion.set(region.id, regionShops.concat(shop));

            if (division) {
                const divisionShops = shopsMappers.byDivision.get(division.id) || [];
                shopsMappers.byDivision.set(division.id, divisionShops.concat(shop));
            }
        }
    });

    dataCache.shopsMappers = shopsMappers;
    return shopsMappers;
};

const getAccessibleObjects = async () => {
    if (dataCache.accessibleObjects) return dataCache.accessibleObjects;
    const [shops, regions, divisions] = await getAccessibleOrgstruct();
    dataCache.accessibleObjects = { shops, regions, divisions };
    return dataCache.accessibleObjects;
};

const filterObjects = ({ shops, regions, divisions }, filters) => {
    if (filters.divisionIds && filters.divisionIds.length) {
        const regionIds = [];

        divisions = divisions.filter(division => filters.divisionIds.includes(division.id));
        regions = regions.filter(region => {
            if (filters.divisionIds.includes(region.divisionId)) {
                regionIds.push(region.id);
                return true;
            }
        });
        shops = shops.filter(shop => regionIds.includes(shop.clusterId));
    }

    return { divisions, regions, shops };
};

const mapObjectsToIds = ({ shops, regions, divisions }) => {
    const mapIds = arr => arr.map(item => item.id);
    return { shops: mapIds(shops), regions: mapIds(regions), divisions: mapIds(divisions) };
};

const createNode = (key, object, state = {}) => {
    let label;

    if (key === 'shops') {
        label = object.locality;
    } else if (key === 'users') {
        label = object.displayName;
    } else {
        label = object.name;
    }

    return { key, source: object, id: object.id, label, nodes: [], expandable: true, ...state };
};

export const clearDataCache = () => {
    dataCache.clear();
};

export const buildObjectsTree = async params => {
    const { executorLevel, checked, expanded, invalidateCache } = params;

    if (invalidateCache) {
        dataCache.clear();
    }

    const objectKeys = ['shops', 'regions', 'divisions'];
    const checkedIds = { divisions: checked?.divisions || [], regions: checked?.regions || [], shops: checked?.shops || [] };
    const expandedIds = { divisions: expanded?.divisions || [], regions: expanded?.regions || [] };
    const levelObjectKey = `${executorLevel.toLowerCase()}s`;
    const levelIndex = objectKeys.indexOf(levelObjectKey);
    const accessibleObjects = await getAccessibleObjects();
    const parentDataMaps = {};
    const objects = { divisions: {}, regions: {}, shops: {} };
    const bottomLevelNodes = accessibleObjects[levelObjectKey].reduce((result, obj) => {
        if (obj.active) {
            objects[levelObjectKey][obj.id] = obj;
            result.push(
                createNode(levelObjectKey, obj, {
                    checked: checkedIds[levelObjectKey]?.includes(obj.id),
                })
            );
        }
        return result;
    }, []);

    const createDataMap = key => {
        return accessibleObjects[key].reduce((hash, obj) => {
            hash[obj.id] = obj;
            return hash;
        }, {});
    };
    const tree = objectKeys.slice(levelIndex + 1).reduce((childNodes, key) => {
        const parentIdKeys = { regions: 'clusterId', divisions: 'divisionId' };
        const parentDataMap = parentDataMaps[key] || createDataMap(key);
        const parentIdKey = parentIdKeys[key];
        const parents = {};

        childNodes.forEach(node => {
            const parent = parentDataMap[node.source[parentIdKey]];

            if (parent && parent.active) {
                const parentNode =
                    parents[parent.id] ||
                    createNode(key, parent, {
                        checked: checkedIds[key]?.includes(parent.id),
                        expanded: expandedIds[key]?.includes(parent.id),
                    });
                parentNode.nodes.push(node);
                node.parent = parentNode;
                parents[parent.id] = parentNode;
            }
        });

        objects[key] = parents;
        return Object.values(parents);
    }, bottomLevelNodes);

    traverseTree(tree, {
        beforeChildren: node => {
            if (node.parent && node.parent.checked) {
                node.checked = true;
                node.disabled = true;
            }
        },
        afterChildren: node => {
            setNodeCheckedState(node);
        },
    });

    return { tree, objects };
};

const setNodeCheckedState = node => {
    const everyChecked = node.nodes.every(node => node.checked);
    const someCheckedOrIndeterminate = node.nodes.some(node => node.checked || node.indeterminate);

    node.checked = everyChecked;
    node.indeterminate = !everyChecked && someCheckedOrIndeterminate;
};

export const traverseTree = (tree, cb) => {
    let beforeChildren, afterChildren;

    if (typeof cb === 'function') {
        beforeChildren = cb;
    } else {
        beforeChildren = cb.beforeChildren;
        afterChildren = cb.afterChildren;
    }

    tree.forEach(node => {
        const hasChildren = node.nodes && node.nodes.length;

        beforeChildren(node);

        if (hasChildren) {
            traverseTree(node.nodes, cb);

            if (afterChildren) {
                afterChildren(node);
            }
        }
    });
};

export const searchTree = (tree, searchString) => {
    const searchRegex = new RegExp(searchString, 'gi');

    return tree.reduce((result, node) => {
        node = { ...node };

        const match = searchRegex.test(node.label);
        const hasChildren = node.nodes && node.nodes.length;

        if (match) {
            if (hasChildren) {
                node.expanded = true;
            }
            result.push(node);
            return result;
        }

        if (hasChildren) {
            node.nodes = searchTree(node.nodes, searchString);

            if (node.nodes.length) {
                node.expanded = true;
                result.push(node);
                return result;
            }
        }

        return result;
    }, []);
};

export const buildUsersTree = async params => {
    const account = window.fprAccount;
    const {
        executorLevel,
        inShops,
        businessDirIds,
        invalidateCache,
        filterDivisionIds,
        selectedShopIds,
        selectedUserIds,
        checkedUserNodeIds,
        checkedShopNodeIds,
        expanded,
    } = params;

    if (invalidateCache) {
        dataCache.clear();
    }

    let treeLevel = executorLevel;

    if (executorLevel === 'SELF') {
        treeLevel = 'SHOP';
    }

    const objectKeys = ['shops', 'regions', 'divisions'];
    const levelObjectKey = `${treeLevel.toLowerCase()}s`;
    const levelIndex = objectKeys.indexOf(levelObjectKey);
    const bottomLevelNodes = {};
    const objects = { divisions: {}, regions: {}, shops: {} };
    const expandedIds = { divisions: expanded?.divisions || [], regions: expanded?.regions || [], shops: expanded?.shops || [] };
    const users = {};
    const userNodes = [];
    const selectedUserNodes = [];
    const checkedUserNodes = [];
    let shopsMappers;

    const accessibleIds = mapObjectsToIds(
        filterObjects(await getAccessibleObjects(), {
            divisionIds: filterDivisionIds,
        })
    );
    const objectMaps = await getObjectMaps();
    const targetUsers = executorLevel === 'SELF' ? await db.users.where('id').equals(account.id).toArray() : await db.users
        .filter(user => {
            let businessDirFilter = true;

            if (businessDirIds && businessDirIds.length) {
                businessDirFilter = businessDirIds.includes(user.businessDirId);
            }

            return (
                user.active &&
                user.level === executorLevel &&
                user[levelObjectKey].some(objId => accessibleIds[levelObjectKey].includes(objId)) &&
                businessDirFilter
            );
        })
        .toArray();

    if (inShops && treeLevel !== 'SHOP') {
        shopsMappers = await getShopsMappers();
    }

    const isUserSelected = (user, parent) => {
        if (executorLevel === 'SHOP' || inShops) {
            return selectedUserIds?.includes(user.id) && selectedShopIds?.includes(parent.id);
        }

        return selectedUserIds?.includes(user.id);
    };

    const isUserNodeChecked = userNode => {
        if (userNode.parent.key === 'shops' && checkedShopNodeIds?.includes(userNode.parent.id)) {
            return true;
        }
        return checkedUserNodeIds?.length ? checkedUserNodeIds.includes(userNode.id) : false;
    };

    const createUserNode = (user, parentObject, parentObjectKey) => {
        if (!parentObject || !parentObject.active) return;

        const id = parentObject.id;

        bottomLevelNodes[id] =
            bottomLevelNodes[id] ||
            createNode(parentObjectKey, parentObject, {
                expanded: expandedIds[parentObjectKey].includes(id),
            });

        const userNode = createNode('users', user);

        userNode.id = `${parentObject.id}_${userNode.id}`;
        userNode.parent = bottomLevelNodes[id];
        userNode.checked = isUserNodeChecked(userNode);
        bottomLevelNodes[id].nodes.push(userNode);
        objects[parentObjectKey][parentObject.id] = parentObject;

        setNodeCheckedState(bottomLevelNodes[id]);

        if (isUserSelected(user, bottomLevelNodes[id])) {
            selectedUserNodes.push(userNode);
        }

        if (userNode.checked) {
            checkedUserNodes.push(userNode);
        }
    };

    targetUsers.forEach(user => {
        const objectIds = executorLevel === 'SELF' ? accessibleIds[levelObjectKey] : user[levelObjectKey];
        objectIds.forEach(id => {
            if (!accessibleIds[levelObjectKey].includes(id)) return;

            if (treeLevel === 'SHOP' || !inShops) {
                createUserNode(user, objectMaps[levelObjectKey][id], levelObjectKey);
            } else {
                const shopsMapper = treeLevel === 'DIVISION' ? shopsMappers.byDivision : shopsMappers.byRegion;

                if (shopsMapper.has(id)) {
                    shopsMapper.get(id).forEach(shop => createUserNode(user, shop, 'shops'));
                }
            }
        });
    });

    const tree = objectKeys.slice(inShops ? 1 : levelIndex + 1).reduce((childNodes, key) => {
        const parentIdKeys = { regions: 'clusterId', divisions: 'divisionId' };
        const parentDataMap = objectMaps[key];
        const parentIdKey = parentIdKeys[key];
        const parents = {};

        if (!parentIdKey) return childNodes;

        childNodes.forEach(node => {
            const parent = parentDataMap[node.source[parentIdKey]];

            if (parent && parent.active) {
                const parentNode =
                    parents[parent.id] ||
                    createNode(key, parent, {
                        expanded: expandedIds[key].includes(parent.id),
                    });
                parentNode.nodes.push(node);
                parents[parent.id] = parentNode;
                setNodeCheckedState(parentNode);
            }
        });

        objects[key] = parents;
        return Object.values(parents);
    }, Object.values(bottomLevelNodes));

    traverseTree(tree, node => {
        if (node.key === 'users') {
            userNodes.push(node);
            users[node.source.id] = node.source;
        }
    });

    return { tree, users, objects, userNodes, selectedUserNodes, checkedUserNodes };
};

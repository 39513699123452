angular.module('smartApp').controller('FilterController4', FilterController4);

FilterController4.$inject = ['$scope', 'UserService'];

function FilterController4($scope, UserService) {

	$scope.displayFilters = {
		displayCurator: false,
		displaySupervisor: false,
		displaySecret: false
	};

	function onUpdateReportResult(result) {
		if(result && result.length > 0) {
			$scope.displayFilters.displayCurator = typeof result[0].curatorFio === 'string';
			$scope.displayFilters.displaySupervisor = typeof result[0].supervisorFio === 'string';
			$scope.displayFilters.displaySecret = typeof result[0].secretFio === 'string';
		}
	}
	$scope.$watch('reportResult', onUpdateReportResult);
	//$scope.users = [];
}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
'use strict';

angular.module('smartApp').factory('ObjectServiceForCities', function ($resource, config) {
    return $resource(config.backend + '/objects/cities', {}, {
            'query': { method: 'GET', isArray: true }
        }
    );
});

angular.module('smartApp').factory('AdditionToAssignService', function($resource, config) {
	return $resource(config.backend + '/assignTemplate/limits', {}, {
		'query': { method: 'GET', isArray : true },
		'update': { method: 'PUT' }
	})
});


var checkNumChecklistsController =
function($rootScope, $scope, $filter, TemplateService, GroupShopService, AdditionToAssignService, UserService, ObjectServiceForDivisions, ObjectServiceForCities, ObjectServiceForClusters, AssignTemplateServiceForTemplates, AssignTemplateServiceForUsers, AssignTemplateServiceForGroups,moment) {
	var dataForExport = [];
	$scope.filters = {
		selected: {cluster: undefined, division: undefined},
		possible: {clusters: undefined, diviosions: undefined},
		all:{clusters: undefined, diviosions: undefined}
	}
	$scope.cities;
	$scope.limits;
	$scope.limitSet;
	$scope.templateChanged = false;
	$scope.groupChanged = false;
	$scope.selectedGroup;


	$scope.exportData = function () {
		var headText = "Чеклист " + $scope.selectedTemplate.name + " &mdash; группа " + $scope.selectedGroup.name;
		
		angular.element("div.data").after("<div class='tempXLS' />");
		angular.element("div.tempXLS").html(angular.element("div.data").html());
		angular.element("div.tempXLS").find("table").prop("border", 1).append("<caption>"+headText+"</caption>")
		angular.element("div.tempXLS").find("table thead sort").remove()
		//angular.element("div.tempXLS").find("table thead").append("<tr></tr>");
		//angular.element("div.tempXLS").find("table thead>tr:last-of-type").html("<td colspan='7'>" + headText +"</td>");
		angular.element("div.tempXLS").find("tr>td:first-of-type").remove()
        var blob = new Blob([angular.element("div.tempXLS").html()], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        saveAs(blob, "limits"+moment().format('YYMMDD')+".xls");
        angular.element("div.tempXLS").remove()
    }

	$scope.loadLimits = function() {
		
		$scope.limits = undefined;
		$scope.checkAll = false;
		
		if($scope.selectedTemplate && $scope.selectedGroup) {

			AdditionToAssignService.query(
				{
					divisionId: $scope.filters.selected.division ? $scope.filters.selected.division.id : null,
					clusterId: $scope.filters.selected.cluster ? $scope.filters.selected.cluster.id : null,
					//locality: $scope.searchShop,//$scope.locality.selected ? $scope.locality.selected.name : null,
					shopQuery: $scope.searchShop ? $scope.searchShop : null,
					templateId: $scope.selectedTemplate.id,
					groupId: $scope.selectedGroup.id
				}, function(result) {
					dataForExport = [];
					for(var i in result){
						if(!result[i].shopId)continue;
						if(result[i].cluster) result[i].clusterName = result[i].cluster.name;
						dataForExport.push({
							sap:result[i].shopSap,
							locality:result[i].locality,
							cluster:result[i].cluster.name,
							address:result[i].address,
							limit:result[i].limit,
						})
					}
					
					$scope.limits = result;
					
					// setTimeout(function(){
					// 	angular.element("table input[type=checkbox]").click(function(){
					// 		$scope.checkedCheckboxes = angular.element("table tbody input[type=checkbox]:checked").length;
					// 		console.log($scope.checkedCheckboxes)
					// 		alert(1)
							
					// 	})
					// }, 0)
					
				}
			)
		}
	}

	
	$scope.sort = 'shopSap';
	$scope.reverse = true;
	$scope.setSort = function(sort) {
		$scope.reverse = ($scope.sort === sort) ? !$scope.reverse : false;
		$scope.sort = sort;
	};


  //$scope.checkedCheckboxes = 0;


      $scope.loadTemplates = function() {
          AssignTemplateServiceForTemplates.query({page: 0, per_page: 0}, function(result) {
              $scope.templates = result;
          });
      }

  		$scope.selectTemplate = function(template) {
  			$scope.selectedTemplate = template;
  			$scope.selectedGroup = undefined
  		}

  		$scope.selectGroup = function(group) {
          $scope.selectedGroup = group;
      }

      $scope.loadGroups = function() {
          AssignTemplateServiceForGroups.query({}, function(result) {
              $scope.groups = result;
          });
      }

	$scope.setLimit = function(newLimit) {
		$scope.limitSet = newLimit;
	}

    $scope.save = function() {
      if(Number.isInteger($scope.limitSet)) {
        var limitsToChange = [];
        $scope.updating = true
        angular.forEach($scope.limits, function(row, rowId) {
          if(row.selected) {
            limitsToChange.push({ id: row.limitId, limit: $scope.limitSet, groupId: $scope.selectedGroup.id, shopId:row.shopId, templateId: $scope.selectedTemplate.id });
          }
        });

        AdditionToAssignService.update(limitsToChange, function(data, result) {
        	$scope.updating = false;
			$scope.loadLimits();
		})
      }
    }

	//$scope.$watch('selectedTemplate', $scope.loadLimits);
	$scope.$watch('selectedGroup', function(){
		//console.log($scope)
		if($scope.divisions)$scope.divisionsSelected=undefined;
		if($scope.clusters)$scope.clustersSelected=undefined;
		if($scope.searchShop)$scope.searchShop=undefined;

		$scope.loadLimits()
	});

	$scope.loadDivisions = function() {
		ObjectServiceForDivisions.query({}, function(result) {
			$scope.filters.all.divisions = result;
			$scope.filters.possible.divisions = result;
			
		});
	}

	$scope.loadClusters = function() {
		ObjectServiceForClusters.query({}, function(result) {
			$scope.filters.all.clusters = result;
			$scope.filters.possible.clusters = result;
		});

	}

	$scope.loadCities = function() {
		ObjectServiceForCities.query({}, function(result) {
			$scope.cities = result;
		});
	}



	$scope.onDivisionSelect = function(){
		if($scope.filters.selected.division){
			$scope.filters.possible.clusters = [];
			for(var i in $scope.filters.all.clusters){
				if($scope.filters.all.clusters[i].divisionId == $scope.filters.selected.division.id)$scope.filters.possible.clusters.push($scope.filters.all.clusters[i])
			}
			//if($scope.filters.possible.clusters.length === 1)$scope.filters.selected.clusters = $scope.filters.possible.clusters[0]
		}else{
			$scope.filters.possible.clusters = $scope.filters.all.clusters
		}
	}

	$scope.onClusterSelect = function(){
		if($scope.filters.selected.cluster){
			$scope.filters.possible.divisions = [];
			for(var i in $scope.filters.all.divisions){
				if($scope.filters.all.divisions[i].id == $scope.filters.selected.cluster.divisionId)$scope.filters.possible.divisions.push($scope.filters.all.divisions[i])
			}
			//if($scope.filters.possible.divisions.length === 1)$scope.filters.selected.division = $scope.filters.possible.divisions[0]
		}else{
			$scope.filters.possible.divisions = $scope.filters.all.divisions
		}
	}

	$scope.selectAll = function(){
		//angular.element("table>tbody input[type=checkbox]").prop('checked', !$scope.checkAll);
		
		angular.forEach($scope.limits, function(row, rowId) {
          row.selected =  $scope.checkAll
        });
	}

	$scope.init = function() {
		$scope.loadTemplates();
		$scope.loadGroups();
		$scope.loadDivisions();
		$scope.loadClusters();
		$scope.loadCities();
	}

    $scope.init();


}

angular.module('smartApp').controller('checkNumChecklistsController', checkNumChecklistsController);

checkNumChecklistsController.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  'TemplateService',
  'GroupShopService',
  'AdditionToAssignService',
  'UserService',
  'ObjectServiceForDivisions',
  'ObjectServiceForCities',
  'ObjectServiceForClusters',
  'AssignTemplateServiceForTemplates',
  'AssignTemplateServiceForUsers',
  'AssignTemplateServiceForGroups'
];

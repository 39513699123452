angular.module('smartApp').service('InspectionActService', ['$http', 'config', InspectionActsService]);

function InspectionActsService($http, config) {
    return {
        getInspectionActs: function(params) {
            return $http.get(config.backend + '/inspectionAct/acts', { params: params });
        },

        getInspectionAct: function(uid) {
            return $http.get(config.backend + '/inspectionAct/acts/' + uid);
        }
    }
}

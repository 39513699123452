<script setup>
import { ref, watch } from 'vue';
import { CustomInput, SingleSelect } from '@eron/ui-kit';

import WeekdaysSelect from '../components/WeekdaysSelect.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const settingsTypes = [
    { label: 'В указанные дни месяца', value: 'weekdays' },
    { label: 'В указанное число месяца', value: 'days' },
];
const weekOptions = [
    { label: 'Первый', value: 1 },
    { label: 'Второй', value: 2 },
    { label: 'Третий', value: 3 },
    { label: 'Четвертый', value: 4 },
    { label: 'Последний', value: 0 },
];

const settingsType = ref('weekdays');
const weeks = ref();
const days = ref([]);
const day = ref();
let skipUpdate = false;

const emitChange = () => {
    let value;

    if (settingsType.value === 'weekdays') {
        value = typeof weeks.value === 'number' && days.value.length ? { weeks: [weeks.value], days: days.value } : undefined;
    } else {
        value = day.value ? { days: [day.value] } : undefined;
    }

    emit('update:model-value', value);
    skipUpdate = true;
};

const validate = () => {
    return settingsType.value === 'weekdays' ? typeof weeks.value === 'number' && Boolean(days.value.length) : Boolean(day.value);
};

const onDayBlur = () => {
    day.value = day.value ? Math.min(Math.max(day.value, 1), 31) : day.value;
    emitChange();
};

const onSettingsTypeUpdate = () => {
    weeks.value = undefined;
    days.value = [];
    day.value = undefined;
    emitChange();
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        if (newVal) {
            settingsType.value = newVal.weeks?.length ? 'weekdays' : 'days';
        } else {
            settingsType.value = 'weekdays';
        }

        weeks.value = Array.isArray(newVal?.weeks) ? newVal.weeks[0] : newVal?.weeks;
        days.value = settingsType.value === 'weekdays' ? newVal?.days || [] : [];
        day.value = settingsType.value === 'days' ? newVal?.days?.[0] : undefined;
    },
    { immediate: true }
);

defineExpose({
    validate,
});
</script>

<template>
    <div>
        <single-select
            id="monthly-settings-type"
            v-model="settingsType"
            :options="settingsTypes"
            class="-mb-3"
            @update:model-value="onSettingsTypeUpdate"
        />

        <div v-if="settingsType === 'weekdays'" class="-flex -items-center">
            <span class="-mr-1.5 -text-sm -text-gray-moss">Каждый</span>
            <span class="-mr-1.5 -w-40">
                <single-select id="monthly-settings-week" v-model="weeks" :options="weekOptions" @update:model-value="emitChange" />
            </span>
            <span class="-mr-1.5 -text-sm -text-gray-moss">указанный день месяца:</span>
            <weekdays-select id="monthly-settings-weekdays" v-model="days" class="-flex-grow" @update:model-value="emitChange" />
        </div>

        <custom-input v-else id="monthly-settings-day" v-model="day" type="number" @blur="onDayBlur" />
    </div>
</template>

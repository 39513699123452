'use strict';

angular.module('smartApp').factory('ShopService', ShopService);

ShopService.$inject = ['$http', 'config', 'HttpUtils'];

function ShopService($http, config, HttpUtils) {

    var shops;
    var service = {
        getAllByActorId: getAllByActorId,
        getAllObjectsByActorId: getAllObjectsByActorId,
        getAll: getAll,
        search: search,
        getShops: getShops,
        assignShop: assignShop,
        deleteAssigning: deleteAssigning,
        loadShopTemplates: loadShopTemplates,
        getUnsignedShops: getUnsignedShops
    };

    return service;

    function getAllByActorId(actorId) {
        return $http.get(config.backend + '/shops/getAllByActorId?actorId=' + actorId)
            .success(function(data) {
                shops = data;
            });
    }

    function getAllObjectsByActorId(actorId) {
        return $http.get(config.backend + '/shops/getAllObjectsByActorId?actorId=' + actorId)
            .success(function(data) {
                shops = data;
            });
    }

    function getAll() {
        return $http.get(config.backend + '/shops/getAll')
            .success(function(data) {
                return data;
            });
    }

    function search(value, page, limit){
        var params = {
            search: value
        };
        if (page) {
            params['page'] = page;
        }
        if (limit) {
            params['per_page'] = limit;
        }
        var query = HttpUtils.getQuery('/admin/shops', params);
        return $http.get(query);
    }


    function getShops() {
        return shops;
    }

    function assignShop(shopId) {
        return $http.post(config.backend + '/shops/assignShop?shopId=' + shopId)
            .success(function(data) {
                return data;
            });
    }

    function deleteAssigning(shopId) {
        return $http.post(config.backend + '/shops/deleteAssigning?&shopId=' + shopId)
            .success(function(data) {
                return data;
            });
    }

    function loadShopTemplates(templateName) {
        return $http.get(config.backend + '/shopTemplate/getAll?templateName=' + templateName)
            .success(function(data) {
                return data;
            });
    }

    /**
     * @desc get shops without director
     * @param {boolean} hasNoThrobber - show or not show throbber when data is loading
     * @returns {promise}
     */
    function getUnsignedShops(hasNoThrobber) {
        return $http.get(config.backend + '/shops/getUnsigned', {
            noThrobber: hasNoThrobber
        });
    }

}
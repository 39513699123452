(function() {
    'use strict';

    angular
        .module('smartApp')
        .component('paging', {
            templateUrl: 'scripts/components/paging/paging.view.html',
            controller: PagingController,
            bindings: {
                total: '<',
                step: '<',
                currentPage: '<',
                displayedPagesCount: '<',
                changePagination: '&'
            }
        });

    PagingController.$inject = [];

    function PagingController() {
        var $ctrl = this;

        $ctrl.pages = [];

        $ctrl.displayedPages = [];

        $ctrl.goToPrev = goToPrev;
        $ctrl.goToNext = goToNext;
        $ctrl.selectPage = selectPage;

        $ctrl.activePage = 0;

        $ctrl.firstPage = 0;

        $ctrl.counter;

        $ctrl.lastPage;

        $ctrl.$onInit = function() {
            initPages();
        };

        $ctrl.$onChanges = function(changes) {
            if (changes.currentPage && changes.currentPage.currentValue !== $ctrl.activePage) {
                initPages();
            }
        };
        $ctrl.$onDestroy = function() {};

        function initPages() {
            $ctrl.pageCount = Math.ceil($ctrl.total / $ctrl.step);
            $ctrl.counter = 0;
            if ($ctrl.pages.length) {
                $ctrl.pages.splice(0);
            }
            for (var i = 0; i < $ctrl.pageCount; i++) {
                $ctrl.pages.push(i);
            }
            $ctrl.displayedPages = $ctrl.pages.slice(
                0,
                $ctrl.displayedPagesCount
            );
            $ctrl.lastPage = $ctrl.pages[$ctrl.pages.length - 1];
            setPagintation();
        }

        function goToPrev() {
            if ($ctrl.counter > 0) {
                $ctrl.counter--;
                setPagintation();
                $ctrl.changePagination({ offset: $ctrl.activePage });
            }
        }

        function goToNext() {
            if ($ctrl.counter < $ctrl.pageCount - 1) {
                $ctrl.counter++;
                setPagintation();
                $ctrl.changePagination({ offset: $ctrl.activePage });
            }
        }

        function setPagintation() {
            $ctrl.activePage = $ctrl.pages[$ctrl.counter];
            if (!_.includes($ctrl.displayedPages, $ctrl.activePage)) {
                $ctrl.displayedPages = $ctrl.pages.slice(
                    $ctrl.counter,
                    $ctrl.counter + $ctrl.displayedPagesCount
                );
            }
        }

        function selectPage(page) {
            $ctrl.counter = page;
            $ctrl.activePage = page;
            $ctrl.changePagination({ offset: $ctrl.activePage });
        }
    }
})();

'use strict';

import { refreshData } from '@shared/data';

angular.module('smartApp').controller('LoginController', function ($filter, $state, $rootScope, $scope, Principal, Auth, localStorageService, ChecklistEditor, NotificationService, SystemParamsService, Notifications, config, ThrobberService) {
    $scope.remember = true;
    $scope.passInputType = 'password';
    $scope.userName = '';
    $scope.pwdMinLen = 4;
    $scope.errorMessage = '';


    var loginDomain = '';
    $scope.projectName = '';

    var loginInfo = localStorageService.get('loginInfo');

    if (localStorageService.get('token')) {
        if (Principal.isInAnyRole([
                'ROLE_SUPERVISOR',
                'ROLE_CURATOR',
                'ROLE_SUPER_SUPERVISOR'
            ])) {
            $state.go('home');
        } else if (Principal.isInRole('ROLE_DIRECTOR')) {
            $state.go('home');
        } else if (Principal.isInRole('ROLE_ADMIN') || Principal.isInRole('ROLE_DIRECTOR_CLUSTER')) {
            $state.go('start');
        }else if (Principal.isInAnyRole([
                'ROLE_OPERATION_DIRECTOR',
                'ROLE_TASK_MANAGER'
            ])) {
            $state.go('tasklist');
        }
    } else if (loginInfo) {
      $scope.userName = loginInfo.userName;
      $scope.password = loginInfo.password;
    }

    SystemParamsService.getSystemProps(['login.domain', 'web.projectName'], function(err, params){

        if(err){
            Notifications.danger('Ошибка получения данных');
            console.log("Err: ", err);
            return
        }
        // тикет: FPR-1390
        /*if (params['login.domain']) {
            if (params['login.domain'][0] !== '@') {
                loginDomain = '@' + params['login.domain'];
            } else if (params['login.domain'][0] === '@') {
                loginDomain = params['login.domain'];
            }
        }*/

        $scope.projectName = params['web.projectName'];
    });

    $scope.togglePasswordInputType = function() {
      var types = ['password', 'text'];
      types.splice(types.indexOf($scope.passInputType), 1);
      $scope.passInputType = types[0];
    };

    $scope.showInvalidEmail = function(){
        if(!$scope.mail)return false
        if($scope.mail.length < 3){return false}else{
            return !/^.+@.+$/.test($scope.mail)
        }

    }

    $scope.changePassword = function(){

        Auth.passwordChange($scope.newPwd, $scope.confPwd, $scope.mail).then(function(data){
            Notifications.success("Пароль успешно изменён");
            afterLogin();
        });
    }

    $scope.pwdRestore = function(){
        if(!$scope.userName){
            Notifications.danger("Необходимо заполнить поле &laquo;Логин&raquo;");
            return;
        }
        var userName = $scope.userName
        if ($scope.userName.indexOf('@') === -1 && loginDomain) {
            userName += loginDomain;
        }
        Auth.passwordRestore(userName).then(function(data){
            Notifications.success("Временный пароль отправлен на почту заведующему магазином");
        });

    }

    $scope.isSubmitDisabled = function(){
        if(!$scope.newPwd)return true
        return !($scope.newPwd == $scope.confPwd && $scope.newPwd.length >= $scope.pwdMinLen && /^.+@.+$/.test($scope.mail))
    }

    var afterLogin = async function() {
        $scope.authenticationError = false;

        ThrobberService.showThrobber();
        await refreshData();
        ThrobberService.hideThrobber();

        if (config.isMobile) {
          $state.go('downloadApp');
        } else if (Principal.isInAnyRole(["ROLE_SUPERVISOR", "ROLE_CURATOR", "ROLE_SUPER_SUPERVISOR"])) {
            $state.go('home');
        } else if (Principal.isInRole("ROLE_DIRECTOR")) {


            $state.go('home');
        } else if (Principal.isInRole("ROLE_ADMIN") || Principal.isInRole("ROLE_DIRECTOR_CLUSTER")) {
            $state.go('start');
        }else if (Principal.isInAnyRole(['ROLE_OPERATION_DIRECTOR', 'ROLE_TASK_MANAGER'])) {
            $state.go("tasks");
        }else{
              $state.go('home');
        }
    }



    $scope.login = function() {
        $scope.errorMessage = '';

        //alert(usernamePostfix)
        var _index = $scope.userName.indexOf('@');
        var userName = $scope.userName;
        if (userName) { // && userName.match(/^[A-z\u00C0-\u00ff]+$/g)
            if (_index === -1 && loginDomain) {
                userName += loginDomain;
            }
            Auth.login({
                username: userName,
                password: $scope.password
            }).then(function (arr) {
                var data = arr[0];
                var user = arr[1];

                if ($scope.remember) {
                  localStorageService.set('loginInfo', JSON.stringify({
                    userName: userName,
                    password: $scope.password
                  }));
                } else {
                  localStorageService.set('loginInfo', '');
                }

                if(user.needChangePassword){
                    $scope.showChangePass = true
                    return
                }
                afterLogin()


            }, function(e){
	            if(!!~[502, 504, 0].indexOf(e.status)) {
		            $scope.authenticationError = false;
		            $scope.wrongNetwork = false;
		            $scope.connectionError = true;
	            } else {
		            if(e.status === 400 && e.headers('x-error') === 'EXA3') {
			            $scope.wrongNetwork = true;
			            $scope.connectionError = false;
			            $scope.authenticationError = false;
		            } else {
			            $scope.connectionError = false;
			            $scope.wrongNetwork = false;
			            $scope.authenticationError = true;
		            }
	            }
		        Notifications.danger('Ошибка авторизации');
            });
        } else {
            $scope.authenticationError = true;
            Notifications.danger('Ошибка авторизации')
        }
    };

    $scope.loginKeycloak = function() {
        Auth.loadSSOConfig().then(ssoConfig => {
            Auth.oidcRedirect(ssoConfig.oidc);
        }).catch(() => {
            Notifications.danger('Произошла ошибка. Пожалуйста, повторите попытку позже');
        });
    };

    function init() {
        const oidcResult = localStorageService.get('oidc:result');

        if (oidcResult) {
            if (oidcResult.code) {
                Auth.loginOIDC(oidcResult.code)
                    .then(() => {
                        localStorageService.remove('oidc:result');
                        window.location.href = '/';
                    })
                    .catch(res => {
                        localStorageService.remove('oidc:result');

                        if (res && res.headers('x-error-message') === 'User not found in fpa') {
                            $scope.errorMessage = 'Отсутствует доступ к системе';
                        } else {
                            $scope.errorMessage = 'Произошла ошибка. Пожалуйста, повторите попытку позже';
                        }
                    });
            } else if (oidcResult.error || oidcResult.errorDescription) {
                console.log('oidc error', oidcResult.error);
                console.log('oidc error description', oidcResult.errorDescription);
                $scope.errorMessage = 'Произошла ошибка. Пожалуйста, повторите попытку позже';
            }
        }
    }

    init();
});

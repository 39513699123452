import axios from 'axios'
import xlsxMaker from "../../../helpers/excel";
export default {
    namespaced: true,
    state: {
        inventoryItems: [],
        inventoryPagination: {},
        inventoryFetchStatus: false,
        excelFetchStatus: false,
    },
    mutations: {
        setInventoryItems(state, payload) {
            state.inventoryItems = payload;
        },
        setInventoryPagination(state, payload) {
            state.inventoryPagination = payload;
        },
        setInventoryFetchStatus(state, payload) {
            state.inventoryFetchStatus = payload;
        },
        resetPagination(state) {
            state.inventoryPagination = {};
        },
        setExcelFetchStatus(state, payload) {
            state.excelFetchStatus = payload;
        },
    },
    actions: {
        fetchItems({ commit }, payload) {
            commit('setInventoryFetchStatus', true);
            return new Promise((resolve) => {
                axios.post(`admin/product/search`, {
                        ...payload,
                    },
                    {
                        headers: {

                            "access-token": JSON.parse(localStorage.getItem('ls.token')).token,

                        }
                    }).then(response => {
                    resolve(response);
                    commit('setInventoryItems', response.data.data);
                    commit('setInventoryPagination', response.data.paginator)
                }).catch(error => {
                    if (error.request.status === 400) {
                        commit('setInventoryItems', []);
                    }
                    console.error('Error', error);
                }).finally(() => {
                    commit('setInventoryFetchStatus', false)
                })
            })
        },
        fetchExcelExport({ commit }, payload) {
            commit('setExcelFetchStatus', true);
            return new Promise((resolve) => {
                axios.post(`/api/reports/stock/product/export-to-excel`, {
                    ...payload,
                }, {
                    responseType: 'blob',
                    headers: {
                        "x-auth-token": JSON.parse(localStorage.getItem('ls.token')).token,
                        "Content-Type": 'application/json',
                    }
                }).then(response => {
                    resolve(response);
                    xlsxMaker(response,'inventory-result');
                }).catch(error => {
                    console.error('Error', error);
                }).finally(() => {
                    commit('setExcelFetchStatus', false);
                })
            })
        },
    },
    getters: {
        getItems(state) {
            return state.inventoryItems;
        },
        getPagination(state) {
            return state.inventoryPagination;
        },
        getItemsFetchStatus(state) {
            return state.inventoryFetchStatus;
        },
        getExcelFetchStatus(state) {
            return state.excelFetchStatus;
        },
    },
};
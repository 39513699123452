angular.module('smartApp').controller('SettingsNotificationsController', function(PanelUsersService, moment, ngDialog, TWTemplateService, Notifications, NotificationsSettingsService, $scope, OrgstructService, $filter) {
	$scope.notifications = {};
	$scope.calendarsOpen = [];

	$scope.countRecepientsToDelete = function(notif){
		if(!notif.recipients) return 0
		return notif.recipients.filter(function(el){return el.toDelete}).length
	}

	$scope.open = function($event, id) {
		$event.preventDefault();
		$event.stopPropagation();

		for(var i in $scope.calendarsOpen){
			$scope.calendarsOpen[i] = false
		}

		$scope.calendarsOpen[id] = true;
	};
	
	var notification = function(type, name, funcObj){

		var switchStatus = function(){
			var notif = this;
			this.loading=true;
			NotificationsSettingsService.switchStatus(notif.type, notif.enabled, function(err, data){
				notif.loading=false;
				if(err){
					console.log(err, data);
					Notifications.danger("Ошибка смены статуса настройки уведомления «" + notif.name + "»");
					return;
				}

				Notifications[((notif.enabled)?"success":"warning")]("Уведомление успешно в" + ((notif.enabled)?"":"ы") + "ключено");
			})
		}



		var addRecipients = function(){
			this.loading=true;
			var _this = this;
			NotificationsSettingsService.addRecipients(this.type, this.users.map(function(el){return el.id}), function(err,data){
				_this.loading=false;
				
				if(err){
					console.log(err, data);
					Notifications.danger("Ошибка добавления получателей")
					return;
				}

				_this.recipients = _this.recipients.concat(_this.users)
				_this.users = []
				
			})
		}

		var removeRecipients = function(){

			this.loading=true;
			var _this = this;
			NotificationsSettingsService.removeRecipients(this.type, this.recipients.filter(function(el){return el.toDelete}).map(function(el){return el.id}), function(err,data){
				_this.loading=false;
				
				if(err){
					console.log(err, data);
					Notifications.danger("Ошибка удаления получателей")
					return;
				}
				_this.recipients = _this.recipients.filter(function(el){return !el.toDelete})
				
			})
		}

 

		var sendOne = function(textToSend){
			var notif = this		
					
			var cb = function(err, data){
				
				notif.loading = false;
				if(err){
					if(err == 400){
						Notifications.danger("У пользователя "+notif.mailToLogin+" не найден e-mail")
						return
					}

					if(err == 204){
						Notifications.warning("Нечего отправлять")
						return
					}

					if(err == 406){
						Notifications.warning("Не найден логин получателя")
						return
					}
					
					Notifications.danger("Ошибка отправки письма")
					return;
				}
				Notifications.success("Письмо успешно отправлено");
			}

			ngDialog.open({
			 	template: 'confirmOneSend',
	            controller: ['$scope', function($scope) {
	            	$scope.notificationName = notif.name;
					$scope.user = notif.mailToLogin;
					$scope.specialMail = notif.specialMail
	            	$scope.send=function(){
	            		notif.loading=true;

	            		if(notif.type=="testSend"){
	            			NotificationsSettingsService.sendTestMail(notif.text, notif.specialMail, cb)
	            			return;	
	            		}
						NotificationsSettingsService.sendMail(notif.type, notif.mailToLogin, notif.specialMail, textToSend, cb)
	            	}
					
				}],	            
	            className: 'ngdialog-theme-plain dialog',
	            showClose: false
			})
		}

		var sendAll = function(){
			var notif = this		
			var cb = function(err, data){
				notif.loading = false;
				if(err){
                    console.log(err, data);
					if(err == 204){
                        Notifications.warning("Нет событий, о которых надо уведомлять");
					}else {
                        Notifications.danger("Ошибка отправки писем")
					}
					return;
				}
				Notifications.success("Письма успешно отправлены");
			}
			ngDialog.open({
			 	template: 'confirmMassSend',
	            controller: ['$scope', function($scope) {
	            	$scope.notificationName = notif.name;

	            	$scope.send=function(){
	            		notif.loading=true;
						NotificationsSettingsService.sendMail(notif.type, undefined, undefined, cb)
	            	}
					
				}],	            
	            className: 'ngdialog-theme-plain dialog',
	            showClose: false
			})			
			
		}

        var userSearch = function(search){
            var selectedUsers = [];
            var _this = this;
            _this.foundUsers = [];
            if(search && search.length >= 2){
                PanelUsersService.getUsersFiltered(1, 1000, {login:search}).success(function (result, status, headers) {
                    if(status === 200){
                    	if (_this.users && _this.users.length > 0){
                            selectedUsers = angular.copy(_this.recipients.concat(_this.users));
						}else{
                            selectedUsers = angular.copy(_this.recipients);
						}
                        if (selectedUsers && selectedUsers.length !== 0) {
                            _this.foundUsers = result.filter(function (elem) {
                                return (!(selectedUsers.map(function(el){return el.id}).indexOf(elem.id) !== -1))
                            });
                            if(_this.foundUsers.length === 0){
                                Notifications.warning("Соответствий больше не найдено")
                            }
                        } else {
                            _this.foundUsers = result;
                        }
                    }else if(status === 204){
                        Notifications.warning('Пользователь не найден');
                    }else{
                        Notifications.danger('Ошибка поиска пользователя');
                    }
                });
            }
        };

		var returnObj = {
			type: type,
			name: name,
			enabled: false,
            foundUsers: [],
            mailToLogin: "",
            specialMail: ""
			//getRecipients: getRecipients
		}

		if(type == "testSend"){
			returnObj =  Object.assign(returnObj, {
				sendOne: sendOne,
			})
			return returnObj;
		}

		NotificationsSettingsService.getOne(type, function(err, data){
	 		if(funcObj)returnObj = Object.assign(returnObj, funcObj);
	 		if(err){
	 			console.log(err, data)
	 			Notifications.danger("Ошибка подгрузки данных");
	 			returnObj["error"] = "Не подгрузились с сервера: " + data
	 			return
	 		}
	 		returnObj = Object.assign(returnObj, data);
	 		
	 		if(returnObj.onStart)returnObj.onStart();
	 		
	 		
	 		
	 		returnObj =  Object.assign(returnObj, {
				
				switchStatus: switchStatus,
				sendOne: sendOne,
				sendAll: sendAll,
				addRecipients: addRecipients,
				removeRecipients: removeRecipients,
                userSearch: userSearch
			})
	 	})

	 	NotificationsSettingsService.getRecipients(type, function(err,data){
			if(err){
				console.log(err, data);
				Notifications.danger("Ошибка загрузки получателей")
				return;
			}
			returnObj.recipients = data
			
		})
		return returnObj
		
	}
	var lowGradesExtraFuncs = {
		settings: {},
		settingsLoaded: {},
		saveSettings: function(){
			var _this = this
			this.loading = true;
			NotificationsSettingsService.saveLowGradesSettings(this.settings, function(err, data){
				_this.loading = false;
				if(err){
					Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»")
					console.log(err, data)
				}
				Notifications.success("Успешно изменено уведомление «"+ _this.name +"»");
				_this.settingsLoaded = angular.copy(_this.settings)
			})

		}, 
		onStart: function(){
			//this.getSettings()
			var _this = this;
			this.loading = true;
			_this.businessDirection = $scope.businessDirections[0];
            _this.setBusinessDirection(_this.businessDirection);
		},
        setBusinessDirection: function (bd){
            var _this = this;
            if(!bd) {
                _this.onStart();
                return;
            }
            _this.businessDirection = bd;
            var reqParams ={
                businessDirId: bd.id
            };
            NotificationsSettingsService.getLowGradesSettings(reqParams,function(err, data){
				if(err){
					Notifications.danger("Ошибка загрузки настроек уведомления «"+ _this.name +"»")
					console.log(err, data)
				}
				_this.loading = false;
				_this.settings = data;
				_this.settingsLoaded = angular.copy(data);
			})
		},
		getSelectedBlocksNum: function(){
			if(!this || !this.settings || this.settings.length == 0 || Object.keys(this.settings).length == 0)return undefined;
			return this.settings.filter(function(el){return el.toDelete}).length
		},
		removeSelectedBlocks: function(){
			var _this = this;
			ngDialog.open({
			 	template: 'removeBlocksFromLowGrades',
	            controller: ['$scope', function($scope) {
	            	

	            	$scope.blocks = _this.settings.filter(function(el){return el.toDelete})
	            	$scope.delete = function(){
	            		_this.loading = true;
                        var reqParams ={
                            businessDirId: _this.businessDirection.id
                        };
	            		NotificationsSettingsService.removeLowGradesBlocks(reqParams, _this.settings.filter(function(el){return el.toDelete}).map(function(el){return el.id}), function(err, data){
							_this.loading = false;
							if(err){
								Notifications.danger("Ошибка исключения блоков из рассылки уведомления «"+ _this.name +"»")
								console.log(err, data)
								return;
							}
							_this.setBusinessDirection(_this.businessDirection);
							
						})
	            	}
					
				}],	            
	            className: 'ngdialog-theme-plain dialog',
	            showClose: false
			 })
		},
		addBlocksToLowGrades:function(){
			var _this = this;
			this.loading = true;
            var reqParams ={
                businessDirId: _this.businessDirection.id
            };
			NotificationsSettingsService.getAvailableLowGradesBlocks(reqParams, function(err, data){
				_this.loading = false;
				if(err){
					Notifications.danger("Ошибка получения доступных блоков для рассылки уведомления «"+ _this.name +"»")
					console.log(err, data)
					return;
				}
				var availableBlocks = data;
				ngDialog.open({
				 	template: 'addBlocksToLowGrades',
		            controller: ['$scope', function($scope) {		            	
		            	$scope.blocks = availableBlocks;
		            	$scope.getSelectedNum = function(){
		            		return $scope.blocks.filter(function(el){return el.toAdd}).length
		            	}

		            	$scope.addBlocks = function(){
		            		_this.loading = true;
                            var reqParamsAdd ={
                                businessDirId: _this.businessDirection.id
                            };
		            		NotificationsSettingsService.addLowGradesBlocks(reqParamsAdd, $scope.blocks.filter(function(el){return el.toAdd}).map(function(el){return el.id}), function(err, data){
								_this.loading = false;
								if(err){
									Notifications.danger("Ошибка добавления блоков в рассылки уведомления «"+ _this.name +"»")
									console.log(err, data)
									return;
								}
								_this.setBusinessDirection(_this.businessDirection);
								
							})
		            	}
						
					}],	            
		            className: 'ngdialog-theme-plain dialog',
		            showClose: false
				 })
			})
		},
	}

	var NDaysWOCheckExtraFuncs = {
		settingsServer: [],
		settings: {},
		settingsLoaded: {},
        settingsVal: [],
		getSettings: function(templates){
			if(templates.length == 0){
				this.settings = {};
				return
			}
			var _this = this;
            _this.settingsServer = [];
            var getId = function(el){return el.id}
            this.loading = true;
            NotificationsSettingsService.getNotCheckedSettings(templates.map(getId), function(err, data){
                _this.loading = false;
                if(err){
                    Notifications.danger('Произошла ошибка в загрузке данных!');
                    return;
                }
                _this.settingsServer = data;
                if(!$scope.levels){
                    return;
                }
                var answer = {};

					angular.forEach( data[0].businessDirs, function (bdValue) {
					angular.forEach($scope.levels, function (level) {
                     	if(level.value === "SHOP"){
                            return;
						}
						if(!answer[level.value])answer[level.value] = {name: level.value, entities: []};
						if (angular.isDefined(bdValue.levelSettings[level.value]) && bdValue.levelSettings[level.value] !== null ){
						answer[level.value].entities.push({businessDir: bdValue.businessDirId, value: bdValue.levelSettings[level.value]})
						}else{
                        	answer[level.value].entities.push({businessDir: bdValue.businessDirId, value: null});
                        }
                    });
                });
                _this.settings = answer;
                _this.settingsLoaded = angular.copy(answer)
            })
        },
		saveStartDate:function(){
			var _this = this;
			this.loading = true;
			this.levels["ALL"].startDate = new Date(this.levels["ALL"].startDate).getTime()
			NotificationsSettingsService.saveNotChecked({enabled: this.enabled, levels: this.levels}, function(err, data){
				_this.loading = false;
				if(err){
					Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»")
					console.log(err, data)
					return;
				}
				Notifications.success("Успешно изменено уведомление «"+ _this.name +"»");
				_this.settingsLoaded = angular.copy(_this.settings)
			})

		},
		saveSettings: function(level, bd, value, index){
			var _this = this;
			this.loading = true;

			for(var i in this.selectedTemplates){
				for(var j in this.settingsServer[i].businessDirs){
					var curBd = this.settingsServer[i].businessDirs[j]
					if(curBd.businessDirId == bd){
						curBd.levelSettings[level] = +value;
					}
				}
			}
			NotificationsSettingsService.saveNotCheckedSettings(this.settingsServer, function(err, data){
				_this.loading = false;
				if(err || (data.status && data.status !== 200)){
					Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»");
					//console.log(err, data)
                    _this.settings[level]['entities'][index].value = _this.settingsLoaded[level]['entities'][index].value;
					return;
				}
                _this.settingsLoaded[level]['entities'][index].value = value;
				Notifications.success("Успешно изменено уведомление «"+ _this.name +"»");
			})



		},
		templates:[],
		selectedTemplates: [],
		getTemplates: function(cb){
			TWTemplateService.getAllWithParams(undefined, cb)
		},
		onStart: function(){
			//this.getSettings()
			var _this = this
			this.loading = true;
			this.getTemplates(function(err, data){
				_this.templates = data
				delete _this.getTemplates;
				//_this.selectedTemplates[0] = _this.templates[0];
				_this.loading = false;
			})

			delete _this.onStart;

		}
	}

	var missplannedExtraFuncs = { 
		saveSettings: function(){
			var _this = this
			this.loading = true;
			for(level in this.levels){
				this.levels[level].startDate = new Date(this.levels[level].startDate).getTime()
			}


			NotificationsSettingsService.saveMissplannedSettings({enabled: this.enabled, levels: this.levels}, function(err, data){
				_this.loading = false;
                if(err || (data.status && data.status !== 200)){
					Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»")
					console.log(err, data)
				}
				Notifications.success("Успешно изменено уведомление «"+ _this.name +"»");
				_this.settingsLoaded = angular.copy(_this.settings)
			})


		},
		
		selectDayInMonth: function(level){
			var _this = this
			ngDialog.open({
			 	template: 'monthDayChooser',
	            controller: ['$scope', function($scope) {
	            	var maxDays = 31;
	            	var daysInRow = 7;
	            	$scope.days = [];
	            	$scope.selectedDay = undefined;
	            	$scope.selectDay = function(day){
	            		$scope.selectedDay = day
	            	}
	            	for (var i = 0; i < Math.ceil(maxDays/daysInRow); i++){
	            		var row = [];
	            		for(var j = 0; j < daysInRow; j++){
	            			var dayN = i*daysInRow+j+1
	            			row.push((dayN<=maxDays)?dayN:undefined)
	            		}	            		
	            		$scope.days.push(row)
	            	}
	            	$scope.send = function(){
                        _this.levels[level].period.type = "MONTHLY";
	            		_this.levels[level].period.dayOfMonth = $scope.selectedDay
	            		_this.saveSettings()
	            	};

                    $scope.lastDayOfMonth = function(){
                        _this.levels[level].period.type = "MONTHS_LAST_DAY";
                        delete _this.levels[level].period.dayOfMonth;
                        _this.saveSettings()
                    }
					
				}],	            
	            className: 'ngdialog-theme-plain dialog',
	            showClose: false
			 })
		},
		onStart: function(){

			// var _this = this
			// console.log(this)
			// for(level in this.levels){
			// 	this.levels[level].startDateFormatted = new Date(this.levels[level].startDate)
			// }
			
 
			//delete _this.onStart;
			
		}

	}

    var performedAuditExtraFuncs = {
        saveSettings: function(){
            var _this = this
            this.loading = true;
            for(level in this.levels){
                this.levels[level].startDate = new Date(this.levels[level].startDate).getTime();
                if(this.levels[level].period.type === "DAILY"){
                	delete this.levels[level].period.daysOfWeek;
                    delete this.levels[level].period.dayOfMonth;
				}else if(this.levels[level].period.type === "WEEKLY"){
                    delete this.levels[level].period.dayOfMonth;
                    if(!this.levels[level].period.daysOfWeek || this.levels[level].period.daysOfWeek.length === 0){
                    	Notifications.warning("Невозможно изменить. Необходимо выбрать хотя бы один день недели для уровня " +  $filter('lowercase')($filter('notificationsSettingsLevels')(level) +"а"), '', 4000);
                        this.loading = false;
                    	return;
					}
				}else if(this.levels[level].period.type === "MONTHLY"){
                    delete this.levels[level].period.daysOfWeek;
                    if(!this.levels[level].period.dayOfMonth){
                        Notifications.warning("Невозможно изменить. Необходимо выбрать день месяца для уровня " +  $filter('lowercase')($filter('notificationsSettingsLevels')(level) +"а"), '', 4000);
                        this.loading = false;
                        return;
					}
				}
            }

            NotificationsSettingsService.savePerformedAuditSettings({enabled: this.enabled, levels: this.levels}, function(err, data){
                _this.loading = false;
                if(err || (data.status && data.status !== 200)){
                    Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»");
                    _this.settings = angular.copy(_this.settingsLoaded);
                    return;
                }
                Notifications.success("Успешно изменено уведомление «"+ _this.name +"»");
                _this.settingsLoaded = angular.copy(_this.settings)
            })

        },
        selectDayInMonth: function(level){
            var _this = this
            ngDialog.open({
                template: 'monthDayChooser',
                controller: ['$scope', function($scope) {
                    var maxDays = 31;
                    var daysInRow = 7;
                    $scope.days = [];
                    $scope.selectedDay = undefined;
                    $scope.selectDay = function(day){
                        $scope.selectedDay = day
                    };
                    for (var i = 0; i < Math.ceil(maxDays/daysInRow); i++){
                        var row = [];
                        for(var j = 0; j < daysInRow; j++){
                            var dayN = i*daysInRow+j+1;
                            row.push((dayN<=maxDays)?dayN:undefined)
                        }
                        $scope.days.push(row)
                    }
                    $scope.send = function(){
                        _this.levels[level].period.type = "MONTHLY";
                        _this.levels[level].period.dayOfMonth = $scope.selectedDay;
                        delete _this.levels[level].period.daysOfWeek;
                        _this.saveSettings()
                    };
                    $scope.lastDayOfMonth = function(){
                        _this.levels[level].period.type = "MONTHS_LAST_DAY";
                        delete _this.levels[level].period.daysOfWeek;
                        delete _this.levels[level].period.dayOfMonth;
                        _this.saveSettings()
                    }
                }],
                className: 'ngdialog-theme-plain dialog',
                showClose: false
            })
        },
        selectDayOfWeek: function(level, day, value){
            var _this = this;
			if(!_this.levels[level].period.daysOfWeek){
                _this.levels[level].period.daysOfWeek = [];
			}
            if(value === true){
                _this.levels[level].period.daysOfWeek.push(day);
			}else{
                var idX = _this.levels[level].period.daysOfWeek.indexOf(day);
				idX > -1 ? _this.levels[level].period.daysOfWeek.splice(idX, 1) : Notifications.danger("Ошибка изменения настроек уведомления «"+ _this.name +"»");
			}

            _this.saveSettings();
        },
        onStart: function(){
            var _this = this;
            this.selectDay = this;
        },
        daysWeek: [{name: "MONDAY", enabled: true}, {name: "TUESDAY", enabled: true}, {name: "WEDNESDAY", enabled: true}, {name: "THURSDAY", enabled: true}, {name: "FRIDAY", enabled: true}, {name: "SATURDAY", enabled: true}, {name: "SUNDAY", enabled: true}],
		selectDay: []
    };

    var notificationsList = {
    	testSend: ["тестовая отправка", undefined],
		taskCreate: ["Создание задачи", undefined],
		taskClose: ["Закрытие задачи", undefined],
		taskReturn: ["Возвращение задачи", undefined],
		lostTasks: ["о просроченных задачах", undefined],
        performedAudit: ["о проведенных проверках", performedAuditExtraFuncs],
		lowGrades: ["о низких оценках СМ", lowGradesExtraFuncs],
		DDChecks: ["ДД о проверках по чек-листу ДД", undefined],
		NDaysWOCheck: ["о СМ, которые не проверялись более N дней", NDaysWOCheckExtraFuncs],
		missplanned: ["о недопланировании проверок на будущий месяц", missplannedExtraFuncs],
		formTaskSupervisor: ["Отправлять письмо проверяющему", undefined],
		formTaskDir: ["Отправлять письмо директору магазина", undefined]
	}

	
	OrgstructService.getLevels().then(function(result){
		if(result.status == 200){
            $scope.levels = result.data;
        }else{
            Notifications.danger("Не удается загрузить уровни");
		}
    });

    OrgstructService.getBusinessDirs({sort: 'name,desc'}).then(function(result){
        if(result.status == 200){
            $scope.businessDirections = result.data;
        }else {
            Notifications.danger("Не удается загрузить бизнес-направления");
		}
    });

    for(i in notificationsList){
		$scope.notifications[i] = new notification(i, notificationsList[i][0], notificationsList[i][1])
	}

	// NotificationsSettingsService.getList($scope.notifications, function(err, list){
	// 	console.log(list)
	// })

	//console.log($scope.notifications)
});
angular.module('smartApp').factory('ChecklistService', function ChecklistService($http, config, HttpUtils) {
	var checklists = null,
		format = 'DD-MM-YYYY';
    	formatRevert = 'YYYY-MM-DD';
	return {

		getQuestionPhotos: function(questionId, checklistId, cb){
			$http.get(config.backend + '/photoService/supervisorWeb?checklistId=' + checklistId + '&questionId='+ questionId, {noThrobber:true})
				.success(function(images, status) {
					cb((status == 200 || status == 204)?null:status, images || []);
				}
			);
		},

		getQuestionDirectorPhotos: function(questionId, checklistId, cb){
			$http.get(config.backend + '/photoService/directorWeb?checklistId=' + checklistId + '&questionId='+ questionId, {noThrobber:true})
				.success(function(images, status) {
					cb((status == 200 || status == 204)?null:status, images || []);
				}
			);
		},

		getChecklistPhotos: function(checklistId, cb){
			async.parallel([
	            function(callback){
	            	$http.get(config.backend + '/photoService/checklistSupervisor?checklistId=' + checklistId).success(function(images, status) {
	            		callback((status==200 || status == 204)?null:status, images);
	            	})
	            },
	            function(callback){
	               	$http.get(config.backend + '/photoService/checklistDirector?checklistId=' + checklistId).success(function(images, status) {
	            		callback((status==200 || status == 204)?null:status, images);
	            	})
	            }
	        ],
	        function(err, results){
	            if(err){
	            	console.log(err);
					cb(err, results)
	            	return;
	            }
	            var ans = [];
	            for(var i in results){
	            	for(var j in results[i]){
	            		ans.push({type:"Фото "+((i == 0)?"проверяющего":"директора"), url:results[i][j]})
	            	}
	            }
	            cb(null, ans)
		        // results[0] = results[0].map(function(item){item.name="Фото проверяющего"; return item;})
		        // results[1] = results[1].map(function(item){item.name="Фото директора"; return item;})
		        // cb(null, results[0].concat(results[1]))
	        });


		},

		delPhoto: function(checklistId, questionId, uid) {
			var params = {
				checklistId: checklistId,
				questionId: questionId,
				photoId: uid
			};

			return $http.delete(config.backend + '/photoService/delete', { params: params });
		},

		getHRReport: function (filters) {
			var params = {
				month: filters.month
			};
			var query = HttpUtils.getQuery('/files/getHRReport', params);
			return $http.get(query);
		},

		getAccreditationChecklistReport: function(filters) {
			var params = {
				fromDate: moment(filters.dateFrom).format(format),
				toDate: moment(filters.dateTo).format(format),
				shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
				clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
				divisionIds: filters.division && filters.division.map(function(e){return e.id}),
				resolverIds: filters.fio && filters.fio.map(function(e){return e.id}),
				checklistId: filters.checklistId,
				templateIds: filters.template && filters.template.id
			};
			var query = HttpUtils.getQuery('/auditor/getAccreditationChecklistReport', params);
			return $http.get(query);
		},

		getAccreditationCategoryReport: function(filters) {
			var params = {
				fromDate: moment(filters.dateFrom).format(format),
				toDate: moment(filters.dateTo).format(format),
				shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
				clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
				divisionIds: filters.division && filters.division.map(function(e){return e.id}),
				resolverIds: filters.fio && filters.fio.map(function(e){return e.id}),
				checklistId: filters.checklistId,
				templateIds: filters.template && filters.template.id
			};
			var query = HttpUtils.getQuery('/auditor/getAccreditationCategoryReport', params);
			return $http.get(query);
		},

		getGreenReport: function (filters) {
			var params = {
				queryFromDate: moment(filters.rangeFrom).format(format),
				queryToDate: moment(filters.rangeTo).format(format),
				queryProcesses: filters.process && filters.process.map(function(e){return e.id}),
				queryQuestions: filters.question && filters.question.map(function(e){return e.id}),
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
				queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
				queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
				queryRoles: filters.role && filters.role.map(function(e){return e.name}),
				queryTemplates: [filters.template.id],
				checklistType: filters.plan
			};
			var query = HttpUtils.getQuery('/files/getGreenReport', params);
			return $http.get(query);
		},

		getVioletReport: function (filters) {
			var params = {
				queryFromDate: moment(filters.rangeFrom).format(format),
				queryToDate: moment(filters.rangeTo).format(format),
				queryProcesses: filters.process && filters.process.map(function(e){return e.id}),
				queryQuestions: filters.question && filters.question.map(function(e){return e.id}),
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
				queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
				queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
				queryRoles: filters.role && filters.role.map(function(e){return e.name}),
                queryPositions: filters.position,
				queryTemplates: [filters.template.id]
			};
			var query = HttpUtils.getQuery('/files/getVioletReport', params);
			return $http.get(query);
		},
		getOrangeReport: function (filters) {
			var params = {
				queryFromDate: moment(filters.rangeFrom).format(format),
				queryToDate: moment(filters.rangeTo).format(format),
				queryTemplates: [filters.template.id],
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
				checklistType: filters.plan
			};
			var query = HttpUtils.getQuery('/files/getOrangeReport', params);
			return $http.get(query);
		},
		getLightBlueReport: function (filters) {
			var params = {
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
				queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
				queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
				queryRoles: filters.role && filters.role.map(function(e){return e.name})

			};
			var query = HttpUtils.getQuery('/files/getLightBlueReport', params);
			return $http.get(query);
		},

		getLightGreyReport: function (filters) {
			var params = {
				queryFromDate: moment(filters.rangeFrom).format(format),
				queryToDate: moment(filters.rangeTo).format(format),
				queryTemplates: [filters.template.id],
				queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
				queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
				queryProcesses: filters.process && filters.process.map(function(e){return e.id}),
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryRoles: filters.role && filters.role.map(function(e){return e.name})

			};
			var query = HttpUtils.getQuery('/files/getLightGreyReport', params);
			return $http.get(query);
		},

		getXreport1: function (filters) {
			tRegionIds = [];
			for(var i in filters.regionIds){
				tRegionIds.push(filters.regionIds[i].id)
			}

			var tDivisionIds = [];
			for(var i in filters.divisionIds){
				tDivisionIds.push(filters.divisionIds[i].id)
			}

			var params = {
				date: moment(filters.month).format(format),

				//groupUser: filters.groupUser,
				divisionIds: tDivisionIds,
				userIds: (filters.fio)?filters.fio.map(function(e){return e.id}):undefined,
				regionIds: tRegionIds,
				groupUser: filters.groups && filters.groups.map(function(e){return e.id}),

			};
			console.log( filters)
			var query = HttpUtils.getQuery('/reports/report1', params);
			return $http.get(query);
		},

		getXreport2: function (filters) {
			var tUserIds = [];
			for(var i in filters.usersArr){
				tUserIds.push(filters.usersArr[i].id)
			}
			var shopSAPs = [];
			for(var i in filters.shop){
				shopSAPs.push(filters.shop[i].id)
			}

			var params = {
				reportMonth: moment(filters.month).format("M"),
				reportYear: moment(filters.month).format("YYYY"),
				userIds: tUserIds,
				shopIds:shopSAPs,
				templateIds:(filters.checklist)?[filters.checklist.id]:undefined
			};
			var query = HttpUtils.getQuery('/reports/report2', params);
			return $http.get(query);
		},
		getXreport3: function (filters) {
			var tUserIds = [];
			for(var i in filters.userIds){
				tUserIds.push(filters.userIds[i].id)
			}
			var tRegionIds = filters.regionIds ? filters.regionIds.map(i => i.id) : [];
			var tDivisionIds = filters.divisionIds ? filters.divisionIds.map(i => i.id) : [];

            var tBusinessDirIds = [];
            for(var i in filters.businessDirIds){
                tBusinessDirIds.push(filters.businessDirIds[i].id)
            }

			var sapList = [];
			for(var i in filters.shop){
				sapList.push(filters.shop[i].id)
			}

			var params = {
				fromDate: moment(filters.rangeFrom).format('YYYY-MM-DD'),
				toDate: moment(filters.rangeTo).format('YYYY-MM-DD'),
				groupId: filters.group ? filters.group.id : undefined,
				userIds: tUserIds,
				regionIds: tRegionIds,
				divisionIds: tDivisionIds,
				shopIds: sapList,
				templateIds: (filters.checklist)?[filters.checklist.id]:undefined,
				checklistType: filters.plan,
				dateType: filters.dateType,
				checklistIds: filters.checklistIds && filters.checklistIds.split(",").map(function(item){
					return item.trim()
				}),
				page: filters.page,
				size: filters.perPage,
				businessDirIds: tBusinessDirIds
			};
					

			return $http.post('/api/reports/report3', params);
		},
		getXreport4: function (filters) {

			var sapList = [];
			for(var i in filters.shop){
				sapList.push(filters.shop[i].id)
			}

			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),
				userIds: (filters.fio)?filters.fio.map(function(e){return e.id}):undefined,
				shopIds: sapList,
				templateIds: filters.template && filters.template.map(function(e){return e.id}),
				processIds: filters.process && filters.process.map(function(e){return e.id}),
				divisionIds: filters.division.map(function(e){return e.id}),
				groupUser: filters.groups && filters.groups.map(function(e){return e.id}),
				dateType: filters.dateType,
				checklistIds: filters.checklistIds && filters.checklistIds.split(",").map(function(item){
  						return item.trim()
					})
			};
			var query = HttpUtils.getQuery('/reports/report4', params);
			return $http.get(query);
		},
		getXreport5: function (filters) {
			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),
				divisionIds: filters.divisionIds.map(function(e){return e.id}),
				templateIds: filters.templates.map(t => t.id),
				shopIds: filters.shops && filters.shops.map(function(e){return e.id}),
				status: filters.finishStatus,
				expiration: filters.missed,
				processIds: filters.process && filters.process.map(function(e){return e.id}),
				questionIds: filters.question && filters.question.map(function(e){return e.id}),
        		businessDirIds: filters.businessDirIds && filters.businessDirIds.map(function(e){return e.id}),
				page: filters.page,
				'per_page': filters.perPage
			};
			var query = HttpUtils.getQuery('/reports/report5', params);
			return $http.get(query);
		},
		getXreport6: function (filters) {
			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
				toDate: moment(filters.rangeTo).format(format),
				templateId: (filters.template)?filters.template.id:undefined,
				processIds: filters.process && filters.process.map(function(e){return e.id}),
				groupUser: filters.groups && filters.groups.map(function(e){return e.id}),
				userIds: (filters.fio)?filters.fio.map(function(e){return e.id}):undefined,
				deviations: (filters.percent)?filters.percent:undefined

			};
			var query = HttpUtils.getQuery('/reports/report6', params);
			return $http.get(query);
		},

		getXreport7: function (filters) {

			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),


			};
			var query = HttpUtils.getQuery('/reports/report7', params);
			return $http.get(query);
		},

		getXreport8CleanData: function(rangeFrom, rangeTill){
			var params= {
				fromDate: rangeFrom.format(format),
				toDate: rangeTill.format(format),
				page: 1,
				per_page: 20000,
			}
			var query = HttpUtils.getQuery('/reports/report5_new', params);
			return $http.get(query);
		},

		getXreport8: function (filters) {
			var sapList = [];
			for(var i in filters.shop){
				sapList.push(filters.shop[i].id)
			}console.log(filters)

			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),
				userIds: (filters.fio)?filters.fio.map(function(e){return e.id}):undefined,
				shopIds: sapList,
				templateIds: filters.template && filters.template.map(function(e){return e.id}),
				processIds: filters.process && filters.process.map(function(e){return e.id}),
				questionIds: filters.question && filters.question.map(function(e){return e.id}),
				divisionIds: filters.division.map(function(e){return e.id}),
				groupUser: filters.groups && filters.groups.map(function(e){return e.id}),
				page: filters.page,
				per_page: filters.perPage,
				dateType: filters.dateType,
				checklistIds: filters.checklistIds && filters.checklistIds.split(",").map(function(item){
  						return item.trim()
					})
			};

			var query = HttpUtils.getQuery('/reports/report5_new', params);
			return $http.get(query);
		},


		getXreport9: function (filters) {
			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),
				sqlGradeReportType: filters.gradeType,
				dateType: filters.dateType,
				shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
				templateIds: filters.template && filters.template.map(function(e){return e.id}),

				categoryIds: filters.categories && filters.categories.map(function(e){return e.id}),

				subCategoryIds: filters.subcategories && filters.subcategories.map(function(e){return e.id}),
				processIds: filters.processes && filters.processes.map(function(e){return e.id}),

				divisionIds: filters.division && filters.division.map(function(e){return e.id}),
				clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
				page: filters.page,
				per_page: filters.perPage,


			};

			var query = HttpUtils.getQuery('/reports/report_check_grade', params);
			return $http.get(query);
		},

		getXreport10: function (filters) {
			var params = {
				fromDate: moment(filters.rangeFrom).format(format),
				toDate: moment(filters.rangeTo).format(format),
				shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
				templateIds: filters.template && filters.template.map(function(e){return e.id}),
				divisionIds: filters.division && filters.division.map(function(e){return e.id}),
				clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
				page: filters.page,
				per_page: filters.perPage
			};

			var query = HttpUtils.getQuery('/reports/reportDirectorDidNotMakeCheck', params);
			return $http.get(query);
		},

        getXreport11: function (filters) {
            var params = {
                reportPeriodStartDate: moment(filters.rangeFrom).format(formatRevert),
                reportPeriodEndDate: moment(filters.rangeTo).format(formatRevert),
                shopIds: (filters.shop && filters.shop.length > 0 && filters.shop.map(function(e){return e.id}).toString()) || null,
                divisionIds: (filters.division && filters.division.length > 0 && filters.division.map(function(e){return e.id}).toString()) || null,
                regionsIds: (filters.cluster && filters.cluster.length > 0 && filters.cluster.map(function(e){return e.id}).toString()) || null,
                contractorIds: (filters.contractor && filters.contractor.length > 0 && filters.contractor.map(function(e){return e.id}).toString()) || null,
                page: filters.page-1,
                size: filters.perPage
            };

            return $http.get(config.backendV2 + '/reports/contractors-to-blocks/basic', {params:params})
        },

        getXreport12: function (filters) {
            var params = {
                reportPeriodStartDate: moment(filters.rangeFrom).format(formatRevert), // "2016-01-01"
                reportPeriodEndDate: moment(filters.rangeTo).format(formatRevert), //"2018-12-31",
                shopIds: (filters.shop && filters.shop.length > 0 && filters.shop.map(function(e){return e.id}).toString()) || null,
                divisionIds: (filters.division && filters.division.length > 0 && filters.division.map(function(e){return e.id}).toString()) || null,
                regionsIds: (filters.cluster && filters.cluster.length > 0 && filters.cluster.map(function(e){return e.id}).toString()) || null,
                processIds: (filters.processes && filters.processes.length > 0 && filters.processes.map(function(e){return e.id}).toString()) || null,
                questionIds: (filters.question && filters.question.length > 0 && filters.question.map(function(e){return e.id}).toString()) || null,
                contractorIds: (filters.contractor && filters.contractor.length > 0 && filters.contractor.map(function(e){return e.id}).toString()) || null,
                page: filters.page-1,
                size: filters.perPage
            };

            return $http.get(config.backendV2 + '/reports/contractors-to-blocks/detailed', {params:params})
        },
		getReportInspectors: function(filters) {
			var params = {
				userId: filters.fio && filters.fio.length ? filters.fio[0].id : null,
				dateFrom: moment(filters.rangeFrom).format('YYYY-MM-DD'),
				dateTo: moment(filters.rangeTo).format('YYYY-MM-DD'),
			};
			return $http.get(config.backendOrgstruct + '/inspector/report', { params })
		},
        getReportManagerCoordinates: function(filters) {
			const params = {
				userId: filters.fio && filters.fio.length ? filters.fio[0].id : null,
                deviationInMeters: filters.deviationInMeters ? filters.deviationInMeters : 0,
				dateFrom: moment(filters.rangeFrom).format('YYYY-MM-DD'),
				dateTo: moment(filters.rangeTo).format('YYYY-MM-DD'),
			};

			return $http.get(config.backendOrgstruct + '/users/coordinates/report', { params })
		},
		//TODO: use Moment JS
		createChecklist: function (checklist) {
			return $http.post(config.backend + '/checklist/createChecklist', {
				resolverId: checklist.resolverId,
				templateName: checklist.templateName,
				shopName: checklist.shopName,
				resolveDate: new Date(Date.UTC(checklist.resolveDate.getFullYear(), checklist.resolveDate.getMonth(), checklist.resolveDate.getDate()))
			});
		},
		deleteChecklist: function(checklistId) {
			return $http.delete(config.backend + '/checklist/'+checklistId);
		},
		deleteChecklistAdmin: function(checklistId) {
			return $http.delete(config.backend + '/checklist/'+checklistId);
		},
		getActiveChecklist: function(){
			return $http.get(HttpUtils.getQuery('/objects/secret'))
		},
        startChecklist: function(checklistId, question){
            return $http.put(config.backend + '/checklist/' + checklistId + '/start', question);
        },
        finishChecklist: function(checklistId, question){
            return $http.post(config.backend + '/checklist/' + checklistId + '/finish', question);
        },
		changeStatus:function(checklistId, newStatus){

			return $http.post(config.backend + '/objects/checklists/changeStatus?id=' + checklistId + "&status=" + newStatus, {})
		},
		getAvailableChecklists: function(userId) {
			return $http.get(config.backend + '/checklist/getAvailableChecklists?userId=' + userId)
				.success(function(data) {
					checklists = data;
				}
			);
		},

		getById: function(id){
			var query = HttpUtils.getQuery('/checklist/getById', {id: id});
			return $http.get(query);
		},

		updateChecklist: function(checklist){
			return $http.post(HttpUtils.getQuery('/checklist/updateChecklist'), checklist);
		},

		getAcceptedByShop: function(shopName) {
			return $http.get(config.backend + '/checklist/getAcceptedByShop?shopName=' + shopName)
				.success(function(data) {
					return data;
				})
		},
		getChecklists: function() {
			return checklists;
		},
		getChecklistGrade: function(checklistId) {
			return $http.get(config.backend + '/checklist/getChecklistGrade?checklistId=' + checklistId)
				.success(function(data) {
					return data;
				})
		},
		getFilteredReport: function(queryShop, queryTemplate, queryFromDate, queryToDate) {
			var fromDate = queryFromDate.getDate().toString().replace( /^([0-9])$/, '0$1' ) + "-" + (queryFromDate.getMonth() + 1).toString().replace( /^([0-9])$/, '0$1' ) + "-" + queryFromDate.getFullYear();
			var toDate = queryToDate.getDate().toString().replace( /^([0-9])$/, '0$1' ) + "-" + (queryToDate.getMonth() + 1).toString().replace( /^([0-9])$/, '0$1' ) + "-" + queryToDate.getFullYear();
			return $http.get(config.backend + '/checklist/getFilteredReport?queryShop=' + queryShop + '&queryTemplate=' + queryTemplate + '&queryFromDate=' + fromDate + '&queryToDate=' + toDate)
		},

		getWopReport: function(templateId, from, to){
			var fromTime = formatDate(from);
			var toTime = formatDate(to);
			return $http.get(config.backend + '/files/getWopReport?templateId=' + templateId + '&queryFromDate=' + fromTime + '&queryToDate=' + toTime);
		},

		getTransactionsReport(body) {
			var apiFields = ['dateFrom', 'dateTo', 'cardNum', 'transactionId', 'buyerId', 'buyerName', 'shopId', 'storeName', 'start', 'limit'];

			var reqBody = apiFields.reduce(function(result, field) {
				result[field] = body[field];
				return result;
			}, {});

			return $http.post(config.backend + '/reports/cardNonStop', Object.assign({ xls: body.xls || false }, reqBody));
		}

		// startChecklist: function(checklistId, cb){
		// 	var d = new Date();
		// 	var data = {
		// 		startTime: moment(d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
		// 		timeShift: -d.getTimezoneOffset()/60
		// 	}
		// 	var doRequest = function(dataRequest){
		// 		$http.put(config.backend + '/checklist/'+ checklistId+ '/start', dataRequest, {noThrobber:true})
		// 			.success(function(dataResponse, status) {
		// 				cb((status == 200 || status == 204)?null:status, dataRequest);
		// 			}
		// 		);
		// 	}
		// 	// if(navigator.geolocation){
		// 	// 	navigator.geolocation.getCurrentPosition(function(position) {
		// 	// 		data["startLatitude"] = position.coords.latitude;
		// 	// 		data["startLongitude"] = position.coords.longitude;
		// 	// 		doRequest(data);
		// 	// 	}, function(){
		// 	// 		doRequest(data);
		// 	// 	},{
		// 	// 		timeout: 5000,
		// 	// 		maximumAge: 60000
		// 	// 	})
		// 	// }else{
        //
		// 		doRequest(data);
		// 	//}
		// }
	}

});

angular.module('smartApp').controller('SettingsParamsController', function($http, ObjectServiceForTemplates, ObjectServiceForClusters, Notifications, $scope, SystemParamsService, UploadService) {
	$scope.checklists = [];
	$scope.clusters = [];
	$scope.paramsBlocks = {};

	$scope.showPropertyKey = false;
	$scope.readingFiles = false;

	var oldParams = {};
	var paramsData = [];

	var refresh = function() {
		SystemParamsService.getSystemProps(false, function(err, params) {
			if (err) {
				console.log("Error: ", err)
				Notifications.danger('Ошибка получения данных');
				return;
			}

			$scope.paramsBlocks = {};

			paramsData =  _.sortBy(params, 'russianName').filter(el => !el.hide);

			var children = {};

			for (var key in paramsData) {
				var param = paramsData[key];
				var splitted = [];

				if (param.hide) continue;

				if (param.russianName) {
					splitted = param.russianName.split(": ");
				} else if (!!~param.propertyKey.indexOf(".")) {
					splitted = param.key.split(".");
				} else {
					splitted[1] = param.propertyKey;
				}

				switch(param.clientType) {
					case "integer":
					case "integerUnsigned":
					case "floatUnsigned":
					case "float":
						param.value = Number(param.value)
						break;
					case "boolean":
						break;
					case "file":
						break;
					// case "checklist":
					// 	row.value = $scope.checklists.filter(function(item){return row.value == item.id})
					// 	console.log("!!!!!!", row.value)
					// 	break;
					default:
						param.clientType = "string"
						break;
				}

				if (param.clientType === 'file') {
					oldParams[param.propertyKey] = param;
				} else {
					oldParams[param.propertyKey] = param.value;
				}


				var name = splitted[splitted.length - 1];
			  var groups = splitted.slice(0, splitted.length - 1);
				var parent = null;
				var isRootGroup = true;

				param.inc = {
					rusName: name,
					rusGroup: groups[groups.length - 1]
				};

				groups.forEach((cur, index) => {
					var next = groups[index + 1];
					var block = { children: {}, params: [], isExpanded: false };

					if (isRootGroup) {
						if (!$scope.paramsBlocks[cur]) {
							$scope.paramsBlocks[cur] = block;
						} else {
							block = $scope.paramsBlocks[cur];
						}

						isRootGroup = false;
					}

					if (parent) {
						if (!parent.children[cur]) {
							parent.children[cur] = block;
						} else {
							block = parent.children[cur];
						}
					}

					if (!next) {
						block.params.push(param);
					}

					parent = block;
				});
			}
		});
	}

	$scope.preventKeys = function(event, unsigned, float){
		var possible = (function(){var arr = [];for(var i = 0; i <= 9; i++){arr.push(""+i)}return arr;})()
		if(float)possible.push(",", ".")
		if(!unsigned)possible.push("-")
		console.log(event.key, possible, !!~possible.indexOf(event.key))
		if(!~possible.indexOf(event.key))return;
	}

	$scope.save = function() {
		var paramsToUpdate = [];
		var filesToRead = [];

		paramsData.forEach(param => {
			if (param.clientType !== 'file' && oldParams[param.propertyKey] === param.value) {
				return;
			}

			if (param.clientType === 'file' && !param.file) {
				return;
			}

			if (param.clientType === 'file' && param.file) {
				filesToRead.push({ propertyKey: param.propertyKey, file: param.file });
				return;
			}

			paramsToUpdate.push({
				propertyKey: param.propertyKey,
				value: param.value
			});
		});

		if (filesToRead.length > 0) {
			$scope.readingFiles = true;

			readFiles(filesToRead, function(result) {
				$scope.readingFiles = false;
				paramsToUpdate = paramsToUpdate.concat(result);

				saveParams(paramsToUpdate, function(err, updatedParams) {
					if (err) {
						return;
					}

					for (var key in $scope.paramsBlocks) {
						$scope.paramsBlocks[key].params.forEach(param => {

							updatedParams.every(updatedParam => {
								if (param.propertyKey === updatedParam.propertyKey) {
									param.value = updatedParam.value;
									return false;
								}

								return true;
							});

						});
					}
				});
			});
			return;
		} else if (paramsToUpdate.length == 0) {
			Notifications.warning('Нечего сохранять');
			return;
		}

		saveParams(paramsToUpdate);
	};

	function readFiles(files, cb) {
		var counter = 0;
		var result = [];

		files.forEach(function(obj) {
			var reader = new FileReader();
			reader.onloadend = function() {
				counter++;
				var content = reader.result.split(',')[1];
				result.push({ propertyKey: obj.propertyKey, value: JSON.stringify({
					content: content,
					filename: obj.file.name
				})});
				if (counter === files.length) {
					cb(result);
				}
			};
			reader.readAsDataURL(obj.file);
		});
	}

	function saveParams(params, cb) {
		console.log(params);
		SystemParamsService.set(params).then(function (result) {
			if (result.status && result.status != 200) {
				Notifications.danger('Ошибка сохранения');
				cb({ message: 'paramsSaveError', status: result.status });
				return;
			}

			Notifications.success('Ваши изменения сохранены');

			cb(null, result.data);
		});
	}

	refresh()
}).filter('objectKeysLength', [function() {
    return function(items) {
      return Object.keys(items).length;
    };
}]);

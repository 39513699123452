(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('taskInfoHeader', taskInfoHeader);

    function taskInfoHeader() {
        return {
            templateUrl: 'scripts/app/tasks/task-full-view/task-info-header/task-info-header.view.html',
            bindToController: true,
            controller: function() {
                const vm = this;
                vm.fullInfoCollapsed = false;
            },
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                task: '<',
                response: '<'
            }
        };
    }
})();
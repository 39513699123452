function SettingsIntegrationController($scope, SettingsIntegrationService, SystemParamsService, DownloadService, $rootScope, CompareTimeService) {
  var paramKeyToName = {
    'sap.integration.emails': 'integrationEmails',
    'sap.integration.fileName.availabilityCheck': 'fileForms',
    'sap.integration.fileName.divisionRegion': 'fileDivisionRegion',
    'sap.integration.fileName.shopDir': 'fileShopDir',
    'sap.integration.leftover': 'fileProductsLeftover',
    'sap.integration.assortment': 'fileProductsAssortment',
    'sap.integration.localcode.barcode': 'fileLocalcodeBarcode'
  };

  var paramNameToKey = _.invert(paramKeyToName);

  var Param = function(title) {
    this.title = title;
    this.val = null;
    this.isSaving = false;
  };

  var params = {
    values: {
      integrationEmails: new Param('Получатели уведомлений об ошибках интеграции с SAP'),
      fileShopDir: new Param('Название файла для загрузки магазинов и директоров'),
      fileDivisionRegion: new Param('Название файла для загрузки привязок групп магазинов к отделам продаж'),
      fileForms: new Param('Название файла для загрузки товаров Проверки наличия'),
      fileProductsLeftover: new Param('Название файла для загрузки остатков товара'),
      fileProductsAssortment: new Param('Название файла для загрузки ассортимента товаров'),
      fileLocalcodeBarcode: new Param('Название файла для загрузки соответствий ШК-ЛК')
    },
    paramModelOptions: {
      updateOn: 'default blur',
      debounce: {
        default: 1500,
        blur: 0
      }
    },
    save: function(paramName, value) {
      this.values[paramName].isSaving = true;
      SettingsIntegrationService.saveParam(paramNameToKey[paramName], value).then(() => {
        this.values[paramName].isSaving = false;
      });
    }
  };

  $scope.params = {
    values: params.values,
    save: params.save,
    paramModelOptions: params.paramModelOptions
  };

  var dateFrom = moment().subtract(1, 'months').toDate();
  var dateTo = moment().toDate();

  var report = {
    dateFrom: dateFrom,
    dateTo: dateTo,
    dateFromOpts: { showWeeks: false, minDate: moment(dateFrom).toDate() },
    dateToOpts: { showWeeks: false, maxDate: moment(dateTo).toDate() },
    calendarsOpen: { dateFrom: false, dateTo: false },
    handleDateChange: function(name, value) {
      switch(name) {
        case 'dateFrom':
          this.dateToOpts.minDate = moment(value).toDate();
          break;
        case 'dateTo':
          this.dateFromOpts.maxDate = moment(value).toDate();
          break;
      }
    },
    openCalendar: function(name) {
      for (key in this.calendarsOpen) {
        this.calendarsOpen[key] = false;
      }

      this.calendarsOpen[name] = true;
    },
    download: function() {
      DownloadService.downloadLogsReport({ rangeFrom: this.dateFrom, rangeTo: this.dateTo });
    }
  };

  var integrationMethods = {
    sap: 'integrateWithSap',
    fileForms: 'integrateForms',
    fileProductsLeftover: 'integrateProductsLeftover',
    fileProductsAssortment: 'integrateProductsAssortment',
    fileLocalcodeBarcode: 'integrateLocalcodeBarcode'
  };

  $scope.report = report;
  $scope.lastIntegrationsList = {
    fileForms: { title: 'товаров проверки наличия', time: null, relevant: false },
    fileProductsLeftover: { title: 'остатков товара', time: null, relevant: false },
    fileProductsAssortment: { title: 'ассортимента товаров', time: null, relevant: false },
    fileLocalcodeBarcode: { title: 'соответствий ШК-ЛК', time: null, relevant: false },
    fileInspectionActs: { title: 'список и статусы актов приёмки', time: null, relevant: false },
    fileShopDir: { title: 'орг.структуры', time: null, relevant: false },
  };

  $scope.startIntegration = function(integrationName) {
    if (!integrationMethods[integrationName]) return;

    var methodName = integrationMethods[integrationName];

    $rootScope.$broadcast('integrationStarted', integrationName);

    return SettingsIntegrationService[methodName]().then(status => {
      $rootScope.$broadcast('integrationEnded', integrationName);
      if (status !== 'ok') return;
      getLastIntegrationTime(integrationName);
    })
  };

  $scope.isIntegrationRunning = function(integrationName) {
    return $rootScope.runningIntegrations ? $rootScope.runningIntegrations.includes(integrationName) : false;
  };

  $rootScope.$on('integrationStarted', (event, integrationName) => {
    $rootScope.runningIntegrations = $rootScope.runningIntegrations || [];
    $rootScope.runningIntegrations.push(integrationName);
  });

  $rootScope.$on('integrationEnded', (event, integrationName) => {
    $rootScope.runningIntegrations.splice($rootScope.runningIntegrations.indexOf(integrationName), 1);
  });

  function getLastIntegrationTime(integrationName) {
    if (![
      'fileForms',
      'fileProductsLeftover',
      'fileProductsAssortment',
      'fileLocalcodeBarcode',
      'fileInspectionActs',
      'fileShopDir'
    ].includes(integrationName)) return;

    var filename = integrationName === 'fileInspectionActs' ? 'ZSS_ACT_BY_DATE' : params.values[integrationName].val;

    SettingsIntegrationService.getLastIntegrationTime(filename).then(result => {
      if (result.status !== 200) return;

      $scope.lastIntegrationsList[integrationName].time = moment(result.data.date).format('DD MMMM YYYY HH:mm');
      $scope.lastIntegrationsList[integrationName].relevant = CompareTimeService(result.data.date);
    });
  }

  function init() {
    SettingsIntegrationService.getParams((err, paramss) => {
      for (var key in paramss) {
        params.values[paramKeyToName[key]].val = paramss[key];
      }

      for (var name in $scope.lastIntegrationsList) {
        getLastIntegrationTime(name);
      }
    });
  }

  init();
}
angular.module('smartApp')
       .controller('SettingsIntegrationController', [
  '$scope',
  'SettingsIntegrationService',
  'SystemParamsService',
  'DownloadService',
  '$rootScope',
  'CompareTimeService',
  SettingsIntegrationController
]);

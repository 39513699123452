'use strict';

angular.module('smartApp').factory('LeftpanelService', function LeftpanelService() {

    return {
        hidePanel: function () {
            $.root_.toggleClass("minified", true);
            return;
        },

        showPanel: function() {
            $.root_.toggleClass("minified", false);
            $.root_.toggleClass("hidden-menu", false);
            return;
        }
    }
});

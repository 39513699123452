angular.module('smartApp').controller('EnterCountersController', function($rootScope, $scope, Principal, CounterService, FormFactory, Notifications, ngTableParams, moment, ngDialog) {

    $scope.progress = false;
    $scope.currentDate = new Date();

    $scope.counterSelected = function(counter) {
        $scope.selectedCounter = counter;
    };

    $scope.createForm = function(form){
        return new FormFactory(form);
    };

    $scope.isValid = function(counter){
      return counter.entry && (counter.lastEntry ? counter.entry >= counter.lastEntry.entry : true);
    };

    $scope.updateRecord = function(counter){
        CounterService.getCounterById(counter.id).success(function(result, status){
            if(status == 200){
                var res = _.find($scope.counters, function(e){return e.id === counter.id});
                if(res){
                    if(result.currentEntry)
                        result.entry = result.currentEntry.entry;
                    var index = $scope.counters.indexOf(res);
                    result.$table = counter.$table;
                    result.$extend = counter.$extend;
                    $scope.counters[index] = result;
                }
            }
        })
    };

    $scope.refreshHistory = function(counter){
        counter.$table.refresh();
    };

    $scope.initCounters = function() {
        $scope.progress = true;
        CounterService.getAllByShopSap(Principal.getIdentity().lastName)
            .success(function(data) {
                $scope.progress = false;
                angular.forEach(data, function(e){
                    if(e.currentEntry)
                        e.entry = e.currentEntry.entry;
                });
                $scope.counters = data;
            }
        );
    };

    $scope.getEntryLength = function(counter){
        if(counter)
            return Number.parseInt(counter.model.valence);
        else return 0;
    };

    $scope.toggleHistory = function(counter){
        counter.$extend = !counter.$extend;
        if(!counter.$table){
            counter.$table = createTable(counter);
        }
    };

    function createTable(counter){
        return new ngTableParams({
            page: 1,
            count: 10
        }, {
            total: 0,
            getData: function ($defer, params) {
                var _this = this;
                var page = params.page();
                var limit = params.count();
                CounterService.getEntriesHistory(counter.id, page, limit).success(function(result, status, headers){
                    if(status === 200){
                        $defer.resolve(result);
                    }
                    _this.total = headers('x-total-count');
                });
            }
        });
    }

	$scope.onChangeCounterValueClick = function (counter) {
		var $selfScope = $scope;
		ngDialog.open(
			{
				template: 'changeCounterValue',
				controller: ['$rootScope', '$scope', 'CounterService', function($rootScope, $scope, CounterService) {
					$scope.currentDate = new Date();
					$scope.counter = angular.copy(counter);

					$scope.setCounter = function() {
						var params = {
							"id": 0,
							"entry": $scope.counter.entry,
							"entryDate": $scope.currentDate,
							"counterId": counter.id,
							"comment": ($scope.counter.comment || '').trim()
						};

						counter.entry = $scope.counter.entry;
						counter.comment = $scope.counter.comment;

						CounterService.createCounterEntry(params).
							success(function(data, status) {
								$scope.progress = false;
								if(status === 200){
									Notifications.success('Данные успешно сохранены');
									counter.entry = null;
									$selfScope.updateRecord(counter);
								}else if(status === 400){
									Notifications.danger('Ошибка', 'Неверные данные');
								}
							}
						);
					};

					$scope.checkCounterLength = function(entry){
						if(entry && counter.model.valence){
							return entry.toString().length <= parseInt(counter.model.valence);
						}else{
							return true;
						}
					}

				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};

	$scope.isWednesday = function () {
		return moment().isoWeekday() === 3;
	};

    $scope.isInputEnabled = function(counter){
		return $scope.isWednesday() && !angular.isObject(counter.currentEntry);
    }

});
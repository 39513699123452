/**
 * Created by vkokurin on 14.05.2015.
 */
'use strict';

angular.module('smartApp')
    .factory('User', function ($resource, config) {
        return $resource(config.backend + 'admin/users', {}, {
            'getAll': { method: 'GET', isArray: true}
        });
    });

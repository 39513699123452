angular.module('smartApp').factory('Xfilter8', checkXreportFactory8);
checkXreportFactory8.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory8(ChecklistService, DownloadService) {
	return {
		id: 13,
		title: 'Отчёты - Проверки-процессы-вопросы',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter8/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter8/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo && filters.division && filters.division.length != 0  && (!filters.checklistIds || filters.checklistIds.match(/^(?:\d\,?\s*)+\d?$/));
		},
		load: function(filters) {
			var t = ChecklistService.getXreport8(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport8(filters);
		},
		downloadKPO: function (filters) {
            return DownloadService.downloadXreport8KPO(filters);
        }
	};
}
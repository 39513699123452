<template>
    <div>
        <h1>{{ title }}</h1>

        <div class="row">
            <div class="col-3">
                <p>Store</p>
                <single-select
                    id="store-select"
                    v-model="pfm"
                    searchable
                    :loading="pfmListFetchStatus"
                    :options="computedPfmList"
                ></single-select>
            </div>
            <div class="col-3">
                <p>Date</p>
                <custom-datepicker
                    v-model="selectedDate"
                    display-year
                    placeholder="Select Filter date"
                ></custom-datepicker>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <expansion-panel id="collapsed-filters" class="mt-4" title="Дополнительные параметры">
                    <tabs v-model="tab" :tabs="tabs" class="mt-3">
                        <template #localCode>
                            <div class="col-4 pl-0 ml-0">
                                <p>Local code</p>
                                <multiple-select
                                    id="lkSelect"
                                    v-model="lkValue"
                                    :options="computedLkList"
                                    :loading="lkFetchStatus"
                                    searchable
                                    @search-change="handleLkInput"
                                ></multiple-select>
                            </div>
                        </template>

                        <template #barcode>
                            <div class="col-4 pl-0 ml-0">
                                <p>Barcode</p>
                                <multiple-select
                                    id="barcodeSelect"
                                    ref="barcodeSelect"
                                    v-model="barcodeValue"
                                    :options="barcodeOptions"
                                    searchable
                                    @search-change="handleBarcodeInput"
                                ></multiple-select>
                            </div>
                        </template>
                    </tabs>
                </expansion-panel>
            </div>
        </div>

        <div class="row align-items-center mt-4">
            <div class="col-auto">
                <custom-button
                    text="Export to Excel"
                    @click="handleExport">
                </custom-button>
                <loader
                    v-if="excelFetchStatus"
                    inside-element
                />
            </div>
        </div>

        <custom-table
            id="inventory-table"
            v-model:meta="table.meta"
            :columns="fields"
            :items="computedTableItems"
            :limit-options="table.perPageOptions"
            :busy="tableFetchStatus"
            no-data-message="Нет данных с текущими параметрами"
            show-pagination
            rounded
            class="mt-4"
        ></custom-table>
    </div>
</template>

<script>
import formatDate from '@/helpers/formatDate';

// Components
import {
    Loader,
    CustomButton,
    CustomDatepicker,
    MultipleSelect,
    SingleSelect,
    Tabs,
    CustomTable,
} from '@eron/ui-kit';
import ExpansionPanel from '@/components/common/ExpansionPanel.vue';

export default {
    components: {
        Loader,
        CustomButton,
        CustomDatepicker,
        MultipleSelect,
        SingleSelect,
        ExpansionPanel,
        Tabs,
        CustomTable,
    },
    props: {
        title: {
            type: String,
            default: 'Inventory',
        },
        storeModuleName: {
            type: String,
            default: 'inventory',
        },
        fields: {
            type: Array,
            default: () => [
                { key: 'id', label: 'ID' },
                { key: 'pfm', label: 'Store' },
                { key: 'sku', label: 'Local Code' },
                { key: 'barcode', label: 'Variant Barcode' },
                { key: 'variantTitle', label: 'Variant Name' },
                { key: 'balance', label: 'Quantity' },
            ],
        },
    },
    data() {
        return {
            tab: 0,
            tabs: [
                { title: 'Local code', index: 'localCode' },
                { title: 'Barcode', index: 'barcode' },
            ],
            table: {
                meta: { page: 1, limit: 5, total: 0 },
                perPageOptions: [5, 10, 15, 20, 50],
            },
            lkValue: [],
            barcodeValue: [],
            barcodeOptions: [],
            pfm: null,
            selectedDate: '',
        };
    },
    computed: {
        lkList() {
            return this.$store.getters.getLk;
        },
        tableItems() {
            return this.$store.getters[`${this.storeModuleName}/getItems`];
        },
        tablePagination() {
            return this.$store.getters[`${this.storeModuleName}/getPagination`];
        },
        tableFetchStatus() {
            return this.$store.getters[`${this.storeModuleName}/getItemsFetchStatus`];
        },
        excelFetchStatus() {
            return this.$store.getters[`${this.storeModuleName}/getExcelFetchStatus`];
        },
        pfmList() {
            return this.$store.getters.getPfmList.filter(item => item.countryCode === 'FPAE');
        },
        pfmListFetchStatus() {
            return this.$store.getters.getPfmListFetchStatus;
        },
        lkFetchStatus() {
            return this.$store.getters.getLkFetchStatus;
        },
        computedTableItems() {
            return this.tableItems.map((item, index) => {
                return {
                    ...item,
                    id: (this.table.meta.page - 1) * this.table.meta.limit + index + 1,
                };
            });
        },
        computedLkList() {
            return this.lkList.map(({ localcode }) => {
                return {
                    label: localcode.slice(11),
                    value: localcode.slice(11),
                };
            });
        },
        computedPfmList() {
            return this.pfmList.map((item) => {
                return {
                    value: item.sap,
                    label: item.sap,
                };
            });
        },
        fetchObject() {
            return {
                pfm: this.pfm,
                values: this.tab === 0 ? this.lkValue : this.barcodeValue,
                type: this.tab === 0 ? 'sku' : 'barcode',
                date: this.selectedDate ? formatDate(this.selectedDate) : null,
                paginator: {
                    page: this.table.meta.page,
                    perPage: this.table.meta.limit,
                },
            };
        },
    },
    watch: {
        tablePagination: {
            handler(paginationArg) {
                this.table.total = paginationArg.dataCount ?? 0;
            },
        },
        lkValue() {
            this.table.meta.page = 1;
        },
        barcodeValue() {
            this.table.meta.page = 1;
        },
        selectedDate() {
            this.table.meta.page = 1;
        },
        fetchObject: {
            handler() {
                this.fetchList();
            },
            deep: true,
        },
        'table.meta.limit'() {
            this.table.meta.page = 1;
        },
    },
    mounted() {
        this.fetchList().then(() => {
            this.$store.dispatch('fetchPfmList');
        });
    },
    methods: {
        fetchList() {
            return this.$store.dispatch(`${this.storeModuleName}/fetchItems`, this.fetchObject);
        },
        handleLkInput(val) {
            this.$store.dispatch('fetchLk', val);
        },
        handleBarcodeInput(val) {
            if (val) {
                this.barcodeOptions = [];
                this.barcodeOptions.push(val);
            } else {
                this.barcodeOptions = [];
            }
        },
        handleExport() {
            const payloadObj = { ...this.fetchObject };
            payloadObj.paginator.perPage = 1000;
            this.$store.dispatch(`${this.storeModuleName}/fetchExcelExport`, payloadObj);
        },
    },
};
</script>

<style scoped lang="scss">
:deep(.pagination) {
    display: flex;
    flex-flow: row;
}

:deep(.pagination-bar) {
    border-top: none !important;
}

:deep(.pagination-action__to-page) {
    display: none !important;
}

:deep(.tab-content) {
    padding-left: 0;
    border: none;
}
</style>

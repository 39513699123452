(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('UsersPageService', UsersPageService);

    UsersPageService.$inject = [];
    function UsersPageService() {
        var levels = [
            'COMPANY',
            'DIVISION',
            'REGION',
            'SHOP'
        ];

        var service = {
            getUserFields: getUserFields
        };

        return service;

        function getUserFields() {
            return [
                {
                    userField: 'email',
                    requestField: 'email',
                    accessibleLevel: levels
                },
                {
                    userField: 'firstName',
                    requestField: 'firstName',
                    accessibleLevel: levels
                },
                {
                    userField: 'lastName',
                    requestField: 'lastName',
                    accessibleLevel: levels
                },
                {
                    userField: 'position',
                    requestField: 'position',
                    accessibleLevel: levels
                },
                {
                    userField: 'password',
                    requestField: 'password',
                    accessibleLevel: levels
                },
                {
                    userField: 'divisions',
                    requestField: 'divisionIds',
                    accessibleLevel: ['COMPANY', 'DIVISION']
                },
                {
                    userField: 'regions',
                    requestField: 'regionIds',
                    accessibleLevel: ['REGION']
                }
            ];
        }
    }
})();
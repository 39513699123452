'use strict';

angular.module('smartApp').controller('LeftbarController', LeftbarController)

LeftbarController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$filter',
    '$state',
    'SystemParamsService',
    'Principal',
    'LeftpanelService',
    'TaskListServiceHttp',
    'projectConfig',
    'TaskListService',
    'Notifications',
    'config'
];

function LeftbarController(
    $scope,
    $rootScope,
    $timeout,
    $filter,
    $state,
    SystemParamsService,
    Principal,
    LeftpanelService,
    TaskListServiceHttp,
    projectConfig,
    TaskListService,
    Notifications,
    config
) {
    // Инструкция



    $scope.downloadInstruction = function($event) {
        $event.preventDefault();
        var url = '';
        if(Principal.isInRole(Principal.ROLES.ROLE_DIRECTOR)){
            url = '/doc/Director.docx'
        }else if(Principal.isInRole(Principal.ROLES.ROLE_SUPERVISOR)){
            url = '/doc/Supervisor.docx';
        }
        if(url)
            $.fileDownload(url)
    };

    $scope.principal = Principal;

    $scope.isAuthenticated = Principal.isAuthenticated();
    $scope.identity = {firstName: '', lastName: '', roles: ''};

    $scope.initMenus = function() {
        if(Principal.isAuthenticated()) {
            if(!Principal.isInRole("ROLE_SECRET") && !config.isMobile) {
                LeftpanelService.showPanel();
            } else {
                LeftpanelService.hidePanel();
            }
        }
    }

    $scope.updateDisplayInfo = function() {
		if(Principal.getIdentity() != null) {
			$scope.identity = Principal.getIdentity();
		}

        $scope.firstName = $scope.identity.firstName;
        $scope.lastName = $scope.identity.lastName;
        $scope.authority = $scope.identity.roles;
        $scope.position = $scope.identity.position;
        if(Principal.isInRole("ROLE_DIRECTOR")){
            $scope.lastName = $filter('wordReplace')('SAPshirt') + $scope.lastName;
        }
        getOpenTasks();
    };

    $scope.$on('principalUpdated', function() {
	    $scope.updateDisplayInfo();
    });

    SystemParamsService.getSystemProps(['powerbi.link', 'tasks.manual.show'], function(err, params){
        if(err){
            Notifications.danger('Ошибка получения данных');
            return;
        }

        $scope.powerbiLink = params['powerbi.link'];
        $scope.tasksManualShow = params['tasks.manual.show'] === 'true';
    });

   function getOpenTasks() {
       $scope.taskCounter = null;
       TaskListServiceHttp.getUserPermissions().then(function(result) {
           if ((result.status === 200 || result.status === 201) && !!result.data.executeTask) {
               TaskListServiceHttp.getOpenTaskCounter().then(function (result) {
                   if (result.status === 200) {
                       $scope.taskCounter = result.data;
                   }
               })
           }
       });
    }

    $scope.$on("loginSuccessfull", function(event) {
        $scope.updateDisplayInfo();
        $scope.initMenus();

    });

    $scope.$on("getOpenTasksCounter", function(event) {
        getOpenTasks();
    });

    if($scope.isAuthenticated) {
        $scope.updateDisplayInfo();
    }

    $scope.viewTasks = function(){ //TODO: сделать универсаное решение
        if(projectConfig.nameProject === "md"){
            return Principal.isInAnyRole(['ROLE_DIRECTOR', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER', 'ROLE_OPERATION_DIRECTOR', 'ROLE_TASK_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPER_SUPERVISOR'])
        }else{
            return Principal.isInAnyRole(['ROLE_DIRECTOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER', 'ROLE_OPERATION_DIRECTOR', 'ROLE_TASK_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPER_SUPERVISOR'])
        }
    };



    //from header

      $scope.$state = $state;

  $scope.navbar = {
    firstName: '',
    lastName: '',
    name: '',
    authority: []
  };
  $scope.breadcrumbs = $state.current.data.breadcrumbs

//   $scope.$on('$locationChangeSuccess', function(a,b,c) {
// console.log("locationChangeSuccess", $state.current)
//       $timeout(function () {
//         $scope.breadcrumbs = $state.current.data.breadcrumbs
//       }, 0);


//     });

  $scope.goHome = function(){
    if(Principal.isInRole('ROLE_ADMIN')){
      $state.go("start");
    }else{
      $state.go("home");
    }
  }


  $scope.updateDisplayInfo = function() {

    if(Principal.getIdentity() != null) {
      $scope.identity = Principal.getIdentity();


    }

    $timeout(function () {
      $scope.navbar.firstName = $scope.identity.firstName;
      $scope.navbar.lastName =  $scope.identity.lastName;
      $scope.navbar.name = $scope.navbar.firstName + ' ' + $scope.navbar.lastName;
      $scope.navbar.position = $scope.identity.position;
    }, 0);
  };

  $scope.$on("principalUpdated", function(event) {
    $scope.updateDisplayInfo();
  });

  $scope.$on("loginSuccessfull", function(event) {
    $scope.updateDisplayInfo();
  });

  if(Principal.isAuthenticated()) {
    $scope.updateDisplayInfo();
  }



    $scope.saveChecklist = function() {
        $rootScope.$broadcast('saveChecklist');
    };

    $scope.review = function() {
        $rootScope.$broadcast('review');
    };

    $scope.allowedForRole = function(role) {
        return Principal.isInRole(role);
    }
}

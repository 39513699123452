angular.module('smartApp').controller('FilterController1', FilterController1);
FilterController1.$inject = ['$scope', 'UserService', 'ChecklistReportService'];

function FilterController1($scope, UserService, ChecklistReportService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);
    $scope.divisions.splice(0, 1);

}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
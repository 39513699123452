angular.module('smartApp').controller('addExistingQuestionController', function(Notifications, PWCategoryService, TWTemplateService, $scope, $stateParams, $state) {
	$scope.questionsCollectionForAdding = {};

	$scope.Utils = {
		keys : Object.keys
	}



	$scope.nodeSelect = function(node){
		if(node.hasQuestions){
			PWCategoryService.getQuestions(node.id, function(err, questions){
				if(err){
					Notifications.danger("Ошибка получения данных")
					console.log(err, questions)
					return
				}
	 
				
				var arr = [];
				var lastCategoryId = undefined;
				var categoryCounter = -1;
				for(var i in questions){
					var question = questions[i]
					if(!question.categoryName)question.categoryName = "Name"+question.categoryId;
					
					if(question.categoryId != lastCategoryId){
						categoryCounter++;
						arr[categoryCounter] = {
							name: question.categoryName,
							id: question.categoryId,
							questions: []
						}
					}
					arr[categoryCounter].questions.push(question)
					lastCategoryId = question.categoryId;
				}	

				$scope.process = arr;
				
				$scope.collectQuestion = function(question){
					if(!!~$scope.questionsOriginalIds.indexOf(question.id))return;

					if($scope.questionsCollectionForAdding[question.id]){
						delete $scope.questionsCollectionForAdding[question.id] 
					}else{
						$scope.questionsCollectionForAdding[question.id] = true;
					}
				}
				$scope.addAll = function(){
					console.log($scope.process[0])
					for(var i in $scope.process[0].questions){
						if($scope.questionsOriginalIds.indexOf($scope.process[0].questions[i].id) === -1){
							$scope.questionsCollectionForAdding[$scope.process[0].questions[i].id] = true;
						}
					}
				}

				$scope.confirm = function(){					
					TWTemplateService.addQuestionsToTemplate(Object.keys($scope.questionsCollectionForAdding).map((el) => +el), $scope.template.id, function (err, result) {
						if(err){
							console.log(err, msg)
							Notifications.danger('Ошибка добавления');
							return;
						}
						//console.log()
						//$scope.questionsOriginalIds.push(result.originalId)
						$state.reload();
					})

					
				}
			})       
		}else{
			$scope.process = []
		}
	}
})                      
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';
import StockTable from '../views/StockTable.vue';
import StockHome from "../views/StockHome.vue";
import BannersList from "../views/Banner/BannersList.vue";
import NotAuthorized from "../views/NotAuthorized.vue";
import TasksGenerate from "../views/tasks-generate/container/TasksGenerate.vue";

const routes = [
    {
        path: '/vue/',
        component: {
            template: '<router-view></router-view>'
        },
        children: [
            {
                path: 'tasks/generate',
                name: 'Tasks',
                component: TasksGenerate
            },
            {
                path: 'stock',
                name: 'StockHome',
                component: StockHome,
                meta: {
                    accessArray: [
                        {
                            roles: ['ROLE_SUPERVISOR', 'ROLE_SHOPDIR'],
                            requiredId: 1004,
                            userLevel: 'SHOP',
                            country: 'AE',
                        },
                        {
                            roles: ['ROLE_SUPERVISOR', 'ROLE_DIRECTOR_CLUSTER'],
                            requiredId: 1001,
                            userLevel: 'REGION',
                            country: 'AE',
                        },
                        {
                            roles: ['ROLE_SUPERVISOR', 'ROLE_OPERATION_DIRECTOR'],
                            requiredId: 1001,
                            userLevel: 'COMPANY',
                            country: 'AE',
                        },
                        {
                            roles: ['ROLE_SUPERVISOR', 'ROLE_CURATOR'],
                            requiredId: 1001,
                            userLevel: 'DIVISION',
                            country: 'AE',
                        },
                        {
                            roles: ['ROLE_STOCK_ADMIN'],
                            userLevel: 'COMPANY',
                        },
                        {
                            roles: ['ROLE_ADMIN'],
                            userLevel: 'COMPANY',
                        },
                    ]
                },
                children: [
                    {
                        name: 'stockInventory',
                        path: 'inventory',
                        component: StockTable,
                        props: {
                            title: 'Inventory',
                            storeModuleName: 'inventory',
                            fields: [
                                { key: "id", label: "ID" },
                                { key: "pfm", label: "Store" },
                                { key: "sku", label: "Local Code" },
                                { key: "barcode", label: "Variant Barcode" },
                                { key: "variantTitle", label: "Variant Name" },
                                { key: "balance", label: "Quantity" },
                            ]
                        }
                    },
                    {
                        name:'stockErrors',
                        path: 'errors',
                        component: StockTable,
                        props: {
                            title: 'Errors',
                            storeModuleName: 'errors',
                            fields: [
                                { key: 'id', label: 'ID' },
                                { key: 'pfm', label: 'Store' },
                                { key: 'sku', label: 'Local Code' },
                                { key: 'barcode', label: 'Variant Barcode' },
                                { key: 'balance', label: 'Quantity' }
                            ]
                        }
                    }
                ]
            },
            {
                path: 'banners',
                component: {
                    template: '<router-view></router-view>'
                },
                meta: {
                    accessArray: [
                        {
                            roles: ['ROLE_ADMIN'],
                        },
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: 'List',
                        component: BannersList,
                    },
                    {
                        path: 'banner',

                    }
                ]
            },
            {
                path: 'not-authorized',
                name: 'NotAuthorized',
                component: NotAuthorized
            }
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory('/'),
    routes
});

router.beforeEach((to, from, next) => {
    store.dispatch('fetchAccountData').then(() => {
        const accountData = store.state.accountData;
        const accessArray = to.meta.accessArray;
        if (accessArray) {
            let access = false
            accessArray.forEach(accessItem => {
                if (
                    (accessItem.country === undefined || accountData.country === accessItem.country) &&
                    (accessItem.requiredId === undefined || accountData.businessDirId === accessItem.requiredId) &&
                    (accessItem.userLevel === undefined || accountData.level === accessItem.userLevel) &&
                    (accessItem.roles === undefined || accessItem.roles.every(value => accountData.roles.includes(value)))
                ) access = true;
            })
            access ? next() : next({ name: 'NotAuthorized' })
        }else {
            next();
        }
    })


});

export default router;

/**
 * @param {Array} list - искомый список для выбора и поиска в компоненте мультиселекта
 * @param {Array} selectedItems - список выбранных элементов
 * @param {string=} searchingProperty - поле объекта по которому будет происходить поиск
 * @param {string=} placeholder - текст для текстового поля, если ничего не выбрано или оно не в фокусе
 * @param {string=} foundText - текст который будет отображаться, если что-либо выбрано. Для вывода количества выбранных элементов используется переменная $selectedCount, после компиляции будет заменена на число. Данная опция перебивает foundProperty
 * @param {string=} foundProperty - поле объекта, которое будет отображаться в текстовом поле, если что-либо найдено или выбрано
 * @param {boolean=} withCheckbox - отображать или не отображать чекбоксы рядом с опциями выбора
 */

(function() {
    'use strict';

    angular
        .module('smartApp')
        .component('mdSelect', {
            controller: MdSelectController,
            controllerAs: '$ctrl',
            bindings: {
                list: '<',
                selectedItems: '=',
                searchingProperty: '@?',
                placeholder: '@?',
                foundText: '@?',
                foundProperty: '@?',
                filterForFound: '<?',
                withCheckbox: '<?'
            },
            template: ['$element', function($element) {
                var item = getItem();
                return '\
                    <div class="md-select__container">\
                        <input\
                            class="md-input md-input--white md-select__search"\
                            data-ng-show="!$ctrl.isListVisible"\
                            data-ng-model="$ctrl.textForFoundItems"\
                            data-ng-click="$ctrl.isListVisible = true;"\
                            placeholder="{{$ctrl.placeholder || \'\'}}"\
                            type="text">\
                        <input\
                            class="md-input md-input--white md-select__search"\
                            data-ng-show="$ctrl.isListVisible"\
                            data-ng-model="$ctrl.searchItem[$ctrl.searchingProperty]"\
                            placeholder="{{$ctrl.placeholder || \'\'}}"\
                            type="text">\
                        <ul\
                         class="md-select__list"\
                         data-ng-if="$ctrl.isListVisible"\
                         click-outside="$ctrl.isListVisible = false;"\
                         outside-if-not="md-select__search">\
                            <li\
                                class="md-select__item"\
                                data-ng-repeat="item in $ctrl.list | filter:$ctrl.searchItem:strict"\
                                data-ng-click="$ctrl.selectListVal(item, $index)"\
                                data-ng-class="{\'md-select__item--selected\': $ctrl.checkSelectedListVal(item)}">\
                                <checkbox\
                                    data-ng-if="$ctrl.withCheckbox"\
                                    data-ng-change="$ctrl.selectListVal(item, $index)"\
                                    data-ng-model="$ctrl.selectedCheckboxs[$index]"></checkbox>\
                                '+ item +'\
                            </li>\
                        </ul>\
                    </div>';

                function getItem() {
                    var template = $element.find('md-select-item').detach();
                    var html;
                    if (template.length) {
                        html = template.html();
                    } else {
                        html = $element.html();
                    }
                    if (!template.length) {
                        $element.empty();
                    }
                    return '' + html;
                }
            }]
        });

    MdSelectController.$inject = ['$scope'];

    function MdSelectController($scope) {
        var $ctrl = this;

        $ctrl.isListVisible = false;
        $ctrl.selectedCheckboxs = [];
        $ctrl.textForFoundItems = '';

        $ctrl.selectListVal = selectListVal;
        $ctrl.checkSelectedListVal = checkSelectedListVal;

        $ctrl.searchItem;

        $scope.$watch(function() {
            return $ctrl.selectedItems;
        }, function(newVal) {
            $ctrl.textForFoundItems = setTextForFoundItems($ctrl.selectedItems);
        }, true)

        $ctrl.$onInit = function() {
            if (!angular.isArray($ctrl.selectedItems)) {
                $ctrl.selectedItems = [];
            }
            $ctrl.searchItem = {
                [$ctrl.searchingProperty]: ''
            };
            if ($ctrl.withCheckbox && $ctrl.list.length) {
                $ctrl.selectedCheckboxs = _.fill(Array($ctrl.list.length), false);
            }
        };
        $ctrl.$onChanges = function(changesObj) {};
        $ctrl.$onDestroy = function() { };

        function selectListVal(item, selecteIndex) {
            var index;
            if (_.find($ctrl.selectedItems, item)) {
                index = $ctrl.selectedItems.indexOf(item);
                $ctrl.selectedItems.splice(index, 1);
                $ctrl.selectedCheckboxs[selecteIndex] = false;
            } else {
                $ctrl.selectedItems.push(item);
                $ctrl.selectedCheckboxs[selecteIndex] = true;
            }
        }

        function checkSelectedListVal(val) {
            return _.find($ctrl.selectedItems, val);
        }

        function setTextForFoundItems(foundItems) {
            var textForFoundItems = '';
            if (foundItems.length) {
                if (angular.isObject(foundItems[0])) {
                    if ($ctrl.foundProperty) {
                        textForFoundItems = foundItems
                            .map(function(item) {
                                return item[$ctrl.foundProperty];
                            })
                            .join(', ');
                    } else {
                        textForFoundItems = 'Выбрано ' + foundItems.length + ' элементов';
                    }
                } else {
                    textForFoundItems = foundItems.join(', ');
                }
                if ($ctrl.foundText) {
                    textForFoundItems = $ctrl.foundText.replace(/\$selectedCount/, foundItems.length);
                }
            }
            return textForFoundItems;
        }
    }
})();
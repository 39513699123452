angular.module('smartApp').controller('addNewQuestionController', function(ngDialog, Notifications, PWCategoryService, TWQuestionService, TWTemplateService, $scope, $stateParams, $state) {
	$scope.Utils = {
		keys : Object.keys
	}

	$scope.close = function(){
		ngDialog.closeAll();
		$state.reload();
	}

	$scope.nodeSelect = function(node){
		$scope.activeNode = node;

		$scope.confirm = function(multiple){
            var question = {};
			async.waterfall([
				function(callback){
					PWCategoryService.addQuestion(node.id, $scope.question, callback);
				},
				function(answer, callback){
					question = answer;
					question.hint = $scope.question.hint;

					TWQuestionService.saveQuestion(question, callback);
				},
				function(q, callback){						
					TWTemplateService.addQuestionsToTemplate([question.id], $scope.template.id, callback)
				},
			], function(err, result){
				if(err){
					console.log(err, result);
					Notifications.danger("Ошибка добавления вопроса");
					return;
				}
				
				if(multiple){
					$scope.question.name = "";
					$scope.question.hint = "";
				}else{
					$state.reload();
				}
			})
			
		}
	}
})
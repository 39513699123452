'use strict';

angular.module('smartApp').controller('DownloadAppController', function(SystemParamsService, $scope, $window) {
    $scope.showInstructionBlock = true;
    $scope.instructionVisible = false;

    $scope.isInstructionOpen = false;

    var params = ["file", "title", "image"];
    var systems = ["android", "ios"];
    var paramsArr = [];

    for (var i in params) {
      for (var j in systems) {
        paramsArr.push("downloadApp."+params[i]+"."+systems[j])
      }
    }

    SystemParamsService.getSystemProps(paramsArr, function(err, params){
        if (err) {
          Notifications.danger('Ошибка получения данных');
          console.log("Err: ", err);
          return;
        }

        $scope.linksSettings = [{
            title: params["downloadApp.title.android"],
            link: params["downloadApp.file.android"],
            imagePath: params["downloadApp.image.android"]
        }];

        console.log( $scope.linksSettings)
    });

    $scope.instructionTrigger = function(){
        $scope.instructionVisible = !$scope.instructionVisible;
    };

    $scope.openLink = function openLink(link) {
      console.log(link);
       // window.open(link, '_blank');

        var a = document.createElement('A');
        a.href = link;
        a.download = link.substr(link.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
});

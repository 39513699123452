(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('shopDirTasksFilter', shopDirTasksFilter);

    shopDirTasksFilter.$inject = [];

    function shopDirTasksFilter() {
        var directive = {
            templateUrl:
                'scripts/app/checklist/shop-dir-tasks/filter/shop-dir-tasks-filter.view.html',
            bindToController: true,
            controller: ShopDirTasksFilterController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                filterBy: '&'
            }
        };
        return directive;
    }

    ShopDirTasksFilterController.$inject = ['$scope'];

    function ShopDirTasksFilterController($scope) {
        var vm = this;

        vm.filterProps = {
            taskIds: null,
            name: null,
            createdAfter: null,
            createdBefore: null,
            deadlineAfter: null,
            deadlineBefore: null
        };

        vm.dateFormat = 'dd.MM.yyyy';

        vm.calendarsOpen = [];

        vm.search = search;
        vm.reset = reset;
        vm.open = open;

        function search() {
            $scope.filterBy({ filter: vm.filterProps });
        }

        function reset() {
            _.keys(vm.filterProps).forEach(function(key) {
                vm.filterProps[key] = null;
            });
            search();
        }

        function open($event, id) {
            $event.preventDefault();
            $event.stopPropagation();
            for (var i in vm.calendarsOpen) {
                if (vm.calendarsOpen.hasOwnProperty(i)) {
                    vm.calendarsOpen[i] = false;
                }
            }
            vm.calendarsOpen[id] = true;
        }
    }
})();

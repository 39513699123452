
angular
    .module('smartApp')
    .component('filtersPopup', {
        templateUrl: 'scripts/components/filters-popup/filters-popup.view.html',
        controller: FiltersPopupController,
        bindings: { hasActive: '<', onClear: '&', onApply: '&' },
        transclude: true
    });

function FiltersPopupController() {
    const $ctrl = this;

    $ctrl.visible = false;

    $ctrl.toggleVisible = function() {
        $ctrl.visible = !$ctrl.visible;
    };

    $ctrl.outsideClick = function() {
        $ctrl.visible = false;
    };
}

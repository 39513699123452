<script setup>
import { computed } from 'vue';
import { MultipleSelect } from '@eron/ui-kit';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Array,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const options = [
    {
        label: 'пн',
        value: 1,
    },
    {
        label: 'вт',
        value: 2,
    },
    {
        label: 'ср',
        value: 3,
    },
    {
        label: 'чт',
        value: 4,
    },
    {
        label: 'пт',
        value: 5,
    },
    {
        label: 'сб',
        value: 6,
    },
    {
        label: 'вс',
        value: 7,
    },
];
const selected = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:model-value', value);
    },
});
</script>

<template>
    <multiple-select :id="id" v-model="selected" :options="options" />
</template>

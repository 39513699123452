'use strict';

angular.module('smartApp').controller('SecretController', function($state, $scope, Principal, ShopService, ChecklistService, ChecklistEditor, TemplateService) {

    $scope.initShops = function() {
        $scope.shops= [];
        ShopService.getAllObjectsByActorId(Principal.getIdentity().id).success(function(data) {
            $scope.shops = data;
            $scope.dataTemplates = [];
            TemplateService.getAllAssignedTo(Principal.getIdentity().id)
                .success(function(data) {
                    $scope.dataTemplates = data;
                    $scope.selectedTemplate = $scope.dataTemplates[0];
                });
        });
    }

    $scope.selectShop = function(shop) {
        $scope.selectedShop = shop;
    }

    $scope.shopSelected = function(shop) {
        $scope.selectedShop = shop;
    }

    $scope.init = function() {
        $scope.initShops();
    }

    $scope.init();

    $scope.startEditing = function() {
        ChecklistService.createChecklist({
            resolverId: Principal.getIdentity().id,
            shopName: $scope.selectedShop.locality + " " + $scope.selectedShop.sap,
            templateName: $scope.selectedTemplate.name,
            resolveDate: new Date()
            })
            .then(function(result){
                if(result.status === 200 || result.status === 201){
                    var id = result.headers('x-created-id');
                    $state.go('editChecklist', {checklist: id});

                }
            });
    }

});
angular.module('smartApp').factory('ReportInspectors', checkReportInspectors);
checkReportInspectors.$inject = ['ChecklistService'];

function checkReportInspectors(ChecklistService) {
    return {
        id: 23,
        title: 'Перемещение ревизоров',
        resultTitle: 'Результаты отчета',
        filtersTemplate: 'scripts/app/checklist/clreports/report-inspectors/filters.html',
        viewTemplate: 'scripts/app/checklist/clreports/report-inspectors/view.html',
        isValid: function(filters) {
            return filters.rangeFrom && filters.rangeTo && filters.fio && filters.fio.length;
        },
        load: function(filters) {
            return ChecklistService.getReportInspectors(filters);
        },
    };

}

'use strict';

angular.module('smartApp')

    .constant('PrincipalRoles', {
        ROLE_DIRECTOR: 'ROLE_DIRECTOR',
        ROLE_DIRECTOR_CLUSTER: 'ROLE_DIRECTOR_CLUSTER',
        ROLE_SECRET: 'ROLE_SECRET',
        ROLE_CURATOR: 'ROLE_CURATOR',
        ROLE_ENERGETIC: 'ROLE_ENERGETIC',
        ROLE_ENGINEER: 'ROLE_ENGINEER',
        ROLE_MAIN_ENGINEER: 'ROLE_MAIN_ENGINEER',
        ROLE_SUPERVISOR: 'ROLE_SUPERVISOR',
        ROLE_OPERATION_DIRECTOR: 'ROLE_OPERATION_DIRECTOR',
        ROLE_TASK_MANAGER: 'ROLE_TASK_MANAGER'
    })

    .factory('Principal', function Principal($rootScope, $q, Account, PrincipalRoles) {
    var _identity,
        _authenticated = false;

    return {
        ROLES: PrincipalRoles,

        getIdentity: function() {
          return _identity;
        },
        isIdentityResolved: function () {
            return angular.isDefined(_identity);
        },
        isAuthenticated: function () {
            return _authenticated;
        },
        isInRole: function (role) {
            if (!_authenticated || !_identity.roles) {
                return false;
            }

            var flag = false;

            angular.forEach(_identity.roles, function(r, rKey) {
                if(r === role)
                    flag = true;
            });

            return flag;
        },
        isInAnyRole: function (roles) {
            if (!_authenticated || !_identity.roles) {
                return false;
            }

            for (var i = 0; i < roles.length; i++) {
                if (this.isInRole(roles[i])) {
                    return true;
                }
            }

            return false;
        },
        authenticate: function (identity) {
            _identity = identity;
            _authenticated = identity !== null;
            window.fprAccount = identity;
        },
        identity: function (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }


            Account.get()
                .then(function (account, a,b ) {
                    _identity = (account.data && account.data.status != 401) ? account.data :  undefined ;
                    window.fprAccount = _identity;
                    _authenticated = !!_identity;
                    if((""+account.status).substr(0, 1) == 5){
                        $rootScope.$broadcast("criticalServerError");
                        return false;
                    }
                    if(_authenticated)$rootScope.$broadcast("loginSuccessfull");
                    deferred.resolve(_identity);
                })
                .catch(function() {
                    _identity = undefined;
                    _authenticated = false;
                    deferred.resolve(_identity);
                });
            return deferred.promise;
        }

    };
});

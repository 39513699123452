'use strict';

var AssignTemplateController;
AssignTemplateController =
function($rootScope, $scope, TemplateService, GroupShopService, UserService, AssignTemplateServiceForTemplates, AssignTemplateServiceForUsers, AssignTemplateServiceForGroups) {


  $scope.loadUsers = function() {
        AssignTemplateServiceForUsers.query({}, function(result) {
            $scope.users = result;
        })
    };



    $scope.selectTemplate = function(template) {
        AssignTemplateServiceForTemplates.get({id: template.id}, function(result) {
            $scope.selectedTemplate = result;
            $scope.selectedGroupShops = [];
            angular.forEach(result.groups, function(group, groupKey) {
                $scope.selectedGroupShops.push(_.find($scope.groups, {name: group.name}));
            });
        });
    }

    $scope.save = function() {

        if($scope.templateChanged) {
            $scope.selectedTemplate.groups = $scope.selectedGroupShops;
            AssignTemplateServiceForTemplates.update($scope.selectedTemplate, function(result, headers) {
                $scope.selectTemplate($scope.selectedTemplate);
                $scope.templateChanged = false;
                $rootScope.$broadcast("templateSaveSuccess");
            });
        }
        if($scope.groupChanged) {
            $scope.selectedGroup.users = $scope.selectedUsers;
            AssignTemplateServiceForGroups.update($scope.selectedGroup, function () {
                $scope.selectGroup($scope.selectedGroup.id);
                $scope.groupChanged = false;
                $rootScope.$broadcast("groupSaveSuccess");
            });
        }
    }

    $scope.init = function() {
        $scope.loadTemplates();
        $scope.loadGroups();
        $scope.loadUsers();
    };






  $scope.divisions;
  $scope.clusters;
  $scope.cities;
  $scope.results;
  $scope.limitSet;
  $scope.templateChanged = false;
  $scope.groupChanged = false;
  $scope.selectedGroup;

      $scope.loadTemplates = function() {
          AssignTemplateServiceForTemplates.query({page: 0, per_page: 0}, function(result) {
              $scope.templates = result;
          });
      }

  		// $scope.selectTemplate = function(template) {
  		// 	$scope.selectedTemplate = template;
  		// }

  		$scope.selectGroup = function(group) {
          $scope.selectedGroup = group;
      }

      $scope.loadGroups = function() {
          AssignTemplateServiceForGroups.query({}, function(result) {
              $scope.groups = result;
          });
      }

      $scope.init()
    //   $scope.loadDivisions = function() {}
    //   $scope.loadClusters = function() {}
    //   $scope.loadCities = function() {}
    //   $scope.setLimit = function() {}
    //   $scope.save = function() {}

  		// $scope.init = function() {}

}

angular.module('smartApp').controller('CommonAssignTemplateController', AssignTemplateController);

AssignTemplateController.$inject = [
  '$rootScope',
  '$scope',
  'TemplateService',
  'GroupShopService',
  'UserService',
  'AssignTemplateServiceForTemplates',
  'AssignTemplateServiceForUsers',
  'AssignTemplateServiceForGroups'
];

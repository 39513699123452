'use strict';

angular.module('smartApp').controller('ProcessWizardController', function(FormFactory, $anchorScroll, $location, ngDialog, ProcessGroupService, $rootScope, $scope, GroupShopService, TemplateService, CategoryService, Notifications, config   ) {

    var _this = $scope;

    $scope.weightFormat = /^[0-9]*(\.[0-9]+)?$/;
	$scope.totalScore = 0;

	$scope.config = config;

	$scope.QUESTION_EDIT_MESSAGE = 'Изменение вопроса может привести к его изменению в существующих проверках. Не изменяйте смысл вопроса!';


    var tempProcess = {};

    // Alex
    $scope.down = function(question, category) { // Перемещает вопрос вниз
        var q = _.findWhere(category.questions, {order: question.order+100});
        question.order += 100;
        q.order -= 100;
    };
    $scope.up = function(question, category) { // Перемещает вопрос вверх
        var q = _.findWhere(category.questions, {order: question.order-100});
        question.order -= 100;
        q.order += 100;
    };

    $scope.setActiveProcess = function(process, parentId) {
        $scope.activeProcessId = parentId;
        $scope.activeProcess = process;
        $scope.activeProcessParentId = parentId;
        angular.copy($scope.activeProcess, tempProcess);
	    $scope.setState('edit');
    };

    $scope.initCategories = function(criteria) {
        $scope.activeProcess = undefined;
        CategoryService.getAllRootGroups()
            .success(function(data) {
                $scope.categories = data;
                angular.forEach($scope.categories, function(c, cKey) {
                    c.collapsed = ($scope.activeProcessId && (c.id == $scope.activeProcessId)) ? false : true;
	                c.children = c.children.sort(function (x, y) {
		                return x.orderInTmpl - y.orderInTmpl;
	                });
                });
                if(criteria) {
                    angular.forEach($scope.categories, function(c, cKey) {
                        //
                        angular.forEach(c.children, function(ch, chKey) {
                            if(criteria == ch.parent.id || criteria == ch.id) {
                                $scope.setActiveProcess(ch, c.id);
                            }
                        });
                    });
                }

                $scope.setJQueryClickListener();
            }
        );
    };

    $scope.createForm = function(form){
        return new FormFactory(form);
    };


    //СМЕНИТЬ ДИСПОЗИЦИЮ
    $scope.changeParent = function() {
        ngDialog.open(
            {
                template: 'changeParent',
                controller: ['$rootScope', '$scope', 'CategoryService', function($rootScope, $scope, CategoryService) {
                    $scope.initAllGroups = function() {
                        CategoryService.getAllGroups()
                            .success(function(data) {
                                $scope.allGroups = data;
                            }
                        );
                    };

                    $scope.createNewProcess = function(parent) {
                        CategoryService.changeProcessParent({parent: parent.id, process: _this.activeProcess.id})
                            .then(function() {
                                $rootScope.$broadcast("saveSuccess");
                                _this.initCategories();
                            });
                    };
                    $scope.initAllGroups();
                }],
                className: 'ngdialog-theme-plain',
                showClose: false
            }
        );
    };

    //СОЗДАНИЕ ПРОЦЕССА
    $scope.createCategory = function() {
        ngDialog.open(
            {
                template: 'createProcess',
                controller: ['$rootScope', '$scope', 'CategoryService', function($rootScope, $scope, CategoryService) {
                    $scope.progress = 0;
                    $scope.changeType = function(type){
                        if(type == 1 && !$scope.categories){
                            $scope.initCategories();
                        }
                    };
                    $scope.initCategories = function() {
                        $scope.progress++;
                        CategoryService.getAllProcesses()
                            .success(function(data) {
                                $scope.progress--;
                                $scope.categories = data;
                            });
                    };
                    $scope.initAllGroups = function() {
                        $scope.progress++;
                        CategoryService.getAllGroups()
                            .success(function(data) {
                                $scope.progress--;
                                $scope.allGroups = data;
                            });
                    };
                    $scope.createNewProcess = function(parent) {
                        CategoryService.saveNewProcess({name: $scope.selectedProcessName, children: [], parent: parent, group: false, questions: [], orderInTmpl: (parent.children.length + 1) * 100})
                            .then(function() {
                                $rootScope.$broadcast("newProcess");
                                _this.initCategories();
                            });
                    };
                    $scope.copyNewProcess = function(parent) {
                        CategoryService.copyProcess({id: $scope.selectedProcess.id, name: $scope.selectedProcess.name, children: [], parent: parent, group: false, questions: $scope.selectedProcess.questions, orderInTmpl: (parent.children.length + 1) * 100})
                         CategoryService.copyProcess($scope.selectedProcess.id)
                            .then(function() {
                                $rootScope.$broadcast("newProcess");
                                _this.initCategories();
                            });
                    };
                    //$scope.initCategories();
                    $scope.initAllGroups();
                }],
                className: 'ngdialog-theme-plain',
                showClose: false
            }
        );
    };

    //СОЗДАНИЕ ГРУППЫ ПРОЦЕССОВ
    $scope.createProcessGroup = function() {
        ngDialog.open(
            {
                template: 'createProcessGroup',
                controller: ['$rootScope', '$scope', 'CategoryService', function($rootScope, $scope, CategoryService) {
                    $scope.initRootGroups = function() {
                        CategoryService.getAllRootGroups()
                            .success(function(data) {
                                $scope.rootGroups = data;
                            }
                        );
                    };
                    $scope.initAllGroups = function() {
                        CategoryService.getAllGroups()
                            .success(function(data) {
                                $scope.allGroups = data;
                            }
                        );
                    };
                    $scope.createNewProcessGroup = function(parent) {
                        CategoryService.saveNewProcess({name: $scope.selectedProcessGroupName, children: [], parent: ((parent == undefined) ? null : parent), group: true})
                            .then(function() {
                                $rootScope.$broadcast("newProcessGroup");
                                _this.initCategories();
                            });
                    };
                    $scope.copyNewProcessGroup = function(parent) {
                        CategoryService.saveNewProcess({name: $scope.selectedProcessGroup.name, children: [], parent: ((parent == undefined) ? null : parent), group: true})
                            .then(function() {
                                $rootScope.$broadcast("newProcessGroup");
                                _this.initCategories();
                            });
                    };
                    $scope.initAllGroups();
                    $scope.initRootGroups();
                }],
                className: 'ngdialog-theme-plain',
                showClose: false
            }
        );
    };

	$scope.deleteProcess = function() {
		CategoryService.deleteProcess($scope.activeProcess)
			.then(function() {
				$rootScope.$broadcast("deleteProcess");
				$scope.initCategories();
			});
	};

	$scope.deleteProcessClick = function() {
		var process = $scope.activeProcess;
		$scope._showAcceptDialog('process', process, $scope.deleteProcess.bind($scope));
//		ngDialog.open(
//			{
//				template: 'prepareAcceptStatement',
//				controller: ['$scope', function($scope) {
//					$scope.name = process.name;
//					$scope.confirm = function() {
//						_this.deleteProcess();
//					};
//				}],
//				className: 'ngdialog-theme-plain',
//				showClose: false
//			}
//		);
	};

	$scope._showAcceptDialog = function (type, obj, callback) {
		ngDialog.open(
			{
				template: 'prepareAcceptStatement',
				controller: ['$scope', function($scope) {
					$scope.type = type;
					$scope.name = obj.name;
					$scope.confirm = function() {
						callback && callback();
					};
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};

    $scope.treeProcess = {
        dropped: function(event) {
	        var category = null;
	        for(var i = 0; i < $scope.categories.length; i++) {
				if($scope.categories[i].id === event.source.nodeScope.$parentNodeScope.lvl1Cat.id) {
					category = $scope.categories[i];
					break;
				}
	        }
	        var orders = new Array(category.children.length);
	        angular.forEach(category.children, function (item, index) {
		        item.orderInTmpl = (index + 1) * 100;
		        orders[index] = {
			      id: item.id,
			      order: item.orderInTmpl
		        };
	        });

	        CategoryService.updateOrders(orders);
	        return true;
        },

	    accept: function (source, dest) {
		    return !!dest.$nodeScope && dest.$nodeScope.lvl1Cat.id === source.$nodeScope.lvl1Cat.id;
	    },

	    beforeDrag: function (e) {
		    return !!e.lvl2Cat;
	    }
    };

    $scope.copyActiveProcess = function(data, parent) {
        //delete parent.collapsed;
        CategoryService.copyProcess(data.id).then(function(output) {
            $scope.initCategories(data.id);
        });
    };

	$scope.setState = function (state) {
		$scope.state = state;
		$scope.totalScore = 0;

		if(state === 'emulation') {
			angular.forEach($scope.activeProcess.questions, function (question) {
				question.answer = null;
			});
		}
	};
	$scope.setState('edit');

	$scope.answer = function (question, answer) {
		question.answer = Number(answer);
		$scope.calcScore();
	};

	$scope.calcScore = function () {
		var totalWeight = 0;
		var totalAnswers = 0;
		angular.forEach($scope.activeProcess.questions, function (question) {
			if((!question.answer && question.answer !== 0) || (question.answer && question.answer === 100)) return;
			var answer = question.answer === 100 ? 0 : question.answer;
			var weight = question.weight || 0;
			totalAnswers += answer * weight;
			totalWeight += weight;
		});

		$scope.totalScore = (totalAnswers / totalWeight).toFixed(2);
	};

    //TODO: рефакторить и плакать || Я рыдал

    //подтверждаю. Рефакторил и плакал
    $scope.updateProcess = function() {
        // Раньше в системе был хитрый драг энд дроп, который драгал все и по окончания обновлял драгнутую сущность.
        // Нет полной уверенности, что комментировании этой строчки не пересекается с другим функционалом.
	    //if(!$scope.activeProcess.parent || !tempProcess.parent) return;
        if(!angular.equals($scope.activeProcess, tempProcess)) {
            angular.forEach($scope.activeProcess.questions, function (q, qKey) {
                if (!q.name) {
	                $scope.activeProcess.questions.splice(qKey, 1);
                }
                angular.forEach(q.subQuestions, function (sq, sqKey) {
                    if (!sq.name) {
	                    q.subQuestions.splice(sqKey, 1);
                    }
                })
            });
            if ($scope.activeProcess.group == true) {
                var childrenS = $scope.activeProcess.children;
                $scope.activeProcess.parent = null;
                CategoryService.saveNewProcess($scope.activeProcess)
                    .success(function (data) {
                        $rootScope.$broadcast("saveSuccess");
                        $scope.editingProcessName = false;

                        angular.forEach(childrenS, function (ch, chKey) {
                            CategoryService.updateProcess(ch, $scope.activeProcessId)
                                .success(function (data) {
                                    $scope.editingProcessName = false;
                                    $scope.initCategories($scope.activeProcess.id);
                                    $rootScope.$broadcast("GLOBAL_SAVE");
                                });
                        });
                    }
                )
            } else {
                CategoryService.updateProcess($scope.activeProcess, $scope.activeProcessParentId)
                    .success(function (data) {
                        $rootScope.$broadcast("saveSuccess");
                        $scope.editingProcessName = false;
                        $scope.initCategories($scope.activeProcess.id);
		                $scope.setState('edit');
                    });
            }
        } else {
            Notifications.danger("Нечего сохранять");
        }
    };

    $scope.$on("GLOBAL_SAVE", function(event) {
        $scope.initCategories();
    });

    $scope.initCategories();

    $scope.removeQuestion = function(category, question) {
	    $scope._showAcceptDialog('question', question, function () {
		    category.questions = _.without(category.questions, _.find(category.questions, question));
	    });
    };

    $scope.removeSubQuestion = function(question, subQuestion) {
	    $scope._showAcceptDialog('question', question, function () {
		    question.subQuestions = _.without(question.subQuestions, _.find(question.subQuestions, subQuestion));
	    });
    };

    $scope.newQuestion = function() {
        $scope.activeProcess.questions.push({order: $scope.activeProcess.questions.length * 100, 'name': '', 'weight': 1.0, 'required': false, 'blockCategory': false, 'blockChecklist': false, 'subQuestions': []});
    };

    $scope.setJQueryClickListener = function() {
        $('.btn').on('click', function(event) {
            //TODO: сделать прокрутку до нижней границы div'a, а не хер пойми чего
            $('div.custom-scroll').scrollTop(99999999);
        });
    }

});
<template>
    <div class="stock">
        <h1 class="title">STOCK</h1>
        <div class="navigation">
            <custom-button
                text="Inventory"
                @click="goTo('stockInventory')"
            ></custom-button>
            <custom-button
                text="Errors"
                @click="goTo('stockErrors')"
            ></custom-button>
        </div>
        <router-view :key="$route.name"></router-view>
    </div>
</template>

<script>
import { CustomButton } from '@eron/ui-kit';

export default {
    name: 'StockHome',
    components: { CustomButton },
    methods: {
        goTo(route) {
            this.$router.push({ name: route });
        },
    },
};
</script>

<style scoped lang="scss">
.app {
    padding: 15px;
}

.navigation {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.nav-button {
    margin-right: 10px;
}
</style>

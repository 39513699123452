var PanelBusinessLineController;

PanelBusinessLineController = function ($rootScope, $scope, PanelBusinessLineService, Pagination, Notifications, ngDialog, $log, $location, $anchorScroll, FormFactory, $q, OrgstructService) {
    $scope.changes = false;
    $scope.isLoading = true;
    $scope.searchBusinessLine = '';

    $scope.data = [];
    $scope.dataUsers = [];
    $scope.levelList = [];
    $scope.updateIdsUsers = [];
    $scope.addIdsUsers = [];
    $scope.deleteIdsUsers = [];
    $scope.usersSort = {sort: 'businessDirId', direction: 'desc'};

    $scope.tableSettings = {
        page: 0,
        limit: 10
    };
    $scope.filters = {};

    if (!$scope.linksUsers){
        $scope.linksUsers = angular.copy($scope.tableSettings);
    }

    $scope.getData = getData;
    $scope.setUsersSort = setUsersSort;
    $scope.getPage = getPage;
    $scope.getPageUsers = getPageUsers;
    $scope.goToCreateBusinessLine = goToCreateBusinessLine;
    $scope.createBusinessLine = createBusinessLine;
    $scope.clearSearch = clearSearch;
    $scope.confirmDelete = confirmDelete;
    $scope.editBusinessLine = editBusinessLine;
    $scope.errorValidation = errorValidation;
    $scope.createForm = createForm;
    $scope.setChanged = setChanged;
    $scope.getChecked = getChecked;
    $scope.saveChanges = saveChanges;
    $scope.saveChangesUsers = saveChangesUsers;
    $scope.getUsers = getUsers;
    $scope.doSearchUsers = doSearchUsers;
    $scope.doSearchBusinessLines = doSearchBusinessLines;
    $scope.checkedBusinessLine = checkedBusinessLine;
    $scope.saveCheckedUsers = saveCheckedUsers;

    $scope.pagination = Pagination.get(function(data) {
      this.viewPort = data;
    });

    init();

    function init (){
        getLevels();
        getData(true);
    }

    function getLevels (){
        OrgstructService.getLevels().then(function(result) {
            if(result.status === 200){
                $scope.levelList = result.data;
            }else{
                Notifications.danger('Ошибка! Не удается загрузить список уровней пользователей', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается загрузить список уровней пользователей', '', 2000);
            $log.log(error);
        });
    }

    function setChanged () {
        $scope.changes = true;
    }

    function getChecked () {
        return $scope.data ? $scope.data.filter(function (e) {
            return e.checked;
        }) : [];
    }

    function setUsersSort(sort) {
        $scope.reverse = ($scope.usersSort.sort === sort) ? !$scope.reverse : false;
        $scope.usersSort.direction = ($scope.usersSort.sort === sort && $scope.usersSort.direction === 'desc') ? 'asc' : 'desc';
        $scope.usersSort.sort = sort;
        $scope.doSearchUsers();
    }

    function getPage(page) {
      $scope.pagination.page = page;
      $scope.getData();
    }

    function getPageUsers (page, clear) {
      if (page || page === 0) {
        $scope.linksUsers.page = page;
      } else if (clear) {
        $scope.filters = { limit: 10 };
        $scope.linksUsers = { page: 1, limit: 10 };
      }

      $scope.addIdsUsers = [];
      $scope.deleteIdsUsers = [];
      $scope.doSearchUsers();
    }

    function getData (isInit) {
        var requestObj = {
            page: $scope.pagination.page - 1,
            size: $scope.pagination.perPage,
            sort: 'name,desc'
        };

        if ($scope.searchBusinessLine.length > 0){
          requestObj.nameLike = $scope.searchBusinessLine;
        }

        PanelBusinessLineService.getAllBusinessDirs(requestObj).then(function(result) {
            if(result.status === 200){
                $scope.data = result.data;

                if (isInit && $scope.data && $scope.data.length) {
                    $scope.editBusinessLine($scope.data[0].id, true);
                }

                var headers = result.headers();

                $scope.pagination.total = parseInt(headers['x-total-count']);
                $scope.pagination.pageChange($scope.data);
            } else{
                Notifications.danger('Ошибка! Не удается загрузить список бизнес-направлений', '', 2000);
            }

            $scope.isLoading = false;
        }, function(error) {
            Notifications.danger('Ошибка! Не удается загрузить список бизнес-направлений', '', 2000);
            $log.log(error);
            $scope.isLoading = false;
        });
    }

    function getUsers(requestObj, hasNoThrobber) {
        requestObj = requestObj || {};

        requestObj.fields = 'businessDir';
        requestObj.sort = $scope.usersSort.sort + ',' + $scope.usersSort.direction;

        PanelBusinessLineService.getUsersBusinessDir($scope.selectedRowId, requestObj, hasNoThrobber).then(function(result) {
            if(result.status === 200) {
                $scope.dataUsers = result.data;
                $scope.linksUsers = {
                    page: parseInt(result.headers()["x-page-number"]) + 1,
                    limit: parseInt(result.headers()["x-page-size"]),
                    totalElements: parseInt(result.headers()["x-total-count"]),
                    totalPages: parseInt(result.headers()["x-total-pages"])
                };
                $scope.filters.limit = $scope.linksUsers.limit;
            }else if(result.status === 404){
                Notifications.danger('Ошибка! Пользователи данного направления не найдены', '', 2000);
            }else{
                Notifications.danger('Ошибка! Не удается загрузить список пользователей', '', 2000);
            }
            $scope.isLoadingUsers = false;
        }, function(error) {
            Notifications.danger('Ошибка! Не удается загрузить список пользователей', '', 2000);
            $log.log(error);
            $scope.isLoadingUsers = false;
        });
    }

    function checkedBusinessLine(idRow){
        return $scope.selectedRowId === idRow;
    }

    function editBusinessLine (id, hasNoThrobber) {

        $scope.selectedRowId = id;
        $scope.newBusinessLine = undefined;

        PanelBusinessLineService.getBusinessDir(id, hasNoThrobber).then(function(result) {
            if(result.status === 200){
                $scope.businessLine = result.data;
                getUsers({ size: $scope.linksUsers.limit, page: 0 }, hasNoThrobber);
            } else if (result.status === 404){
                Notifications.danger('Ошибка! Бизнес-направление отсутствует', '', 2000);
            } else {
                Notifications.danger('Ошибка! Не удается загрузить бизнес-направление', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается загрузить бизнес-направление', '', 2000);
            $log.log(error);
        });
    }

    function errorValidation () {
        if($scope.changes) {
            Notifications.danger('Не все поля заполнены верно');
        }
    }

    function createForm (form) {
        return new FormFactory(form);
    }

    function goToCreateBusinessLine () {
        $scope.selectedRowId = null;
        ngDialog.open({
            template: 'newBusinessLine',
            scope: $scope,
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        });
    }

    function createBusinessLine (newBL) {
        $scope.businessLine = undefined;
        if (newBL && newBL.name){
            PanelBusinessLineService.createBusinessDir({name: newBL.name}).then(function(result) {
                if(result.status === 200){
                    $scope.newBusinessLine = undefined;
                    Notifications.success('Бизнес-направление успешно добавлено', '', 2000);
                    ngDialog.closeAll();
                    $scope.getPage(1);
                }else{
                    Notifications.danger('Ошибка! Не удается создать бизнес-направление', '', 2000);
                }
            }, function(error) {
                Notifications.danger('Ошибка! Не удается создать бизнес-направление', '', 2000);
                $log.log(error);
            });
        }else{
            $rootScope.$broadcast('formNotFilled');
        }
    }

    function saveChanges (geo) {
        if ($scope.changes || geo) {
            if ($scope.businessLine && ($scope.businessLine.name || $scope.businessLine.geoCheckStart || $scope.businessLine.geoCheckFinish)) { //information tab
                var sendingObj = {}
                if(!geo && $scope.businessLine.name){
                    sendingObj = {
                        name: $scope.businessLine.name,
                        createTaskForShop: $scope.businessLine.createTaskForShop
                    }
                }else if(geo){
                    sendingObj = {
                        geoCheckStart: angular.copy($scope.businessLine.geoCheckStart),
                        geoCheckFinish: angular.copy($scope.businessLine.geoCheckFinish)
                    }
                }

                PanelBusinessLineService.updateBusinessDir($scope.businessLine.id, sendingObj).then(function(result) {
                    if (result.status === 200){
                        Notifications.success('Ваши изменения сохранены', '', 2000);
                        $scope.changes = undefined;
                        getData();
                        editBusinessLine($scope.businessLine.id);
                    } else if (result.status === 404){
                        Notifications.danger('Ошибка! Бизнес-направление отсутствует', '', 2000);
                    } else {
                        Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
                    }
                }, function(error) {
                    Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
                    $log.log(error);
                });
            } else {
                return $rootScope.$broadcast('formNotFilled');
            }
        } else {
            Notifications.danger('Ошибка', 'Нечего сохранять', 2000);
        }
    }

    function saveCheckedUsers(userId, add){
      var revertChangesArr = add ? $scope.deleteIdsUsers : $scope.addIdsUsers;
      var updateArr = add ? $scope.addIdsUsers : $scope.deleteIdsUsers;

      var i = revertChangesArr.indexOf(userId);

      if (i !== -1) {
        revertChangesArr.splice(i, 1);
      }

      updateArr.push(userId);
    }

    function addUserInBusinessLine(addIdsUsers){
        PanelBusinessLineService.addUserInBusinessDir($scope.selectedRowId, addIdsUsers).then(function(result) {
            if (result.status === 200){
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.addIdsUsers = [];
                getData();
                editBusinessLine($scope.businessLine.id);
            } else if (result.status === 404){
                Notifications.danger('Ошибка!', 'Не найдено бизнес-направление', 2000);
            } else {
                Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            $log.log(error);
        });
    }

    function deleteUserFromBusinessLine(deleteIdsUsers){
        PanelBusinessLineService.deleteUserFromBusinessDir($scope.selectedRowId, deleteIdsUsers).then(function(result) {
            if (result.status === 200) {
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.deleteIdsUsers = [];
                getData();
                editBusinessLine($scope.businessLine.id);
            } else if (result.status === 404) {
                Notifications.danger('Ошибка!', 'Не найдено бизнес-направление', 2000);
            } else {
                Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается сохранить изменения', '', 2000);
            $log.log(error);
        });
    }

    function saveChangesUsers () {
        if ($scope.addIdsUsers.length || $scope.deleteIdsUsers.length){
            if ($scope.addIdsUsers.length) addUserInBusinessLine($scope.addIdsUsers);
            if ($scope.deleteIdsUsers.length) deleteUserFromBusinessLine($scope.deleteIdsUsers);
        } else {
            Notifications.danger('Нечего сохранять', '', 2000);
        }
    }

    function doSearchBusinessLines() {
        $scope.selectedRowId = null;
        $scope.getPage(1);
    }

    function doSearchUsers() {
        var requestObjSearch = {};

        if ($scope.filters.login) requestObjSearch.loginLike = $scope.filters.login;
        if ($scope.filters.email) requestObjSearch.emailLike = $scope.filters.email;
        if ($scope.filters.firstName) requestObjSearch.firstNameLike = $scope.filters.firstName;
        if ($scope.filters.lastName) requestObjSearch.lastNameLike = $scope.filters.lastName;
        if ($scope.filters.level) requestObjSearch.levels = $scope.filters.level;
        if ($scope.filters.noGroups !== undefined) requestObjSearch.hasGroup = !$scope.filters.noGroups;
        if ($scope.filters.hasBusinessDir !== undefined) requestObjSearch.hasBusinessDir = $scope.filters.hasBusinessDir;
        //requestObjSearch.page = 0;
        if ($scope.filters.limit) requestObjSearch.size = $scope.filters.limit;
        requestObjSearch.page = $scope.linksUsers.page - 1;
        getUsers(requestObjSearch);
    }

    function confirmDelete () {
        ngDialog.openConfirm({
            template: 'scripts/app/common/confirm-dialog.template.html',
            scope: $scope,
            data: {
                title: 'Вы действительно хотите удалить выбранные бизнес-направления?'
            }
        }).then(function(e) {
            $scope.selectedRowId = null;
            deleteBusinessLine();
        }, function () {});
    }

    function deleteBusinessLine () {
        var ref, row;
        var ids = [];
        ref = $scope.data;

        for (var j = 0; j < ref.length; j++) {
            row = ref[j];
            if (row.checked) {
                ids.push(row.id);
            }
        }

        PanelBusinessLineService.deleteBusinessDir(ids).then(function(result) {
            if(result.status === 200) {
                Notifications.success('Выбранные бизнес-направления удалены', '', 2000);
                $scope.getPage(1);
            }else if(result.status === 404){
                Notifications.danger('Ошибка! Бизнес-направление отсутствует', '', 2000);
            }else if(result.status === 409){
                Notifications.danger('Невозможно удалить бизнес-направление, к которому привязаны пользователи', '', 2000);
            }else {
                Notifications.danger('Ошибка! Не удалось удалить все бизнес-направления', '', 2000);
            }
        }, function(error) {
            Notifications.danger('Ошибка! Не удается удалить бизнес-направления', '', 2000);
            $log.log(error);
        });
    }

    function clearSearch () {
        $scope.searchBusinessLine = '';
        getPage(1);
    }

    $scope.geoSave = function(){

    }
};

angular.module('smartApp').controller('PanelBusinessLineController', PanelBusinessLineController);

PanelBusinessLineController.$inject = ['$rootScope', '$scope', 'PanelBusinessLineService', 'Pagination', 'Notifications', 'ngDialog', '$log', '$location', '$anchorScroll', 'FormFactory', '$q', 'OrgstructService'];

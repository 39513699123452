angular.module('smartApp').controller('AddCheckController', function($state, Principal, ChecklistEditor, SystemParamsService, $scope, ObjectServiceForShops, ObjectServiceForTemplates, Notifications) {
	ObjectServiceForTemplates.query({}, function(result) {
		$scope.templates = _.sortBy(result, ['name']);
	});
	ObjectServiceForShops.query({view: 'dashboard'}, function (data, headers) {
		var shops = data;

		$scope.shops = shops.map(function (shop) {
			shop.name = shop.locality + ' ' + shop.sap;
			return shop;
		});

	});

	$scope.calendarsOpen = [];

	$scope.open = function($event, id) {
		$event.preventDefault();
		$event.stopPropagation();

		$scope.calendarsOpen[1] = false;
		$scope.calendarsOpen[2] = false;
		$scope.calendarsOpen[id] = true;
	};

	$scope.check = {date:new Date(), teplate: undefined, shop: undefined}
	var systemProps = {}


	var getSystemProps = function(cb){
		SystemParamsService.getSystemProps(['checklist.daysToBegin', 'checklist.beginPlaningPeriodDay', 'checklist.enablePlanningDateControl'], function(err, params){
			if(err){
	    		Notifications.danger('Ошибка получения данных');
	    		cb(result.status)
	    		console.log("Err: ", err);
	    		return
	    	}

	    	cb(null, params)
		})
	}

	getSystemProps(function(err, data){
		if(err)return;
		systemProps = data


	});

	$scope.plan = function(){

		//copied from dashboard2.controller.js
		var planingControlEnabled = !!systemProps['checklist.enablePlanningDateControl'];

			if(choosedDate.isBefore(moment(), 'day')) {
				Notifications.danger("Ошибка", "Планирование проверок на прошедшую дату запрещено", 7000);
				return;
			}
			

			if (planingControlEnabled) {
				var isNextMonth = choosedDate.isSame(moment().add(1, 'month'), 'month');
				var isToday = choosedDate.isSame(moment(), 'day');

				if (!(isNextMonth || isToday)) {
					var limitText = ".";

						limitText =  " или на следующий месяц <i>(" + moment().add(1, 'month').format("MMMM YYYY") + ")</i>"

					Notifications.danger("Ошибка", "Проверку можно назначить на сегодняшний день" + limitText, 7000);

					return;
				}

			}


		var checklist = {
			resolverId: Principal.getIdentity().id,
			shopId: $scope.check.shop.id,
			templateId: $scope.check.template.id,
			resolveDate: $scope.check.date
		};

		ChecklistEditor.createChecklists([checklist])
			.then(function(result,a,headers){

				if(result.status == 200) {
					Notifications.success('Проверка назначена!');
					$state.go("home")
				}else if(result.headers('x-error') == 'ECP1'){
					Notifications.danger(result.data.message);
					console.log(1)
				}else{
					Notifications.danger('Изменения утеряны');
					console.log(2)
				}

			});
	}
})

angular.module('smartApp').factory('Filter3', checkReportFactory3);
checkReportFactory3.$inject = ['ChecklistService', 'DownloadService'];

function checkReportFactory3(ChecklistService, DownloadService) {
	return {
		id: 3,
		title: 'Фильтр 3',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter3/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter3/view.html',
		isValid: function(filters) {
			return filters.rangeFrom &&	filters.rangeTo
				&& filters.template;
		},
		load: function(filters) {
			return ChecklistService.getOrangeReport(filters);
		},
		download: function(filters) {
			return DownloadService.downloadOrangeReport(filters);
		}
	};
}
var CompareTimeService;

CompareTimeService = function() {
    return function(integrationTime, date) {
    	var comparedDate = date? moment(date) : moment();
        var minutesInDay = 60 * 24;

        return comparedDate.diff(integrationTime, 'minutes') >= minutesInDay ? false : true;
    }
};

angular.module('smartApp').factory('CompareTimeService', CompareTimeService);
'use strict';

angular.module('smartApp').factory('GroupShopService', function GroupShopService($http, config, HttpUtils) {

    return {
        getAllNotAssigned: function () {
            return $http.get(config.backend + '/groupShops/getAllNotAssigned')
                .success(function(data) {
                    return data;
                });
        },
        getAssigned: function(){
            var query = HttpUtils.getQuery('/groupShops/getAssigned');
            return $http.get(query);
        },
        getAllAssigned: function(templateId) {
            return $http.get(config.backend + '/groupShops/getAllAssigned?templateId=' + templateId)
                .success(function(data) {
                    return data;
                });
        },
        saveAssigned: function(groupShops, templateId) {
            return $http.post(config.backend + '/groupShops/saveAssigned?templateId=' + templateId, groupShops)
                .success(function(data) {
                    return data;
                });
        }
    }

});
angular.module('smartApp').factory('Xfilter6', checkXreportFactory6);
checkXreportFactory6.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory6(ChecklistService, DownloadService) {
	return {
		id: 11,
		title: 'Отчёты - Проверки & процессы',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter6/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter6/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo && filters.template && filters.process  && filters.process.length>0;
		},
		load: function(filters) {

			var t = ChecklistService.getXreport6(filters); 
			
			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport6(filters);
		}
	};

}
angular.module('smartApp').factory('DownloadService', function DownloadService($http, $window, $filter, moment, config, HttpUtils, localStorageService, ThrobberService, Notifications) {
	var checklists = null,
		format = 'DD-MM-YYYY';

	var getToken = function(){
		return localStorageService.get('token').token;
	};

	function downloadReport (request) {
		return downloadReportAsNative(request, {responseType: "arraybuffer"}, undefined, undefined, false);
	}

	function downloadFile(data, fileName, type, withBom) {
    	withBom = withBom !== false ? true : withBom;

		var name = "["+moment().format('DD-MM-YYYY')+"]";
		var blob = new Blob([withBom ? '\ufeff' : '', data], {
			type: type === '.zip' ? 'application/octet-stream' : "application/vnd.ms-excel"
		});

		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);

		if (fileName && type) {
		  link.download = fileName + type;
		} else if (fileName) {
		  link.download = fileName;
		} else {
		  link.download = name + ".xls";
		}

			link.click();
		}

	function downloadReportAsNative (request, params, fileName, type, withBom){ //из-за изменений на беке downloadReport не подходит
        return $http.get(request, params).then(function(result) { 
            if(result.status === 200 ||  result.usersExcel.status === 201){
								downloadFile(result.data, fileName, type, withBom);
								Notifications.success('Файл успешно загружен');
            }else{
                Notifications.danger('Ошибка! Невозможно загрузить файл');
            }
        }, function(error) {
            Notifications.danger('Ошибка! Невозможно загрузить файл');
            $log.log(error);
        });
	}

    function downloadPdfAsNative (request, params){
        $http.get(request, params).then(function(result) {
            if(result.status === 200){
                var blob = new Blob([result.data], {
                    type: "application/pdf"
                });
                FileSaver.default(blob, "["+moment().format('DD-MM-YYYY')+"].pdf");
                Notifications.success('Файл успешно загружен');
            }else{
                Notifications.danger('Ошибка! Невозможно загрузить файл');
            }
        }, function(error) {
            Notifications.danger('Ошибка! Невозможно загрузить файл');
            $log.log(error);
        });
    }

	function getFiltersDownloadXreport8 (filters){
        var sapList = [];
        for(var i in filters.shop){
            sapList.push(filters.shop[i].id)
        }

		return {
            fromDate: moment(filters.rangeFrom).format(format),
            toDate: moment(filters.rangeTo).format(format),
            userIds: (filters.fio)?filters.fio.map(function(e){return e.id}):undefined,
            shopIds: sapList,
            templateIds: (filters.template && filters.template.length > 0 && filters.template.map(function(e){return e.id}).toString()) || null,
            processIds: (filters.process && filters.process.length > 0 && filters.process.map(function(e){return e.id}).toString()) || null,
            questionIds:(filters.question && filters.question.length > 0 && filters.question.map(function(e){return e.id}).toString()) || null,
            divisionIds: (filters.division && filters.division.length > 0 && filters.division.map(function(e){return e.id}).toString()) || null,
            groupUser: filters.groups && filters.groups.map(function(e){return e.id}),
            dateType: filters.dateType,
            token: getToken(),
            checklistIds: filters.checklistIds && filters.checklistIds.split(",").map(function(item){
                return item.trim()
            })
		}
	}

    function getFiltersDownloadXreport3 (filters){
        return {
            fromDate: moment(filters.rangeFrom).format('YYYY-MM-DD'),
            toDate: moment(filters.rangeTo).format('YYYY-MM-DD'),

            groupId: (filters.group)? filters.group.id: undefined,
            userIds: (filters.userIds && filters.userIds.length > 0 && filters.userIds.map(function(e){return e.id})) || undefined,
            regionIds: (filters.regionIds && filters.regionIds.length > 0 && filters.regionIds.map(function(e){return e.id})) || undefined,
            divisionIds: (filters.divisionIds && filters.divisionIds.length > 0 && filters.divisionIds.map(function(e){return e.id})) || undefined,
            shopIds: (filters.shop && filters.shop.length > 0 && filters.shop.map(function(e){return e.id})) || undefined,
            templateIds: (filters.checklist)?[filters.checklist.id]:undefined,
            checklistType: filters.plan,
            token: getToken(),
            dateType: filters.dateType,
            checklistIds: filters.checklistIds && filters.checklistIds.split(",").map(function(item){
                return item.trim()
            }),
            businessDirIds: (filters.businessDirIds && filters.businessDirIds.length > 0 && filters.businessDirIds.map(function(e){return e.id})) || undefined
        }
    }

    function getFormsParams(filters) {
      return {
        dateType: filters.dateType,
        fromDate: moment(filters.rangeFrom).format(format),
        toDate: moment(filters.rangeTo).format(format),
        divisions: filters.divisionIds && filters.divisionIds.map(division => division.id),
        regions: filters.clusterIds && filters.clusterIds.map(region => region.id),
        userGroupIds: filters.groups && filters.groups.map(group => group.id),
        executorIds: filters.fio && filters.fio.map(user => user.id),
        shopIds: filters.shops && filters.shops.map(shop => shop.id),
        templateFormIds: filters.formsTemplates && filters.formsTemplates.map(template => template.id),
        groupFormIds: filters.formsGroups && filters.formsGroups.map(group => group.id),
        pointFormIds: filters.formsPoints && filters.formsPoints.map(point => point.id),
        taskIds: !filters.taskIds ? undefined : filters.taskIds.split(",").map(item => parseInt(item.trim())),
        makeXls: true
      };
    }

	return {
		downloadFile: downloadFile,

		downloadFileFromUrl: function(url, cb){
			var config = {headers: {
        			'Accept': "text/html"
			    }
			};
			$http.get(url, config).then(function (response)             {
			    cb((response.status == 200)?null:'download error', response.data)
			})
		},

		downloadHRReport: function (filters) {
			var token = getToken();
			var params = {
				token: token,
				month: filters.month
			};
			var request = HttpUtils.getQuery('/files/downloadHRReport', params);
			return downloadReport(request);
		},

        downloadAdminTemplate: function(templateId) {
	        var token = getToken();
	        var params = {
				token: token,
		        templateId: templateId
	        };

	        var request = HttpUtils.getQuery('/files/downloadAdminTemplateReport', params);
	        return downloadReport(request);
        },

				downloadAdminTemplateExcel: function(templateId) {
	        var params = {
		        templateId: templateId
	        };

	        var request = HttpUtils.getQuery('/reports/reportBlockProcessQuestion', params);
	        return downloadReport(request);
        },

		downloadResolverChecklist: function(checklistId, format, viewMode) {
			var token = localStorageService.get('token').token;

			var params = {
				token: token,
				checklistId: checklistId,
				viewMode: viewMode,
				format: format
			};
			var request = HttpUtils.getQuery('/files/downloadChecklistGradesReport', params);
			return downloadReport(request);
		},

		downloadEngineerReport: function(divisionIds) {
			var params = {
				divisionIds: divisionIds,
				token: getToken()
			};
			var query = HttpUtils.getQuery('/reports/counterDownload', params);
			return downloadReport(query);
		},

        downloadWopReport: function (templateId, queryFromDate, queryToDate) {
            var fromDate = formatDate(queryFromDate);
            var toDate = formatDate(queryToDate);
            return downloadReport(config.backend + '/files/downloadWopReport?templateId=' + templateId + '&queryFromDate=' + fromDate + '&queryToDate=' + toDate);
        },

		downloadUsersExport: function() {
			return downloadReport(config.backend + '/files/exportUsers');
		},

		downloadShopsExport: function(shopIds) {
			var query = config.backend + '/admin/shops_new/xls?shopIds=' + shopIds;
            downloadReportAsNative(query, {responseType: "arraybuffer"});
		},

        // Download excels
		downloadGreenReport: function (filters) {
			var params = {
				queryFromDate: moment(filters.rangeFrom).format(format),
				queryToDate: moment(filters.rangeTo).format(format),
				queryProcesses: filters.process && filters.process.map(function(e){return e.id}),
				queryQuestions: filters.question && filters.question.map(function(e){return e.id}),
				queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
				queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
				queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
				queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
				queryRoles: filters.role && filters.role.map(function(e){return e.name}),
				queryTemplates: [filters.template.id],
				token: getToken()
			};
			var query = HttpUtils.getQuery('/files/downloadGreenReport', params);
			return downloadReport(query);
		},

			downloadVioletReport: function (filters) {
				var params = {
					queryFromDate: moment(filters.rangeFrom).format(format),
					queryToDate: moment(filters.rangeTo).format(format),
					queryProcesses: filters.process && filters.process.map(function(e){return e.id}),
					queryQuestions: filters.question && filters.question.map(function(e){return e.id}),
					queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
					queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
					queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
					queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
					queryRoles: filters.role && filters.role.map(function(e){return e.name}),
					queryTemplates: [filters.template.id],
					token: getToken()

				};
				var query = HttpUtils.getQuery('/files/downloadVioletReport', params);
				return downloadReport(query);
			},
			downloadOrangeReport: function (filters) {
				var params = {
					queryFromDate: moment(filters.rangeFrom).format(format),
					queryToDate: moment(filters.rangeTo).format(format),
					queryTemplates: [filters.template.id],
					queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
					queryShops: filters.shop && filters.shop.map(function(e){return e.id}),

					token: getToken()

				};
				var query = HttpUtils.getQuery('/files/downloadOrangeReport', params);
				return downloadReport(query);
			},

			downloadLightBlueReport: function (filters) {
				var params = {
					queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
					queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
					queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
					queryShops: filters.shop && filters.shop.map(function(e){return e.id}),
					queryRoles: filters.role && filters.role.map(function(e){return e.name}),

					token: getToken()
				};
				var query = HttpUtils.getQuery('/files/downloadLightBlueReport', params);
				return downloadReport(query);
			},

			downloadAccreditationChecklistReport: function(filters) {
				var params = {
					fromDate: moment(filters.dateFrom).format(format),
					toDate: moment(filters.dateTo).format(format),
					shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
					clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
					divisionIds: filters.division && filters.division.map(function(e){return e.id} ),
					resolverIds: filters.fio && filters.fio.map(function(e){return e.id}),
					checklistId: filters.checklistId,
					templateIds: filters.template && filters.template.id
				};
				var query = HttpUtils.getQuery('/auditor/downloadAccreditationChecklistReport', params);
				return downloadReport(query);
			},

			downloadAccreditationCategoryReport: function(filters) {
				var params = {
					fromDate: moment(filters.dateFrom).format(format),
					toDate: moment(filters.dateTo).format(format),
					shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
					clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
					divisionIds: filters.division && filters.division.map(function(e){return e.id}),
					resolverIds: filters.fio && filters.fio.map(function(e){return e.id}),
					checklistId: filters.checklistId,
					templateIds: filters.template && filters.template.id
				};
				var query = HttpUtils.getQuery('/auditor/downloadAccreditationCategoryReport', params);
				return downloadReport(query);
			},

		downloadAccreditationAct: function(checklistId) {
			var params = {
				checklistId: checklistId
			};
			var query = HttpUtils.getQuery('/auditor/downloadAccreditationAct', params);
			return downloadReport(query);
		},

			downloadLightGreyReport: function (filters) {
				var params = {
					queryFromDate: moment(filters.rangeFrom).format(format),
					queryToDate: moment(filters.rangeTo).format(format),
					queryTemplates: [filters.template.id],
					queryDivisions: filters.division && filters.division.map(function(e){return e.id}),
					queryClusters: filters.cluster && filters.cluster.map(function(e){return e.id}),
					queryProcessName: filters.process && [filters.process.name],
					queryResolvers: filters.fio && filters.fio.map(function(e){return e.id}),
					queryRoles: filters.role && filters.role.map(function(e){return e.name}),
					token: getToken()
				};
				var query = HttpUtils.getQuery('/files/downloadLightGreyReport', params);
				return downloadReport(query);
			},

			downloadXreport3: function (filters) {
				var params = getFiltersDownloadXreport3(filters);
				params.xls = true;

				return $http.post('/api/reports/report3', params, { responseType: "arraybuffer" }).then(function(result) {
						if (result.status === 200) {
							downloadFile(result.data, `Проверки_${params.fromDate}-${params.toDate}`, '.csv', false);
							Notifications.success('Файл успешно загружен');
						} else {
							Notifications.danger('Ошибка! Невозможно загрузить файл');
						}
				}, function(error) {
						Notifications.danger('Ошибка! Невозможно загрузить файл');
						$log.log(error);
				});
			},

			downloadXreport5: function (filters) {
				var params = {
					token: getToken(),
					fromDate: moment(filters.rangeFrom).format(format),
					toDate: moment(filters.rangeTo).format(format),
					divisionIds: filters.divisionIds.map(function(e){return e.id}),
					shopIds: filters.shops && filters.shops.map(function(e){return e.id}),
					templateIds: filters.templates && filters.templates.map(function(e){return e.id}),
					status: filters.finishStatus,
					expiration: filters.missed,
					processIds: filters.process && filters.process.map(function(e){return e.id}),
					questionIds: filters.question && filters.question.map(function(e){return e.id}),
					businessDirIds: filters.businessDirIds && filters.businessDirIds.map(function(e){return e.id}),
					xls: true
				};
				var query = HttpUtils.getQuery('/reports/report5', params);
				return downloadReport(query);
			},

			downloadXreport8: function (filters) {
                var params = getFiltersDownloadXreport8(filters);
                params.xls = true;
				var query = HttpUtils.getQuery('/reports/report5_new', params);
				return downloadReport(query);
			},
			downloadXreport9: function (filters) {
				var params = {
					fromDate: moment(filters.rangeFrom).format(format),
					toDate: moment(filters.rangeTo).format(format),
					sqlGradeReportType: filters.gradeType,
					dateType: filters.dateType,
					shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
					templateIds: filters.template && filters.template.map(function(e){return e.id}),
					categoryIds: filters.categories && filters.categories.map(function(e){return e.id}),
					subCategoryIds: filters.subcategories && filters.subcategories.map(function(e){return e.id}),
					processIds: filters.processes && filters.processes.map(function(e){return e.id}),
					divisionIds: filters.division && filters.division.map(function(e){return e.id}),
					clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
					token: getToken(),
					xls: true

				};
				var query = HttpUtils.getQuery('/reports/report_check_grade', params);
				return downloadReport(query);
			},
			downloadXreport10: function (filters) {
				var params = {
					fromDate: moment(filters.rangeFrom).format(format),
					toDate: moment(filters.rangeTo).format(format),
					shopIds: filters.shop && filters.shop.map(function(e){return e.id}),
					templateIds: filters.template && filters.template.map(function(e){return e.id}),
					divisionIds: filters.division && filters.division.map(function(e){return e.id}),
					clusterIds: filters.cluster && filters.cluster.map(function(e){return e.id}),
					token: getToken(),
					xls: true
				};
				var query = HttpUtils.getQuery('/reports/reportDirectorDidNotMakeCheck', params);
				return downloadReport(query);
			},
			downloadXreport11: function (filters) {
				var params = {
					contractorServicesDateFrom: moment(filters.rangeFrom).format(formatRevert),
					contractorServicesDateTo: moment(filters.rangeTo).format(formatRevert),
					shopIds: (filters.shop && filters.shop.length > 0 && filters.shop.map(function(e){return e.id}).toString()) || null,
					divisionIds: (filters.division && filters.division.length > 0 && filters.division.map(function(e){return e.id}).toString()) || null,
					regionsIds: (filters.cluster && filters.cluster.length > 0 && filters.cluster.map(function(e){return e.id}).toString()) || null,
					contractorIds: (filters.contractor && filters.contractor.length > 0 && filters.contractor.map(function(e){return e.id}).toString()) || null,
					page: filters.page,
					size: filters.perPage
				};
				var query = config.backendV2 + '/reports/contractors-to-blocks/basic';
				downloadReportAsNative(query, {params:params, responseType: "arraybuffer",  headers: {'Accept': 'application/vnd.ms-excel'}});
			},
			downloadXreport12: function (filters) {
				var params = {
					contractorServicesDateFrom: moment(filters.rangeFrom).format(formatRevert),
					contractorServicesDateTo: moment(filters.rangeTo).format(formatRevert),
					shopIds: (filters.shop && filters.shop.length > 0 && filters.shop.map(function(e){return e.id}).toString()) || null,
					divisionIds: (filters.division && filters.division.length > 0 && filters.division.map(function(e){return e.id}).toString()) || null,
					regionsIds: (filters.cluster && filters.cluster.length > 0 && filters.cluster.map(function(e){return e.id}).toString()) || null,
					contractorIds: (filters.contractor && filters.contractor.length > 0 && filters.contractor.map(function(e){return e.id}).toString()) || null,
					processIds: (filters.processes && filters.processes.length > 0 && filters.processes.map(function(e){return e.id}).toString()) || null,
					questionIds: (filters.question && filters.question.length > 0 && filters.question.map(function(e){return e.id}).toString()) || null,
					page: filters.page,
					size: filters.perPage
				};
				var query = config.backendV2 + '/reports/contractors-to-blocks/detailed';
				downloadReportAsNative(query, {params:params, responseType: "arraybuffer",  headers: {'Accept': 'application/vnd.ms-excel'}});
			},
        	downloadXreport8KPO: function(filters){
                var params = getFiltersDownloadXreport8(filters);
                var queryOne = config.backend + '/reports/report_KRO_list1';
                var queryTwo = config.backend + '/reports/report_KRO_list2';
                downloadReportAsNative(queryOne, {params:params, responseType: "arraybuffer"}, 'report_KRO_list1_' + params.fromDate + '-' + params.toDate, '.csv');
                downloadReportAsNative(queryTwo, {params:params, responseType: "arraybuffer"}, 'report_KRO_list2_' + params.fromDate + '-' + params.toDate, '.csv');
			},
			downloadXreport3KPO: function(filters){
				var params = getFiltersDownloadXreport3(filters);
				var queryOne = config.backend + '/reports/report_KRO1';
				downloadReportAsNative(queryOne, {params:params, responseType: "arraybuffer"}, 'report_KRO1_' + params.fromDate + '-' + params.toDate, '.csv');
			},
			downloadTaskAttachments: function(taskId){
				const params = {
					taskId: taskId,
					token: getToken()
				};
				return downloadReportAsNative('/api/tasks-manual/responses/zip', { params, responseType: 'arraybuffer' }, `Вложения по задаче ${taskId}`, '.zip', false);
			},
			downloadReportPdfCleaning: function(params){
                var query = config.backendV2 + '/cleaning/pdf-form';
                downloadPdfAsNative(query, {params:params, responseType: "arraybuffer",  headers: {'Accept': 'application/pdf'}});
			},
			downloadFormsReport: function(filters) {
        		var params = getFormsParams(filters);

				return $http.post(config.backend + '/reports/forms', params).then(result => {
					if (result.status !== 200) {
						Notifications.danger('Ошибка! Не удалось загрузить файл');
						return;
					}

					if (result.data === 'no data') {
						Notifications.danger('По вашему запросу ничего не найдено');
						return;
					}

					var headers = result.headers();
					var filename = headers['content-disposition'].split('filename=')[1].split('.');
					var name = filename[0];
					var ext = '.' + filename[1];

					downloadFile(result.data, name, ext);

					Notifications.success('Файл успешно загружен');
				});
			},

			downloadLogsReport(filters) {
				var body = {
					fromDate: (moment(filters.rangeFrom).format(format)) + ' 00:00',
					toDate: (moment(filters.rangeTo).format(format)) + ' 00:00'
				};

				return $http.post(config.backend + '/integration/forms/data/sapIntegration', body, {
          responseType: 'arraybuffer',
          headers: { 'Accept': 'application/vnd.ms-excel' }
        }).then(result => {
					if (result.status !== 200) {
						Notifications.danger('Ошибка! Не удалось загрузить файл');
						return;
					}

          var filename = result.headers('content-disposition').split('filename=')[1];

					downloadFile(result.data, filename, '.xls', false);

					Notifications.success('Файл успешно загружен');
				});
			},
		downloadFormsResultsReport(filters) {
			var params = getFormsParams(filters);

			return $http.post(config.backend + '/reports/inspectionPercentage', params).then(result => {
				if (result.status !== 200) {
					Notifications.danger('Ошибка! Не удалось загрузить файл');
					return;
				}

				if (result.data === 'no data') {
					Notifications.danger('По вашему запросу ничего не найдено');
					return;
				}

				var headers = result.headers();
				var filename = headers['content-disposition'].split('filename=')[1];

				downloadFile(result.data, filename, '.csv');

				Notifications.success('Файл успешно загружен');
			});
		}
	}
});

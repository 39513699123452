angular.module('smartApp').factory('filterFormsResults', ['FormsHttpService', 'DownloadService', function(FormsHttpService, DownloadService) {
  return {
		title: 'Отчет по итогам проверке наличия',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter-forms-results/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter-forms-results/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		load: function(filters) {
			return FormsHttpService.getFormsResultsReport(filters);
		},
		download: function(filters) {
			return DownloadService.downloadFormsResultsReport(filters);
		}
	};
}]);

var PanelRolesController;

PanelRolesController = function(
    $rootScope,
    $scope,
    $q,
    PanelRolesService,
    PanelUsersService,
    Notifications,
    $filter,
    OrgstructService,
    $timeout
) {
    $scope.userService = PanelUsersService;
    $scope.changes = false;
    $scope.isSelected = false;
    $scope.isLoading = true;
    $scope.selected_users = [];
    $scope.selected_row = null;
    $scope.role_name_to_search = null;
    $scope.tableSettings = {
        page: 1,
        limit: 100
    };
    $scope.usersPagination = {
        totalCount: null,
        offset: 5,
        index: 1,
        firstPage: 1,
        lastPage: 1
    };
    $scope.groupsPagination = {
        totalCount: null,
        offset: 5,
        index: 1,
        firstPage: 1,
        lastPage: 1
    };

    $scope.usersDistribution = {
      pagination: {
        page: 1,
        perPage: 20,
        totalItems: null
      },
      resetControl: false,
      searhTimeout: null,
      searchValue: '',
      loading: false,
      data: [],
      onBoxOnePageChange: function(page, user) {
        this.pagination.page = page;
        this.getUsers();
      },
      onBoxOneSearch: function(searchValue, user) {
        $timeout.cancel(this.searhTimeout);

        this.searhTimeout = $timeout(() => {
          this.pagination.page = -1;
          this.searchValue = searchValue;
          this.getUsers();
        }, 3000);
      },
      reset: function() {
        this.pagination.page = 1;
        this.resetControl = true;

        $timeout(() => {
          this.resetControl = false;
        });
      },
      getUsers: function(){
        var _this = this;
        var requestParamsUsers = {}
        requestParamsUsers.page = this.pagination.page
        requestParamsUsers.size = this.pagination.perPage
        requestParamsUsers.loginLike = (this.searchValue.length)?this.searchValue:undefined,
        requestParamsUsers.active = true
        // requestParamsUsers.fields="businessDir,roles,authType"
        // requestParamsUsers.sort="login,desc"

        OrgstructService.getUsers(requestParamsUsers).then(function(result){
            if (result.status === 200) {
              _this.data = result.data
              _this.pagination.totalItems = result.headers('x-total-count');
            }
        })
      }

    };

    $scope.data = [];
    $scope.allGroups = [];
    $scope.allUsers = [];

    $scope.getData = getData;

    var selectedUsersForRole = null;
    var selectedGroupsForRole = null;

    init();

    $scope.clear_input = function() {
        $scope.role_name_to_search = '';
        $scope.getPage(1);
    };
    $scope.searchRole = function() {
        $scope.data = $filter('filter')(
            $scope.data,
            $scope.role_name_to_search
        );
    };
    $scope.getPage = function(page) {
        $scope.tableSettings.page = page;
        $scope.getData();
    };

    $scope.setChanged = function() {
        $scope.changes = true;
    };

    $scope.isReadOnly = function() {
        return $scope.role && $scope.role.name === 'ROLE_SUPERVISOR';
    };



    $scope.edit_role = function(row, hasNoThrobber) {
        var handleRoleInfoLoad = function(data) {
            var selected_groups, selected_users;
            selected_users = [];
            selected_groups = [];
            angular.forEach(data.groups, function(group) {
                return selected_groups.push(
                    _.find($scope.allGroups, {
                        name: group.name
                    })
                );
            });

            angular.forEach(data.users, function(user) {
                return selected_users.push(
                    _.find($scope.allUsers, {
                        login: user.login
                    })
                );
            });

            // selectedUsersForRole = selected_users.slice();
            // selectedGroupsForRole = selected_groups.slice();

            $scope.role.users = selected_users;

            //updatePagination($scope.usersPagination, $scope.role.users);
            $scope.role.groups = selected_groups;
            console.log($scope.role)
            //updatePagination($scope.groupsPagination, $scope.role.groups);
        };

        var allUsersPromise = null;
        var allGroupsPromise = null;

        if (!$scope.allGroups.length) {
            allUsersPromise = PanelRolesService.getAllGroups(hasNoThrobber).success(function(result) {
              return ($scope.allGroups = result);
            });
        }

        if (!$scope.allUsers.length) {
            allGroupsPromise = PanelRolesService.getAllUsers(hasNoThrobber).success(function(result) {
              return ($scope.allUsers = result);
            });
        }

        $scope.selected_row = row.name;
        $scope.role = {
            name: row.name,
            description: row.description,
            users: [],
            groups: []
        };

        if (allUsersPromise || allGroupsPromise) {
          $q.all([allUsersPromise, allGroupsPromise]).then(function(result) {
            PanelRolesService.getRoleInfo(row.name, hasNoThrobber).success(handleRoleInfoLoad);
          });

          return;
        }

        return PanelRolesService.getRoleInfo(row.name, hasNoThrobber).success(handleRoleInfoLoad);
    };

    var updateRole = function(roleToUpdate) {
      if (!$scope.changes) {
        Notifications.danger('Ошибка', 'Нечего сохранять', 2000);
        return;
      }

      PanelRolesService.updateRole(roleToUpdate).success(function(result, status, headers) {
        if (status < 200 || status >= 300 || headers('x-error')) {
          Notifications.danger('Ошибка', 'Не удалось сохранить изменения', 2000);
          return;
        }

        Notifications.success('Ваши изменения сохранены', '', 2000);

        selectedUsersForRole = roleToUpdate.users;
        selectedGroupsForRole = roleToUpdate.groups;

        var role = null;

        for (var i = 0; i < $scope.data.length; i++) {
          role = $scope.data[i];

          if (roleToUpdate.name === role.name) {
            $scope.data[i] = JSON.parse(JSON.stringify(roleToUpdate));
            break;
          }
        }
      });
    };

    var findRoleByName = function(name) {
      var role = null;

      for (var i = 0; i < $scope.data.length; i++) {
        if ($scope.data[i].name === name) {
          role = $scope.data[i];
          break;
        }
      }

      return JSON.parse(JSON.stringify(role));
    };

    $scope.saveRoleInfo = function() {
      var name = $scope.role.name;
      var description = $scope.role.description;
      var role = findRoleByName(name);

      role.name = name;
      role.description = description;

      updateRole(role);
    };

    $scope.saveRoleUsers = function() {
      // var users = $scope.role.users
      // var role = findRoleByName($scope.role.name);

      // role.groups = $scope.role.users
      // role.users = $scope.role.users
       $scope.changes = true

      updateRole(angular.copy($scope.role));
    };

    $scope.saveRoleGroups = function() {
      // var groups = $scope.role.groups.slice();
      // var role = findRoleByName($scope.role.name);

      // role.users = selectedUsersForRole.slice();
      // role.groups = groups;

      updateRole(angular.copy($scope.role));
    };

    function updatePagination(pagination, arr) {
        var c = pagination;
        c.totalCount = arr.length;
        if (c.totalCount < c.offset) {
            c.lastPage = 1;
        } else if (c.totalCount % !c.offset) {
            c.lastPage = c.totalCount / c.offset;
        } else {
            c.lastPage = c.totalCount / c.offset + 1;
        }
    }

    function init() {
        getData(true);
    }

    function getData(isInit) {

        $scope.usersDistribution.getUsers()

        PanelRolesService.getReportRoles().then(
            function(result) {
                if (result.status === 200) {
                    $scope.data = result.data;
                    $scope.isLoading = false;
                    if (isInit && $scope.data && $scope.data.length) {
                        $scope.edit_role($scope.data[0], true);
                    }
                } else {
                    Notifications.danger(
                        'Ошибка! Не удается загрузить роли',
                        '',
                        2000
                    );
                }
            },
            function() {
                Notifications.danger(
                    'Ошибка! Не удается загрузить роли',
                    '',
                    2000
                );
            }
        );
    }
};

angular
    .module('smartApp')
    .controller('PanelRolesController', PanelRolesController);

PanelRolesController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    'PanelRolesService',
    'PanelUsersService',
    'Notifications',
    '$filter',
    'OrgstructService',
    '$timeout'
];


'use strict';

angular.module('smartApp').factory('NotificationsSettingsService', function NotificationsSettingsService($http, config) {
	var notifList = {
        testSend: "TEST_SEND",
        taskCreate: "TASK_CREATE",
        taskClose: "TASK_CLOSE",
        taskReturn: "TASK_RETURN",
		lostTasks: "MISSED_TASK_DEADLINE",
        performedAudit: "PERFORMED_AUDIT",
        NDaysWOCheck:"NOT_CHECKED_SM",
        lowGrades: "GRADE_CRITICAL_FALL",
        missplanned: "MISSED_AUDIT_PLAN",
        DDChecks:"NOT_CHECKED_SM_DD",
        formTaskSupervisor:"FORM_TASK_SUPERVISOR",
        formTaskDir: "FORM_TASK_DIR"
	}
    return {
        getOne: function(type, cb){
            if(!notifList[type]){
                cb(404, "Wrong type")
                return
            }
            $http.get(config.backend + '/memento/notifications/'+notifList[type], {noThrobber: true})
                .success(function (data, status) {  
                    if(status != 200 && status != 204){
                        cb((status == 200 || status == 204)?null:status, data);
                        console.log(data, status)
                        return;
                    }
                    cb(null, data)                                 
                    
                }
            );
        },
        getList: function (list, cb) {
        	// for(var cur in list){

        	// }
        	async.map(list, function(item, callback){
                if(!notifList[item.type]){
                    callback(null, undefined)
                    return
                }
        		$http.get(config.backend + '/memento/notifications/'+notifList[item.type], {noThrobber: true})
	                .success(function (data) {	                    
	                    callback(null, data)
	                }
	            );
        	}, function(err, result){

        		for(var i in list){
                    list[i] = Object.assign(list[i], result[i])
                }       		
        		cb(err, list)

        	})
            // return $http.get(config.backend + '/notifications/getDirectorNotification?shopSap=' + shopSap)
            //     .success(function (data) {
            //         return data;
            //     }
            // );
        },
        switchStatus: function(type, newVal, cb){
            var translate = {true:'enabled', false:'disabled'};

            $http.post(config.backend + '/memento/notifications/'+ notifList[type] +'/'+translate[newVal], {}, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });
        },
        
        sendTestMail: function(text, email, cb){
            $http.post(config.backend + '/outbound/testMail', {text:text, email:email,noThrobber: true}).success(function (data, status) {                   
                 cb((status != 200)?status:null, data)
            });
        },

        sendMail: function(type, login, mail,  cb){
            login = (login)?'?login='+login:"/all";
            mail = (mail)?'&email='+mail:"";
            
            $http.post(config.backend + '/memento/notifications/'+ notifList[type] +'/ALL/notify'+login+mail, {noThrobber: true})
                .success(function (data, status) {                   
                 cb((status != 200)?status:null, data)
            });
        }, 


        getRecipients: function(type, cb){
            $http.get(config.backend + '/memento/notifications/'+ notifList[type] +'/ALL/receivers/', {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });    
        },

        addRecipients: function(type, userdIds, cb){
            $http.post(config.backend + '/memento/notifications/'+ notifList[type] +'/ALL/receivers/'+ userdIds.join(","), {}, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });    
        },

        removeRecipients: function(type, recipientIds, cb){
            $http.delete(config.backend + '/memento/notifications/'+ notifList[type] +'/ALL/receivers/'+ recipientIds.join(","), {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });    
        },

        getNotCheckedSettings: function(templateId, cb){            
            $http.get(config.backend + '/memento/notifications/NOT_CHECKED_SM/ALL/settings/'+templateId, {noThrobber: true})
                .success(function (data, status) {
                    cb((status != 200)?status:null, data)
            });            
        },
        saveNotCheckedSettings: function(data, cb){            
            $http.post(config.backend + '/memento/notifications/NOT_CHECKED_SM/ALL/settings/', data, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });            
        },
        saveNotChecked: function(data, cb){     
            $http.post(config.backend + '/memento/notifications/NOT_CHECKED_SM/', data, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });            
        },

        getLowGradesSettings: function(data, cb){
            $http.get(config.backend + '/memento/notifications/GRADE_CRITICAL_FALL/ALL/settings/',{
                params: data,
                noThrobber: true
            }).success(function (data) {
                    cb(null, data)
            });
        },
        saveLowGradesSettings: function(data, cb){     
            $http.post(config.backend + '/memento/notifications/GRADE_CRITICAL_FALL/ALL/settings/', data, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });            
        },
        saveMissplannedSettings: function(data, cb){     
            $http.post(config.backend + '/memento/notifications/MISSED_AUDIT_PLAN/', data, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
            });            
        },
        savePerformedAuditSettings: function(data, cb){
            $http.post(config.backend + '/memento/notifications/PERFORMED_AUDIT/', data, {noThrobber: true})
                .success(function (data) {
                    cb(null, data)
                });
        },
        getAvailableLowGradesBlocks: function(data, cb){
            $http.get(config.backend + '/memento/notifications/GRADE_CRITICAL_FALL/ALL/settings/blocks/available', {
                params: data,
                noThrobber: true
            }).success(function (data, status) {
                cb((status != 200)?status:null, data)
            });            
        },
        addLowGradesBlocks: function(data, blocksIds, cb){
            $http.post(config.backend + '/memento/notifications/GRADE_CRITICAL_FALL/ALL/settings/blocks/'+blocksIds, {}, {
                params: data,
                noThrobber: true
            }).success(function (data, status) {
                cb((status != 200)?status:null, data)
            });            
        },
        removeLowGradesBlocks: function(data, blocksIds, cb){
            $http.delete(config.backend + '/memento/notifications/GRADE_CRITICAL_FALL/ALL/settings/blocks/'+blocksIds, {
                params: data,
                noThrobber: true
            }).success(function (data, status) {
                    cb((status != 200)?status:null, data)
            });            
        },
    }

});
var PanelGroupsController;

PanelGroupsController = function (
    $rootScope,
    $scope,
    $location,
    $anchorScroll,
    PanelGroupsService,
    PanelUsersService,
    FormFactory,
    Notifications,
    ngDialog,
    Pagination,
    OrgstructService,
    $timeout
) {
    $scope.userService = PanelUsersService;
    $scope.isSelected = false;
    $scope.changes = false;
    $scope.isLoading = true;
    $scope.selected_users = [];
    $scope.group_name_to_search = null;

    $scope.tableSettings = {
        page: 1,
        limit: 10
    };

    $scope.pagination = Pagination.get(function(data) {
      this.viewPort = data;
    });

    $scope.data = [];
    $scope.allRoles = [];

    $scope.setChanged = function () {
        $scope.changes = true;
    };

    $scope.usersDistribution = {
      pagination: {
        page: 1,
        perPage: 20,
        totalItems: null
      },
      resetControl: false,
      searhTimeout: null,
      searchValue: '',
      loading: false,
      data: [],
      onBoxOnePageChange: function(page, user) {
        this.pagination.page = page;
        this.getUsers();
      },
      onBoxOneSearch: function(searchValue, user) {
        $timeout.cancel(this.searhTimeout);

        this.searhTimeout = $timeout(() => {
          this.pagination.page = 1;
          this.searchValue = searchValue;
          this.getUsers();
        }, 1500);
      },
      reset: function() {
        this.pagination.page = 1;
        this.resetControl = true;

        $timeout(() => {
          this.resetControl = false;
        });
      },
      getUsers: function(){
        var _this = this;
        var requestParamsUsers = {};
        // пагинация начинается с нуля
        requestParamsUsers.page = this.pagination.page - 1;
        requestParamsUsers.size = this.pagination.perPage;
        requestParamsUsers.searchValue = (this.searchValue.length)?this.searchValue:undefined;
        // requestParamsUsers.fields="businessDir,roles,authType"
        // requestParamsUsers.sort="login,desc"

        // поиск по логину, имени, фамилии и емейлу
        OrgstructService.getSearchedUsers(requestParamsUsers).then(function(result) {
          if (result.status === 200) {
            _this.data = result.data.filter(user => user.active);
            _this.pagination.totalItems = result.headers('x-total-count');
          }
        })
      }

    };

    $scope.getData = getData;

    init();

    $scope.getChecked = function () {
        return $scope.data ? $scope.data.filter(function (e) {
            return e.checked;
        }) : [];
    };

    $scope.clear_input = function() {
        $scope.group_name_to_search = '';
        $scope.getPage(1);
    };

    $scope.getPage = function(page) {
        // $scope.tableSettings.page = page;
      $scope.pagination.page = page;
      $scope.getData();
    };

    $scope.go_to_group_creation = function () {
        $scope.group = null;
        $scope.new_group = {
            name: null,
            description: null,
            users: [],
            authorities: []
        };

        $location.hash('createGroupForm');
        $anchorScroll();
    };

    $scope.createForm = function(form){
        return new FormFactory(form);
    };

    $scope.errorValidation = function(){
        if($scope.changes) {
            Notifications.danger('Не все поля заполнены верно');
        }
    };

    $scope.select_or_diselect = function (selected) {
        var isSelected, j, k, len, len1, ref, ref1, results, results1, row;
        if (selected) {
            ref = $scope.data;
            results = [];
            for (j = 0, len = ref.length; j < len; j++) {
                row = ref[j];
                row.checked = false;
                results.push(isSelected = false);
            }
            return results;
        } else {
            ref1 = $scope.data;
            results1 = [];
            for (k = 0, len1 = ref1.length; k < len1; k++) {
                row = ref1[k];
                isSelected = true;
                results1.push(row.checked = true);
            }
            return results1;
        }
    };

    $scope.create_group = function () {
        if (group_is_valid($scope.new_group)) {
            PanelGroupsService.createGroup($scope.new_group).success(function (data, status, headers) {
                if (status === 201) {
                    Notifications.success('Группа успешно добавлена', '', 2000);
                    var splits = headers('Location').split('/');

                    $scope.getPage(1);
                    $scope.edit_group(splits[splits.length - 1]);
                }
            });
            return true;
        } else {
            $rootScope.$broadcast('formNotFilled');
            return false;
        }
    };

    $scope.edit_group = function (id, hasNoThrobber) {

        $scope.selectedRowId = id;
        $scope.new_group = undefined;

        async.parallel([
            function(callback){
                if(!$scope.allRoles || !$scope.allRoles.length){
                    PanelGroupsService.getReportRoles(hasNoThrobber).success(function (result) {
                        callback(null, result);
                    });
                } else {
                    callback(null, $scope.allRoles);
                }
            },
            
            function(callback){
                PanelGroupsService.getGroupInfo(id, hasNoThrobber).success(function (data) {
                    callback(null, data);
                });
            }
        ],
        // optional callback
        function(err, results){
            var authorities = [];
            var users = [];
            $scope.allRoles = results[0].map(el => {el.id = el.name; return el});
            $scope.group = results[1];

            angular.forEach($scope.group.authorities, function (role) {
                return authorities.push(_.find($scope.allRoles, {
                    name: role.name
                }));
            });

            // angular.forEach($scope.group.users, function (user) {
            //     return users.push(_.find($scope.allUsers, {
            //         login: user.login
            //     }));
            // });

            // $scope.group.users = users;
            $scope.group.authorities = authorities;
        });
    };

    var updateGroup = function(groupToUpdate) {
      if (!$scope.changes) {
        Notifications.danger('Ошибка', 'Нечего сохранять', 2000);
        return;
      }

      if (!group_is_valid(groupToUpdate)) {
        $rootScope.$broadcast('formNotFilled');
        return;
      }

      PanelGroupsService.updateGroup(groupToUpdate).success(function (result, status) {
          if (status === 200) {
              Notifications.success('Ваши изменения сохранены', '', 2000);
          }

          $scope.changes = false;

          var group = null;

          for (var i = 0; i < $scope.data.length; i++) {
            group = $scope.data[i];

            if (group.id === groupToUpdate.id) {
              $scope.data[i] = JSON.parse(JSON.stringify(groupToUpdate));
              break;
            }
          }
      });
    };

    var findGroupById = function(id) {
      var group = null;

      for (var i = 0; i < $scope.data.length; i++) {
        if ($scope.data[i].id === id) {
          group = $scope.data[i];
          break;
        }
      }

      return group;
    };

    $scope.saveGroupInfo = function() {
      // var name = $scope.group.name;
      // var description = $scope.group.description;
      // var group = findGroupById($scope.group.id);

      // group.name = name;
      // group.description = description;

      updateGroup($scope.group);
    };

    $scope.saveGroupUsers = function() {
      // var users = $scope.group.users.slice();
      // var group = findGroupById($scope.group.id);

      // group.users = users;

      updateGroup($scope.group);
    };

    $scope.saveGroupRoles = function() {
      // var authorities = $scope.group.authorities.slice();
      // var group = findGroupById($scope.group.id);

      // group.authorities = authorities;

      updateGroup($scope.group);
    };

    $scope.onUsersTabClick = function(){
        if(!$scope.usersDistribution.data.length){
            $scope.usersDistribution.getUsers()
        }
    }

    $scope.confirm_delete = function () {
	    ngDialog.openConfirm({
		    template: 'scripts/app/common/confirm-dialog.template.html',
		    scope: $scope,
		    data: {
			    title: 'Вы действительно хотите удалить выбранные группы?'
		    }
	    }).then(function(e){
		    $scope.delete_group();
	    }, function () {});
    };
    return $scope.delete_group = function () {
        var ids, j, len, ref, row;
        ids = [];
        ref = $scope.data;
        for (j = 0, len = ref.length; j < len; j++) {
            row = ref[j];
            if (row.checked) {
                ids.push(row.id);
            }
        }
        return PanelGroupsService.deleteGroup(ids).success(function (r, status, headers) {
            if (headers('x-error-delete')) {
                var errorIds = headers('x-error-delete').split(',').map(function(e){return Number(e)});
                ids = _.difference(ids, errorIds);
                var names = errorIds.map(function(e){
                    var gr = _.find($scope.data, function(g){return g.id === e});
                    return gr && gr.name || '';
                }).join(', ');
                Notifications.danger('Невозможно удалить группы', names);
            } else {
                Notifications.success('Группы удалены успешно');
            }
            if (status === 200) {
                angular.forEach(ids, function(e){
                    removeFromData(e);
                });
            }
        });
    };

    function init(){
        getData(true);
    }

    function getData(isInit) {
      PanelGroupsService.getGroups($scope.pagination.page, $scope.pagination.perPage, $scope.group_name_to_search).success(function (data, status, headers) {
          var i, j, len;
          if (data) {
              $scope.links = PanelGroupsService.parse(headers('link'));

              for (j = 0, len = data.length; j < len; j++) {
                i = data[j];
                i.checked = false;
              }

              $scope.data = data;
              $scope.isLoading = false;

              if (isInit && $scope.data && $scope.data.length) {
                  $scope.edit_group(data[0].id, true);
              }

              $scope.pagination.total = headers('x-total-count');
              $scope.pagination.pageChange($scope.data);
          } else {
              $rootScope.$broadcast('noResultsFound');
          }
      });
    }

    function group_is_valid(group) {
        return group && group.name;
    }

    function removeFromData(user){
        var index = getGroupIndex(user);
        if(index >= 0)
            $scope.data.splice(index, 1);
    }

    function getGroupIndex(group){
        var id = angular.isObject(group) ? group.id : group;
        var index = -1;
        _.forEach($scope.data, function(e, i){
            if(e.id === id)
                index = i;
        });
        return index;
    }

};

angular.module('smartApp').controller('PanelGroupsController', PanelGroupsController);

PanelGroupsController.$inject = [
    '$rootScope',
    '$scope',
    '$location',
    '$anchorScroll',
    'PanelGroupsService',
    'PanelUsersService',
    'FormFactory',
    'Notifications',
    'ngDialog',
    'Pagination',
    'OrgstructService',
    '$timeout'
];

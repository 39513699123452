//Этот файл подгружается только для облачников

angular.module('smartApp').controller("AssignTemplateController", [
	'$scope', '$controller', 'ObjectServiceForDivisions', 'ObjectServiceForClusters', 'ObjectServiceForCities', 'AdditionToAssignService',
	function($scope, $controller, ObjectServiceForDivisions, ObjectServiceForClusters, ObjectServiceForCities, AdditionToAssignService){
		$controller("CommonAssignTemplateController", {
			$scope: $scope
		});



}]);

angular.module('smartApp').constant('projectConfig', {
    'nameProject': "md"
});

angular.module('smartApp').factory('projectData', function() {
    return {
        photoReport: "Фотоотчет",
        periodicTask: "Периодическая"
    };
});

angular.module('smartApp').controller("InstructionController", [
	'$scope', 'Principal', '$controller',
	function($scope, Principal, $controller){
		$controller("CommonInstructionController", {
			$scope: $scope
		});

		$scope.adminDocName = './doc/Admin.pdf';
		$scope.directorDocName = './doc/Director.pdf';
		$scope.supervisorDocName = './doc/Supervisor.pdf';
}]);


angular.module('smartApp').controller('PanelShopsController', [
    '$filter',
    '$scope',
    '$q',
    '$timeout',
    '$location',
    '$anchorScroll',
    '$controller',
    'ParseLink',
    'Notifications',
    'UserService',
    'PanelDivisionsService',
    'PanelClustersService',
    'PanelShopsResource',
    'ObjectServiceForClusters',
    'DownloadService',
    'OrgstructService',
    function(
        $filter,
        $scope,
        $q,
        $timeout,
        $location,
        $anchorScroll,
        $controller,
        ParseLink,
        Notifications,
        UserService,
        PanelDivisionsService,
        PanelClustersService,
        PanelShopsResource,
        ObjectServiceForClusters,
        DownloadService,
        OrgstructService
    ) {
        $controller('CommonPanelShopsController', {
    	    $scope: $scope
        });

        $scope.editable = true;

	    var shopIsValid = function(shop) {
            var requiredFields = [
                'city',
                'address',
                'cluster',
                'sap',
                'locality',
                'latitude',
                'longitude',
                'status'
            ];
            return _.every(requiredFields, function(field) {
                return shop[field];
            });
        }

        $scope.download = function() {
            PanelShopsResource
                .getAllFiltered(
                    $scope.tableSettings.page,
                    $scope.tableSettings.limit,
                    $scope.filters,
                    [{
                        field:$scope.sort,
                        orderBy: $scope.reverse ? 'asc' : 'desc'
                    }],
                    true).then(function (resp) {
                    if (resp.status === 200){
                        if (resp.data && angular.isArray(resp.data)) {
                            DownloadService.downloadShopsExport(resp.data);
                        } else {
                            Notifications.warning('Не удается выгрузить файл');
                        }
                    } else if (resp.status === 204) {
                        Notifications.warning('Ничего не найдено для выгрузки в файл');
                    } else {
                        Notifications.danger('Ошибка');
                    }
                });
        };

        $scope.getData = function(){
                    console.log('Get data');
            $q.all({
                shops: getShops(),
                statuses: getShopStatuses(),
                clusters: getClusters(),
                divisions: getDivisions()
            }).then(function(resp) {
                $scope.data = resp.shops;
                $scope.allShopStatuses = resp.statuses;

                $scope.allClusters = resp.clusters;
                $scope.possibleClusters = resp.clusters;
                $scope.allDivisions = resp.divisions;

                if ($scope.data && $scope.data.length) {
                   var editShop = $scope.data[0];

                   if ($scope.selected_row) {
                      for (var i = 0; i < $scope.data.length; i++) {
                         if ($scope.data[i].id === $scope.selected_row) {
                            editShop = $scope.data[i];
                            break;
                        } else {
                            editShop = $scope.data[0];
                        }
                    }
                }

              $scope.edit(editShop, true);
          }
            }, function() {
                            console.log(arguments, 'Error');
                Notifications.warning('Произошла ошибка');
            });
        };

     $scope.checkSapExist = function(value) {
        $scope.sapExist = '';

        PanelShopsResource.getAllFiltered($scope.tableSettings.page, $scope.tableSettings.limit, {'sap': value})
            .then(result => {
                if (!result.data.length) return;

                result.data.filter(shop => {
                    if (shop.sap !== value) return;
                    
                    $scope.sapExist = 'Магазин с таким номером уже существует';
                    return;
                })
            })
    };
            
    // Фильтрует массив директоров по введенным символам в поиске
    $scope.changeDirector = function(search) {
        if(search.length < 2) {
            return $scope.filteredDirs = [];
        }

        $scope.filteredDirs = $scope.unsignedDirList.filter(dir => {
            for (var key in dir) {
                if (key === 'login' || key === 'firstName' || key === 'lastName'){
                    if (dir[key].toLowerCase().includes(search.toLowerCase())) {
                        return dir;
                    }
                } 
            }
        });
    };

        $scope.edit = function(shop, hasNoThrobber) {
            $scope.newShop = null;
			$scope.selected_row = shop.id;
			$scope.shop = null;

            getShop(shop.id, hasNoThrobber).then(shop => {
                $scope.shop = shop;
                $scope.$broadcast('EDITING_SHOP', shop);
            }, () => Notifications.warning('Произошла ошибка загрузки данных магазина!'));
        };


        function getShops() {
            var deferred = $q.defer();
            //$scope.filters.active = $scope.showActive || undefined;

            PanelShopsResource.getAllFiltered(
                $scope.tableSettings.page,
                $scope.tableSettings.limit,
                $scope.filters,
                [{
                    field:$scope.sort,
                    orderBy: $scope.reverse ? 'asc': 'desc'
                }]
            ).then(function (resp) {
                 $scope.isLoading = false;
                if(resp.status === 200){
                    $scope.tableSettings.totalCount = resp.headers('x-total-count');
                    $scope.links = ParseLink.parse(resp.headers('link'));
                    deferred.resolve(resp.data);

                } else if (resp.status === 204) {
                    deferred.resolve([]);
                    Notifications.warning('Ничего не найдено!');
                } else {
                    deferred.reject(resp.data);
                    Notifications.danger('Ошибка');
                }
            });

            return deferred.promise;
        }

        function getShopStatuses() {
            var deferred = $q.defer();

            if (!$scope.allShopStatuses || !$scope.allShopStatuses.length) {
                PanelShopsResource
                    .getShopStatuses()
                    .then(function(resp) {
                        deferred.resolve(resp.data);
                    });
            } else {
                deferred.resolve($scope.allShopStatuses);
            }

            return deferred.promise;
        }

        function getClusters() {
            var deferred = $q.defer();

            if (!$scope.allClusters || !$scope.allClusters.length){
                PanelShopsResource
                    .getAllClusters()
                    .then(function(resp){
                        deferred.resolve(resp.data);
                    });
            } else {
                deferred.resolve($scope.allClusters);
            }

            return deferred.promise;
        }

        function getDivisions() {
            var deferred = $q.defer();

            if (!$scope.allDivisions || !$scope.allDivisions.length){
                PanelDivisionsService
                    .getAllDivisions()
                    .then(function(resp) {
                        if (resp.status === 200){
                            deferred.resolve(resp.data);
                        } else {
                            deferred.reject(resp.status);
                        }
                    });
            } else {
                deferred.resolve($scope.allDivisions);
            }

            return deferred.promise;
        }

        function getShop(shopId, hasNoThrobber) {
            var deferred = $q.defer();

            PanelShopsResource
                .getById(shopId, hasNoThrobber)
                .then(function(resp){
                    deferred.resolve(resp.data);
                });

            return deferred.promise;
        }

        // Не используется
        // function fillDirectors(unsignedDirList, selectedDir) {
        //     if(!$scope.dirList)$scope.dirList = []
        //     if ($scope.dirList.length) {
        //         $scope.dirList.splice(0);
        //     }

        //     if (unsignedDirList.length) {
        //         _.forEach(unsignedDirList, function(shop) {
        //             $scope.dirList.push(shop);
        //         });
        //     }

        //     if (selectedDir && !_.find($scope.dirList, { id: selectedDir.id })) {
        //         $scope.dirList.push(selectedDir);
        //     }
        // }

    $scope.download = function() {
        PanelShopsResource.getAllFiltered($scope.tableSettings.page, $scope.tableSettings.limit, $scope.filters, [{field:$scope.sort, orderBy:(($scope.reverse) ? 'asc' : 'desc')}], true).success(function (result, status) {
            console.log(result)
            var blob = new Blob([result], {
                type: 'application/vnd.ms-excel'
            });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            link.download = 'users_' + moment().format('YYMMDD') + '.xls';
            link.click();

            Notifications.success('Файл успешно загружен');
         })
	}

	$scope.createMapUrl = function(lat, lon){
		if(lat && lon){
			return "<a target=\"_blank\" href='https://maps.yandex.ru/?ll="+lon+","+lat+"&pt="+lon+","+lat+"&spn=0.03,0.03&l=sat,skl'>карта</a>";
		}else{
			var t = [];
			if(lat)t.push(lat+"N")
			if(lon)t.push(lon+"E")
			return t.join("<br>");
		}
	}

	var findShopById = function(id) {
		var shop = null;

		for (var i = 0; i < $scope.data.length; i++) {
			if ($scope.data[i].id === id) {
				shop = $scope.data[i];
				break;
			}
		}

		return JSON.parse(JSON.stringify(shop));
	};

	$scope.saveShopInfo = function() {
		if(!shopIsValid($scope.shop)){
			Notifications.warning('Не все поля заполнены');
			return;
		}

		var sendObj = {};

		sendObj.active = $scope.shop.active;
		sendObj.allDay = $scope.shop.allDay;
		sendObj.mvz = $scope.shop.mvz;
		sendObj.id = $scope.shop.id;
		sendObj.sap = $scope.shop.sap;
		sendObj.address = $scope.shop.address;
		sendObj.code = $scope.shop.code;
		sendObj.locality = $scope.shop.locality;
		sendObj.city = $scope.shop.city;
		sendObj.longitude = $scope.shop.longitude;
		sendObj.latitude = $scope.shop.latitude;
		sendObj.addrRegOffice = $scope.shop.addrRegOffice;
		sendObj.format = $scope.shop.format;
		sendObj.subFormat = $scope.shop.subFormat;
		sendObj.subFilial = $scope.shop.subFilial;
		sendObj.equipmentShop = $scope.shop.equipmentShop;
		sendObj.productionShop = $scope.shop.productionShop;

		sendObj.directorId = _.get($scope.shop, ['director',' id']);
		sendObj.clusterId = ($scope.shop.cluster)?$scope.shop.cluster.id:null;
		sendObj.categoryShopId = $scope.shop.categoryShop ? $scope.shop.categoryShop.id : null;
		sendObj.statusId =  $scope.shop.status ? $scope.shop.status.id : null;

		// sendObj.supervisors = $scope.selectedSupervisors.filter(el => !!el && el.id).map(el => el.id);

		PanelShopsResource.update(sendObj).success(function (result, status, headers) {
			var error = headers('x-error');

			if (status === 200 && !error) {
				Notifications.success('Ваши изменения сохранены');
				$scope.getData();
			} else if (error === 'DirectorNotFoundException') {
				Notifications.warning('Выберите директора магазина');
			} else {
				Notifications.warning('Изменения утеряны');
			}
		});

	};

	$scope.saveShopSupervisors = function() {
		var shop = findShopById($scope.shop.id);

		shop.supervisors = $scope.shop.supervisors.filter(el => !!el && el.id).map(el => el.id)

		PanelShopsResource.update(shop).success(function (result, status, headers) {
			if(status === 200 && !headers('x-error-text')) {
				Notifications.success('Ваши изменения сохранены');
				$scope.getData();
			}
			else Notifications.warning('Изменения утеряны');
		});
	}

	// $scope.setSupervisors = function(sups) {
	// 	$scope.selectedSupervisors = sups;
	// }

	$scope.createShop = function(){
		//$scope.shop = null;
        console.log($scope.newShop)



		if(!shopIsValid($scope.newShop)){
			Notifications.danger('Не все поля заполнены');
			return;
		}

		if(!!~$scope.newShop.sap.indexOf(" ")){
      Notifications.danger('В поле «'+ $filter('wordReplace')('SAPcode') +"» не допускаются пробелы");
      return;
    }

		if ($scope.newShop.sap.length < 2) {
			Notifications.danger($filter('wordReplace')('SAPcode') +" должен содержать не менее 2 символов");
      return;
		}

		$scope.newShop.clusterId = $scope.newShop.cluster.id;
		$scope.newShop.statusId = $scope.newShop.status.id;
		$scope.newShop.active = true;

        // копируем объект что бы не очищались поля, которые мы удаляем ниже
        var copyNewShop = Object.assign({}, $scope.newShop);
        delete copyNewShop.cluster;
        delete copyNewShop.status;

		PanelShopsResource.createShop(copyNewShop)
			.then(function (resp) {
				if (resp.status === 200 || resp.status === 201) {
					var newShopName = $scope.newShop.locality
                    $scope.newShop = null;
                    $scope.filters.locality = newShopName;
                    console.log($scope.filters)
					getShops().then(function(resp) {
                        $scope.data = resp;
                         if ($scope.data && $scope.data.length) {
                            $scope.edit($scope.data[0], true);
                        }
                    });

				} else if (resp.status === 400 && resp.headers('x-error') === 'EASC2') {
					Notifications.danger('Магазин с таким номером уже существует');
				} else {
					Notifications.danger('Ошибка создания магазина');
				}
				// return resp.data;
			});
			// need to change api or come up with something another
			// now createShop doesn't return anything
			// .then(function(shop) {
			// 	if (shop) {
			// 		$scope.newShopDirector.shopDirector = true;
        	// 		$scope.newShopDirector.shopIds = [shop.id];
			// 		$scope.userService.updateUser($scope.newShopDirector)
			// 			.then(function(resp) {
			// 				if (resp.status === 200 || resp.status === 201) {
			// 					Notifications.success('Магазин успешно создан');
			// 				} else {
			// 					Notifications.danger('Ошибка создания магазина');
			// 				}
			// 			});
			// 	}
			// });
	};
}]);



angular.module('smartApp').controller("PanelDivisionsController", ['Notifications', 'PanelDivisionsService', '$controller', 'ngDialog', '$scope', function(Notifications, PanelDivisionsService, $controller, ngDialog, $scope){
	$controller("CommonPanelDivisionsController", {
		$scope: $scope
	});
	$scope.editable = true;

	$scope.addDivision = function(newDivision){
        newDivision.active = true;
		PanelDivisionsService.createDivision(newDivision).then(function (result) {
			if (result.status === 200 || result.status === 201) {
				Notifications.success('Успешно создан');
				ngDialog.closeAll();
				$scope.getData()
			} else if (result.headers('x-error') === 'DuplicateSapLoginDivision') {
				Notifications.danger('Ошибка! Такой SAP логин ответственного уже существует.');
			} else {
				Notifications.danger("Ошибка создания");
			}
		});
	}

	$scope.showCreateDivision = function(){
		$scope.newDivision = {};
		 ngDialog.open({
		 	template: 'new',
            scope: $scope,
            className: 'ngdialog-theme-plain dialog',
            showClose: false
		 })
	}


}])

angular.module('smartApp').controller("PanelClustersController", ['Notifications', 'PanelDivisionsService', 'PanelClustersService', '$controller', 'ngDialog', '$scope', function(Notifications, PanelDivisionsService, PanelClustersService, $controller, ngDialog, $scope){
	$controller("CommonPanelClustersController", {
		$scope: $scope
	});

	$scope.editable = true;

	$scope.addCluster = function(newCluster){
		newCluster.divisionId = newCluster.division.id;
        newCluster.active = true;

		PanelClustersService.createCluster(newCluster).then(function (resp) {
			if (resp.status === 200 || resp.status === 201) {
				Notifications.success('Успешно создан');
				$scope.getData();
			} else if (resp.headers('x-error') === 'DuplicateSapLoginCluster') {
				Notifications.danger('Ошибка создания! Такой SAP логин ответственного уже существует.');
			} else {
				Notifications.danger('Ошибка создания');
			}

			ngDialog.closeAll();
            delete newCluster.division;
		});
	}

	$scope.showCreateCluster = function(){
		PanelDivisionsService.getAllDivisions().success(function (data, status, headers) {
			if (status === 200 || status === 201) {
				$scope.divisions = data;
				ngDialog.open({
					template: 'new',
					scope: $scope,
					className: 'ngdialog-theme-plain dialog',
					showClose: false
				})

			}else Notifications.danger('Ошибка подгрузки');
		});


	}
}])

angular.module('smartApp').controller("EditChecklistController", ['$controller', '$scope', function($controller, $scope){
	$controller("CommonEditChecklistController", {
		$scope: $scope
	});
	var refreshFilledCounter = function(){
		//alert('perek')
	}
}])

'use strict';

angular.module('smartApp')
    .directive('localPagination', function() {
        return {
            templateUrl: 'scripts/components/directives/localPagination.html',
            scope: {
                options: '=localPagination'
            }
        };
    });

var PanelDivisionsService;

PanelDivisionsService = function($http, config, utils) {
    return {
        getDivisions: function(page, limit, division_name, showAll) {
            var params = {
              page: page,
              'per_page': limit,
              name: division_name,
              showAll: showAll
            };

            var query = utils.getQuery('/admin/divisions', params);

            return $http.get(query);
        },
        getDivisionShops: function(id, page, limit, hasNoThrobber) {

            var query =
                config.backend +
                '/admin/divisions/' +
                id +
                '/shops?' +
                utils.getParamString({ page: page, per_page: limit });
            return $http.get(query, {
                noThrobber: hasNoThrobber
            });
        },

        createDivision: function(division_info) {
            return $http.post(
                config.backend + '/admin/divisions',
                division_info
            );
        },
        updateDivision: function(division_to_update) {
            return $http.put(
                config.backend + '/admin/divisions',
                division_to_update
            );
        },
        deleteDivisions: function(ids) {
            return $http['delete'](config.backend + '/admin/divisions/' + ids);
        },
        getAllClusters: function() {
            return $http.get(config.backend + '/objects/clusters');
        },
        getAllDivisions: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/divisions', {
                noThrobber: hasNoThrobber
            });
        },
        getAllUsers: function() {
            return $http.get(config.backend + '/objects/users');
        },
        getDivisionInfo: function(id, hasNoThrobber) {

            return $http.get(config.backend + '/admin/divisions/' + id, {
                noThrobber: hasNoThrobber
            });
        },
        parse: function(headers) {
            var links, parts;
            parts = headers.split(',');
            links = {};
            angular.forEach(parts, function(p) {
                var name, page, queryString, section, url;
                section = p.split(';');
                url = section[0].replace(/<(.*)>/, '$1').trim();
                queryString = {};
                url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
                    $0,
                    $1,
                    $2,
                    $3
                ) {
                    return (queryString[$1] = $3);
                });
                page = queryString['page'];
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                name = section[1].replace(/rel="(.*)"/, '$1').trim();
                return (links[name] = page);
            });
            return links;
        },
        getDivisionsOrgstruct: function(idDivision, userIds) {
          return $http.get(config.backendOrgstruct + '/divisions');
        },
        updateDivisionDirectors: function(idDivision, userIds) {
            return $http.put(
                config.backendOrgstruct +
                    '/divisions/' +
                    idDivision +
                    '/dirs/' +
                    userIds
            );
        },
        deleteDivisionDirectors: function(idDivision, userIds) {
            return $http.delete(
                config.backendOrgstruct + '/divisions/' + idDivision + '/dirs/',
                userIds
            );
        }
    };
};

angular
    .module('smartApp')
    .factory('PanelDivisionsService', PanelDivisionsService);

PanelDivisionsService.$inject = ['$http', 'config', 'HttpUtils'];

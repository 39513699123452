(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('paramsInput', paramsInput);

    paramsInput.$inject = [];

    function paramsInput() {
        var directive = {
            templateUrl: 'scripts/app/settings/params/params-input/params-input.view.html',
            bindToController: true,
            controller: ParamsInputController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                property: '=',
                changeVal: '&'
            }
        };

        return directive;
    }

    function ParamsInputController() {
        var vm = this;

        vm.setClass = setClass;
        vm.getSelectedVaue = getSelectedVaue;

        function setClass(inputType) {
            var classes = {
                chips: 'params__input--chips',
                number: 'params__input--right params__input--number',
                text: 'params__input--right',
                select: 'params__input--right',
                checkbox: 'params__input--right',
                'multi-select': 'params__input--right'
            };
            return classes[inputType];
        }

        function getSelectedVaue(value, valuesList) {
            var output =  _.has(value, 'val') ? value.val : value;
            var foundVal;
            if (valuesList && valuesList.length && _.isObject(valuesList[0]) && !_.has(value, 'val')) {
                foundVal = _.find(valuesList, function(listItem) {
                    return listItem.id == value;
                });
                output = foundVal && foundVal.val;
            }
            return output;
        }
    }
})();
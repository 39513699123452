'use strict';

angular.module('smartApp')
    .factory('AuthServerProvider', function loginService($http, $q, localStorageService, config) {
        return {
            login: function(credentials) {
                var defer = $q.defer();
                var data = "username=" + credentials.username + "&password="
                    + encodeURIComponent(credentials.password);
                //var data = {username: credentials.username, password: credentials.password}
                $http.post(config.backend + '/xauth/authenticate', data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json"
                    }
                }).success(function (response, status, headers) {
                    var result = {
                        data: response,
                        status: status,
                        headers: headers()
                    };
                    if(status === 200){
                        localStorageService.set('token', response);
                        defer.resolve(result);
                    }else{
                        defer.reject(result);
                    }
                });
                return defer.promise;
            },
            logout: function() {
                //Stateless API : No server logout
                localStorageService.remove('token');
                localStorageService.remove('userPermissions');
            },
            getToken: function () {
                return localStorageService.get('token');
            },
            hasValidToken: function () {
                var token = this.getToken();
                return token && token.expires && token.expires > new Date().getTime();
            },
            passwordRestore: function(login){
                var defer = $q.defer();
                $http({
                    method: 'GET',
                    url: config.backend + '/xauth/restorePassword?login='+login,
                  
                   
                }).then(function(result) {
                    if(result.status == 200){
                       defer.resolve(result); 
                   }else{
                        if(!result.data || !result.data.message){
                           defer.reject(result); 
                           return;
                        }
                        defer.reject(result.data.message);
                   }
                    
               }, function(error) {
                   console.log(error);
                   defer.reject(error);
               });
                return defer.promise;
            },
            makeNewPassword: function (newPassword, repeatNewPassword, email) {               
                var data = {
                    newPassword: newPassword,
                    repeatNewPassword: repeatNewPassword,
                    email: email
                }
                var defer = $q.defer();
                $http({
                    method: 'PUT',
                    data:data,
                    url: config.backend + '/xauth/makeNewPassword',
                   
                }).then(function(result) {
                    if(result.status == 200){
                       defer.resolve(result); 
                   }else{
                        if(!result.data || !result.data.message){
                           defer.reject(result); 
                           return;
                        }
                        defer.reject(result.data.message);
                   }
                    
               }, function(error) {
                   console.log(error);
                   defer.reject(error);
               });
                // $http.post(config.backend + '/xauth/makeNewPassword?newPassword='+newPassword+'&repeatNewPassword='+repeatNewPassword+'&email='+email).success(function (response, status, headers) {                    
                //     if(status === 200){                        
                //         defer.resolve(response);
                //     }else{
                //         defer.reject(response);
                //     }
                // });
                return defer.promise;
            },
            getSSOConfiguration: function() {
                return $http.get('/api/xauth/sso/configuration');
            },
            loginOIDC: function(code) {
                const defer = $q.defer();
                $http.post('/api/xauth/sso/oidc/authenticate', { code }).then(result => {
                    if (result.status == 200) {
                        const data = result.data;
                        localStorageService.set('token', data.token);
                        localStorageService.set('oidc:user_info', data.userInfo);
                        defer.resolve(data);
                    } else {
                        defer.reject(result);
                    }
                });
                return defer.promise;
            },
        };
    });

(function() {
	'use strict';

	angular.module('smartApp').component('taskNewForm', {
		templateUrl: 'scripts/app/tasks/task-new/task-new-form/task-new-form.view.html',
		controller: TaskNewFormController,
		controllerAs: '$ctrl',
		bindings: {
		  isPeriodic: '<',
		  userPermissions: '<',
		  taskName: '<',
		  attachments: '<',
		  executors: '<',
		  onCreate: '&',
		  newItem: '=',
		  callback: '='
		}
	});



	TaskNewFormController.$inject = ['Principal', 'TasksTemplateService', 'PanelShopsResource', 'UserService','OrgstructService', 'ShopService', 'TasksViewService','config', '$q', 'Upload', '$state', '$scope', 'TaskNewService', 'ngDialog', 'TaskListServiceHttp', 'Notifications', 'TaskListService', 'RfcIntegrationApi'];

	function TaskNewFormController(Principal, TasksTemplateService, PanelShopsResource, UserService, OrgstructService, ShopService, TasksViewService, config, $q, Upload, $state, $scope, TaskNewService, ngDialog, TaskListServiceHttp, Notifications, TaskListService, RfcIntegrationApi) {
		var $ctrl = this;

		Array.prototype.groupBy = function(prop) {
            return this.reduce(function(groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || [];
                groups[val].push(item);
                return groups;
            }, {});
        };

		$ctrl.attachments = !!$ctrl.attachments ? $ctrl.attachments : [];
		$ctrl.caseNameFiles = {nom:'вложение',gen:'вложения',plu:'вложений'}; //TODO: убрать в сервис
		$ctrl.savedSaps = [];
		$ctrl.preFiles = []
		$ctrl.taskTypes = TaskNewService.getTaskTypes();

		$ctrl.periodicTypes = TaskNewService.getPeriodicTypes();

		$ctrl.weekDays = TaskNewService.getWeekDays();

		$ctrl.monthDays = TaskNewService.getMonthDays();

		$ctrl.selectedShops = []
		$ctrl.executorsForShops = []
		$ctrl.allShops = []
		$ctrl.selectedExecutors = []
		$ctrl.datepickers = {
			isCreateDateOpen: false,
			isDeadLineOpen: false,
			isPeriodicStartOpen: false,
			isPeriodicEndOpen: false
		};

		$ctrl.createDate = new Date();
		$ctrl.deadlineDateOptions = TaskNewService.getDatepickerSettings();
		$ctrl.deadlineTime = '';
		$ctrl.equipments = { hasError: false, items: [] };

		function convertTimeToUtc(time) {
			const splittedTime = time.split(':');
			const hour = splittedTime[0];
			const minute = splittedTime[1];
			const second = splittedTime[2];

			const dateWithTime = moment().set({ hour, minute, second });
			return dateWithTime.utc().format('HH:mm:ss');
		}

		$ctrl.createDateOptions = TaskNewService.getDatepickerSettings();

		$ctrl.notificationFrequencies = TaskNewService.getNotificationFrequency();

		$ctrl.save = save;
		$ctrl.openModalExecutors = openModalExecutors;
		$ctrl.modalExecutorsEnabled = true;
		$ctrl.canAddAttachments = true;
		$ctrl.selectedExecutorsRole = null;
		$ctrl.formsTemplates = null;
		$ctrl.principal = Principal.getIdentity()
		$ctrl.goods = [];

		$ctrl.handleTaskDateChange = handleTaskDateChange;

		var taskIsCreated = false;
        const defaultTexts = ['Ручной пересчёт товаров от ' + moment(new Date()).format('DD.MM.YYYY'), 'Сбор данных для производственной отчётности'];

        function handleTaskDateChange(type, date) {
			switch(type) {
				case 'create':
					$ctrl.deadlineDateOptions.minDate = date;
					break;
				case 'deadline':
					$ctrl.createDateOptions.maxDate = date;
					break;
			}
		}

		$ctrl.executorsForShopsFilter = function(level){
			return $ctrl.executorsForShops.filter(el => el.level == level)
		}

		$scope.$watch(function () {
			return $ctrl.weekDays;
		}, function() {
			if (_.get($ctrl.task, ['periodicTaskSchedule', 'period', 'type']) === 'WEEKLY') {
				$ctrl.task.periodicTaskSchedule.period.daysOfWeek = _($ctrl.weekDays)
					.filter({ state: true})
					.map('val')
					.value();
			}
		}, true);

		$ctrl.refreshShops = function (search) {
		  if(search.length >= 2){

			ShopService.search(search).success(function(result, status) {
			  var alreadyLoadedShops = angular.copy($ctrl.allshops );
			  if(status === 200){
				$ctrl.allShops = result;
			  }else if(status === 204){
				$ctrl.allShops = [];
			  }
			})
		  }
		};

		$ctrl.$onInit = function() {
			TaskListService.clearDataObject();
			$ctrl.responsible = null;

			if ($ctrl.attachments.length > 0) {
				$ctrl.preUploadFiles($ctrl.attachments);
				$ctrl.canAddAttachments = false;
			}

			if ($ctrl.executors && $ctrl.executors.length > 0) {
				$ctrl.modalExecutorsEnabled = false;
				$ctrl.responsible = {};
				$ctrl.responsible.executorsIds = $ctrl.executors.map(ex => ex.id);
				$ctrl.responsible.role = $ctrl.executors[0].role;
				$ctrl.loadShopsForExecutors($ctrl.responsible.executorsIds);
			} else if ($ctrl.principal.level === 'SHOP') {
			$ctrl.modalExecutorsEnabled = false;
			setExecutors({
				executorsInfo: [$ctrl.principal],
				role: 'SHOP',
			});
			}

		  	(function(){
		  		var cb = function(err, data){
		  			if(err){
	                    Notifications.danger("Ошибка получения доступных ролей: "+err)
	                    return;
	                }

	                $ctrl.possibleExecutorLevels =  Object.keys(data).map((el) => {
	                    return {
	                        "name": data[el],
	                        "role": el,
	                        "state": el.toLowerCase()
	                    }
	                });

	                if($ctrl.possibleExecutorLevels.length == 1)$ctrl.executorsLevel = $ctrl.possibleExecutorLevels[0]
		  		}
		  		TaskListServiceHttp.getExecutorRoles().then(function(response) {
	                cb( (response.status === 200 || response.status === 201)?null:response.status+response.headers('x-error'), response.data)
	            });
  		  	})()

			if ($ctrl.newItem) {
			  	if ($ctrl.newItem.type=="task") {

				  	if ($ctrl.newItem.date) {
				  		$ctrl.task.deadlineDate  = moment($ctrl.newItem.date).toDate();
				  		$ctrl.createDate = moment($ctrl.newItem.date).toDate();
				  	}

				  	if ($ctrl.newItem.name) $ctrl.task.name = $ctrl.newItem.name
				  	if ($ctrl.newItem.description) $ctrl.task.description = $ctrl.newItem.description

					if ($ctrl.newItem.taskType && $ctrl.newItem.taskType.val === 'MANUAL_RECOUNT') {
                        setTaskDefaultText(defaultTexts[0]);
					}

				  	if ($ctrl.newItem.ordType && $ctrl.newItem.ordItemId) {
				  		if ($ctrl.newItem.ordType == 'shops') {
				  			$ctrl.task.selectedShops = [$ctrl.newItem.ordItemId]
				  			$ctrl.loadExecutorsForSelectedShop($ctrl.task.selectedShops[0])
				  			PanelShopsResource.getById($ctrl.newItem.ordItemId).success(function (data, status) {

				  				if (status != "404" && status != "500") {
				  					$ctrl.newItem.selectedItemName = data.locality+" ("+data.sap+")"
				  				} else {
				  					$ctrl.newItem.selectedItemName = "Неизвестный магазин"
				  				}

						    });
				  		} else {
				  			$ctrl.task.selectedExecutors = [$ctrl.newItem.ordItemId]
				  			$ctrl.loadShopsForExecutors($ctrl.task.selectedExecutors)
				  			OrgstructService.getUser($ctrl.newItem.ordItemId).success(function (data, status) {
				  				if(status != "404" && status != "500"){
				  					$ctrl.newItem.selectedItemName = data.firstName+" "+data.lastName
				  				}else{
						        	$ctrl.newItem.selectedItemName = "Неизвестный исполнитель"
						        }
						        $ctrl.newItem.role = data.level
						    });
				  		}
				  	}
				  	if ($ctrl.newItem.template) {
				  		TasksTemplateService.getTemplate($ctrl.newItem.template.id, function(err, data){
				  			if(err){
				  				Notifications.danger("Обшика получения шаблона задачи", err, data)
				  				return;
				  			}

				  			$ctrl.newItem.template = data;
				  			$ctrl.task = Object.assign($ctrl.task, data)
				  			$ctrl.task.name = data.name
					 		$ctrl.task.description = data.description
					 		$ctrl.task.taskType = data.type
					 		$ctrl.task.minAttachmentsCount = data.minAttachCount
					 		$ctrl.task.executorsCommentRequired = data.commentRequired

					 		$ctrl.task.active = data.active;
					 		$ctrl.task.groupIds = data.groupIds;
					 		$ctrl.task.businessDirIds = data.businessDirIds;
					 		$ctrl.task.notificationPeriod = _.camelCase(data.notificationType) || $ctrl.notificationFrequencies[0].val;
				  		})
				  	}
				  	if ($ctrl.newItem.taskType) {
				  		$ctrl.task.taskType = $ctrl.newItem.taskType.val
				  	}
			 	} else if ($ctrl.newItem.type=="taskTemplate") {

			 		$ctrl.task.id = $ctrl.newItem.taskTemplate.id

			 		$ctrl.task.name = $ctrl.newItem.taskTemplate.name
			 		$ctrl.task.description = $ctrl.newItem.taskTemplate.description
			 		$ctrl.task.taskType = $ctrl.newItem.taskTemplate.type
			 		$ctrl.task.minAttachmentsCount = $ctrl.newItem.taskTemplate.minAttachCount
			 		$ctrl.task.executorsCommentRequired = $ctrl.newItem.taskTemplate.commentRequired
			 		$ctrl.task.active = $ctrl.newItem.taskTemplate.active;
			 		$ctrl.task.groupIds = $ctrl.newItem.taskTemplate.groupIds;
			 		$ctrl.task.businessDirIds = $ctrl.newItem.taskTemplate.businessDirIds;
			 		$ctrl.task.notificationPeriod = _.find($ctrl.notificationFrequencies, {val: _.camelCase($ctrl.newItem.taskTemplate.notificationType)})//$ctrl.newItem.taskTemplate.notificationType

			 	}
			}

			handleTaskDateChange('create', $ctrl.createDate);
		};

		$ctrl.onLevelChange = function(){
			$ctrl.selectedExecutors = [];
			if($ctrl.executorsForShopsFilter($ctrl.executorsLevel.role).length == 0){
				Notifications.warning("Нет доступных исполнителей с такой ролью")
			}
		}

		$ctrl.$onChanges = function(changesObj) {
			if ($ctrl.isPeriodic) {
				$ctrl.task = TaskNewService.getPeriodicTaskProperties();
			} else {
				$ctrl.task = TaskNewService.getTaskProperties();

				if ($ctrl.taskName) {
				  $ctrl.task.name = $ctrl.taskName;
				}
			}
		};

		$ctrl.$onDestroy = function() {};

		$ctrl.isFile = function(object) {
		  return object instanceof File;
		};

		$ctrl.removeAttachment = function(index) {
		  $ctrl.preFiles.splice(index, 1);
		};


		$ctrl.isCreateBtnDisabled = function() {
			var areExecutorsSelected = false;
			var areShopsSelected = null;
			let hasEquipments = true;

			if (['FORMS', 'MANUAL_RECOUNT', 'AUTO_RECOUNT', 'EQUIPMENT'].includes($ctrl.task.taskType)) {
				var selectedShops = $ctrl.task.selectedShops ? $ctrl.task.selectedShops : $ctrl.selectedShops;
				areShopsSelected = selectedShops && selectedShops.length > 0;

				if ($ctrl.task.taskType === 'EQUIPMENT') {
				    hasEquipments = Boolean($ctrl.equipments.items.length);
                }
			} else {
				areShopsSelected = true;
			}

			areExecutorsSelected =  $ctrl.forSelf || $ctrl.responsible && $ctrl.responsible.executorsIds && $ctrl.responsible.executorsIds.length ||
									$ctrl.newItem && $ctrl.newItem.ordType == "executors" && $ctrl.newItem.ordItemId ||
									$ctrl.newItem && $ctrl.newItem.ordType == "shops" && $ctrl.newItem.ordItemId && $ctrl.selectedExecutors.length

			var isDisabled = !$ctrl.task.deadlineDate || !$ctrl.task.description || !$ctrl.task.name || !areExecutorsSelected || !areShopsSelected || $ctrl.task.minAttachmentsCount < 0 || taskIsCreated || !$ctrl.task.notificationPeriod || !hasEquipments;

			return isDisabled;
		};

		$ctrl.onTaskTypeChange = function(type) {
			if (type.val === 'MANUAL_RECOUNT') {
                setTaskDefaultText(defaultTexts[0]);
			}

			if (type.val === 'EQUIPMENT') {
                setTaskDefaultText(defaultTexts[1]);
                $ctrl.fetchEquipments();
			}

			if ($ctrl.shopsForExecutors && !$ctrl.shopsForExecutors.length && $ctrl.task.taskType === "FORMS") {
				Notifications.warning("Для выбранных исполнителей нет доступных магазинов или для магазинов не загружены товары");
			}
		};

		$ctrl.toggleAllShopsForExecutorsSelection = function() {
			if ($ctrl.selectedShops.length < $ctrl.shopsForExecutors.length) {
				$ctrl.selectedShops = $ctrl.shopsForExecutors;
			} else {
				$ctrl.selectedShops = [];
                $ctrl.task.deadlineByLocalTime = false;
			}
		};

        $ctrl.resetDeadlineLocalTime = function() {
            if ($ctrl.selectedShops.length) return;

            $ctrl.task.deadlineByLocalTime = false;
        }

		$ctrl.toggleAllShopsSelection = function() {
			if ($ctrl.selectedShops.length < $ctrl.allShops.length) {
				$ctrl.selectedShops = $ctrl.allShops;
			} else {
				$ctrl.selectedShops = [];
			}

			$ctrl.onShopsSelect();
		};


		function linkAttachmentToTask(taskId, attachments, callback) {
		  TaskListServiceHttp.linkAttachmentToTask(taskId, attachments.map(attachment => attachment.uid)).then(result => {
				if (result.status >= 300 || result.status < 200) {
				  callback('ALL_FAILED');
				  return;
				}

				callback(null, []);
		  });
		}

		function uploadFiles(taskIds, files) {
			var promisesUpload = [];

			if (files && files.length !== 0) {
			 	promisesUpload = files.map(function(file) {
					return Upload.upload({
						url: config.backend + '/attachmentService/forTask/upload',
						data: { taskIds: Array.isArray(taskIds) ? taskIds.join(',') : taskIds, attachments: file }
					});
				});

				return $q.all(promisesUpload);
			}
		}

		function uploadNewFiles(taskIds, files, callback) {
		  uploadFiles(taskIds, files).then(function (result) {
			  var countSuccess = 0;

			  for (var i=0; i < result.length; i++) {
				if (result[i].status === 200 || result[i].status === 201){
				  countSuccess++;
				}
			  }

			  if (countSuccess === result.length) {
				callback(null, []);
			  } else {
				callback('NOT_ALL_UPLOADED');
			  }
		  }, function (error) {
				callback('ALL_FAILED');
		  });
		}

		function save() {
			taskIsCreated = true;
			if($ctrl.newItem && $ctrl.newItem.ordType == "shops" && $ctrl.newItem.ordItemId)$ctrl.selectedShops = [{id:$ctrl.newItem.ordItemId}]

			if ($ctrl.generalTask.$invalid && !$ctrl.responsible) {
				console.log("Error on invalid incoming data");
				return;
			}

            let userIds = [];

			if (!$ctrl.responsible && $ctrl.selectedExecutors && $ctrl.selectedExecutors.length){
                userIds = $ctrl.selectedExecutors.map(ex => ex.id);
			}

			if ($ctrl.responsible && $ctrl.responsible.executorsIds.length){
                userIds = $ctrl.responsible.executorsIds;
			}

			if ($ctrl.newItem && $ctrl.newItem.ordType === "executors" && $ctrl.newItem.ordItemId){
                userIds.push($ctrl.newItem.ordItemId);
			}


            if($ctrl.forSelf){
                userIds.push($ctrl.principal.id)
            }


			var create = function(){
                const equipments = $ctrl.task.taskType === 'EQUIPMENT' ? $ctrl.equipments.items.map(item => {
                    return { code: item.CODE_EQUIP, name: item.NAME_EQUIP };
                }) : undefined;

                const taskToSend = {
                    templateId: $ctrl.task.taskType === "FORMS" ? -1 : undefined,
                    planDate: moment($ctrl.createDate).format('YYYY-MM-DD'),
                    deadlineDate: moment($ctrl.task.deadlineDate).format('YYYY-MM-DD'),
                    deadlineTime: convertTimeToUtc($ctrl.deadlineTime ? $ctrl.deadlineTime + ':00' : '23:59:59'),
                    shopIds: $ctrl.selectedShops.map(el => el.id),
                    userIds,
                    role: $ctrl.selectedExecutorsRole ? $ctrl.selectedExecutorsRole : $ctrl.executorsLevel.role,
                    name: $ctrl.task.name,
                    description: $ctrl.task.description,
                    notifyType: $ctrl.task.notificationPeriod,
                    taskType: $ctrl.task.taskType,
                    commentRequired: $ctrl.task.executorsCommentRequired,
                    minAttachmentsCount: $ctrl.task.minAttachmentsCount,
                    isWeb: true,
                    urgent: $ctrl.task.urgent ? $ctrl.task.urgent : false,
                    localcodes: $ctrl.task.taskType === 'MANUAL_RECOUNT' ? $ctrl.goods.map(item => item.localcode) : undefined,
                    equipments,
                    deadlineByLocalTime: $ctrl.task.deadlineByLocalTime,
                };

				TaskListServiceHttp.createTask(taskToSend).then(function(response) {
					if (response.status === 200) {
						if ($ctrl.preFiles && $ctrl.preFiles.length !== 0) {
						  var newFiles = $ctrl.preFiles.filter(file => $ctrl.isFile(file));
						  var existingFiles = $ctrl.preFiles.filter(file => !$ctrl.isFile(file));
						  var requests = [];

						  if (newFiles.length > 0) {
							requests.push(function(callback) {
							  uploadNewFiles(response.data[0].id, newFiles, callback);
							});
						  }

						  if (existingFiles.length > 0) {
							requests.push(function(callback) {
							  linkAttachmentToTask(response.data[0].id, existingFiles, callback);
							});
						  }

						  async.parallel(requests, function(err, result) {
							if (err === 'NOT_ALL_UPLOADED') {
							  Notifications.danger('Ошибка! Некоторые файл(ы) загрузить не удалось', '', 2000);
							} else if (err === 'ALL_FAILED') {
							  Notifications.danger('Ошибка! Файл(ы) загрузить не удалось', '', 2000);
							}

							if ($ctrl.onCreate) {
							  $ctrl.onCreate();
							}

							Notifications.success('Задача создана!');

							if($ctrl.callback){
								$ctrl.callback(response.data)
							}else{
								$state.go('tasks');
							}

						  });
						} else {
						  if ($ctrl.onCreate) {
							$ctrl.onCreate();
						  }

						  Notifications.success('Задача будет создана в течение 15 минут');
						  if($ctrl.callback){
								$ctrl.callback(response.data)
							}else{
								$state.go('tasks');
							}
						}

						return;
					}

					if (response.status === 504 || response.status === -1) {
						Notifications.warning('Создание задачи будет завершено в ближайшее время', '', 20000);
						$state.go('tasks');
						return;
					}

					Notifications.danger('Ошибка создания задачи');
					taskIsCreated = false;
				});
			}

			create()
		}

		function openModalExecutors() {
			ngDialog.open(
				{
					template: 'executorsModal',
					className: 'ngdialog-theme-progressive',
					showClose: true,
					controller: ['$scope', function($scope) {
						$scope.isOpen = true;
						$scope.userPermissions = $ctrl.userPermissions;
						$scope.savedSaps = $ctrl.savedSaps;
						$scope.setExecutors = setExecutors;
						$scope.taskType = $ctrl.task.taskType;
						$scope.selectedExecutorsRole = $ctrl.responsible && $ctrl.responsible.role;
						$scope.currUserAsExecutor = $ctrl.responsible && $ctrl.responsible.currUserAsExecutor;
					}],
					preCloseCallback: function(a) {
						$scope.isOpen = false;

						if (!$ctrl.responsible) {
							TaskListService.clearDataObject();
						}

						return true;
					}
				}
			);
		}

		function setExecutors(responsible) {
			$ctrl.responsible = JSON.parse(JSON.stringify(responsible));
			$ctrl.responsible.executorsIds = $ctrl.responsible.executorsInfo.map(info => info.id);
			$ctrl.responsible.displayValue = generateResponsibleDisplayValue($ctrl.responsible.executorsInfo);
			$ctrl.selectedExecutorsRole = $ctrl.responsible.role;

			$ctrl.savedSaps = _.clone(responsible.saps);
			$ctrl.selectedShops = [];
			$ctrl.loadShopsForExecutors($ctrl.responsible.executorsIds)
		}

		function generateResponsibleDisplayValue(executorsInfo) {
			return executorsInfo.map(info => (info.firstName || '') + ' ' + (info.lastName || '') + ' (' + info.position + ')');
		}

		 $ctrl.preUploadFiles = function(files) {
			var getAllFiles = $ctrl.preFiles.concat(files.map(el => {
				el.typeForPreview = TasksViewService.getAttachmentTypeForPreview(el.type)
				return el;
			}));
			$ctrl.preFiles = getAllFiles.filter(function(val) { return val !== null; });
		}

		$ctrl.loadShopsForExecutors = function(executorsIds){
			$ctrl.shopsForExecutors = undefined;
			$ctrl.shopsForExecutorsLoading =true;
			OrgstructService.getShopsForExecutors({ executorsIds: executorsIds, withIntegration: $ctrl.task.taskType === 'FORMS' }, function(err, data){
				$ctrl.shopsForExecutorsLoading =false;
				if(err){
					Notifications.danger(err, data);
					return;
				}

				if(!data.length && $ctrl.task.taskType=="FORMS")Notifications.warning("Для выбранных исполнителей нет доступных магазинов или для магазинов не загружены товары")
				$ctrl.shopsForExecutors = data;
			})
		}
		$ctrl.loadExecutorsForSelectedShop = function(shopId){
		  	$ctrl.executorsForShops = undefined;
			$ctrl.executorsForShopsLoading = true;
			OrgstructService.getExecutorsForShop(shopId, function(err, data){
				$ctrl.executorsForShopsLoading = false;

				if (err) {
					Notifications.danger(err, data);
					return;
				}
				if (!data.length) {
					Notifications.warning("Не существует исполнителей для выбранных магазинов");
				}
				$ctrl.executorsForShops = data;
			})
		}

		$ctrl.onShopsSelect = function(){
			const shopId = $ctrl.selectedShops[0].id;
			$ctrl.selectedExecutors = [];
			$ctrl.loadExecutorsForSelectedShop(shopId)
		}

		$ctrl.saveTaskTemplate = function(){
			var obj = angular.copy($ctrl.task)

			obj.commentRequired = obj.executorsCommentRequired;
			delete obj.executorsCommentRequired

			obj.type = obj.taskType;
			delete obj.taskType;

			obj.minAttachCount = obj.minAttachmentsCount;
			delete obj.minAttachmentsCount;

			obj.notificationType = obj.notificationPeriod
			delete obj.notificationPeriod;

			TasksTemplateService.updateTemplate(obj, function(err, data){
				if(err){
					Notifications.danger("Ошибка сохранения шаблона задачи: ", err+data)
					return
				}
				Notifications.success("Шаблон задачи успешно изменён")
			})
		}

		$ctrl.openGoodsSelection = function() {
			ngDialog.open(
				{
					template: '<goods-selection selected="selected" apply="onApply(selected)"></goods-selection>',
					className: 'ngdialog-theme-progressive task-modal',
					showClose: true,
					plain: true,
					controller: ['$scope', function($scope) {
						$scope.selected = $ctrl.goods;

						$scope.onApply = function(selected) {
							$ctrl.goods = selected;
							$scope.closeThisDialog();
						}
					}]
				}
			);
		};

        $ctrl.fetchEquipments = function() {
            const equipmentsRequestData = {
                functionName: "ZMM_FM_REP_EQUIP",
                tableOutParams: { ET_DATA: ['CODE_EQUIP', 'NAME_EQUIP', 'EQUIPMENT', 'COMMENTS'] },
                inParams: { IV_WERKS: '3008', IV_DATE: moment().format('DD.MM.YYYY') },
            };

            RfcIntegrationApi.fetchTable(equipmentsRequestData).then(result => {
                if (!result || result.status !== 200 || result.data.error) {
                    $ctrl.equipments.hasError = true;
                    return;
                }

                $ctrl.equipments.hasError = false;
                $ctrl.equipments.items = result.data.table[0].values;
            })
        }

		function setTaskDefaultText(defaultText) {
			$ctrl.task.name = defaultTexts.includes(defaultText) || !$ctrl.task.name ? defaultText : $ctrl.task.name;
			$ctrl.task.description = defaultTexts.includes(defaultText) || $ctrl.task.description ? defaultText : $ctrl.task.description;
		}
	}
})();

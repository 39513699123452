angular.module('smartApp')
	.directive('sortButtons', function() {		
		return {
			restrict: 'E',
			template: 
				'<button ng-click="doSort(true)" ng-disabled="isFirst" class="btn btn-xs btn-{{btnClass}}"><i class="fa fa-arrow-up sm"  /></button>'+
				'<button ng-click="doSort(false)" ng-disabled="isLast" class="btn btn-xs btn-{{btnClass}}"><i class="fa fa-arrow-down sm" /></button>',
				
			
			scope: {click:'=', isLast: '=', isFirst:'=', btnClass:'@', obj:'=', array:'='},
			link: function(scope, elm, attrs, controller) {
				
				//
				scope.doSort = function(direction){
					//console.log(scope.obj, direction)
					console.log(scope.obj ,direction, scope.arra)
					scope.click(scope.obj ,direction, scope.array)
				}

			}
		};
	});
'use strict';

angular.module('smartApp').factory('ObjectServiceForChecklists', function ($resource, config) {
    return $resource(config.backend + '/objects/checklists/:id', {}, {
            'query': { method: 'GET', isArray: true },
		    'get': {
			    method: 'GET',
			    transformResponse: function(data) {
				    if(!data) {
					    return {status: 'error'};
				    }
				    data && (data = angular.fromJson(data));
				    return data;
			    }
		    },
		    put: {method: 'PUT'}
        }
    );
});

angular.module('smartApp').factory('ObjectServiceResolverChecklists', function ($resource, config) {
    return $resource(config.backend + '/checklist/resolvers/:userId/checklists', {userId:'@id'}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if(!data) {
                        return {status: 'error'};
                    }
                    data && (data = angular.fromJson(data));
                    return data;
                }
            },
            put: {method: 'PUT'}
        }
    );
});


(function() {
    'use strict';

    angular.module('smartApp').factory('TaskItemService', TaskItemService);

    TaskItemService.$inject = ['$q', 'Upload', 'config', '$http'];




    function TaskItemService($q, Upload, config, $http) {
        var service = {
            //setStatusSettings: setStatusSettings,
            uploadFiles: uploadFiles,
            deleteFile: deleteFile,
            setUserRole: setUserRole,
            changeStatusTask: function (taskResponseId, action, taskParams){
                return $http.post(config.backend + '/tasks-manual/responses/' + taskResponseId + '/' + action, taskParams)
            },
            updateCheckIntegrationTime: function (formId, integrationDate) {
                return $http.post(config.backend + '/forms/forms/' + formId + '/updateCheckIntegrationFileDate', {date: integrationDate});
            },
            activateInstance: function(formId) {
                return $http.put(config.backend + '/forms/forms/' + formId + '/activateInstancePoints ');
            }
        };

        return service;

        function uploadFiles(taskId, files) {
            if (files && files.length !== 0) {
                return $q.all(
                    files.map(function(file) {
                        var attachments = [];
                        attachments.push({ value: file });
                        return Upload.upload({
                            url:
                                config.backend +
                                '/attachmentService/forResponse/' +
                                taskId,
                            data: { attachments: attachments }
                        });
                    })
                );
            }
            return false;
        }

        function deleteFile(responseId, attachment) {
          return $http.delete(config.backend + '/attachmentService/forResponse/' + responseId + '/' + attachment.uid);
        }


        // function setStatusSettings(status) {
        //     var statuses = {
        //         OPEN: {
        //             statusTitle: 'Открыто',
        //             class: 'task-item--open'
        //         },
        //         IN_PROGRESS: {
        //             statusTitle: 'В работе',
        //             class: 'task-item--in-progress'
        //         },
        //         CLOSED: {
        //             statusTitle: 'Закрыто',
        //             class: 'task-item--closed'
        //         }
        //     };
        //     return statuses[status];
        // }

        function setUserRole(role) {
            var roles = {
                author: 'Автор',
                executor: 'Ответственный',
                observer: 'Наблюдатель'
            };
            return roles[role];
        }
    }
})();

'use strict';

angular.module('smartApp').controller('InspectionActsController', InspectionActsController);

InspectionActsController.$inject = ['$scope', '$state', 'config', 'InspectionActService', 'Notifications', 'localStorageService'];

function InspectionActsController($scope, $state, config, InspectionActService, Notifications, localStorageService) {
    var defaultLimit = 20;

    $scope.calendarsOpen = [];
    $scope.perPageVariants = [ 5, 10, 15, 20 ];
    $scope.filters = { page: 1, size: localStorageService.get("perPage") ? localStorageService.get("perPage") : defaultLimit };
    $scope.multiselectTranslations = config.multiselectTranslations;
    $scope.statuses = [
        { value: 'NEW', name: 'Акт создан' },
        { value: 'SAVED', name: 'Акт сохранен' },
        { value: 'CHECKED_REGION_DIRECTOR', name: 'Акт проверен начальником дп' },
        { value: 'CHECKED_BY_DATE', name: 'Акт проверен со сроками устранения' },
        { value: 'FIXING', name: 'Устранение замечаний' },
        { value: 'FIXED', name: 'Замечания устранены' },
        { value: 'ACCEPTED', name: 'Объект принят' },
        { value: 'EXPORTED', name: 'Акт выгружен в босс' },
        { value: 'DECLINED_BY_ACCOUNTER', name: 'Отклонен бухгалтером ос' },
        { value: 'SEND_TO_ACCEPTION', name: 'Отправлен на повторное согласование' },
        { value: 'NOT_SENT', name: 'Не отправлен в SAP' },
    ];

    $scope.dateOptions = { showWeeks: false };
    $scope.dates = {};

    $scope.open = function($event, id) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.calendarsOpen[1] = false;
        $scope.calendarsOpen[2] = false;
        $scope.calendarsOpen[id] = true;
    };

    $scope.getFilteredActs = function() {
        if ($scope.filters.statuses) {
            $scope.filters.statuses = $scope.filters.statuses.map(filter => typeof filter === 'string' ? filter : filter.value);
        } else {
            $scope.filters.statuses = undefined;
        }
        $scope.filters.shopSap = $scope.filters.shopSap ? $scope.filters.shopSap : undefined;
        $scope.filters.createDateFrom = $scope.dates.createDateFrom ? moment($scope.dates.createDateFrom).format('DD-MM-YYYY') : undefined;
        $scope.filters.createDateTo = $scope.dates.createDateTo ? moment($scope.dates.createDateTo).format('DD-MM-YYYY') : undefined;
        getInspectionActs($scope.filters);
    };

    $scope.clearFilters = function() {
        for (var key in $scope.filters) {
            if (key === 'statuses') {
                $scope.statuses.forEach(filter => filter.ticked = false);
            }

            if (!['size', 'page'].includes(key)) {
                $scope.filters[key] = undefined;
            }
        }

        $scope.dates.createDateFrom = undefined;
        $scope.dates.createDateTo = undefined;
        $scope.filters.page = 1;
        getInspectionActs($scope.filters);
    };

    $scope.openInspectionAct = function(uid) {
        $state.go('inspectionAct', { uid: uid });
    };

    $scope.getInspectionActs = function() {
        getInspectionActs($scope.filters);
    };

    function getInspectionActs(params) {
        params = Object.assign({}, params, { page: params.page - 1, sort: 'createDate,desc', total: undefined } );

        if (localStorageService.get("perPage") !== $scope.filters.size) localStorageService.set("perPage", $scope.filters.size);

        InspectionActService.getInspectionActs(params).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки актов проверки');
                return;
            }

            $scope.filters.total = parseInt(result.headers('x-total-count'));
            $scope.inspectionActs = result.data;
        });
    }

    getInspectionActs($scope.filters);
}

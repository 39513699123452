var PanelDivisionsController;

PanelDivisionsController = function (
    $rootScope,
    $scope,
    $q,
    $filter,
    PanelDivisionsService,
    PanelUsersService,
    Notifications,
    Roles,
    TableParams,
    OrgstructService,
    PanelBusinessLineService,
    Pagination
) {
	$scope.showActive = true;
	$scope.userService = PanelUsersService;
	$scope.isSelected = false;
	$scope.isLoading = true;
	$scope.selected_clusters = [];
	$scope.selected_row = null;
	$scope.division_to_search = null;
	$scope.tableSettings = {
		page: 1,
		limit: 10
	};
	$scope.data = [];
	$scope.allClusters = [];
	$scope.allCurators = [];
	$scope.allSecretUsers = [];
	$scope.engineers = [];
	$scope.energetics = [];

	$scope.getShopsPage = getShopsPage;
    $scope.getDivisionDirectors = getDivisionDirectors;

  $scope.pagination = Pagination.get(function(data) {
    this.viewPort = data;
  });


	$scope.tableParams = new TableParams({
		page: 1,
		count: 5
	}, {
		counts: [],
		total: 0,
		getData: function ($defer, params) {
			if($scope.division && $scope.division.clusters){
				var page = params.page();
				var limit = params.count();
				var from = (page - 1) * limit;
				var to = from + limit;
				this.total = $scope.division.clusters.length;
				$defer.resolve($scope.division.clusters.slice(from, to))
			} else {
				$defer.resolve([]);
			}
		}
	});

    $scope.getData = function(isInit) {
        return PanelDivisionsService.getDivisions(
            $scope.pagination.page,
            $scope.pagination.perPage,
            $scope.division_to_search,
            !$scope.showActive)
            .success(function (data, status, headers) {
                if (data) {
                    $scope.links = PanelDivisionsService.parse(headers('link'));
                    $scope.data = data;
                    if ($scope.data && $scope.data.length) {
                        $scope.edit($scope.data[0], false);
                    }

                    $scope.pagination.total = headers('x-total-count');
                    $scope.pagination.pageChange($scope.data);

                    $scope.isLoading = false;
                } else {
                    $rootScope.$broadcast('noResultsFound');
                }
            });
    };

	init();

    $scope.OrgstructService = OrgstructService;

	/**
	 *
	 * @param {*} flagFromBNUpdate этот флаг костылик, который нужен для того,
	 * чтобы обновить прежде всего allSelectedDirectors, а потом уже загружать информацию для БН
	 */
    function getDivisionDirectors(businessLine, flagFromBNUpdate, hasNoThrobber){

        var reqObjAvailableUsers = {
            active: true,
            levels: 'DIVISION',
            fields: 'businessDir,roles'
        };

        var reqObjSelectedUsers = {
            active: true,
            levels: 'DIVISION',
            fields: 'businessDir,roles'
        };



        if (businessLine && !flagFromBNUpdate){
            reqObjAvailableUsers.businessDirId = businessLine.id;
            reqObjSelectedUsers.businessDirId = businessLine.id;
            reqObjSelectedUsers.divisionId = $scope.division.id;
        } else {
            reqObjSelectedUsers.divisionId = $scope.division.id;
        }

        var initSelectedBLDirectors = [];
        var availableDivisionDirectors = [];

        OrgstructService.getUsers(reqObjAvailableUsers, hasNoThrobber).then(function(result) {
            if (result.status === 200) {
                availableDivisionDirectors = result.data;
                initSelectedBLDirectors = [];

                OrgstructService.getUsers(reqObjSelectedUsers, hasNoThrobber).then(function(result) {
                    if (result.status === 200) {
                        angular.forEach(result.data, function(element) {
                            initSelectedBLDirectors.push(_.find(availableDivisionDirectors, {id: element.id}));
                        });

                        if (!businessLine || businessLine && flagFromBNUpdate) {
                            $scope.allSelectedDirectors = angular.copy(initSelectedBLDirectors);
                        }

                        if (!flagFromBNUpdate) {
                        	$scope.initSelectedBLDirectors = initSelectedBLDirectors;
                            $scope.availableDivisionDirectors = availableDivisionDirectors;
                            $scope.division.directors = $scope.initSelectedBLDirectors;
                        }

                        if(flagFromBNUpdate) {
                            getDivisionDirectors(businessLine, false);
                        }
                    } else {
                        Notifications.danger('Ошибка! Не удается загрузить выбранных пользователей', '', 2000);
                    }
                }, function() {
                    Notifications.danger('Ошибка! Не удается загрузить выбранных пользователей', '', 2000);
                });
            }else{
                Notifications.danger('Ошибка! Не удается загрузить доступных пользователей', '', 2000);
            }
        }, function() {
            Notifications.danger('Ошибка! Не удается загрузить доступных пользователей', '', 2000);
        });
    }

	$scope.clearInput = function() {
		$scope.division_to_search = '';
		$scope.getPage(1);
	};
	$scope.getPage = function (page) {
		$scope.tableSettings.page = page;
		$scope.getData();
	};

	$scope.edit = function (row, hasNoThrobber) {
		$scope.selected_row = row.id;
		$scope.division = {
			id: row.id,
			active:row.active,
			name: row.name,
			description: row.description,
			clusters: row.clusters,
			secrets: null,
			//curators: null,
			shops: row.shops
		};

		//updateEngineersAndEnergetics();
		PanelDivisionsService.getDivisionInfo($scope.division.id, hasNoThrobber).success(function (data) {
			$scope.division.clusters = data.clusters.map(function(e){
				return _.find($scope.allClusters, function(clust){
					return e.id === clust.id;
				});
			}).filter(function(e){return e;});

            $scope.division.sapLoginDivision = data.sapLoginDivision;

			// $scope.division.curators = data.curators.map(function(cur){
			// 	return _.find($scope.allCurators, function(e){return e.id === cur.id}) || cur;
			// });

			if(data.secrets) {
				$scope.division.secrets = data.secrets.map(function(cur){
					return _.find($scope.allSecretUsers, function(e){return e.id === cur.id}) || cur;
				});
			}

			if(data.engineer){
				var eng = _.find($scope.engineers, function(e){return e.id === data.engineer.id});
				if(eng){
					$scope.division.engineer = eng;
				}else{
					$scope.engineers.push(data.engineer);
					$scope.division.engineer = data.engineer;
				}
			}

			if(data.energetic){
				var en = _.find($scope.energetics, function(e){return e.id === data.energetic.id});
				if(en){
					$scope.division.energetic = en;
				}else{
					$scope.energetics.push(data.energetic);
					$scope.division.energetic = data.energetic;
				}
			}
			$scope.tableParams.reload();
			getDivisionShops(hasNoThrobber);
            getBusinessDirs(hasNoThrobber);
            getDivisionDirectors(null, null, hasNoThrobber);
		});
	};

    $scope.saveChangesInformation = function () {
        delete $scope.division.shops;
        delete $scope.division.directors;

        PanelDivisionsService.updateDivision($scope.division).then(function (result) {
            if(result.status === 200 || result.status === 201) {
                Notifications.success('Успешно обновлено');
                $scope.getData()
                    .then(function() {
                        getDivisionShops();
                    });
            } else if (result.headers('x-error') === 'DuplicateSapLoginDivision') {
				Notifications.danger('Ошибка! Такой SAP логин ответственного уже существует.');
            } else {
            	Notifications.warning('Изменения утеряны');
            }
        });
    };

    $scope.saveChangesDirectors = function () {
        var divisionDirectorsIds = [];

        if($scope.division.businessLine) { //так как БН является фильтром и не взаимодействует с бекендом, то будем вручную фильтровать то, что нужно
            var diff;
            divisionDirectorsIds = $scope.allSelectedDirectors.map(function(elem){ //$scope.allSelectedDirectors - все выбранные директора без учета БН
                return elem.id;
            });
            //$scope.division.directors - выбранные директора в БН на текущий момент
            angular.forEach($scope.division.directors, function(divisionDirector){ //эта логика для добавления выбранных директоров в текущий БН
                diff = null;
                diff = $scope.allSelectedDirectors.filter(function(initBLDirector){
                    return divisionDirector.id === initBLDirector.id;
                })[0];
                if(!diff){
                    divisionDirectorsIds.push(divisionDirector.id);
                }
            });
            //initSelectedBLDirectors -  выбранные директора в БН изначально с сервера
            angular.forEach($scope.initSelectedBLDirectors, function(initBLDirector){ //эта логика для удаления выбранных директоров из текущего БН
                diff = null;
                diff = $scope.division.directors.filter(function(divisionDirector){
                    return initBLDirector.id === divisionDirector.id;
                })[0];
                if(!diff){
                    var index = divisionDirectorsIds.indexOf(initBLDirector.id);
                    if (index > -1){
                        divisionDirectorsIds.splice(index, 1);
                    }
                }
            });
        }else{
            divisionDirectorsIds = $scope.division.directors.map(function(elem){
                return elem.id;
            });
        }

        if (divisionDirectorsIds.length > 0) {
            updateDivisionDirectors('updateDivisionDirectors', divisionDirectorsIds);
        }else{ //если пользователь не выбрал ни одного директора, удаляем всех соотвественно
            updateDivisionDirectors('deleteDivisionDirectors');
        }
    };

    function updateDivisionDirectors(method, directorsIds){ //method - либо updateDivisionDirectors, либо deleteDivisionDirectors
        PanelDivisionsService[method]($scope.division.id, directorsIds).then(function (result) {
            if (result.status === 200) {
                Notifications.success('Ваши изменения сохранены', '', 2000);
                $scope.getData();
                getDivisionDirectors($scope.division.businessLine, true);
            } else {
                Notifications.danger('Ошибка! Не удается обновить директоров ' + $filter('wordReplace')('clusters'), '', 2000);
            }
        }, function() {
            Notifications.danger('Ошибка! Не удается обновить директоров ' + $filter('wordReplace')('clusters'), '', 2000);
        });
    }

	function getShopsPage(page){
		if($scope.division.shopsPagination){
			$scope.division.shopsPagination.page = page;
			getDivisionShops();
		}
	}

	function getDivisionShops(hasNoThrobber) {

		if($scope.division && $scope.division.shops && $scope.division.shopsPagination){
			var page = $scope.division.shopsPagination.page;
			var limit = $scope.division.shopsPagination.limit;
		}
		PanelDivisionsService.getDivisionShops($scope.division.id, page || 1, limit || 10, hasNoThrobber).success(function(result, status, headers){
			if(status === 200){
				$scope.division.shops = result;
				if(!$scope.division.shopsPagination){
					$scope.division.shopsPagination = {
						page: 1,
						limit: 10
					};
				}
				$scope.division.shopsPagination.links = PanelDivisionsService.parse(headers('link'))
			}else if(status === 204){
				$scope.division.shops = [];
			}

		});
	}

	function getBusinessDirs(hasNoThrobber){

        PanelBusinessLineService.getAllBusinessDirs({}, hasNoThrobber).then(function(result) {
            if(result.status === 200){
                $scope.businessLines = result.data;
            }else{
                Notifications.danger(' Ошибка! Не удается загрузить список бизнес-направлений', '', 2000);
            }
        }, function() {
			Notifications.danger('Ошибка! Не удается загрузить список бизнес-направлений', '', 2000);
        });
	}

	function init() {
        $q.all([
            PanelDivisionsService.getAllClusters(),
            PanelUsersService.getUsersByRole(Roles.ROLE_CURATOR)
        ]).then(function(resp) {
            $scope.allClusters = resp[0].data;
            $scope.allCurators = resp[1].data;
            $scope.getData(true);
        });
	}
};

angular.module('smartApp').controller('CommonPanelDivisionsController', PanelDivisionsController);

PanelDivisionsController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$filter',
    'PanelDivisionsService',
    'PanelUsersService',
    'Notifications',
    'PrincipalRoles',
    'ngTableParams',
    'OrgstructService',
    'PanelBusinessLineService',
    'Pagination'
];

<script setup>
import { onBeforeUnmount, onMounted, ref, useModel, watch } from 'vue';
import { ULabel, SingleSelect, MultipleSelect, Loader, CustomButton, CustomInput, CustomCheckbox, Modal, Tree } from '@eron/ui-kit';
import { Notifications } from '@shared/notifications';
import { useExecutorLevels } from '@/use/tasks/executor-levels';
import { buildObjectsTree, clearDataCache, searchTree, traverseTree } from '@/services/tasks/executors-tree';
import { getBusinessDirections } from '@/api/common/orgstruct';

import ExpansionPanel from '@/components/common/ExpansionPanel.vue';
import SapSearchTextarea from '@/components/tasks/SapSearchTextarea.vue';
import RoundedBlock from '@/components/common/RoundedBlock.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
    expanded: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value']);

let skipUpdate = false;
let treeData = [];

const innerExpanded = useModel(props, 'expanded');
const { executorLevels } = useExecutorLevels({ alwaysIncludeShopLevel: true });
const executorLevel = ref('SHOP');
const filteredTreeData = ref([]);
const loading = ref(false);
const businessDirIds = ref([]);
const businessDirOptions = ref([]);
const loadingBusinessDirs = ref(false);
const levelChangeConfirmVisible = ref(false);
const checkedAll = ref(false);
const searchString = ref();
const checked = { divisions: [], regions: [], shops: [] };
const expandedNodes = { divisions: [], regions: [], shops: [] };

let prevExecutorLevel;
let treeObjects;

const emitUpdate = () => {
    skipUpdate = true;
    emit('update:model-value', {
        ...props.modelValue,
        executors: {
            executorRole: executorLevel.value,
            divisionIds: checked.divisions,
            regionIds: checked.regions,
            shopIds: checked.shops,
            bnIds: businessDirIds.value,
        },
    });
};

const fillBusinessDirections = async () => {
    loadingBusinessDirs.value = true;
    try {
        const result = await getBusinessDirections();
        // В селекте “Бизнес-направления“ должно быть всегда скрыто бизнес-направление с ID = 1006 (Ревизоры),
        // т.к. им никто не ставит задачи.
        businessDirOptions.value = result.data.filter(bd => bd.id !== 1006);
    } catch {
        Notifications.danger('Произошла ошибка при загрузке бизнес-направлений');
    }
    loadingBusinessDirs.value = false;
};

const refreshCheckedAllState = () => {
    checkedAll.value = filteredTreeData.value.every(node => checked.divisions.includes(node.id));
};

const doSearchTree = () => {
    let newTreeData = treeData;

    if (searchString.value) {
        newTreeData = searchTree(treeData, searchString.value);
    }

    filteredTreeData.value.length = 0;
    Array.prototype.push.apply(filteredTreeData.value, newTreeData);
    refreshCheckedAllState();
};

const refreshTree = async params => {
    loading.value = true;

    const { tree, objects } = await buildObjectsTree({
        executorLevel: executorLevel.value,
        checked,
        expanded: expandedNodes,
        ...params,
    });

    treeData = tree;
    treeObjects = objects;

    doSearchTree();
    loading.value = false;
};

const toggleCheckedAll = async () => {
    if (checkedAll.value) {
        checked.divisions = [];
        checked.regions = [];
        checked.shops = [];
    }

    filteredTreeData.value.forEach(node => {
        const idx = checked.divisions.indexOf(node.id);

        if (checkedAll.value) {
            idx === -1 && checked.divisions.push(node.id);
        } else if (idx !== -1) {
            checked.divisions.splice(idx, 1);
        }
    });

    refreshTree();
    emitUpdate();
};

const autoSelectBusinessDirs = () => {
    if (['REGION', 'DIVISION'].includes(executorLevel.value)) {
        businessDirIds.value = [1001];
    } else if (executorLevel.value === 'SHOP') {
        businessDirIds.value = [1004];
    }
};

const getAvailableShops = () => {
    return Object.values(treeObjects.shops);
};

const onExecutorLevelChange = newVal => {
    prevExecutorLevel = executorLevel.value;
    executorLevel.value = newVal;

    if (checked.divisions.length || checked.shops.length || checked.regions.length) {
        levelChangeConfirmVisible.value = true;
    } else {
        onExecutorLevelChangeConfirm();
    }
};

const onExecutorLevelChangeConfirm = () => {
    prevExecutorLevel = undefined;
    levelChangeConfirmVisible.value = false;
    checked.divisions = [];
    checked.regions = [];
    checked.shops = [];
    checkedAll.value = false;

    autoSelectBusinessDirs();
    refreshTree();
    emitUpdate();
};

const onExecutorLevelChangeCancel = () => {
    executorLevel.value = prevExecutorLevel;
    prevExecutorLevel = undefined;
    levelChangeConfirmVisible.value = false;
};

const onNodeCheckToggle = changedNode => {
    if (checked[changedNode.key].includes(changedNode.id)) {
        checked[changedNode.key].splice(checked[changedNode.key].indexOf(changedNode.id), 1);
    } else {
        checked[changedNode.key].push(changedNode.id);
    }

    changedNode.nodes?.forEach(node => {
        const idx = checked[node.key].indexOf(node.id);
        if (idx !== -1) checked[node.key].splice(idx, 1);
        node?.nodes.forEach(node => {
            const idx = checked[node.key].indexOf(node.id);
            if (idx !== -1) checked[node.key].splice(idx, 1);
        });
    });

    refreshCheckedAllState();
    refreshTree();
    emitUpdate();
};

const onNodeExpanded = (node, state) => {
    if (state) expandedNodes[node.key].push(node.id);
    else expandedNodes[node.key].splice(expandedNodes[node.key].indexOf(node.id), 1);
};

const onBusinessDirsChange = () => {
    emitUpdate();
};

const onShopsSearch = shops => {
    const shopIds = shops.map(s => s.id);
    const idsToCheck = [];

    traverseTree(treeData, node => {
        if (node.key === 'shops' && shopIds.includes(node.id) && !node.checked) {
            idsToCheck.push(node.id);
        }
    });

    checked.shops = idsToCheck.concat(checked.shops);
    refreshTree();
    emitUpdate();
};

const onSearchChange = _.debounce(doSearchTree, 800);

const validate = () => {
    return executorLevel.value && !!businessDirIds.value.length && !!(checked.divisions.length || checked.regions.length || checked.shops.length);
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        if (newVal) {
            const { executorRole, divisionIds, regionIds, shopIds, bnIds } = newVal.executors || {};
            executorLevel.value = executorRole || 'SHOP';
            businessDirIds.value = bnIds || [];
            checked.divisions = divisionIds || [];
            checked.regions = regionIds || [];
            checked.shops = shopIds || [];
            autoSelectBusinessDirs();
        }

        refreshTree({ invalidateCache: true });
    },
    { immediate: true }
);

onMounted(async () => {
    await fillBusinessDirections();
    autoSelectBusinessDirs();
});

onBeforeUnmount(() => {
    clearDataCache();
});

defineExpose({ validate });
</script>

<template>
    <div>
        <expansion-panel id="tasks-generate-executors" v-model="innerExpanded" title="Выбор ответственных">
            <div class="-relative">
                <div class="-mb-6">
                    <u-label for-value="tasks-generate-executors-level"> Исполнитель</u-label>
                    <single-select
                        id="tasks-generate-executors-level"
                        :model-value="executorLevel"
                        :options="executorLevels"
                        :clearable="false"
                        @update:model-value="onExecutorLevelChange"
                    />
                </div>

                <div v-if="executorLevel === 'SHOP'" class="-mb-6">
                    <u-label for-value="tasks-generate-executors-sap-search"> Выбрать по номерам магазинов </u-label>
                    <sap-search-textarea id="tasks-generate-executors-sap-search" :get-available-shops="getAvailableShops" @search="onShopsSearch" />
                </div>

<!--                <div class="-mb-6">-->
<!--                    <u-label for-value="tasks-generate-executors-business-dirs"> Бизнес-направления </u-label>-->
<!--                    <multiple-select-->
<!--                        id="tasks-generate-executors-business-dirs"-->
<!--                        v-model="businessDirIds"-->
<!--                        :options="businessDirOptions"-->
<!--                        :loading="loadingBusinessDirs"-->
<!--                        label-prop="name"-->
<!--                        value-prop="id"-->
<!--                        @update:model-value="onBusinessDirsChange"-->
<!--                    />-->
<!--                </div>-->

                <rounded-block class="-mb-3 -max-w-full">
                    <div class="-flex -items-center" style="padding: 0.625rem 2.1875rem">
                        <custom-checkbox
                            id="task-executor-check-all"
                            v-model:checked="checkedAll"
                            style="margin-right: 0.625rem"
                            @update:checked="toggleCheckedAll"
                        />

                        <custom-input id="task-executor-search" v-model.trim="searchString" icon-name="search" @update:model-value="onSearchChange" />
                    </div>

                    <tree
                        v-model:nodes="filteredTreeData"
                        use-checkbox
                        class="-max-h-96 -overflow-auto"
                        @node-expanded="onNodeExpanded"
                        @node-checked="onNodeCheckToggle"
                        @node-unchecked="onNodeCheckToggle"
                    />
                </rounded-block>

                <loader v-if="loading" inside-element inside-element-position="absolute" class="-text-primary" />
            </div>
        </expansion-panel>

        <modal v-model="levelChangeConfirmVisible" :focus-trap="false" :teleport-to="false">
            <template #default>
                <div class="-max-w-xl -text-lg">
                    Выбор исполнителей задачи возможен только в одной вкладке. Очистить список выбранных исполнителей и перейти в другую вкладку?
                </div>
            </template>
            <template #footer>
                <div class="-flex">
                    <custom-button text="Ок" theme="green" class="-mr-4" @click="onExecutorLevelChangeConfirm" />

                    <custom-button text="Отмена" @click="onExecutorLevelChangeCancel" />
                </div>
            </template>
        </modal>
    </div>
</template>

(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('tasksExtendedFilter', tasksExtendedFilter);

    tasksExtendedFilter.$inject = [];

    function tasksExtendedFilter() {
        var directive = {
            templateUrl: 'scripts/app/tasks/tasks-extended-filter/tasks-extended-filter.view.html',
            bindToController: true,
            controller: TasksFilterController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                generalFilter: '=',
                filter: '&',
                taskType: '<'
            }
        };
        return directive;
    }

    TasksFilterController.$inject = ['Principal', '$scope', 'TasksExtendedFilterService', 'Notifications', '$filter', 'MyResponsibilityFilter', 'localStorageService'];

    function TasksFilterController (Principal, $scope, TasksExtendedFilterService, Notifications, $filter, MyResponsibilityFilter, localStorageService) {
        var vm = this;
        let filterByMyResponsibility;

        vm.isRegManager = Principal.isInRole('ROLE_REG_MANAGER');
        vm.mainSearchFilters = TasksExtendedFilterService.filterSettings();

        vm.allDivisions = [];
        vm.allRegions = [];
        vm.selectedDivisions = [];
        vm.selectedRegions = [];
        vm.calendarsOpen = [];
        vm.reset = reset;
        vm.showDivisionFilter = true;
        vm.showClusterFilter = true;

        vm.dateOptions = {
          createDateFromOpts: { showWeeks: false },
          createDateToOpts: { showWeeks: false },
          deadlineDateFromOpts: { showWeeks: false },
          deadlineDateToOpts: { showWeeks: false }
        };

        vm.onlyMyResponsibility = {
            options: [
                { label: 'Все объекты', value: false },
                { label: 'Только мои объекты', value: true },
            ],
            value: false,
            lsKey: 'tasksExtendedFilter:onlyMyResponsibility'
        };

        init();

        function init() {
//            var identity = Principal.getIdentity();

            if(Principal.isInAnyRole(["ROLE_SHOPDIR", "ROLE_DIRECTOR_CLUSTER"])){
                vm.showDivisionFilter = false;
                vm.showClusterFilter = false;
            }else if(Principal.isInRole("ROLE_CURATOR")){
                vm.showDivisionFilter = false;
            }

            // Если в локал стораже сохранены фильтры, тогда при загрузке страницы они применятся
            TasksExtendedFilterService.setFilter(vm.mainSearchFilters, vm.generalFilter);
            TasksExtendedFilterService.getListData()
                .then(function(data) {
                    vm.allDivisions = [];
                    if(!data[0]){
                        Notifications.danger("Ошибка: не подгружаются " + $filter('wordReplace')('division') + "ы")
                    }else if(!data[0].length && vm.showDivisionFilter){
                        Notifications.warning("Ошибка: отсутствуют " + $filter('wordReplace')('division') + "ы")
                    }else{
                        vm.allDivisions = data[0]
                    }

                     if(!data[1]){
                        Notifications.danger("Ошибка: не подгружаются " + $filter('wordReplace')('clusters'))
                    }else if(!data[1].length && vm.showClusterFilter){
                        Notifications.warning("Ошибка: отсуствуют " + $filter('wordReplace')('clusters'))
                    }else{
                        vm.allRegions = data[1];
                        //vm.allRegions = JSON.parse('[{"id":85,"name":"Регион 1","active":true,"divisionId":28},{"id":88,"name":"Регион 2 Дивизиона 1","active":true,"divisionId":28}]');
                    }

                    if (vm.isRegManager) {
                        filterByMyResponsibility = MyResponsibilityFilter(vm.allDivisions, vm.allRegions);
                        vm.onlyMyResponsibility.value = localStorageService.get(vm.onlyMyResponsibility.lsKey) === 'true';
    
                        if (vm.onlyMyResponsibility.value) {
                            vm.onOnlyMyResponsibilityChange();
    
                            if (vm.mainSearchFilters.divisions.length === vm.allDivisions.length) {
                                vm.mainSearchFilters.divisions = [];
                            }
    
                            refreshGeneralFilter();
                        }
                    }

                    vm.filter();
                })

        }

        vm.handleDateChange = function(name) {
          var opposites = {
            createDateTo: 'createDateFrom', deadlineDateFrom: 'deadlineDateTo',
            createDateFrom: 'createDateTo', deadlineDateTo: 'deadlineDateFrom'
          };
          var isToDate = name.toLowerCase().indexOf('to') !== -1;
          var oppositeOpts = opposites[name] + 'Opts';

          if (isToDate) {
            vm.dateOptions[oppositeOpts].maxDate = moment(vm.mainSearchFilters[name]).toDate();
          } else {
            vm.dateOptions[oppositeOpts].minDate = moment(vm.mainSearchFilters[name]).toDate();
          }
        };

        vm.open = function($event, id) {
            $event.preventDefault();
            $event.stopPropagation();
            for(var i = 1; i <= 4; i++){
                vm.calendarsOpen[i] = (i == id)?true:false
            }
        }

        vm.onOnlyMyResponsibilityChange = function() {
            const result = filterByMyResponsibility(vm.onlyMyResponsibility.value)
            vm.allDivisions = result.divisions;
            vm.allRegions = result.clusters;
        };

        vm.applyFilter = function() {
            localStorageService.set(vm.onlyMyResponsibility.lsKey, vm.onlyMyResponsibility.value);
            refreshGeneralFilter()
            vm.filter();
        }

        function refreshGeneralFilter() {
            TasksExtendedFilterService.setFilter(vm.generalFilter, Object.assign({}, vm.mainSearchFilters, {
                divisions: vm.onlyMyResponsibility.value && !vm.mainSearchFilters.divisions.length && !vm.mainSearchFilters.regions.length ?
                    vm.allDivisions : vm.mainSearchFilters.divisions
            }));
        }

        function reset() {
            vm.selectedDivisions = [];
            vm.selectedRegions = [];
            vm.onlyMyResponsibility.value = false;
            vm.mainSearchFilters = angular.copy(TasksExtendedFilterService.epmtyFilters);
            vm.applyFilter()
       }

        // function changeDate(date, key) {
        //     vm.mainSearchFilters[key] = date ? +date : null;
        // }
    }
})();

/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 11.06.15.
 */

(function () {
	'use strict';

	angular.module('smartApp')
		.config(function ($stateProvider) {
			$stateProvider
				.state('counters.report', {
					parent: 'counters',
					url: '/counters/report',
					data: {
						roles: ['ROLE_ENGINEER', 'ROLE_MAIN_ENGINEER']
					},
					views: {
						'content@': {
							templateUrl: 'scripts/app/counters/reports/counters-report.html'
						}
					}
				})
		});
})();

(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('ShopDirTasksService', ShopDirTasksService);

    ShopDirTasksService.$inject = [];

    function ShopDirTasksService() {
        var fields = [{
            prop: 'id',
            title: 'ID задачи'
        },{
            prop: 'name',
            title: 'Наименование задачи'
        },{
            prop: 'authorName',
            title: 'Автор'
        },{
            prop: 'createDate',
            title: 'Дата создания'
        },{
            prop: 'deadlineDate',
            title: 'Крайний срок'
        },{
            prop: 'status',
            title: 'Статус'
        }];

        var service = {
            fields: fields,
            convertDateToNumber: convertDateToNumber
        };

        return service;

        function convertDateToNumber(date) {
            return +moment.utc(date);
        }
    }
})();
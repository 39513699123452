var PanelRolesService;

PanelRolesService = function($http, config, $q, $filter, $timeout) {
    return {
        getRoles: function(page, limit, role_name) {
            var query;
            if (!page && !limit) {
                page = 1;
                limit = 100;
            }
            query =
                config.backend +
                '/admin/authorities?page=' +
                page +
                '&per_page=' +
                limit;
            if (role_name && role_name !== '') {
                query += '&name=' + role_name;
            }
            return $http.get(query);
        },
        getReportRoles: function() {
            return $http.get(config.backendOrgstruct + '/roles/reports/');
        },
        createRole: function(role_info) {
            return $http.post(config.backend + '/admin/authorities', role_info);
        },
        updateRole: function(role_to_update) {
            return $http.put(
                config.backend + '/admin/authorities',
                role_to_update
            );
        },
        getAllGroups: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/groups', {
                noThrobber: hasNoThrobber
            });
        },
        getAllUsers: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/users', {
                noThrobber: hasNoThrobber
            });
        },
        getRoleInfo: function(name, hasNoThrobber) {

            return $http.get(config.backend + '/admin/authorities/' + name, {
                noThrobber: hasNoThrobber
            });
        },
        parse: function(headers) {
            var links, parts;
            parts = headers.split(',');
            links = {};
            angular.forEach(parts, function(p) {
                var name, page, queryString, section, url;
                section = p.split(';');
                url = section[0].replace(/<(.*)>/, '$1').trim();
                queryString = {};
                url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
                    $0,
                    $1,
                    $2,
                    $3
                ) {
                    return (queryString[$1] = $3);
                });
                page = queryString['page'];
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                name = section[1].replace(/rel="(.*)"/, '$1').trim();
                return (links[name] = page);
            });
            return links;
        }
    };
};

angular.module('smartApp').factory('PanelRolesService', PanelRolesService);

PanelRolesService.$inject = ['$http', 'config', '$q', '$filter', '$timeout'];

(function() {
    'use strict';

    angular.module('smartApp').factory('ParamsTabService', ParamsTabService);

    ParamsTabService.$inject = ['Notifications'];

    function ParamsTabService(Notifications) {
        var service = {
            getResponseMessage: getResponseMessage,
            setPropertiesForSaving: setPropertiesForSaving,
            updateProperty: updateProperty,
            setClass: setClass
        };

        return service;

        /**
         * @desc sets notification text by response and show its
         * @param {object} resp
         * @param {object} property
         */
        function getResponseMessage(resp, property) {
            var message;
            if (resp.status === 200) {
                message =
                    'Изменения для поля "' +
                    property.label +
                    '" сохранены';
                Notifications.success(message);
            } else {
                message =
                    'Ошибка сохранения поля "' + property.label + '"';
                Notifications.danger(message);
            }
        }

        /**
         * @desc adds changes property for saving or updates its value
         * if property has beend added
         * @param {object} property
         * @param {array} propertiesForSaving
         */
        function setPropertiesForSaving(property, propertiesForSaving) {
            var existingPropIndex = _.findIndex(
                propertiesForSaving,
                function(propertyItem) {
                    return propertyItem.propertyKey === property.propertyKey;
                }
            );

            if (existingPropIndex !== -1) {
                propertiesForSaving[existingPropIndex].value = property.value;
            } else {
                propertiesForSaving.push(property);
            }
        }

        /**
         * @desc updates array of all properties after success updating some properties
         * @param {object} resp
         * @param {obejct} property
         * @param {array} allProperties
         */
        function updateProperty(resp, property, allProperties) {
            var updatedProperty;
            if (resp.status === 200) {
                updatedProperty = _.find(allProperties, function(propertyItem) {
                    return propertyItem.propertyKey === property.propertyKey;
                });
                updatedProperty.value = property.value;
            }
        }

        function setClass(propertyType) {
            var classes = {
                checkbox: 'params__input-container--checkbox',
                chips: 'params__input-container--chips'
            };
            return classes[propertyType];
        }
    }
})();

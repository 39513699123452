angular.module('smartApp')
    .directive('timeInput', function(timeFormatter) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                onChange: '&',
            },
            link: function(scope, element, attrs, ngModel) {
                element.on('blur', () => {
                    const value = ngModel.$viewValue ? timeFormatter(ngModel.$viewValue) : '';

                    ngModel.$setViewValue(value);
                    ngModel.$render();
                    scope.onChange({ value });
                });

                scope.$on('$destroy', function() {
                    element.off('blur');
                });
            },
        };
    });

'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('analytics', {
                url: '/analytics',
                data: {
                    roles: ['ROLE_REPORT_POWER_BI']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analytics.html',
                        controller: function($scope, SystemParamsService, Notifications, $sce){                            
                            SystemParamsService.getSystemProps(['powerbi.link'], function(err, params){
                                if(err){
                                    Notifications.danger('Ошибка получения данных');
                                    cb(result.status)
                                    console.log("Err: ", err);
                                    return
                                }

                                $scope.powerBiLink =  $sce.trustAsResourceUrl(params["powerbi.link"])

                            })
                        }
                    }
                },
                parent: 'site'
            });
    });
(function() {
    'use strict';

    angular
        .module('smartApp')
        .component('taskNew', {
            templateUrl: 'scripts/app/tasks/task-new/task-new.view.html',
            controller: TaskNewController,
            controllerAs: '$ctrl',
            bindings: {}
        });

    TaskNewController.$inject = ['$stateParams'];

    function TaskNewController($stateParams) {
        var $ctrl = this;

        $ctrl.states = [{
            title: 'обычная',
            state: 'tasks.new.general'
        }
        // , {
        //     title: 'периодическая',
        //     state: 'tasks.new.periodic'
        // }
        ];

        if($stateParams.newItem){
            $ctrl.newItem = JSON.parse($stateParams.newItem)
        }

        $ctrl.$onInit = function() { };
        $ctrl.$onChanges = function(changesObj) { };
        $ctrl.$onDestroy = function() { };
    }
})();
'use strict';

angular.module('smartApp')
    .service('ProcessTreeUtils', function () {
        this.setupIconForNewNode = function (el) {
            if(!el.children){
                el.children = (el.hasChildren) ? {} : [];
            }else if(el.children.length != 0){
                el.hasChildren = true;
            }
            

            if(el.hasQuestions) el.type = 'categorie';
            if(el.hasChildren) el.type = 'group';
            if(!el.hasQuestions && !el.hasChildren) el.type = 'empty'
            if(!el.parentId)el.type = 'group';

            switch(el.type){
                case "group":
                    //el.icon = (!el.hasChildren) ? "folderEmpty" : "folder";
                    el.icon = "folder"
                    break;
                case "categorie":
                    el.icon = 'fileEmpty';
                    if(el.hasQuestions)el.icon = 'fileFilled'
                    break;
                case 'empty':
                    el.icon = 'empty'
                    break;
                default:
                    el.icon = 'wheelchair'
                    break;
            }
         
            delete el.hasChildren;
            return el;
        };
    });

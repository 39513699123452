angular.module('smartApp').controller('FormFullViewController', ['$scope', '$stateParams', '$timeout', 'TaskListServiceHttp', 'Principal', 'Notifications', 'FormsService', 'CompareTimeService', function($scope, $stateParams, $timeout, TaskListServiceHttp, Principal, Notifications, FormsService, CompareTimeService) {
  var answers = null;
  var answerTimer = null;
  var numberCheckTimer = null;
  var allQuestionsDisabled = false;
  var nodesContainer = null;
  var instanceId = null;

  $scope.multiselectTranslate = {
    selectAll: 'Выбрать все',
    selectNone: 'Отменить',
    reset: 'Сбросить',
    search: 'Поиск...',
    nothingSelected: 'Ничего не выбрано'
  };

  $scope.node = {};
  $scope.datePickerOptions = { showWeeks: false, initDate: new Date() };
  $scope.calendarsOpen = {};
  $scope.visibilityMap = {};
  $scope.questionToAnswer = {};
  $scope.fullInfoCollapsed = true;
  $scope.task = null;
  $scope.pointToResolution = {};
  $scope.shopName = null;
  $scope.checkResult = null;
  $scope.relevant = true;

  $scope.getMultiselectInput = function(values, selected) {
    return values.map(valueObj => {
      valueObj.ticked = false;

      selected.forEach(selectedValueObj => {
        if (selectedValueObj.value === valueObj.value) {
          valueObj.ticked = true;
        }
      });

      return valueObj;
    });
  };

  $scope.scrollToBeiginning = function() {
    nodesContainer.scrollTop = 0;
  };

  $scope.toggleFullInfo = function() {
    $scope.fullInfoCollapsed = !$scope.fullInfoCollapsed;
  };

  $scope.getAnswerValue = function(questionId) {
    var val = null;

    for (var i = 0; i < answers.length; i++) {
      if (answers[i].questionId === questionId) {
        val = answers[i].answers;
        break;
      }
    }

    if (!val) {
      return 'Ответ не указан';
    }

    if (val.length === 1) {
      return (val[0].displayValue ? val[0].displayValue : val[0].value) || 'Ответ не указан';
    } else {
      return val.map(obj => (obj.displayValue ? obj.displayValue : obj.value) || 'Ответ не указан').join(', ');
    }
  };

  // $scope.openDatePicker = function($event, questionId) {
  //   $scope.calendarsOpen[questionId] = true;
  //
  //   var questionIds = Object.keys($scope.calendarsOpen);
  //
  //   for (var i = 0; i < questionIds.length; i++) {
  //     $scope.calendarsOpen[questionIds[i]] = questionId === parseInt(questionIds[i]) ? true : false;
  //   }
  // };
  //
  $scope.isQuestionDisabled = function(question) {
    return allQuestionsDisabled || question.autocalculated;
  };
  //
  // $scope.handleNumberAnswerChange = function(answer, min, max, questionId) {
  //   $timeout.cancel(numberCheckTimer);
  //
  //   numberCheckTimer = $timeout(() => {
  //     if ((min || min === 0) && answer < min) {
  //       Notifications.danger('Ответ меньше допустмого минимального значения: ' + min + ' !');
  //       answer = parseInt(min);
  //     } else if ((max || max === 0) && answer > max) {
  //       Notifications.danger('Ответ больше допустмого максимального значения: ' + max + ' !');
  //       answer = parseInt(max);
  //     }
  //
  //     $scope.answerToQuestion(answer, questionId);
  //   }, 2000);
  // };

  $scope.timeoutAnswer = function(answer, questionId) {
    $timeout.cancel(answerTimer);

    answerTimer = $timeout(() => {
      $scope.answerToQuestion(answer, questionId);
    }, 2000);
  };

  $scope.answerToQuestion = function(answer, questionId, pointId) {
    $scope.questionToAnswer[questionId] = answer;

    var answerType = FormsService.getQuestionById(questionId, $scope.template).type;

    if ($scope.isMultiselectAnswer(answerType)) {
      answer = answer.length > 0 ? removeTickedFromOutput(answer) : null;
    } else if ($scope.isDateAnswer(answerType) && answer) {
      answer = moment(answer).format('YYYY-MM-DD');
    }

    updateAnswer(questionId, answer);
    updateQuestionsAndAnswers(FormsService.getQuestionsFromTemplate($scope.template));
    calculateFixpriceResolution(pointId);

    TaskListServiceHttp.updatePointsResolutions([{
      instanceId: instanceId,
      pointId: pointId,
      resolution: $scope.pointToResolution[pointId]
    }]);

    TaskListServiceHttp.multiAnswerToFormQuestion(answers).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка сохранения ответа!');
        return;
      }
    });
  };

  $scope.isBinaryAnswer = function(answer) {
    return answer.uiType === 'RADIO';
  };

  $scope.uploadFiles = function(files, invalidFiles, questionId) {
    console.log(files, invalidFiles, questionId);
  };

  $scope.isFileAnswer = function(answer) {
    return answer.uiType === 'FILE';
  };

  $scope.isTextAreaAnswer = function(answer) {
    return answer.uiType === 'TEXT';
  };

  $scope.isTextAnswer = function(answer) {
    return answer.uiType === 'INPUT_STRING';
  };

  $scope.isNumberAnswer = function(answer) {
    return answer.uiType === 'INPUT_NUMBER';
  };

  $scope.isDateAnswer = function(answer) {
    return answer.uiType === 'DATE';
  };

  $scope.isSelectAnswer = function(answer) {
    return answer.uiType === 'SELECT';
  };

  $scope.isMultiselectAnswer = function(answer) {
    return answer.uiType === 'MULTI_SELECT';
  };

  function updateQuestionsAndAnswers(questions) {
    questions.forEach(question => {
      var questionId = question.id;
      var prevVisibility = $scope.visibilityMap[questionId];
      var currVisibility = checkQuestionVisibility(question);
      var hasValue = !!$scope.questionToAnswer[questionId];

      if (prevVisibility && !currVisibility && hasValue && !question.autocalculated) {
        $scope.questionToAnswer[questionId] = null;
        updateAnswer(questionId, null);
      }

      $scope.visibilityMap[questionId] = currVisibility;
    });
  }

  function checkQuestionVisibility(question) {
    if (!question.visibility) {
      return true;
    }

    var connectedAnswers = question.visibility;

    var canShow = connectedAnswers.every(answer => {
      var rightAnswer = answer.values || [];
      var currentAnswer = FormsService.getAnswerByQuestionId(answer.questionId, answers).answers;

      var isRight = null;

      if (!currentAnswer) {
        isRight = false;
      } else {
        currentAnswer = !Array.isArray(currentAnswer) ? [currentAnswer] : currentAnswer;

        isRight = rightAnswer.length === currentAnswer.length && rightAnswer.every(val => {
          return currentAnswer.every(answerObj => val === answerObj.value);
        });
      }

      return isRight;
    });

    return canShow;
  }

  function updateAnswer(questionId, value) {
    for (var i = 0; i < answers.length; i++) {
      if (answers[i].questionId === questionId) {
        if (value === null) {
          answers[i].answers = value;
          break;
        }

        value = JSON.parse(angular.toJson(value));

        if (typeof value === 'object') {
          value = Array.isArray(value) ? value : [value];
        } else {
          value = [{ id: null, value: value }];
        }

        answers[i].answers = value;
        break;
      }
    }
  }

  function removeTickedFromOutput(output) {
    return output.map(obj => {
      var newObj = {};

      Object.keys(obj).forEach(key => {
        if (key !== 'ticked') {
          newObj[key] = obj[key];
        }
      });

      return newObj;
    });
  }

  function calculateFixpriceResolution(pointId) {
    var pointQuestions = FormsService.getPointById($scope.template, pointId).questions;
    var questionsAnswers = null;

    if (pointQuestions.length > 0) {
      questionsAnswers = FormsService.sortAnswersByQuestions(pointQuestions, FormsService.getQuestionsAnswers(pointQuestions, answers))
    }

    if (questionsAnswers && questionsAnswers.length > 0) {
      $scope.pointToResolution[pointId] = getResolution(questionsAnswers);
    }
  }

  function calculateCheckResult() {
  	var okResolutionCounter = 0;
  	var zeroFaultCounter = 0;
  	var totalCounter = 0;

  	for (var pointId in $scope.pointToResolution) {
  		switch ($scope.pointToResolution[pointId].toLowerCase()) {
		    case 'ok':
        case 'ок':
		    	okResolutionCounter += 1;
		    	break;
		    case 'вина 0':
		    	zeroFaultCounter += 1;
		    	break;
	    }

	    totalCounter += 1;
    }

    return (((okResolutionCounter + zeroFaultCounter) / totalCounter) * 100).toFixed(2);
  }

  function getResolution(answers) {
    var resolution = null;

    if (answers[0].answers && answers[0].answers[0].value === 'Да') {
      resolution = 'Ок';
      answers[0].resolution = resolution;
      answers[1].resolution = null;
    } else if (answers[1].answers && answers[1].answers[0].value === 'Да') {
      resolution = 'Вина 1';
      answers[1].resolution = resolution;
      answers[0].resolution = null;
    } else if (!!answers[0].answers && (!!answers[1].answers || !$scope.visibilityMap[answers[1].questionId])) {
      answers[0].resolution = null;
      answers[1].resolution = null;

      var autoCalculated = [{ index: 3,
                              targetValue: ['Да'] },
                            { index: 4,
                              targetValue: ['Нет'] },
                            { index: 5,
                              targetValue: ['Нет'] },
                            { index: 6,
                              targetValue: ['Да', 'Нет'] }];

      var autocalculatedAnswers = null;

      for (var i = 0; i < autoCalculated.length; i++) {
        autocalculatedAnswers = answers[autoCalculated[i].index].answers;
        if (autocalculatedAnswers && autoCalculated[i].targetValue.indexOf(autocalculatedAnswers[0].value) !== -1) {
          resolution = answers[autoCalculated[i].index].resolution;
          break;
        }
      }
    }

    return resolution;
  }

  function initAnswer(question, answer) {
    $scope.questionToAnswer[question.id] = null;

    if (!answer.answers) {
      return;
    }

    var answerType = question.type;

    if ($scope.isDateAnswer(answerType)) {
      $scope.questionToAnswer[question.id] = moment(answer.answers[0].value).toDate();
    } else if ($scope.isMultiselectAnswer(answerType)) {
      $scope.questionToAnswer[question.id] = [];

      answerType.possibleAnswers.forEach(possibleValueObj => {
        answer.answers.forEach(selectedValueObj => {
          if (possibleValueObj.value === selectedValueObj.value) {
            $scope.questionToAnswer[question.id].push(possibleValueObj);
          }
        });
      });
    } else if ($scope.isSelectAnswer(answerType) || $scope.isBinaryAnswer(answerType)) {
      answerType.possibleAnswers.every(possibleValueObj => {
        if (possibleValueObj.value === answer.answers[0].value) {
          $scope.questionToAnswer[question.id] = possibleValueObj;
          return false;
        }

        return true;
      });
    } else if ($scope.isNumberAnswer(answerType)) {
      $scope.questionToAnswer[question.id] = parseInt(answer.answers[0].value);
    } else {
      $scope.questionToAnswer[question.id] = answer.answers[0].value;
    }
  }

  function initQuestions(questions, answers) {
    questions.forEach(question => {
      for (var i = 0; i < answers.length; i++) {
        if (answers[i].questionId !== question.id) {
          continue;
        }

        initAnswer(question, answers[i]);
        break;
      }

      $scope.visibilityMap[question.id] = checkQuestionVisibility(question);
    });
  }

  function init() {
    if (!$stateParams.id) {
      return;
    }

    var taskId = $stateParams.id;

    async.parallel([
      function(callback) {
        loadTask(taskId, callback);
      },
      function(callback) {
        loadTaskResponses(taskId, callback);
      }
    ], function(err, result) {
      if (err) {
        return;
      }

      $scope.task = result[0][0];

      allQuestionsDisabled = $scope.task.userRole !== 'EXECUTOR' || $scope.task.status === 'CLOSED';

      var response = result[1].content[0];
      var formId = response.formId;
      $scope.shopName = response.shopName;

      TaskListServiceHttp.getFormInstance(formId).then(result => {
        if (result.status !== 200) {
          Notifications.danger('Ошибка загрузки!');
          return;
        }

        instanceId = result.data.id;

        async.parallel([
          function(cb) {
            loadTemplate(instanceId, cb);
          },
          function(cb) {
            loadAnswers(formId, cb);
          }
        ], function(err, result) {
          if (err) {
            return;
          }

          $scope.template = result[0];
          if ($scope.template.lastChangeIntegrationFileDate) {
            $scope.relevant = CompareTimeService($scope.template.lastChangeIntegrationFileDate, $scope.task.closeDate);
            $scope.template.lastChangeIntegrationFileDate = moment($scope.template.lastChangeIntegrationFileDate).format('DD MMMM YYYY HH:mm');
          }
          
          answers = result[1];

          FormsService.filterNonActiveQuestions($scope.template, answers);
          FormsService.sortQuestionsInTemplate($scope.template);
          initQuestions(FormsService.getQuestionsFromTemplate($scope.template), answers);

          $scope.template.groups.forEach(group => {
            group.points.forEach(point => calculateFixpriceResolution(point.id));
          });

          if ($scope.task.status === 'CLOSED') {
          	$scope.checkResult = calculateCheckResult();
          }
        });
      });
    });
  }

  function loadTask(taskId, callback) {
    TaskListServiceHttp.getFiltered({ taskIdParam: taskId }).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка загрузки задачи!');
        callback({ status: result.status, message: 'Task load error' });
        return;
      }

      callback(null, result.data);
    });
  }

  function loadTaskResponses(taskId, callback) {
    TaskListServiceHttp.getTaskAllStatuses(taskId).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка загрузки респонсов!');
        callback({ status: result.status, message: 'Responses load error' });
        return;
      }

      callback(null, result.data);
    });
  }

  function loadTemplate(instanceId, callback) {
    TaskListServiceHttp.getFormTemplateByInstanceId(instanceId).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка загрузки шаблона!');
        callback({ status: result.status, message: 'Template load error' });
        return;
      }

      callback(null, result.data);
    });
  }

  function loadAnswers(formId, callback) {
    TaskListServiceHttp.getFormAnswers(formId).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка загрузки ответов!');
        callback({ status: result.status, message: 'Answers load error' });
        return;
      }

      callback(null, result.data);
    });
  }

  init();

}]);

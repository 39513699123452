(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('navTabs', navTabs);

    function navTabs() {
        var directive = {
            templateUrl: 'scripts/components/nav-tabs/nav-tabs.view.html',
            restrict: 'EA',
            bindToController: true,
            controller: NavTabController,
            controllerAs: 'vm',
            scope: {
                states: '=',
                isLinks: '=?',
                changeState: '&?'
            },
            link: function(scope, el, attr) {
                if (!_.has(attr, 'isLinks')) {
                    scope.vm.isLinks = true;
                }
            }
        };

        return directive;
    }

    function NavTabController() {
        var vm = this;

        vm.changeActive = changeActive;

        vm.activeItem;
        vm.activeIndex;

        init();

        function init() {
            if (!vm.isLinks) {
                vm.activeItem = vm.states[0];
                vm.activeIndex = 0;
            }
        }

        function changeActive(selectedItem, index) {
            vm.activeItem = selectedItem;
            vm.activeIndex = index;
            vm.changeState({state: selectedItem});
        }
    }
})();

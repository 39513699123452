'use strict';

angular.module('smartApp').factory('Dashboard3Service', function Dashboard3Service($filter, $http, config, HttpUtils) {
	 
    var mocks = false
    var ordinateTypes = [
        {val:'shops', name: $filter('wordReplace')('Shops'), data: []},
        {val:'executors', name: $filter('wordReplace')('Executors'), data:[]}
    ]
    var prepareOrdinateParams = function(params, cb){
        var newParams = angular.copy(params)

        if(!params.ordType || !~ordinateTypes.map(el => el.val).indexOf(params.ordType)){
            cb("wrong ordType or no ordIds")
            return;
        }
        if(newParams.ordType == "shops"){
            newParams.shopIds = newParams.ordIds
        }else{
            newParams.executorIds = newParams.ordIds
        }
        delete newParams.ordType;
        delete newParams.ordIds;
        return newParams;
    }

    return {
        getOrdinateTypes: function(){
            return ordinateTypes
        },
        getTasks: function(params, cb) {
            
            $http.get(HttpUtils.getQuery('/taskmanager/tasks', prepareOrdinateParams(params, cb)), {noThrobber: true}).success(function (result, status) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result);
            });
        }, 
    	getLastChecklists: function(cb) {
			if(mocks){
                $http.get('scripts/mocksForTasksCalendar.json').success(function(data, status) {
    		 		cb(null, data.lastTenChecklists)
    		        }).error(function(data) {
    		          cb(data)
    		          console.log('Ошибка чтения данных из файла mocksForTasksCalendar.json')
                });
				return
            }

            $http.get(config.backend + '/taskmanager/last?type=CHECKLIST').success(function (result) {
                if (result.status && result.status != 200 && result.status != 204) {
                    cb(result.status);
                    return;
                }
                cb(null, result);
            });
        }, 
        getLastChecklistTemplates: function(cb) {
            $http.get(config.backend + '/taskmanager/last?type=TEMPLATE').success(function (result) {
                if (result.status && result.status != 200 && result.status != 204) {
                    cb(result.status);
                    return;
                }
                cb(null, result);
            });
        }, 
        getLastTasks: function(cb) {
            if(mocks){
    			$http.get('scripts/mocksForTasksCalendar.json').success(function(data, status) {
    		 		cb(null, data.lastTenTasks)
    		        }).error(function(data) {
    		          cb(data)
    		          console.log('Ошибка чтения данных из файла mocksForTasksCalendar.json')
    		    });
    			return
            }

            $http.get(config.backend + '/taskmanager/last?type=TASK').success(function (result) {
                if (result.status && result.status != 200 && result.status != 204) {
                    cb(result.status);
                    return;
                }
                cb(null, result);
            });
        }, 
        getLastTaskTemplates: function(cb) {
			if(mocks){
                $http.get('scripts/mocksForTasksCalendar.json').success(function(data, status) {
    		 		cb(null, data.lastTenTaskTemplates)
    		        }).error(function(data) {
    		          cb(data)
    		          console.log('Ошибка чтения данных из файла mocksForTasksCalendar.json')
    		    });
				return
            }

            $http.get(config.backend + '/taskmanager/last?type=TASK_TEMPLATE').success(function (result, status) {
                console.log(result, status)
                if (result.status && result.status != 200 && result.status != 204) {
                    cb(result.status);
                    return;
                }
                cb(null, result);
            });
        }, 
		getShops: function(params, cb) {
            var body = {
                name: params.search, 
                page: params.page,
                perPage: params.perPage,
                clusterIds: params.clusterIds,
                divisionIds: params.divisionIds
            };

            $http.post(config.backend + '/orgstruct/shops/dependent', body, { noThrobber: true }).success(function (result, status, headers) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result.map(el=>{
                        el.name = el.locality
                        return el;
                      }), headers);
            });
        }, 
        getExecutors: function(params, cb) {
            var obj = {
                noThrobber: true,
                name: params.search, 
                page: params.page,
                perPage: params.perPage,
                clusterIds: params.clusterIds,
                divisionIds: params.divisionIds
            }

            $http.get(HttpUtils.getQuery('/orgstruct/users/dependent', obj)).success(function (result, status, headers) {
                if (status && status != 200 && status != 204) {
                    cb(status, result);
                    return;
                }
                cb(null, result.map(el=>{
                        el.name = ((el.firstName)?el.firstName:"")+ ' ' + ((el.lastName)?el.lastName:"")
                        return el;
                      }), headers);
            });
        },

        // getExecutorsForShop: function(shopId, cb) {
        //     $http.get(config.backend + '/orgstruct/users/dependent', {noThrobber: true}).success(function (result) {
        //         if (result.status && result.status != 200 && result.status != 204) {
        //             cb(result.status);
        //             return;
        //         }
        //         cb(null, result.map(el=>{
        //                 el.name = el.firstName+ ' ' + el.lastName
        //                 return el;
        //               }));
        //     });
        // },

        //  getShopsForExecutor: function(executorId, cb) {
        //     $http.get(config.backend + '/orgstruct/shops/dependent').success(function (result) {
        //         if (result.status && result.status != 200 && result.status != 204) {
        //             cb(result.status);
        //             return;
        //         }
        //         cb(null, result);
        //     });
        // },

        update: function(template){
            return $http.post(config.backend + '/counters/updateCounter', template);
        }, 
        switchTemplate: function(template, cb) {
            $http.post(config.backend + '/api/manager/templates/'+ template.id +'/toggle', {noThrobber: true}).success(
                function (result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },

        getDivisionsList: function(cb){
           $http.get(config.backend + '/orgstruct/divisions').success(
                function (result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },

        getClustersList: function(cb){
            $http.get(config.backend + '/orgstruct/regions').success(
                function (result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },
        createChecklist: function(checklist, cb) {
            // var 
            // ChecklistEditor.createChecklists([checklist]).then(function(result,a,headers){
            //     console.log(result,a,headers)
            //     cb("created")
            //     return;
            //     if(result.status == 200) {
            //         $rootScope.$broadcast("saveSuccess");
            //         //refresh()
            //         afterChecklistCreate(result.data, shop)
            //     }else if(result.data && result.data.message){
            //         Notifications.danger(result.data.message, '', 3000);
            //     }else if(result.status == 400){
            //         Notifications.danger('Планирование проверок на прошедшую дату запрещено');
            //     }else{
            //         Notifications.danger('Изменения утеряны');
            //     }

            // });
cb("NOT IMPLEMENTED", "We need to create this method")
            // $http.post(config.backend + ' /api/manager/templates/'+ template.id +'/toggle', {noThrobber: true}).success(
            //     function (result) {
            //         if (result.status && result.status != 200) {
            //             cb(result.status);
            //         }
            //         cb(null, result.id);
            //     }
            // );
        },
    }

});

/**
 * Created by tyurin on 27.05.15.
 */

'use strict';

angular.module('smartApp')
	.directive('equalValidation', function() {
		var VALIDATION_NAME = 'equalValidation';
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				compareTo: '=equalValidation'
			},
			link: function(scope, elem, attrs, ctrl){
				ctrl.$parsers.unshift(function(viewValue) {
					return validate(viewValue);
				});

				scope.$watch("compareTo", function() {
					validate(ctrl.$viewValue);
				});

				function validate(value){
					ctrl.$setValidity(VALIDATION_NAME, value == scope.compareTo);
					return value;
				}

			}
		};
	})
	.directive('limitNumberInput', function() {
		var VALIDATION_NAME = 'limitNumberInput';
		return {
			require: 'ngModel',
			scope: {
				maxValue: '=',
				minValue: '='
			},
			link: function(scope, elem, attrs, ctrl) {
				ctrl.$parsers.unshift(function(viewValue) {
					return validate(viewValue);
				});

				scope.$watch("maxValue", function() {
					validate(ctrl.$viewValue);
				});

				function validate(value){
					ctrl.$setValidity(VALIDATION_NAME, value <= scope.maxValue && value >= scope.minValue);
					return value;
				}
			}
		}
	})
    .directive("limitTo", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                var limit = parseInt(attrs.limitTo);
                angular.element(elem).on("keypress", function(e) {
                    if (this.value.length == limit) e.preventDefault();
                });
            }
        }
    })
    .directive("numberRequired", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                var backupValue = 0;
                angular.element(elem).on("keyup", function(e) {
                    var itemValue = +this.value;
                    if (Math.floor(itemValue) !== itemValue){
                        this.value = backupValue;
                    }else{
                        backupValue = this.value
                    }
                });

                // angular.element(elem).on("blur", function(e) {
                //     (!this.value) && (this.value = 0)
                // });
            }
        }
    })



<script setup>
import { computed } from 'vue';
import { SingleSelect } from '@eron/ui-kit';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Boolean,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const options = [
    {
        label: 'Стандартная',
        value: 'false',
    },
    {
        label: 'Срочная',
        value: 'true',
    },
];
const selected = computed({
    get() {
        return typeof props.modelValue === 'boolean' ? String(props.modelValue) : undefined;
    },
    set(value) {
        emit('update:model-value', value === 'true');
    },
});
</script>

<template>
    <single-select :id="id" v-model="selected" :options="options" :clearable="false" />
</template>

import { db } from '@shared/data';

export const getAccessibleOrgstruct = async () => {
    const account = window.fprAccount;
    const user = await db.users.where('id').equals(account.id).first();

    let promise;

    switch (user.level) {
        case 'COMPANY':
            promise = Promise.all([db.shops.toArray(), db.regions.toArray(), db.divisions.toArray()]);
            break;
        case 'DIVISION':
            promise = Promise.all([
                db.divisions.where('id').anyOf(user.divisions).toArray(),
                db.regions.where('divisionId').anyOf(user.divisions).toArray(),
            ]).then(async ([divisions, regions]) => {
                const shops = await db.shops
                    .where('clusterId')
                    .anyOf(regions.map(r => r.id))
                    .toArray();
                return [shops, regions, divisions];
            });
            break;
        case 'REGION':
            promise = Promise.all([
                db.shops.where('clusterId').anyOf(user.regions).toArray(),
                db.regions.where('id').anyOf(user.regions).toArray(),
            ]).then(async ([shops, regions]) => {
                const divisions = await db.divisions
                    .where('id')
                    .anyOf(regions.map(r => r.divisionId))
                    .toArray();
                return [shops, regions, divisions];
            });
            break;
        case 'SHOP':
            promise = db.shops
                .where('id')
                .anyOf(user.shops)
                .toArray()
                .then(async shops => {
                    const regions = await db.regions
                        .where('id')
                        .anyOf(shops.map(s => s.clusterId))
                        .toArray();
                    const divisions = await db.divisions
                        .where('id')
                        .anyOf(regions.map(r => r.divisionId))
                        .toArray();

                    return [shops, regions, divisions];
                });
            break;
    }

    return promise;
};

angular.module('smartApp').controller('ModelCountersController', function($scope, Principal, ShopService, CounterService, FormFactory, ngDialog, Notifications, ObjectServiceHierarchy) {
    var _this = $scope;
    $scope.progress = false;
    $scope.all_divisions = [];
    $scope.all_clusters = [];
    $scope.all_shops = [];
    $scope.shop = {selected: null};

    // Load functions
        function loadData() {
            $scope.progress = true;
            $scope.all_divisions = [];
            $scope.all_clusters = [];
            $scope.all_shops = [];

            ObjectServiceHierarchy.query({}, function(result) {
                angular.forEach(result, function(i) {
                    $scope.all_divisions.push(i);
                    angular.forEach(i.clusters, function(_i) {
                        $scope.all_clusters.push(_i);
                        angular.forEach(_i.shops, function(__i) {
                            $scope.all_shops.push(__i);
                        });
                    });
                });
                $scope.progress = false;
            });
        };
    // Init
        var arr = ['Выбрать все', 'Удалить все'];
        var allDivisionsSelected = false;
        var allClustersSelected = false;
        var allShopsSelected = false;

        function init() {
            $scope.all_divisions = [];
            $scope.all_clusters = [];
            $scope.all_shops = [];

            allDivisionsSelected = false;
            allClustersSelected = false;
            allShopsSelected = false;

            $scope.select_all_divisions = arr[0];
            $scope.select_all_clusters = arr[0];
            $scope.select_all_shops = arr[0];

            loadData();
        }
        $scope.refresh = function() {init();}
        init();
    // Set select to this scope
        $scope.setSelectForDivisions = function($select) {
            $select.onRemoveCallback = removeUnmatchedDivisions;
            $scope.select_for_divisions = $select
        };
        $scope.setSelectedClusters = function($select) {
            $select.onRemoveCallback = removeUnmatchedClusters;
            $scope.select_for_clusters = $select
        };
        $scope.setSelectedShops = function($select) {
            $scope.select_for_shops = $select
        };
    // Removing unmatched items
        function removeUnmatchedDivisions(_scope, element) {
            allDivisionsSelected = false;
            $scope.select_all_divisions = arr[0];

            // var questions_to_delete = element.$item.questions
            // for (var i = 0; i < questions_to_delete.length; i++) {
            //     var index = _.indexOf($scope.select_for_questions.selected, questions_to_delete[i])
            //     if (index) {$scope.select_for_questions.selected.splice(index, 1)}
            // };
        }
        function removeUnmatchedClusters(_scope, element) {
            allClustersSelected = false;
            $scope.select_all_clusters = arr[0];
            if ($scope.select_for_clusters.selected.length === 0) {
                $scope.selectedShop = null;
                $scope.shop.selected = null;
            }

            // var questions_to_delete = element.$item.questions
            // for (var i = 0; i < questions_to_delete.length; i++) {
            //     var index = _.indexOf($scope.select_for_questions.selected, questions_to_delete[i])
            //     if (index) {$scope.select_for_questions.selected.splice(index, 1)}
            // };
        }
        function removeQuestion(_scope, element) {
            allShopsSelected = false;
            $scope.select_all_shops = arr[0];
        }
    // Select all
        $scope.selectAllDivisions = function() {
            allDivisionsSelected = !allDivisionsSelected;
            if (allDivisionsSelected) {
                $scope.select_all_divisions = arr[1];
                $scope.select_for_divisions.selected = $scope.all_divisions;
            } else {
                if ($scope.select_for_clusters) {$scope.select_for_clusters.selected = []}
                if ($scope.select_for_shops) {$scope.select_for_shops.selected = []}
                $scope.select_all_divisions = arr[0];
                $scope.select_for_divisions.selected = [];
            }
        }
        $scope.selectAllClusters = function() {
            allClustersSelected = !allClustersSelected;
            if (allClustersSelected) {
                $scope.select_all_clusters = arr[1];
                $scope.select_for_clusters.selected = $scope.all_clusters;
            } else {
                $scope.shop.selected = null;
                $scope.selectedShop = null;
                if ($scope.select_for_shops) {$scope.select_for_shops.selected = []}
                $scope.select_all_clusters = arr[0];
                $scope.select_for_clusters.selected = [];
            }
        };
    // Select Item
        $scope.selectedShop = null;
        $scope.selectDivision = function(division) {
        };
        $scope.selectCluster = function(clusters) {
            $scope.select_for_clusters.selected = clusters
        };

    $scope.matchClustersToDivisions = function() {
        var _clusters = [];
        // for (var i = 0; i < $scope.select_for_divisions.selected.length; i++) {
        //     for (var a = 0; a < $scope.select_for_divisions.selected[i].questions.length; a++) {
        //         _questions.push($scope.select_for_processes.selected[i].questions[a])
        //     };
        // };
        // $scope.questions.splice(0, $scope.questions.length)
        // for (var i = 0; i < _questions.length; i++) {
        //     $scope.questions.push(_questions[i])
        // };
    }
    $scope.matchShopsToClusters = function() {
        var _shops = [];
        for (var i = 0; i < $scope.select_for_clusters.selected.length; i++) {
            for (var a = 0; a < $scope.select_for_clusters.selected[i].shops.length; a++) {
                _shops.push($scope.select_for_clusters.selected[i].shops[a]);
            }
        }
        $scope.all_shops.splice(0, $scope.all_shops.length);
        for (var i = 0; i < _shops.length; i++) {
            $scope.all_shops.push(_shops[i]);
        }
    }



    // Counters
        $scope.initCountersForShop = function(shopSap) {
            $scope.selectedShop = shopSap
            $scope.progress = true;
            CounterService.getAllByShopSap(shopSap.sap).success(function(data) {
                $scope.counters = data;
                $scope.calculateLimit();
                $scope.progress = false;
            });
        };
        $scope.calculateLimit = function() {
            $scope.limitCounters = 0;
            angular.forEach($scope.counters, function(c, cKey) {
                if(c.active) {
                    $scope.limitCounters++;
                }
            })
        };
        $scope.deActivateCounter = function(counter) {
            counter.active = !counter.active;
            $scope.progress = true;
            CounterService.updateCounter(counter).success(function(data) {
                Notifications.success('Счетчик успешно ' + (data.active ? 'активирован' : 'деактивирован'));
                counter = data;
                $scope.calculateLimit();
                $scope.progress = false;
            });
        };
        $scope.removeCounter = function(counter){
            ngDialog.openConfirm({
                template: 'scripts/app/common/confirm-dialog.template.html',
                scope: $scope,
                data: {title: 'Вы действительно хотите удалить счетчик?'}
            }).then(function() {
                CounterService.deleteCounter(counter.id).success(function (data, status) {
                    if (status === 200) {
                        removeFromCountersList(counter);
                        Notifications.success('Счетчик успешно удален');
                    }
                })
            });
        };
        $scope.bindCounter = function() {
            ngDialog.open(
                {
                    template: 'bindCounter',
                    controller: ['$rootScope', '$scope', 'CounterService', function($rootScope, $scope, CounterService) {
                        $scope.progress = false;
	                    $scope.nowYear = (new Date().getFullYear());
                        $scope.initModels = function() {
                            $scope.progress = true;
                            CounterService.getAllModels().success(function(data) {
                                $scope.models = _.sortBy(data, ['model']);
                                $scope.progress = false;
                            });
                        };

                        $scope.selectModel = function(model) {
                            $scope.selectedModel = model;
                        };

                        $scope.bindCounter = function() {
                            $scope.progress = true;
                            CounterService.createNewCounter(
	                            _this.selectedShop.sap,
	                            $scope.serialNumber,
	                            $scope.selectedModel.id,
	                            $scope.ktt,
	                            $scope.counterYear,
	                            $scope.counterCalInterval,
	                            $scope.transYear,
	                            $scope.transCalInterval
                            ).success(function(data) {
                                    $scope.progress = false;
                                    Notifications.success('Связка ' + _this.selectedShop.locality + ' - ' + $scope.selectedModel.model + ' успешно создана');
                                    _this.initCountersForShop(_this.selectedShop);
                                }
                            );
                        }

                    }],
                    className: 'ngdialog-theme-plain',
                    showClose: false
                }
            );
        };
        function removeFromCountersList(counter){
            var index = -1;
            _.forEach($scope.counters, function(e, i){e.id === counter.id && (index = i)});
            if(index >= 0){
                $scope.counters.splice(index, 1);
            }
        }
});

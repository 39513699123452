(function() {
    'use strict';
    /**
     * @module smartApp
     *
     * @description
     * top-scroll directive
     */
    angular.module('smartApp').directive('topScroll', topScroll);

    topScroll.$inject = ['$timeout'];

    /**
     * @name topScroll
     * @module smartApp
     *
     * @description
     * The top-scroll directive uses for adding top scroll
     *
     * @usage
     * <div class="table-container" data-top-scroll="">
     *  <table>
     *      <tr>
     *          <td>
     *          </td>
     *      </tr>
     *  </table>
     * </div>
     */
    function topScroll() {
        var directive = {
            link: link,
            restrict: 'EA',
            scope: {}
        };
        return directive;

        function link(scope, element) {
            var el = null;
            var secondScroll = null;
            var tableWidth = 0;
            if (element) {
                el = element;
                angular.element(document).ready(function() {
                    tableWidth = el.find('table').width();
                    el.before(
                        '<div class="top-scroll"><div class="top-scroll__content"></div></div>'
                    );
                    secondScroll = angular.element('.top-scroll');
                    angular.element('.top-scroll').css({
                        width: '100%',
                        height: '20px',
                        overflow: 'auto'
                    });
                    angular.element('.top-scroll__content').css({
                        width: tableWidth + 'px',
                        height: '20px'
                    });
                    secondScroll.bind('scroll', function() {
                        el[0].scrollLeft = secondScroll[0].scrollLeft;
                    });
                    el.bind('scroll', function() {
                        secondScroll[0].scrollLeft = el[0].scrollLeft;
                    });
                });
            }
        }
    }
})();

var directives = angular.module('smartApp');

directives.directive('image', function (config, $timeout, Notifications, localStorageService) {
	var uid = 1;
	function getUid () {
		return uid++;
	}

	return {
		scope: {
			ngModel: '=',
			imageField: '=ngImageField'
		},
		require: 'ngModel',
		link: function($scope, element, attrs) {

			var DISABLED_CLASS = 'disabled';

			function init () {
				element.find('form,iframe').remove();
				$scope.id = getUid();
				$scope.file = null;
				var formTpl = '<form method="POST" action="' + config.backend + config.photoCreate + '" enctype="multipart/form-data" target="upload-' + $scope.id + '" style="position: absolute;top: 0;right: 0; left: 0; bottom: 0; opacity: 0">' +
					'<input name="token" type="hidden" value="' + localStorageService.get('token').token + '" />'+
					'<input name="upload" type="file" ng-model="file" style="width: 100%; height: 100%" accept="image/jpeg,image/png,image/gif"/>' +
					'</form>' +
					'<iframe style="display: none" name="upload-' + $scope.id +'"></iframe>';
				element.append(formTpl);

				element.find('[type="file"]').on('change', function (e) {
					$scope.file = e.currentTarget.files[0];
					if($scope.file.size > config.maxPhotoSize) {
						Notifications.danger('Ошибка', 'Файл слишком большой');
						$(e.currentTarget).val('');
						element.removeClass(DISABLED_CLASS);
						return;
					}
					element.addClass(DISABLED_CLASS);
					if ($scope.file.name) element.find('form').submit();
				});

				element.find('iframe').on('load', function () {
					element.removeClass(DISABLED_CLASS);
					var file = element.find('iframe').contents().find('pre');
					var text = file.text();
					if(!text.trim()) return;
					$scope.file = JSON.parse(text);
					$scope.file.src = config.pathToPhoto + $scope.file.preview;
					element.find('form').hide();

					$timeout(function () {
						$scope.ngModel[attrs.ngImageField] = {
							name: $scope.file.name,
							preview: $scope.file.preview,
							src: $scope.file.src
						};
						init();
					}, 0);
				});

				element.off('click');
				element.on('click', function () {
					if(navigator.userAgent.indexOf("Firefox") != -1  || ((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true ))) {
						element.find('[type="file"]').click();
					}
				});

				element.find('form').on('click', function (e) {
					e.stopPropagation();
				});
			}

			init();
		}
	}
});
(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('tasks', tasks);

    tasks.$inject = [];



    function tasks() {
        var directive = {
            templateUrl: 'scripts/app/tasks/tasks.view.html',
            bindToController: true,
            controller: TasksController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                userPermissions: '<',
                systemParams: '<',
                type: '@?'
            }
        };
        return directive;
    }

    TasksController.$inject = [
        '$rootScope',
        '$state',
        '$location',
        'Notifications',
        'TaskListServiceHttp',
        'TasksService',
        'localStorageService',
        'Principal',
        '$timeout'
    ];

    function TasksController(
        $rootScope,
        $state,
        $location,
        Notifications,
        TaskListServiceHttp,
        TasksService,
        localStorageService,
        Principal,
        $timeout
    ) {
        var vm = this;

        vm.taskTypes = [
            {
                label: 'Общие',
                value: 'COMMON',
                isSelected: false,
            },
            {
                label: 'Фотоотчеты',
                value: 'PHOTO_REPORT',
                isSelected: false,
            },
            {
                label: 'Чек-листы',
                value: 'ROUTED',
                isSelected: false,
            },
            {
                label: 'Проверка наличия',
                value: 'FORMS',
                isSelected: false,
            },
            {
                label: 'Плановый пересчёт',
                value: 'AUTO_RECOUNT',
                isSelected: false,
            },
            {
                label: 'Выборочный пересчёт',
                value: 'MANUAL_RECOUNT',
                isSelected: false,
            },
            {
                label: 'Печать ценников',
                value: 'PRINT_PRICE_TAGS',
                isSelected: false,
            },
            {
                label: 'Сбор данных',
                value: 'EQUIPMENT',
                isSelected: false,
            },
            {
                label: 'Непродающиеся товары',
                value: 'UNSOLD_GOODS',
                isSelected: false,
            },
            {
                label: 'Показания счётчиков',
                value: 'METER',
                isSelected: false,
            },
            {
                label: 'Формирование заказа на доставку',
                value: 'COLLECTION_DELIVERY',
                isSelected: false,
            },
            {
                label: 'Формирование заказа на самовывоз',
                value: 'COLLECTION_PICKUP',
                isSelected: false,
            },
            {
                label: 'Согласование заказа на доставку',
                value: 'APPROVAL_DELIVERY',
                isSelected: false,
            },
            {
                label: 'Согласование заказа на самовывоз',
                value: 'APPROVAL_PICKUP',
                isSelected: false,
            },
            {
                label: 'Расформирование заказа',
                value: 'DISBANDMENT',
                isSelected: false,
            },
            {
                label: 'Внесение сроков годности',
                value: 'EXPIRATION_DATE_ADD'
            },
            {
                label: 'Проверка сроков годности',
                value: 'EXPIRATION_DATE_CHECK'
            },
            {
                label: 'Видео-аналитика',
                value: 'VIDEO_MONITORING'
            },
            {
                label: 'Подготовка к инвентаризации',
                value: 'INVENTORY_PREPARATION'
            }
        ];

        vm.identity = Principal.getIdentity();


        vm.step = 10;

        vm.displayedPages = 5;

        vm.page = 0;

        vm.isFilterWindowDislayed = false;

        vm.tasksFilter = {
            type: [],
            role: ['executor', 'author'].join(','),
            taskStatus: ['OPEN', 'IN_PROGRESS'].join(',')
        };

        const types = vm.type ? vm.type.split(',').map(type => type.trim()) : [];
        const filters = vm.taskTypes.filter(taskType => types.includes(taskType.value));
        vm.tasksFilter.type = filters.length ? filters : [];
        vm.taskTypesSearch = '';

        vm.isFilteredByExtFilters = false;
        vm.extendedFilters = {};

        vm.filterByExtendSettings = filterByExtendSettings;

        vm.hasPermissionsToCreate = false;

        vm.createNewTask = createNewTask;

        vm.getTasks = getTasks;

        vm.tasks;
        vm.loading = false;
        vm.pagesCount;

        vm.settings = {
            params: null,
            permissions: null
        };

        vm.createTaskModalVisible = false;
        vm.createTaskModalProps = {
            onClose() {
                $timeout(() => {
                    vm.createTaskModalVisible = false;
                });
            }
        };

        let fetchedAllTasks = false;
        let page = 1;

        vm.getMoreTasks = function() {
            if (fetchedAllTasks) return;
            page += 1;
            getTasks();
        };

        vm.onTaskTypesFilterChange = function() {
            vm.tasksFilter.type = vm.taskTypes.filter(taskType => taskType.isSelected);
            getTasks(true);
        }

        vm.deselectAllTaskTypes = function() {
            vm.taskTypes.forEach(taskType => (taskType.isSelected = false));
            vm.onTaskTypesFilterChange();
        }

        var userPermissions = {};

        init();

        function init() {
            var queries = $location.search();
            if (!_.isEmpty(queries)) {
                _.assign(vm.tasksFilter, queries);
            }

            vm.settings.params = vm.systemParams;
            vm.settings.permissions = vm.userPermissions;

            TaskListServiceHttp.getUserPermissions().then(function(permissions) {
              userPermissions = permissions;
              vm.hasPermissionsToCreate = checkHasPermissionsToCreate();
            });

            var savedFilters = localStorageService.get('filters');

            if (savedFilters && checkCanApplyCachedFilters(savedFilters)) {
                applyCachedFilters(savedFilters);
            } else {
                localStorageService.remove('filters');
            }
        }

        function checkCanApplyCachedFilters(localFilters) {
            var prevUrl = $rootScope.history.slice(-1)[0].state.url;
            return prevUrl.includes('/fullView') || prevUrl.includes('/photoReportCheck') || prevUrl.includes('/formFullView');
        }

        function applyCachedFilters(localFilters) {
          // Заполняет поля фильтров значениями из локал стоража
            for (var key in localFilters.extended) {
                if (key.includes('Date') && localFilters.extended[key]) {
                vm.extendedFilters[key] = moment(localFilters.extended[key]).toDate();
                } else {
                    vm.extendedFilters[key] = localFilters.extended[key];
                }
            }

            for (var key in localFilters.default) {
                if (key === 'type') {
                    const selectedTypes = localFilters.default.type ? localFilters.default.type.split(',') : [];
                    vm.tasksFilter.type = vm.taskTypes.filter(item => {
                        item.isSelected = selectedTypes.includes(item.value);
                        return item.isSelected;
                    })
                } else {
                    vm.tasksFilter[key] = localFilters.default[key];
                }
            }
        }

        function normalizeExtendedFilters(extFilters) {
            var normalized = {};

            for (var key in extFilters) {
                if (extFilters.hasOwnProperty(key)) {
                    switch(key) {
                        case 'divisions':
                            normalized.divisionIds = extFilters[key] && extFilters[key].length ? extFilters[key].map(item => item.id) : undefined;
                            break;
                        case 'regions':
                            normalized.regionIds = extFilters[key] && extFilters[key].length ? extFilters[key].map(item => item.id) : undefined;
                            break;
                        default:
                            normalized[key] = angular.copy(extFilters[key]);
                            break;
                    }
                }
            }

            // разворачивает фильтры, если в кэше что-то есть
            if (Object.keys(normalized).length) {
                vm.showExtended = true;
            }

            return normalized;
        }

        /**
         * @desc get tasks by params filter
         */
        function getTasks(setPageToFirst) {
            page = setPageToFirst ? 1 : page;

            vm.loading = true;
            fetchedAllTasks = false;

            const typeFilter = {
                type: !vm.tasksFilter.type.length || vm.tasksFilter.type.length === vm.taskTypes.length ? undefined : vm.tasksFilter.type.map(type => type.value).join(','),
            };

            localStorageService.set('filters', { extended: vm.extendedFilters, default: Object.assign({}, vm.tasksFilter, typeFilter) });

            TaskListServiceHttp
                .getFiltered(Object.assign({
                    page,
                    perPage: 20,
                }, vm.tasksFilter, normalizeExtendedFilters(vm.extendedFilters), typeFilter))
                .then(function(resp) {
                    vm.loading = false;

                    if(resp.status !== 200){
                        Notifications.danger('Ошибка!', resp.headers('x-error') +"<br />"+ resp.headers('x-error-text'));
                        console.log(resp.status, resp.headers('x-error-text'));
                        return;
                    }

                    vm.tasks = page === 1 ? [] : vm.tasks;

                    if (resp.data && resp.data.length) {
                        var fetchedTasks = resp.data.map((el) => {
                            el.task = !el.response;

                            if(el.response){ // для универсализации выдачи респонсов. Данные отображаемого респонса в объекте responseData
                                el.responseData = el.responseList[0]
                            }

                            el.isTaskCreatedByMyself = vm.identity.id === el.authorId;

                            return el;
                        });
                        vm.tasks = vm.tasks.concat(fetchedTasks);
                    } else {
                        fetchedAllTasks = true;
                    }
                });
        }

        function filterByExtendSettings() {
          vm.isFilteredByExtFilters = Object.keys(vm.extendedFilters).length !== 0;

          getTasks(true);
        }

        function createNewTask() {
            $state.go('tasks.new');
        }

        function checkHasPermissionsToCreate() {
          var hasPermissions = false;

          if (Principal.isInRole('ROLE_INSPECTOR')) return hasPermissions;

          var targetPermissions = [
            'createTaskForDivision',
            'createTaskForRegion',
            'createTaskForShop',
            'createTaskForSupervisor',
            'createTaskForTaskManager'
          ];
          var permissionKeys = Object.keys(userPermissions);
          var permissionKey = null;

          for (let i = 0; i < permissionKeys.length; i++) {
            permissionKey = permissionKeys[i];

            if (targetPermissions.indexOf(permissionKey) !== -1 && userPermissions[permissionKey]) {
              hasPermissions = true;
              break;
            }
          }

          return hasPermissions;
        }
    }
})();

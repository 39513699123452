angular.module('smartApp').factory('Xfilter5', checkXreportFactory5);
checkXreportFactory5.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory5(ChecklistService, DownloadService) {
	return {
		id: 10,
		title: 'Задания',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter5/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter5/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo && filters.divisionIds && filters.divisionIds.length > 0 && filters.templates && filters.templates.length > 0;
		},
		load: function(filters) {
 			return ChecklistService.getXreport5(filters);
		},
		download: function(filters) {
			return DownloadService.downloadXreport5(filters);
		}
	};

}

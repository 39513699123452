(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('ParamsDataService', ParamsDataService);

    ParamsDataService.$inject = [];

    function ParamsDataService() {
        var service = {
            transformData: transformData
        };

        var inputTypes = {
            Boolean: 'checkbox',
            String: 'text',
            Integer: 'number',
            'List<Integer>': 'chips',
            'Set<String>': 'chips'
        };

        var transforms = {
            'List<Integer>': transformToList,
            'Set<String>': transformToList,
            'chips': transformToList,
            Boolean: transformToBoolean,
            Integer: transformToInt,
            String: transformToString
        };

        return service;

        /**
         * @desc changes response property object for view
         * @param {object} data
         */
        function transformData(data, tabSettings, templates) {

            var settings = {
                possibleValues: getPossibleValues(data.possibleValues),
                propertyKey: data.propertyKey,
                type: getInputType(data)
            };

            
            

            if (_.has(tabSettings, data.propertyKey)) {
                _.extend(settings, tabSettings[data.propertyKey]);
            }

            if (settings.type === 'chips') {
                settings.value = transforms['chips'](data.value);
            } else {
                settings.value = transforms[data.type](data.value);
            }
             if(data.possibleValues == null && settings.type === "multi-select"){
                settings.type = 'text'
            }

//             if (_.has(settings, 'isChecklistValues')) {
//                 settings.possibleValues = templates;
//             }
 console.log(settings)
            return settings;
        }

        /**
         * @desc changes string value to array
         * @param {string} val
         * @returns {array}
         */
        function transformToList(val) {
            return val && val !== '-' ? val.split(',') : [];
        }

        /**
         * @desc changes string value to boolean
         * @param {string} val
         * @returns {boolean}
         */
        function transformToBoolean(val) {
            return val === 'true';
        }

        /**
         * @desc changes string value to integer
         * @param {string} val
         * @returns {integer}
         */
        function transformToInt(val) {
            return +val;
        }

        function transformToString(val) {
            return val;
        }

        function getPossibleValues(value) {
            var values = null;
            if (value) {
                values = checkJSON(value) || value.split(',');
            }
            return values;
        }

        function getInputType(data) {
            var type = inputTypes[data.type];
            if (data.possibleValues) {
                type = 'select';
            }
            return type;
        }

        function checkJSON(val) {
            var output;
            var obj;
            try {
                obj = JSON.parse(val);
                output = [];
                _.mapKeys(obj, function(value, key) {
                    output.push({
                        id: key,
                        val: value
                    });
                });
            } catch (e) {
                output = false;
            }
            return output;

        }
    }
})();
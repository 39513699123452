(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('AssignTemplateService', AssignTemplateService);

    AssignTemplateService.$inject = ['$http', 'config'];

    function AssignTemplateService($http, config) {
        var service = {
            get: get
        };

        var url = config.backend + '/assignTemplate/templates/';

        return service;

        function get(id, hasNoThrobber) {

            return $http.get(url + id, {
                noThrobber: hasNoThrobber
            }).then(function(resp) {
                if (resp.status === 200) {
                    return resp.data;
                }
            });
        }
    }
})();
function vueStylesInjector(element) {
  window.vueStyles = window.vueStyles || { list: [], root: null };
  window.vueStyles.list.push(element);

  if (window.vueStyles.root) {
    window.vueStyles.root.appendChild(element);
  }

  window.vueStyles.inject = function(root) {
    window.vueStyles.root = root;
    window.vueStyles.list.forEach(el => {
      el.innerHTML = el.innerHTML.replace(/:root/g, ':host');
      root.appendChild(el);
    });
  };
}

module.exports = vueStylesInjector;
'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('addCheck', {
                parent: 'checklist',
                url: '/addCheck',
                data: {
                    roles: ['ROLE_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/checklist/addCheck/addCheck.html',
                        controller: 'AddCheckController'
                    }
                }
            });
    });
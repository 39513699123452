(function() {
    'use strict';

    angular.module('smartApp').directive('paramsTab', paramsTab);

    paramsTab.$inject = [];

    function paramsTab() {
        var directive = {
            templateUrl: 'scripts/app/settings/params/params-tab/params-tab.view.html',
            bindToController: true,
            controller: ParamsTabController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                properties: '=',
                allProperties: '=',
                settings: '=',
                templates: '='
            }
        };
        return directive;
    }

    ParamsTabController.$inject = [
        'ParamsDataService',
        'ParamsTabService',
        'Notifications',
        'SystemParamsService'
    ];

    function ParamsTabController(
        ParamsDataService,
        ParamsTabService,
        Notifications,
        SystemParamsService
    ) {
        var vm = this;

        vm.titles = [];

        vm.propertiesForSaving = [];

        vm.save = save;

        vm.changeVal = changeVal;

        vm.setClass = ParamsTabService.setClass;

        vm.checkValRelationProp = checkValRelationProp;

        vm.transformedProperties = [];

        vm.initialProperties;

        init();

        /**
         * @desc data initialization
         */
        function init() {
            vm.properties.forEach(function(property) {
                var transformedProperty = ParamsDataService.transformData(property, vm.settings, vm.templates);
                vm.transformedProperties.push(transformedProperty);
            });
            vm.initialProperties = angular.copy(vm.transformedProperties);
            _.forEach(vm.transformedProperties, function(property) {
                if (vm.titles.indexOf(property.title) === -1) {
                    vm.titles.push(property.title);
                }
            });
        }

        /**
         * @desc save changing properties values
         */
        function save() {
            if (vm.propertiesForSaving.length) {
                vm.propertiesForSaving.map(function(property) {
                    var updatingProperty = {
                        key: property.propertyKey,
                        val: property.value
                    };
                    SystemParamsService.set(updatingProperty).then(function(resp) {
                        console.log(resp);
                        ParamsTabService.getResponseMessage(resp, property);
                        ParamsTabService.updateProperty(resp, property, vm.allProperties);
                    });
                });
                vm.propertiesForSaving.splice(0);
                vm.initialProperties = angular.copy(vm.transformedProperties);
            } else {
                Notifications.warning('Нечего сохранять');
            }
        }

        /**
         * @desc calls when property value is changing
         * @param {object} property
         */
        function changeVal(property) {
            ParamsTabService.setPropertiesForSaving(
                property,
                vm.propertiesForSaving
            );
        }

        function checkValRelationProp(relationPropKey) {
            var relationProp = false;
            if (relationPropKey) {
                relationProp = _.find(vm.transformedProperties, { propertyKey: relationPropKey }).value;
            }
            return relationProp;
        }
    }
})();
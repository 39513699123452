'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('counters.bind', {
                parent: 'counters',
                url: '/counters/bind',
                data: {
                    roles: ['ROLE_ENERGETIC']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/counters/modelcounters/modelcounters.html',
                        controller: 'ModelCountersController'
                    }
                }
            })
            .state('counters.list', {
                parent: 'counters',
                url: '/counters/list',
                data: {
                    roles: ['ROLE_ENERGETIC']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/counters/modelcounters/list/list.html'
                    }
                }
            })
            .state('counters.modify', {
                abstract: true,
                parent: 'counters',
                data: {
                    roles: ['ROLE_ENERGETIC']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/counters/modelcounters/edit/new-model.form.html'
                    }
                }
            })
            .state('counters.new', {
                parent: 'counters.modify',
                url: '/counters/new'
            })
            .state('counters.edit', {
                parent: 'counters.modify',
                url: '/counters/edit/:id'
            });
    });
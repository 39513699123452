angular.module('smartApp').directive('vueSidePanel', function() {
  return {
    restrict: 'E',
    scope: {
      componentName: '@',
      visible: '<',
      onVisibleChange: '&',
    },
    template: `<vue-component ng-if="innerVisible" component-name="{{componentName}}" side-panel="true" on-side-panel-close="onSidePanelClose()"></vue-component>`,
    link: function(scope, element) {
      scope.innerVisible = scope.visible;

      scope.onSidePanelClose = () => {
        scope.innerVisible = false;
        scope.onVisibleChange({ value: false });
      };

      scope.$watch('visible', newVal => {
        scope.innerVisible = newVal;
      });
    }
  };
});
import { computed } from 'vue';
import { useAccount } from '../common/account';

export const useExecutorLevels = ({ alwaysIncludeShopLevel } = {}) => {
    const { account } = useAccount();
    const executorLevels = computed(() => {
        const levels = [
            { label: 'Начальник отдела продаж', value: 'DIVISION' },
            { label: 'Менеджер по продажам', value: 'REGION' },
            { label: 'Магазин', value: 'SHOP' },
        ];

        if (account.value.level === 'DIVISION') {
            return levels.filter(level => level.value !== 'DIVISION');
        }

        if (account.value.level === 'REGION' || account.value.level === 'SHOP' && alwaysIncludeShopLevel) {
            return levels.filter(level => !['DIVISION', 'REGION'].includes(level.value));
        }

        if (account.value.level === 'SHOP') {
            return [];
        }

        return levels;
    });

    return { executorLevels };
};

<script setup>
import { ref, useModel, watch } from 'vue';
import { CustomCheckbox, CustomInput, ULabel, SingleSelect } from '@eron/ui-kit';

import ExpansionPanel from '@/components/common/ExpansionPanel.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
    expanded: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value', 'update:expanded']);

const notificationPeriodOptions = [
    {
        label: 'Каждый день',
        value: 'daily',
    },
    {
        label: 'За день до крайнего срока',
        value: 'day_before_deadline',
    },
    {
        label: 'В крайний день исполнения',
        value: 'deadline',
    },
    {
        label: 'Раз в неделю',
        value: 'weekly',
    },
];

const innerExpanded = useModel(props, 'expanded');
const notificationPeriod = ref('daily');
const executorCommentRequired = ref(false);
const executorAttachmentsRequired = ref(false);
const minAttachmentsCount = ref(1);
let skipUpdate = false;

const emitChange = () => {
    skipUpdate = true;
    emit('update:model-value', {
        ...props.modelValue,
        notificationPeriod: notificationPeriod.value,
        executorCommentRequired: executorCommentRequired.value,
        minAttachmentsCount: executorAttachmentsRequired.value ? minAttachmentsCount.value : 0,
    });
};

const validate = () => {
    return !!notificationPeriod.value;
};

const onMinAttachmentsCountBlur = () => {
    minAttachmentsCount.value = minAttachmentsCount.value ? Math.max(minAttachmentsCount.value, 1) : 1;
    emitChange();
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        if (newVal) {
            notificationPeriod.value = newVal.notificationPeriod || 'daily';
            executorCommentRequired.value = newVal.executorCommentRequired || false;
            minAttachmentsCount.value = newVal.minAttachmentsCount || 1;
        }
    },
    { immediate: true }
);

defineExpose({
    validate,
});
</script>

<template>
    <expansion-panel id="tasks-generate-task-data" v-model="innerExpanded" title="Дополнительные параметры">
        <div class="-mb-6 -w-80">
            <u-label for-value="task-notification-period"> Напоминать исполнителям </u-label>
            <single-select
                id="task-notification-period"
                v-model="notificationPeriod"
                :options="notificationPeriodOptions"
                :clearable="false"
                @update:model-value="emitChange"
            />
        </div>

        <div class="-mb-3">
            <u-label>Исполнители обязаны</u-label>
        </div>

        <div class="-mb-3">
            <custom-checkbox
                id="task-executors-comment-required"
                v-model:checked="executorCommentRequired"
                label="Ввести комментарий"
                @update:checked="emitChange"
            />
        </div>

        <div class="-mb-3">
            <custom-checkbox
                id="task-executors-attachments-required"
                v-model:checked="executorAttachmentsRequired"
                label="Добавить вложения"
                @update:checked="emitChange"
            />
        </div>

        <div v-if="executorAttachmentsRequired" class="-w-36">
            <custom-input id="task-executors-min-attachments" v-model="minAttachmentsCount" type="number" @blur="onMinAttachmentsCountBlur" />
        </div>
    </expansion-panel>
</template>

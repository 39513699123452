angular.module('smartApp').controller('AccreditationCategoryReportController', AccreditationCategoryReportController);
AccreditationCategoryReportController.$inject = ['$scope'];

function AccreditationCategoryReportController($scope) {



    $scope.categoryNames = [];

    $scope.findCategory = function(categories, categoryNameToMatch) {
        for(var i = 0; i < categories.length; i++) {
            if (categories[i].categoryName == categoryNameToMatch) {
                return categories[i];
            }
        }

        var defaultReturnValue = {
            "categoryAccreditationRate" : 'Отсутствует'
        }

        return defaultReturnValue;
    };

    function onUpdateReportResult(result) {
        if (result) {
            for(var i = 0; i < result.length; i++) {
                var el = result[i];
                for(var j = 0; j < el.categories.length; j++) {
                    if($scope.categoryNames.indexOf(el.categories[j].categoryName) == -1) {
                        $scope.categoryNames.push(el.categories[j].categoryName);
                    }
                }
            }
        }
    }

    $scope.$watch('reportResult', onUpdateReportResult);
}
<template>
    <div>
        <div class="row mb-4">
            <div class="col-3 align-self-center">
                <custom-input
                    id="banner-attachment-name"
                    v-model="fileName"
                    placeholder="Введите название"
                ></custom-input>
            </div>

            <div class="col-1 align-self-center attachment-uploader mr-0 pr-0">
                <file-uploader
                    id="banner-attachment"
                    input-label=""
                    input-icon="clip"
                    @upload="handleFileUpload"
                ></file-uploader>
            </div>

            <div v-if="addedFile" class="col-auto align-self-center">
                <p>{{ addedFile.name }}</p>
            </div>

            <div class="col-auto align-self-center">
                <custom-button
                    text="Добавить файл"
                    variant="grey"
                    :disabled="!addedFile || !fileName"
                    @click="handleSendAttachment"
                >
                </custom-button>
            </div>

            <div class="align-self-center">
                <p v-if="addedFile && !fileName" class="name-error">Введите имя файла</p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <custom-table
                    id="banner-attachments"
                    :columns="tableFields"
                    :items="attachmentObject.attachments"
                    :busy
                >
                    <template #createDate="{ item: { value } }">
                        {{ formatDate(new Date(value)) }}
                    </template>

                    <template #link="{ item: { value } }">
                        <a
                            target="_blank"
                            :href="value"
                        > {{ value }} </a>
                    </template>

                    <template #delete="{ row }">
                        <custom-button
                            text="X"
                            variant="grey"
                            class="delete-row-button"
                            @click="handleDeleteAttachment(row)"
                        >
                        </custom-button>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import formatDate from '@/helpers/formatDate';

// Components
import { CustomInput, CustomButton, FileUploader, CustomTable } from '@eron/ui-kit';

export default {
    name: 'BannerAttachmentList',
    components: {
        CustomInput,
        CustomButton,
        FileUploader,
        CustomTable,
    },
    props: {
        attachmentObject: {
            type: Object,
            required: true,
        },
        busy: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fileName: '',
            addedFile: null,
            tableFields: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'name',
                    label: 'Название файла',
                },
                {
                    key: 'link',
                    label: 'Ссылка',
                },
                {
                    key: 'createDate',
                    label: 'Дата добавления',
                },
                {
                    key: 'delete',
                    label: '',
                },
            ],
            formatDate,
        };
    },
    methods: {
        handleDeleteAttachment(row) {
            const result = confirm('Удалить данное вложение?');
            if (result) {
                // NOTE: Проверить удаление
                this.$store.dispatch('banners/fetchContentAttachmentDelete', row.id).then(() => {
                    this.$emit('attachmentDeleted');
                });
            }
        },
        handleFileUpload(files) {
            this.addedFile = files[0];
        },
        handleSendAttachment() {
            const payload = {
                file: this.addedFile,
                content: {
                    contentId: this.attachmentObject.id,
                    name: this.fileName,
                },
            };
            this.$store.dispatch('banners/fetchAddAttachment', payload).then(() => {
                alert('Вложение добавлено успешно');
                this.$emit('attachmentAdded');
                this.addedFile = null;
            });
        },
    },
};
</script>

<style scoped lang="scss">
:deep(.component-custom-file-input__label .icon) {
    width: 40px;
    height: 40px;
}

:deep(.file-uploader) {
    width: fit-content;
}

.attachment-uploader {
    width: fit-content;
}

.name-error {
    color: red;
}
</style>

angular.module('smartApp').controller('XfilterController5', XfilterController5);
XfilterController5.$inject = ['$scope', 'UserService', 'ChecklistReportService'];

function XfilterController5($scope, UserService, ChecklistReportService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);

}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
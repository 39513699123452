'use strict';

angular.module('smartApp').controller('AccountController', function($state, Auth, ngDialog, $scope, Principal, UserService, localStorageService, Notifications, config, SystemParamsService) {
	$scope.user = Principal.getIdentity()


	$scope.showPasswordChage = function(){
		var userEmail = $scope.user.email
		ngDialog.open({
			 	template: 'passwordChange',
	            controller: ['$scope', function($scope) {
	            	$scope.pwdMinLen = 4;
	            	$scope.newPwd = "";
	            	$scope.confPwd = "";
	            	$scope.change = function(){
	            		Auth.passwordChange($scope.newPwd, $scope.confPwd, userEmail).then(function(data){
				            Notifications.success("Пароль успешно изменён");
				            ngDialog.closeAll();
				            $state.go('login');
				        });
	            	}

	            	$scope.isSubmitDisabled = function(){
				        if(!$scope.newPwd)return true
				        return !($scope.newPwd == $scope.confPwd && $scope.newPwd.length >= $scope.pwdMinLen)
				    }
					
				}],	            
	            className: 'ngdialog-theme-plain dialog',
	            showClose: false
			 })
	}
})
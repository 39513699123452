angular.module('smartApp').controller('FilterController2', FilterController2);
FilterController2.$inject = ['$scope', 'UserService'];

function FilterController2($scope, UserService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);


	//$scope.users = [];
}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
angular.module('smartApp').controller('XfilterController11', XfilterController11);
XfilterController11.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService', 'PWCategoryService', 'TemplateService'];

function XfilterController11($scope, UserService, ChecklistReportService, PanelGroupsService, PWCategoryService, TemplateService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);
	$scope.users = [];
	$scope.fio = {selected:undefined}


	//$scope.selectAllTemplates()

	TemplateService.getOnlyDM().then(function(result) {
		$scope.uiSelect.templates.selected = result.data
	});
}


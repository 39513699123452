<template>
    <tabs v-model="tab" :tabs="tabs" class="mt-3">
        <template #general>
            <div class="row">
                <div class="col-6">
                    <label for="input-live">Название баннера*</label>
                    <custom-input
                        id="input-live"
                        v-model="localItem.name"
                        placeholder="Введите название баннера"
                    ></custom-input>
                </div>

                <div class="col-2">
                    <label for="input-live">Дата начала*</label>
                    <custom-datepicker
                        v-model="localItem.dateStart"
                        display-year
                        auto-position
                    ></custom-datepicker>
                </div>

                <div class="col-2">
                    <label for="input-live">Дата окончания*</label>
                    <custom-datepicker
                        v-model="localItem.dateFinish"
                        display-year
                        auto-position
                    ></custom-datepicker>
                </div>
            </div>

            <br>

            <div class="row">
                <div class="col-6">
                    <label for="input-live">Балансовые единицы*</label>
                    <multiple-select
                        id="banner-business-units"
                        v-model="localItem.businessUnit"
                        open-direction="top"
                        :options="computedBusinessUnits"
                    ></multiple-select>
                </div>

                <div class="col-auto align-self-center mr-0 pr-0">
                    <custom-checkbox id="banner-is-active" v-model="localItem.isActive"></custom-checkbox>
                </div>
                <div class="col align-self-center ml-0 pl-0">
                    <p>Активен</p>
                </div>
            </div>

            <br>
            <br>

            <div class="row align-items-center justify-content-center">
                <div class="col-auto">
                    <custom-button
                        text="Сохранить"
                        :disabled="buttonState && isSaveDisabled"
                        @click="$emit('save', localItem)"
                    >
                    </custom-button>
                </div>
            </div>
        </template>

        <template #content>
            <banner-content :banner="localItem"></banner-content>
        </template>
    </tabs>
</template>

<script>
import { toRaw } from 'vue';
import { mapGetters } from 'vuex';

// Components
import {
    MultipleSelect, CustomInput, CustomDatepicker, CustomCheckbox, CustomButton, Tabs,
} from '@eron/ui-kit';
import BannerContent from './BannerContent.vue';

export default {
    name: 'BannerEdit',
    components: {
        MultipleSelect,
        CustomInput,
        CustomDatepicker,
        CustomCheckbox,
        CustomButton,
        Tabs,
        BannerContent,
    },
    props: {
        showContentTab: {
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
        },
        isSaveDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localItem: { ...this.item },
            tab: 0,
            tabs: [
                { title: 'Общее', index: 'general' },
                { title: 'Контент', index: 'content' },
            ],
        };
    },
    computed: {
        ...mapGetters('banners', {
            businessUnits: 'getBusinessUnits',
        }),
        computedBusinessUnits() {
            return this.businessUnits.map((businessUnitArg) => {
                return {
                    value: businessUnitArg.countryCode,
                    label: businessUnitArg.countryCode,
                };
            });
        },
        buttonState() {
            return !(
                this.localItem.name &&
                this.localItem.dateStart &&
                this.localItem.dateFinish &&
                toRaw(this.localItem).businessUnit.length > 0
            );
        },
    },
    watch: {
        'localItem.dateStart'(newDateStart) {
            this.$nextTick(() => {
                if (newDateStart > new Date(this.localItem.dateFinish)) {
                    this.localItem.dateFinish = newDateStart;
                }
            });
        },
        'localItem.dateFinish'(newDateFinish) {
            const today = new Date();
            if (!this.localItem.dateStart && newDateFinish < today) {
                this.localItem.dateFinish = today;
            }
            this.$nextTick(() => {
                if (this.localItem.dateStart && new Date(this.localItem.dateStart) > newDateFinish) {
                    this.localItem.dateFinish = new Date(this.localItem.dateStart);
                }
            });
        },
    },
    created() {
        if (this.businessUnits.length <= 0) this.$store.dispatch('banners/fetchBusinessUnits');
    },
};
</script>

<style scoped lang="scss">
:deep(.tab-content) {
    padding-left: 0;
    border: none;
}

.fade.show {
    opacity: 1;
}
</style>

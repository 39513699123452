(function() {
    'use strict';

    angular
        .module('smartApp')
        .directive('taskAuthorAttach', taskAuthorAttach);

    taskAuthorAttach.$inject = [];

    function taskAuthorAttach() {
        var directive = {
            templateUrl: 'scripts/app/tasks/task-item/task-author-attach/task-author-attach.view.html',
            bindToController: true,
            controller: TaskAuthorAttachController,
            controllerAs: 'vm',
            restrict: 'EA',
            scope: {
                taskId: '<',
                isResponse: '<'
            }
        };
        return directive;
    }

    TaskAuthorAttachController.$inject = ['TasksViewService','Notifications','$window','TaskListServiceHttp'];

    function TaskAuthorAttachController(TasksViewService, Notifications, $window,TaskListServiceHttp) {
        var vm = this;

        vm.attachments = [];
        vm.activeIndex = null;
        vm.isDisplayedControls = false;

        vm.setActive = setActive;
        vm.showControls = showControls;
        vm.download = download;


        vm.viewAttachment = function(attachment, e){
             e.preventDefault();
             e.stopPropagation();
            $window.open(attachment.url, '_blank');
        }

        init();

        function init() {
            var queryObj = {}
            queryObj[(vm.isResponse)?"responseId":"taskId"] = (vm.isResponse)?vm.responseObject.responseId:vm.taskId
console.log(queryObj)
            TaskListServiceHttp[(vm.isResponse)?"getListResponseAttachments":"getListMainAttachments"](queryObj)
                .then(function(resp) {
                    if (resp.status === 200) {
                        vm.attachments = resp.data.attachments.map(el => {
                          el.typeForPreview = TasksViewService.getAttachmentTypeForPreview(el.type)
                          return el;
                        })                      
                    }else if(resp.status === 204){
                        Notifications.warning('Вложения отсуствуют. Вероятно что-то пошло не так');
                    }else{
                        console.log(resp.status, resp.headers('x-error'))
                        Notifications.danger('Ошибка в загрузке вложений: ', resp.headers('x-error'));
                    }
                });
        }

        function setActive(index) {
            vm.isDisplayedControls = false;
            vm.activeIndex = index;
        }

        function showControls(event) {
            event.stopPropagation();
            vm.isDisplayedControls = !vm.isDisplayedControls;
        }

        function download(attachment) {
            $window.open(attachment.url,'_blank');
        }
    }
})();
angular.module('smartApp').directive('scrollRestoration', ['$rootScope', '$state', '$timeout', function($rootScope, $state, $timeout) {
  var scrollPositionCache = {};
  var lastStateName = null;

  function restoreScrollPosition(element) {
    if (lastStateName && !isSameRoot($state.current.name, lastStateName)) {
      return;
    }

    var href = $state.href($state.current.name, $state.params);

    if (scrollPositionCache[href]) {
      var pos = scrollPositionCache[href];

      $timeout(function() {
        element.animate({ scrollTop: pos.y, scrollLeft: pos.x }, 500);
      }, 0);
    }
  }

  function isSameRoot(nameOne, nameTwo) {
    if (nameOne.indexOf('.') !== -1) {
      nameOne = nameOne.split('.');
    } else {
      nameOne = [nameOne];
    }

    if (nameTwo.indexOf('.') !== -1) {
      nameTwo = nameTwo.split('.');
    } else {
      nameTwo = [nameTwo];
    }

    return nameOne[0] === nameTwo[0];
  }

  return {
    link: function(scope, element) {
      restoreScrollPosition(element);

      var cleanupStart = $rootScope.$on('$stateChangeStart', function() {
        scrollPositionCache[$state.href($state.current.name, $state.params)] = {
          x: element[0].scrollLeft,
          y: element[0].scrollTop
        };
      });

      var cleanupSuccess = $rootScope.$on('$stateChangeSuccess', function() {
        lastStateName = $state.current.name;
      });

      scope.$on('$destroy', function() {
        cleanupStart();
        cleanupSuccess();
      });
    }
  };
}]);

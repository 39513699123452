/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 17.06.15.
 */

(function () {
	'use strict';

	angular.module('smartApp')
		.config(function ($stateProvider) {
			$stateProvider
				.state('counters.entries', {
					parent: 'counters',
					url: '/counters/entries',
					data: {
						roles: ['ROLE_ENGINEER', 'ROLE_MAIN_ENGINEER']
					},
					views: {
						'content@': {
							templateUrl: 'scripts/app/counters/editcounters/edit-counters.html'
						}
					}
				})
		})
})();

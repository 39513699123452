'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('adapter-auditor', {
                parent: 'developer',
                url: '/adapter-auditor',
                data: {
                    roles: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/developer/adapter-auditor/adapter-auditor.html'
                    }
                }
            });
    });

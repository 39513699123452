(function() {
	'use strict';

	angular.module('smartApp').component('executorsModal', {
		templateUrl:
			'scripts/app/tasks/task-new/executors-modal/executors-modal.view.html',

		controller: ExecutorsModalController,

		controllerAs: '$ctrl',

		bindings: {
			savedSaps: '<',

			userPermissions: '<',

			setExecutors: '&',

			selectedRole: '<',

			currUserAsExecutor: '<',

			taskType: '<'
		}
	});

	ExecutorsModalController.$inject = [
		'$scope',

		'$filter',

		'TaskListServiceHttp',

		'Notifications',

		'ngDialog',

		'OrgstructService',

		'Principal'
	];

	function ExecutorsModalController(
		$scope,

		$filter,

		TaskListServiceHttp,

		Notifications,

		ngDialog,

		OrgstructService,

		Principal
	) {
		var $ctrl = this;

		var searchPlaceholders = {
			SHOP:
				'Поиск по SAP, адресу, группе магазинов, отделу продаж, ФИО директора',

			REGION: 'Поиск по имени, фамилии и email директора',

			DIVISION: 'Поиск по имени, фамилии и email директора',

			TASK_MANAGER: 'Поиск по имени, фамилии и email'
		};

		$ctrl.executorRole = {};

		$ctrl.dataDirective = {
			selectedNodes: []
		};

		$ctrl.shopsSapObj = '';

		$ctrl.searchRequest = '';

		$ctrl.searchPlaceholder = '';

		$ctrl.allCompany = {
			selected: false
		};

		$ctrl.saps = {
			select: []
		};

		$ctrl.currUserAsExecutor = $ctrl.currUserAsExecutor || false;

		var currentUser = null;

		$ctrl.sendShopSap = sendShopSap;

		$ctrl.doSearch = doSearch;

		$ctrl.onRoleChange = onRoleChange;

		$ctrl.applyExecutor = applyExecutor;

		$scope.$watch('$ctrl.dataDirective.selectedNodes', function(newValue) {
			setTextForSelectedNodes(newValue);
		});

		$scope.$watch('$ctrl.dataForTheTree', function() {
			var saps = [];

			if (
				$ctrl.dataForTheTree &&
				$ctrl.dataForTheTree.length &&
				$ctrl.savedSaps &&
				$ctrl.savedSaps.length
			) {
				saps = $ctrl.savedSaps.map(function(item) {
					return item + '';
				});

				getShopData(saps);
			}
		});

		$ctrl.$onInit = function() {
			$ctrl.requestedSaps = [];

			getCurrentUser((err, user) => {
				if (err) {
					return;
				}

				currentUser = user;
			});

			getExecutorRoles((err, data) => {
				if (err) {
					Notifications.danger(
						'Ошибка получения доступных ролей: ' + err
					);

					console.log(err);

					return;
				}

				$ctrl.executorRoles = Object.keys(data).map(el => {
					return {
						name: data[el],

						role: el,

						state: el.toLowerCase()
					};
				});

				var roleNotFound = true;

				if ($ctrl.selectedRole) {
					roleNotFound = $ctrl.executorRoles.every(item => {
						if (item.role === $ctrl.selectedRole) {
							$ctrl.executorRole.selected = item;

							return false;
						}

						return true;
					});
				}

				if (roleNotFound || !$ctrl.selectedRole) {
					$ctrl.executorRole.selected = $ctrl.executorRoles[0];
				}

				$ctrl.searchPlaceholder =
					searchPlaceholders[$ctrl.executorRole.selected.role] ||
					'Поиск';
			});
		};

		$ctrl.$onChanges = function(changesObj) {};

		$ctrl.$onDestroy = function() {
			$ctrl.searchRequest = '';
		};

		function getExecutorRoles(cb) {
			TaskListServiceHttp.getExecutorRoles().then(function(response) {
				cb(
					response.status === 200 || response.status === 201
						? null
						: response.status + response.headers('x-error'),
					response.data
				);
			});
		}

		function getCurrentUser(callback) {
			var identity = Principal.getIdentity();

			return OrgstructService.getUser(identity.id).then(result => {
				if (result.status !== 200) {
					Notifications.danger(
						'Не удалось сохранить ответственного!'
					);
					callback({ status: result.status });
					return;
				}

				callback(null, result.data);
			});
		}

		function sendShopSap() {
			var pattern = /(?!:)[\w]+?(?=,|$)/gm;

			$ctrl.shopsSapObj = $ctrl.shopsSap.match(pattern);

			if ($ctrl.shopsSapObj) {
				getShopData($ctrl.shopsSapObj);
			} else {
				Notifications.danger(
					'Ошибка! Неверный формат данных!',

					'',

					2000
				);
			}
		}

		function applyExecutor() {
			if (
				!$ctrl.dataDirective.selectedNodes[0] &&
				!$ctrl.currUserAsExecutor
			) {
				Notifications.danger('Ошибка! Ничего не выбрано', '', 2000);
				return;
			}

			var result = null;

			if ($ctrl.currUserAsExecutor) {
				result = handleCurrUserAsExecutor();
			} else {
				result = handleNodesAsExecutors();
			}

			$ctrl.setExecutors({ data: result });

			ngDialog.close(ngDialog.latestID);
		}

		function handleCurrUserAsExecutor() {
			var data = {
				executorsInfo: [
					{
						id: currentUser.id,
						firstName: currentUser.firstName,
						lastName: currentUser.lastName,
						position: currentUser.position
					}
				],
				role: currentUser.level,
				currUserAsExecutor: true
			};

			return data;
		}

		function handleNodesAsExecutors() {
			var selectedRole = $ctrl.executorRole.selected.role;

			var getUserInfo = {
				REGION: getUserInfoForRegionLevel,
				SHOP: getUserInfoForShopLevel,
				DIVISION: getUserInfoForDivisionLevel
			};

			var executorsInfo = getUserInfo[selectedRole](
				$ctrl.dataDirective.selectedNodes
			);

			return { executorsInfo: executorsInfo, role: selectedRole };
		}

		function getUserInfoForShopLevel(nodes) {
			var ids = [];

			var infos = [];

			nodes.forEach(node => {
				if (node.type.toLowerCase() === 'shop') {
					if (ids.indexOf(node.directorId) === -1) {
						ids.push(node.directorId);

						infos.push({
							id: node.directorId,

							firstName: node.directorFirstName,

							lastName: node.directorLastName,

							position:
								'Заведующий магазином "' + node.locality + '"'
						});
					}

					if (node.sap) {
						$ctrl.requestedSaps.push(node.sap);
					}
				}
			});

			return infos;
		}

		function getUserInfoForRegionLevel(nodes) {
			var ids = [];
			var infos = [];

			nodes.forEach(node => {
				switch (node.type.toLowerCase()) {
					case 'region':
						node.dirs.forEach(dir => {
							if (ids.indexOf(dir.id) === -1) {
								ids.push(dir.id);

								infos.push({
									id: dir.id,
									firstName: dir.firstName,
									lastName: dir.lastName,
									position: 'Менеджер по продажам "' + node.name + '"'
								});
							}
						});
						break;
					case 'shop':
						if (ids.indexOf(node.id) === -1) {
							ids.push(node.id);
							infos.push({
								id: node.id,
								firstName: node.firstName,
								lastName: node.lastName,
								position: 'Менеджер по продажам "' + node.name + '"'
							});
						}
						break;
				}
			});

			return infos;
		}

		function getUserInfoForDivisionLevel(nodes) {
			var ids = [];

			var infos = [];

			nodes.forEach(node => {
				switch (node.type.toLowerCase()) {
					case 'division':
						node.dirs.forEach(dir => {
							if (ids.indexOf(dir.id) === -1) {
								ids.push(dir.id);

								infos.push({
									id: dir.id,

									firstName: dir.firstName,

									lastName: dir.lastName,

									position:
										'Начальник отдела продаж "' +
										node.name +
										'"'
								});
							}
						});
						break;
					case 'region':
						if (ids.indexOf(node.id) === -1) {
							ids.push(node.id);
							infos.push({
								id: node.id,
								firstName: node.firstName,
								lastName: node.lastName,
								position: 'Менеджер по продажам "' + node.name + '"'
							});
						}
						break;
				}
			});

			return infos;
		}

		function getShopData(shopsSaps) {
			var missingSaps = [];

			TaskListServiceHttp.getShopData(shopsSaps).then(function(response) {
				if (response.status === 200 || response.status === 201) {
					if (!response.data.length) {
						missingSaps = shopsSaps;
					} else {
						missingSaps = _.difference(
							shopsSaps,

							_(response.data)
								.map('sap')

								.value()
						);
					}

					$ctrl.saps.select = _.clone(response.data);

					$ctrl.shopsSap = '';

					if (missingSaps.length) {
						notFoundSap(missingSaps, response.data.length);
					}

					setTextAreaContent($ctrl.saps.select);
				} else {
					Notifications.danger(
						'Ошибка! Невозможно получить магазины!',

						'',

						2000
					);
				}
			});
		}

		function doSearch(role, request, e) {
			if (!e) {
				$ctrl.searchRequest = request;
				return;
			}

			if (e && e.which === 13) {
				$ctrl.searchRequest = request;
				e.target.blur();
			}
		}

		function onRoleChange() {
			$ctrl.searchPlaceholder =
				searchPlaceholders[$ctrl.executorRole.selected.role] || 'Поиск';

			$ctrl.searchRequestExecutor = null;
		}

		function setTextAreaContent(shops) {
			_.forEach(shops, function(shop) {
				$ctrl.shopsSap =
					$ctrl.shopsSap +
					'SAP: ' +
					shop.sap +
					', ' +
					shop.locality +
					'\n';
			});
		}

		function notFoundSap(saps, numSelectedShops) {
			ngDialog.open({
				template: 'warningNotFoundSap',

				controller: [
					'$scope',

					function($scope) {
						$scope.plural = saps.length > 1;

						$scope.saps = saps.join(', ');

						$scope.numSelectedShops = numSelectedShops;
					}
				],

				className: 'ngdialog-theme-plain',

				showClose: false
			});
		}

		function setTextForSelectedNodes(nodes) {
			var filteredShops = $filter('uniqueItems')(nodes, 'id').filter(node => node.type === 'shop');

			$ctrl.shopsSap = '';

     		$ctrl.numSelectedShops = filteredShops.length;
    
			setTextAreaContent(filteredShops);
		}
	}
})();

'use strict';

angular.module('smartApp').factory('ThrobberService', function ShopService() {
    var counter = 0;
    return {
        showThrobber: function(isHide) {
            $('ajaxLoader').show();
            counter++;
        },

        hideThrobber: function() {
            counter--;

            if (!counter || counter < 0) {
              counter = 0;
              $('ajaxLoader').hide();
            }
        }
    };
});

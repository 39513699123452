'use strict';

angular.module('smartApp').factory('HoutService', function HoutService($http, config) {

    return {
        getAllGroups: function(cb) {
            $http.get(config.backend + '/processWizard/group', {noThrobber:true})
                .success(function (result) {    
			    	if(result.status && result.status != 200){
			    		console.log("Error:", result)			    		
			    		cb(result.status)
			    	}
			    	cb(null, result)
			    }
            );
        },

        removeGroup: function(groupId, cb) {
            $http.delete(config.backend + '/processWizard/group/'+groupId)
                .success(function (result) {    
			    	if(result.status && result.status != 200){
			    		console.log("Error:", result)			    		
			    		cb(result.status)
			    	}
			    	if(cb)cb(null, result)
			    }
            );
        },

        createGroup: function(name, parentId, cb) {
        	var data = {'name':name,parentId:parentId}
            $http.post(config.backend + '/processWizard/group/')
                .success(function (result) {    
			    	if(result.status && result.status != 200){
			    		console.log("Error:", result)			    		
			    		cb(result.status)
			    	}
			    	cb(null, result)
			    }
            );
        },

        updateGroup: function(name, parentId, cb) {
        	var data = {'name':name,parentId:parentId}
            $http.put(config.backend + '/processWizard/group/')
                .success(function (result) {    
			    	if(result.status && result.status != 200){
			    		console.log("Error:", result)			    		
			    		cb(result.status)
			    	}
			    	cb(null, result)
			    }
            );
        },
        
        getGroup: function(id, cb){
        	$http.get(config.backend + '/processWizard/group/'+id, {noThrobber:true})
                .success(function (result) {    
			    	if(result.status && result.status != 200){
			    		console.log("Error:", result)			    		
			    		cb(result.status)
			    	}
			    	cb(null, result)
			    }
            );
            
        }
        
    }

});



<script setup>
import { onMounted, ref } from 'vue';
import { CustomButton } from '@eron/ui-kit';
import { getRules } from '@/api/tasks/generate';
import { useAccount } from '@/use/common/account';

import TasksGenerateFilters from './TasksGenerateFilters.vue';
import TasksGenerateTable from './TasksGenerateTable.vue';
import TasksGenerateEditor from '../editor/TasksGenerateEditor.vue';

const selectedFilters = ref();
const pagination = ref({
    page: 1,
    limit: 15,
});
const sort = ref({
    field: 'name',
    direction: 'desc',
});

const { account } = useAccount();
const data = ref([]);
const totalPages = ref(1);
const loading = ref(false);
const editorVisible = ref(false);
const editorMode = ref();
const editRuleId = ref();

const refreshData = async () => {
    loading.value = true;

    const { name, periodicity, taskTypes: type, onlyActive, authorIds: authors } = selectedFilters.value || {};
    const { page, limit: size } = pagination.value;
    const { field, direction } = sort.value;
    const apiSortFields = {
        date: 'activeFrom',
        author: 'authorName',
        taskType: 'type',
    };

    try {
        const result = await getRules({
            name,
            periodicity,
            type,
            active: onlyActive === false ? undefined : true,
            authors,
            page: page - 1,
            size,
            sort: `${apiSortFields[field] || field},${direction}`,
        });
        data.value = result.data;
        totalPages.value = parseInt(result.headers['x-total-pages']);
    } catch {
        //
    }

    loading.value = false;
};

const openEditor = async (mode, ruleId) => {
    editRuleId.value = ruleId;
    editorMode.value = mode;
    editorVisible.value = true;
};

const onFiltersChange = () => {
    pagination.value = { ...pagination.value, page: 1 };
    refreshData();
};

const onTableRowClick = async dataItem => {
    const { id: accountId, roles } = account.value;
    const canEdit = roles.includes('ROLE_ADMIN') || (roles.includes('TASK_GENERATION_RULE_EDITOR') && dataItem.authorId === accountId);

    if (canEdit) {
        openEditor('edit', dataItem.id);
    }
};

const onTableRowCopy = async dataItem => {
    openEditor('create', dataItem.id);
};

const onEditorVisibilityChange = visible => {
    if (!visible) {
        editRuleId.value = undefined;
    }
};

onMounted(() => {
    refreshData();
});
</script>

<template>
    <div class="-overflow-auto">
        <h1>Правила генерации</h1>

        <tasks-generate-filters v-model="selectedFilters" :disabled="loading" @update:model-value="onFiltersChange">
            <template #header:end>
                <custom-button text="Создать правило" theme="green" class="-mr-4" @click.stop="openEditor('create')" />
            </template>
        </tasks-generate-filters>

        <tasks-generate-table
            v-model:pagination="pagination"
            v-model:sort="sort"
            :data="data"
            :loading="loading"
            :total-pages="totalPages"
            :pagination="pagination"
            class="-mt-6"
            @update:pagination="refreshData"
            @update:sort="refreshData"
            @click:row="onTableRowClick"
            @copy:row="onTableRowCopy"
        />

        <tasks-generate-editor
            v-model="editorVisible"
            :rule-id="editRuleId"
            :mode="editorMode"
            @update:model-value="onEditorVisibilityChange"
            @save="refreshData"
        />
    </div>
</template>

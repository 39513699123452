(function() {
    'use strict';

    angular.module('smartApp').factory('ParamsTabDataService', ParamsTabDataService);

    function ParamsTabDataService() {


        var notificationView = {
            // 'memento.NotCheckedSmDd.dd.template.id': {
            //     title: 'Общие настройки',
            //     label: 'Id шаблона чек-листа для ДД'
            // },
            'memento.missedTaskDeadline.before.day.amount': {
                title: 'Общие настройки',
                label: 'За сколько дней начинать показывать просроченные задачи?'
            },
            'memento.systemStats.notification.defaultEmails': {
                title: 'Общие настройки',
                label: 'Введите E-mail, на которые следует отправлять ежедневные отчеты о работе системы',
                placeholder: '(если адресов несколько – указывайте их через пробел)',
                type: 'chips'
            },
            'memento.ShopSelfCheckStat.superDirs.emails': {
                title: 'Общие настройки',
                label: 'Введите E-mail тех, кто получает уведомления по всем магазинам, которые не заполняют чек-листы ДМ',
                placeholder: '(если адресов несколько – указывайте их через пробел)',
                type: 'chips'
            },
            // 'memento.shopSelfCheckStat.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о магазинах, которые не заполняют чеклисты ДМ на реальные адреса'
            // },
            // 'memento.shopSelfCheckStat.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.missedAuditPlan.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о недопланировании проверок на следующий месяц на реальные адреса'
            // },
            // 'memento.missedAuditPlan.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.gradeCriticalFall.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о низких оценках по СМ на реальные адреса'
            // },
            // 'memento.gradeCriticalFall.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.performedAudit.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о проведенных проверках на реальные адреса'
            // },
            // 'memento.performedAudit.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.notCheckedSmDd.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о проверках по чек-листу ДM  на реальные адреса'
            // },
            // 'memento.notCheckedSmDd.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.outOfDateTask.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о просроченных задачах на реальные адреса'
            // },
            // 'memento.outOfDateTask.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.notCheckedSm.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления о СМ, которые не проверялись более'
            //         + ' N дней (а так же по чеклисту ДД за 4 месяца) на реальные адреса'
            // },
            // 'memento.notCheckedSm.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // },
            // 'memento.openTasks.sendToRealEmails': {
            //     title: 'Отправка уведомлений',
            //     label: 'Отправлять уведомления об открытых задачах на реальные адреса'
            // },
            // 'memento.openTasks.mockEmail': {
            //     title: 'Отправка уведомлений',
            //     label: 'Ввведите e-mail, на которые следует отправлять уведомления:',
            //     placeholder: '(если адресов несколько – указывайте их через пробел)',
            //     type: 'chips'
            // }
        };
        var checklistView = {
            'checklist.check.interval': {
                title: 'Проведение проверок',
                label: 'Запретить проверку вне допустимого временного интервала?'
            },
            'checklist.supervisingDirector.treatCoordinatesAsSupervisor': {
                title: 'Проведение проверок',
                label: 'При проведении проверки директором учитывать коордиинаты как для проверяющего?'
            },
            // '': {
            //     title: 'Проведение проверок',
            //     label: 'Блокировать проверку при несовпадении координат?',
            //     placeholder: ''
            // },
            // '': {
            //     title: 'Проведение проверок',
            //     label: 'Пустой комментарий допустим для ответа "Нет"?',
            //     placeholder: ''
            // },
            'checklist.beginPlaningPeriodDay': {
                title: 'Проведение проверок',
                supTitle: 'Выберите требуемое количество дней',
                label: 'С какого дня месяца начать планирование проверок?'
            },
            'checklist.daysToBegin': {
                title: 'Проведение проверок',
                label: 'За сколько дней можно начать отправлять проверки?'
            },
            'checklist.daysBeforeBegin': {
                title: 'Проведение проверок',
                label: 'За сколько дней начинать проверку?'
            },
            'checklist.defaultMinimalInterval': {
                title: 'Проведение проверок',
                label: 'Минимальный интервал планирования по умолчанию'
            },
            'checklist.consideringCoordinates': {
                title: 'Проведение проверок',
                supTitle: 'Выберите действие',
                label: 'Учитывать координаты при проведении проверки?'
            },
            'checklist.directorChecklistsForEveryDay': {
                title: 'Чек-листы для ДМ',
                label: 'Установить единый номер чек-листа на все дни',
                type: 'checkbox'
            },
            'checklist.directorChecklistForMonday': {
                title: 'Чек-листы для ДМ',
                label: 'Понедельник',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForTuesday': {
                title: 'Чек-листы для ДМ',
                label: 'Вторник',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForWednesday': {
                title: 'Чек-листы для ДМ',
                label: 'Среда',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForThursday': {
                title: 'Чек-листы для ДМ',
                label: 'Четверг',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForFriday': {
                title: 'Чек-листы для ДМ',
                label: 'Пятница',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForSaturday': {
                title: 'Чек-листы для ДМ',
                label: 'Суббота',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.directorChecklistForSunday': {
                title: 'Чек-листы для ДМ',
                label: 'Воскресенье',
                type: 'multi-select',
                isChecklistValues: true
            },
            'checklist.deletion.nextMonthPlan': {
                title: 'Удаление чек-листов',
                supTitle: 'Разрешать пользователям',
                label: 'Удалять запланированные чек-листы в следующем месяце'
            },
            'checklist.deletion.nextMonthUnplan': {
                title: 'Удаление чек-листов',
                label: 'Удалять незапланированные чек-листы в следующем месяце'
            },
            'checklist.deletion.currentMonthPlan': {
                title: 'Удаление чек-листов',
                label: 'Удалять запланированные чек-листы в текущем месяце'
            },
            'checklist.deletion.currentMonthUnplan': {
                title: 'Удаление чек-листов',
                label: 'Удалять незапланированные чек-листы в текущем месяце'
            },
            'checklist.deletion.today': {
                title: 'Удаление чек-листов',
                label: 'Удалить чек-листы за сегодня'
            },
            // '': {
            //     title: 'Удаление чек-листов',
            //     label: 'Удалять чек-листы только на следующий месяц',
            //     placeholder: ''
            // },
            'checklist.deletion.daysBack': {
                title: 'Удаление чек-листов',
                supTitle: 'Выберите требуемое количество дней',
                label: 'Количество дней назад, за которое можно начать удалять чек-листы'
            }

        };
        var qualityView = {
            'serviceQuality.checklistCreate.dayOfMonth': {
                title: 'Создание чек-листов',
                label: 'День месяца создания чек-листов при ежемесячном создании'
            },
            'serviceQuality.checklistCreate.dayOfWeek': {
                title: 'Создание чек-листов',
                label: 'День недели создания чек-листов при еженедельном создании',
                possibleValues: [{
                    id: 1,
                    val: 'Понедльник',
                },{
                    id: 2,
                    val: 'Вторник',
                },{
                    id: 3,
                    val: 'Среда',
                },{
                    id: 4,
                    val: 'Четверг',
                },{
                    id: 6,
                    val: 'Суббота',
                },{
                    id: 7,
                    val: 'Воскресенье',
                }],
                type: 'select'
            },
            'serviceQuality.checklistCreate.period': {
                title: 'Создание чек-листов',
                label: 'Периодичность создания чек-листов',
                possibleValues: [{
                    id: 'DAILY',
                    val: 'Ежедневно'
                }, {
                    id: 'WEEKLY',
                    val: 'Еженедельно'
                }, {
                    id: 'MONTHLY',
                    val: 'Ежемесячно'
                }]
            },
            'serviceQuality.checklistCreate.hourOfDay': {
                title: 'Создание чек-листов',
                label: 'Час создания чек-листов'
            },
            'mobile.reports.shouldShow': {
                title: 'Планшеты',
                label: 'Показывать отчеты?'
            },
            'serviceQuality.templateId': {
                title: 'Планшеты',
                supTitle: 'Выберите значение',
                label: 'Id шаблона чек-листа для отображения на планшетах',
                type: 'multi-select',
                isChecklistValues: true
            },
            'serviceQuality.questionDisplayTimeoutSec': {
                title: 'Планшеты',
                label: 'Время отображения вопроса на планшете',
                type: 'number'
            },
            'serviceQuality.adTimeoutSec': {
                title: 'Планшеты',
                label: 'Длительность отображения рекламы на планшете',
                type: 'number'

            },
            'serviceQuality.templateUpdateIntervalSec': {
                title: 'Планшеты',
                label: 'Интервал обновления планшетами шаблона чек-листа',
                type: 'number'
            },
            'serviceQuality.adRefreshHour': {
                title: 'Планшеты',
                label: 'Периодичность обновления рекламы с сервера',
                type: 'number'
            }
        };

        var generalView = {
            // 'tasks.manual.show': {
            //     title: 'Веб',
            //     label: 'Показывать задачи?'
            // },
            // 'tasks.manual.attachments.show': {
            //     title: 'Веб',
            //     label: 'Показывать вложения для задач?'
            // },
            // 'shop.franchiseeSapPrefixes': {
            //     title: 'Магазины',
            //     label: 'Префиксы SAP для франчайзи',
            //     placeholder: 'Введите префикс'
            // },
            'login.domain': {
                title: 'Система',
                label: 'Домен для подстановки в логины',
                placeholder: 'Введите домен'
            },
            'web.projectName': {
                title: 'Система',
                label: 'Название системы для подстановки логина',
                placeholder: 'Название'
            },
            'server.node.list': {
                title: 'Система',
                label: 'Список URL серверов',
                placeholder: '(если URL несколько – указывайте их через запятую)',
                type: 'chips'
            },
            'powerbi.link': {
                title: 'Система',
                label: 'Ссылка на аналитику PowerBI',
                placeholder: 'http://'
            }
        };

        var service = {
            notificationView: notificationView,
            checklistView: checklistView,
            qualityView: qualityView,
            generalView: generalView
        };

        return service;
    }
})();
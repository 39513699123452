<script setup>
import { ref, watch } from 'vue';
import { Modal } from '@eron/ui-kit';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: undefined,
    },
    subTitle: {
        type: String,
        default: undefined,
    },
    clickToClose: {
        type: Boolean,
        default: true,
    },
});
const emit = defineEmits(['update:model-value', 'opened', 'closed']);

const visible = ref(false);

watch(
    () => props.modelValue,
    newVal => {
        visible.value = newVal;
    },
    { immediate: true }
);
</script>

<template>
    <modal
        v-model="visible"
        position="right"
        content-transition="vfm-slide-right"
        content-class="side-panel"
        :focus-trap="false"
        :title="title"
        :sub-title="subTitle"
        :teleport-to="false"
        :click-to-close="clickToClose"
        show-header
        @update:model-value="emit('update:model-value', $event)"
        @opened="emit('opened', $event)"
        @closed="emit('closed', $event)"
    >
        <template #default="defaultProps">
            <slot name="default" v-bind="defaultProps" />
        </template>
        <template #footer="footerProps">
            <slot name="footer" v-bind="footerProps" />
        </template>
    </modal>
</template>

<style lang="scss">
.modal {
    display: flex !important;
}

.side-panel {
    width: 550px !important;

    .modal__body {
        flex-grow: 1;
    }

    .modal__content-inner {
        border-radius: 0;
    }

    .modal__header,
    .modal__body,
    .modal__footer {
        padding-left: 28px !important;
        padding-right: 28px !important;
    }

    .modal__header svg {
        right: 28px;
    }
}
</style>

<script setup>
import { CustomTable, CustomButton, PaginationBar } from '@eron/ui-kit';
import { computed, ref, watch } from 'vue';
import { useAccount } from '@/use/common/account';

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    pagination: {
        type: Object,
        default: undefined,
    },
    sort: {
        type: Object,
        default: undefined,
    },
    totalPages: {
        type: Number,
        default: 0,
    },
});
const emit = defineEmits(['update:pagination', 'update:sort', 'click:row', 'copy:row']);

const tableColumns = [
    {
        key: 'name',
        label: 'Название',
        sortable: true,
    },
    {
        key: 'author',
        label: 'Автор',
        sortable: true,
    },
    {
        key: 'periodicity',
        label: 'Периодичность',
    },
    {
        key: 'date',
        label: 'Период работы правила',
        sortable: true,
    },
    {
        key: 'taskType',
        label: 'Тип задачи',
        sortable: true,
    },
    {
        key: 'actions',
    },
];

const { account } = useAccount();
const page = ref();
const limit = ref();
const sortBy = ref(props.sort?.field);
const sortDesc = ref(props.sort?.direction.toLowerCase() === 'desc');
const isAdmin = account.value.roles.includes('ROLE_ADMIN');

const items = computed(() => {
    const periodicityLabel = {
        daily: 'Ежедневно',
        weekly: 'Еженедельно',
        monthly: 'Ежемесячно',
        quarterly: 'Ежеквартально',
        halfYearly: 'Раз в полгода',
        lastDayOfMonth: 'Последний день месяца',
    };
    return props.data.map(item => {
        const dateFormat = 'DD.MM.YYYY';
        let date;

        if (item.activeTo) {
            date = `${moment(item.activeFrom).format(dateFormat)} - ${moment(item.activeTo).format(dateFormat)}`;
        } else {
            date = `${moment(item.activeFrom).format(dateFormat)}`;
        }

        return {
            id: item.id,
            active: item.active,
            name: item.name,
            author: item.authorName,
            authorId: item.authorId,
            periodicity: periodicityLabel[item.generationRule.type],
            taskType: item.type === 'COMMON' ? 'Общая' : item.type === 'PHOTO_REPORT' ? 'Фотоотчёт' : '',
            date,
        };
    });
});

const onPaginationUpdate = () => {
    emit('update:pagination', { page: page.value, limit: limit.value });
};

const onSortUpdate = () => {
    emit('update:sort', { field: sortBy.value, direction: sortDesc.value ? 'desc' : 'asc' });
};

const onRowClick = row => {
    emit(
        'click:row',
        props.data.find(item => item.id === row.id)
    );
};

const onCopyClick = row => {
    emit(
        'copy:row',
        props.data.find(item => item.id === row.id)
    );
};

watch(
    () => props.pagination,
    (newVal) => {
        page.value = newVal?.page || 1;
        limit.value = newVal?.limit || 1;
    },
    { immediate: true }
);

watch(
    () => props.sort,
    ({ field, direction }) => {
        sortBy.value = field;
        sortDesc.value = direction.toLowerCase() === 'desc';
    }
);
</script>

<template>
    <div>
        <custom-table
            id="tasks-generate-table"
            v-model:sort-by="sortBy"
            v-model:sort-desc="sortDesc"
            :columns="tableColumns"
            :items="items"
            :busy="loading"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            shadowed
            rounded
            @update:sort-by="onSortUpdate"
            @update:sort-desc="onSortUpdate"
            @row-click="onRowClick"
        >
            <template #name="{ row }">
                <template v-if="row.active">
                    {{ row.name }}
                </template>
                <template v-else>
                    <div>
                        <div class="-mb-1">{{ row.name }}</div>
                        <div class="-text-gray-moss">{{ row.active ? '' : '(неактивно)' }}</div>
                    </div>
                </template>
            </template>
            <template #actions="{ row }">
                <custom-button
                    v-if="isAdmin || row.authorId === account.id"
                    text="Скопировать"
                    theme="white"
                    class="-whitespace-nowrap"
                    @click.stop="onCopyClick(row)"
                />
            </template>
        </custom-table>

        <pagination-bar
            v-model:page="page"
            v-model:limit="limit"
            :page-count="totalPages"
            @update:limit="onPaginationUpdate"
            @update:page="onPaginationUpdate"
        />
    </div>
</template>

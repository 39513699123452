import Dexie from 'dexie';

export const db = new Dexie('fpr');

db.version(1).stores({
	users: 'id,displayName,firstName,lastName,login,level,divisions*,regions*,shops*,directorOfShops*,businessDirId',
	divisions: 'id,name',
	regions: 'id,name,divisionId',
	shops: 'id,locality,sap,clusterId',
});

<script setup>
import { computed } from 'vue';
import { SingleSelect } from '@eron/ui-kit';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const options = [
    {
        label: 'Ежедневно',
        value: 'daily',
    },
    {
        label: 'Еженедельно',
        value: 'weekly',
    },
    {
        label: 'Ежемесячно',
        value: 'monthly',
    },
    {
        label: 'Ежеквартально',
        value: 'quarterly',
    },
    {
        label: 'Раз в полгода',
        value: 'halfYearly',
    },
    {
        label: 'Последний день месяца',
        value: 'lastDayOfMonth',
    },
];
const selected = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:model-value', value);
    },
});
</script>

<template>
    <single-select :id="id" v-model="selected" :options="options" />
</template>

'use strict';

import { Notifications } from '@shared/notifications';

angular.module('smartApp').controller('AppController', function($timeout, Auth, $rootScope, $scope, $state, Principal, config, localStorageService) {
    $scope.$state = $state;
    $scope.loggedIn = false
    setTimeout(function(){
        //$scope.loggedIn = Principal.isAuthenticated();
    }, 500)

     $scope.$on("loginSuccessfull", function(event) {
        $scope.loggedIn = true
     })

     $scope.$on("criticalServerError", function(event) {
        $scope.criticalServerError = true;
     })

     $rootScope.$on("logout", function(event) {
        $scope.loggedIn = false;

         localStorageService.remove('token');
         localStorageService.remove('userPermissions');
     })

    $scope.logout = function () {
      $rootScope.$emit('logout')
        Auth.logout();
      $timeout(function () {
        if($scope.navbar)$scope.navbar.name = $scope.navbar.lastName = $scope.navbar.firstName = '';
        if($scope.navbar)$scope.navbar.authority = [];
      }, 0);
        $state.go('login');
    };

    // $scope.initGlobalClickEvent = function(event){
    //     $rootScope.$emit('globalClickEvent', event);
    // }

    $scope.allowedForRole = function(role) {
        return Principal.isInRole(role);
    };

    $scope.$on("templateSaveSuccess", function(event) {
        $.smallBox({
            title: "Анкета успешно сохранена",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("groupSaveSuccess", function(event) {
        $.smallBox({
            title: "Группа успешно сохранена",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("saveSuccess", function(event) {
        $.smallBox({
            title: "Ваши изменения успешно сохранены",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("counterToSmall", function(event) {
        $.smallBox({
            title : "Введенные показания неверны: меньше, чем за предыдущий период",
            content : "",
            color : "#C46A69",
            //timeout: 6000,
            timeout : config.notificationTimeout
        });
    });

	$scope.$on("noResultsFound", function(event) {
		$.smallBox({
			title : "По Вашему запросу ничего не найдено",
			content : "",
			color : "#C46A69",
			timeout : config.notificationTimeout
		});
	});

	$scope.$on("noDates", function(event) {
		$.smallBox({
			title : "По выбранным датам ничего не найдено",
			content : "",
			color : "#C46A69",
			timeout : config.notificationTimeout
		});
	});

    $scope.$on("clAccepted", function(event) {
        $.smallBox({
            title: "Чек-лист утвержден",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("nothingToSave", function(event) {
        $.smallBox({
            title : "Нечего сохранять!!",
            content : "",
            color : "#C46A69",
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("shopAdded", function(event) {
        $.smallBox({
            title: "Новый магазин добавлен",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("newChecklist", function(event) {
        $.smallBox({
            title: "Новый чек-лист успешно создан",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("deleteChecklist", function(event) {
        $.smallBox({
            title: "Чек-лист удален",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("deleteProcess", function(event) {
        $.smallBox({
            title: "Объект удален",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("newProcess", function(event) {
        $.smallBox({
            title: "Новый процесс успешно создан",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("newProcessGroup", function(event) {
        $.smallBox({
            title: "Новая группа процессов успешно создана",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("checklistSentToReview", function(event) {
        $.smallBox({
            title: "Чек-лист отправлен",
            content: "",
            color: "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on('showFillCountersNotification', function(event) {
        $.smallBox({
            title: "Сегодня среда: не забудьте снять показания счетчиков",
            content: "",
            color: "#739E73",
            timeout: null
        });
    });

    $scope.$on("checklistCanNotBeReviewed", function(event) {
        $.smallBox({
            title : "Есть непроверенные пункты!",
            content : "",
            color : "#C46A69",
            //timeout: 6000,
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("checklistCanNotBeAccepted", function(event) {
        $.smallBox({
            title : "Не все проблемы исправлены. Пожалуйста, исправьте все проблемы и повторите попытку",
            content : "",
            color : "#C46A69",
            //timeout: 6000,
            timeout : config.notificationTimeout
        });
    });

  $scope.$on("formNotFilled", function(event) {
        $.smallBox({
            title : "Не все поля заполнены правильно",
            content : "",
            color : "#C46A69",
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("createSuccess", function(event) {
        $.smallBox({
            title : "Добавление выполнено успешно",
            content : "",
            color : "#739E73",
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("deleteSuccess", function(event) {
        $.smallBox({
            title : "Удаление выполнено успешно",
            content : "",
            color : "#739E73",
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("deleteError", function(event, cant_delete_this_users) {
        $.smallBox({
            title : "Пользователей "+cant_delete_this_users+" нельзя удалить",
            content : "",
            color : "#C46A69",
            timeout : config.notificationTimeout
        });
    });

    $scope.$on("passwordsAreDifferent", function(event) {
        $.smallBox({
            title: "Пароли не совпадают",
            content: "",
            color: "#C46A69",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("cantSetCalendarCheck", function(event) {
        $.smallBox({
            title: "Проверку можно назначить на сегодняшний день или на следующий месяц",
            content: "",
            color: "#C46A69",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("checklistStartSuccess", function(event) {
        $.smallBox({
            title: "Проверка успешно начата",
            color : "#739E73",
            timeout: config.notificationTimeout
        });
    });

    $scope.$on("datesNotFilled", function(event) {
        $.smallBox({
            title: "Пропущены даты",
            color: "#C46A69",
            timeout: config.notificationTimeout
        });
    });
});

angular.module('smartApp').service('Notifications', function() {
    return Notifications;
});

angular.module('smartApp').controller('XfilterController6', XfilterController6);
XfilterController6.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService'];

function XfilterController6($scope, UserService, ChecklistReportService, PanelGroupsService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);

	$scope.fio = {selected:undefined};


	$scope.getUsersOnGroups = function(groups){
		$scope.fio.selected = undefined;
		var groupsArr = [];
		async.eachSeries(groups, function(item, callback){
			PanelGroupsService.getGroupInfo(item.id).success(function (data, status, headers) {
				if(status != 200){
					callback(data);
					return
				}
				groupsArr.push(data);
				callback(null, data)
			})
		}, function(err, msg){
			if(err){
				Notifications.danger('Ошибка получения групп');
				console.log(err);
				return;
			}
			var users = [];
			for(var i in groupsArr){
				users = users.concat(groupsArr[i].users);
			}
			
			// for(var j in users){
			// 	var auth = [];
			// 	for(var k in users[j].authorities){
			// 		auth.push(users[j].authorities[k].name)
			// 	}
			// 	console.log(users[j],auth )
			// 	//$scope.users.push()
			// }
			$scope.users = users;
		})
		// PanelGroupsService.getGroupInfo(id).success(function (data, status, headers) {
		// 	if(status != 200){
		// 		console.log("Error: "+data)
		// 		return;
		// 	}
		// 	console.log(data)
		// })
	}
    $scope.selectAllFio = function() {
        $scope.uiSelect.fio.selected = $scope.users;
    };
}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
angular.module('smartApp').factory('filterLogs', ['DownloadService', function(DownloadService) {
	return {
		filtersTemplate: 'scripts/app/checklist/clreports/filter-logs/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter-logs/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		download: function(filters) {
			return DownloadService.downloadLogsReport(filters);
		}
	};
}]);

<template>
    <div>
        <banner-block-creator
            v-if="banner.blocks.length < 9"
            :current-blocks="banner.blocks"
            @block-created="handleCreateBlock"
        ></banner-block-creator>

        <div class="row">
            <div class="col">
                <banner-content-item
                    v-for="unit in banner.blocks"
                    :item="unit"
                    :banner-id="banner.id"
                >
                </banner-content-item>
            </div>
        </div>
    </div>
</template>

<script>
import { CustomInput } from '@eron/ui-kit';
import BannerContentItem from './BannerContentItem.vue';
import BannerBlockCreator from './BannerBlockCreator.vue';

export default {
    name: 'BannerContent',
    components: {
        BannerBlockCreator,
        CustomInput,
        BannerContentItem,
    },
    props: {
        banner: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleCreateBlock(payload) {
            const block = {
                description: payload.description,
                id: 1,
                name: payload.block,
                platform: payload.platform,
                content: [],
            };

            if (block.name === 'main') block.id = 1;
            else if (block.name === 'pinnedtext') block.id = 2;
            else block.id = 3;

            this.banner.blocks.push(block);
        },
    },
};
</script>

<style scoped lang="scss">
.image-preview {
    width: 80px;
    height: 80px;
}
</style>

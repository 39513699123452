'use strict';

angular.module('smartApp').factory('NotificationService', function UserService($http, config) {

    return {
        getDirectorNotification: function (shopSap) {
            return $http.get(config.backend + '/notifications/getDirectorNotification?shopSap=' + shopSap)
                .success(function (data) {
                    return data;
                }
            );
        }
    }

});
'use strict';

angular.module('smartApp').controller('TaskTemplateEditController', function(
	$scope,
	taskTemplate
	) {

	$scope.taskTemplate = taskTemplate

});

(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('TasksDefaultFilterService', TasksDefaultFilterService);

    function TasksDefaultFilterService(Principal) {
        var service = {
            getRolesFilter: getRolesFilter,
            getStatusesFilter: getStatusesFilter,
            selectMultyValues: selectMultyValues
        };

        return service;

        function getRolesFilter() {
          var identity = Principal.getIdentity();

          var roles = [{
              title: 'Поручил',
              val: 'author',
              isSelected: true
          }, {
              title: 'Исполняю',
              val: 'executor',
              isSelected: true
          }, {
              title: 'Наблюдаю',
              val: 'observer',
              isSelected: false
          }];

          return roles;
        }

        function getStatusesFilter() {
            return [
                {
                    title: 'Новые',
                    val: 'OPEN',
                    isSelected: true
                },
                {
                    title: 'В работе',
                    val: 'IN_PROGRESS',
                    isSelected: true
                },
                {
                    title: 'Закрытые',
                    val: 'CLOSED',
                    isSelected: false
                }
            ];
        }

        function selectMultyValues(filterValues, filter, filterName) {
            var filterItems = [];
            filterValues.forEach(function(item) {
                if (item.isSelected) {
                    filterItems.push(item.val);
                }
            });
            if (filterItems.length) {
                filter[filterName] = filterItems.join();
            } else {
                delete filter[filterName];
            }
        }
    }

    TasksDefaultFilterService.$inject = ['Principal'];
})();

angular.module('smartApp').factory('AccreditationCategoryReport', accreditationCategoryReportFactory);
accreditationCategoryReportFactory.$inject = ['ChecklistService', 'DownloadService'];

function accreditationCategoryReportFactory(ChecklistService, DownloadService) {
    return {
        id: 2,
        title: 'Фильтр 7',
        resultTitle: 'Результаты отчета',
        filtersTemplate: 'scripts/app/checklist/clreports/filter7/filters.html',
        viewTemplate: 'scripts/app/checklist/clreports/filter7/view.html',
        isValid: function(filters) {
            return filters.dateFrom && filters.dateTo
        },
        load: function(filters){
            return ChecklistService.getAccreditationCategoryReport(filters);
        },
        download: function(filters){
            return DownloadService.downloadAccreditationCategoryReport(filters);
        }
    };
}
import { createStore } from 'vuex';
import inventory from "./stock/inventory/inventory";
import errors from "./stock/errors/errors";
import banners from './banners/banners'
import axios from "axios";

export default createStore({
    state: {
        accountData: null,
        pfmListFetchStatus: false,
        pfmList: [],
        lk: [],
        lkFetchStatus: false,
    },
    mutations: {
        setAccountData(state, data) {
            state.accountData = data;
        },
        setPfmList(state, payload) {
            state.pfmList = payload;
        },
        setPfmListFetchStatus(state, payload) {
            state.pfmListFetchStatus = payload;
        },
        setLkFetchStatus(state, payload) {
            state.lkFetchStatus = payload;
        },
        setLk(state, payload) {
            state.lk = payload;
        },
    },
    actions: {
        fetchAccountData({ commit }) {
            return new Promise((resolve) => {
                const AccountService = angular.element(document.body).injector().get('Account');
                AccountService.get().then(response => {
                    resolve(response);
                    commit('setAccountData', response.data);
                }).catch(error => {
                    console.error('Ошибка при получении данных аккаунта:', error);
                });
            })
        },
        fetchPfmList({ commit }) {
            commit('setPfmListFetchStatus', true);
            return new Promise((resolve) => {
                axios.get(`api/orgstruct/shops`, {
                    headers: {
                        "x-auth-token": JSON.parse(localStorage.getItem('ls.token')).token,
                    }
                }).then(response => {
                    resolve(response);
                    commit('setPfmList', response.data);
                }).catch(error => {
                    console.error('Error', error);
                }).finally(() => {
                    commit('setPfmListFetchStatus', false);
                })
            })
        },
        fetchLk({ commit }, query) {
            commit('setLkFetchStatus', true);
            return new Promise((resolve) => {
                axios.get(`api/goods/search?search=${query}&page=0&size=20`, {
                    headers: {
                        "x-auth-token": JSON.parse(localStorage.getItem('ls.token')).token,
                    }
                }).then(response => {
                    resolve(response);
                    commit('setLk', response.data);
                }).catch(error => {
                    console.error('Error', error);
                }).finally(()=> {
                    commit('setLkFetchStatus', false);
                })
            })
        },
    },
    getters: {
        accountData: state => state.accountData,
        getPfmList(state) {
            return state.pfmList;
        },
        getPfmListFetchStatus(state) {
            return state.pfmListFetchStatus
        },
        getLk(state) {
            return state.lk;
        },
        getLkFetchStatus(state) {
            return state.lkFetchStatus
        },
    },
    modules: {
        inventory,
        errors,
        banners,
    },
});
'use strict';

angular.module('smartApp').controller('ChecklistWizardController', function(Notifications, ObjectServiceForTemplates, DownloadService, ngDialog, ProcessGroupService, $rootScope, $scope, GroupShopService, TemplateService, CategoryService, config) {

    $scope.openCategories = [];
    $scope.disableCategories = [];
	$scope.config = config;


    var _this = $scope;
    var tempChecklist = {}; //Variable to store activeChecklist, so its not lost after save action

    $scope.downloadSuperTemplate = function () {
        DownloadService.downloadAdminTemplate($scope.activeChecklist.id);
    }

    $scope.recheckCategories = function() {
        angular.forEach($scope.categories, function(category, categoryKey) {
            category.active = false;
            angular.forEach(category.children, function(child1, child1Key) {
                if(!child1.group) {
                    if (!_.find($scope.activeChecklist.categories, {id: child1.id})) {
                        category.active = true;
                    }
                }
            });
        });
    };

    $scope.initCategories = function() {
        CategoryService.getAllRootGroups()
            .success(function(data) {
                $scope.categories = data;
                $scope.initChecklists();
            }
        );
    };

    $scope.initChecklists = function(){
        ObjectServiceForTemplates.query({asTree: true}, function(result) {
	        result = result.sort(function (a, b) {
	        	if(!a.name)return a;
		        return a.name.localeCompare(b.name);
	        });

            $scope.checklists = result;
            if(!angular.equals(tempChecklist, {})) {
	            var filter = {};
	            var fieldName = tempChecklist.id? 'id' : 'name';
	            filter[fieldName] = tempChecklist[fieldName];
                $scope.setActiveChecklist(_.find($scope.checklists, filter));
            } else{
                $scope.setActiveChecklist($scope.checklists[0]);
            }
        });
    };

    $scope.increaseOrder = function(category, index, scope, e) {
	    if(e) {
		    e.preventDefault();
		    e.stopPropagation();
	    }
	    var cat = _.find($scope.activeChecklist.categories, {
            orderInTmpl: (category.orderInTmpl - 100)
        });
        if(!cat) return;
	    cat.orderInTmpl = category.orderInTmpl;
        category.orderInTmpl = (category.orderInTmpl - 100);
        $scope.openCategories[index] = false;
        $scope.openCategories[index - 1] = false;
        $scope.disableCategories[index - 1] = true;
    };

    $scope.decreaseOrder = function(category, index, scope, e) {
	    if(e) {
		    e.preventDefault();
		    e.stopPropagation();
	    }
        var cat = _.find($scope.activeChecklist.categories, {
            orderInTmpl: (category.orderInTmpl + 100)
        });
	    if(!cat) return;
	    cat.orderInTmpl = category.orderInTmpl;
        category.orderInTmpl = (category.orderInTmpl + 100);
        $scope.openCategories[index] = false;
        $scope.openCategories[index + 1] = false;
        $scope.disableCategories[index + 1] = true;
    };

    $scope.deleteChecklist = function(cl) {
        TemplateService.deleteTemplate(cl)
            .then(function(e) {
		        if(e.status == 200) {
			        $rootScope.$broadcast("deleteChecklist");
		        }
                $scope.activeChecklist = undefined;
		        tempChecklist = {};
                $scope.initChecklists();
            });
    };


	$scope.deleteChecklistClick = function(cl) {
		ngDialog.open(
			{
				template: 'prepareAcceptStatement',
				controller: ['$scope', function($scope) {
					$scope.name = cl.name;
					$scope.confirm = function() {
						_this.deleteChecklist(cl);
					};
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};


	$scope.createChecklist = function() {
        ngDialog.open(
            {
                template: 'createChecklist',
                controller: ['$rootScope', '$scope', 'TemplateService', function($rootScope, $scope, TemplateService) {
                    $scope.createNewChecklist = function() {
	                    tempChecklist = {name: $scope.selectedChecklistName};
                        TemplateService.saveTemplate(tempChecklist)
                            .then(function(e) {
		                        if(e.status === 200) {
			                        $rootScope.$broadcast("newChecklist");
		                        }
                                _this.initChecklists();
                            });
                    }
                }],
                className: 'ngdialog-theme-plain',
                showClose: false
            }
        );
    };

    $scope.saveChecklist = function() {
	    if(angular.equals($scope.activeChecklist, tempChecklist)) {
		    Notifications.danger('Нечего сохранять_x');
		    return;
	    }
		var selfRootScope = $rootScope;
	    ngDialog.open(
		    {
			    template: 'prepareAcceptTemplateStatement',
			    controller: ['$rootScope', '$scope', 'TemplateService', function($rootScope, $scope, TemplateService) {
				    $scope.changes = _this.activeChecklist.changes;
				    $scope.confirm = function() {
					    TemplateService.saveTemplate(_this.activeChecklist)
						    .then(function (e) {
							    if(e.status === 200) {
								    selfRootScope.$broadcast("saveSuccess");
							    }
							    _this.initChecklists();
							    _this.editingChecklistName = false;
						    });
				    };
			    }],
			    className: 'ngdialog-theme-plain dialog',
			    showClose: false
		    }
	    );
    };

    $scope.reindexCategories = function() {
        angular.forEach($scope.activeChecklist.categories, function(category, categoryKey) {
            category.orderInTmpl = (categoryKey + 1) * 100;
        });
    };


	$scope.categoryExists = function (activeCategories, newCategory) {
		var result = activeCategories.filter(function (category) {
			return (category.original && category.original.id === newCategory.id) || (category.id === newCategory.id);
		});

		return result.length ? result[0] : null;
	};

    $scope.addGroupToChecklist = function(cl) {
	    cl.children = cl.children.sort(function (x, y) {
		    return x.orderInTmpl - y.orderInTmpl;
	    });

        angular.forEach(cl.children, function(child1, child1Key) {
            if(!child1.group && !$scope.categoryExists($scope.activeChecklist.categories, child1)) {
                $scope.activeChecklist.categories.push(child1);
	            $scope._setChanges(child1, 'added');
            } else {
                angular.forEach(child1.children, function (child2, child2Key) {
                    if(!$scope.categoryExists($scope.activeChecklist.categories, child1)) {
                        $scope.activeChecklist.categories.push(child2);
	                    $scope._setChanges(child1, 'added');
                    }
                })
            }
            $scope.reindexCategories();
            $scope.recheckCategories();
        });
    };

    $scope.saveChecklistParams = function(){
    	TemplateService.saveTemplate($scope.activeChecklist).success(function(data) {
    		if(data)Notifications.success('Параметры сохранены');
    	})
    }

    $scope.setActiveChecklist = function(cl) {
	    if(!cl) return;
        TemplateService.getTemplateByIdNew(cl.id).success(function(data) {
            $scope.activeChecklist = data || cl;
	        tempChecklist.changes = [];
            angular.copy($scope.activeChecklist, tempChecklist);
	        $scope.activeChecklist.changes = [];
            $scope.recheckCategories();
        });
    };

	$scope._setChanges = function (categories, action) {
		if(!categories) return;
		action = action || 'change';
		if(!angular.isArray(categories)) categories = [categories];
		var changedCategories = JSON.parse(JSON.stringify(categories));
		angular.forEach(changedCategories, function (category) {
			category.action = action;
			var exists = $scope.categoryExists($scope.activeChecklist.changes, category);
			if(!exists) {
				$scope.activeChecklist.changes.push(category);
			} else {
				if(action !== 'deleted') {
					exists.action = 'change';
					exists.new = category;
				} else {
					exists.action = action;
				}
			}
		});
	};

	$scope.removeAllCategory = function () {
		ngDialog.open(
			{
				template: 'prepareAcceptCategoryStatement',
				controller: ['$scope', function($scope) {
					$scope.confirm = function() {
						_this._setChanges(_this.activeChecklist.categories, 'deleted');
						_this.activeChecklist.categories = [];
						_this.reindexCategories();
						_this.recheckCategories();
					};
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			}
		);
	};

    $scope.removeCategory = function(cl, e) {
	    if(e) {
		    e.preventDefault();
		    e.stopPropagation();
	    }
	    ngDialog.open(
		    {
			    template: 'prepareAcceptCategoryStatement',
			    controller: ['$scope', function($scope) {
				    $scope.name = cl.name;
				    $scope.confirm = function() {
					    _this.activeChecklist.categories = _.without(_this.activeChecklist.categories, _.findWhere(_this.activeChecklist.categories, cl));
					    _this._setChanges(cl, 'deleted');
					    _this.reindexCategories();
					    _this.recheckCategories();
				    };
			    }],
			    className: 'ngdialog-theme-plain',
			    showClose: false
		    }
	    );
    };

    $scope.showHint = function(hint) {
        $.SmartMessageBox({
            title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Подсказка <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span>",
            content : hint,
            buttons : '[OK]'
        });
    };

	$scope.showHintImage = function (question, field) {
		field = field ? 'questionRightImage' : 'questionWrongImage';
		$.SmartMessageBox({
			title : '<img/><div class="image" style="background:url(' + config.pathToPhoto + question[field].name + ');"></div>',
			buttons : '[OK]'
		});
	};

    $scope.initCategories();

});
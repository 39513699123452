angular.module('smartApp').factory('SettingsCleaningService', function SettingsCleaningService($http, config) {
    return {
        getFormQuestions: function (params) {
            return $http.get(config.backendV2 + "/form/questions", {params: params});
        },

        getFormTemplateById: function (formTemplateId) {
            return $http.get(config.backendV2 + "/form/templates/" + formTemplateId);
        },

        createFormQuestion: function (formTemplateId, params){
            return $http.post(config.backendV2 + "/form/templates/" + formTemplateId + "/questions", params);
        },

        updateFormQuestion: function(questionId, params){
            return $http.put(config.backendV2 + "/form/questions/" + questionId, params);
        },

        deleteFormQuestions: function(questionIds){
            return $http.delete(config.backendV2 + "/form/questions/" + questionIds);
        },

        getAnswerTypes: function(){
            return $http.get(config.backendV2 + "/form/directory/forms/questions/answers/types");
        }
    }

});
'use strict';

var PanelShopsService = function($http, config, utils) {
    return {
        getAll: function(page, limit, search, showAll, hasNoThrobber) {
            var params = {
                page: page,
                per_page: limit,
                search: search,
                showAll: showAll
            };
            var query = utils.getQuery('/admin/shops', params);
            return $http.get(query, { noThrobber: hasNoThrobber });
        },

        getAllFiltered: function(page, limit, filters, sort, xls) {
            var query;
            var sendFilters = {};

            Object.keys(filters).forEach(key => {
              switch (key) {
                case 'clusterObjs':
                  sendFilters.clusters = filters.clusterObjs.map(cur => cur.id);
                  break;
                case 'divisionObjs':
                  sendFilters.divisions = filters.divisionObjs.map(cur => cur.id);
                  break;
                case 'statusIds':
                  sendFilters.statuses = filters.statusIds.map(cur => cur.id);
                  break;
                default:
                  sendFilters[key] = filters[key];
                  break;
              }
            });

            var params = {
                page: page,
                perPage: limit,
                sorts: sort,
                filters: sendFilters
            };

            query = config.backend + '/admin/shops_new';

            if (xls) {
                query = query + '/xls';

                return $http.post(query, params, {
                responseType: 'arraybuffer',
                                headers: {
                    'Accept': 'application/vnd.ms-excel'
                }});
            }
            return $http.post(query, params);
        },

        getById: function(id, hasNoThrobber) {

            var query = config.backend + '/admin/shops/' + id;
            return $http.get(query, {
                noThrobber: hasNoThrobber
            });
        },
        createShop: function(shop) {
            return $http.post(config.backend + '/admin/shops/', shop);
        },
        update: function(shop) {
            return $http.put(config.backend + '/admin/shops/', shop);
        },
        getUsersByRole: function(role, params, hasNoThrobber) {

            return $http.get(config.backend + '/account/getUsersByRole', {
                params: Object.assign(params || {}, {
                    userRole: role
                }),
                noThrobber: hasNoThrobber
            });
        },
        getShopFormats: function() {
            return $http.get(config.backend + '/objects/shops/formats');
        },
        getShopRegions: function() {
            return $http.get(config.backend + '/objects/shops/regions');
        },
        getShopStatuses: function() {
            return $http.get(config.backend + '/objects/shops/statuses');
        },
        getShopSubFormats: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/shops/subFormats', {
                noThrobber: hasNoThrobber
            });
        },
        getShopSubFilials: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/shops/subFilials', {
                noThrobber: hasNoThrobber
            });
        },
        getAllClusters: function() {
            return $http.get(config.backend + '/objects/clusters');
        },
        getShopSupervisors: function(idShop) {
            return $http.get(
                config.backendOrgstruct + '/shops/' + idShop + '/supervisors'
            );
        },
        getSupervisorsAvailable: function(idShop) {
            return $http.get(
                config.backendOrgstruct +
                    '/shops/' +
                    idShop +
                    '/supervisors/available'
            );
        }
    };
};

angular.module('smartApp').factory('PanelShopsResource', PanelShopsService);

PanelShopsService.$inject = ['$http', 'config', 'HttpUtils'];

angular.module('smartApp').controller('TemplateWizardEditorController', function($state, $rootScope, TWQuestionService, Notifications, ngDialog, TWTemplateService, $scope, $stateParams, $filter, $q, OrgstructService, allUsers, allBusinessDirs, SystemParamsService) {

    var systemProps = [];

    $scope.viewType = ($state.current.name == "templateWizard.editor.zones") ? "zone" : "process";

    var paramsArr = ['daysForAction',
        'check.interval'];

    SystemParamsService.getSystemProps(paramsArr.map(function(el){return "checklist."+el}), function(err, params){
        if(err){
            Notifications.danger('Ошибка получения данных');
            cb(result.status)
            console.log("Err: ", err);
            return
        }

        systemProps = params

        init();
    })



function init (){
	var zonesInUse = [];
    TWTemplateService.getTemplate($stateParams.id, function (err, data) {
		var _this = $scope;

		$scope.template = data;
		$scope.allUsers = allUsers.data;
		$scope.allBusinessLines = allBusinessDirs.data;

		$scope.template.editName = angular.copy($scope.template.name)
		$scope.checkedQuestions = [];
		$scope.questionsOriginalIds = [];
        $scope.selectedZone = {};
        $scope.visibleProcesses = true;

		var arr = [];
		var categoryCounter = -1;
		var questionsObj = {};
		for(var i in $scope.template.questions){
			var question = $scope.template.questions[i];

			if(!question.categoryName)question.categoryName = "Name"+question.categoryId;
			if(question.routeToBusinessDirId || question.routeToUserId){
				(question.routeToBusinessDirId) && (question.routeBusinessLine = $filter('filter')($scope.allBusinessLines, {'id':question.routeToBusinessDirId})[0]);
                (question.routeToUserId) && (question.routeUser = $filter('filter')($scope.allUsers, {'id':question.routeToUserId})[0]);
			}
            if(!question.daysForAction)question.daysForAction = systemProps["checklist.daysForAction"]

			var index = _.findIndex(arr, {id: question.categoryId});
			if(index === -1){
				categoryCounter++;
				arr[categoryCounter] = {
					name: question.categoryName,
					id: question.categoryId,
					questions: []
				}
                arr[categoryCounter].questions.push(question)
			} else {
                arr[index].questions.push(question)
            }

			//fill zonesInUse array
			if(!~zonesInUse.indexOf(question.zoneId) && question.zoneId != 0) {
                zonesInUse.push(question.zoneId);
			}
			questionsObj[question.id] = question;
			$scope.questionsOriginalIds.push(question.originalId)
		}
		$scope.categories = arr;

        $scope.categories.map(function(category){
            return category.visible = true;
        });

        getZones();

		$scope.$watch('viewType', function(newVal, oldVal){
			if(newVal != oldVal){
				if(newVal == "zone")$state.go("templateWizard.editor.zones", $scope.template.id);
				if(newVal == "process")$state.go("templateWizard.editor", $scope.template.id)
			}
		});

		$scope.models = {
        	selected: null,
        	dragging:false,
        	data:[]
        };

        $scope.showType = $filter('lowercase')($scope.template.viewMode);

        function getZones() {
            TWTemplateService.getZones(function (err, result) {
                $scope.dataZones = result.map(function(el){
                	el["nameOrigin"] = el.name;
                	if(!!~zonesInUse.indexOf(el.id)){
                		el.inUse = true;
                	}
                	return el;
                });

            })
        }

        var zoneObj;
        var questionObj = {};

        function createObjectZone(element1, element2) {
            return {
                type: "container",
                id: angular.copy(element1),
                name: angular.copy(element2),
                columns: [[]]
            }
        }

        for (var i in $scope.template.questions) {

            if (!zoneObj || (zoneObj && (zoneObj.id !== $scope.template.questions[i].zoneId))) {
                zoneObj = createObjectZone($scope.template.questions[i].zoneId, $scope.template.questions[i].zoneName);
                $scope.models.data.push(zoneObj);
            }

            questionObj.type = 'item';
            questionObj.id = angular.copy($scope.template.questions[i].id);
            questionObj.name = angular.copy($scope.template.questions[i].name);
            questionObj.selected = false;

            zoneObj.columns[0].push(angular.copy(questionObj));
        }

		//console.log("$scope.models.data", $scope.models.data);
        $scope.dragend = function (e, v) {
            angular.element("[itemId=" + v.id + "]").removeClass("selected")
            v.selected = false
        }

        $scope.insertItem = function (item, container, modelsData, list, index) {
            //Да прибудет с вами сила, если вы будете это все разбирать дальше
            var modelesData = angular.copy(modelsData);
            $scope.moveToZone(item.id, container.id, modelesData, index);
        };


		$scope.getSelectedQuestions = function(){
			var result = [];
			var zones = angular.copy($scope.models.data)

			for(var i in zones){
				for(var j in zones[i].columns[0]){
					if(zones[i].columns[0][j].selected)result.push([zones[i].columns[0][j].id, zones[i].id, zones[i].columns[0][j]])
				}
			}
			delete zones;
			return result;
		}

		$scope.clearSelectedQuestions = function(){
			for(var i in zones){
				for(var j in zones[i].columns[0]){
					if(zones[i].columns[0][j].selected)delete zones[i].columns[0][j].selected
				}
			}
			$scope.selectedQuestions = [];
		}

		$scope.emptyZoneIsClear = function(){
			var result = [];
			var zones = angular.copy($scope.models.data)
			for(var i in zones){
				if(zones[i].id == 0){
					return zones[i].columns[0].length == 0;
					break;
				}
			}
			delete zones;
			return true;
		}


        $scope.setCollapsed = function(item, e){
            e.preventDefault();
            e.stopPropagation();
            item.collapsed =!item.collapsed
        }


        // I'm afraid of this
        // оставь надежду, всяк сюда влезающий
        $scope.moveToZone = function (moveItemId, containerId, modelsData, moveIndex) {

            var selectedQuestions = [];
            var moveIdZone;
            var questionsInZone;

            if (!moveItemId) {
                selectedQuestions = $scope.getSelectedQuestions();
                selectedQuestions = selectedQuestions.map(function (elem) {
                    return elem[0];
                });
            } else {
                selectedQuestions.push(moveItemId);
            }

            if (!containerId) {
                moveIdZone = $scope.selectedZone.select && $scope.selectedZone.select.id ? $scope.selectedZone.select.id : 0
            } else {
                moveIdZone = containerId;
            }
            var questionsOrder = [];

            if(modelsData) { //если перемещение вопроса drag and drop
                var zones = angular.copy(modelsData); //modelsData содержит элемент и в старой позиции и в новой

                for (var i in zones) {
                	questionsInZone = zones[i].columns[0];
                    for (var j = 0; j < questionsInZone.length; j++) {

                        // эта штука от безысходности
                        if (questionsInZone[j].id && (questionsInZone[j].id !== moveItemId || (questionsInZone[j].id === moveItemId && zones[i].id === moveIdZone && moveIndex === j ))){ //оставляем элемент только в новой позиции
                        	questionsOrder.push(questionsInZone[j].id);
                        }
                    }
                }
            }else{ //если перемещение вопроса через выпадающее меню
                var zones = angular.copy($scope.models.data);
				var flagZoneFind = false;

                for (var i in zones) {
                    questionsInZone = zones[i].columns[0];
                    for (var j = 0; j < questionsInZone.length; j++) { //удаляем выбранные элементы из массивов вопросов в зонах из старых позиций
                        for (var k in selectedQuestions) {
                            if (questionsInZone[j].id && (questionsInZone[j].id === selectedQuestions[k])) {
                                questionsInZone.splice(j, 1);
                            }
                        }
                        if (questionsInZone[j]) questionsOrder.push(questionsInZone[j].id);
                    }

                    if (zones[i].id == moveIdZone && !flagZoneFind){ //добавляем выбранные элементы в выбранную зону в конец списка
                    	flagZoneFind = true;
                        questionsOrder = questionsOrder.concat(selectedQuestions);
                    }
                }

                if (!flagZoneFind){ //для новой зоны
                    questionsOrder = questionsOrder.concat(selectedQuestions);
				}
			}
            //console.log("questionsOrder!!", questionsOrder);

            TWTemplateService.questionsInZone(selectedQuestions, moveIdZone, function (err, result) {
                if (err) {
                    console.log(err);
                    Notifications.danger("Не удалось сделать перемещение");
                    $scope.selectedZone = {};
                } else {
                    Notifications.success("Перемещение выполнено");
                    if (questionsOrder.length !== 0) {
                        TWTemplateService.saveQuestionsOrderInTemplate($scope.template.id, questionsOrder, function (err) {
                            //list.loading = false;
                            if (err) {
                                Notifications.danger("Ошибка изменения сортировки");
                                //  $state.reload();
                            }
                            init();
                        });
                    }else{
                        init();
                    }
                }
            });
            // $scope.selectedZone = {};

        }

        $scope.dropCallback = function(index, item, modelsData) {
            var modData = angular.copy(modelsData);
            var questionsOrder = [];
            for (var i in modData) {
                for (var j = 0; j < modData[i].columns[0].length; j++) {
                    if (modData[i].columns[0][j].id) {
                        questionsOrder.push(modData[i].columns[0][j].id);
                    }
                }
            }
            if (questionsOrder.length) {
                TWTemplateService.saveQuestionsOrderInTemplate($scope.template.id, questionsOrder, function (err) {
                    if (err) {
                        Notifications.danger("Ошибка изменения сортировки зон");
                    }
                    Notifications.success("Сортировка сохранена");
                    init();
                });
            }else{
                init();
            }
            return item;
        };

        // $scope.saveZones = function(){
        // 	var obj = {
        // 		templateId: $scope.template.id,
        // 		type: $scope.showType,
        // 		zones: []
        // 	}
        //
        // 	var zones = angular.copy($scope.models.data);
        // 	for(var i in zones){
        // 		var zone = zones[i];
        //
        // 		zone.zoneId = zone.id;
        // 		zone.zoneOrder = +i;
        // 		zone.zoneName = (zone.name)?zone.name: "Зона "+zone.id;
        //
        // 		var counter = 0;
        // 		zone.questions = zone.columns[0].map(function(el){
        // 			el.questionId = el.id;
        // 			el.position = counter;
        // 			delete el.type;
        // 			delete el.id;
        // 			delete el.name;
        // 			counter++;
        // 			return el;
        // 		})
        // 		delete zone.name;
        // 		delete zone.id;
        // 		delete zone.type;
        // 		delete zone.columns;
        // 		obj.zones.push(zone)
        // 	}
        //
        // 	TWTemplateService.saveZones(obj, function(err, data){
        // 		if(err){
        // 			console.log(err)
        // 			Notifications.danger("Данные потеряны :(");
        // 			return;
        // 		}
        // 		Notifications.success("Изменения сохранены");
        // 		$state.reload();
        // 	})
        // }

        $scope.switchCategory = function(category, event){
            // ангуляр криво воспринимает ng-diabled у кнопки, если в ней есть ещё один контейнер и блокирует евент на самой кнопке, но пропускает его дальше
            // Поэтому приходится ловить евент и ничего не делать в случае клика по задизабленной кнопке
            // у i в кнопке (по нему приходится клик) неду дочерних элементов, поэтому такая проверка имеет право на существование
            if(event.target.childNodes.length != 0)category.visible = !category.visible;

            if(!$scope.categories.filter(function(el){return el.visible}).length)$scope.visibleProcesses = false
            if($scope.categories.filter(function(el){return el.visible}).length == $scope.categories.length)$scope.visibleProcesses = true

        }

        $scope.changeMode = function (typeView) {
            TWTemplateService.viewModeTemplates($scope.template, typeView, function (err, result) {
                if (err) {
                    console.log(err);
                    return;
                }
                $scope.showType = typeView;
            });
        };

        $scope.visibleAllProcesses = function (value) {
            console.log($scope.visibleProcesses. value)
            $scope.visibleProcesses = !$scope.visibleProcesses;

            for(var i = 0; i < $scope.categories.length;i++){
               $scope.categories[i]["visible"] = value
            }
        };

		$scope.switchTemplate = function(event) {
            if (!_.includes(event.target.className, 'active')) {
			    TWTemplateService.switchTemplate($scope.template, function(err, result) {
			    	if(err) {
			    	    return;
                    }
                    $scope.template.active = !$scope.template.active;
			    });
            }
		};

		$scope.saveName = function(){
			var tTemplate = angular.copy($scope.template)
			tTemplate.name = tTemplate.editName
			TWTemplateService.saveTemplate(tTemplate, function(){
				if(err){
					console.log(err, result)
					Notifications.danger("Ошибка сохранения")
					return;
				}
				$scope.template.name = $scope.template.editName
			})
		}

		$scope.saveQuestionParam = function(param, question){
			question.loading = true;
			TWQuestionService.saveQuestion(question, function(err, result){
				delete question.loading
				if(err){

					question[param] = !question[param]
					console.log(err, result)
					Notifications.danger("Ошибка сохранения")
					return;
				}

			})
		}

		$scope.saveQuestionControlType = function(question){
			if(question.controlType == question.controlTypeEdit)return;
			question.loading = true;
			console.log(question.controlType, question.controlTypeEdit)
			var tQ = angular.copy(question)
			tQ.controlType = tQ.controlTypeEdit;
			TWQuestionService.saveQuestion(tQ, function(){
				delete question.loading
				if(err){
					console.log(err, result)
					Notifications.danger("Ошибка сохранения")
					return;
				}
				question.controlType = question.controlTypeEdit
			})
		}

        // TODO: create common metod for saving inputs when third one will appear. koryan

		$scope.saveQuestionWeight = function(question){
			question.loading = true;
			var tQ = angular.copy(question);
			tQ.weight = tQ.weightEdit;
			TWQuestionService.saveQuestion(tQ, function(){
				delete question.loading
				if(err){
					console.log(err, result)
					Notifications.danger("Ошибка сохранения")
					return;
				}
				question.weight = question.weightEdit;
			})
		}

        $scope.saveDaysForAction = function(question){
            question.loading = true;
            var tQ = angular.copy(question)

            //tQ.daysForAction = tQ.daysForActiontEdit;
            console.log(tQ)
            TWQuestionService.saveQuestion(tQ, function(){
                delete question.loading
                if(err){
                    console.log(err, result)
                    Notifications.danger("Ошибка сохранения")
                    return;
                }
                question.daysForActionEdit =  question.daysForAction = tQ.daysForAction
            })
        }

		var saveSort =function(cb){
			var arr = [];
			for(var i in $scope.categories){
				for(var j in $scope.categories[i].questions){
					if($scope.categories[i].questions[j].id)arr.push($scope.categories[i].questions[j].id)
				}
			}
			TWTemplateService.saveQuestionsOrderInTemplate($scope.template.id, arr, cb)
		}

		$scope.sortArr = function(list, elem, direction){
			list.loading = true;
			for(var i = 0; i < list.length; i++){
				if(elem.id == list[i].id){
					var j = i;
					if(direction){ //наверх
						j--;
					}else{//вниз
						j++;
					}

					var t1 = angular.copy(list[j]);
					list[j] = angular.copy(list[i])
					list[i] = t1;
					break;
				}

			}
			saveSort(function(err){
				//list.loading = false;
				if(err){
					Notifications.danger("Ошибка изменения сортировки")
					$state.reload();
				}
			})
		}



		$scope.sortCategory = function(category, direction){
			$scope.sortArr($scope.categories, category, direction)
		}

		$scope.sortQuestion = function(question, direction, questions){
			$scope.sortArr(questions, question, direction)
		}

		$scope.showAddExistingQuestion = function(){

			ngDialog.open({
				template: 'scripts/app/constructor/templateWizard/addExistingQuestionTpl.html',
				controller: 'addExistingQuestionController',
				className: 'ngdialog-theme-plain dialog-extra-wide',
				scope: $scope,
                showClose: true
			})
		}

		$scope.showAddNewQuestion = function(){

			ngDialog.open({
				template: 'scripts/app/constructor/templateWizard/addNewQuestionTpl.html',
				controller: 'addNewQuestionController',
				className: 'ngdialog-theme-plain dialog-extra-wide',
				scope: $scope,
                showClose: true
			 })
		}

		$scope.removeQuestion = function(question){
			_scope = $scope;
			ngDialog.open({
				template: 'deleteQuestion',
				controller: ['$scope', function($scope) {
					$scope.name = question.name;
					$scope.templateName = _scope.template.name;
					$scope.confirm = function(){
						question.active = false;
						TWQuestionService.remove(question.id, function(err, data){
							if(err){
								Notifications.danger("Ошибка удаления вопроса")
								return
							}
							$state.reload();
						})

						//_scope.questions = _scope.questions.filter(function(el){console.log(el, question.id);return (el.id != question.id)?true:false})

					}

				}],
				className: 'ngdialog-theme-plain dialog',
				showClose: false
			})
		}



		$scope.showDelete = function(){
			var tpl = $scope.template;
			ngDialog.open({
				template: 'confirmDelete',
				controller: ['$scope', function($scope) {
					$scope.name = tpl.name;
					$scope.delete = function(){
						_this.deleteTemplate(tpl.id);
					}
				}],
				className: 'ngdialog-theme-plain',
				showClose: false
			});
		};


		$scope.deleteTemplate = function(templateId) {
			TWTemplateService.deleteTemplate(templateId, function(err, result) {
				if(err) {
					Notifications.danger("Ошибка удаления шаблона");
					console.log(err);
					return;
				}
				$rootScope.back();
			});
		};



        // $scope.addZone = function(){
        // 	var maxId = 0;
        // 	$scope.models.data.map(function(el){if(el.id >= maxId){maxId = el.id + 1}})
        // 	console.log("maxId: "+maxId)
        // 	$scope.models.data.unshift({type: "container", id: maxId, columns: [[]]})
        // };

        $scope.createTemplate = function(nameZone) {
            TWTemplateService.addZone({name: nameZone}, function (err, result) {
                    if (err) {
                        Notifications.danger("Не удалось добавить зону");
                        console.log(err);
                        return;
                    } else {
                        Notifications.success("Зона добавлена");
                        $scope.models.data.unshift({type: "container", id: result.id, name: result.name, columns: [[]]});
                        getZones();
                    }
                    // init();
                }
            )
        };

        $scope.addZone = function(){
            ngDialog.open(
                {
                    template: 'scripts/templates/zoneWizard/ng-dialog-create-template.html',
                    className: 'ngdialog-theme-plain',
                    scope: $scope
                }
            )

        };

        $scope.saveNameZone = function(id, newName){
            TWTemplateService.renameZone({"id": id, "name": newName}, function(err, result){
                if (err) {
                    Notifications.danger("Изменения не сохранены");
                    console.log(err);
                    return;
                }
                Notifications.success("Изменения сохранены");
                getZones();
                $scope.models.selected = null;

            })
        };

        // 	$scope.deleteZone = function(zone){
        // 		$scope.models.data = _scope.models.data.filter(function(el){
        // 			if(el.id != zone.id){
        // 				return true;
        // 			}
        // 		})
        // 		// var _scope = $scope;
        // 		// ngDialog.open({
        // //              template: 'deleteZone',
        // 		// 	controller: ['$scope', function($scope) {
        // 		// 		$scope.name = (zone.name)?zone.name:"Зона "+zone.id;
        // 		// 		$scope.questionNum = (zone.colomns)?zone.colomns.length:false;
        // 		// 		$scope.templateName = _scope.template.name;
        // 		// 		console.log(zone)
        // 		// 		$scope.confirm = function(){
        //
        // 		// 			_scope.models.data = _scope.models.data.filter(function(el){
        // 		// 				if(el.id != zone.id){
        // 		// 					return true;
        // 		// 				}
        // 		// 			})
        //
        // 		// 		}
        //
        // 		// 	}],
        // 		// 	className: 'ngdialog-theme-plain dialog',
        // 		// 	showClose: false
        // //          });
        // 	}
    });
}
})

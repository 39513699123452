(function() {
    'use strict';

    angular.module('smartApp').config(tasksRouter);

    tasksRouter.inject = ['$stateProvider', '$urlRouterProvider'];

    function tasksRouter($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('/tasks/new', '/tasks/new/general/');
        $urlRouterProvider.when('/tasks', '/tasks/all');

        $stateProvider
            .state('tasks', {
                parent: 'site',
                url: '/tasks',
                params: {
                    taskParams: {
                        value: null
                    }
                },
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_DIRECTOR',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ],
                    css: ['css/states/tasks.css']
                },
                resolve: {
                    userPermissions: ['$q', 'TaskListServiceHttp', 'Notifications', function($q, TaskListServiceHttp, Notifications) {
                        var q = $q.defer();
                        TaskListServiceHttp.getUserPermissions()
                            .then(function(res) {
                                q.resolve(res.data);
                            })
                            .catch(function(err) {
                                  // Notifications.danger('Произошла ошибка в загрузке данных!');
                                    console.error(err)

                                q.reject(err);
                            });
                        return q.promise;
                    }],
                    systemParams: ['$q', 'SystemParamsService', 'Notifications', function($q, SystemParamsService, Notifications) {
                        var q = $q.defer();
                        var tasksParams = [
                            'tasks.manual.attachments.show',
                            'tasks.manual.enableDeadlineDateControl.forAuthor',
                            'task.crm.baseurl'
                        ];
                        SystemParamsService.getSystemProps(
                            tasksParams,
                            function(err, params) {
                                if (err) {
                                    // Notifications.danger('Произошла ошибка в загрузке данных!');
                                    console.error(err)
                                    q.reject(err);
                                } else {
                                    q.resolve(params);
                                }
                            }
                        );
                        return q.promise;
                    }]
                }
            })
            .state('tasks.all', {
                url: '/all',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template:
                            '<tasks user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.checklists', {
                url: '/checklists?templateId&shopId&checklistId',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="ROUTED" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.common', {
                url: '/common',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="COMMON" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.photo', {
                url: '/photo',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="PHOTO_REPORT" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.form', {
                url: '/form',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="FORMS" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.recount', {
                url: '/recount',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="MANUAL_RECOUNT,AUTO_RECOUNT" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            }).state('tasks.equipment', {
                url: '/equipment',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="EQUIPMENT" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.unsold', {
                url: '/unsold',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="UNSOLD_GOODS" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.expirationDate', {
                url: '/expirationDate',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="EXPIRATION_DATE_ADD,EXPIRATION_DATE_CHECK" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.new', {
                url: '/new',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<task-new></task-new>'
                    }
                }
            })
            .state('tasks.new.general', {
                url: '/general/:newItem',
                views: {
                    taskNewTab: {
                        template:
                            '<task-new-form is-periodic="false" user-permissions="$resolve.userPermissions"></task-new-form>'
                    }
                }
            })
            .state('tasks.new.periodic', {
                url: '/periodic/',
                views: {
                    taskNewTab: {
                        template:
                            '<task-new-form is-periodic="true" user-permissions="$resolve.userPermissions"></task-new-form>'
                    }
                }
            })
            .state('tasks.taskFullView', {
                url: '/fullView/:id',
                views: {
                    'content@': {
                        template:
                            '<task-full-view user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></task-full-view>'
                    }
                }
            })
            .state('tasks.meters', {
                url: '/meters',
                data: {
                    roles: [
                        'ROLE_ADMIN',
                        'ROLE_SUPERVISOR',
                        'ROLE_CURATOR',
                        'ROLE_DIRECTOR_CLUSTER',
                        'ROLE_OPERATION_DIRECTOR',
                        'ROLE_TASK_MANAGER',
                        'ROLE_SUPER_SUPERVISOR'
                    ]
                },
                views: {
                    'content@': {
                        template: '<tasks type="METER" user-permissions="$resolve.userPermissions" system-params="$resolve.systemParams"></tasks>'
                    }
                }
            })
            .state('tasks.meterValues', {
                url: '/meters/:id',
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/tasks/meters/meters.view.html',
                        controller: 'MetersController'
                    }
                }
            })
            .state('tasks.recountTask', {
                url: '/recount/:id',
                views: {
                  'content@': {
                    templateUrl: 'scripts/app/tasks/recount-task/recount-task.view.html',
                    controller: 'RecountTaskController'
                  }
                }
            }).state('tasks.equipmentTask', {
                url: '/equipment/:id',
                views: {
                  'content@': {
                    templateUrl: 'scripts/app/tasks/equipment-task/equipment-task.view.html',
                    controller: 'EquipmentTaskController'
                  }
                }
            }).state('tasks.formFullView', {
              url: '/formFullView/:id',
              views: {
                'content@': {
                  templateUrl: 'scripts/app/tasks/form-full-view/formFullView.view.html',
                  controller: 'FormFullViewController'
                }
              }
            })
            .state('tasks.unsoldGoods', {
                url: '/unsoldGoods/:id',
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/tasks/unsold-goods/unsold-goods.view.html',
                        controller: 'UnsoldGoodsController'
                    }
                }
            })
            .state('tasks.expirationDateAdd', {
                url: '/expirationDateAdd/:id',
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/tasks/expiration-date-add/expiration-date-add.view.html',
                        controller: 'ExpirationDateAddController'
                    }
                }
            })
            .state('tasks.expirationDateCheck', {
                url: '/expirationDateCheck/:id',
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/tasks/expiration-date-check/expiration-date-check.view.html',
                        controller: 'ExpirationDateCheckController'
                    }
                }
            })
            .state('tasks.photoReportCheck', {
              url: '/photoReportCheck/:id',
              views: {
                'content@': {
                  templateUrl: 'scripts/app/tasks/photoReportCheck/photoReportCheck.html',
                  controller: 'PhotoReportCheckController'
                }
              },
              data: {
                css: ['css/states/photoReportCheck.css']
              }
            });

    }
})();

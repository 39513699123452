angular.module('smartApp').factory('Filter2', checkReportFactory2);
checkReportFactory2.$inject = ['ChecklistService', 'DownloadService' ,'Principal'];

function checkReportFactory2(ChecklistService, DownloadService, Principal) {
	return {
		id: 2,
		title: 'Фильтр 2',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filter2/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filter2/view.html',
		isValid: function(filters) {
			if(!Principal.isInRole("ROLE_SUPERVISOR")){
				return filters.rangeFrom && filters.rangeTo &&  filters.template && filters.division && filters.division.length != 0
			}else{
				return filters.rangeFrom && filters.rangeTo &&  filters.template
			}
		},
		load: function(filters){
			return ChecklistService.getVioletReport(filters);
		},
		download: function(filters){
			return DownloadService.downloadVioletReport(filters);
		}
	};
}
angular.module('smartApp').component('goodsOrderInfo', {
    templateUrl: 'scripts/app/goods/goods-order-info/goods-order-info.component.html',
    controller: GoodsOrderInfoController,
    bindings: { orderNumber: '<' },
});

function GoodsOrderInfoController(GoodsService, Notifications) {
    const $ctrl = this;

    function fetchWorkOrder() {
        GoodsService.getWorkOrder($ctrl.orderNumber).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки информации по заказ-наряду');
                return;
            }

            $ctrl.workOrder = result.data;
        })
    }

    fetchWorkOrder();
}

GoodsOrderInfoController.$inject = ['GoodsService', 'Notifications'];

'use strict';

angular.module('smartApp').controller('SettingsPushController', SettingsPushController);

SettingsPushController.inject = ['$scope', 'SettingsPushService', 'Notifications'];

function SettingsPushController($scope, SettingsPushService, Notifications) {
  var initialPushList = {};

	$scope.pushList = [];

	$scope.savePushChanges = function(push) {
		SettingsPushService.updatePush(push).then(result => {
      if (result.status !== 200) {
        Notifications.danger('Ошибка! Не удалось обновить push-уведомление.');
        return;
      }

      initialPushList[push.id] = angular.copy(push);

      Notifications.success('Push-уведомление успешно обновлено');
    });
	};

  $scope.isPushChanged = function(push) {
    var initialPush = initialPushList[push.id];

    for (let prop in initialPush) {
      if (push[prop] !== initialPush[prop]) return true;
    }

    return false;
  };

  SettingsPushService.getPushList().then(result => {
    if (result.status !== 200) {
      Notifications.danger('Ошибка! Не удалось загрузить список push-уведомлений.');
      return;
    }

    $scope.pushList = result.data;

    $scope.pushList.forEach(push => {
      initialPushList[push.id] = angular.copy(push);
    });
  });
}

angular.module('smartApp')
    .controller('MetersController', ['$scope', '$stateParams', '$state', 'TaskListServiceHttp', 'Notifications', function(
        $scope,
        $stateParams,
        $state,
        TaskListServiceHttp,
        Notifications
    ) {
        if (!$stateParams.id) {
            $state.go('tasks');
            return;
        }

        $scope.task = {};
        $scope.response = {};
        $scope.meters = [];
        const responseId = $stateParams.id;

        function init() {
            TaskListServiceHttp
                .getResponse(responseId)
                .then(function(resp) {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке задачи');
                        $state.go('tasks');
                        return;
                    }
                    $scope.response = resp.data;

                    getTaskInfo(resp.data.taskId);
                });

            TaskListServiceHttp
                .fetchMeterIndications(responseId)
                .then(function(resp) {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке показаний');
                        $state.go('tasks');
                        return;
                    }
                    $scope.meters = resp.data;
                });
        }

        function getTaskInfo(id) {
            TaskListServiceHttp
                .getTask(id)
                .then(function(resp) {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке задачи');
                        $state.go('tasks');
                        return;
                    }
                    $scope.task = resp.data;
                });
        }

        init();
    }]);

var PanelClustersService;

PanelClustersService = function($http, config) {
    return {
        getClusters: function(page, limit, CLUSTER_name, showAll) {
            var query;
            query =
                config.backend +
                '/admin/clusters?page=' +
                page +
                '&per_page=' +
                limit;
            if (CLUSTER_name !== null && CLUSTER_name !== '') {
                query += '&name=' + CLUSTER_name;
            }
            if (showAll && showAll !== '') {
                query += '&showAll=true';
            }
            return $http.get(query);
        },
        createCluster: function(newCluster) {
            return $http.post(config.backend + '/admin/clusters', newCluster);
        },
        updateCluster: function(Cluster_to_update) {
            return $http.put(
                config.backend + '/admin/clusters',
                Cluster_to_update
            );
        },
        getAllClusters: function() {
            return $http.get(config.backend + '/objects/clusters');
        },
        getAllUsers: function() {
            return $http.get(config.backend + '/objects/users');
        },
        getClusterInfo: function(id, hasNoThrobber) {

            return $http.get(config.backend + '/admin/clusters/' + id, {
                noThrobber: hasNoThrobber
            });
        },
        parse: function(headers) {
            var links, parts;
            parts = headers.split(',');
            links = {};
            angular.forEach(parts, function(p) {
                var name, page, queryString, section, url;
                section = p.split(';');
                url = section[0].replace(/<(.*)>/, '$1').trim();
                queryString = {};
                url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
                    $0,
                    $1,
                    $2,
                    $3
                ) {
                    return (queryString[$1] = $3);
                });
                page = queryString['page'];
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                name = section[1].replace(/rel="(.*)"/, '$1').trim();
                return (links[name] = page);
            });
            return links;
        },
        updateRegion: function(idRegion, params) {
            return $http.put(
                config.backendOrgstruct + '/regions/' + idRegion,
                params
            );
        },
        updateRegionDirectors: function(idRegion, userIds) {
            return $http.put(
                config.backendOrgstruct +
                    '/regions/' +
                    idRegion +
                    '/dirs/' +
                    userIds
            );
        },
        deleteRegionDirectors: function(idRegion, userIds) {
            return $http.delete(
                config.backendOrgstruct + '/regions/' + idRegion + '/dirs/',
                userIds
            );
        }
    };
};

angular
    .module('smartApp')
    .factory('PanelClustersService', PanelClustersService);

PanelClustersService.$inject = ['$http', 'config'];

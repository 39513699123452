'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('adapter-mobile-api', {
                parent: 'developer',
                url: '/adapter-mobile-api',
                data: {
                    roles: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/developer/adapter-mobile-api/adapter-mobile-api.html'
                    }
                }
            });
    });

angular.module('smartApp').factory('FormsService', function FormsService() {
  return {
    filterNonActiveQuestions: function(template, answers) {
      var groupsToDelete = [];

      template.groups.forEach(group => {
        var pointsToDelete = [];

        group.points.forEach(point => {
          point.questions = point.questions.filter(question => {
            var questionHasAnswer = answers.filter(answer => answer.questionId === question.id).length > 0;
            return questionHasAnswer;
          });

          if (!point.questions.length) {
            pointsToDelete.push(point.id);
          }
        });

        if (pointsToDelete.length) {
          group.points = group.points.filter(point => pointsToDelete.indexOf(point.id) === -1);
        }

        if (!group.points.length) {
          groupsToDelete.push(group.id);
        }
      });

      if (groupsToDelete.length) {
        template.groups = template.groups.filter(group => groupsToDelete.indexOf(group.id) === -1);
      }
    },

    getAnswerByQuestionId: function(questionId, answers) {
      for (var i = 0; i < answers.length; i++) {
        if (answers[i].questionId === questionId) {
          return answers[i];
        }
      }
    },

    getQuestionById: function(targetId, template) {
      var questions = this.getQuestionsFromTemplate(template);

      for (var i = 0; i < questions.length; i++) {
        if (questions[i].id === targetId) {
          return questions[i];
        }
      }
    },

    getPointById: function(template, pointId) {
      var targetPoint = null;

      template.groups.every(group => {
        return group.points.every(point => {
          if (point.id === pointId) {
            targetPoint = point;
            return false;
          }
          return true;
        })
      });

      return targetPoint;
    },

    getQuestionsAnswers: function(questions, allAnswers) {
      var questionIds = questions.map(question => question.id);
      var targetAnswers = [];

      allAnswers.forEach(answer => {
        if (questionIds.indexOf(answer.questionId) !== -1) {
          targetAnswers.push(answer);
        }
      });

      return targetAnswers;
    },

    sortAnswersByQuestions: function(questions, answers) {
      var questionIds = questions.map(question => question.id);
      return answers.sort((answerOne, answerTwo) => questionIds.indexOf(answerOne.questionId) - questionIds.indexOf(answerTwo.questionId));
    },

    sortQuestionsInTemplate: function(template) {
      template.groups.forEach(group => {
        group.points.forEach(point => {
          point.questions = point.questions.sort((questionOne, questionTwo) => questionOne.orderNum - questionTwo.orderNum);
        });
      });
    },

    getQuestionsFromTemplate: function(template) {
      var groups = template.groups;
      var questionNodes = [];

      groups.forEach(group => {
        group.points.forEach(point => {
          questionNodes = questionNodes.concat(point.questions);
        });
      });

      return questionNodes;
    }

  }
});

<script setup>
import { ref, useModel, watch } from 'vue';
import { CustomTextarea, ULabel, FileUploader } from '@eron/ui-kit';

import ExpansionPanel from '@/components/common/ExpansionPanel.vue';
import PrioritySelect from '@/components/tasks/PrioritySelect.vue';
import TaskTypeSelect from '../components/TaskTypeSelect.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
    attachments: {
        type: Array,
        default: () => [],
    },
    expanded: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:model-value', 'update:attachments', 'delete:attachment']);

const innerExpanded = useModel(props, 'expanded');
const taskType = ref();
const priorityUrgent = ref(false);
const description = ref();
const innerAttachments = ref([]);
let skipUpdate = false;
let skipAttachmentsUpdate = false;

const emitChange = () => {
    skipUpdate = true;
    emit('update:model-value', {
        ...props.modelValue,
        taskType: taskType.value,
        urgent: priorityUrgent.value,
        description: description.value,
    });
};

const validate = () => {
    return !!taskType.value;
};

const onAttachmentsChange = async newVal => {
    const promises = newVal.map(async item => {
        const isNewFile = item.url.startsWith('blob');

        if (isNewFile) {
            if (item.file) {
                return item;
            }

            const blob = await fetch(item.url).then(r => r.blob());
            return {
                ...item,
                file: new File([blob], item.label, { type: blob.type }),
            };
        }

        return item;
    });
    const results = await Promise.all(promises);

    innerAttachments.value = results;
    emit('update:attachments', innerAttachments.value);
    skipAttachmentsUpdate = true;
};

const onAttachmentsItemDelete = async index => {
    const targetAttachment = innerAttachments.value.find(item => item.index === index);

    if (targetAttachment) {
        if (targetAttachment.file) {
            URL.revokeObjectURL(targetAttachment.url);
        } else {
            emit('delete:attachment', targetAttachment);
        }
    }
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        if (newVal) {
            taskType.value = newVal.taskType;
            priorityUrgent.value = newVal.urgent || false;
            description.value = newVal.description;
        }
    },
    { immediate: true }
);

watch(
    () => props.attachments,
    newVal => {
        if (skipAttachmentsUpdate) {
            skipAttachmentsUpdate = false;
            return;
        }

        innerAttachments.value = newVal.map((item, index) => {
            return {
                ...item,
                url: `${location.origin}/files${item.url}`,
                label: item.name,
                index,
            };
        });
    },
    { immediate: true }
);

defineExpose({
    validate,
});
</script>

<template>
    <expansion-panel id="tasks-generate-task-data" v-model="innerExpanded" title="Данные по задаче">
        <div class="-mb-6 -flex -items-end">
            <div class="-mr-4 -w-80 -flex-shrink-0">
                <u-label for-value="task-type"> Тип задачи </u-label>
                <task-type-select id="task-type" v-model="taskType" @update:model-value="emitChange" />
            </div>

            <div class="-flex-grow">
                <u-label for-value="task-priority"> Приоритет </u-label>
                <priority-select id="task-priority" v-model="priorityUrgent" @update:model-value="emitChange" />
            </div>
        </div>

        <div class="-mb-6">
            <u-label for-value="task-description"> Описание (необязательно) </u-label>
            <custom-textarea id="task-description" v-model="description" @update:model-value="emitChange" />
        </div>

        <div>
            <div class="-text-lg">Вложения</div>
            <div class="-mb-2 -text-sm -text-gray-moss">Необязательно</div>
            <file-uploader
                id="task-attachments"
                :model-value="innerAttachments"
                input-label="Перетащите сюда файлы или добавьте с компьютера"
                multiple
                drag-n-drop
                @update:model-value="onAttachmentsChange"
                @item-delete="onAttachmentsItemDelete"
            />
        </div>
    </expansion-panel>
</template>

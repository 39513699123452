'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'checklist',
                url: '/plannedChecklist',
                data: {
                    roles: [],
                    breadcrumbs: [['pencil-square-o', 'Список проверок']]
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/checklist/plannedchecklist/plannedchecklist.html',
                        controller: 'PlannedChecklistController'
                    }
                }
            });
    });
'use strict';

angular.module('smartApp').factory('OrgstructService', function OrgstructService($http, config, HttpUtils) {
    var mocks = false;
    return {
        getLevels: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/users/levels', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getBusinessDirs: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/business-dirs', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        // поиск пользователей по нескольким параметрам
        getSearchedUsers: function(filters) {
            var params = {
                composition: 'OR',
                loginLike: filters.searchValue,
                lastNameLike: filters.searchValue,
                emailLike: filters.searchValue,
                firstNameLike: filters.searchValue,
                page: filters.page,
                size: filters.size
            };

            return $http.get(config.backendOrgstruct + '/users/', {
                params: params
            });
        },
        getUsers: function (filters, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/users', {
                params: filters,
                noThrobber: hasNoThrobber
            });
        },
        getDependentUsers: function(params, hasNoThrobber) {
          return $http.get(config.backendOrgstruct + '/users/dependent', {
            params: params,
            noThrobber: hasNoThrobber
          });
        },
        getUsersExcel: function (filters){
            return $http.get(config.backendOrgstruct + '/users/', {
                responseType: 'arraybuffer',
                params: filters,
                headers: {
                    'Accept': 'application/vnd.ms-excel'
                }
            });
        },
        getUserControls: function (idUser, type, hasNoThrobber){

            return $http.get(config.backendOrgstruct + '/users/' + idUser + '/' + type, {
                noThrobber: hasNoThrobber
            }); //type equal regions, divisions or shops
        },
        getReports: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/roles/reports/', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUserReportsSources: function (userId, params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/users/' + userId + '/roles/sources', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUserReports: function (userId, params){
            return $http.get(config.backendOrgstruct + '/users/' + userId + '/roles', {
                params: params
            });
        },
        addUserInReport: function (userId, roleNames){
            return $http.post(config.backendOrgstruct + '/users/' + userId + '/roles/'+ roleNames);
        },
        deleteUserFromReport: function (userId, roleNames){
            return $http.delete(config.backendOrgstruct + '/users/' + userId + '/roles/' + roleNames);
        },

        getGroups: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/groups', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUserGroups: function (userId, params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/users/' + userId + '/groups/', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getGroupsAvailable: function (userId, params) {
            return $http.get(config.backendOrgstruct + '/users/' + userId + '/groups/available', {
                params: params
            });
        },
        getDivisions: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/divisions/',  {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUserDivisions: function(userId, hasNoThrobber){
            return $http.get(config.backendOrgstruct + '/users/' + userId + '/divisions/',  {
                noThrobber: hasNoThrobber
            });
        },
        getRegions: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/regions/',  {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUserRegions: function(userId, hasNoThrobber){
            return $http.get(config.backendOrgstruct + '/users/' + userId + '/regions/',  {
                noThrobber: hasNoThrobber
            });
        },
        getShops: function (params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/shops/', {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        getUser: function (idUser, params, hasNoThrobber) {

            return $http.get(config.backendOrgstruct + '/users/' + idUser, {
                params: params,
                noThrobber: hasNoThrobber
            });
        },
        createUser: function (params){
            return $http.post(config.backendOrgstruct + '/users/', params);
        },
        updateUser: function (userId, params){
            return $http.put(config.backendOrgstruct + '/users/' + userId, params);
        },
        addUserGroups: function (userId, groupIds){
            return $http.post(config.backendOrgstruct + '/users/' + userId + '/groups/' + groupIds);
        },
        updateUserGroups: function (userId, groupIds){
          var method = null;
          var path = config.backendOrgstruct + '/users/' + userId + '/groups';

          if (groupIds.length === 0) {
            method = 'delete';
          } else {
            method = 'put';
            path += '/' + groupIds;
          }

          return $http[method](path);
        },
        addUserReportRoles: function (userId, roles){
            return $http.post(config.backendOrgstruct + '/users/' + userId + '/roles/' + roles);
        },
        getDirectorName: function(user){
            var outputUser;
            if (user.login) {
                outputUser = user.login;
            }
            if (user.firstName) {
                outputUser = outputUser + ' ' + user.firstName;
            }
            if (user.lastName) {
                outputUser = outputUser + ' ' + user.lastName;
            }
            if (user.businessDir) {
                outputUser = outputUser + '   ' + user.businessDir.name;
            }
            return outputUser;
        },
        getShopName: function(shop){
            var outputShop;
            if (shop.sap)  {
                outputShop = shop.sap;
            }
            if (shop.locality)  {
                outputShop = outputShop + ' ' + shop.locality;
            }
            return outputShop;
        },
        getTemplates: function(params){
            return $http.get(config.backendOrgstruct + '/templates', {
                params: params
            });
        },
        getUnsignedShops: function(hasNoThrobber) {
            return $http.get(config.backendOrgstruct + '/shops/unsigned', {
                noThrobber: hasNoThrobber
            });
        },
        getUnsignedDirectors: function() {
            return $http.get(config.backendOrgstruct + '/users/directors/unsigned');
        },
        getDirectors: function() {
            return $http.get(config.backendOrgstruct + '/users/directors');
        },

        setDirectorToShop: function(directorId, shopId) {
            return $http.post(config.backendOrgstruct + '/users/'+directorId+'/shop/'+shopId);
        },
        removeDirectorFromShop: function(shopId) {
            return $http.delete(config.backendOrgstruct + '/shops/'+shopId+'/director');
        },
        getExecutorsForShops: function(shopsIds, cb) {
            $http.get(HttpUtils.getQuery('/orgstruct/users/dependent', { orgIds: shopsIds }), { timeout: 5 * 60 * 1000 })
                .success(function (result, status) {
                    cb((status && status == 200)?null:status, result)
                }
            );
        },
        getExecutorsForShop: function(shopId, cb) { // тикет FPR-1622
            $http
                .get(HttpUtils.getQuery('/orgstruct/users/dependent', { shopId }), { timeout: 5 * 60 * 1000 })
                .success(function (result, status) {
                        cb(status && status === 200 ? null : status, result);
                    }
                );
        },
        getShopsForExecutors: function(options, cb) {
            options = options || {};

            $http.post(config.backendOrgstruct + '/shops/dependent', {
                userIds: options.executorsIds,
                withIntegration: options.withIntegration
            }).success(function (result, status) {
                cb((status && status === 200)?null:status, result)
            })
        },
    };
});

(function() {
    'use strict';

    angular.module('smartApp').directive('checkbox', checkbox);

    function checkbox() {
        return {
            template: '<label for="{{idFor}}" class="md-checkbox"><input type="checkbox" id="{{idFor}}" ng-disabled="disabled" data-ng-model="val"><div class="md-checkbox__field"></div></label>',
            link: link,
            restrict: 'EA',
            require: '?ngModel',
            scope: {
                idFor: '@',
                disabled: '<',
            }
        };

        function link(scope, element, attrs, ngModel) {

            scope.isRequired;
            scope.$watch(
                function() {
                    return scope.val;
                },
                function() {
                    if (scope.isRequired) {
                        if (!_.isNil(scope.val)) {
                            ngModel.$setValidity('required', true);
                        } else {
                            ngModel.$setValidity('required', false);
                        }
                    }
                    ngModel.$setViewValue(scope.val);
                }
            );

            init();

            function init() {
                ngModel.$render = function() {
                    scope.val = ngModel.$viewValue;
                };
                scope.isRequired = !!_.find(attrs, [
                    'name',
                    'required'
                ]);
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('smartApp').factory('TaskNewService', TaskNewService);

    TaskNewService.$inject = [];

    function TaskNewService() {
        var service = {
            getTaskTypes: getTaskTypes,
            getTaskProperties: getTaskProperties,
            getPeriodicTaskProperties: getPeriodicTaskProperties,
            getPeriodicTypes: getPeriodicTypes,
            getWeekDays: getWeekDays,
            getMonthDays: getMonthDays,
            getNotificationFrequency: getNotificationFrequency,
            getDatepickerSettings: getDatepickerSettings

        };

        return service;

        function getTaskProperties() {
            return {
                name: '',
                description: '',
                deadlineDate: '',
                executorsCommentRequired: false,
                notificationPeriod: 'DAILY',
                minAttachmentsCount: 0,
                periodic: false,
                taskType: 'COMMON',
                executors: {
                    allCompanySelected: false,
                    executorRole: ''
                }
            };
        }

        function getPeriodicTaskProperties() {
            var periodicProperties = {
                periodicTaskSchedule: {
                    creationEndDate: null,
                    creationStartDate: null,
                    daysToCompleteExemplar: 0,
                    period: {
                        type: 'DAILY'
                    }
                }
            };

            var taskProperties = getTaskProperties();

            return _.merge(taskProperties, periodicProperties);
        }

        function getTaskTypes() {
            return [
                {
                    title: 'Общая',
                    val: 'COMMON'
                },
                {
                    title: 'Фотоотчет',
                    val: 'PHOTO_REPORT'

                },
                {
                  title: 'Проверка наличия',
                  val: 'FORMS'
                },
                {
                  title: 'Пересчёт товара',
                  val: 'MANUAL_RECOUNT'
                },
                {
                  title: 'Сбор данных',
                  val: 'EQUIPMENT'
                }
            ];
        }

        function getNotificationFrequency() {
            return [
                {
                    title: 'Каждый день',
                    val: 'DAILY'
                },
                {
                    title: 'За день до крайнего срока',
                    val: 'DAY_BEFORE_DEADLINE'
                },
                {
                    title: 'В крайний день исполнения',
                    val: 'DEADLINE'
                },
                {
                    title: 'Раз в неделю',
                    val: 'WEEKLY'
                }
            ];
        }

        function getPeriodicTypes() {
            return [
                {
                    title: 'Ежедневно',
                    val: 'DAILY'
                },
                {
                    title: 'По дням недели',
                    val: 'WEEKLY'
                },
                {
                    title: 'Ежемесячно',
                    val: 'MONTHLY'
                }
            ];
        }

        function getWeekDays() {
            return [
                {
                    title: 'Пн',
                    val: 'MONDAY',
                    state: false
                },
                {
                    title: 'Вт',
                    val: 'TUESDAY',
                    state: false
                },
                {
                    title: 'Ср',
                    val: 'WEDNESDAY',
                    state: false
                },
                {
                    title: 'Чт',
                    val: 'THURSDAY',
                    state: false
                },
                {
                    title: 'Пт',
                    val: 'FRIDAY',
                    state: false
                },
                {
                    title: 'Сб',
                    val: 'SATURDAY',
                    state: false
                },
                {
                    title: 'Вс',
                    val: 'SUNDAY',
                    state: false
                }
            ];
        }

        function getMonthDays() {
            return _.map(new Array(31), function(item, i) {
                return i + 1;
            });
        }

        function getDatepickerSettings() {
            return {
                dateDisabled: false,
                formatYear: 'yy',
                minDate: new Date(),
                startingDay: 1
            };
        }
    }
})();

angular.module('smartApp').controller('XfilterController1', XfilterController1);
XfilterController1.$inject = ['$scope', 'UserService', 'ChecklistReportService', 'PanelGroupsService'];

function XfilterController1($scope, UserService, ChecklistReportService, PanelGroupsService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);

	$scope.allUsers = [];
	$scope.fio = {selected:undefined};

	UserService.getAllWithoutDirectors().success(function (data) {
	//UserService.getAll().success(function (data) {
	    $scope.users = data;
	    $scope.allUsers = data;
	});

	$scope.selectAllFio = function() {
		$scope.uiSelect.fio.selected = $scope.users;
	};	
	$scope.getUsersOnGroups = function(groups){
		if(groups.length == 0){
			$scope.users = $scope.allUsers;
			return;
		}
		$scope.fio.selected = undefined;
		var groupsArr = [];
		async.eachSeries(groups, function(item, callback){
			PanelGroupsService.getGroupInfo(item.id).success(function (data, status, headers) {
				if(status != 200){
					callback(data);
					return
				}
				groupsArr.push(data);
				callback(null, data)
			})
		}, function(err, msg){
			if(err){
				Notifications.danger('Ошибка получения групп');
				console.log(err);
				return;
			}
			var users = [];
			for(var i in groupsArr){
				users = users.concat(groupsArr[i].users);
			}		

			for(var i in users){
				users[i].name = users[i].lastName + users[i].firstName
			}
			$scope.users = users;
		})
	}
}


/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/
angular.module('smartApp').factory('Xfilter2', checkXreportFactory2);
checkXreportFactory2.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory2(ChecklistService, DownloadService) {
	return {
		id: 8,
		title: 'X Фильтр 2',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter2/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter2/view.html',
		isValid: function(filters) {
			return filters.month;
		},
		load: function(filters){
			return ChecklistService.getXreport2(filters);
		},
		download: function(filters){
			return DownloadService.downloadVioletReport(filters);
		}
	};
}
angular.module('smartApp').factory('ImageModal', [function ImageModal() {
  var body = angular.element(document).find('body');
  var modalTemplate = '<div class="imageModal" id="imageModal"><div class="imageContent"></div></div>'
  var modal = angular.element(modalTemplate);

  function closeModal() {
    modal.animate({
      top: '-100%',
    }, 300, function() {
      modal.remove();
    });
  }

  return {
    open: function(imageUrl) {
      modal.css({ top: '-100%' });
      modal.on('click', closeModal);
      body.append(modal);
      modal.animate({ top: 0 }, 300);

      modal.children('.imageContent').css({
        backgroundImage: 'url(' + imageUrl + ')',
        backgroundRepeat: 'no-repeat'
      });
    }
  };
}]);

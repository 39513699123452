'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('shop-dir-tasklist', {
                parent: 'checklist',
                url: '/shop-dir-tasklist/:checkListId',
                data: {
                    roles: ['ROLE_SHOPDIR']
                },
                views: {
                    'content@': {
                        template: '<shop-dir-tasks class="tasklist"></shop-dir-tasks>'
                    }
                }
            });
    });
(function() {
    'use strict';

    angular
        .module('smartApp')
        .component('tasksItem', {
            templateUrl: 'scripts/app/tasks/task-item/task-item.view.html',
            controller: TasksItemController,
            controllerAs: '$ctrl',
            bindings: {
                task: '<',
                settings: '=',
                parentTask: '<',
            }
        });

    TasksItemController.$inject = [
        '$window',
        '$state',
        '$timeout',
        '$log',
        'Notifications',
        'TaskListServiceHttp',
        'TaskItemService',
        'TasksViewService',
        'Principal',
        'FormsService',
        'ngDialog',
        'CompareTimeService',
        '$filter'
    ];

    function TasksItemController(
        $window,
        $state,
        $timeout,
        $log,
        Notifications,
        TaskListServiceHttp,
        TaskItemService,
        TasksViewService,
        Principal,
        FormsService,
        ngDialog,
        CompareTimeService,
        $filter
    ) {
        var $ctrl = this;
        var freshLoadedData = true;

        $ctrl.isAdmin = Principal.isInRole('ROLE_ADMIN');
        $ctrl.showAuthorAttachments = false;

        $ctrl.viewSettings = TasksViewService.getViewProperty($ctrl.task, $ctrl.settings);

        $ctrl.uploadExecutorAttachments =  uploadExecutorAttachments;

        $ctrl.setUserRole = TaskItemService.setUserRole;

        $ctrl.taskInf;

        // $ctrl.taskDataByStatus;

        $ctrl.$onChanges = function(changesObj) {
            if (_.get(changesObj, 'task')) {
                initializeTask();
            }
        };

        $ctrl.downloadInventoryReport = function() {
            TaskListServiceHttp.getResponseInventoryReport($ctrl.taskInf.id).then(result => {
                if (result.status !== 200) {
                    Notifications.danger('Произошла ошибка');
                    return;
                }

                const blob = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Подготовка к инвентаризации (id ${$ctrl.taskInf.id}).xls`;
                link.click();

                Notifications.success('Файл успешно загружен');
            });
        };

        $ctrl.deleteTask = function(){
            if(!confirm('Удалить задачу?'))return false;

            TaskListServiceHttp.deleteTask($ctrl.taskInf.id).then(function(result) {
                if(result.status === 200 || status === 201){
                    Notifications.success('Задача удалена');
                    delete $ctrl.task;
                    delete $ctrl.taskInf;
                }else{
                    Notifications.danger('Ошибка! Не удается удалить задачу');
                }
            }, function(error) {
                Notifications.danger('Ошибка! Не удается удалить задачу');
                $log.log(error);
            });
        };

        $ctrl.viewAttachment = function(attachment){
            $window.open(attachment.url, '_blank');
        };

        $ctrl.getDeadlineTitle = function(task) {
            if (task.deadlineByLocalTime) {
                if (task.userRole === 'EXECUTOR') {
                    return $filter('formatDate')(task.responseList[0].deadlineDate, 'DD.MM.YY HH:mm', true)
                } else {
                    const deadlineDate = $filter('formatDate')(task.deadlineDate, 'DD.MM.YY HH:mm', true);
                    return deadlineDate + ' по местному';
                }
            } else {
                const deadlineDate = $filter('formatDate')(task.deadlineDate, 'DD.MM.YY HH:mm', true);
                return deadlineDate + ' по Мск';
            }
        };

        $ctrl.removeAttachment = function(attachmentToRemove) {
          TaskItemService.deleteFile($ctrl.task.responseData.id, attachmentToRemove).then(result => {
            if (result.status !== 200) {
              Notifications.danger('Ошибка удаления вложения!');
              return;
            }

            var currentAttachments = $ctrl.taskInf.responseData.attachments;
            $ctrl.taskInf.responseData.attachments = currentAttachments.filter(attach => attachmentToRemove.uid !== attach.uid);
            $ctrl.taskInf.responseData.attachmentsCount = $ctrl.taskInf.responseData.attachments.length;
          });
        };

        $ctrl.toggleAttachments = function(e, setTo){
            if(e){
                e.preventDefault();
                e.stopPropagation();
            }
            if(setTo == undefined && $ctrl.taskInf.attachmentsCount){
                $ctrl.showAuthorAttachments = !$ctrl.showAuthorAttachments;
            }
            if(setTo == false)$ctrl.showAuthorAttachments = setTo

        }

        $ctrl.openTask = function(event, task) {
            if (event.ctrlKey || event.metaKey) {
                const url = $state.href('tasks.taskFullView', { id: task.id });
                $window.open(url, '_blank');
            } else {
                $state.go('tasks.taskFullView', { id: task.id });
            }
        };

        $ctrl.goToCrmPage = function(task){
            const baseUrl = $ctrl.settings.params['task.crm.baseurl'];
            $window.open(`${baseUrl}/tasks/${task.responseData.crmTaskId}`);
        };

        $ctrl.checkPhotoReport = function(task) {
          $state.go('tasks.photoReportCheck', { id: task.id });
        };

        var debouncedUpdateTimer;
        $ctrl.debounceComment = function(comment){
            $timeout.cancel(debouncedUpdateTimer);
            debouncedUpdateTimer = $timeout(function () {
                $ctrl.saveComment(comment);
            }, 2000);
        };

        $ctrl.saveComment = function(comment){
            //часть данных в taskInf.responseData
            $timeout.cancel(debouncedUpdateTimer);
            if(comment == null || comment == $ctrl.taskInf.responseData.comment){
                return;
            }


            var commentObj={
                "version": $ctrl.taskInf.responseData.version,
                "comment": comment
            };

            TaskListServiceHttp.changeComment($ctrl.taskInf.responseData.id, commentObj).success(function (result, status) {
                if (status == 200 || status === 201) {
                    Notifications.success('Комментарий обновлен', '', 3000);
                    $ctrl.taskInf.responseData.comment = comment;
                    $ctrl.taskInf.responseData.version = result.version
                    $ctrl.taskInf.version = result.version
                    //updateTask($ctrl.infoTaskMain.id);
                    //updateTask($ctrl.infoTaskMain.id);

                    //renew this shit
                    $ctrl.viewSettings = TasksViewService.getViewProperty($ctrl.taskInf, $ctrl.settings);
                }else if(status == 409) {
                    Notifications.danger('Ошибка! Комментарий уже был обновлен');
                    //editMain($ctrl.infoTaskMain);
                }else{
                    Notifications.danger('Ошибка! Комментарий не сохранен');
                }
            });
        };

        $ctrl.openWarning = function(form) {
            if (freshLoadedData) {
                openFormTask(form);
            } else {
                ngDialog.open({
                template: 'warningLoadedData',
                showClose: false,
                closeByNavigate: true,
                className: 'ngdialog-theme-plain',
                controller: ['$scope', function($scope) {
                    $scope.goToForm = function() {
                        openFormTask(form);
                        ngDialog.close();
                    };
                }]
            });
            }
        };

        $ctrl.changeStatus = function(taskType, action) {
            if (taskType === 'FORMS') {
                changeFormTaskStatus(action);
            } else {
                changeStatusTask(action);
            }
        };

        $ctrl.checkStatus = function() {
            if ($ctrl.taskInf.taskType === 'FORMS' && $ctrl.taskInf.wasClosedBySystem) {
                return 'Автоматически закрыта системой';
            }

            return  $filter('statusTaskFormatter')($ctrl.taskInf.task ? $ctrl.taskInf.status : $ctrl.taskInf.responseData.status)
        };

        function changeFormTaskStatus (action, attachmentsRequired) {
            if (action === 'stop' && $ctrl.taskInf.taskType === 'FORMS') {
                checkAllQuestionsAnswered(result => {
                    if (result === 'OK') {
                        changeStatusTask(action, attachmentsRequired, updateCheckIntegrationTime.bind(null, $ctrl.taskInf.responseData.formId, $ctrl.taskInf.lastChangeIntegrationFileDate));
                    } else {
                        Notifications.danger('Ошибка завершения задачи! Не все обязательные вопросы заполнены.');
                    }
                });
                return;
          }

          changeStatusTask(action, attachmentsRequired, activateInstance.bind(null, $ctrl.taskInf.responseData.formId));
        }


        function changeStatusTask(action, attachmentsRequired, cb){
        //deprecated
        // if ((!responseTask.comment && action === 'stop') || ($ctrl.infoTaskMain.attachmentsRequired && action === 'stop' && (!responseTask.attachments || responseTask.attachments.length === 0))){
        //     if (!responseTask.comment && action === 'stop') Notifications.danger('Ошибка! Необходимо заполнить комментарий');
        //     if ($ctrl.infoTaskMain.attachmentsRequired && action === 'stop' && (!responseTask.attachments || responseTask.attachments.length === 0)) Notifications.danger('Ошибка! Необходимо вложить файлы');
        // }else {

            if (action === 'stop' && $ctrl.taskInf.responseData.attachmentsCount < $ctrl.taskInf.responseData.minAttachmentsCount) {
              Notifications.danger('Недостаточно вложений для завершения задачи!');
              return;
            }

            var taskObj = {
                "version": $ctrl.taskInf.responseData.version,
                "comment": $ctrl.taskInf.responseData.comment
            };
            TaskItemService.changeStatusTask($ctrl.taskInf.responseData.id, action, taskObj).success(function (result, status) {
                if (status == 200 || status == 201) {
                    var text = 'обновлена';

                    switch(action) {
                      case 'start':
                        text = 'взята в работу';
                        break;
                      case 'stop':
                        text = 'завершена';
                        break;
                      case 'return':
                        text = 'возвращена';
                        break;
                    }

                    if($ctrl.taskInf.task) {
                      $ctrl.taskInf.status = result.status
                      if(result.status == "CLOSED")$ctrl.taskInf.closeDate = result.closeDate;
                    } else{
                      $ctrl.taskInf.responseData.status = result.status;
                      $ctrl.taskInf.responseData.version = result.version;
                      if(result.status == "CLOSED")$ctrl.taskInf.responseData.closeDate = result.closeDate;
                    }

                    //renew this shit
                    $ctrl.viewSettings = TasksViewService.getViewProperty($ctrl.taskInf, $ctrl.settings);

                    Notifications.success("Задача "+ text)
                }else if(status == 404) {
                    Notifications.danger('Не найден ответ по исполнению задачи с таким id', '', 3000);
                }else if(status == 409) {
                    if(result.status === "IN_PROGRESS"){
                        Notifications.danger('Ошибка! Задача уже была взята в работу', '', 3000);
                    }else if(result.status === "CLOSED"){
                        if(!result.taskLog){
                            Notifications.danger('Ошибка! Задача уже была завершена', '', 3000);
                        }else {
                            Notifications.danger('Ошибка! ' + result.taskLog, '', 3000);
                        }
                    }

                } else {
                    Notifications.danger('Ошибка изменения статуса задачи');
                }

                if (cb) cb();
            })
        //}
        }

        function openFormTask(task) {
            if(task.responseInTask) task.id = task.taskId
          $state.go('tasks.formFullView', { id: task.id });
        }

        function checkAllQuestionsAnswered(cb) {
            const formId = $ctrl.taskInf.responseData.formId;

            TaskListServiceHttp.getPointsResolutions(formId).then(result => {
                const isAllResolved = result.data.every(point => Boolean(point.resolution));

                if (isAllResolved) {
                    cb('OK');
                } else {
                    cb('NOT_ALL_ANSWERED');
                }
            })
        }

        function uploadExecutorAttachments(files) {

            if (files && files.length != 0) {
                TaskItemService.uploadFiles($ctrl.task.responseData.id, files).then(function (response) {
                    if (!_.has($ctrl.task, 'attachments')) {
                        $ctrl.task.attachments = [];
                    }
                    angular.forEach(response, function(item) {
                        if (item.status === 200 || item.status === 201) {
                            if(!$ctrl.taskInf.responseData.attachments) $ctrl.taskInf.responseData.attachments  = []

                            $ctrl.taskInf.responseData.attachments  = $ctrl.taskInf.responseData.attachments.concat(item.data.attachments.map(el => {
                                el.typeForPreview = TasksViewService.getAttachmentTypeForPreview(el.type)
                                return el;
                            }));
                        }
                    });

                    $ctrl.taskInf.responseData.attachmentsCount = $ctrl.taskInf.responseData.attachments.length;
                    // if (countSuccess === response.length) {
                    // Notifications.success('Файлы загружены');
                    // } else {
                    // Notifications.danger('Ошибка! Файл(ы) загрузить не удалось');
                    // }
                }, function () {
                    // Notifications.danger('Ошибка! Файл(ы) загрузить не удалось');
                });
            }
        }

        function updateCheckIntegrationTime(formId, integrationTime) {
            if (!integrationTime) return;
            TaskItemService.updateCheckIntegrationTime(formId, integrationTime);
        }

        function activateInstance(formId) {
            if (!formId) return;
            TaskItemService.activateInstance(formId);
        }

        function initializeTask() {
            var currentDate = Date.now();
            var deadLineDate = $ctrl.task.deadlineDate;
            var observerName;
            //$ctrl.taskDataByStatus = TaskItemService.setStatusSettings($ctrl.task.status);
            $ctrl.taskInf = angular.copy($ctrl.task);

            if (_.has($ctrl.taskInf, 'observerName') && $ctrl.task.observerName) {
                observerName = $ctrl.task.observerName.slice(', ');
                $ctrl.taskInf.observerName = observerName;
            }

            if ($ctrl.taskInf.taskType === 'FORMS' && $ctrl.taskInf.lastChangeIntegrationFileDate) {
                $ctrl.relevant = CompareTimeService($ctrl.taskInf.lastChangeIntegrationFileDate, $ctrl.taskInf.closeDate);
            }

            //renew this shit
            $ctrl.viewSettings = TasksViewService.getViewProperty($ctrl.taskInf, $ctrl.settings);

            //get attachments
            if(!$ctrl.taskInf.task && $ctrl.taskInf.responseData.attachmentsCount > 0){
                TaskListServiceHttp["getListResponseAttachments"]({"responseId": $ctrl.taskInf.responseData.id})
                    .then(function(resp) {
                        $ctrl.taskInf.responseData.attachmentsError = "";
                        $ctrl.taskInf.responseData.attachments = 0;
                        if (resp.status === 200) {
                            $ctrl.taskInf.responseData.attachments = resp.data.attachments.map(el => {
                                el.typeForPreview = TasksViewService.getAttachmentTypeForPreview(el.type)
                                return el;
                            });
                        }else if(resp.status === 204){
                            $ctrl.taskInf.responseData.attachmentsError = 'Вложения отсуствуют. Вероятно что-то пошло не так';
                        }else{
                            console.log(resp.status, resp.headers('x-error'))
                            $ctrl.taskInf.responseData.attachmentsError = 'Ошибка в загрузке вложений: '+ resp.headers('x-error')
                        }
                    });
            }
        }
    }
})();

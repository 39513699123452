angular.module('smartApp').factory('FilterHR', checkReportFactoryHR);
checkReportFactoryHR.$inject = ['ChecklistService', 'DownloadService'];

function checkReportFactoryHR(ChecklistService, DownloadService) {
	return {
		id: 6,
		title: 'Фильтр HR',
		resultTitle: 'Результаты отчета',
		filtersTemplate: 'scripts/app/checklist/clreports/filterHR/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/filterHR/view.html',
		isValid: function(filters) {
			var month = parseInt(filters.month);
			return !isNaN(month) && month >= 0;
		},
		load: function(filters) {
			return ChecklistService.getHRReport(filters);
		},
		download: function(filters) {
			return DownloadService.downloadHRReport(filters);
		}
	};

}
'use strict';

angular.module('smartApp').factory('ObjectServiceForShops', function ($resource, config) {
    return $resource(config.backend + '/objects/shops', {}, {
            'query': { method: 'GET', isArray: true },
            'cities': { method: 'GET', isArray: true }
        }
    );
});

angular.module('smartApp').factory('ObjectServiceResolverShops', function ($resource, config) {
    return $resource(config.backend + '/checklist/resolvers/:userId/objects/shops', {}, {
            'query': { method: 'GET', isArray: true },
            'cities': { method: 'GET', isArray: true }
        }
    );
});


'use strict';

angular.module('smartApp').controller('PlannedChecklistController', PlannedChecklistController);

PlannedChecklistController.$inject = [
    '$rootScope',
    '$scope',
    '$filter',
    '$state',
    'ObjectServiceForChecklists',
    'ChecklistService',
    'ChecklistEditor',
    'Principal',
    'UserService',
    'moment',
    'projectConfig',
    'ngDialog'
];

function PlannedChecklistController(
    $rootScope,
    $scope,
    $filter,
    $state,
    ObjectServiceForChecklists,
    ChecklistService,
    ChecklistEditor,
    Principal,
    UserService,
    moment,
    projectConfig,
    ngDialog
) {

    $scope.calendarsOpen = [];

    $scope.open = function($event, id) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.calendarsOpen[1] = false;
        $scope.calendarsOpen[2] = false;
        $scope.calendarsOpen[id] = true;
    };
    $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'dd-MM-yyyy'];
    $scope.format = $scope.formats[2];
    $scope.dateFrom = +moment().startOf('month').subtract(1, 'month');

    $scope.dateTo = null;
    $scope.allShops = [];
    $scope.shop = {selected: []};
    $scope.savedChecklists = [];
    $scope.checklists = [];
    $scope.isFullScreen = false;
    $scope.user = Principal.getIdentity();
    $scope.projectName = projectConfig.nameProject;
    var filtered_ckecklists = [];

    $scope.to_search = '';


    $scope.predicate = Principal.getIdentity() && Principal.getIdentity().roles.indexOf('ROLE_DIRECTOR') !== -1 ?'finishDate':'resolveDate';
    $scope.resolveDate = true;
    $scope.shopName = true;
    $scope.templateName = true;
    $scope.statusName = true;
    $scope.isShowDeleteButton = (Principal.getIdentity().roles.indexOf('ROLE_INSPECTOR') === -1) && (Principal.getIdentity().level !== 'REGION')

    $scope.reverse = true;
    $scope.SORT = function(predicate) {
        $scope.reverse = $scope.predicate === predicate ? !$scope.reverse : false;
        $scope.resolveDate = false;
        $scope.shopName = false;
        $scope.templateName = false;
        $scope.statusName = false;
        $scope[predicate] = true;
        $scope.predicate = predicate;
    };
    $scope.clear_input = function() {
        $scope.to_search = '';
        $scope.search();
    };
    $scope.search = function() {
        // Есть ли фильтр по датам
        if (filtered_ckecklists.length > 0) {
            $scope.checklists = filtered_ckecklists;
        } else {
            $scope.checklists = $scope.savedChecklists;
        }

        // Поиск
        if ($scope.to_search.length > 0) {
            var _new_checklist = [],
                _search = new RegExp($scope.to_search, 'i');

            // Матчим проверки
            for (var i = 0; i < $scope.checklists.length; i++) {
                var _shop = $scope.checklists[i].shopLocality + ' ' + $scope.checklists[i].shopSap,
                    _template = $scope.checklists[i].templateName;
                if (_shop.match(_search) || _template.match(_search)) {
                    _new_checklist.push($scope.checklists[i]);
                }
            }

            // Если это директор магазина, извлекаем проверяющих
            if (Principal.getIdentity().roles.indexOf('ROLE_DIRECTOR') !== -1) {
                for (var j = 0; i < $scope.resolvers.length; j++) {
                    if ($scope.resolvers[j]) {
                        var _resolver_name = $scope.resolvers[j].firstName,
                            _resolver_last_name = $scope.resolvers[j].lastName;
                        if (_resolver_name.match(_search) || _resolver_last_name.match(_search)) {
                            for (var a = 0; a < $scope.checklists.length; a++) {
                                if ($scope.checklists[a].resolverId === $scope.resolvers[j].id) {
                                    _new_checklist.push($scope.checklists[a]);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            $scope.checklists = _new_checklist;
        } else {
            if (filtered_ckecklists.length > 0) {
                $scope.checklists = filtered_ckecklists;
            } else {
                $scope.checklists = $scope.savedChecklists;
            }
        }
    };


    $scope.filterShops = function () {
        if ($scope.shop.selected.length > 0) {
            var _new_checklist = [];
            for (var a = 0; a < $scope.shop.selected.length; a++) {
                var _shop = $scope.shop.selected[a].name;
                for (var i = 0; i < $scope.savedChecklists.length; i++) {
                    if ($scope.savedChecklists[i].shopName === _shop) {
                        _new_checklist.push($scope.savedChecklists[i]);
                        break;
                    }
                }
            }
            $scope.checklists = _new_checklist;
        } else {
            $scope.checklists = $scope.savedChecklists;
        }
    };
    $scope.reset = function () {
        $scope.checklists = $scope.savedChecklists;
        filtered_ckecklists = [];
        $scope.dateFrom = null;
        $scope.dateTo = null;
    };
    $scope.initChecklists = function () {

        ObjectServiceForChecklists.query({
            dateFrom: $scope.dateFrom ? moment($scope.dateFrom).format('YYYY-MM-DD') : undefined,
            dateTo: $scope.dateTo ? moment($scope.dateTo).format('YYYY-MM-DD') : undefined
        }, function(result) {
            $scope.resolvers = [];
            $scope.grades = [];
            $scope.allShops = [];
            $scope.savedChecklists = result;
            $scope.checklists = $scope.savedChecklists;

            for (var i = 0; i < $scope.savedChecklists.length; i++) {
                $scope.allShops.push({
                    name: $scope.savedChecklists[i].shopLocality + ' ' + $scope.savedChecklists[i].shopSap,
                    id: $scope.savedChecklists[i].id
                });
            }

            $scope.allShops = _.uniqBy($scope.allShops, function(item){
                return item.name;
            });
        });
    };

    $scope.analyzeDate = function(checklist) {
        return checklist.statusName === 'MISSED' || moment(checklist.resolveDate, 'YYYY-MM-DD').isAfter(moment());
    };

    $scope.initChecklists();

    $scope.editChecklist = function(checklist) {
        var params = { checklist: checklist.id };
        if ($scope.allowedForRole('ROLE_DIRECTOR')) {
            params.resolverId = checklist.resolverId;
        }

        // if (Principal.isInRole('ROLE_SHOPDIR')) {
        //     $state.go('shop-dir-tasklist', { checkListId: checklist.id });
        // }
        if (checklist.statusName === 'PLANNING' && checklist.resolverId !== $scope.user.id) {
            $state.go('editChecklist', params);
        } else if (checklist.statusName === 'PLANNING') {
            confirmChecklistEditing(params);
        } else {
            $state.go('editChecklist', params);
        }
    };

    $scope.goToTasks = function(checklist) {
        // console.log(checklist)
        // return
        // var params = {
        //     shopId: checklist.shopId,
        //     templateId: checklist.templateId,
        //     dateFrom: moment(checklist.actualFinishDate).toJSON(),
        //     dateTo: moment(checklist.actualFinishDate).toJSON()
        // };
        var params = {
            templateId: checklist.templateId,
            shopId: checklist.shopId,
            checklistId: checklist.id,
        }
        $state.go('tasks.checklists', params);
    };

    $scope.allowedForRole = function(role) {
        return Principal.isInRole(role);
    };

    $scope.isActionBtnVisible = function(check) {
       return !(check.statusName === 'PLANNING' && $scope.allowedForRole('ROLE_INSPECTOR'));
    };

    /**
     * @desc - open modal window for confirming transition to checklist editing page
     * @param {object} params - params for transition to checklist editing page
     */
    function confirmChecklistEditing(params) {
        ngDialog.open({
            template: 'modalForConfirmTemplateEditing',
            controller: ['$scope', function($scope) {
                $scope.confirm = function() {
                    ngDialog.closeAll();
                    $state.go('editChecklist', params);
                };
            }],
            className: 'ngdialog-theme-plain dialog',
            showClose: false
        });
    }

    $scope.deleteChecklist = function(checklist) {
        var confirmDelete = window.confirm('Внимание! Вы точно хотите удалить проверку ' +checklist.shopName+' ? Эта необратимая операция, проверка будет безвозвратно удалена.');
        if (confirmDelete) {
        ChecklistEditor.deleteChecklist(checklist.id).then(function(response) {
            if (response.data.success) {
                // ничего не вернет, тк метод void, но рефреш будет после колбэка в блоке else
            } else {
                location.reload();
            }
        }).catch(function(error) {
            alert('An error occurred: ' + error.message);
        });

        }
        
    };
}

'use strict';

angular.module('smartApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('clreports', {
                parent: 'checklist',
                url: '/filters',
                data: {
                    roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CURATOR', 'ROLE_DIRECTOR_CLUSTER']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/checklist/clreports/start.html',
                        controller: 'ChecklistReportsController'
                    }
                }
            });
    });
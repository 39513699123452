'use strict';

angular.module('smartApp').controller('GoodsBoxController', function($scope, GoodsService, Notifications, $state, $stateParams, $timeout) {
    $scope.goodsOrder = $stateParams;
    $scope.totalGoodsBox = 0;
    $scope.goodsBox = [];
    $scope.statuses = [{
        title: 'Принят',
        val: true,
        isSelected: false
    },
        {
            title: 'Отклонен',
            val: false,
            isSelected: false
        }];
    $scope.boxStatuses = [
        {
            title: 'Отклонен',
            val: false,
            isSelected: false
        },
        {
            title: 'Проверка пройдена',
            val: true,
            isSelected: false
        }];

    $scope.onlyNullBoxStatus = { title: 'Пусто', val: 'onlyNullBoxStatus', isSelected: false };
    $scope.filters = { page: 1, size: 50, searchLike: '' };

    $scope.goToWorkOrders = function() {
        $state.go('goods');
    };

    $scope.goToOrder = function() {
        $state.go('goods.order', { number: $scope.goodsOrder.number });
    };

    $scope.getFilteredGoodsBox = function() {
        const statuses = $scope.statuses.filter(s => s.isSelected).map(s => s.val);
        const boxStatuses = $scope.boxStatuses.filter(s => s.isSelected).map(s => s.val);

        const params = Object.assign({}, $scope.filters, {
            page: $scope.filters.page - 1,
            status: statuses.length ? statuses : undefined,
            boxStatus: boxStatuses.length ? boxStatuses : undefined,
            onlyNullBoxStatus: $scope.onlyNullBoxStatus.isSelected ? true : undefined,
        });

        fetchGoodsBox(params);
    };

    $scope.copyDataMatrix = function(item) {
        if (item.isTextCopied) return;

        window.navigator.clipboard.writeText(item.dataMatrix);
        item.isTextCopied = true;

        $timeout(function () {
            item.isTextCopied = false;
        }, 1500);
    }

    function fetchGoodsBox(params) {
        GoodsService.getOrderGoodsBox(Object.assign({}, params, { number: $scope.goodsOrder.number, localcode: $scope.goodsOrder.localcode })).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки списка товаров');
                return;
            }

            $scope.goodsBox = result.data;
            $scope.totalGoodsBox = parseInt(result.headers('x-total-count'));
        });
    }

    $scope.getFilteredGoodsBox();

});

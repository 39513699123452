(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('TaskListService', function TaskService() {
            var data = {};
            var blockingParam = 0;
            var blockingParamInit = 0;

            return {
                getDataObject: getDataObject,
                setDataObject: setDataObject,
                clearDataObject: clearDataObject,
                setBlockingParam: setBlockingParam,
                getBlockingParam: getBlockingParam,
                setBlockingParamInit: setBlockingParamInit,
                getBlockingParamInit: getBlockingParamInit
            };

            function getDataObject() {
                return angular.copy(data);
            }

            function setDataObject(
                argDataObject,
                argExpandedNodes,
                argSelectedNodes,
                argCheckboxNodes,
                argDataMap,
                argAllCompanySelected,
                changedElements
            ) {
                data.dataObject = argDataObject;
                data.expandedNodes = argExpandedNodes;
                data.selectedNodes = argSelectedNodes;
                data.checkboxNodes = argCheckboxNodes;
                data.dataMap = argDataMap;
                data.allCompanySelected = argAllCompanySelected;
                data.changedElems = changedElements;
            }

            function clearDataObject() {
              data = {};
            }

            function getBlockingParamInit() {
                return blockingParamInit;
            }
            function setBlockingParamInit(blockingParamInitVar) {
                blockingParamInit = blockingParamInitVar;
            }

            function getBlockingParam() {
                return blockingParam;
            }
            function setBlockingParam(blockingParamVar) {
                blockingParam = blockingParamVar;
            }
        });
})();

angular.module('smartApp').controller('TransactionsReportFiltersController', TransactionsReportFiltersController);

TransactionsReportFiltersController.$inject = ['$scope'];

function TransactionsReportFiltersController($scope) {
    $scope.selected = {
        dateFrom: moment().subtract(1, 'months').toDate(),
        dateTo: new Date(),
        cardNum: '',
        couponNum: '',
        cardOwnerId: '',
        cardOwnerFullName: '',
        storeNum: '',
        storeName: '',
    };

    $scope.updateFilter = function(name) {
        setFilter(name, $scope.selected[name]);
    };

    $scope.updateFilter('dateFrom');
    $scope.updateFilter('dateTo');

    $scope.reports.setValidator(function() {
        return $scope.transactionsReportFilters.$valid;
    });

    $scope.pagination.limit = 30;

    function setFilter(name, value) {
        $scope.reports.setFilter(name, value);
    }
}
'use strict';

angular.module('smartApp').controller('GoodsOrderController', function(localStorageService, $scope, OrgstructService, GoodsService, config, Notifications, $state, $stateParams) {
    $scope.workOrder = $stateParams;
    $scope.totalOrderGoods = 0;
    $scope.orderGoods = [];
    $scope.statuses = [{
        title: 'Новый',
        val: 'NEW',
        isSelected: false
    }, {
        title: 'Принят',
        val: 'ACCEPTED',
        isSelected: false
    }, {
        title: 'Принят частично',
        val: 'PARTIALLY_ACCEPTED',
        isSelected: false
    },
    {
        title: 'Отклонен',
        val: 'REJECTED',
        isSelected: false
    }];

    $scope.filters = { page: 1, size: 50, searchLike: '' };

    $scope.goToWorkOrders = function() {
        $state.go('goods');
    };

    $scope.goToGoodsBox = function(goods) {
        if (['NEW', 'IN_PROGRESS'].includes($stateParams.status)) return;

        $state.go('goods.box', { number: $stateParams.number, localcode: goods.localcode, name: goods.name });
    };

    $scope.getFilteredOrder = function() {
        const statuses = $scope.statuses.filter(s => s.isSelected).map(s => s.val);
        const params = Object.assign({}, $scope.filters, {
            page: $scope.filters.page - 1,
            statuses: statuses.length ? statuses : undefined
        });

        fetchOrderGoods(params);
    };

    function fetchOrderGoods(params) {
        GoodsService.getOrderGoods(Object.assign({}, params, { number: $scope.workOrder.number })).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Ошибка загрузки списка товаров');
                return;
            }

            $scope.orderGoods = result.data;
            $scope.totalOrderGoods = parseInt(result.headers('x-total-count'));
        })
    }

    $scope.getFilteredOrder();

});

'use strict';

angular.module('smartApp').factory('CategoryService', function CategoryService($http, config) {

    return {
        getAllProcesses: function() {
            return $http.get(config.backend + '/categories/getAllProcesses')
                .success(function(data) {
                    return data;
                }
            );
        },
        changeProcessParent: function(request) {
            return $http.post(config.backend + '/categories/changeProcessParent', request)
                .success(function(data) {
                    return data;
                }
            );
        },
        updateProcess: function(process, parentId) {
            return $http.post(config.backend + '/categories/updateProcess?parentId=' + parentId, process)
                .success(function(data) {
                    return data;
                }
            );
        },

	    updateOrders: function (orders) {
			return $http.put(config.backend + '/categories/updateOrders', orders, {noThrobber: true});
	    },

        saveNewProcess: function(process) {
            return $http.post(config.backend + '/categories/saveProcess', process)
                .success(function(data) {
                    return data;
                }
            );
        },
        copyProcess: function(id) {
            return $http.post(config.backend + '/categories/copyProcess/' + id)
                .success(function(data) {
                    return data;
                }
            );
        },
        getAllRootGroups: function() {
            return $http.get(config.backend + '/categories/getAllRootGroups')
                .success(function(data) {
                    return data;
                }
            );
        },
        getAllGroups: function() {
            return $http.get(config.backend + '/categories/getAllGroups')
                .success(function(data) {
                    return data;
                }
            );
        },
        deleteProcess: function(process) {
            return $http.post(config.backend + '/categories/deleteProcess', process)
                .success(function(data) {
                    return data;
                }
            );
        }
    }

});
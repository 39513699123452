angular.module('smartApp').factory('Xfilter12', checkXreportFactory12);
checkXreportFactory12.$inject = ['ChecklistService', 'DownloadService'];

function checkXreportFactory12(ChecklistService, DownloadService) {
	return {
		id: 15,
		title: 'Отчёты',
		resultTitle: 'Результаты отчета по клинингу (детально)',
		filtersTemplate: 'scripts/app/checklist/clreports/xfilter12/filters.html',
		viewTemplate: 'scripts/app/checklist/clreports/xfilter12/view.html',
		isValid: function(filters) {
			return filters.rangeFrom && filters.rangeTo;
		},
		load: function(filters) {
			var t = ChecklistService.getXreport12(filters);

			return t
		},
		download: function(filters) {
			return DownloadService.downloadXreport12(filters);
		}
	};

}
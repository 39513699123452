<script setup>
import { computed, ref, watch } from 'vue';
import { SingleSelect } from '@eron/ui-kit';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: undefined,
    },
});
const emit = defineEmits(['update:model-value']);

const hours = Array.apply(null, Array(24)).map((_, i) => String(i).padStart(2, '0'));
const minutes = Array.apply(null, Array(60)).map((_, i) => String(i).padStart(2, '0'));
const hour = ref();
const minute = ref();
let skipUpdate = false;

const hoursId = computed(() => {
    return `${props.id}-hours`;
});
const minutesId = computed(() => {
    return `${props.id}-minutes`;
});

const emitChange = () => {
    skipUpdate = true;
    emit('update:model-value', hour.value && minute.value ? `${hour.value}:${minute.value}` : undefined);
};

watch(
    () => props.modelValue,
    newVal => {
        if (skipUpdate) {
            skipUpdate = false;
            return;
        }

        let newHour, newMinute;

        if (newVal) {
            [newHour, newMinute] = newVal.split(':').map(item => item.trim());
        }

        hour.value = newHour;
        minute.value = newMinute;
    }
);
</script>

<template>
    <div class="fpr-time-select">
        <single-select
            :id="hoursId"
            v-model="hour"
            :options="hours"
            :clearable="false"
            class="fpr-time-select_control"
            @update:model-value="emitChange"
        />
        <span class="fpr-time-select_sep">:</span>
        <single-select
            :id="minutesId"
            v-model="minute"
            :options="minutes"
            :clearable="false"
            class="fpr-time-select_control"
            @update:model-value="emitChange"
        />
    </div>
</template>

<style lang="scss">
.fpr-time-select {
    display: flex;
    align-items: center;

    &_sep {
        margin: 0 4px;
    }

    &_control {
        width: 85px;
    }
}
</style>

angular.module('smartApp').factory('ReportManagerCoordinates', checkReportManagerCoordinates);
checkReportManagerCoordinates.$inject = ['ChecklistService'];

function checkReportManagerCoordinates(ChecklistService) {
    return {
        id: 24,
        title: 'Перемещение менеджеров по продажам',
        resultTitle: 'Результаты отчета',
        filtersTemplate: 'scripts/app/checklist/clreports/report-manager-coordinates/filters.html',
        extraContentTemplate: 'scripts/app/checklist/clreports/report-manager-coordinates/events-container.html',
        viewTemplate: 'scripts/app/checklist/clreports/report-manager-coordinates/view.html',
        isValid: function(filters) {
            return filters.rangeFrom && filters.rangeTo && filters.fio && filters.fio.length;
        },
        load: function(filters) {
            return ChecklistService.getReportManagerCoordinates(filters);
        },
    };

}

'use strict';

angular.module('smartApp')
.filter('wordReplace', function() {
		return function(input) {
			var ans = input;
			switch(input){
				case "supervisor":
					ans = 'региональный директор';
					break;
                case 'Супервайзер':
				case "Supervisor":
					ans = 'Региональный директор';
					break;
                case "cluster":
                    ans = 'регион';
                    break;
                case "Cluster":
                    ans = 'Регион';
                    break;
                case "division":
                    ans = 'дивизион';
                    break;
                case "Division":
                    ans = 'Дивизион';
                    break;
                case "BusinessDir":
                    ans = 'Бизнес-направление';
                    break;
                case "SAPcode":
                    ans = "Номер магазина";
                    break;
                case "sAPcode":
                    ans = "номер магазина";
                    break;
                case "SAPshirt":
                    ans = "";
                    break;
                case "Curators":
                    ans = "Кураторы";
                    break;
                case "сurators":
                    ans = "кураторы";
                    break;
                case "сurators_rod":
                    ans = "кураторов";
                    break;
                case "PointChecklists":
                    ans = "Проверки";
                    break;
                case "shopName":
                    ans = "название магазина";
                    break;
                case "ShopName":
                    ans = "Название магазина";
                    break;
                case "shopName":
                    ans = "название магазина";
                    break;
                case "shop":
                    ans = "магазин";
                    break;
                case "shops":
                    ans = "магазины";
                    break;
                case "Shop":
                    ans = "Магазин";
                    break;
                 case "Shops":
                    ans = "Магазины";
                    break;
                case "verifyCheckPrint":
                    ans = "Печать";
                    break;
                case "shopAccusative":
                	ans = "магазин";
                	break;
                case "shopAccusative":
                	ans = "магазин";
                case "shopsAccusative":
                	ans = "магазины";
                	break;
                case "executorAccusative":
                	ans = "исполнителя";
                	break;
                case "ExecutorsGenetive":
                	ans = "Исполнители";
                	break;
                case "executor":
                	ans = "исполнитель";
                	break;
                case "executors":
                	ans = "исполнители";
                	break;
                 case "Executor":
                	ans = "Исполнитель";
                	break;
                case "Executors":
                	ans = "Исполнители";
                	break;
                case "task":
                	ans = "задача";
                	break;
                case "Task":
                	ans = "Задача";
                	break;
                case "checklist":
                	ans = "проверка";
                	break;
                 case "checklistGenetive":
                	ans = "проверки";
                	break;
                case "Checklist":
                	ans = "Проверка";
                	break;
                case "checklistTemplateAccusative":
                	ans = "шаблон проверки"
                	break;
                case "taskTemplateAccusative":
                	ans = "шаблон задачи"
                	break;

                case "executorAccusative":
                	ans = "исполнителя";
                	break;
                case "executorAccusative":
                	ans = "исполнителя";
                	break;
                case "executorsAccusative":
                	ans = "исполнителей";
                	break;
                case "Choose":
                	ans = "Выбор";
                	break;
            }
			return ans;
		}
	})
	.filter('parseAuthority', function() {
		return function(input) {
            if(input.indexOf("ROLE_CURATOR") > -1) {
                return "Куратор";
            } else if(input.indexOf("ROLE_DIRECTOR_CLUSTER") > -1) {
                return "Директор филиала";
            } else if(input.indexOf("ROLE_SUPERVISOR") > -1) {
                return "Региональный директор";
            } else if(input.indexOf("ROLE_DIRECTOR") > -1) {
                return "Директор";
            } else if(input.indexOf("ROLE_ADMIN") > -1) {
                return "Администратор";
            } else if(input.indexOf("ROLE_SECRET") > -1) {
                return "Тайный покупатель";
            } else if(input.indexOf("ROLE_ENGINEER") > -1) {
                return "Инженер ОУКР";
            } else if(input.indexOf("ROLE_ENERGETIC") > -1) {
                return "Энергетик";
            } else if(input.indexOf('ROLE_MAIN_ENGINEER') > -1){
	            return 'Главный инженер ОУКР';
            }
        }
    })
    .filter('roleName', function() {
        return function(input) {
            var ans = input

            return ans;
        }
    })
    .filter('statusAction', function(Principal) {
        return function(input, isResolverIdEqualUserId) {
            //planning, editing, review, accepted, fixed, missed,
            var ans = undefined;
            switch(input){
                case "PLANNING":
                	if (isResolverIdEqualUserId) {
						ans = "Начать";
					} else {
						ans = "Просмотреть";
					}
                    break;
                case "EDITING":
					if (isResolverIdEqualUserId) {
						ans = "Редактировать";
					} else {
						ans = "Просмотреть";
					}
                    break;
                case "REVIEW":
                    ans = Principal.isInRole('ROLE_DIRECTOR') ? "Открыть" : "Просмотреть";
                    break;
                case "ACCEPTED":
                case "ACCEPTEDBYSYSTEM":
                case "FIXED":
                case "MISSED":
                    ans = "Просмотреть";
                    break;
                default:
                    ans = "n/a";
                    break;
            }
            return ans;
        }
    })
    .filter('displayStatus', function() {
        return function(input) {
            if(input === "PLANNING") {
                return "Запланирована";
            } else if(input === "EDITING"){
                return "Заполняется";
            } else if(input === "REVIEW"){
                return "Отправлено директору";
            } else if(input === "MISSED") {
                return "Проверка не состоялась";
            } else if(input === "ACCEPTED") {
                return "Подтверждена директором";
            } else if(input === "ACCEPTEDBYSYSTEM") {
                return "Проверка завершена";
            } else if(input === "FIXED") {
                return "Исправлено";
            }
        }
    })
		.filter('labelFromPrimitive', function() {
			return function(input) {
				if (input) {
		      return 'Да';
		    }

		    if (!input && input !== false) {
		      return 'Не важно';
		    }

		    return 'Нет';
			}
		})

		.filter('labelForUrgentPrimitive', function() {
			return function(input) {
				if (input) {
		      return 'Срочная';
		    }

		    if (!input && input !== false) {
		      return 'Не важно';
		    }

		    return 'Обычная';
			}
		})

	.filter('jsonToArray', function() {
		return function(input) {
			return _.toArray(input);
		}
	})
	.filter('businessDirection', function() {
		return function(input, search) {
			var l = {"OPERATIONS": "Операции"}

			return (l[input])?l[input]:input
		}
	})
	.filter('setDayOfMonth', function() {
		return function(input) {
			var ans = "Не выбран"
			if(input)ans = "Назначить "+ input +"-й";

			return ans+" день месяца"
		}
	})

	.filter('missplannedLevels', function() {
		return function(input, search) {
			var l = {
				"RESPONSIBLE": "Заблаговременно ответственному сотруднику",
				"HEAD_OF_RESPONSIBLE":"Заблаговременно руководителю ответственного сотрудника",
				"RESPONSIBLE_AND_HEAD":"По факту просрочку руководителю ответственного сотрудника + ответственному"
			}

			return (l[input])?l[input]:input
		}
	})
	.filter('notificationsSettingsLevels', function() {
		return function(input, search) {
			var l = {
				"ALL": "Все",
				"COMPANY":"Компания",
				"DIVISION":"Дивизион",
				"REGION":"Регион",
                "SHOP":"Магазин"

			}

			return (l[input])?l[input]:input
		}
	})
    .filter('userLevels', function() {
        return function(input, search) {
            var l = {
                "COMPANY":"Компания",
                "DIVISION":"Отдел продаж",
                "REGION":"Группа магазинов",
                "SHOP":"Магазин"
            };
            return (l[input])?l[input]:input
        }
    })
    .filter('daysOfWeekAbbr', function() {
        return function(input, search) {
            var l = {
                "MONDAY":"Пн",
                "TUESDAY":"Вт",
                "WEDNESDAY":"Ср",
                "THURSDAY":"Чт",
                "FRIDAY":"Пт",
                "SATURDAY":"Сб",
                "SUNDAY":"Вс"
            };
            return (l[input])?l[input]:input
        }
    })
    .filter('daysOfWeekFull', function() {
        return function(input, search) {
            var l = {
                "MONDAY":"Понедельник",
                "TUESDAY":"Вторник",
                "WEDNESDAY":"Среда",
                "THURSDAY":"Четверг",
                "FRIDAY":"Пятница",
                "SATURDAY":"Суббота",
                "SUNDAY":"Воскресенье"
            };
            return (l[input])?l[input]:input
        }
    })
	.filter('search', function() {
		return function(input, search) {
			//return input+"<b>dasdsa</b>"
			if(!search)return input;
			var position = input.toLowerCase().indexOf(search.toLowerCase())
			if(position === -1){
				console.log("something wrong", input, search);
				return input;
			}
			//console.log(input, search)
			return input.substring(0, position)+"<i>"+input.substring(position, position+search.length)+"</i>"+input.substring(position + search.length)
		}
	})
	.filter('addBlocksToLowGrades', function() {
		return function(input) {
			var result = "Выберите блоки";
			if(input == 1) result = "Добавить блок";
			if(input > 1) result = "Добавить блоки";
			return result;
		}
	})
	.filter('directorAnswer', function() {
		return function(input) {
			var result = "n/a";
			switch(input){
				case undefined:
					result = "неизвестно";
					break;
				case false:
					result = "Не исправлено";
					break;
				case true:
					result = "Исправлено";
					break;
			}
			return result;
		}
	})
	.filter('shopFiltersButton', function() {
		return function(input) {
			if(!input) input = {}
			var length = Object.keys(input).length;
			var ending = "";
			switch(length){
				case 2:
				case 3:
				case 4:
					ending = "а";
					break;
				case 5:
				case 0:
					ending = "ов";
					break;
			}
			return ((length > 0)?length:"Нет")+" фильтр" + ending;
		}
	})

	.filter('rusAccusativeEnding', function() {
		return function(input) {
			var stringInput = input.toString();
			var lastLetter = stringInput.substr(stringInput.length-1)
			var preLastLetter = stringInput.substr(stringInput.length-2, 1)
			var ending = "";
			if(stringInput.length > 1 && preLastLetter == 1){
				return "ов";
			}

			switch(lastLetter){
				case "1":
					break;
				case "2":
				case "3":
				case "4":
					ending = "а";
					break;
				default:
					ending = "ов";
					break
			}
			return ending;
		}
	})


	.filter('answerStatus', function() {
		return function(input, isTriple) {
			var result = "n/a";
			switch(input){
				case undefined:
					result = "Нет ответа";
					break;
				case false:
					result = "Нет";
					break;
				case true:
					result = "Да";
					break;
				case 0.5:
					result = "Средне";
					break;
			}
			return result;
		}
	})
	.filter('platform', function() {
		return function(input) {
			var result = input;
			switch(input){
				case "M":
					result = "Планшет";
					break;
				case "W":
					result = "Web";
					break;
			}
			return result;
		}
	})


	.filter('chartsNames', function() {
        return function(input, search) {
            var l = {
                "shopId":"Магазин",
                "templateId":"Шаблон",
                "processName":"Процесс",

            };
            return (l[input])?l[input]:input
        }
    })

	.filter('changePassAns', function() {
		return function(input) {
			var result = input;
			switch(input){
				case "Password is weak":
					result = "слабый пароль";
					break;
				case "Password and it's repetition are not equal":
					result = "пароль и подтверждение не совпадают";
					break;
				case "User have no email":
					result = "пользователь не имеет привязанного почтового ящика";
					break;
				case "Email doesn't belongs to user":
					result = "введённый почтовый ящик не принадлежит пользователю";
					break;
				case "User not found for email":
				case "User not found for login":
					result = "не найден пользователь";
					break;
				case "Login is empty":
					result = "не указан логин";
					break;
				case "Problems sending email":
					result = "ошибка отправки почты";
					break;
			}
			return result;
		}
	})
	.filter('report9gradeType', function() {
		return function(input) {
			var result = input;
			switch(input){
				case "CATEGORY":
					result = "блок";
					break;
				case "SUB_CATEGORY":
					result = "подблок";
					break;
				case "PROCESS":
					result = "процесс";
					break;
			}
			return result;
		}
	})
	.filter('active', function() {
		return function(input) {
			var result = "";
			if(input) result = "✔";
			if(input == 'no')result = "";
			return result;
		}
	})

	.filter('rusBool', function() {
		return function(input) {
			var result = "Нет";
			if(input) result = "Да";
			if(input == 'no')result = "Нет";
			return result;
		}
	})
	.filter('monthNameFromNum', function() {
		return function(input) {

			var monthesArr = ["январь","февраль","март","апрель","май","июнь","июль","август","сентябрь","октябрь","ноябрь","декабрь"];

			return monthesArr[input-1];
		}
	})
	.filter('monthFromUTC', function() {
		return function(input) {

			return moment(input, 'DD-MM-YYYY HH:mm Z').format("MMMM");
		}
	})
	.filter('getDayMonthAndYear', function() {
		return function(input) {
			if (!Boolean(input)) {
				return 'Не указана';
			}

			return moment(input).format("DD MMMM YYYY");
		}
	})
	.filter('getMonthAndYear', function() {
		return function(input) {
			return moment(input).format("MMMM YYYY");
		}
	})
	.filter('monthYearFromUTC', function() {
		return function(input) {

			return moment(input).format("MMMM YYYY");
		}
	})
	.filter('getDateTime', function() {
		return function(input) {
			if(!input){
				return ""
			}
			return moment.utc(input).format("HH:mm DD.MM.YYYY");
		}
	})
    .filter('getDateLocal', function() {
        return function(input, time, format) {
        	//var format;
            if(!input){
                return ""
            }
            if(time){
				(format) ? format = 'HH:mm '+ format :format = 'HH:mm DD.MM.YYYY';
			}else{
                (!format) && (format = 'DD.MM.YYYY');
			}
            var localTime  = moment.utc(input).toDate();
            return moment(localTime).format(format);
        }
    })
	.filter('activeRus', function() {
		return function(input) {
			var result = "Не активен";
			if(input) result = "Активен";
			return result;
		}
	})
	.filter('displayStatus', function() {
		return function(input) {
			if(input === "PLANNING") {
				return "Запланирована";
			} else if(input === "EDITING"){
				return "Редактируется";
			} else if(input === "REVIEW"){
				return "На утверждении";
			} else if(input === "MISSED") {
				return "Проверка не состоялась";
			} else if(input === "ACCEPTED") {
				return "Подтверждена директором";
			} else if(input === "ACCEPTEDBYSYSTEM") {
				return "Проверка завершена";
			}
		}
	})
    .filter('taskTypeName', function(projectData) {
        return function(input) {
            if (input === "COMMON") {
                return "Общая";
            } else if (input === "PHOTO_REPORT") {
                return projectData.photoReport;
            } else if (input === "ROUTED") {
                return "Перенаправленная";
            } else if (input === "CHECKLIST") {
                return "Чек-лист";
            } else if (input === "CLEANING") {
                return "Форма сбора";
            } else if (input === "FORMS") {
				return 'Проверка наличия';
			} else if (input === "MANUAL_RECOUNT") {
				return 'Выборочный пересчет';
			} else if (input === "AUTO_RECOUNT") {
				return 'Плановый пересчет';
			} else if (input === "EQUIPMENT") {
                return 'Сбор данных';
            } else if (input === 'UNSOLD_GOODS') {
				return 'Непродающиеся товары';
			} else if (input === 'COLLECTION_DELIVERY') {
				return 'Формирование заказа на доставку';
			} else if (input === 'COLLECTION_PICKUP') {
				return 'Формирование заказа на самовывоз';
			} else if (input === 'APPROVAL_DELIVERY') {
				return 'Согласование заказа на доставку';
			} else if (input === 'APPROVAL_PICKUP') {
				return 'Согласование заказа на самовывоз';
			} else if (input === 'DISBANDMENT') {
				return 'Расформирование заказа';
			} else if (input === 'METER') {
				return 'Показания счётчиков';
			} else if (input === 'EXPIRATION_DATE_ADD') {
				return 'Внесение сроков годности';
			} else if (input === 'EXPIRATION_DATE_CHECK') {
				return 'Проверка сроков годности';
			} else if (input === 'VIDEO_MONITORING') {
				return 'Видео-аналитика';
			} else if (input === 'INVENTORY_PREPARATION') {
				return 'Подготовка к инвентаризации';
			} else {
            	return input
            }
        }
    })
	.filter('taskGroupName', function(projectData) {
		return function(input) {
			if(input === "COMMON") {
				return "Общая";
			} else if(input === "PHOTO_REPORT"){
				return projectData.photoReport;
			} else if(input === "ROUTED"){
				return "Перенаправленная";
			} else if(input === "CHECKLIST"){
				return "Чек-лист";
			} else if(input === "CLEANING" || input === "FORMS"){
				return "Форма сбора";
			}else{
				return input
			}
		}
	})


	.filter('statusActionIcon', function(Principal) {
		return function(input, isResolverIdEqualUserId) {
            //planning, editing, review, accepted, fixed, missed,
            var ans = undefined;
            switch(input){
                case "PLANNING":
					if (isResolverIdEqualUserId) {
						ans = "fa-folder-open-o";
					} else {
						ans = "fa-search";
					}
                    break;
                case "EDITING":
					if (isResolverIdEqualUserId) {
						ans = "fa-pencil";
					} else {
						ans = "fa-search";
					}
                    break;
                case "REVIEW":
                    ans = Principal.isInRole('ROLE_DIRECTOR') ? "fa-folder-open-o" : "fa-search";
                    break;
                case "ACCEPTED":
                case "ACCEPTEDBYSYSTEM":
                case "FIXED":
                case "MISSED":
                    ans = "fa-search";
                    break;
                default:
                    ans = "n/a";
                    break;
            }
            return " " + ans;
        }
    })

	.filter('actionFormatter', function () {
		var actions = {
			added: 'Добавлена',
			deleted: 'Удалена',
			change: 'Изменена'
		};

		return function (action) {
			return actions[action];
		}
	})

	.filter('roleInTaskFormatter', function () {
		var actions = {
			EXECUTOR: 'Ответственный',
			AUTHOR: 'Автор',
			OBSERVER: 'Наблюдающий'
		};

		return function (action) {
			return actions[action];
		}
	})

	.filter('booleanFormatter', function () {
		var values = {
			true: 'Да',
			false: 'Нет'
		};

		return function (value) {
			return values[value];
		}
	})

	.filter('statusTaskFormatter', function () {
		var statuses = {
			OPEN : 'Новая',
			IN_PROGRESS: 'В работе',
			CLOSED: 'Завершена',
			ALL: 'Все'
		};

		return function (status) {
			return statuses[status];
		}
	})

	.filter('statusGoodsFormatter', function () {
		return function (status) {
		let result;

		switch(status){
			case "NEW":
				result = "Новый";
				break;
			case "ACCEPTED":
				result = "Принят";
				break;
			case "PARTIALLY_ACCEPTED":
				result = "Принят частично";
				break;
			case "REJECTED":
				result = "Отклонен";
				break;
			case "IN_PROGRESS":
				result = 'В работе';
				break;
			case "CLOSED":
			case "SENT":
				result = "Закрыт";
				break;
			case "MONTHS_LAST_DAY":
				result = "Последний день месяца";
				break;
		}
			return result;
		}
	})

	.filter('myStatusTaskFormatter', function () {
		var statuses = {
			OPEN : 'Ваша новая задача',
			IN_PROGRESS: 'Вами взята в работу',
			CLOSED: 'Вами завершена'
		};

		return function (status) {
			return statuses[status];
		}
	})

	.filter('uniqueItems', function () {
		return function(input, keyname) {
			var result = [],
				keys = [];

			angular.forEach(input, function(item) {
				if (item.type === "shop" || ((item.type === "region" || item.type === "division") && (!item.children || item.children.length === 0))){
					var key = item[keyname];
					if(keys.indexOf(key) === -1) {
						keys.push(key);
						result.push(item);
					}
				}
			});

			return result;
		};
	})

    .filter('periodicTaskInfo', function ($filter) {
        return function(input) {
            var result;
            var weekDay;
            var weekDays = [];
            switch(input.type){
                case "DAILY":
                    result = "Ежедневно";
                    break;
                case "WEEKLY":
                	angular.forEach(input.daysOfWeek, function(item){
                        weekDay = $filter('daysOfWeekFull')(item);
                        weekDays.push(weekDay);
					});
                	if(weekDays.length > 0) {
                        weekDays = $filter('sortDaysOfWeek')(weekDays);
                        result = weekDays.join(', ');
                    }
                    break;
                case "MONTHLY":
                	result = input.dayOfMonth + "-й день месяца";
                    result = "Последний день месяца";
                    break;
                case "MONTHS_LAST_DAY":
                    result = "Последний день месяца";
                    break;
            }
            if(result){
            	return result;
			}
			return input
        };
    })

    .filter('sortDaysOfWeek', function($filter) {
        return function(input) {
        	var newWeekDays = [];
        	if (!input || input.length === 0) return input;
            var weeeks = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
        	angular.forEach(weeeks, function(item){
				(input.indexOf(item) !== -1) && (newWeekDays.push(item));
			});
			return newWeekDays
        }
    })

	.filter('executorRolesListPermissions', function () {
		return function(input) {
			var result = [];
			angular.forEach(input, function(item) {
				if (item.permissions){
					result.push(item);
				}
			});
			return result;
		};
	})

	// Deprecated. Use formatDate
    .filter('parseDate', function() {
        return function(input) {
            return input && input.split("T")[0].split("-")[2] + "-" + input.split("T")[0].split("-")[1] + "-" + input.split("T")[0].split("-")[0];
        }
    })

	.filter('formatDate', function(){
		return function(input, format, isLocal){
			if(input){
				format = format || 'DD.MM.YYYY';

				if (isLocal) {
					return moment.utc(input).local().format(format || 'YYYY-MM-DD HH:mm:ss')
				} else {
					return moment.utc(input).format(format);
				}
			}else{
				return '';
			}
		}
	})

.filter('formatDefault', function(){
        return function(input){
            if(input){
                return moment.utc(input).format();
            }else{
                return '';
            }
        }
    })

	.filter('houtNodeType', function(){
		return function(input, genetive) {
			var result = input;
			switch(input){
				case "group":
					result = "групп"+(genetive)?"ы":"а"+" категорий";
					break;
				case "categorie":
					result = "категори"+(genetive)?"и":"я";
					break;
				case "question":
					result = "вопрос"+(genetive)?"а":"";;
					break;
			}
			return result;
		}
	})

	.filter('toFixed', function(){
		return function(input, num, def){
			input = parseFloat(input);
			if(!isNaN(input)){
				return input.toFixed(num);
			}else{
				return def || '';
			}
		}
	})
    .filter('resolveDateFilter', function() {
        return function(input, incrementDays) {
            var resolveDate = new Date(input);
            var fixDate = new Date(resolveDate);
            fixDate.setDate(resolveDate.getDate() + incrementDays);
            return fixDate.getDate().toString().replace( /^([0-9])$/, '0$1' ) + "." + (fixDate.getMonth() + 1).toString().replace( /^([0-9])$/, '0$1' ) + "." + fixDate.getFullYear();
        }
    })
    .filter('gradeFormatter', function() {
        return function(input) {
	        input = parseFloat(input);
	        if(isNaN(input)) return '0.00';
	        return input.toFixed(2);
        }
    })

	//Source http://plnkr.co/edit/a3KlK8dKH3wwiiksDSn2?p=preview
	.filter('propsFilter', function() {
		return function(items, props, options) {
			var defaultOptions = {
				ignoreEmptyArray: false
			};
			options = angular.extend(defaultOptions, options);
			var out = [];

			if (angular.isArray(items)) {
				items.filter(function(e){return e;}).forEach(function(item) {
					var itemMatches = false;

					var keys = Object.keys(props);
					for (var i = 0; i < keys.length; i++) {
						var prop = keys[i];
						if(props[prop] != null) {
							if(angular.isString(props[prop]) && item[prop] != null){
								var text = props[prop].toLowerCase();
								if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
									itemMatches = true;
									break;
								}
							}else if(angular.isArray(props[prop]) && !angular.isArray(item[prop])){
								 if(props[prop].length){
									 var value = item[prop];
									 angular.forEach(props[prop], function(e){
										 if(angular.equals(value, e)){
											 itemMatches = true;
										 }
									 });
								}else if(options.ignoreEmptyArray){
									itemMatches = true;
								}
								if(itemMatches) break;
							}
						}

					}

					if (itemMatches) {
						out.push(item);
					}
				});
			} else {
				// Let the output be the input untouched
				out = items;
			}

			return out;
		}
	})
	.filter('getFileName', function() {
		return function(input) {
			if (input) {
				return (input.match(/[^/]+$/g)[0]);
			} else {
				return '';
			}
		}
	})
	.filter('getNumEnding', function () {
		return function (num, cases) {
			if (!num) return;
			cases = {nom: cases.nom, gen: cases.gen, plu: cases.plu}; //например {nom:'файл',gen:'файла',plu:'файлов'}
			num = Math.abs(num);
			var word = '';
			if (num.toString().indexOf('.') > -1) {
				word = cases.gen;
			} else {
				word = (
					num % 10 == 1 && num % 100 != 11
						? cases.nom
						: num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
						? cases.gen
						: cases.plu
				);
			}
			return num + " " + word;
		}
	})
	.filter('searchExecutor', function () {
		return function (input, searchCriteria) {
			if (!angular.isDefined(searchCriteria) || searchCriteria === '') {
				return input;
			}

			const term = searchCriteria.request.toLowerCase().trim();
			return input.reduce((acc, item) => {
				if (searchCriteria.type === 'SHOP') {
					const matchedRegionsWithShops = item.children.reduce((filteredRegs, reg) => {
						const matchedShops = reg.children.reduce((filteredShops, shop) => {
							const dirName = (shop.dir && shop.dir.fullName) || '';
							const string = shop.sap + shop.locality + dirName + shop.clusterName + shop.groupShopName;

							if (string.toLowerCase().includes(term)) {
								filteredShops.push(angular.copy(shop));
							}
							return filteredShops;
						}, []);

						if (matchedShops.length) {
							filteredRegs.push(Object.assign({}, reg, { children: matchedShops }));
						}
						return filteredRegs;
					}, []);

					if (matchedRegionsWithShops.length) {
						acc.push(Object.assign({}, item, { children: matchedRegionsWithShops }));
					}
				}
				if (searchCriteria.type === 'REGION') {
					const matchedRegions = item.regions.reduce((filteredRegs, reg) => {
						const matchedDirs = reg.dirs.reduce((filteredDirs, dir) => {
							const string = dir.email + dir.fullName;

							if (string.toLowerCase().includes(term)) {
								filteredDirs.push(angular.copy(dir));
							}
							return filteredDirs;
						}, []);

						if (matchedDirs.length) {
							filteredRegs.push(Object.assign({}, reg, { dirs: matchedDirs }));
						}
						return filteredRegs;
					}, []);

					if (matchedRegions.length) {
						acc.push(Object.assign({}, item, { regions: matchedRegions }));
					}
				}
				if (searchCriteria.type === 'DIVISION') {
					const matchedDirs = item.dirs.filter(dir => {
						const string = dir.firstName + dir.lastName + dir.email;
						return string.toLowerCase().includes(term);
					});

					if (matchedDirs.length) {
						acc.push(Object.assign({}, item, { dirs: matchedDirs }));
					}
				}
				return acc;
			}, []);
		}
	})
    .filter('getAnswerType', function() {
        return function(input) {
            if(input === "NUMBER") {
                return "Число";
            } else if(input === "TEXT"){
                return "Текст";
            } else if(input === "IMAGE"){
                return "Изображение";
            }
        }
    })
    .filter('getFilterName', function() {
        return function(input) {
            var l = {
                "filter1": "Проверки",
                "filter2":"Оценка за период",
                "filter3":"Отчет по календарю",
                "filter4":"Привязка магазина к проверяющему",
                "filter5":"Количество посещений",
                "filter6":"Отчет по Акту аккредитации магазинов",
                "filter7":"Отчет по Акту аккредитации (детально)",
                "filterHR":"Отчет HR",
                "xfilter1":"План/Норма",
                "xfilter2":"План/Факт",
                "xfilter3":"Проверки",
                "xfilter4":"Проверки & процессы",
                "xfilter5":"Задания",
                "xfilter6":"Отклонения",
                "xfilter7":"Сводный",
                "xfilter8":"Проверки-процессы-вопросы",
                "xfilter9":"Блоки-подблоки-процессы",
                "xfilter10":"ДМ, не заполняющие чеклисты",
                "xfilter11":"Отчет по клинингу",
                "xfilter12":"Отчет по клинингу (детально)",
                "filterForms":"Отчет по проверке наличия",
                "filterFormsResults":"Отчет по итогам проверки наличия",
                "filterLogs": 'Отчет по выгрузке логов',
                "transactions": 'Транзакции по картам лояльности',
                "reportInspectors": "Перемещение ревизоров",
                "reportManagerCoordinates": "Перемещение менеджеров по продажам",
            };
            return (l[input])?l[input]:input
        }
    })
	.filter('capitalizeLetter', function() {
		return function(input) {
			return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
		}
	})
    .filter('deletePropertyFilter', function () {
        return function(input) {
			if(input == true) {
			   return "удален";
			}
        }
    })
    .filter('deletePropertyView', function () {
        return function(input) {
            if(input == true) {
                return "Удален";
            }
            return "Не удален"
        }
    })
	.filter('inspectionActStatuses', function() {
		return function(input) {
			var status;

			switch (input) {
				case 'NEW':
					status = 'Акт создан';
					break;
				case 'SAVED':
					status = 'Акт сохранен';
					break;
				case 'CHECKED_REGION_DIRECTOR':
					status = 'Акт проверен начальником дп';
					break;
				case 'CHECKED_BY_DATE':
					status = 'Акт проверен со сроками устранения';
					break;
				case 'FIXING':
					status = 'Устранение замечаний';
					break;
				case 'FIXED':
					status = 'Замечания устранены';
					break;
				case 'ACCEPTED':
					status = 'Объект принят';
					break;
				case 'EXPORTED':
					status = 'Акт выгружен в босс';
					break;
				case 'DECLINED_BY_ACCOUNTER':
					status = 'Отклонен бухгалтером ос';
					break;
				case 'SEND_TO_ACCEPTION':
					status = 'Отправлен на повторное согласование';
					break;
				case 'NOT_SENT':
					status = 'Не отправлен в SAP';
					break;
				default:
					status = '';
					break;
			}

			return status;
		}
	})
	.filter('stringReplace', function() {
		return function(input, from, to) {
			if (input === undefined) {
				return;
			}

			var regex = new RegExp(from, 'g');
			return input.replace(regex, to);
		}
	})
	.filter('localcodeLeadZerosRemove', function() {
		return function(localcode) {
			return localcode.slice(11);
		}
	});

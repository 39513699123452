/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 11.06.15.
 */

(function () {
	'use strict';

	angular.module('smartApp')

		.controller('CountersReportController', countersReportController);


	countersReportController.$inject = ['$scope', 'ObjectServiceForDivisions', 'CounterService', 'Principal', 'FormFactory', 'ngTableParams', 'DownloadService'];

	function countersReportController($scope, DivisionService, CounterService, Principal, FormFactory, TableParams, DownloadService){

		$scope.createForm = createForm;
		$scope.open = openCalendar;
		$scope.getDivisions = getDivisions;
		$scope.setSelected = setSelected;
		$scope.selectAll = selectAll;
		$scope.loadReport = loadReport;
		$scope.getMonthEntry = getMonthEntry;
		$scope.downloadReport = downloadReport;

		$scope.showExtend = showExtendData;
		$scope.divisions = [];

		$scope.Months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Ноябрь', 'Декабрь'];
		$scope.select = null;
		$scope.showForm = false;
		$scope.isSelectAll = false;
		$scope.enableReport = false;
		$scope.filters = {
			dateFrom: null,
			dateTo: null,
			divisions: []
		};
		$scope.calendar = {
			calendars: {
				from: {
					isOpen: false
				},
				to: {
					isOpen: false
				}
			},
			open: openCalendar,
			maxDate: new Date(),
			format: 'dd.MM.yyyy'
		};
		$scope.reportResult = [];
		$scope.tableParams = new TableParams({
			page: 1,
			count: 10
		}, {
			total: 0,
			getData: function ($defer, params) {
				var _this = this;
				$scope.searchProgress = true;
				var page = params.page();
				var limit = params.count();
				var divisions = $scope.filters.divisions.map(function(e){return e.id});
				CounterService.getReport(divisions, page, limit).success(function(result, status, headers){
					if(status === 200){
						$defer.resolve(result);
					}
					_this.total = headers('x-total-count');
					$scope.searchProgress = false;
				});
			}
		});


		init();

		function init(){
			if(Principal.isInRole(Principal.ROLES.ROLE_MAIN_ENGINEER)){
				loadDivisions();
				$scope.showForm = true;
			}else{
				loadReport();
			}
		}

		function loadDivisions(){
			DivisionService.query({}, function(result) {
				$scope.divisions = result;
			});
		}

		function showExtendData(row, flag){
			row.$extend = flag;
			if(!row.$extendData){
				row.progress = true;
				CounterService.getExtendReport(row.counterId, new Date(2010, 1, 1), new Date()).success(function(data, status){
					if(status === 200){
						row.progress = false;
						var obj =  data.reduce(function(prev, element){
							if(!prev[element.year]){
								prev[element.year] = {
									year: element.year
								}
							}
							element.monthName = $scope.Months[element.month + 1];
							prev[element.year][element.month] = element;
							return prev;
						}, {});
						row.$extendData = _.values(obj);
					}
				})
			}
		}

		function getDivisions(){
			return $scope.divisions;
		}

		function selectAll(flag){
			if(flag){
				angular.forEach($scope.select.items, function(item){
					$scope.select.select(item);
				})
			}else{
				var size = $scope.select.selected.length;
				for(var i = 0; i < size; i++){
					$scope.select.removeChoice(0);
				}
			}
		}

		function setSelected(e){
			$scope.select = e;
			e.onRemoveCallback = function(scope){
				updateSelectedFlag(scope.$select);
			};
			e.onSelectCallback = function(scope){
				updateSelectedFlag(scope.$select);
			};
		}

		function updateSelectedFlag(select){
			if(select.selected.length === 0){
				$scope.isSelectAll = false;
			}else if(select.items.length === 0){
				$scope.isSelectAll = true;
			}else{
				$scope.isSelectAll = null;
			}
		}

		function loadReport(){
			$scope.enableReport = true;
			$scope.tableParams.reload();
		}

		function downloadReport() {
			DownloadService.downloadEngineerReport($scope.filters.divisions.map(function(e){return e.id}));
		}

		function createForm(form){ // TODO: to directive
			return new FormFactory(form);
		}

		function openCalendar($event, id) {
			$event.preventDefault();
			$event.stopPropagation();

			angular.forEach($scope.calendar.calendars, function(e){e.isOpen = false;});
			$scope.calendar.calendars[id].isOpen = true;
		}

		function getMonthEntry(months, monthName){
			var index = $scope.Months.indexOf(monthName);
			if(index >= 0){

			}

			return $scope.Months[month + 1];
		}

	}
})();

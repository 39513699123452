/**
 * Created by tyurin on 26.05.15.
 */
'use strict';

angular.module('smartApp').constant('ApiErrors', {
    'EAUU7':{
        title: 'Изменение роли невозможно, т.к. директор группы магазинов имеет привязку к группе магазинов.'
    },
	'EAUC1': {
		title: 'Супервайзеры не могут быть созданы через веб-интерфейс'
	},
    'EAGC1': {
        title: 'Нельзя создать группы с указанным айди'
    },
    'EAGC2': {
        title: 'Нельзя создать группу: указанные роли конфликтуют'
    },
    'EAGU1': {
        title: 'Нельзя обновить группу: указанные роли конфликтуют'
    },
    'EAGD1': {
        title: 'Нельзя удалить группу: у указанной группы имеются активные привязки'
    },
    'EAAU1': {
        title: 'Нельзя обновить роль: указанная роль не найдена'
    },
    'EAAU2': {
        title: 'Нельзя обновить роль: данную роль нельзя редактировать'
    },
    'EACU5': {
        title: 'Указан неверный директор кластера'
    },
    'EAAU4': {
        title: 'Не все привязки обновлены: данная роль конфликтует с ролями привязанных объектов'
    },
    'EAUC2': {
        title: 'Нельзя создать пользователя: указанные роли конфликтуют'
    },
	'EAUC3': {
		title: 'Пользователь с таким логином уже существует'
	},
    'EAUU1': {
        title: 'Нельзя обновить пользователя: указан неверный айди'
    },
    'EAUU2': {
        title: 'Нельзя обновить пользователя: указанные роли конфликтуют c текущими ролями пользователя'
    },
    'EAUU3': {
        title: 'Нельзя обновить пользователя: указанные роли конфликтуют c текущими ролями групп пользователя'
    },
    'EAUD1': {
        title: 'Не все пользователи удалены: проверьте привязки и роли указанных пользователей'
    },
    'EAGU2': {
        title: 'Нельзя обновить группу: роль супервайзера запрещено присваивать группам'
    },
    'EAUU4': {
        title: 'Нельзя обновить пользователя: роль супервайзера запрещено присваивать пользователям'
    },
    'EADU1': {
        title: 'Дивизион должен иметь хотя-бы одного куратора'
    },
    'ECDC1': {
        title: 'Нельзя удалить эту проверку согласно правилам удаления проверок'
    },
    'ECCC1': {
        title: 'Проверка по данной анкете и магазину на сегодня уже запланирована'
    },
    'ECUC1': {
        title: 'Переносить дату можно только у запланированных проверок'
    },
    'ECUC2': {
        title: 'Проверка по данной анкете и магазину на сегодня уже запланирована'
    },
    'EAUU6': {
        title: 'Невозможно удалить роль. Убедитесь в том, что нет активных привязок'
    },
	'EXA1': {
		title: 'Директор кластера не привызан к кластеру. Обратитесь к администратору'
	},
	'EXA2': {
		title: 'Пользователь не привязан к отделу продаж'
	},
	'TST1': {
		title: 'Шаблон с таким именем уже существует'
	},
	'ETDT': {
		title: 'Невозможно удалить чек-лист для которого уже существуют проверки'
	},
	'EXA3': {
		title: 'Супервайзер не может подключаться из внешних сетей'
	},
	'MAIN1': {
		title: 'По вашему запросу ничего не найдено'
	},
	'ESORG1': {
		title: 'Привязанных магазинов не найдено',
        warning: true
	},
	'ESORG2': {
		title: 'Привязанным магазинам не указаны лимиты.',
        warning: true
	},
	'ESORG3': {
		title: 'Не всем магазинам указаны лимиты.',
        warning: true
	},
	'ECUC4': {
		title: 'Время работы над данной проверкой истекло'
	},

    'EADC2': {
      title: 'Отдел продаж с таким наименованием уже существует'  
    },
  'PasswordIsWeakException': {
      title: 'Пароль не должен быть равен wrs1234 или логину» введите новый пароль еще раз'
  },
  'NoUserFoundForEmail': {
      title: 'E-mail магазина введен неверно, проверьте правильность ввода e-mail или обратитесь в Help, приложив скрин экрана'
  },
  'UserHaveNoEmailException': {
      title: 'В системе не прописан E-mail магазина, обратитесь в Help приложив, скрин экрана'
  },
  'MailSendingException': {
      title: 'Ошибка отправки email. Обратитесь в Help, приложив скрин экрана'
  }
});

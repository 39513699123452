angular.module('smartApp').controller('QualityControlController', function($scope, $http, OrgstructService, Notifications, QualityControlService) {

    $scope.calendarsOpen = [];
    $scope.saps  = [];
    $scope.shops = [];
    $scope.filters = {periodData: null};

    init();

    $scope.openCalendar = openCalendar;
    $scope.getExcel = getExcel;
    $scope.refreshShops = refreshShops;
    $scope.getSap = getSap;

    function init(){
        $scope.shops = [];
        OrgstructService.getShops().then(function(result) {
            if(result.status === 200){
                $scope.shops = result.data;
            }else{
                Notifications.danger('Ошибка! Невозможно загрузить список магазинов');
            }
        }, function(error) {
            Notifications.danger('Ошибка! Невозможно загрузить список магазинов');
            $log.log(error);
        });
    }

    function openCalendar ($event, id) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.calendarsOpen[1] = false;
        $scope.calendarsOpen[2] = false;
        $scope.calendarsOpen[id] = true;
    }

    function refreshShops (search) {
        if(search.length >= 2){
            OrgstructService.getShops({sap: search}).then(function(result) {
                if(result.status === 200){
                    $scope.shops = result.data;
                    //initShopSelect();
                }else{
                    $scope.shops = [];
                    Notifications.danger('Ошибка! Невозможно загрузить список магазинов');
                }
            }, function(error) {
                $scope.shops = [];
                Notifications.danger('Ошибка! Невозможно загрузить список магазинов');
                $log.log(error);
            });
        }else{
            $scope.shops.splice(0, $scope.shops.length);
        }
    }

    function getSap(shops){
        $scope.saps = shops.map(function (shop) {
            return shop.sap;
        });
    }

    function getExcel(){
        /*if ($scope.saps.length === 0){
            Notifications.danger('Ошибка! Не выбран ни один магазин');
            return;
        }else*/
        if(!$scope.filters.periodData || !$scope.filters.periodData.from){
            Notifications.danger('Ошибка! Не выбрана дата начала расчета');
            return;
        }else if(!$scope.filters.periodData.to){
            Notifications.danger('Ошибка! Не выбрана дата окончания расчета');
            return;
        }

        //sap = $scope.saps[0].sap;

        var filters = {
            from: moment($scope.filters.periodData.from).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
            to: moment($scope.filters.periodData.to).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
        };

        QualityControlService.getExcel($scope.saps, filters).then(function(result) {
            if(result.status === 200 || status === 201){
                var blob = new Blob([result.data], {
                    type: "application/vnd.ms-excel"
                });

                 var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    link.download = "["+moment().format('DD-MM-YYYY')+"]_quality_control_.xls";
                    link.click();

                Notifications.success('Файл успешно загружен');
            }else if(result.status === 204) {
                Notifications.danger('Нет данных для выгрузки');
            }else{
                Notifications.danger('Ошибка! Невозможно сформировать файл');
            }
        }, function(error) {
            Notifications.danger('Ошибка! Невозможно сформировать файл');
            $log.log(error);
        });
    }
});
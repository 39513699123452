/**
 * Created by tyurin on 27.05.15.
 */
'use strict';

angular.module('smartApp')

	.factory('FormFactory', formFactory);

formFactory.$inject = [];

function formFactory(){
	var Factory = function(form){
		var _this = this;
		this.form = form;
		this.submitError = false;
	};

	Factory.prototype.isShowError = function(name, error){
		var input = this.form[name];
		if(input){
			return (this.submitError || !input.$pristine) && input.$invalid && (!error || input.$error[error]);
		}else{
			return this.submitError;
		}
	};

	Factory.prototype.reset = function(){
		this.form.$setPristine(false)
	};

	Factory.prototype.submit = function(success, error, reset){
		this.submitError = this.form.$invalid || ((typeof(success) === 'function') && (success() === false));
		if(this.submitError && angular.isFunction(error))
			error();
		if(!this.submitError && reset)
			this.reset();
	};

	return Factory;
}




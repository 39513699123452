'use strict';

angular.module('smartApp').factory('TWTemplateService', function TWTemplateService($http, config, HttpUtils, Notifications) {

    return {

        getAllWithParams: function (filters, cb) {
            var  params = {}
            if(filters){
                if(filters.active != undefined)filters.active = filters.active.toString()
                params = {
                    search: filters.keyword,
                    activity: filters.active,
                    hasChecklists: filters.hasChecklists
                };
            }

            var query = HttpUtils.getQuery('/admin/constructor/templates', params);



            $http.get(query, {noThrobber: true}).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        createTemplate: function(template, cb) {
            var link = config.backend + '/admin/constructor/templates';
            return $http.post(link, template, {noThrobber: true}).success(
                function(result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },

        copyTemplate: function(template) {
            var link = config.backend + '/admin/constructor/templates/copy';
            return $http.post(link, template, {noThrobber: true});
        },

        switchTemplate: function(template, cb) {
            $http.put(config.backend + '/admin/constructor/templates/active/' + template.id, {active:((template.active)?false:true)}, {noThrobber: true}).success(
                function (result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },

        getProcessBlocks: function(template, hasNoThrobber, cb) {

            $http.get(config.backend + '/admin/constructor/templates/' + template.id + '/processes/accreditation', {
                params: {
                    active: !template.active
                },
                noThrobber: hasNoThrobber
            }).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },
        saveQuestionsOrderInTemplate: function(templateId, array, cb){
            $http.put(config.backend + '/admin/constructor/templates/'+templateId+'/questions/order', {questionIdsOrdered:array}, {noThrobber: true}).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },
        getTemplate: function(id, cb){
            async.parallel([
                function(callback){
                    $http.get(config.backend + '/admin/constructor/templates/'+id+'/questions', {}).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
                function(callback){
                    $http.get(config.backend + '/admin/constructor/templates/'+id, {}).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
                // function(callback){
                //     $http.get(config.backend + '/admin/zone/'+id, {}).success(
                //         function (result) {
                //             if (result.status && result.status != 200 && result.status != 204) {
                //                 callback(result.status);
                //             }
                //             callback(null, result);
                //         }
                //     );
                // },
            ],

            function(err, results){
              if(err){
                  Notifications.danger("Не найдены данные шаблона", "", 2000);
              }
                  results[1]["questions"] = results[0]
                  //results[1]["zones"] = results[2]["zones"]
                  //results[1]["showType"] = results[2]["type"]
                  cb(null, results[1])
            })


        },
        getTemplatesBusinessDirs: function(templateId, hasNoThrobber) {

            return $http.get(config.backend + '/templates/' + templateId + '/business-dirs', {
                noThrobber: hasNoThrobber
            });
        },
        updateTemplatesBusinessDirs: function(templateId, businessDirsIds){
            return $http.put(config.backend + '/templates/' + templateId + '/business-dirs/' + businessDirsIds);
        },
        deleteTemplatesBusinessDirs: function(templateId){
            return $http.delete(config.backend + '/templates/' + templateId + '/business-dirs');
        },
        saveTemplate:function(template, cb) {
            $http.post(config.backend + '/admin/constructor/templates', template, {noThrobber:true}).then(
                function (result) {
                    if (result.status && result.status !== 200 && result.status !== 204) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },
        saveZones: function(template, cb){
            $http.put(config.backend + '/admin/zone/changeOrder ', template).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },

        getZones: function(cb){
            $http.get(config.backend + '/admin/constructor/zones', {}).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        addZone: function(newZone, cb){
            $http.post(config.backend + '/admin/constructor/zones', newZone).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        renameZone: function(newNameZone, cb){
            $http.put(config.backend + '/admin/constructor/zones', newNameZone).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        questionsInZone: function(questions, zoneId, cb){
            $http.put(config.backend + '/admin/constructor/zones/' + zoneId + '/questions/move', questions).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        viewModeTemplates: function(template, type, cb){
            $http.put(config.backend + '/admin/constructor/templates/' + template.id + '/viewMode/' + type).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },

        saveActParams: function(processBlocks, template, cb){
             async.parallel([
                function(callback){
                     $http.put(config.backend + '/admin/constructor/templates/'+template.id+'/processes/accreditation', processBlocks).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
                function(callback){
                    $http.post(config.backend + '/admin/constructor/templates', template).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
            ],

            function(err, results){
                cb((err)?err:null)
            })
        },
        saveTemplateWithQuestions: function(template, questions, cb){
             async.parallel([
                function(callback){
                     $http.put(config.backend + '/admin/constructor/templates/'+template.id+'/questions', questions).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
                function(callback){
                    $http.put(config.backend + '/admin/constructor/templates/'+template.id, template).success(
                        function (result) {
                            if (result.status && result.status != 200 && result.status != 204) {
                                callback(result.status);
                            }
                            callback(null, result);
                        }
                    );
                },
            ],

            function(err, results){
                cb((err)?err:null)
            })
        },
        deleteTemplate: function(templateId, cb) {
            $http.delete(config.backend + '/admin/constructor/templates/' + templateId, {noThrobber:true}).success(
                function (result) {
                    if (result.status && result.status != 200) {
                        cb(result.status);
                    }
                    cb(null, result);
                }
            );
        },
        addQuestionsToTemplate: function(questions, templateId, cb){

             $http.post(config.backend + '/admin/constructor/templates/' + templateId + '/questions/', questions, {noThrobber:true}).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                    }
                    cb(null, result);
                }
            );

        },

        addRecipients: function(type, templateId, userIds, cb){
            $http.post(config.backend + '/admin/template/mail/' + templateId + '/' + type, userIds).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                    }
                    cb(null, result);
                }
            );
        },

        getRecipients: function(type, templateId, hasNoThrobber, cb){

            $http.get(config.backend + '/admin/template/mail/' + templateId + '/' + type, {
                noThrobber: hasNoThrobber
            }).success(
                function (result) {
                    if (result.status && result.status !== 200 && result.status !== 204) {
                        cb(result.status, result);
                    }
                    cb(null, result);
                }
            );
        },

        removeRecipients: function(type, templateId, userIds, cb){
            $http.delete(config.backend + '/admin/template/mail/' + templateId + '/' + type + '/' + userIds).success(
                function (result) {
                    if (result.status && result.status != 200 && result.status != 204) {
                        cb(result.status, result);
                    }
                    cb(null, result);
                }
            );
        }
    }
});

<template>
    <div class="blockCreator row align-items-center justify-content-center">
        <div class="col">
            <label>Платформа</label>
            <single-select
                id="banner-platform"
                v-model="selectedPlatform"
                placeholder="Выберите платформу"
                :options="filteredPlatformOptions"
            ></single-select>
        </div>

        <div class="col">
            <label>Баннер блок</label>
            <single-select
                id="banner-block"
                v-model="selectedBlock"
                placeholder="Выберите блок"
                :options="filteredBlockOptions"
            ></single-select>
        </div>

        <div class="col align-self-end">
            <custom-button
                text="Добавить Баннер-Блок"
                variant="grey"
                @click="handleCreate">
            </custom-button>
        </div>
    </div>
</template>

<script>
import { SingleSelect, CustomButton } from '@eron/ui-kit';

export default {
    name: 'BannerBlockCreator',
    components: {
        SingleSelect,
        CustomButton,
    },
    props: {
        currentBlocks: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedPlatform: null,
            selectedBlock: null,
            blocksOptions: [
                {
                    value: 'main',
                    label: 'Основной баннер',
                },
                {
                    value: 'pinnedtext',
                    label: 'Текстовые рассылки',
                },
                {
                    value: 'fastlink',
                    label: 'Быстрые ссылки',
                },
            ],
        };
    },
    computed: {
        filteredPlatformOptions() {
            const options = [
                {
                    value: 'ANDROID',
                    label: 'Android',
                },
                {
                    value: 'WEB',
                    label: 'Web',
                },
            ];

            if (this.handledBlocksData.android?.count >= 3) {
                options.shift();
            }

            if (this.handledBlocksData.web?.count >= 3) {
                options.pop();
            }

            return options;
        },
        filteredBlockOptions() {
            const options = [
                {
                    value: 'main',
                    label: 'Основной баннер',
                },
                {
                    value: 'pinnedtext',
                    label: 'Текстовые рассылки',
                },
                {
                    value: 'fastlink',
                    label: 'Быстрые ссылки',
                },
            ];
            if (this.selectedPlatform?.toLowerCase() === 'web') {
                return [];
            }
            if (this.selectedPlatform) {
                if (this.handledBlocksData[this.selectedPlatform.toLowerCase()]?.names || this.handledBlocksData.hasOwnProperty(this.selectedPlatform.toLowerCase())) {
                    return options.filter(option => !this.handledBlocksData[this.selectedPlatform.toLowerCase()]?.names.includes(option.value));
                }
            } else {
                return options;
            }
        },
        handledBlocksData() {
            const platformData = {
                web: null,
                android: null,
            };
            this.currentBlocks.forEach(item => {
                const platform = item.platform.toLowerCase();
                const name = item.name;

                if (!platformData[platform]) {
                    platformData[platform] = { count: 0, names: [] };
                }

                platformData[platform].count += 1;
                platformData[platform].names.push(name);
            });
            return platformData;
        },
    },
    methods: {
        handleCreate() {
            if (this.selectedPlatform && this.selectedBlock) {
                this.$emit('blockCreated', {
                    platform: this.selectedPlatform,
                    block: this.selectedBlock,
                    description: this.blocksOptions.find(item => item.value === this.selectedBlock).label,
                });
                this.selectedPlatform = null;
                this.selectedBlock = null;
            } else alert('Заполните все поля');
        },
    },
};
</script>

<style lang="scss" scoped>
.blockCreator {
    margin-bottom: 60px;
}
</style>

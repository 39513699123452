(function() {
    'use strict';

    angular.module('smartApp').controller('ChartsController', chartsController);

    chartsController.$inject = [
        '$scope',
        '$state',
        '$timeout',
        '$window',
        'ngDialog',
        'Principal',
        'ChartsService',
        'ChartsConfig',
        'Notifications',
        'TWTemplateService',
        'PanelClustersService',
        'PWCategoryService',
        'PanelShopsResource',
        'PanelDivisionsService',
        'moment'
    ];

    function chartsController(
        $scope,
        $state,
        $timeout,
        $window,
        ngDialog,
        Principal,
        ChartsService,
        ChartsConfig,
        Notifications,
        TWTemplateService,
        PanelClustersService,
        PWCategoryService,
        PanelShopsResource,
        PanelDivisionsService,
        moment
    ) {
        $scope.dateFrom = moment().subtract(7, 'days').toDate();
        $scope.dateTill = moment().toDate();
        $scope.chart = {};
        $scope.calendarsOpen = [];
        $scope.settedFilters = [];
        $scope.templates = [];
        $scope.bottomCharts = [];

        var chartjsObjects = {};
        var chartsMap = {};
        var currentPanel = null;
        var controlChart = null;
        var filtersFromChart = {};
        var filters = {};

        Array.prototype.groupBy = function(prop) {
            return this.reduce(function(groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || [];
                groups[val].push(item);
                return groups;
            }, {});
        };

        $scope.$on('chart-create', function(e, chart) {
          var id = chart.canvas.id;
          chartjsObjects[id] = chart;
        });

        $scope.$on('$destroy', function() {
          $window.removeEventListener('resize', handleResize);
        });

        var updateChartsDimensions = function(chartjsObjMap) {
          var findAncestor = function(el, tagName) {
            var parent = el.parentElement;

            while (parent) {
              if (parent.tagName === tagName.toUpperCase()) {
                break;
              }

              parent = parent.parentElement;
            }

            return parent;
          };

          Object.keys(chartjsObjMap).forEach(id => {
            var canvas = chartjsObjMap[id].canvas;
            var chartElement = findAncestor(canvas, 'chart');
            var chartTitleElement = chartElement.getElementsByTagName('charttitle')[0];

            var chartHeight = chartElement.clientHeight;
            var chartTitleHeight = chartTitleElement ? chartTitleElement.clientHeight : 0;

            var graphHeight = chartHeight - chartTitleHeight;
            var graphWidth = chartElement.clientWidth;
            var canvasHeight = graphHeight;
            var canvasWidth = graphWidth;

            var viewChart = chartsMap[id];
            var optimalBarDimension = 45;

            switch (viewChart.type) {
              case 'horizontalBar':
                var oneBarHeight = graphHeight / viewChart.chartData.length;

                if (oneBarHeight < optimalBarDimension) {
                  canvasHeight = viewChart.chartData.length * optimalBarDimension;
                  canvasWidth -= 18;
                }
                break;
              case 'bar':
                var oneBarWidth = graphWidth / viewChart.chartData.length;

                if (oneBarWidth < optimalBarDimension) {
                  canvasWidth = viewChart.chartData.length * optimalBarDimension;
                  canvasHeight -= 18;
                }
                break;
            }

            viewChart.graphHeight = graphHeight;
            viewChart.graphWidth = graphWidth;
            viewChart.canvasHeight = canvasHeight;
            viewChart.canvasWidth = canvasWidth;
          });

          if(!$scope.$$phase) {
            $scope.$digest();
          }
        };

        var handleResize = function() {
          updateChartsDimensions(chartjsObjects);
        };

        $window.addEventListener('resize', handleResize);

        var init = function() {
            TWTemplateService.getAllWithParams({}, function(err, data) {
              var templates = [];
              for (var i in data) {
                if (data.hasOwnProperty(i)) {
                  templates.push(data[i]);
                }
              }

              $scope.templates = templates;
            });

            PanelClustersService.getAllClusters().then(function(result) {
              if (result.status !== 200) {
                  console.log('Error on getting clusters');
                  return;
              }

              $scope.clusters = result.data;
            });

            PanelShopsResource.getAll().then(function(result) {
              if (result.status !== 200) {
                console.log('Error on getting shops');
                return;
              }

              $scope.shops = result.data.map(shop => {
                  shop.name = shop.locality;
                  return shop;
              });
            });

            PanelDivisionsService.getDivisionsOrgstruct().then(function(result) {
              if (result.status !== 200) {
                console.log('Error on getting divisions');
                return;
              }

              $scope.divisions = result.data;
            });

            PWCategoryService.getProcesses().then(function(result) {
              $scope.processes = result;
            });

            currentPanel = ChartsConfig.getConfig()[0];

            updateCharts(currentPanel, getDefaultParams());
        };

        var handleChartDataLoad = function(chart, data) {
          var chartObj = null;

          if (!chartsMap[chart.id]) {
            var switchTypes = chart.switchTypes;

            if (switchTypes) {
              switchTypes = chart.switchTypes.slice();
              switchTypes.unshift(chart.type);
            }

            chartObj = ChartsService.getChart({
              id: chart.id,
              type: chart.type,
              title: chart.title,
              generateFrom: data,
              labelKey: chart.labelKey,
              dataKey: chart.dataKey,
              filter: chart.filter,
              styles: chart.getStyles(chart.type),
              switchTypes: switchTypes
            });
            chartsMap[chart.id] = chartObj;
          } else {
            chartObj = chartsMap[chart.id];
            chartObj.setData(data);
          }

          chartObj.isLoading = false;

          switch (chart.placement) {
            case 'mainCharts':
              $scope.chart = chartObj;
              break;
            case 'bottomCharts':
              $scope.bottomCharts[chart.placementIndex] = chartObj;
              break;
          }

          if (chartObj.hasData) {
            // wait digest cycle end
            $timeout(function () {
              updateChartsDimensions({
                [chart.id]: chartjsObjects[chart.id]
              });
            }, 0, false);
          }

          return chartObj;
        };

        var updateChart = function(panel, params, chartId) {
          var chart = ChartsConfig.getChartByIdInPanel(panel, chartId);
          return fetchChartData(chart, params);
        };

        var updateCharts = function(panel, params, skip) {
          var promises = [];

          panel.charts.forEach(chart => {
            if (skip && skip.indexOf(chart.id) !== -1) {
              return;
            }

            var promise = fetchChartData(chart, params);

            promises.push(promise);
          });

          return Promise.all(promises).then(() => highliightClickedInAllCharts());
        };

        var fetchChartData = function(chart, params) {
          if (chartsMap[chart.id]) {
            chartsMap[chart.id].isLoading = true;
          }

          return ChartsService[chart.dataFetchMethod](params).then(function(result) {
           if (result.status !== 200) {
             console.log('Error on getting data');
             return;
           }

           return handleChartDataLoad(chart, result.data);
         });
        };

        var getDefaultParams = function() {
          return {
            fromDate: moment($scope.dateFrom).format('DD-MM-YYYY'),
            toDate: moment($scope.dateTill).format('DD-MM-YYYY')
          };
        };

        var highlightClicked = function(chart, chartConfig) {
          var type = chart.type;
          var defaultStyles = chartConfig.getStyles && chartConfig.getStyles(type);
          var exceptSelectedStyles = chartConfig.getExceptSelectedStyles && chartConfig.getExceptSelectedStyles(type);
          var selectedStyles = chartConfig.getSelectedStyles && chartConfig.getSelectedStyles(type);

          if (defaultStyles) chart.setStylesAll(defaultStyles);
          if (exceptSelectedStyles) chart.setStylesExceptIndex(exceptSelectedStyles, chart.clickedIndexes);
          if (selectedStyles) chart.setStylesByIndex(selectedStyles, chart.clickedIndexes);
        };

        var resetStyles = function(chart, chartConfig) {
          chart.setStylesAll(chartConfig.getStyles(chart.type));
        };

        function highliightClickedInAllCharts() {
            const settedFiltersLabels = $scope.settedFilters.map(filter => filter.displayName);

            Object.values(chartsMap).forEach(chart => {
                chart.clickedIndexes = chart.clickedIndexes || [];

                if (chart.clickedIndexes.length) {
                    chart.clickedIndexes = chart.labels.reduce((acc, label, i) => {
                        if (settedFiltersLabels.includes(label)) {
                            acc.push(i);
                            return acc;
                        }
                        return acc;
                    }, []);

                    highlightClicked(chart, ChartsConfig.getChartByIdInPanel(currentPanel, chart.id));
                } else {
                    resetStyles(chart, ChartsConfig.getChartByIdInPanel(currentPanel, chart.id));
                }
            })
        }

        var resetChart = function(chart) {
            resetStyles(chart, ChartsConfig.getChartByIdInPanel(currentPanel, chart.id));
        };

        function toggleChartItem(chart, clickedLabel, filterData) {
            const chartId = chart.id;
            const chartConfig = ChartsConfig.getChartByIdInPanel(currentPanel, chartId);
            const clickedIndex = chart.labels.indexOf(clickedLabel);
            const isChartClickedItem = clickedIndex !== -1;
            const filterParamName = chart.filter.by;
            const firstGrouped = chart.groupedData[clickedLabel] && chart.groupedData[clickedLabel][0];
            const filterValue = firstGrouped ? firstGrouped[chart.filter.key] : filterData.id;

            updateFilters(filtersFromChart, filterParamName, filterValue);
            toggleSettedFilters(clickedLabel, filterParamName, filterValue, chartId);

            if (isChartClickedItem) {
                chart.clickedIndexes = chart.clickedIndexes || [];
                const index = chart.clickedIndexes.indexOf(clickedIndex);

                if (index !== -1) {
                    chart.clickedIndexes.splice(index, 1);
                } else {
                    chart.clickedIndexes.push(clickedIndex);
                }
            }

            if (Object.values(filtersFromChart).some(filter => Boolean(filter))) {
                highlightClicked(chart, chartConfig);
            } else {
                controlChart = null;
                updateCharts(currentPanel, Object.assign(getDefaultParams(), filters));
                return;
            }

            if (controlChart && controlChart.id !== chartId) {
                resetChart(controlChart);
            }

            controlChart = chart;
            var combined = combineFilters(filters, filtersFromChart);
            return updateCharts(currentPanel, Object.assign(getDefaultParams(), combined), [chartId]);
        }

        var toggleSettedFilters = function(filterDisplayName, filterKey, filterValue, chartId) {
          var settedFilters = $scope.settedFilters;
          var found = false;

          for (var i = 0; i < settedFilters.length; i++) {
            if (filterValue === settedFilters[i].filterValue) {
              found = true;
              break;
            }
          }

          if (found) {
            settedFilters.splice(i, 1);
          } else {
            settedFilters.push({
              chartId: chartId,
              displayName: filterDisplayName,
              filterKey: filterKey,
              filterValue: filterValue
            });
          }
        };

        $scope.removeSettedFilter = function(filter) {
            const isChartItemFilter = Boolean(filter.chartId);

            if (!isChartItemFilter) {
                $scope.filter(filter.filterKey, { name: filter.displayName, id: filter.filterValue });
                return;
            }

            const index = $scope.settedFilters.indexOf(filter);
            $scope.settedFilters.splice(index, 1);
            updateFilters(filtersFromChart, filter.filterKey, filter.filterValue);
            redrawAndUpdate(filter);
        };

        function redrawAndUpdate(filter) {
            const chart = chartsMap[filter.chartId];
            const clickedIndex = chart.labels.indexOf(filter.displayName);
            const isChartClickedItem = clickedIndex !== -1;
            const chartSkipIds = [];
            let params;

            if ($scope.settedFilters.length) {
                if (isChartClickedItem) {
                    const clickedIndexes = chart.clickedIndexes;
                    clickedIndexes.splice(clickedIndexes.indexOf(clickedIndex), 1);

                    if (clickedIndexes.length) {
                        chartSkipIds.push(chart.id);
                    }
                }

                highlightClicked(chart, ChartsConfig.getChartByIdInPanel(currentPanel, chart.id));
            } else {
                chart.clickedIndexes = [];
                resetChart(chart);
            }

            const combined = combineFilters(filtersFromChart, filters);
            params = Object.assign(getDefaultParams(), combined);
            updateCharts(currentPanel, params, chartSkipIds);
        }

        $scope.dateChanged = function() {
            Object.keys(chartsMap).forEach(id => resetChart(chartsMap[id]));
            const params = Object.assign(getDefaultParams(), filters);

            updateCharts(currentPanel, params);
        };

        $scope.changeChartType = function(chart, type) {
          if (chart.type === type) {
            return;
          }

          var chartConfig = ChartsConfig.getChartByIdInPanel(currentPanel, chart.id);
          chart.setType(type);

          if (chart.clickedIndexes && chart.clickedIndexes.length > 0) {
            highlightClicked(chart, chartConfig);
          } else {
            resetStyles(chart, chartConfig);
          }
        };

        $scope.chartClick = function(eventData) {
            if (eventData.length === 0) {
                return;
            }

            eventData = eventData[0];

            var chartId = eventData._chart.canvas.id;
            var chartObj = chartsMap[chartId];
            var clickedIndex = eventData._index;
            var clickedLabel = chartObj.labels[clickedIndex];

            toggleChartItem(chartObj, clickedLabel);
        };

        $scope.hasFilter = function(filterId, value) {
          return filters[filterId] && filters[filterId].includes(value)
              || filtersFromChart[filterId] && filtersFromChart[filterId].includes(value);
        };

        $scope.filter = function(prop, item, skip) {
            updateFilters(filters, prop, item.id);
            toggleSettedFilters(item.name, prop, item.id);

            var defaultParams = getDefaultParams();
            var combined = combineFilters(filters, filtersFromChart);

            return updateCharts(currentPanel, Object.assign(defaultParams, combined), skip);
        };

        // этот фильтр от $scope.filter отличается тем, что должен подсвечивать аналогичный итем в графике
        $scope.onFilterChange = function(prop, item, chartId) {
            const filterData = {
                propName: prop,
                id: item.id,
            };

            toggleChartItem(chartsMap[chartId], item.name, filterData);
        };

        $scope.open = function($event, id) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.calendarsOpen[1] = false;
            $scope.calendarsOpen[2] = false;
            $scope.calendarsOpen[id] = true;
        };

        var combineFilters = function(filtersOne, filtersTwo) {
          var combined = {};
          var keys = Object.keys(filtersOne).concat(Object.keys(filtersTwo));

          var filterValuesOne = null;
          var filterValuesTwo = null;

          keys.forEach(key => {
            filterValuesOne = filtersOne[key] || [];
            filterValuesTwo = filtersTwo[key] || [];
            combined[key] = _.union(filterValuesOne, filterValuesTwo);
          });

          return combined;
        };

        var updateFilters = function(filtersObj, filterParamName, filterValue) {
          var filtersToUpdate = filtersObj[filterParamName] || [];
          var index = filtersToUpdate.indexOf(filterValue);

          if (index === -1) {
            filtersToUpdate.push(filterValue);
          } else {
            filtersToUpdate.splice(index, 1);
          }

          filtersObj[filterParamName] = filtersToUpdate.length > 0 ? filtersToUpdate : null;
        }

        init();
    }
})();

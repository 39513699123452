
angular.module('smartApp').factory('TWQuestionService', function TWQuestionService($http, config, HttpUtils) {

	return {
		saveQuestion: function(question, cb) {
			console.log(config.backend + '/admin/constructor/questions')
			setTimeout(function(){
				var link = config.backend + '/admin/constructor/questions';
				$http.put(link, question, {noThrobber: true}).success(
					function(result) {
						if (result.status && result.status != 200) {
							cb(result.status);
							return;
						}
						cb(null, result);
					}
				);
			}, 0)
		},
		remove:function(questionsIds, cb) {
			//setTimeout(function(){
				var link = config.backend + '/admin/constructor/questions/' + questionsIds;
				$http.delete(link, {noThrobber: true}).success(
					function(result) {
						if (result.status && result.status != 200) {
							cb(result.status);
							return;
						}
						cb(null, result);
					}
				);
		   // }, 0)
		},
		move:function(questionsIds, processId, cb) {
			//setTimeout(function(){{processId}/questions/move
				var link = config.backend + '/admin/constructor/processes/' + processId + '/questions/move';
				$http.put(link, questionsIds, {noThrobber: true}).success(
					function(result) {
						if (result.status && result.status != 200) {
							cb(result.status);
							return;
						}
						cb(null, result);
					}
				);
		   // }, 0)
		},
        getClones:function(action, questionId, cb){
            if(action == "rename")action = 'name'
            if(action == "relink")action = 'process'
            $http.get(config.backend + "/admin/constructor/questions/cloned/"+ questionId +"/"+action, {noThrobber: false}).success(
                function(result, status) {
                    if (result && status != 200 && status != 204) {
                        cb(status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },
        actClones:function(action, parentQuestionId, questions, cb){
            if(action == "rename")action = 'name'
            if(action == "relink")action = 'process'
            $http.put(config.backend + "/admin/constructor/questions/cloned/"+ parentQuestionId +"/"+action, questions).success(
                function(result, status) {
                    if (result && status != 200 && status != 204) {
                        cb(status, result);
                        return;
                    }
                    cb(null, result);
                }
            );
        },
    getQuestionByValue: function(searchVal) {
      return $http.post(config.backend + '/admin/constructor/processes/questions/search', JSON.stringify(searchVal))
        .then(function (response) {
            return response.status == 200 ? response.data : [];
        }, function (response) {
            console.log(response);
        });
    }

	};
});

angular.module('smartApp').controller('ZoneWizardController', function(ngDialog, Notifications, $scope, TWTemplateService ) {

    $scope.createZone = createZone;
    $scope.saveNameZone = saveNameZone;

    init();

    function init() {
        getZones();
    }

    function getZones() {
        $scope.isLoading = true;
        TWTemplateService.getZones(function(err, result){
            $scope.isLoading = false;
            $scope.dataZones = result;
        })
    }

    function saveNameZone(id, newName) {
        TWTemplateService.renameZone({"id": id, "name": newName}, function(err, result){
            if (err) {
                Notifications.danger("Изменения не сохранены");
                console.log(err);
                return;
            }else{
                Notifications.success("Изменения сохранены");
            }
            getZones();
        })
    }

    function createZone() {
        ngDialog.open(
            {
                template: 'scripts/templates/zoneWizard/ng-dialog-create-template.html',
                className: 'ngdialog-theme-plain',
                controller: ['$scope', 'TWTemplateService', function($scope, TWTemplateService) {
                    $scope.createTemplate = function() {
                        TWTemplateService.addZone(
                            {
                                name: $scope.selectedName
                            },
                            function (err, result) {
                                if (err) {
                                    Notifications.danger("Не удалось добавить зону");
                                    console.log(err);
                                    return;
                                }else {
                                    Notifications.success("Зона добавлена");
                                }
                                init();
                            }
                        );
                    }
                }]
            }
        )
    };

});
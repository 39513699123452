const prefix = 'fpr:vue';

export const events = {
    unauthorized: 'unauthorized',
};

export const dispatch = (event, data) => {
    const errorEvent = new CustomEvent(`${prefix}:${event}`, {
        detail: data,
    });
    window.dispatchEvent(errorEvent);
};

export const listen = (event, cb) => {
    const listener = ev => {
        cb(ev.detail);
    };
    const key = `${prefix}:${event}`;
    window.addEventListener(key, listener);
    return () => window.removeEventListener(key, listener);
};

(function() {
    'use strict';

    angular
        .module('smartApp')
        .factory('TasksExtendedFilterService', TasksExtendedFilterService);

    TasksExtendedFilterService.$inject = ['$q', 'OrgstructService'];
    function TasksExtendedFilterService($q, OrgstructService) {
        var filterSettings = {
            taskIds: null,
            taskId: null,
            overdue: undefined,
            name: '',
            divisions: [],
            regions: [],
            executor: '',
            observer: '',
            createDateTo: null,
            createDateFrom: null,
            deadlineDateFrom: null,
            deadlineDateTo: null,
            grouped: null,
            executorLevels: null
        };
        var epmtyFilters = angular.copy(filterSettings)

        var service = {
            filterSettings: function() {
              return JSON.parse(JSON.stringify(filterSettings));
            },
            setFilter: setFilter,
            getListData: getListData,
            epmtyFilters:epmtyFilters
        };

        return service;


        function setFilter(generalFilter, filterSettings) {
            //TODO: выкинуть этот ад
            for (var key in filterSettings) {
                if (filterSettings.hasOwnProperty(key)) {
                    if (checkFilling(filterSettings[key])) {
                        generalFilter[key] = filterSettings[key];
                    }
                    if (
                        !checkFilling(filterSettings[key]) &&
                        _.has(generalFilter, key)
                    ) {
                        delete generalFilter[key];
                    }
                }
            }

            var dateParams = ['deadlineDateFrom', 'deadlineDateTo', 'createDateFrom', 'createDateTo'];

            for(var i in dateParams){
                if(filterSettings && filterSettings[dateParams[i]]){
                    generalFilter[dateParams[i]] = moment(filterSettings[dateParams[i]]).format("YYYY-MM-DD")
                }else if(generalFilter[dateParams[i]]){
                    delete generalFilter[dateParams[i]]
                }
            }


        }

        function getListData() {
            return $q
                .all([
                    OrgstructService.getDivisions({noThrobber: true}),
                    OrgstructService.getRegions({noThrobber: true})
                ])
                .then(function(data) {
                    var mappedData = [];
                    if (data && data.length) {
                        return data.map(el => el.data)
                    }else return [undefined,undefined];
                });
        }

        function checkFilling(val) {
            var filling;
            if (_.isObject(val)) {
                filling = !_.isEmpty(val);
            } else {
                filling = val || _.isInteger(val) || _.isBoolean(val);
            }
            return filling;
        }
    }
})();

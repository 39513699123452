angular.module('smartApp')
.filter('wordReplace', function() {
		return function(input) {
			var ans = input;
			switch(input){
				case "supervisor":
					ans = 'региональный директор';
					break;
        case 'Супервайзер':
				case "Supervisor":
					ans = 'Региональный директор';
					break;
        case "cluster":
            ans = 'группа магазинов';
            break;
        case "Cluster":
            ans = 'Группа магазинов';
            break;
        case "clusters":
            ans = 'группы магазинов';
            break;
        case "Clusters":
            ans = 'Группы магазинов';
            break;
				case 'clustersGenitive':
          ans = 'групп магазинов';
					break;
				case 'ClustersGenitive':
          ans = 'Групп магазинов';
					break;
        case "clusterGenitive":
            ans = 'группы магазинов';
            break;
        case "clustersDative":
            ans = 'группам магазинов';
            break;
        case 'ClusterGenitive':
          ans = 'Группы магазинов';
          break;
        case 'clusterDative':
          ans = 'группe магазинов';
          break;
        case 'ClusterDative':
          ans = 'Группe магазинов';
          break;
        case 'clusterAccusative':
          ans = 'группу магазинов';
          break;
        case 'ClusterAccusative':
          ans = 'Группу магазинов';
          break;
        case "division":
            ans = 'отдел продаж';
            break;
        case "Division":
            ans = 'Отдел продаж';
            break;
        case "divisions":
            ans = 'отделы продаж';
            break;
        case "Divisions":
            ans = 'Отделы продаж';
            break;
        case 'divisionGenitive':
          ans = 'отдела продаж';
          break;
        case 'DivisionGenitive':
          ans = 'Отдела продаж';
          break;
				case 'divisionsGenitive':
          ans = 'отделов продаж';
          break;
        case 'DivisionsGenitive':
          ans = 'Отделов продаж';
          break;
        case 'divisionDative':
          ans = 'отделу продаж';
          break;
        case 'DivisionDative':
          ans = 'Отделу продаж';
          break;
        case "BusinessDir":
            ans = 'Бизнес-направление';
            break;
         case "SAPcode":
            ans = "Номер магазина";
            break;
        case "SAPshirt":
            ans = "";
            break;
        case "Curators":
            ans = "Кураторы";
            break;
        case "сurators":
            ans = "кураторы";
            break;
        case "сurators_rod":
            ans = "кураторов";
            break;
        case "PointChecklists":
            ans = "Проверки";
            break;
        case "shopName":
            ans = "Название магазина";
            break;
        case "verifyCheckPrint":
            ans = "Печать";
            break;
				case 'shopAccusative':
					ans = 'магазин';
					break;
				case 'shopsAccusative':
					ans = 'магазины';
					break;
				case 'Choose':
					ans = 'Выберите';
					break;
				case 'executorsAccusative':
					ans = 'ответственных';
					break;
				case 'ExecutorsGenetive':
					ans = 'Ответственные'
					break;
        case 'Shop':
          ans = 'Магазин'
          break;
        case 'Shops':
          ans = 'Магазины'
          break;
       case "shopAccusative":
          ans = "магазин";
          break;
        case "shopAccusative":
          ans = "магазин";
        case "shopsAccusative":
          ans = "магазины";
          break;
        case "executorAccusative":
          ans = "исполнителя";
          break;
        case "ExecutorsGenetive":
          ans = "Исполнители";
          break;
        case "executor":
          ans = "исполнитель";
          break;
        case "executors":
          ans = "исполнители";
          break;
         case "Executor":
          ans = "Исполнитель";
          break;
        case "Executors":
          ans = "Исполнители";
          break;
        case "task":
          ans = "задача";
          break;
        case "Task":
          ans = "Задача";
          break;
        case "checklist":
          ans = "проверка";
          break;
         case "checklistGenetive":
          ans = "проверки";
          break;
        case "Checklist":
          ans = "Проверка";
          break;
        case "checklistTemplateAccusative":
          ans = "шаблон проверки"
          break;
        case "taskTemplateAccusative":
          ans = "шаблон задачи"
          break;

        case "executorAccusative":
          ans = "исполнителя";
          break;
        case "executorAccusative":
          ans = "исполнителя";
          break;
        case "executorsAccusative":
          ans = "исполнителей";
          break;
        case "Choose":
          ans = "Выбор";
          break;
			}
			return ans;
		}
	})

    .filter('taskGroupName', function(projectData) {
        return function(input) {
            if(input === "COMMON") {
                return "Общая";
            } else if (input === "PHOTO_REPORT") {
                return projectData.photoReport;
            } else if (input === "ROUTED") {
                return "Перенаправленная";
            } else if (input === "CHECKLIST") {
                return "Чек-лист";
            } else if (input === "CLEANING" || input === "FORMS") {
                return "Проверка наличия";
            } else if (input === "MANUAL_RECOUNT") {
                return 'Выборочный пересчет';
            } else if (input === "AUTO_RECOUNT") {
                return 'Плановый пересчет';
            } else if (input === "EQUIPMENT") {
                return 'Сбор данных'
            } else {
                return input
            }
        }
    })

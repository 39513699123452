<script setup>
import { onMounted, ref, watch } from 'vue';
import { CustomButton, CustomInput, CustomCheckbox, Accordion, Icon, SingleSelect, MultipleSelect, ULabel } from '@eron/ui-kit';
import { getRuleAuthors } from '@/api/tasks/generate';

import TaskTypeSelect from '../components/TaskTypeSelect.vue';
import PeriodicitySelect from '../components/PeriodicitySelect.vue';

defineOptions({
    components: {
        CustomButton,
        CustomInput,
        Accordion,
        Icon,
        MultipleSelect,
        SingleSelect,
        ULabel,
    },
});

const props = defineProps({
    modelValue: {
        type: Object,
        default: undefined,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:model-value']);

const expanded = ref(false);
const onlyActive = ref(true);
const name = ref();
const taskTypes = ref([]);
const periodicity = ref();
const authorIds = ref([]);
const authors = ref([]);

const emitSelected = () => {
    emit('update:model-value', {
        onlyActive: onlyActive.value,
        name: name.value,
        taskTypes: taskTypes.value,
        periodicity: periodicity.value,
        authorIds: authorIds.value,
    });
};

const reset = () => {
    name.value = undefined;
    taskTypes.value = [];
    periodicity.value = undefined;
    authorIds.value = [];
    emitSelected();
};

watch(
    () => props.modelValue,
    newVal => {
        onlyActive.value = newVal?.onlyActive || onlyActive.value;
        name.value = newVal?.name || name.value;
        taskTypes.value = newVal?.taskTypes || taskTypes.value;
        periodicity.value = newVal?.periodicity || periodicity.value;
        authorIds.value = newVal?.authorIds || authorIds.value;
    }
);

onMounted(async () => {
    const result = await getRuleAuthors();
    authors.value = result.data;
});
</script>

<template>
    <accordion id="filters" v-model:show-content="expanded" title="" class="-rounded-lg -p-4" style="box-shadow: 0 1px 2px rgb(0 0 0 / 15%)">
        <template #header>
            <custom-checkbox id="only-active-filter" v-model:checked="onlyActive" label="Только активные" @update:checked="emitSelected" />

            <div class="-flex -flex-shrink-0 -items-center">
                <slot name="header:end"></slot>
                <icon name="IconChevronDown" :class="{ '-rotate-180': expanded }" class="-text-gray-mist -transition-transform hover:-text-primary" />
            </div>
        </template>

        <template #default>
            <form class="-mt-5 -grid -grid-cols-4 -gap-4" @submit.prevent="emitSelected">
                <div>
                    <u-label for-value="name-filter"> Название </u-label>
                    <custom-input id="name-filter" v-model="name" />
                </div>
                <div>
                    <u-label for-value="author-filter"> Периодичность правила </u-label>
                    <periodicity-select id="periodicity-filter" v-model="periodicity" />
                </div>
                <div>
                    <u-label for-value="author-filter"> Автор правила </u-label>
                    <multiple-select id="author-filter" v-model="authorIds" :options="authors" label-prop="fullName" value-prop="id" />
                </div>
                <div>
                    <u-label for-value="task-type-filter"> Тип задачи </u-label>
                    <task-type-select id="task-type-filter" v-model="taskTypes" multi />
                </div>
                <div class="-col-span-4 -flex -items-center -justify-end">
                    <custom-button :disabled="disabled" text="Применить" type="submit" theme="green" class="-mr-4" @click.stop="emitSelected" />
                    <custom-button text="Сбросить" @click.stop="reset" />
                </div>
            </form>
        </template>
    </accordion>
</template>

'use strict';

angular.module('smartApp')
    .service('ExecutorTreeUtils', function () {
        this.getDataMap = function(nodes) {
            const nodesMap = {};
            let segment1, segment2;

            nodes.forEach(node => {
                const type = node.type;

                if (['division', 'taskManager'].includes(type)) {
                    nodesMap[node.id + "_" + type] = {};

                    for (let key in node) {
                        nodesMap[node.id + "_" + type][key] = node[key];
                    }

                    if (type === 'division') {
                        nodesMap[node.id + "_" + type].type = type;
                    }
                }

                if (node.regions) {
                    segment1 = 'regions';
                    segment2 = 'shops';
                    nodesMap[node.id + "_division"].children = node.regions;
                } else if (node.children) { //с children старый рест по магазинам
                    segment1 = segment2 = 'children';
                    nodesMap[node.id + "_division"].children = node.children;
                }

                if (!node[segment1] || !node[segment1].length) return;

                node[segment1].forEach(region => {
                    nodesMap[region.id + "_region"] = {};

                    for (let key in region) {
                        nodesMap[region.id + "_region"][key] = region[key];
                    }

                    nodesMap[region.id + "_region"].parentId = node.id;

                    if (region[segment2]) {
                        nodesMap[region.id + "_region"].children = region[segment2];
                    }

                    if (!region[segment2] || !region[segment2].length) return;

                    region[segment2].forEach(shop => {
                        nodesMap[shop.id + "_shop"] = {};

                        for (let key in shop) {
                            nodesMap[shop.id + "_shop"][key] = shop[key];
                        }

                        nodesMap[shop.id + "_shop"].parentId = region.id;
                    });
                });
            });

            return nodesMap;
        };

        this.getDataObject = function(nodes){
            nodes.forEach(division => {
                if (division.children) return; //значит это старый рест с необходимыми данными по магазинам, его менять здесь не нужно

                division.type = division.name ? 'division' : 'taskManager';

                if ((division.regions && division.regions.length) || (division.dirs && division.dirs.length)) {
                    division.children = division.regions || division.dirs;

                    division.children.forEach(region => {
                        if (division.dirs) {
                            region.name = region.lastName + ' ' + region.firstName;
                        }
                        region.type = 'region';
                        region.parentId = division.id;

                        if ((region.shops && region.shops.length) || (region.dirs && region.dirs.length)) {
                            region.children = region.shops || region.dirs;
                            region.children.forEach(shop => {
                                shop.type = 'shop';
                                shop.parentId = region.id;
                                shop.name = shop.fullName;
                            });

                            delete region.shops;
                        }
                    });

                    delete division.regions;
                }
            });

            return nodes;
        }
    });

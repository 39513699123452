(function() {
    'use strict';

    angular.module('smartApp').factory('ParamsService', ParamsService);

    ParamsService.$inject = ['$http', 'config'];

    function ParamsService($http, config) {

        var service = {
            getStates: getStates,
            getParams: getParams,
            filterBySelectedPropeties: filterBySelectedPropeties
        };

        return service;

        function getStates() {
            return [
                {
                    title: 'Уведомления',
                    state: 'settings.params.notifications'
                },
                {
                    title: 'Чек-листы',
                    state: 'settings.params.checklist'
                },
                // {
                //     title: 'Оценка качества',
                //     state: 'settings.params.quality'
                // },
                {
                    title: 'Общие',
                    state: 'settings.params.general'
                }
            ];
        }

        function getParams() {
            return $http
                .get(config.backend + '/admin/systemProperties')
                .then(function(resp) {
                    if (resp && resp.status === 200) {
                        return resp.data;
                    }
                });
        }

        function filterBySelectedPropeties(paramsList, tabSettings) {
            var fields = _.keys(tabSettings);
            var filteredFields = [];
            fields.forEach(function(field) {
                var foundField =  _.find(paramsList, {propertyKey: field});
                if (foundField) {
                    filteredFields.push(foundField);
                }
            });
            return filteredFields;
        }
    }
})();
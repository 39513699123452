angular.module('smartApp').directive('vuePage', function() {
  return {
    restrict: 'E',
    scope: {
      title: '@',
      componentName: '@'
    },
    template: `
      <page class="-overflow-auto">
        <header>
          <pageName>{{ title }}</pageName>
        </header>
        <panel>
          <vue-component component-name="{{ componentName }}"></vue-component>
        </panel>
      </page>
    `
  };
});
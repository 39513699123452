angular.module('smartApp').controller('XfilterController4', XfilterController4);
XfilterController4.$inject = ['$scope', 'PanelGroupsService'];

function XfilterController4($scope, PanelGroupsService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);

	$scope.fio = {selected:undefined};


	$scope.selectAllFio = function() {
		$scope.uiSelect.fio.selected = $scope.users;
	};	
	$scope.getUsersOnGroups = function(groups){
		$scope.fio.selected = undefined;
		var groupsArr = []
		async.eachSeries(groups, function(item, callback){
			PanelGroupsService.getGroupInfo(item.id).success(function (data, status, headers) {
				if(status != 200){
					callback(data);
					return
				}
				groupsArr.push(data)
				callback(null, data)
			})
		}, function(err, msg){
			if(err){
				Notifications.danger('Ошибка получения групп');
				console.log(err)
				return;
			}
			var users = [];
			for(var i in groupsArr){
				users = users.concat(groupsArr[i].users);
			}
			$scope.users = users;
		})
	}
	
}
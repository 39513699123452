(function() {
    'use strict';

    angular.module('smartApp')
        .directive('executorsTree', executorsTree);

    executorsTree.$inject = [
        'PWCategoryService',
        'Notifications',
        '$filter',
        'TaskListServiceHttp',
        'ExecutorTreeUtils',
        'TaskListService',
        'ngDialog',
        'Principal',
        'MyResponsibilityFilter',
        'localStorageService'
    ];

    function executorsTree(
        PWCategoryService,
        Notifications,
        $filter,
        TaskListServiceHttp,
        ExecutorTreeUtils,
        TaskListService,
        ngDialog,
        Principal,
        MyResponsibilityFilter,
        localStorageService
    ) {
        let filterByMyResponsibility;

        var directive = {
            restrict: 'E',
            scope: {
                dataobject:'=',
                executorRole:'@',
                changedElems:'=',
                shopsSap:'=',
                listExecutors:'=',
                searchRequest:'=',
                allCompanySelected:'=',
                maxSelectionOne: '='
            },
            templateUrl: 'scripts/templates/executors-tree.html',
            link: link
        };

        return directive;

        function link(scope) {
            var dataSave;
            var currentRole = null;

            scope.userData = Principal.getIdentity();
            scope.isRegManager = Principal.isInRole('ROLE_REG_MANAGER');
            scope.nodeValue = {};
            scope.dataMap = {};
            scope.expandedNodes = []; //массив развернутых элементов
            scope.checkboxNodes = {}; //массив отмеченных чекбоксов
            scope.outlineNodes = []; //массив обведенных чекбоксов
            scope.onlyMyResponsibility = {
                value: true,
                lsKey: 'executorsTree:onlyMyResponsibility'
            };
            let unfilteredData = [];

            scope.init = init;
            scope.checkedAll = checkedAll;
            scope.getNodes = getNodes;
            scope.showSelected = showSelected;
            scope.onOnlyMyResponsibilityChange = onOnlyMyResponsibilityChange;

            scope.init();

            function init() {
                dataSave = TaskListService.getDataObject();

                if (dataSave && dataSave.dataObject) {
                    unfilteredData = dataSave.dataObject;
                    scope.expandedNodes = dataSave.expandedNodes;
                    scope.dataobject = dataSave.dataObject;
                    scope.selectedNodes = dataSave.selectedNodes;
                    scope.dataMap = dataSave.dataMap;
                    scope.checkboxNodes = dataSave.checkboxNodes;
                    scope.allCompanySelected = dataSave.allCompanySelected;
                    scope.changedElems = dataSave.changedElems;
                } else {
                    scope.getNodes();
                }
            }

            scope.$watch('executorRole', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    scope.expandedNodes = [];
                    scope.dataobject = [];
                    scope.selectedNodes = [];
                    scope.checkboxNodes = {};
                    scope.searchRequest = '';
                    scope.allCompanySelected = false;
                    scope.getNodes();
                }
            });

            scope.$watch('searchRequest', function(newVal, oldVal) {
                if (!newVal || !newVal.trim()){
                    setTreeData(angular.copy(unfilteredData));
                    return;
                }

                if (newVal !== oldVal){
                    searchInTree(newVal);
                }
            });

            scope.$watch('shopsSap', function(newVal, oldVal) {
                if (newVal !== oldVal){
                    selectedAndExpanded(newVal);
                }
            });

            scope.toggleTreeExpand = function() {
                if (scope.expandedNodes.length) {
                    scope.expandedNodes = [];
                } else {
                    scope.expandedNodes = expandAllNodes(scope.dataobject);
                }
            }

            function onOnlyMyResponsibilityChange() {
                const result = filterByMyResponsibility(scope.onlyMyResponsibility.value);
                unfilteredData = result.divisions;
                localStorageService.set(scope.onlyMyResponsibility.lsKey, scope.onlyMyResponsibility.value);
                if (scope.searchRequest && scope.searchRequest.trim()) {
                    searchInTree(scope.searchRequest)
                } else {
                    setTreeData(unfilteredData);
                }
            }

            function selectedAndExpanded(values){
                if (!values || values.length === 0) return;

                var treeItems = [];
                for (var n = 0; n < values.length; n++){
                    treeItems.push(scope.dataMap[values[n].id + '_' + values[n].type]);
                }

                for (var i = 0; i < treeItems.length; i++){
                    scope.showSelected(treeItems[i], true);
                    scope.expandedNodes = scope.expandedNodes.concat(expandNode(treeItems[i], scope.dataobject));
                }
            }

            function checkedAll () {
                if (!!scope.allCompanySelected){
                    for (var i = 0; i < scope.dataobject.length; i++) {
                       scope.showSelected(scope.dataobject[i], false)
                   }
                }else{
                    for (var i = 0; i < scope.dataobject.length; i++) {
                       scope.showSelected(scope.dataobject[i], true)
                   }
                }
            }

            function getNodes() {
                currentRole = scope.executorRole;

                scope.allCompanySelected = false;
                scope.selectedNodes = [];
                scope.changedElems = [];
                scope.dataobject = [];

                var lessThanUserLevel;
                var businessDirId = (!~scope.userData.roles.indexOf('ROLE_ADMIN')) ? scope.userData.businessDirId : null; //если пользователь админ, то ему видны все пользователи, безотносительно бизнес-направления

                const onResultData = data => {
                    if (scope.isRegManager) {
                        filterByMyResponsibility = MyResponsibilityFilter(data);
                        scope.onlyMyResponsibility.value = localStorageService.get(scope.onlyMyResponsibility.lsKey) === 'true';
    
                        if (scope.onlyMyResponsibility.value) {
                            const result = filterByMyResponsibility(scope.onlyMyResponsibility.value);
                            unfilteredData = result.divisions;
                        } else {
                            unfilteredData = data;
                        }
                    } else {
                        unfilteredData = data;
                    }

                    setTreeData(angular.copy(unfilteredData), true);
                };

                switch (scope.executorRole) {
                    case "SHOP":
                        TaskListServiceHttp.getHierarchyShops().success(function (result, status) {
                            if (currentRole !== scope.executorRole) {
                              return;
                            }

                            if (status === 200 || status === 201) {
                                onResultData(result);
                            }
                        });
                        break;
                    case "REGION":
                        TaskListServiceHttp.getHierarchyRegions({withDirs: true, businessDirId: businessDirId}).success(function (result, status) {
                            if (currentRole !== scope.executorRole) {
                              return;
                            }

                            if (status === 200 || status === 201) {
                                onResultData(result);
                            }
                        });
                        break;
                    case "DIVISION":
                        TaskListServiceHttp.getListExecutors({fields: "dirs", businessDirId: businessDirId}, 'divisions').success(function (result, status) {
                            if (status === 200 || status === 201) {
                                onResultData(result);
                            }
                        });
                        break;
                    case "TASK_MANAGER":

                        businessDirId = scope.userData.businessDirId;

                        if(~scope.userData.roles.indexOf('ROLE_ADMIN')){ //если пользователь админ, то ему видны все пользователи, безотносительно бизнес-направления
                            businessDirId = null;
                            lessThanUserLevel = "COMPANY";
                        }else if(~scope.userData.roles.indexOf('ROLE_OPERATION_DIRECTOR')){
                            lessThanUserLevel = "COMPANY";
                        }else if(~scope.userData.roles.indexOf('ROLE_CURATOR')){
                            lessThanUserLevel = "DIVISION";
                        }else{
                            Notifications.danger("Ошибка доступа!", "", 3000);
                        }

                        var reqMZ = {
                            businessDirId: businessDirId,
                            role: "ROLE_TASK_MANAGER",
                            lessThanUserLevel: lessThanUserLevel
                        };

                        TaskListServiceHttp.getListExecutors(reqMZ, 'users').success(function (result, status) {
                            if (status == 200 || status === 201) {
                                result = result.filter(function(elem){ //убираем самого текущего пользователя (админа) из списка, если он там есть
                                    if(elem.id !== scope.userData.id) return elem
                                });
                                scope.dataobject = ExecutorTreeUtils.getDataObject(result);
                                scope.dataMap = ExecutorTreeUtils.getDataMap(scope.dataobject);
                                scope.nodeValue.selected = scope.dataobject[0].id;
                                scope.selectedNodes.push(scope.dataobject[0]);
                                scope.changedElems = scope.selectedNodes;
                            }
                        });
                        break;
                    default:
                        console.error("unknown executor role!")
                        break;
                }
            }

            function expandAllNodes(treeData) {
                let expandedNodes = [];

                treeData.forEach(node => {
                    if (node.children) {
                        expandedNodes.push(node);
                        expandedNodes = expandedNodes.concat(expandAllNodes(node.children))
                    }
                })

                return expandedNodes;
            }

            function expandNode(node) {
                var expNodes = [];
                if (node.parentId !== undefined){
                    if (node.type == 'shop'){
                        var region = scope.dataMap[node.parentId + '_region'];
                        expNodes.push(region);
                        expNodes.push(scope.dataMap[region.parentId + '_division']);
                    }else if(node.type == 'region'){
                        expNodes.push(scope.dataMap[node.parentId + '_division']);
                    }

                }
                return expNodes;
            }

            function searchInTree(request){
                const resultSearchItems = $filter('searchExecutor')(angular.copy(unfilteredData), { request: request, type: scope.executorRole });
                if (resultSearchItems.length) {
                    setTreeData(resultSearchItems);
                } else {
                    notFoundNodes();
                }
            }

            function setTreeData(data, isInit) {
                switch (scope.executorRole) {
                    case 'SHOP':
                        scope.dataobject = data;
                        scope.dataMap = ExecutorTreeUtils.getDataMap(scope.dataobject);
                        break;
                    case 'REGION':
                        scope.dataobject = ExecutorTreeUtils.getDataObject(data);
                        scope.dataMap = ExecutorTreeUtils.getDataMap(scope.dataobject);
                        scope.expandedNodes = expandAllNodes(scope.dataobject);
                        break;
                    case 'DIVISION':
                        scope.dataobject = ExecutorTreeUtils.getDataObject(data);
                        scope.dataMap = ExecutorTreeUtils.getDataMap(scope.dataobject);
                        scope.expandedNodes = expandAllNodes(scope.dataobject);
                        break;
                    default:
                        console.error('unknown executor role!')
                        break;
                }
                if (isInit) {
                    saveData();
                }
            }

            function handleOneNodeSelectionShop(node, selected) {
              if ((node.type === 'region' || node.type === 'division')) {
                scope.selectedNodes = scope.selectedNodes.filter(selectedNode => selectedNode.id !== node.id);
              } else if (node.type === 'shop') {
                selectOneNode(node, selected);
              }

              if (scope.selectedNodes.length > 0) {
                saveData();
              }
            }

            function handleOneNodeSelectionRegion(node, selected) {
              if (node.type === 'division') {
                scope.selectedNodes = scope.selectedNodes.filter(selectedNode => selectedNode.id !== node.id);
              } else if (node.type === 'region') {
                selectOneNode(node, selected);
              }

              if (scope.selectedNodes.length > 0) {
                saveData();
              }
            }

            function selectOneNode(node, selected) {
              var prevNode = scope.selectedNodes[0];

              if (selected && prevNode) {
                scope.checkboxNodes[prevNode.id + '_' +  prevNode.type] = { checked: false };
                scope.outlineNodes[prevNode.id + '_' + prevNode.type] = { checked: false };
              }

              var selectedNodes = selected ? [node] : [];

              scope.checkboxNodes[node.id + '_' +  node.type] = { checked: selected };
              scope.outlineNodes[node.id + '_' + node.type] = { checked: selected };

              scope.changedElems = angular.copy(selectedNodes);
              scope.selectedNodes = angular.copy(selectedNodes);
            }

            function showSelected(node, selected) {
              // ограничение выбора до одного ответственного
              // if (scope.maxSelectionOne) {
              //   switch(scope.executorRole.toLowerCase()) {
              //     case 'shop':
              //       handleOneNodeSelectionShop(node, selected);
              //       break;
              //     case 'region':
              //       handleOneNodeSelectionRegion(node, selected);
              //       break;
              //   }
              //   return;
              // }

                var selNodes = [];
                var parent;
                var grandParent;
                var itemSelect;

                //TODO: переписать с картой
                if (node.type !== 'taskManager'){
                    selNodes = angular.copy(scope.changedElems);
                }

                var parentType;
                var grandParentType;
                var findItem;
                var findAllItems;

                if (node.type === "shop"){
                    parentType = "region"
                }else if(node.type === "region"){
                    parentType = "division"
                }

                if(node.parentId != undefined){
                    parent = scope.dataMap[node.parentId + '_' + parentType];

                    if(parent.parentId){
                        if (parent.type === "shop"){
                            grandParentType = "region"
                        }else if(parent.type === "region"){
                            grandParentType = "division"
                        }
                        grandParent = scope.dataMap[parent.parentId + '_' + grandParentType];
                    }
                }

                //scope.selectedNodes = [];
                if (selected === true){ //if element clicked checked

                    itemSelect = selNodes.some(function(elem){
                        return (elem.id === node.id) && (elem.type === node.type)
                    });

                    if(itemSelect) return;

                    selNodes.push(scope.dataMap[node.id + '_' + node.type]);
                    if(node.type === 'taskManager'){
                        scope.nodeValue.selected = node.id
                    } else{
                        scope.checkboxNodes[node.id + '_' + node.type] = {checked: true};
                        scope.outlineNodes[node.id + '_' + node.type] = {checked: false};
                    }
                    if (node.children !== undefined && node.children.length !== 0){ // if the element have children then checked their and add in selected array
                        for (var j = 0; j < node.children.length; j++) {
                            selNodes.push(scope.dataMap[node.children[j].id + '_' + node.children[j].type]);
                            scope.checkboxNodes[node.children[j].id + '_' +  node.children[j].type]= {checked: true};
                            scope.outlineNodes[node.children[j].id + '_' +  node.children[j].type] = {checked: false};
                            if (node.children[j].children !== undefined && node.children[j].children.length !== 0){
                                for (var k = 0; k < node.children[j].children.length; k++) {
                                    selNodes.push(scope.dataMap[node.children[j].children[k].id + '_' + node.children[j].children[k].type]);
                                    scope.checkboxNodes[node.children[j].children[k].id + '_' +  node.children[j].children[k].type] = {checked: true};
                                    scope.outlineNodes[node.children[j].children[k].id + '_' +  node.children[j].children[k].type] = {checked: false};
                                }
                            }
                        }
                    }
                }else if(selected === false && node.type !== 'taskManager'){ //if element clicked unchecked
                    scope.checkedAllNode = false;
                    selNodes = $filter('filter')(selNodes, function (value, index) {
                        return  !((value.type === node.type) && (value.id === node.id))
                    });
                    scope.checkboxNodes[node.id + '_' + node.type] = {checked: false};
                    if (node.children !== undefined && node.children.length !== 0) { // if the element have children then unchecked and delete their from selected array
                        for (var j = 0; j < node.children.length; j++) {
                            selNodes = $filter('filter')(selNodes, function (value, index) {
                                return !((value.type === node.children[j].type) && (value.id === node.children[j].id));
                            });
                            scope.checkboxNodes[node.children[j].id + '_' +  node.children[j].type] = {checked: false};

                            if (node.children[j].children !== undefined && node.children[j].children.length !== 0) {
                                for (var k = 0; k < node.children[j].children.length; k++) {
                                    selNodes = $filter('filter')(selNodes, function (value, index) {
                                        return !((value.type === node.children[j].children[k].type) && (value.id === node.children[j].children[k].id));
                                    });
                                    scope.checkboxNodes[node.children[j].children[k].id + '_' +  node.children[j].children[k].type] = {checked: false};
                                }
                            }
                        }
                    }

                    if (node.parentId !== undefined){ // if the element have parents then unchecked and delete their from selected array

                        var parentEl = $filter('filter')(selNodes,  function (value, index) {return (value.type === parentType) && (value.id === node.parentId)});
                        if (parentEl.length !== 0) {

                            selNodes = $filter('filter')(selNodes, function (value, index) {
                                return !((value.id === parentEl[0].parentId) && (value.type === "division"))
                            });

                            selNodes = $filter('filter')(selNodes, function (value, index) {
                                return !((value.type === parentType) && (value.id === node.parentId))
                            });
                        }

                        for (var i = 0; i < scope.dataobject.length; i++) {
                            if (parentEl[0] && parentEl[0].parentId !== undefined) {
                                if (scope.dataobject[i].id === parentEl[0].parentId) {
                                    scope.checkboxNodes[scope.dataobject[i].id + '_' +  scope.dataobject[i].type] = {checked: false};
                                }
                                for (var j = 0; j < scope.dataobject[i].children.length; j++) {
                                    if (scope.dataobject[i].children[j].id === node.parentId) {
                                        scope.checkboxNodes[scope.dataobject[i].children[j].id + '_' +  scope.dataobject[i].children[j].type] = {checked: false};
                                    }
                                }
                            }else{
                                if (scope.dataobject[i].id === node.parentId) {
                                    scope.checkboxNodes[scope.dataobject[i].id + '_' +  scope.dataobject[i].type] = {checked: false};
                                }
                            }
                        }
                    }
                }else if(selected === false && node.type === 'taskManager'){
                    selNodes = angular.copy(scope.changedElems);
                }

                //----обработка дочерних чек-боксов для постановки выделенного чек-бокса родителя, если все дочерние отмечены
                if(parent && parent.children && parent.children.length !== 0){
                    var childrenIds = [];
                    var notAll = false;
                    var selectSomeEl = false;

                    angular.forEach(parent.children, function(value){
                        if (value.id && value.type){
                            childrenIds.push(value.id + "_" + value.type); //собираем все id детей элемента в массив для последующего сравнения
                        }
                        if (value.children && value.children.length !== 0){
                            angular.forEach(value.children, function(val) {
                                childrenIds.push(val.id + "_" + val.type);  //собираем все id внуков (если есть) элемента в массив для последующего сравнения
                            })
                        }
                    });
                    if(childrenIds.length !== 0){
                        angular.forEach(childrenIds, function(elemChild) {
                            if(!scope.checkboxNodes[elemChild] || !scope.checkboxNodes[elemChild].checked){ //сравниваем с массивом выделенных элементов - все ли дочерние элементы выделены
                                notAll = true;
                            }
                             if(scope.checkboxNodes[elemChild] && scope.checkboxNodes[elemChild].checked){
                                selectSomeEl = true;
                            }
                        })
                    }else{
                        notAll = true;
                    }

                    if(selectSomeEl){
                        if(parent){
                            scope.outlineNodes[parent.id + '_' + parent.type] = {checked: true}; //добавляем родителя в массив обведенных чекбоксов
                        }
                        if(grandParent){
                            scope.outlineNodes[grandParent.id + '_' + grandParent.type] = {checked: true}; //добавляем старшего родителя в массив обведенных чекбоксов
                        }
                    }else{
                        if(parent){
                            scope.outlineNodes[parent.id + '_' + parent.type] = {checked: false}; //удаляем родителя в массив обведенных чекбоксов
                        }
                        if(grandParent){
                            scope.outlineNodes[grandParent.id + '_' + grandParent.type] = {checked: false}; //удаляем старшего родителя в массив обведенных чекбоксов
                        }
                    }

                    if (notAll === false){ //если все дочерние элементы выделены
                        selNodes.push(scope.dataMap[parent.id + '_' + parent.type]); //добавляем родителя в массив выделенных элементов
                        scope.checkboxNodes[parent.id + '_' + parent.type] = {checked: true}; //добавляем родителя в массив отмеченных чекбоксов
                        scope.outlineNodes[parent.id + '_' + parent.type] = {checked: false}; //удаляем родителя из массива обведенных чекбоксов
                        if(grandParent){
                            selNodes.push(scope.dataMap[grandParent.id + '_' + grandParent.type]); //добавляем старшего родителя в массив выделенных элементов
                            scope.checkboxNodes[grandParent.id + '_' + grandParent.type] = {checked: true}; //добавляем старшего родителя в массив отмеченных чекбоксов
                            scope.outlineNodes[grandParent.id + '_' + grandParent.type] = {checked: false}; //удаляем старшего родителя из массива обведенных чекбоксов
                        }
                    }
                }
                //----
                //вычисляем отмечать ли нам пункт "Выбрать все"
                var findItems = [];
                    findAllItems = scope.dataobject.every(function (element) {
                        findItems = selNodes.filter(function(el){ //ищем в массиве выделенных элементов все верхнеуровневые элементы объекта scope.dataobject
                            return (el.id === element.id) && (el.type === element.type)
                        });
                        //findItem = $filter('filter')(selNodes, {id: element.id, type: element.type})[0];
                        return findItems[0];
                    });
                    findAllItems === true ? scope.allCompanySelected = true : scope.allCompanySelected = false; //findAllItems - true  - найдены все верхнеуровневые элементы объекта scope.dataobject в массиве выделенных элементов selNodes
                //----

                scope.changedElems = [];
                scope.selectedNodes = angular.copy(scope.changedElems = angular.copy(selNodes));
                saveData();
            }

            function saveData() {
              TaskListService.setDataObject(scope.dataobject,
                                            scope.expandedNodes,
                                            scope.selectedNodes,
                                            scope.checkboxNodes,
                                            scope.dataMap,
                                            scope.allCompanySelected,
                                            scope.changedElems);
            }

            function notFoundNodes(){
                ngDialog.open(
                    {
                        template: 'warningNotFoundNodes',
                        className: 'ngdialog-theme-plain',
                        showClose: false
                    }
                );
            }

            scope.treeOptions = {
                label: "Root",
                nodeChildren: "children",
                multiSelection: true,
                dirSelectable: true,
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            };

            /**
             * The part below is taken from original angular-tree-control plugin.
             * The original tree setting are initiated in the scope of the main controller,
             * to which the directive instance belongs, so we dont have access to them from
             * this scope. This is an implicit option spec that can be replaced by any other
             * workaround that solves the issue with the defaultTreeOptions init in directive
             * scope.
             */

            scope.treeOptions.equality = function (a, b) {
                if (!a || !b)
                    return false;
                a = shallowCopy(a);
                a[scope.treeOptions.nodeChildren] = [];
                b = shallowCopy(b);
                b[scope.treeOptions.nodeChildren] = [];
                return angular.equals(a, b);
            };

            scope.treeOptions.isSelectable = function (node) {
                return true;
            };

            function shallowCopy(src, dst) {
                if (angular.isArray(src)) {
                    dst = dst || [];

                    for (var i = 0; i < src.length; i++) {
                        dst[i] = src[i];
                    }
                } else if (angular.isObject(src)) {
                    dst = dst || {};

                    for (var key in src) {
                        if (hasOwnProperty.call(src, key) && !(key.charAt(0) === '$' && key.charAt(1) === '$')) {
                            dst[key] = src[key];
                        }
                    }
                }

                return dst || src;
            };

            scope.treeOptions.isLeaf = function (node) {
                return !node[scope.treeOptions.nodeChildren] || node[scope.treeOptions.nodeChildren].length === 0;
            };

            scope.getSelectedChildrenCount = function (nodeId) {
                let selectedNode = scope.selectedNodes.find(node => node.id === nodeId);
                return selectedNode && selectedNode.children ? selectedNode.children.length : 0;
            };
        }
    }

})();

angular.module('smartApp').directive('vueWrapper', function() {
    return {
        restrict: 'E',
        scope: {
            component: '@',
            withRouter: '<',
            props: '<'
        },
        link: function(scope, element) {
            scope.$watch('component', function(newValue) {
                if (newValue) {
                    const vueAppElement = element[0].querySelector('#vue-app');
                    if (vueAppElement) {
                        if (window.mountVueComponent) {
                            window.mountVueComponent(newValue, vueAppElement, scope.props, {
                                withRouter: scope.withRouter
                            });
                        }
                    }
                }
            });
        },
        template: '<div id="vue-app" class="vue-container"></div>'
    };
});

'use strict';

angular.module('smartApp')
    .service('timeFormatter', function () {
        let prevTime = '';

        return function(value) {
            const splittedValue = value.split(':');
            let firstHalf = splittedValue[0];
            let secondHalf = splittedValue[1];

            if (!firstHalf && !secondHalf) {
                return prevTime;
            }

            firstHalf = [firstHalf, secondHalf].join('').match(/\d+/g);

            if (!firstHalf || firstHalf.length > 4) {
                return prevTime;
            }

            firstHalf = firstHalf.join('');

            if (firstHalf.length < 3) {
                firstHalf = firstHalf.length < 2 ? `0${firstHalf}` : firstHalf;
                secondHalf = '00';
            } else {
                secondHalf = firstHalf.slice(2, 4);
                firstHalf = firstHalf.slice(0, 2);
                secondHalf = secondHalf.length < 2 ? `0${secondHalf}` : secondHalf;
            }

            if (parseInt(firstHalf) > 23 || parseInt(secondHalf) > 59) {
                return prevTime;
            }

            prevTime = [firstHalf, secondHalf].join(':');

            return prevTime;
    }
})

/**
 * Created by vsko on 26.07.16.
 */

angular.module('smartApp').directive('treednd', ['PWCategoryService', 'ProcessTreeUtils', 'Notifications', function (PWCategoryService, ProcessTreeUtils, Notifications) {
    return {
        restrict: 'A',
        link: function (scope, elt, attrs, parentCtrl) {
            // elt.draggable({
            //     cursor: 'move',
            //     appendTo: 'body',
            //     /**
            //      *  Uncomment next line to disable 1st lvl node dragging
            //      */
            //     //disabled: !scope.$parentNode,
            //     drag: function (event, ui) {
            //         var destination = ui.helper.data('destination')
            //         if (destination) {
            //             destination.removeClass('tree-hover-middle');
            //             destination.addClass('tree-hover-middle');
            //         }
            //     },
            //     helper: function (event) {
            //         var helper = $('<div class="helper">' + scope.node.name + '</div>');
            //         // fill some data to be catched up by droppable() of receiver directives
            //         helper.data('node', scope.node);
            //         helper.data('parentNode', scope.$parentNode);
            //         if(scope.$parentNode == null) {
            //             helper.data('dataobject', scope.$parent.dataobject);
            //         }
            //         return helper;
            //     }

            // });
            // elt.droppable({
            //     tolerance: 'pointer',
            //     over: function (event, ui) {
            //         ui.helper.data('destination', elt);
            //         elt.addClass('tree-hover');
            //     },
            //     out: function (event, ui) {
            //         ui.helper.data('destination', null);
            //         elt.removeClass('tree-hover tree-hover-middle');
            //     },
            //     drop: function (event, ui) {
            //         var toNode = scope.node;
            //         var fromNode = ui.helper.data('node');
            //         var fromParentNode = ui.helper.data('parentNode');
            //         var dataobject = ui.helper.data('dataobject');

            //         function updateNode(newParentId, cb) {
            //             PWCategoryService.updateProcessParent(fromNode.id, newParentId, function(err, result) {
            //                 cb(result);
            //             });
            //         }

            //         scope.$apply(function () {
            //             if (toNode.type == 'group' || toNode.type == 'empty') {
            //                 //remove dragged item from old parent
            //                 if (fromParentNode && fromParentNode != null) {
            //                     var idx = fromParentNode.children.indexOf(fromNode);
            //                     if (idx != -1) {
            //                         fromParentNode.children.splice(idx, 1);
            //                     }
            //                     toNode.type = 'group';
            //                     toNode.icon = 'folder';
            //                 } else { //Remove it from root
            //                     var idx = dataobject.indexOf(fromNode);
            //                     if(idx != -1) {
            //                         dataobject.splice(idx, 1);
            //                     }
            //                 }
            //                 //server req & move dragged item to dropped parent
            //                 updateNode(toNode.id, function(newNode) {
            //                     if (toNode.children) {
            //                         toNode.children.push(ProcessTreeUtils.setupIconForNewNode(newNode));
            //                     }
            //                 });
            //             } else {
            //                 Notifications.danger("'" + toNode.name + "' не является блоком процессов, и не может вместить '" + fromNode.name + "'");
            //             }
            //         });
            //         elt.removeClass('tree-hover tree-hover-middle');
            //     }
            // });

            var dereg = scope.$on('$destroy', function () {
                try {
                    elet.draggable('destroy');
                } catch (e) {
                    // may fail
                }
                dereg();
                dereg = null;
            });

        }
    };
}]);
/**
 * Created by vsko on 26.07.16.
 */

'use strict';

angular.module('smartApp').factory('PWGroupService', function ($resource, config) {
    return $resource(config.backend + '/processWizard/group/:id', {}, {
            'query': { method: 'GET', isArray: true },
            'get': { method: 'GET'},
            'update': { method: 'PUT' },
            'create': { method: 'POST' },
            'delete': { method: 'DELETE' }
        }
    );
});
angular.module('smartApp')
    .controller('UnsoldGoodsController', ['$scope', '$stateParams', '$state', 'TaskListServiceHttp', 'Notifications', 'ngDialog', 'SystemParamsService', function(
        $scope,
        $stateParams,
        $state,
        TaskListServiceHttp,
        Notifications,
        ngDialog,
        SystemParamsService
    ) {
        if (!$stateParams.id) {
            $state.go('tasks');
            return;
        }

        const responseId = $stateParams.id;
        let daysWithoutSales = null;

        $scope.response = {};
        $scope.task = {};
        $scope.unsoldGoods = [];

        $scope.showPhotoInModal = function(url) {
            ngDialog.open({
                template: 'unsoldGoodsPhoto',
                controller: ['$scope', function($scope) {
                    $scope.url = url;
                }],
                className: 'ngdialog-theme-plain dialog img-popup',
            });
        };

        $scope.isGoodsWithoutSales = function(item) {
           if (!item.comment || !item.recount) {
                 return daysWithoutSales < item.daysWithoutSales;
           } else {
               return false;
           }
        };

        $scope.trimLocalCode = function(item) {
            return item.replace(/^0+/, '');
        }

        function getDaysWithoutSales() {
            SystemParamsService.getSystemProps(['tasks.manual.goods.withoutsale.days'], (err, result) => {
                if(err){
                    console.log("Err: ", err);
                    return
                }

                daysWithoutSales = Number(result['tasks.manual.goods.withoutsale.days']);
            });
        }

        function init() {
            TaskListServiceHttp
                .getResponse(responseId)
                .then(function(resp) {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке товаров');
                        $state.go('tasks');
                        return;
                    }

                    $scope.response = resp.data;
                    $scope.unsoldGoods = resp.data.unsoldGoods;

                    getTaskInfo(resp.data.taskId);
                    getDaysWithoutSales();
                });
        }

        function getTaskInfo(id) {
            TaskListServiceHttp
                .getTask(id)
                .then(function(resp) {
                    if (resp.status !== 200) {
                        Notifications.danger('Произошла ошибка при загрузке задачи');
                        $state.go('tasks');
                        return;
                    }

                    $scope.task = resp.data;
                });
        }

        init();
    }]);

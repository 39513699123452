angular.module('smartApp').component('goodsSelection', {
    templateUrl: 'scripts/app/tasks/task-new/goods-selection/goods-selection.component.html',
    controller: GoodsSelectionController,
    bindings: {
        apply: '&',
        selected: '<',
    },
});

function GoodsSelectionController(goodsApi, Notifications) {
    const $ctrl = this;

    $ctrl.goods = {
        list: [],
        selected: $ctrl.selected ? $ctrl.selected.slice() : [],
    };
    $ctrl.searchValue = '';
    $ctrl.searched = false;
    $ctrl.pagination = {
        size: 20,
        total: 0,
        page: 1,
    };
    $ctrl.localCodesToParse = '';

    $ctrl.searchTypes = [
        { label: 'Поиск по наименованию или ЛК', value: 'SEARCH' },
        { label: 'Множественное добавление', value: 'LOCALCODES' },
    ];

    $ctrl.searchType = $ctrl.searchTypes[0].value;

    $ctrl.search = function(searchValue) {
        if (searchValue.length < 3) return;
        $ctrl.pagination.page = 1;
        loadGoods().then(() => {
            $ctrl.searched = true;
        });
    };

    $ctrl.toggle = function(item) {
        const targetIdx = $ctrl.goods.selected.findIndex(goodsItem => item.localcode === goodsItem.localcode);

        if (targetIdx >= 0) {
            $ctrl.goods.selected.splice(targetIdx, 1);
        } else {
            $ctrl.goods.selected.push(item);
        }
    };

    $ctrl.remove = function(item) {
        const targetIdx = $ctrl.goods.selected.findIndex(goodsItem => item.localcode === goodsItem.localcode);
        $ctrl.goods.selected.splice(targetIdx, 1);
    };

    $ctrl.isSelected = function(item) {
        return Boolean($ctrl.goods.selected.find(goodsItem => item.localcode === goodsItem.localcode));
    };

    $ctrl.changePage = function(page) {
        $ctrl.pagination.page = page;
        loadGoods();
    };

    $ctrl.selectByLocalCodes = function() {
        const localcodes = $ctrl.localCodesToParse
            .split(/[^a-zA-Z\d:]/)
            .map(localcode => localcode.trim())
            .filter(localcode => Boolean(localcode) && /^\d+$/.test(localcode));

        if (!localcodes.length) return;

        goodsApi.getGoodsByLocalCodes(localcodes).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Произошла ошибка при загрузке данных');
                return;
            }

            result.data.forEach(item => {
                if (!$ctrl.isSelected(item)) {
                    $ctrl.goods.selected.push(item);
                }
            });
        });
    };

    function loadGoods() {
        return goodsApi.getGoods({ search: $ctrl.searchValue, page: $ctrl.pagination.page - 1, size: $ctrl.pagination.size  }).then(result => {
            if (result.status !== 200) {
                Notifications.danger('Произошла ошибка при загрузке данных');
                return;
            }

            $ctrl.pagination.total = result.headers('x-total-count');
            $ctrl.goods.list = result.data;
        });
    }
}

GoodsSelectionController.$inject = ['GoodsApiService', 'Notifications'];

function SettingsIntegrationService($http, SystemParamsService, Notifications, config) {
  function handleIntegrationResult(result) {
    if (result.status === 200) {
      Notifications.success('Интеграция прошла успешно');
      return 'ok';
    }

    Notifications.danger('Ошибка интеграции');
  }

  return {
    getParams: function(cb) {
      var propsToGet = [
        'sap.integration.emails',
        'sap.integration.fileName.shopDir',
        'sap.integration.fileName.divisionRegion',
        'sap.integration.fileName.availabilityCheck',
        'sap.integration.leftover',
        'sap.integration.assortment',
        'sap.integration.localcode.barcode'
      ];

      SystemParamsService.getSystemProps(propsToGet, function(err, params) {
        if (err) {
          Notifications.danger('Ошибка! Не удалось получить системные параметры');
          cb(err);
          return;
        }

        cb(null, params);
      });
    },

    saveParam: function(paramKey, value) {
      var paramToSave = { propertyKey: paramKey, value: value };

      return SystemParamsService.set([paramToSave], { noThrobber: true }).then(result => {
        if (result.status && result.status != 200) {
          Notifications.danger('Ошибка сохранения');
          return;
        }

        Notifications.success('Ваши изменения сохранены');
      });
    },

    integrateWithSap: function() {
      return $http.get(config.backend + '/integration/shopDir', {
        timeout: 300000,
        noThrobber: true
      }).then(result => {
        if (result.status === 200) {
          Notifications.success('Интеграция прошла успешно');
        } else if (result.headers('x-error') === 'FileNotFoundException') {
          var xErrorText = result.headers('x-error-text');
          var fileNames = xErrorText.replace(/"\./g, '').replace(/"/g, '').split(' ').join(', ')
          Notifications.danger('Неправильные наименования файлов: ' + fileNames);
        } else {
          Notifications.danger('Ошибка интеграции');
        }
      });
    },

    integrateForms: function() {
      return $http.patch(config.backend + '/integration/forms/data/reload', {}, {
        timeout: 1000 * 60 * 60,
        noThrobber: true
      }).then(handleIntegrationResult);
    },

    integrateProductsLeftover: function() {
      return $http.get(config.backend + '/integration/importLeftoverGoods', {
        timeout: 1000 * 60 * 60,
        noThrobber: true
      }).then(handleIntegrationResult);
    },

    integrateProductsAssortment: function() {
      return $http.get(config.backend + '/integration/importGoodsAssortment', {
        timeout: 1000 * 60 * 60,
        noThrobber: true
      }).then(handleIntegrationResult);
    },

    integrateLocalcodeBarcode: function() {
      return $http.get(config.backend + '/integration/importGoodsLocalcodeBarcode', {
        timeout: 1000 * 60 * 60,
        noThrobber: true
      }).then(handleIntegrationResult);
    },

    getLastIntegrationTime: function(filename) {
      return $http.get(config.backend + '/integration/lastIntegration', { params: { filename: filename } });
    }
  }
}

angular.module('smartApp').service('SettingsIntegrationService', [
  '$http',
  'SystemParamsService',
  'Notifications',
  'config',
  SettingsIntegrationService
]);

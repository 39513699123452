/**
 * Created by Yury Tyurin <tyurin23@gmail.com> on 10.06.15.
 */

'use strict';

angular.module('smartApp')
	.controller('CounterModelsEditDialogController', modelEditDialogController);

modelEditDialogController.$inject = ['$scope', '$state', '$stateParams', 'CounterService', 'CounterTypes', 'CounterConnectionTypes', 'FormFactory', 'Notifications'];

function modelEditDialogController($scope, $state, $stateParams, CounterService, CounterTypes, CounterConnectionTypes, FormFactory, Notifications){
	$scope.progress = false;
	$scope.years = _.range(new Date().getFullYear() - 9, new Date().getFullYear() + 1);
	$scope.counterTypes = CounterTypes;
	$scope.counterConnectionTypes = CounterConnectionTypes;
	$scope.model = {
		type: CounterTypes[0].name,
		connectionType: CounterConnectionTypes[0].name,
		releaseYear: $scope.years[$scope.years.length - 1]
	};

	init();

	$scope.createForm = function(form){
		return new FormFactory(form);
	};

	$scope.submit = function(){
		save();
		return true;
	};

	$scope.showAlert = function(){
		Notifications.danger('Не все поля заполнены верно');
	};

	function init(){
		if($stateParams.id){
			CounterService.getModelById($stateParams.id)
				.success(function(data, status){
					if(status === 200){
						if(data.canDelete){ // Если модель можно редактировать
							$scope.model = data;
						}else{
							Notifications.danger('Модель запрещено редактировать');
							$state.go('counters.list');
						}
					}else if(status === 404 || status === 400){
						Notifications.danger('Модель не найдена');
						$state.go('counters.list');
					}
				})
		}
	}

	function save() {
		$scope.progress = true;
		if($scope.model.id){
			CounterService.updateModel($scope.model)
				.success(function(data, status) {
					if(status === 200 || status === 201){
						Notifications.success('Данные успешно сохранены');
						$scope.progress = false;
					}else if(status === 400 || status === 404){
						Notifications.danger('Ошибка');
					}
				})
		}else{
			CounterService.createNewModel($scope.model)
				.success(function(data, status) {
					if(status === 200 || status === 201){
						Notifications.success('Модель счетчика ' + $scope.model.model + ' успешно создана');
						$scope.progress = false;
						$state.go('counters.list');
					}else if(status === 400){
						Notifications.danger('Ошибка');
					}
				})
		}

	}
}







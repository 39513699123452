'use strict';

angular.module('smartApp')
    .factory('Account', function Account($http, config) {
        return {
          get: function() {
            return $http.get(config.backend + '/xauth/account', { params: {} });
          }
        };
    });

var PanelGroupsService;

PanelGroupsService = function($http, config, $q, $filter, $timeout) {
    return {
        getGroups: function(page, limit, group_name) {
            var query;
            query =
                config.backend +
                '/admin/groups?page=' +
                page +
                '&per_page=' +
                limit;
            if (group_name !== null && group_name !== '') {
                query += '&name=' + group_name;
            }
            return $http.get(query);
        },
        createGroup: function(group_info) {
            return $http.post(config.backend + '/admin/groups', group_info);
        },
        getAllGroups: function() {
            return $http.get(config.backend + '/objects/groups');
        },
        updateGroup: function(group_to_update) {
            return $http.put(config.backend + '/admin/groups', group_to_update);
        },
        deleteGroup: function(ids) {
            return $http['delete'](config.backend + '/admin/groups/' + ids);
        },
        getAllRoles: function() {
            return $http.get(config.backend + '/objects/authorities');
        },
        getReportRoles: function(hasNoThrobber) {

            return $http.get(
                config.backend + '/orgstruct/roles/groups-available', {
                    noThrobber: hasNoThrobber
                }
            );
        },
        getAllUsers: function(hasNoThrobber) {

            return $http.get(config.backend + '/objects/users', {
                noThrobber: hasNoThrobber
            });
        },
        getGroupInfo: function(id, hasNoThrobber) {

            return $http.get(config.backend + '/admin/groups/' + id, {
                noThrobber: hasNoThrobber,
                timeout: 120000
            });
        },
        parse: function(headers) {
            var links, parts;
            parts = headers.split(',');
            links = {};
            angular.forEach(parts, function(p) {
                var name, page, queryString, section, url;
                section = p.split(';');
                url = section[0].replace(/<(.*)>/, '$1').trim();
                queryString = {};
                url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
                    $0,
                    $1,
                    $2,
                    $3
                ) {
                    return (queryString[$1] = $3);
                });
                page = queryString['page'];
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                name = section[1].replace(/rel="(.*)"/, '$1').trim();
                return (links[name] = page);
            });
            return links;
        }
    };
};

angular.module('smartApp').factory('PanelGroupsService', PanelGroupsService);

PanelGroupsService.$inject = ['$http', 'config', '$q', '$filter', '$timeout'];

angular.module('smartApp').controller('FilterController5', FilterController5);
FilterController5.$inject = ['$scope', 'UserService'];

function FilterController5($scope, UserService) {
	function onUpdateReportResult(result) {}
	$scope.$watch('reportResult', onUpdateReportResult);


	//$scope.users = [];
}

/*
	onUpdateReportResult - функция, отвечающая за биндинг
	$scope.init - запрос к API и инициализация фильтров
*/